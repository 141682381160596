import React, { useEffect } from "react";
import { convertJsonDateToString } from "../util";

const RelativeTimeDisplay = ({ date }: { date: string }): JSX.Element => {
  const timeoutRef = React.useRef<NodeJS.Timer | null>(null);
  const [relativeTime, setRelativeTime] = React.useState(
    convertJsonDateToString(date)
  );
  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearInterval(timeoutRef.current);
    }
    timeoutRef.current = setInterval(() => {
      setRelativeTime(convertJsonDateToString(date));
    }, 60000);
    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [timeoutRef, date]);
  return <span>{relativeTime}</span>;
};

export default RelativeTimeDisplay;

import { gql } from "@apollo/client";
import { getClient } from "../../gql-client";
import { ChatTypes } from "frank-types";
import { PaginationArgument } from "frank-types/dist/chat-types";

export async function getChannels({
  memberPagination,
}: {
  memberPagination: PaginationArgument;
}): Promise<ChatTypes.Channel[]> {
  const client = await getClient();
  const { data, errors } = await client.query<
    Pick<ChatTypes.Query, "channels">
  >({
    query: gql`
      query GetChannels($memberPagination: PaginationArgument!) {
        channels {
          id
          name
          unreadCount
          importantCount
          allowReplies
          cursor
          type
          members(memberPagination: $memberPagination) {
            objects {
              id
              displayName
              profilePic
            }
            hasNext
            total
          }
        }
      }
    `,
    variables: {
      memberPagination,
    },
    fetchPolicy: "no-cache",
  });

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data?.channels;
}

export async function getChannel(
  channelId: string,
  memberPagination: PaginationArgument
): Promise<ChatTypes.Channel> {
  const client = await getClient();
  const { data, errors } = await client.query<Pick<ChatTypes.Query, "channel">>(
    {
      query: gql`
        query GetChannel($id: ID!, $memberPagination: PaginationArgument!) {
          channel(id: $id) {
            id
            name
            unreadCount
            allowReplies
            importantCount
            cursor
            type
            members(memberPagination: $memberPagination) {
              objects {
                id
                displayName
                profilePic
              }
              hasNext
              total
            }
          }
        }
      `,
      variables: { id: channelId, memberPagination },
      fetchPolicy: "no-cache",
    }
  );

  if (errors?.length || !data) {
    throw new Error("Graph error" + JSON.stringify(errors));
  }

  return data?.channel;
}

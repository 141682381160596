import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import { isEmpty, omit } from "lodash";
import * as React from "react";

const MUTATION = gql`
  mutation SignAuthorizationCard($input: SignAuthorizationCardInput!) {
    signAuthorizationCard(input: $input) {
      id
    }
  }
`;

export default function useSignAuthorizationCard() {
  const [invokeSignAuthorizationCard, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationSignAuthorizationCardArgs
  >(MUTATION);

  const signAuthorizationCard = React.useCallback(
    async (input: {
      firstName: string;
      lastName: string;
      phone: string;
      employer: string;
      email: string;
      signature: string;
    }) => {
      const authorizationCardFormValues = omit(input, [
        "firstName",
        "lastName",
        "phone",
        "employer",
        "email",
        "signature",
      ]);

      await invokeSignAuthorizationCard({
        variables: {
          input: {
            firstName: input.firstName,
            lastName: input.lastName,
            phone: input.phone,
            employer: input.employer,
            email: input.email,
            signature: input.signature,
            authorizationCardFormResponses: isEmpty(authorizationCardFormValues)
              ? undefined
              : JSON.stringify(authorizationCardFormValues),
          },
        },
      });
    },
    [invokeSignAuthorizationCard]
  );

  return {
    signAuthorizationCard,
    loading,
    called,
    error,
  };
}

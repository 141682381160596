import React from "react";
import { MembershipRequest } from "frank-types/dist/frank-backend-types";
import { useModals } from "../../../Modals";
import PendingMemberList from "../../../PendingMembers/pages/PendingMemberList";
import ActionListItem from "./ActionListItem";
import { useTranslation } from "react-i18next";

const MembershipRequestsActionItem = ({
  membershipRequests,
  onUpdate,
}: {
  membershipRequests: MembershipRequest[];
  onUpdate: () => Promise<void>;
}) => {
  const { setModal } = useModals();
  const { t } = useTranslation();
  const defaultAction = () =>
    setModal({
      type: "genericModal",
      props: {
        body: <PendingMemberList onRefetch={onUpdate} slim />,
        title: t("reviewPendingMembers"),
      },
    });
  const actions = [
    {
      label: t("review"),
      action: defaultAction,
      dataCy: "review-pending-membership-requests",
    },
  ];
  return (
    <ActionListItem
      key={membershipRequests[0].id}
      title={t("reviewPendingMembersWithCount", {
        count: membershipRequests.length,
      })}
      subheading={t("vouchForCoworkers")}
      actions={actions}
      defaultAction={defaultAction}
    />
  );
};

export default MembershipRequestsActionItem;

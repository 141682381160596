import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query MyPartner {
    myPartner {
      id
      name
      defaultWelcomeMessage
      logoAttachment {
        id
        url
      }
      onboardingFormJSON
    }
  }
`;

export default function useMyPartner() {
  const { data, error, loading } = useQuery<
    Pick<FrankBackendTypes.Query, "myPartner">
  >(QUERY);

  return {
    loading,
    error,
    partner: data?.myPartner,
  };
}

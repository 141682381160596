import * as React from "react";
import CustomFormFieldResponseInput from "./CustomFormFieldResponseInput";
import { DataFieldResponseDataInput } from "./types/common";
import { FrankBackendTypes } from "frank-types";
import EventEmitter from "./util";

export default function CustomFormSectionResponse({
  section,
  fields,
  onUpdateResponse,
  responses,
  disabled,
  fieldErrors,
}: {
  section: FrankBackendTypes.CustomFormSection;
  fields: FrankBackendTypes.CustomFormField[];
  onUpdateResponse: (val: DataFieldResponseDataInput) => void;
  responses: DataFieldResponseDataInput[];
  disabled: boolean;
  fieldErrors: FrankBackendTypes.CustomFormFieldError[];
}): JSX.Element {
  const fieldInputs = React.useMemo(
    () =>
      fields.map((field, index) => (
        <CustomFormFieldResponseInput
          key={field.id}
          field={field}
          index={index}
          onUpdate={(value) => onUpdateResponse(value)}
          response={
            responses.find((r) => r.dataFieldId === field.dataField.id) ??
            undefined
          }
          error={fieldErrors.find(
            (e) => e.customFormFieldId === field.dataField.id
          )}
          disabled={disabled}
        />
      )),
    [fields, onUpdateResponse, responses, disabled, fieldErrors]
  );
  return (
    <fieldset className="t-large plus border pb-0 pt-0 px-3 mb-6">
      {section.title.length > 0 && (
        <legend className="px-2">{section.title}</legend>
      )}
      {section.instructions.length > 0 && (
        <div className="py-2 px-1 t-mini">{section.instructions}</div>
      )}
      {fieldInputs}
    </fieldset>
  );
}

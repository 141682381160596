import { Nudge } from "@get-frank-eng/design-system";
import * as React from "react";
import { Link } from "react-router-dom";
import Crossroads from "./Crossroads";
import { useTranslation } from "react-i18next";

export interface Pagination<T extends Record<string, any>> {
  hasNext: boolean;
  objects: T[];
  total?: number;
}

export enum PaginationStrategy {
  SHOW_MORE = "showMore",
  FOREVER_SCROLL = "foreverScroll",
}

const showMoreClasses = "text-canvas-400 hover:text-canvas-300 t-mini plus";

export default function Pager<T>({
  pagination,
  paginationStrategy,
  onShowMore,
  showMoreLink,
  children,
  showAllText,
  header,
}: {
  pagination: Pagination<T>;
  paginationStrategy: PaginationStrategy;
  onShowMore?: () => any;
  showMoreLink?: string;
  children: any;
  showAllText?: string;
  header?: React.ReactElement;
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-row items-center justify-between">
        {header && (
          <>
            {header}
            {pagination?.hasNext &&
              paginationStrategy === PaginationStrategy.SHOW_MORE && (
                <Nudge y={0}>
                  {showMoreLink ? (
                    <Link className={showMoreClasses} to={showMoreLink}>
                      {t("showAll")}
                    </Link>
                  ) : (
                    <button
                      type="button"
                      onClick={onShowMore}
                      className={showMoreClasses}
                    >
                      {showAllText || t("showAll")}{" "}
                    </button>
                  )}
                </Nudge>
              )}
          </>
        )}
      </div>
      {children}
      {pagination?.hasNext &&
        paginationStrategy === PaginationStrategy.FOREVER_SCROLL && (
          <Crossroads onEntering={onShowMore} />
        )}
      {!header &&
        pagination?.hasNext &&
        paginationStrategy === PaginationStrategy.SHOW_MORE && (
          <>
            {showMoreLink ? (
              <Link className={showMoreClasses} to={showMoreLink}>
                {showAllText}
              </Link>
            ) : (
              <button
                type="button"
                onClick={onShowMore}
                className={showMoreClasses}
              >
                {showAllText}
              </button>
            )}
          </>
        )}
    </>
  );
}

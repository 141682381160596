import {
  Button,
  Intent,
  useToaster,
  Sizes,
  Tooltip,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";
import useArchiveMutations from "../dataAccess/useArchiveMutations";
import { useTranslation } from "react-i18next";

const ActionBar = ({
  toggleAllRowsSelected,
  refetch,
  selectedCoworkers,
  actions,
}: {
  toggleAllRowsSelected: (bool: boolean) => any;
  refetch: () => any;
  actions: string[];
  selectedCoworkers: FrankBackendTypes.Coworker[];
}) => {
  const { t } = useTranslation();
  const { setModal, openConfirmationModal, closeModal } = useModals();

  const { addToast } = useToaster();

  const {
    invokeArchive,
    invokeUnArchive,
    loadingArchive,
    loadingUnArchive,
  } = useArchiveMutations();

  const afterBulkAction = React.useCallback(() => {
    toggleAllRowsSelected(false);
    closeModal();
    refetch();
  }, [toggleAllRowsSelected, refetch, closeModal]);

  const numSelected = selectedCoworkers.length;

  const archive = React.useCallback(async () => {
    try {
      const confirmed = await openConfirmationModal({
        title: t("confirmArchive"),
        bodyText: t("areYouSureYouWantToArchiveThisCoworkerWithCount", {
          count: numSelected,
        }),
        actionText: t("confirm"),
      });

      if (!confirmed) {
        return;
      }

      await invokeArchive({
        variables: { coworkerIds: selectedCoworkers.map((c) => c.id) },
      });

      addToast({
        intent: Intent.SUCCESS,
        children: t("coworkersArchived", { count: numSelected }),
      });

      afterBulkAction();
    } catch (e) {}
  }, [
    addToast,
    afterBulkAction,
    openConfirmationModal,
    numSelected,
    selectedCoworkers,
    invokeArchive,
    t,
  ]);

  const unarchive = React.useCallback(async () => {
    try {
      await openConfirmationModal({
        title: t("confirmUnarchive"),
        bodyText: t("areYouSureYouWantToUnarchiveThisCoworkerWithCount", {
          count: numSelected,
        }),
        actionText: t("confirm"),
      });

      await invokeUnArchive({
        variables: { coworkerIds: selectedCoworkers.map((c) => c.id) },
      });

      addToast({
        intent: Intent.SUCCESS,
        children: t("coworkersUnarchived", { count: numSelected }),
      });

      afterBulkAction();
    } catch (e) {}
  }, [
    addToast,
    afterBulkAction,
    openConfirmationModal,
    numSelected,
    selectedCoworkers,
    invokeUnArchive,
    t,
  ]);

  const openInviteModal = React.useCallback(() => {
    const shouldWarn = selectedCoworkers.some(
      (coworker) => coworker.shouldWarnBeforeInvite
    );
    setModal({
      type: "emailInviteModal",
      props: {
        warnings: shouldWarn ? (
          <div className="my-2 p-2 bg-frank-gold-200">
            <p className="t-small plus-plus">{t("warning")}</p>
            <p className="t-small">{t("someOfTheCoworkersYouveInvitedHav")}</p>
          </div>
        ) : null,
        onSubmit: afterBulkAction,
        emails: selectedCoworkers.map((coworker) => coworker.email),
        coworkerIds: selectedCoworkers.map((coworker) => coworker.id),
      },
    });
  }, [afterBulkAction, selectedCoworkers, setModal, t]);

  const openFlagModal = React.useCallback(() => {
    setModal({
      type: "flagModal",
      props: {
        onSubmit: afterBulkAction,
        toFlag: selectedCoworkers.map((coworker) => ({
          label: coworker.name,
          id: coworker.user?.id,
        })),
      },
    });
  }, [setModal, selectedCoworkers, afterBulkAction]);

  const disabledSettings = React.useMemo(
    () => ({
      archive: selectedCoworkers.every((coworker) => coworker.canArchive),
      unArchive: selectedCoworkers.every((coworker) => coworker.canUnArchive),
      flag: selectedCoworkers.every((coworker) => coworker.canFlag),
      invite: selectedCoworkers.every((coworker) => coworker.canInvite),
    }),
    [selectedCoworkers]
  );

  return (
    <div>
      <div className="flex flex-row items-center space-x-2">
        {actions.includes("flag") && (
          <Button
            disabled={!disabledSettings.flag}
            onClick={openFlagModal}
            buttonStyle="minimal"
            iconRight="flag"
            size={Sizes.SM}
          >
            {t("flag")}
          </Button>
        )}

        {actions.includes("unarchive") && (
          <Button
            disabled={!disabledSettings.unArchive}
            onClick={unarchive}
            loading={loadingUnArchive}
            buttonStyle="minimal"
            size={Sizes.SM}
          >
            {t("unarchive")}
          </Button>
        )}

        {actions.includes("archive") && (
          <Button
            disabled={!disabledSettings.archive}
            onClick={archive}
            loading={loadingArchive}
            iconRight="delete"
            buttonStyle="minimal"
            size={Sizes.SM}
          >
            {t("archive")}
          </Button>
        )}

        {actions.includes("invite") && (
          <Tooltip
            hideArrow
            nudgeY={12}
            disabled={disabledSettings.invite}
            tooltip={t("someOfTheCoworkersYouSelected") as string}
            trigger="hover"
          >
            <Button
              size={Sizes.SM}
              disabled={!disabledSettings.invite}
              buttonStyle="brand"
              onClick={openInviteModal}
            >
              {/** SPECIAL CASE */}
              Invite {selectedCoworkers.length}{" "}
              {selectedCoworkers.length > 1 ? " Coworkers" : " Coworker"}
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ActionBar;

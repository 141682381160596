import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation AreUsersInChannel($input: AreUsersInChannel!) {
    areUsersInChannel(input: $input)
  }
`;

export default function useAreUsersInChannel() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "areUsersInChannel">,
    FrankBackendTypes.MutationAreUsersInChannelArgs
  >(mutation);

  const areUsersInChannel = React.useCallback(
    async (input: FrankBackendTypes.AreUsersInChannel) => {
      const { data } = await invokeMutation({
        variables: { input },
      });
      return data?.areUsersInChannel;
    },
    [invokeMutation]
  );

  return {
    areUsersInChannel,
    loading,
    error,
    called,
  };
}

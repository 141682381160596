import { Button } from "@get-frank-eng/design-system";
import * as React from "react";
import { useModals } from "../Modals";
import { useTranslation } from "react-i18next";

export const UseOffline = ({ children }) => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useModals();
  const [offline, setOffline] = React.useState<boolean>();

  // commenting this out as well because Logan is freaking out about it
  // React.useEffect(() => {
  //   window.onoffline = (event) => {
  //     setOffline(true);
  //     setModal({
  //       type: "genericModal",
  //       props: {
  //         body: (
  //           <div className="t-small">
  //             {t("youAreNotConnectedToTheInternet")}
  //           </div>
  //         ),
  //         title: t("youAreOffline"),
  //         footer: <Button onClick={closeModal}>{t("gotIt")}</Button>,
  //       },
  //     });
  //   };
  // });

  // commenting this out because not sure of the behavior we actually want people to do when they're back online
  // React.useEffect(() => {
  //   window.ononline = (event) => {
  //     if (offline) {
  //       setModal({
  //         type: "genericModal",
  //         props: {
  //           body: (
  //             <div className="t-small">{t("refreshThePageToSeeNewData")}</div>
  //           ),
  //           title: t("youAreBackOnline"),
  //           footer: <Button onClick={closeModal}>{t("gotIt")}</Button>,
  //         },
  //       });

  //       setOffline(false);
  //     }
  //   };
  // });
  return children;
};

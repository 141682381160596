import {
  Button,
  Icon,
  InlineAlert,
  Sizes,
  Avatar,
} from "@get-frank-eng/design-system";
import * as React from "react";
import Loading from "../../components/Loading";
import { useAuthorizationCardSignatureByIdQuery } from "../../generated-hooks";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { printPDF } from "../../utils/printPdf";

const ViewSignedCard = ({
  customFormId,
  signatureId,
}: {
  customFormId?: string;
  signatureId: string;
}) => {
  const { t } = useTranslation();
  const { partner } = useCurrentUserData();
  const [additionalFields, setAdditionalFields] = React.useState();

  const { data, loading, error } = useAuthorizationCardSignatureByIdQuery({
    variables: { signatureId, customFormId },
  });

  React.useEffect(() => {
    if (!data) {
      return;
    }

    if (!data.authorizationCardSignatureById.authCardFormResponseJSON) {
      return;
    }

    setAdditionalFields(
      JSON.parse(data.authorizationCardSignatureById.authCardFormResponseJSON)
    );
  }, [data, setAdditionalFields]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <InlineAlert title={t("serverError")} />;
  }

  return (
    <div>
      <div
        className="max-w-xl border rounded text-black bg-white flex flex-col p-4 authorization-card"
        id={`authorization-card-${signatureId}`}
      >
        <div className="flex items-center space-x-4">
          <Avatar
            src={partner.logoAttachment.url}
            size={Sizes.XL}
            className="mt-4"
          />
          <div>
            <h2 className="t-large plus">
              {t("partnerNameAuthorizationCard", {
                partnerName: partner.name,
              })}
            </h2>
            <p className="t-small max-w-2xl">
              {t("bySigningThisCardYouAuthorizePar", {
                partnerName: partner.name,
              })}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 py-4">
          <div>
            <span className="font-bold">{t("firstName")}:</span>{" "}
            {data.authorizationCardSignatureById.firstName}
          </div>
          <div>
            <span className="font-bold">{t("lastName")}:</span>{" "}
            {data.authorizationCardSignatureById.lastName}
          </div>
          <div>
            <span className="font-bold">{t("email")}:</span>{" "}
            {data.authorizationCardSignatureById.email}
          </div>
          <div>
            <span className="font-bold">{t("date")}:</span>{" "}
            {new Date(
              data.authorizationCardSignatureById.createdAt
            ).toLocaleDateString()}
          </div>
          <div>
            <span className="font-bold">{t("employer")}:</span>{" "}
            {data.authorizationCardSignatureById.employer}
          </div>
          <div>
            <span className="font-bold">{t("phone")}:</span>{" "}
            {data.authorizationCardSignatureById.phone}
          </div>
          {!!additionalFields &&
            Object.keys(additionalFields)
              .sort()
              .map((k) => {
                if (additionalFields) {
                  return (
                    <div key={k}>
                      {/** @ts-ignore */}
                      <span className="font-bold">{t(k)}</span>:{" "}
                      {/** @ts-ignore */}
                      {additionalFields[k]}
                    </div>
                  );
                }
                return null;
              })}
        </div>
        <div className="t-small max-w-2xl">
          {data.authorizationCardSignatureById.authorizationLanguage}
        </div>
        <div className="pt-2">
          <span className="font-bold">Signature:</span>{" "}
          <span style={{ fontFamily: "cursive" }}>
            {data.authorizationCardSignatureById.signature}
          </span>
        </div>
      </div>
      <Button
        id="download-button"
        size={Sizes.SM}
        className="mt-4"
        onClick={() =>
          printPDF({
            id: `authorization-card-${signatureId}`,
            width: 132,
            filename: `${data.authorizationCardSignatureById.firstName}_${data.authorizationCardSignatureById.lastName}_authorization_card.pdf`,
          })
        }
      >
        <div className="flex flex-row justify-between items-center space-x-2">
          {/** FIXME i18n this */}
          <div>Download</div>
          <Icon icon="cloud_download" size={Sizes.MD} />
        </div>
      </Button>
    </div>
  );
};

export default ViewSignedCard;

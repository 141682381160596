"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutlinePillBadge = exports.PillBadge = exports.StatusBadge = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const StatusBadge = ({ color, children, size, border }) => {
    const textSize = {
        [constants_1.Sizes.MD]: "t-mini",
        [constants_1.Sizes.LG]: "t-small",
        [constants_1.Sizes.XL]: "t-regular"
    }[size];
    const dotSize = border ? "16px" : "8px";
    return (React.createElement("span", { className: "flex flex-row space-x-2 items-center" },
        React.createElement("span", { style: { width: dotSize, height: dotSize }, className: `${border &&
                "border-canvas-700 border-4"} rounded-full flex-shrink-0 ${color} inline-block` }),
        React.createElement("span", { className: `${textSize} text-canvas-500` }, children)));
};
exports.StatusBadge = StatusBadge;
const PillBadge = ({ children, size, backgroundColor, textColor }) => {
    const textSize = {
        [constants_1.Sizes.MD]: "t-mini plus",
        [constants_1.Sizes.LG]: "t-mini plus"
    }[size];
    const padding = {
        [constants_1.Sizes.MD]: "px-2 py-1",
        [constants_1.Sizes.LG]: "px-2 py-1"
    }[size];
    return (React.createElement("span", { className: `${padding} bg-${backgroundColor} text-${textColor} rounded-full ${textSize} plus` }, children));
};
exports.PillBadge = PillBadge;
const OutlinePillBadge = ({ children, size, borderColor, textColor }) => {
    const textSize = {
        [constants_1.Sizes.MD]: "t-mini plus",
        [constants_1.Sizes.LG]: "t-mini plus"
    }[size];
    const padding = {
        [constants_1.Sizes.MD]: "px-2 py-0.5",
        [constants_1.Sizes.LG]: "px-2 py-1"
    }[size];
    return (React.createElement("span", { className: `${padding} border border-${borderColor} text-${textColor} rounded-full ${textSize} plus whitespace-nowrap` }, children));
};
exports.OutlinePillBadge = OutlinePillBadge;

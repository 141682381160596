import { Button, Sizes } from "@get-frank-eng/design-system";
import * as React from "react";
import { useModals } from "../../Modals";
import { useTranslation } from "react-i18next";

export const TaskActions = ({ task, refetch, groupId }) => {
  const { t } = useTranslation();
  const { setModal } = useModals();

  const handleEdit = React.useCallback(
    async (e) => {
      setModal({
        type: "createTaskModal",
        props: {
          existingTask: task,
          afterSubmit: refetch,
          groupId,
        },
      });
    },
    [groupId, refetch, setModal, task]
  );

  const handleAssign = React.useCallback(
    async (e) => {
      setModal({
        type: "assignTaskModal",
        props: {
          afterSubmit: refetch,
          task,
          groupId,
        },
      });
    },
    [groupId, refetch, setModal, task]
  );

  return (
    <div className="flex flex-row space-x-2">
      <Button size={Sizes.SM} onClick={handleEdit}>
        {t("edit")}
      </Button>
      <Button size={Sizes.SM} onClick={handleAssign}>
        {t("assign")}
      </Button>
    </div>
  );
};

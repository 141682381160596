import {
  Avatar,
  Button,
  doesEmailHaveFreeDomain,
  FormGroup,
  FullCenter,
  InlineAlert,
  Input,
  Intent,
  Sizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import Frank from "../../components/icons/Frank";
import { useConfirmEmailModal } from "../ConfirmEmailModal";
import useSignUp from "../dataAccess/mutations/useSignUp";
import * as analytics from "../../analytics";
import useQuery from "../../hooks/useQuery";
import { EventTypes, FrankBackendTypes } from "frank-types";
import usePartner from "../dataAccess/queries/usePartner";
import * as spinners from "react-spinners";
import { WorkerOneOnboardingError } from "frank-types/dist/frank-backend-types";
import sentryClient, { SentryLoggingLevel } from "../../sentryClient";
import { useTranslation } from "react-i18next";

type FormData = { email: string };
const Email = () => {
  const { t } = useTranslation();
  const [serverErr, setServerErr] = React.useState<Error | null>();
  const [
    workerOneOnboardingError,
    setWorkerOneOnboardingError,
  ] = React.useState<WorkerOneOnboardingError | null>();
  const { signUp, loading } = useSignUp();

  const { register, handleSubmit, errors, watch } = useForm<FormData>();
  const history = useHistory();
  const openConfirmEmailModal = useConfirmEmailModal();

  const { partnerSlug } = useParams<{ partnerSlug: string }>();

  const qs = useQuery();

  const templateId = React.useMemo(() => qs.get("templateId"), [qs]);

  const { partner, loading: partnerLoading } = usePartner({ partnerSlug });

  const signUpHandler = React.useCallback(
    async (params) => {
      const hasFreeDomain = doesEmailHaveFreeDomain(params.email);
      analytics.capture(EventTypes.AnalyticsEvents.SCREEN_SIGNUP_EMAIL, {
        email: params.email,
        hasFreeDomain,
        properties: {
          acValue: hasFreeDomain
            ? "Has free domain"
            : "Does not have free domain",
        },
      });
      if (!hasFreeDomain) {
        const confirm = await openConfirmEmailModal({
          workerTwo: false,
          email: params.email,
        });
        if (!confirm) {
          return;
        }
      }
      try {
        const { data } = await signUp(params.email, partnerSlug, templateId);
        if (
          data.signUp.error ===
          FrankBackendTypes.WorkerOneOnboardingError.ExistingUser
        ) {
          setWorkerOneOnboardingError(
            FrankBackendTypes.WorkerOneOnboardingError.ExistingUser
          );
          sentryClient.addBreadcrumb({
            category: "worker-one-onboarding",
            message:
              "Someone with an existing account tried to start a new group",
            level: SentryLoggingLevel.Error,
          });
          return;
        }
        history.push(
          `/join/email-confirmation?email=${encodeURIComponent(
            params.email
          )}&groupId=${encodeURIComponent(data.signUp.groupId)}`
        );
      } catch (e) {
        setServerErr(e);
      }
    },
    [openConfirmEmailModal, signUp, partnerSlug, templateId, history]
  );

  if (partnerLoading) {
    return (
      <FullCenter>
        <spinners.BeatLoader />
      </FullCenter>
    );
  }

  if (serverErr) {
    throw serverErr;
  }
  const { email } = watch();
  return (
    <div className="flex bg-canvas-800 h-screen w-screen">
      <div className="relative w-full flex flex-col justify-center items-center">
        <a
          href="https://getfrank.com"
          className="hidden sm:block absolute top-0 left-0 mt-4 ml-6"
        >
          <Frank />
        </a>
        {workerOneOnboardingError && (
          <div className="mb-8">
            <InlineAlert
              dataCy="account-already-exists"
              actions={
                <Button
                  buttonStyle="outline"
                  size={Sizes.SM}
                  onClick={() => history.push("/login")}
                >
                  {t("goToLogin")}
                </Button>
              }
              intent={Intent.FAILURE}
              title={t("somethingWentWrong")}
            >
              {t("thisEmailIsAlreadyAssociatedWithAn")}
            </InlineAlert>
          </div>
        )}
        <div className="px-6 sm:px-0 sm:max-w-xs sm:mx-auto w-full flex flex-col justify-center items-center">
          {serverErr && (
            <div className="py-4 w-full">
              <InlineAlert
                intent={Intent.FAILURE}
                title={t("authenticationError")}
              >
                {t("thereWasAnErrorCreatingYourAccount")}
              </InlineAlert>
            </div>
          )}
          <div className="h-12 w-12 rounded-lg bg-canvas-700 flex flex-row justify-center items-center mb-4">
            <Avatar src={partner.logoAttachment.url} size={Sizes.LG} />
          </div>
          <div className="t-title-4 plus">
            {t("signUpForPartnerName", { partnerName: partner.name })}
          </div>
          <div>
            <span className="text-canvas-400">{t("haveAnAccount")}</span>{" "}
            <Link to="/login" className="text-brand-300 t-regular plus">
              {t("logIn")}
            </Link>
          </div>

          <form
            noValidate
            onSubmit={handleSubmit(signUpHandler)}
            className="pt-1 pb-3 w-full"
          >
            <div className="mb-6">
              <FormGroup id="email" label="" name="email" className="-mb-2">
                <Input
                  dataCy="email"
                  register={register}
                  errorText={
                    errors.email && (
                      <>
                        {errors.email.type === "required" &&
                          t("emailIsRequired")}
                        {errors.email.type === "pattern" && t("notAValidEmail")}
                      </>
                    )
                  }
                  registerArgs={{
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  }}
                  type="email"
                  disabled={loading}
                  placeholder={t("personalEmailAddress")}
                />
              </FormGroup>
            </div>

            <Button
              dataCy="sign-up"
              loading={loading}
              buttonStyle="brand"
              className="w-full"
              size={Sizes.LG}
              disabled={!email?.length}
            >
              {t("signUpForAFreeAccount")}
            </Button>
          </form>
        </div>
        <div className="hidden sm:block absolute bottom-0 w-full">
          <div className="flex flex-row justify-between pb-6 mx-6">
            <div className="py-0 text-white t-mini flex justify-center items-center">
              {t("getFrankPbc")}
            </div>
            <div className="flex flex-row justify-center space-x-6">
              <a
                rel="noreferrer"
                href="https://twitter.com/GetFrank_com"
                target="_blank"
                className="text-canvas-400 t-mini hover:text-white"
              >
                {t("twitter")}
              </a>
              <a
                rel="noreferrer"
                href="https://medium.com/@getfrank"
                target="_blank"
                className="text-canvas-400 t-mini hover:text-white"
              >
                {t("blog")}
              </a>
              <a
                rel="noreferrer"
                href="https://app.getfrank.com/legal/privacy"
                target="_blank"
                className="text-canvas-400 t-mini hover:text-white"
              >
                {t("privacy")}
              </a>
              <a
                rel="noreferrer"
                href="https://app.getfrank.com/contact"
                target="_blank"
                className="text-canvas-400 t-mini hover:text-white"
              >
                {t("contact")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;

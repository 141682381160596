import * as React from "react";
import {
  Button,
  FormGroup,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Select,
  Switch,
  useToaster,
} from "@get-frank-eng/design-system";
import { useForm } from "react-hook-form";
import { useModals } from "../../Modals";
import useGroupReport from "../dataAccess/hooks/queries/useGroupReport";
import useMoveWorker from "../dataAccess/hooks/mutations/useMoveWorker";
import { useTranslation } from "react-i18next";
import { FrankBackendTypes } from "frank-types";
import { useRemoveWorkerFromGroupMutation } from "../../generated-hooks";
import Sentry from "../../sentryClient";

type FormParams = {
  groupId: string;
};

const MoveWorkerModal = ({
  coworker,
  partnerId,
}: {
  partnerId: string;
  coworker: FrankBackendTypes.Coworker & { groupId: string; userId: string };
}) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { register, handleSubmit } = useForm<FormParams>();
  const { closeModal, openConfirmationModal } = useModals();
  const { groups, loading } = useGroupReport({ partnerId });
  const { moveWorker, loading: loadingMoveWorker } = useMoveWorker();
  const [dirty, setIsDirty] = React.useState(false);
  const [removeWorkerFromGroup, setRemoveWorkerFromGroup] = React.useState(
    false
  );

  const [
    workerCurrentGroup,
    setWorkerCurrentGroup,
  ] = React.useState<FrankBackendTypes.GroupReport>(null);

  const [
    invokeRemoveWorkerFromGroup,
    { error: removeWorkerFromGroupError },
  ] = useRemoveWorkerFromGroupMutation();

  React.useEffect(() => {
    if (groups) {
      setWorkerCurrentGroup(
        groups.objects.find((g) => g.id === coworker.groupId)
      );
    }
  }, [groups, setWorkerCurrentGroup, coworker]);

  // in the UI, we'll call it Delete - on the backend, we call it remove ... closer to how we're actually thinking about it in the code
  const submitRemoveWorkerFromGroup = async () => {
    const confirm = await openConfirmationModal({
      bodyText: t("confirmDeleteWorkerFromGroupBodyText", {
        workerName: coworker.name,
        groupName: workerCurrentGroup.groupName,
      }),
      actionText: t("confirm"),
      title: t("confirmDeleteWorkerFromGroupModalTitle", {
        workerName: coworker.name,
        groupName: workerCurrentGroup.groupName,
      }),
    });
    if (!confirm) {
      return;
    }
    try {
      await invokeRemoveWorkerFromGroup({
        variables: { coworkerId: coworker.id },
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("success"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("somethingWentWrong"),
      });
      Sentry.captureException(e);
    }

    if (removeWorkerFromGroupError) {
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("somethingWentWrong"),
      });
      Sentry.captureException(removeWorkerFromGroupError);
    }
    closeModal();
  };

  const submitMoveWorker = async (formData: FormParams) => {
    if (removeWorkerFromGroup) {
      return submitRemoveWorkerFromGroup();
    }

    try {
      const confirm = await openConfirmationModal({
        bodyText: t("youWillNotBeAbleToUndoThisAndMo") as string,
        actionText: t("confirm"),
        title: t("areYouSureYouWantToMoveThisWorke"),
      });
      if (!confirm) {
        return;
      }

      await moveWorker({
        userId: coworker.userId,
        groupId: formData.groupId,
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("successfullyMovedWorker"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrongMovingThisWorker"),
      });
    }
    closeModal();
  };

  if (loading) {
    return null;
  }

  const form = (
    <form
      onSubmit={handleSubmit(submitMoveWorker)}
      noValidate
      className="space-y-8"
      id="move-worker-form"
    >
      {!removeWorkerFromGroup && (
        <section>
          <div className="t-small plus pb-1">{t("moveWorker")}</div>
          <FormGroup name="groupId" label={t("chooseGroup")} id="groupId">
            <Select
              options={groups.objects.map((o) => ({
                value: o.id,
                label: o.groupName,
                selected: o.id === coworker.groupId,
              }))}
              register={register}
              id="groupId"
              defaultValue={coworker.groupId}
              helpText={t("clickingSaveWillMoveThisWorkerToT") as string}
              onChange={() => setIsDirty(true)}
            />
          </FormGroup>
        </section>
      )}
      <div>
        <div className="t-small plus pb-4">{t("deleteWorker")}</div>
        <div className="flex justify-between">
          <div>
            <div className="t-small pb-1">
              {t("deleteWorkerNameFromGroupName", {
                coworkerName: coworker.name,
                groupName: workerCurrentGroup?.groupName,
              })}
            </div>
            <div className="t-mini text-canvas-400">
              {t("deleteWorkerHelpText", {
                groupName: workerCurrentGroup?.groupName,
              })}
            </div>
          </div>

          <Switch
            onChange={() => {
              setRemoveWorkerFromGroup(!removeWorkerFromGroup);
              setIsDirty(true);
            }}
            checked={removeWorkerFromGroup}
            label={`Remove worker from ${workerCurrentGroup?.groupName}`}
            id="remove-worker-switch"
          />
        </div>
      </div>
    </form>
  );

  return (
    <>
      <ModalHeader title={t("moveWorkerToADifferentGroup")} />
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        <Button
          form="move-worker-form"
          buttonStyle="brand"
          disabled={!dirty}
          loading={loadingMoveWorker}
        >
          {t("save")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default MoveWorkerModal;

import React from "react";
import { Avatar } from "@get-frank-eng/design-system/dist/components/Avatar";
import { Sizes } from "@get-frank-eng/design-system/dist/constants";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { useTranslation } from "react-i18next";

const LeadOrganzer = () => {
  const { t } = useTranslation();
  const { currentGroup } = useCurrentUserData();
  const leadOrganizer = currentGroup.leadOrganizer;
  if (!leadOrganizer) {
    return null;
  }
  return (
    <div className="w-full text-left align-top py-2 px-2 h-full items-center border rounded border-canvas-500 flex">
      <a
        className="flex w-full space-x-2 p-2 lg:flex-col lg:items-center"
        href={`/users/${leadOrganizer.user.id}`}
      >
        <div className="px-3 sm:flex">
          {leadOrganizer.user.profilePic && (
            <Avatar size={Sizes.XL} src={leadOrganizer.user.profilePic.url} />
          )}
        </div>
        <div className="flex-col pt-1 lg:pt-2">
          <div className="lg:text-center pb-1">
            {currentGroup.leadOrganizer.user.name}
          </div>
          <div className="text-canvas-400 t-small">{t("leadOrganizer")}</div>
        </div>
      </a>
    </div>
  );
};

export default LeadOrganzer;

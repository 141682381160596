import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import { nanoid } from "nanoid";
import * as React from "react";
import copyText from "../../../../utils/copyText";
import { generateFutureDate } from "../../../../utils/Date";

const CREATE_INVITE_LINK = gql`
  mutation CreateInviteLink($input: CreateInviteLinkDTO!) {
    createInviteLink(input: $input) {
      id
      token
      maxSeats
      passphrase
      expiration
      expiresIn
    }
  }
`;

export const useCreateInviteLink = ({
  campaignId,
}: {
  campaignId?: string;
}) => {
  const [token] = React.useState(nanoid());
  const [invokeCreateInviteLink, { loading, error }] = useMutation<
    { createInviteLink: FrankBackendTypes.Invite },
    FrankBackendTypes.MutationCreateInviteLinkArgs
  >(CREATE_INVITE_LINK);

  const [
    inviteData,
    setInviteData,
  ] = React.useState<null | FrankBackendTypes.Invite>(null);

  const createInviteLink = React.useCallback(
    async (data: {
      maxSeats?: number;
      passphrase?: string;
      expiration?: string;
      groupId?: string;
    }) => {
      const input: FrankBackendTypes.CreateInviteLinkDto = {
        token,
        campaignId,
        maxSeats: +data.maxSeats,
        passphrase: data.passphrase,
        expiration: data.expiration
          ? generateFutureDate(new Date(), +data.expiration)
          : undefined,
        // going to hard code this for now -- in the future, might want to have people be able to adjust this
        netTrustNeeded: 2,
      };
      const { data: mutationData } = await invokeCreateInviteLink({
        variables: { input },
        context: { groupId: data.groupId },
      });
      setInviteData(mutationData.createInviteLink);
    },
    [token, campaignId, invokeCreateInviteLink]
  );

  return {
    inviteData,
    loading,
    createInviteLink,
    token,
  };
};

export const linkGen = (id) =>
  `${process.env.REACT_APP_FRONTEND_URL}${process.env.REACT_APP_ONBOARDING_URL}?token=${id}`;

export function useShareOrCopyAndCreateInvite({
  campaignId,
  supportsShare,
  isMobile,
}: {
  campaignId?: string;
  supportsShare: boolean;
  isMobile: boolean;
}) {
  const {
    createInviteLink: original,
    loading,
    inviteData,
    token,
  } = useCreateInviteLink({ campaignId });

  const url = linkGen(token);

  const createInviteLink = React.useCallback(
    async (data: {
      maxSeats?: number;
      groupId?: string;
      passphrase?: string;
      expiration?: string;
    }) => {
      copyText(url);
      await original(data);
      if (supportsShare && isMobile) {
        try {
          await navigator.share({
            title: "Join me on Frank",
            url,
          });
        } catch (e) {
          console.error("Error using native share", e);
        }
      }
    },
    [original]
  );

  return { createInviteLink, loading, inviteData, token, url };
}

import { gql } from "@apollo/client";
const attachmentFragment = gql`
  fragment AttachmentFragment on Attachment {
    id
    url
    filename
    humanReadableSize
    fileType
    currentUserCanRemove
    deletedAt
    user {
      id
      name
    }
    thumbnail {
      id
      url
    }
    preview {
      title
      subtitle
      imageUrl
      url
    }
  }
`;

export default attachmentFragment;

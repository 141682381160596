import { gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import client from "../../../gqlClient";

export async function fetchNavigation() {
  const { data, errors } = await client.query<{
    navigation: FrankBackendTypes.Navigation;
  }>({
    query: gql`
      query Navigation {
        navigation {
          incompleteTours
          sections {
            identifier
            isOpen
            title
            isTitleVisible
            closeableSectionName
            addable
            addLabel
            items {
              navigationType
              subjectType
              label
              channelId
              identifier
              badge
            }
          }
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return {
    navigation: data.navigation,
    errors,
  };
}

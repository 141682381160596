import {
  Button,
  FormGroup,
  Input,
  Intent,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import camelCase from "lodash/camelCase";
import useCreateOnboardingForm from "../dataAccess/hooks/mutations/useCreateOnboardingForm";
import usePartner from "../../SSO/dataAccess/queries/usePartner";
import useDeleteOnboardingForm from "../dataAccess/hooks/mutations/useDeleteOnboardingForm";
import { useModals } from "../../Modals";
import { useTranslation } from "react-i18next";

type Field = {
  maxLength?: number;
  minLength?: number;
  description?: string;
  title: string;
  type: "string";
};

// I know this is awful. But hopefully it will be deleted soon.
const createFormProperty = (
  formData: FormData,
  number
): { [index: string]: Field } => {
  let data: any = {};
  const fieldKeys = Object.keys(formData).filter((k) => k.includes(number));

  for (const key of fieldKeys) {
    const [name, rest] = key.split("-");
    const value = formData[key];
    if (!!value) {
      if (name === "maxLength" || name === "minLength") {
        data[name] = +formData[key];
      } else {
        data[name] = formData[key];
      }
    }
  }

  const propertyName = camelCase(data.title);

  if (!propertyName.length) {
    return {};
  }

  const toReturn = {
    [propertyName]: {
      ...data,
      type: "string",
    },
  };

  if (toReturn.maxLength) {
    toReturn.maxLength = +toReturn.maxLength;
  }

  return toReturn;
};

// this is very bad. bad Emily.
type FormData = {
  "title-1": string;
  "maxLength-1": number;
  "minLength-1": number;
  "description-1": string;
  "title-2": string;
  "maxLength-2": number;
  "minLength-2": number;
  "description-2": string;
  "title-3": string;
  "maxLength-3": number;
  "minLength-3": number;
  "description-3": string;
};

export default function AddCustomForm({
  partnerSlug,
}: {
  partnerSlug: string;
}) {
  const { t } = useTranslation();
  const { partner, loading: partnerLoading, refetch } = usePartner({
    partnerSlug,
  });
  const { createOnboardingForm, loading } = useCreateOnboardingForm();
  const { register, handleSubmit } = useForm();
  const toaster = useToaster();
  const {
    deleteOnboardingForm,
    loading: deleteLoading,
  } = useDeleteOnboardingForm();
  const { openConfirmationModal } = useModals();

  const formProperties = React.useMemo(() => {
    const parsed = JSON.parse(partner?.onboardingFormJSON);
    if (parsed) {
      return parsed.properties;
    }
    return [];
  }, [partner]);

  const submitDelete = React.useCallback(
    async (partnerId) => {
      try {
        const confirm = await openConfirmationModal({
          bodyText: t("thisCannotBeUndone") as string,
          actionText: t("confirm"),
          title: t("areYouSureYouWantToDeleteThisFor"),
        });
        if (!confirm) {
          return;
        }

        await deleteOnboardingForm(partnerId);
        await refetch();
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("successfullyDeletedForm"),
        });
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrong"),
        });
      }
    },
    [openConfirmationModal, deleteOnboardingForm, toaster, refetch, t]
  );

  const submit = React.useCallback(
    async (formData: FormData) => {
      let toSubmit;

      for (const number of ["1", "2", "3"]) {
        toSubmit = { ...toSubmit, ...createFormProperty(formData, number) };
      }

      try {
        await createOnboardingForm({
          onboardingFormFields: JSON.stringify(toSubmit),
          partnerId: partner.id,
        });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("successfullyCreatedForm"),
        });
        await refetch();
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrong"),
        });
      }
    },
    [createOnboardingForm, partner, toaster, refetch, t]
  );

  if (partnerLoading) {
    return null;
  }

  if (partner.onboardingFormJSON) {
    return (
      <div>
        <div className="t-large plus">{t("currentForm")}</div>
        <div className=" mt-6 space-y-2 mb-16 border rounded overflow-x-auto">
          <table className="text-sm w-full">
            <thead>
              <tr className="border-b text-xs divide-x divide-canvas-400">
                <th className="text-left p-2 bg-canvas-700">{t("field")}</th>
                <th className="text-left p-2 bg-canvas-700">
                  {t("description")}
                </th>
                <th className="text-left p-2 bg-canvas-700">
                  {t("maxLength")}
                </th>
                <th className="text-left p-2 bg-canvas-700">
                  {t("minLength")}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-canvas-400">
              {Object.keys(formProperties).map((property) => {
                return (
                  <tr className="divide-x divide-canvas-400" key={property}>
                    <td className="p-2">{formProperties[property].title}</td>
                    <td className="p-2">
                      {formProperties[property].description}
                    </td>

                    <td className="p-2">
                      {formProperties[property].maxLength}
                    </td>
                    <td className="p-2">
                      {formProperties[property].minLength}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Button
          buttonStyle="brand"
          loading={deleteLoading}
          onClick={async () => await submitDelete(partner.id)}
        >
          {t("removeForm")}
        </Button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-8 mb-4 max-w-md">
      {["1", "2", "3"].map((number) => (
        <div className="space-y-2">
          <div className="t-large plus">{t("fieldNumberOf3", { number })}</div>

          <FormGroup
            name={`title-${number}`}
            label={t("fieldName")}
            id={`title-${number}`}
          >
            <Input
              register={register}
              id={`title-${number}`}
              maxLength={120}
              placeholder={t("theNameOfTheField")}
            />
          </FormGroup>
          <FormGroup
            name={`description-${number}`}
            label={t("description")}
            id={`description-${number}`}
          >
            <Input
              register={register}
              id={`description-${number}`}
              maxLength={400}
              placeholder={t("additionalInformationAboutThisField")}
              helpText="optional"
            />
          </FormGroup>
          <FormGroup
            name={`maxLength-${number}`}
            label={t("maxValue")}
            id={`maxLength-${number}`}
          >
            <Input
              type="number"
              register={register}
              id={`maxLength-${number}`}
              maxLength={600}
              placeholder={t("maximumNumberOfCharactersForField")}
              helpText="optional"
            />
          </FormGroup>
          <FormGroup
            name={`minLength-${number}`}
            label={t("minValue")}
            id={`minLength-${number}`}
          >
            <Input
              type="number"
              register={register}
              id={`minLength-${number}`}
              maxLength={200}
              placeholder={t("minimumNumberOfCharactersForField")}
              helpText="optional"
            />
          </FormGroup>
        </div>
      ))}

      <Button type="submit" buttonStyle="brand" loading={loading}>
        {t("save")}
      </Button>
    </form>
  );
}

import { useChannels } from "@get-frank-eng/chat-client";
import { AbilityAction, ChatTypes } from "frank-types";
import {
  Icon,
  Menu,
  MenuItem,
  ScreenSizes,
  Sidebar,
  SidebarDivider,
  SidebarFooter,
  SidebarItem,
  SidebarSection,
  Sizes,
  useLayout,
  useScreenSize,
  useToggle,
} from "@get-frank-eng/design-system";
import {
  MenuDivider,
  NonInteractiveMenuItem,
} from "@get-frank-eng/design-system/dist/components/menus/MenuItem";
import keyBy from "lodash/keyBy";
import * as React from "react";
import { useCurrentUserData } from "../Auth/useCurrentUserData";
import { useModals } from "../Modals";
import { FrankBackendTypes } from "frank-types";
import useSidebarSectionOpen from "./dataAccess/mutations/useSidebarSectionOpen";
import { useHistory } from "react-router";
import useSession from "../Auth/useSession";
import { useNavigationState } from "./hooks/useNavigationState";
import useRetakeTour from "../Tour/dataAccess/useRetakeTour";
import { useCurrentTourContext } from "../Tour/CurrentTourProvider";
import {
  AddToHomeScreenModal,
  useInstallPromptContext,
} from "../providers/InstallPromptProvider";
import { find } from "lodash";
import { useTranslation } from "react-i18next";

function getPath() {
  const url = new URL(window.location.href);

  const { pathname } = url;

  return pathname;
}

function getUrlForNavigationItem(
  item: FrankBackendTypes.NavigationItem,
  {
    partner,
    group,
  }: { partner: FrankBackendTypes.Partner; group: FrankBackendTypes.Group }
): string {
  switch (item.subjectType) {
    case FrankBackendTypes.NavigationSubjectTypes.Home:
      return "/home";
    case FrankBackendTypes.NavigationSubjectTypes.DraftCampaigns:
      return "/campaigns/drafts";
    case FrankBackendTypes.NavigationSubjectTypes.Group:
      return "/coworkers";
    case FrankBackendTypes.NavigationSubjectTypes.Campaign:
      return `/campaigns/${item.identifier}`;
    case FrankBackendTypes.NavigationSubjectTypes.PublicTopic:
      return `/topics/${item.identifier}`;
    case FrankBackendTypes.NavigationSubjectTypes.PrivateTopic:
      return `/topics/${item.identifier}`;
    case FrankBackendTypes.NavigationSubjectTypes.FromFrank:
      return `/from-welcomebot`;
    case FrankBackendTypes.NavigationSubjectTypes.CreateCampaign:
      return "/templates";
    case FrankBackendTypes.NavigationSubjectTypes.FrankAdmin:
      return "/partners";
    case FrankBackendTypes.NavigationSubjectTypes.PartnerAdmin:
      return `/partners/${partner.slug}/groups`;
    case FrankBackendTypes.NavigationSubjectTypes.GroupSettings:
      return `/partners/${partner.slug}/groups/${group.id}`;

    default:
      throw new Error(
        `${item.subjectType} not supported for getUrlForNavigationItem`
      );
  }
}

function useGetOnClickForNavigationItem({
  refetch,
  onClickInstall,
  showIosInstall,
  isSafari,
}: {
  refetch: () => void;
  onClickInstall: () => Promise<void>;
  showIosInstall: boolean;
  isSafari: boolean;
}) {
  const { setModal, closeModal } = useModals();
  return React.useCallback(
    (item: FrankBackendTypes.NavigationItem) => {
      switch (item.subjectType) {
        case FrankBackendTypes.NavigationSubjectTypes.CreateTopic:
          return setModal({
            type: "createTopicModal",
            props: {
              afterSubmit: refetch,
            },
          });
        case FrankBackendTypes.NavigationSubjectTypes.Invite:
          return setModal({ type: "defaultInviteModal", props: {} });
        case FrankBackendTypes.NavigationSubjectTypes.Help:
          return; //open intercom todo
        case FrankBackendTypes.NavigationSubjectTypes.InstallApp:
          if (showIosInstall) {
            return setModal({
              type: "genericModal",
              props: {
                title: "Install Frank",
                body: (
                  <AddToHomeScreenModal
                    onClose={closeModal}
                    isSafari={isSafari}
                  />
                ),
              },
            });
          }
          return onClickInstall();
        default:
          throw new Error(
            `${item.subjectType} not supported for useGetOnClickForNavigationItem`
          );
      }
    },
    [setModal, refetch, onClickInstall, closeModal, showIosInstall]
  );
}

function isActive(
  item: FrankBackendTypes.NavigationItem,
  path: string
): boolean {
  switch (item.subjectType) {
    case FrankBackendTypes.NavigationSubjectTypes.Home:
      return path.includes("/home");
    case FrankBackendTypes.NavigationSubjectTypes.DraftCampaigns:
      return path.includes("/campaigns/drafts");
    case FrankBackendTypes.NavigationSubjectTypes.Group:
      return path.includes("/coworkers");
    case FrankBackendTypes.NavigationSubjectTypes.Campaign:
      return path.includes(`/campaigns/${item.identifier}`);
    case FrankBackendTypes.NavigationSubjectTypes.PublicTopic:
      return path.includes(`/topics/${item.identifier}`);
    case FrankBackendTypes.NavigationSubjectTypes.PrivateTopic:
      return path.includes(`/topics/${item.identifier}`);
    case FrankBackendTypes.NavigationSubjectTypes.FromFrank:
      return path.includes(`/from-welcomebot`);
    case FrankBackendTypes.NavigationSubjectTypes.Invite:
      return false;
    case FrankBackendTypes.NavigationSubjectTypes.FrankAdmin:
      return path.includes("partners");
    case FrankBackendTypes.NavigationSubjectTypes.PartnerAdmin:
      return path.includes("partners");
    default:
      return false;
  }
}

function useCloseMobileNavOnPageTransition() {
  const history = useHistory();
  const { mobilePanel, setMobilePanel } = useLayout();
  const { stackSize } = useModals();
  const screensize = useScreenSize();

  React.useEffect(() => {
    if (screensize > ScreenSizes.MD) {
      return;
    }
    if (mobilePanel !== "left") {
      return;
    }
    if (stackSize > 0) {
      setMobilePanel(null);
    }
  }, [stackSize, setMobilePanel, screensize, mobilePanel]);

  const respondToPageTransition = React.useCallback(() => {
    if (screensize > ScreenSizes.MD) {
      return;
    }
    if (mobilePanel !== "left") {
      return;
    }
    setMobilePanel(null);
  }, [screensize, setMobilePanel, mobilePanel]);

  React.useEffect(() => {
    const unlisten = history.listen(respondToPageTransition);
    return unlisten;
  }, [history, respondToPageTransition]);
}

const Section = ({
  section,
  channelsById,
  refetch,
}: {
  section: FrankBackendTypes.NavigationSection;
  channelsById: { [channlId: string]: ChatTypes.Channel };
  refetch: () => void;
}) => {
  const { open, close } = useSidebarSectionOpen();
  const [isOpen, toggleLocalOpenState] = useToggle(section.isOpen);
  const { partner, currentGroup: group } = useCurrentUserData();
  const { setModal } = useModals();
  const history = useHistory();
  const toggleOpen = React.useCallback(() => {
    toggleLocalOpenState();
    if (isOpen) {
      close(section.closeableSectionName);
    } else {
      open(section.closeableSectionName);
    }
  }, [toggleLocalOpenState, open, close, isOpen, section]);
  const {
    showInstallPromo,
    onClickInstall,
    isMobileIos,
    standaloneMode,
    isSafari,
  } = useInstallPromptContext();

  const onClick = useGetOnClickForNavigationItem({
    refetch,
    onClickInstall,
    showIosInstall: isMobileIos && !standaloneMode,
    isSafari,
  });

  const path = getPath();

  return (
    <SidebarSection
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      header={section.isTitleVisible ? section.title : null}
      onAdd={
        {
          Topics: () =>
            setModal({
              type: "createTopicModal",
              props: { afterSubmit: refetch },
            }),
          Campaigns: () => history.push("/templates"),
        }[section.title]
      }
      key={section.title}
    >
      {section.items
        .filter((item) => {
          const showIosInstall = isMobileIos && !standaloneMode;
          if (
            item.subjectType ===
            FrankBackendTypes.NavigationSubjectTypes.InstallApp
          ) {
            if (!showInstallPromo && !showIosInstall) {
              return false;
            }
          }
          return true;
        })
        .map((item) => {
          const channel = item.channelId && channelsById[item.channelId];
          return (
            <SidebarItem
              id={
                item.subjectType ===
                FrankBackendTypes.NavigationSubjectTypes.Help
                  ? "intercom-launcher"
                  : item.subjectType
              }
              active={isActive(item, path)}
              className={item.subjectType}
              icon={
                {
                  [FrankBackendTypes.NavigationSubjectTypes.Campaign]:
                    "FileText",
                  [FrankBackendTypes.NavigationSubjectTypes.CreateTopic]:
                    "Plus",
                  [FrankBackendTypes.NavigationSubjectTypes.CreateCampaign]:
                    "Plus",
                  [FrankBackendTypes.NavigationSubjectTypes.PublicTopic]:
                    "Hash",
                  [FrankBackendTypes.NavigationSubjectTypes.PrivateTopic]:
                    "Lock",
                  [FrankBackendTypes.NavigationSubjectTypes.Home]: "Home",
                  [FrankBackendTypes.NavigationSubjectTypes.DraftCampaigns]:
                    "File",
                  [FrankBackendTypes.NavigationSubjectTypes.Invite]: "UserPlus",
                  [FrankBackendTypes.NavigationSubjectTypes.Help]:
                    "MessageSquare",
                  [FrankBackendTypes.NavigationSubjectTypes.Group]: "Users",
                  [FrankBackendTypes.NavigationSubjectTypes.FromFrank]: "Inbox",
                  [FrankBackendTypes.NavigationSubjectTypes.FrankAdmin]:
                    "Settings",
                  [FrankBackendTypes.NavigationSubjectTypes.PartnerAdmin]:
                    "Settings",

                  [FrankBackendTypes.NavigationSubjectTypes.GroupSettings]:
                    "Settings",
                  [FrankBackendTypes.NavigationSubjectTypes.InstallApp]:
                    "Download",
                }[item.subjectType] || "Hash"
              }
              key={item.identifier}
              onClick={
                item.navigationType ===
                FrankBackendTypes.NavigationItemType.Button
                  ? () => onClick(item)
                  : undefined
              }
              to={
                item.navigationType ===
                FrankBackendTypes.NavigationItemType.Link
                  ? getUrlForNavigationItem(item, { group, partner })
                  : undefined
              }
              unread={item.channelId ? channel?.unreadCount > 0 : item.unread}
              badge={item.channelId ? channel?.importantCount > 0 : item.badge}
            >
              {item.label}
            </SidebarItem>
          );
        })}
    </SidebarSection>
  );
};

const AccountMenuItemWrapper = ({ children }) => {
  return <div className="px-4 py-1.5">{children}</div>;
};

export default function AppSidebar() {
  const {
    currentGroup,
    myProfile,
    partner,
    notCurrentGroups,
    ability,
    unseenActivityForGroups,
    preferredLanguage,
  } = useCurrentUserData();
  const {
    navigation,
    graphQLErrors,
    networkError,
    refetch,
  } = useNavigationState();
  const { channels } = useChannels();
  const channelsById = React.useMemo(() => keyBy(channels, "id"), [channels]);
  const { setModal } = useModals();
  const { switchGroup } = useSession({});
  const { retakeTour } = useRetakeTour();
  const { setRetakingTour, setCurrentTour } = useCurrentTourContext();
  const { t } = useTranslation();

  useCloseMobileNavOnPageTransition();

  React.useEffect(() => {
    refetch();
  }, [channels, refetch]);

  if (graphQLErrors.length || networkError) {
    if (!navigation) {
      console.error(
        "Sidebar error: there is no data",
        "GraphQLError:",
        graphQLErrors,
        "networkError",
        networkError
      );
      throw new Error("No nav sections");
    }
  }

  if (!navigation) {
    return null;
  }

  if (!currentGroup || !myProfile) {
    return null;
  }

  return (
    <>
      <Sidebar
        createNewGroupMenuItem={
          ability.can(AbilityAction.Create, "Group") ? (
            <MenuItem
              onClick={async () => {
                setModal({
                  type: "createGroupModal",
                  props: {
                    afterSubmit: (id) =>
                      switchGroup(
                        id,
                        `${process.env.REACT_APP_FRONTEND_URL}/partners/${partner.slug}/groups/${id}`
                      ),
                  },
                });
              }}
            >
              <div className="flex flex-row items-center">
                <Icon icon="add" size={Sizes.MD} classNames="pr-2" />
                {t("createAGroup")}
              </div>
            </MenuItem>
          ) : undefined
        }
        switchGroups={switchGroup}
        groupLogoUrl={currentGroup.logo?.url}
        headerLinkTo={
          notCurrentGroups.length === 0 &&
          !ability.can(AbilityAction.Create, "Group")
            ? "/account/group"
            : undefined
        }
        // userName={myProfile.name}
        // userAvatarUrl={myProfile.profilePic?.url}

        footer={
          <SidebarFooter
            lng={preferredLanguage}
            menu={
              <Menu>
                <NonInteractiveMenuItem>
                  <div className="px-2">
                    {t("signedInAs")}{" "}
                    <div className="text-canvas-400 truncate">
                      {myProfile?.email}
                    </div>
                  </div>
                </NonInteractiveMenuItem>
                <MenuDivider />
                <MenuItem
                  pad={false}
                  onClick={() =>
                    setModal({ type: "defaultInviteModal", props: {} })
                  }
                >
                  <AccountMenuItemWrapper>
                    {t("inviteCoworkers")}
                  </AccountMenuItemWrapper>
                </MenuItem>
                <MenuItem id="second-intercom-launcher" pad={false}>
                  <AccountMenuItemWrapper>
                    {t("helpAndFeedback")}
                  </AccountMenuItemWrapper>
                </MenuItem>
                <MenuItem className="intercom-launcher" pad={false}>
                  <AccountMenuItemWrapper>
                    {t("deleteAccount")}
                  </AccountMenuItemWrapper>
                </MenuItem>
                <MenuDivider />
                <MenuItem pad={false} to={`/users/${myProfile?.id}`}>
                  <AccountMenuItemWrapper>
                    {t("viewProfile")}
                  </AccountMenuItemWrapper>
                </MenuItem>
                <MenuItem pad={false} to="/account">
                  <AccountMenuItemWrapper>
                    {t("settings")}
                  </AccountMenuItemWrapper>
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  pad={false}
                  onClick={() => {
                    setRetakingTour(true);
                    retakeTour(FrankBackendTypes.TourType.Welcome);
                    setCurrentTour(FrankBackendTypes.TourType.Welcome);
                    refetch();
                  }}
                >
                  <AccountMenuItemWrapper>
                    {t("showProductTour")}
                  </AccountMenuItemWrapper>
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  pad={false}
                  to="/logout"
                  className="text-frank-red-300"
                >
                  <AccountMenuItemWrapper>
                    {t("signOut")}
                  </AccountMenuItemWrapper>
                </MenuItem>
              </Menu>
            }
            name={myProfile?.name}
            avatarUrl={myProfile?.profilePic?.url}
          />
        }
        groupName={currentGroup.name}
        otherGroups={notCurrentGroups.map((group) => {
          const activity = find(
            unseenActivityForGroups,
            (g) => g.groupId === group.id
          );
          return {
            name: group.name,
            id: group.id,
            unseenGroupActivity:
              activity?.unseenChatActivity || activity?.unseenNotifications,
          };
        })}
      >
        {navigation.sections.map((section, i) => {
          const isLast = i === navigation.sections.length - 1;
          return (
            <React.Fragment key={section.title}>
              <Section
                refetch={refetch}
                channelsById={channelsById}
                section={section}
              />
              {!isLast && <SidebarDivider />}
            </React.Fragment>
          );
        })}
      </Sidebar>
    </>
  );
}

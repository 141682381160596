import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query AuthorizationCard(
    $signaturePagination: PaginationArgumentDTO!
    $groupId: ID!
    $unsignedMembersPagination: PaginationArgumentDTO!
  ) {
    authorizationCard(groupId: $groupId) {
      id
      createdAt
      language
      additionalFields
      signatures(pagination: $signaturePagination) {
        hasNext
        total
        objects {
          id
          firstName
          lastName
          createdAt
          employer
          email
          phone
          authCardFormResponseJSON
          confirmedByUser
          coworkerId
        }
      }
      unsignedMembers(pagination: $unsignedMembersPagination) {
        hasNext
        total
        objects {
          id
          name
          email
          joinedAt
        }
      }
    }
  }
`;

export default function useAuthorizationCard(groupId) {
  const perPage = 25;

  const { loading, error, data, refetch, fetchMore } = useQuery<
    Pick<FrankBackendTypes.Query, "authorizationCard">,
    FrankBackendTypes.QueryAuthorizationCardArgs & {
      signaturePagination: { page: number; perPage: number };
    } & {
      unsignedMembersPagination: { page: number; perPage: number };
    }
  >(QUERY, {
    variables: {
      signaturePagination: {
        page: 0,
        perPage,
      },
      unsignedMembersPagination: {
        page: 0,
        perPage,
      },
      groupId,
    },
  });

  const nextPageSignatures = React.useCallback(() => {
    return fetchMore({
      variables: {
        signaturePagination: {
          page: Math.round(
            data?.authorizationCard.signatures.objects.length / perPage
          ),
          perPage,
        },
      },
    });
  }, [fetchMore, data]);

  const nextPageUnsignedMembers = React.useCallback(() => {
    return fetchMore({
      variables: {
        unsignedMembersPagination: {
          page: Math.round(
            data?.authorizationCard.unsignedMembers.objects.length / perPage
          ),
          perPage,
        },
      },
    });
  }, [fetchMore, data]);

  return {
    authorizationCard: data?.authorizationCard,
    loading: loading,
    error: error,
    refetch,
    nextPageSignatures,
    nextPageMembers: nextPageUnsignedMembers,
  };
}

import {
  Button,
  Card,
  EmptyState,
  FormGroup,
  IconButton,
  Intent,
  MenuItem,
  Select,
  TaskCard,
  useToaster,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import Loading from "../../components/Loading";
import { useModals } from "../../Modals";
import { dateFormatter } from "../../utils/Date";
import { TaskActions } from "../components/TaskActions";
import TaskMenu from "../components/TaskMenu";
import useUpdateTaskAssignment from "../dataAccess/mutations/useUpdateTask";
import useTaskAssignments from "../dataAccess/queries/useTaskAssignments";
import useTasks from "../dataAccess/queries/useTasks";
import { useOnboardingTasksQuery } from "../../generated-hooks";
import useRemoveScheduledTask from "../../Tasks/dataAccess/mutations/useRemoveScheduledTask";
import { useTranslation } from "react-i18next";

const ListTasks = () => {
  const { preferredLanguage } = useCurrentUserData();
  const { t } = useTranslation();
  const { myProfile, currentGroup } = useCurrentUserData();
  const { setModal, openConfirmationModal } = useModals();
  const toaster = useToaster();

  const [groupId, setGroupId] = React.useState(currentGroup.id);

  const { removeScheduledTask } = useRemoveScheduledTask();

  const {
    taskAssignments,
    loading: loadingAssignments,
    error: errorAssignments,
    refetch: refetchAssignments,
    nextPage: nextPageAssignments,
  } = useTaskAssignments({ groupId });

  const {
    tasks,
    loading: loadingTasks,
    error: errorTasks,
    refetch: refetchTasks,
    nextPage: nextPageTasks,
  } = useTasks();

  const {
    updateTaskAssignment,
    loading: loadingUpdateAssignment,
  } = useUpdateTaskAssignment();

  const {
    data,
    loading: loadingOnboardingTasks,
    refetch: refetchOnboardingTasks,
  } = useOnboardingTasksQuery({
    variables: {
      groupId,
    },
  });

  const updateAssignment = async ({
    assignment,
    workflowState,
    completedAt,
  }: {
    assignment: FrankBackendTypes.TaskAssignment;
    workflowState: FrankBackendTypes.AssignmentWorkflowState;
    completedAt?: Date;
  }) => {
    try {
      await updateTaskAssignment({
        assignmentId: assignment.id,
        workflowState,
        completedAt,
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("success"),
      });
      await refetchAssignments();
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
    }
  };

  const launchTaskAssignmentModal = ({
    refetchAssignments,
    groupId,
    existingTask,
    refetchTasks,
  }: {
    refetchAssignments: () => void;
    groupId: string;
    existingTask?: FrankBackendTypes.Task;
    refetchTasks: () => void;
  }) => {
    setModal({
      type: "createTaskModal",
      props: {
        existingTask,
        groupId,
        afterSubmit: () => {
          refetchAssignments();
          refetchTasks();
        },
      },
    });
  };

  const getColor = (status: FrankBackendTypes.AssignmentWorkflowState) => {
    if (status === FrankBackendTypes.AssignmentWorkflowState.Cancelled) {
      return "text-frank-red-300";
    }
    if (status === FrankBackendTypes.AssignmentWorkflowState.Complete) {
      return "text-frank-green-300";
    }
    if (status === FrankBackendTypes.AssignmentWorkflowState.Incomplete) {
      return "text-frank-gold-300";
    }
  };

  const getStatusText = (status: FrankBackendTypes.AssignmentWorkflowState) => {
    if (status === FrankBackendTypes.AssignmentWorkflowState.Cancelled) {
      return "Cancelled";
    }
    if (status === FrankBackendTypes.AssignmentWorkflowState.Complete) {
      return "Complete";
    }
    if (status === FrankBackendTypes.AssignmentWorkflowState.Incomplete) {
      return "Incomplete";
    }
  };

  return (
    <div className="space-y-8 pb-48">
      <section className="flex flex-row space-x-8 items-center">
        <FormGroup name="groupId" label={t("chooseGroup")} id="groupId">
          <Select
            options={myProfile.groups.map((g) => ({
              value: g.id,
              label: g.name,
              selected: g.id === groupId,
            }))}
            id="groupId"
            placeholder={t("selectAGroup")}
            defaultValue={currentGroup.id}
            onChange={(e) => setGroupId(e.currentTarget.value)}
            helpText={t("selectAGroupToManageItsTasks")}
          />
        </FormGroup>
      </section>
      {loadingAssignments ? (
        <Loading />
      ) : (
        <section className="pt-8 space-y-4">
          <div className="t-large plus">{t("assignments")}</div>
          {taskAssignments.objects.length ? (
            <div className="overflow-x-auto rounded border">
              <table className="w-full">
                <thead>
                  <tr className="border-b-2">
                    <th className="p-2"></th>
                    <th className="text-left p-2">{t("status")}</th>
                    <th className="text-left p-2">{t("title")}</th>
                    <th className="text-left p-2">{t("assignedTo")}</th>
                    <th className="text-left p-2">{t("assignedBy")}</th>
                    <th className="text-left p-2">{t("dateAssigned")}</th>
                    <th className="text-left p-2">{t("due")}</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-canvas-500">
                  {taskAssignments.objects?.map((a) => {
                    const incomplete =
                      a.workflowState ===
                      FrankBackendTypes.AssignmentWorkflowState.Incomplete;
                    return (
                      <tr key={a.id}>
                        <td className="p-2">
                          <div className="flex flex-row space-x-2 items-center justify-end">
                            {incomplete && (
                              <IconButton
                                icon="cancel"
                                title={t("cancel")}
                                onClick={async () =>
                                  await updateAssignment({
                                    assignment: a,
                                    workflowState:
                                      FrankBackendTypes.AssignmentWorkflowState
                                        .Cancelled,
                                    completedAt: null,
                                  })
                                }
                              />
                            )}
                            {incomplete ? (
                              <IconButton
                                icon="check"
                                title={t("markComplete")}
                                onClick={async () =>
                                  await updateAssignment({
                                    assignment: a,
                                    workflowState:
                                      FrankBackendTypes.AssignmentWorkflowState
                                        .Complete,
                                    completedAt: new Date(),
                                  })
                                }
                                loading={loadingUpdateAssignment}
                              />
                            ) : (
                              <IconButton
                                icon="replay"
                                title={t("reopen")}
                                onClick={async () =>
                                  await updateAssignment({
                                    assignment: a,
                                    workflowState:
                                      FrankBackendTypes.AssignmentWorkflowState
                                        .Incomplete,
                                    completedAt: null,
                                  })
                                }
                                loading={loadingUpdateAssignment}
                              />
                            )}
                          </div>
                        </td>
                        <td className={`p-2 ${getColor(a.workflowState)}`}>
                          {getStatusText(a.workflowState)}
                        </td>
                        <td className="p-2">{a.task.title}</td>
                        <td className="p-2">{a.coworker.name}</td>
                        <td className="p-2">{a.assignedByUser.name}</td>
                        <td className="p-2">
                          {dateFormatter(preferredLanguage).format(
                            new Date(a.createdAt)
                          )}
                        </td>
                        <td className="p-2">
                          {a.dueDate &&
                            dateFormatter(preferredLanguage).format(
                              new Date(a.dueDate)
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <EmptyState title={t("noTasksAssignedYet")}>
              {t("createTasksToAssignThemToGroupMem")}
            </EmptyState>
          )}
          {taskAssignments.hasNext && (
            <div className="pt-8">
              <Button onClick={nextPageAssignments}>{t("showMore")}</Button>
            </div>
          )}
        </section>
      )}
      {!loadingOnboardingTasks && !!data.group.onboardingTasks?.length && (
        <section className="space-y-4">
          <div>
            <div className="t-large plus">{t("onboardingTasks")}</div>
            <div className="t-small">
              {t("tasksAssignedToNewWorkersUponCompl")}
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {data.group.onboardingTasks.map((task) => (
              <TaskCard
                lng={preferredLanguage}
                task={{ ...task.task, note: task.note }}
                menuItems={
                  <MenuItem
                    onClick={async () => {
                      const confirm = await openConfirmationModal({
                        title: t("areYouSureYouWantToRemoveThisOnb"),
                        bodyText: t("ifYouChangeYourMindYouCanReAssi"),
                        actionText: t("confirm"),
                      });
                      if (!confirm) {
                        return;
                      }
                      await removeScheduledTask(task.id);
                      await refetchOnboardingTasks();
                    }}
                  >
                    {t("remove")}
                  </MenuItem>
                }
              />
            ))}
          </div>
        </section>
      )}
      <section className="space-y-4">
        <div>
          <div className="t-large plus">{t("taskLibrary")}</div>
          <div className="t-small max-w-2xl">
            {t("previouslyCreatedTasksYouCanEditA")}
          </div>
        </div>
        {tasks?.total === 0 ? (
          <EmptyState
            title={t("noTasksCreatedYet")}
            buttonLabel="Create"
            onClick={() =>
              launchTaskAssignmentModal({
                refetchAssignments,
                groupId,
                refetchTasks,
              })
            }
          >
            {t("createTasksToAssignToNewAndExisti")}
          </EmptyState>
        ) : (
          <div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              <Card
                className="p-4 t-small plus flex items-center justify-center"
                onClick={() =>
                  launchTaskAssignmentModal({
                    refetchAssignments,
                    groupId,
                    refetchTasks,
                  })
                }
              >
                {t("createNew")}
              </Card>

              {tasks?.objects.map((t) => (
                <TaskCard
                  lng={preferredLanguage}
                  menuItems={
                    <TaskMenu
                      task={t}
                      refetch={async () => {
                        await refetchAssignments();
                        await refetchTasks();
                      }}
                    />
                  }
                  key={t.id}
                  task={t}
                >
                  <div className="pt-6">
                    <TaskActions
                      task={t}
                      groupId={groupId}
                      refetch={async () => {
                        await refetchAssignments();
                        await refetchTasks();
                      }}
                    />
                  </div>
                </TaskCard>
              ))}
            </div>
            {tasks?.hasNext && (
              <div className="py-6">
                <Button buttonStyle="outline" onClick={nextPageTasks}>
                  {t("showMore")}
                </Button>
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default ListTasks;

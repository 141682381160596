import { IconButton } from "@get-frank-eng/design-system/dist/components/Button";
import { useLayout } from "@get-frank-eng/design-system/dist/components/Layout";
import { TopBar } from "@get-frank-eng/design-system/dist/components/Layout/TopBar";
import { Sizes } from "@get-frank-eng/design-system/dist/constants";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import CustomFormResponseEdit from "./CustomFormResponseEdit";

interface RouteParams {
  id: string;
  responseId: string;
}

export default function CustomForm(): JSX.Element {
  const { id, responseId } = useParams<RouteParams>();
  const { setMobilePanel } = useLayout();
  const history = useHistory();
  const onComplete = () => history.push("/home");
  return (
    <>
      <div className="md:hidden fixed z-50 top-0 w-screen">
        <TopBar
          classNames="bg-canvas-800"
          left={
            <IconButton
              size={Sizes.XL}
              onClick={() => setMobilePanel("left")}
              buttonStyle="minimal"
              icon="menu"
            />
          }
          title="Custom Form"
        />
      </div>
      <div className="mx-auto max-w-4xl w-full md:mt-12 mt-24 mb-20 px-4">
        <CustomFormResponseEdit
          customFormId={id}
          customFormResponseId={responseId}
          onComplete={onComplete}
        />
      </div>
    </>
  );
}

import * as React from "react";
import { useParams } from "react-router";
import { FullCenter } from "@get-frank-eng/design-system";
import Loading from "../components/Loading";
import useQueryParams from "../hooks/useQueryParams";
import useFlagMgmt from "./dataAccess/mutations/useFlagMgmt";
import { useTranslation } from "react-i18next";

export default function FlagMgmt() {
  const { t } = useTranslation();
  const { flagId } = useParams<{ flagId: string }>();
  const qs = useQueryParams();
  const outcome = React.useMemo(() => {
    return qs.get("outcome");
  }, [qs]);

  const {
    invokeRemove,
    invokeUphold,
    loadingRemove,
    loadingUphold,
  } = useFlagMgmt();

  React.useEffect(() => {
    if (outcome === "remove") {
      invokeRemove({ variables: { input: { flagId } } });
    }
    if (outcome === "uphold") {
      invokeUphold({ variables: { input: { flagId } } });
    }
  }, [invokeRemove, invokeUphold, flagId]);

  if (loadingUphold || loadingRemove) {
    return <Loading />;
  }

  return (
    <FullCenter>
      <div className="max-w-sm">
        <div className="t-regular plus">{t("thankYou")}</div>
        <div className="t-small">{t("yourDecisionHasBeenProcessed")}</div>
      </div>
    </FullCenter>
  );
}

import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const UPHOLD = gql`
  mutation UpholdFlag($input: UpholdFlagInput!) {
    upholdFlag(input: $input) {
      nothing
    }
  }
`;

const REMOVE = gql`
  mutation RemoveFlag($input: RemoveFlagInput!) {
    removeFlag(input: $input) {
      nothing
    }
  }
`;

export default function useFlagMgmt() {
  const [invokeUphold, { loading: loadingUphold }] = useMutation<
    any,
    FrankBackendTypes.MutationUpholdFlagArgs
  >(UPHOLD);

  const [invokeRemove, { loading: loadingRemove }] = useMutation<
    any,
    FrankBackendTypes.MutationRemoveFlagArgs
  >(REMOVE);

  return { invokeRemove, invokeUphold, loadingRemove, loadingUphold };
}

import * as React from "react";
import ViewFullDocument from "../../Templates/components/ViewFullDocument";
import AttachmentsAndLinks from "../components/AttachmentsAndLinks";
import PetitionRecipientHeader from "../components/PetitionRecipientHeader";
import {
  Button,
  IconButton,
  LinkButton,
  ScreenSizes,
  Sizes,
  Tooltip,
  TopBar,
  useResponsive,
  WorkerStatementRow,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useModals } from "../../Modals";
import { IconLinkButton } from "@get-frank-eng/design-system/dist/components/Button";
import startCase from "lodash/startCase";
import useCampaignSignatures from "../dataAccess/queries/useSignatures";
import { PulseLoader } from "react-spinners";
import Pager, { PaginationStrategy } from "../../components/Pager";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

export default function DemandLetter({
  campaign,
  refetchCampaign,
}: {
  campaign: FrankBackendTypes.Campaign;
  refetchCampaign: () => {};
}) {
  const { t } = useTranslation();
  const { preferredLanguage } = useCurrentUserData();

  const { screenSize } = useResponsive();
  const isMobile = screenSize < ScreenSizes.MD;
  const { setModal } = useModals();

  const {
    loading,
    signatures,
    loadMore,
    refetch: refetchSignatures,
  } = useCampaignSignatures(campaign.id);

  const header = (
    <div className="flex flex-row items-center">
      <Tooltip
        placement="left"
        trigger="hover"
        delayShow={100}
        nudgeX={-10}
        tooltip={
          <div className="t-micro p-2 bg-canvas-700 rounded-lg">
            {t("help")}
          </div>
        }
        styleCard={false}
      >
        <IconButton
          icon="help_outline"
          size={Sizes.LG}
          className="text-white"
          buttonStyle="minimal"
          onClick={() => {
            setModal({
              type: "signCampaignFAQModal",
              props: {
                recipientNames: campaign.petitionRecipients.map(
                  (recipient) => recipient.fullName
                ),
                typeOfSignatures: campaign.signaturesVisible
                  ? "public"
                  : "anonymous",
              },
            });
          }}
        />
      </Tooltip>
      {campaign.relationshipToMe ===
        FrankBackendTypes.CampaignUserRelationship.None && (
        <Button
          buttonStyle="brand"
          onClick={() => {
            setModal({
              type: "signDemandLetterModal",
              props: {
                campaignId: campaign.id,
              },
              afterClose: () => {
                refetchSignatures();
                refetchCampaign();
              },
            });
          }}
        >
          {t("signPetition")}
        </Button>
      )}
      {campaign.relationshipToMe ===
        FrankBackendTypes.CampaignUserRelationship.Organizer &&
        (isMobile ? (
          <IconLinkButton
            buttonStyle="minimal"
            icon="edit"
            type="button"
            to={`../${campaign.id}/builder`}
          />
        ) : (
          <LinkButton
            buttonStyle="minimal"
            iconRight="edit"
            type="button"
            to={`../${campaign.id}/builder`}
          >
            <span className="mr-1">{t("edit")}</span>
          </LinkButton>
        ))}
    </div>
  );

  return (
    <div className="flex flex-col w-full">
      <TopBar
        left={
          <IconLinkButton
            to={`../${campaign.id}`}
            icon="arrow_back"
            size={Sizes.XL}
            buttonStyle="minimal"
          />
        }
        title={t("petition")}
        right={header}
      />
      <div className="mx-auto max-w-4xl w-full px-4 pb-24 pt-8">
        <ViewFullDocument
          contentJSON={campaign.contentJSON}
          documentFooterContent={
            campaign.signaturesVisible && (
              <div className="pt-8">
                <div className="t-large plus text-white pb-4">
                  {startCase(
                    t("signatureWithCount", {
                      count: campaign.signatures.total,
                    })
                  )}
                </div>
                {loading ? (
                  <PulseLoader />
                ) : (
                  <Pager<FrankBackendTypes.Signature>
                    pagination={signatures}
                    onShowMore={loadMore}
                    paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
                  >
                    <div className="grid grid-cols-1 gap-3">
                      {signatures.objects.map((signature) => (
                        <WorkerStatementRow
                          key={signature.id}
                          name={signature.user.name}
                          date={new Date(signature.createdAt)}
                          statement={signature.statement}
                          profilePicUrl={signature.user.profilePic.url}
                          lng={preferredLanguage}
                        />
                      ))}
                    </div>
                  </Pager>
                )}
              </div>
            )
          }
          documentHeaderContent={
            <PetitionRecipientHeader
              petitionRecipients={campaign.petitionRecipients}
            />
          }
        />
        {(campaign.links.length > 0 ||
          campaign.attachments.objects.length > 0) && (
          <div className="mt-6">
            <AttachmentsAndLinks
              links={campaign.links}
              attachments={campaign.attachments.objects}
            />
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";
import { Button } from "@get-frank-eng/design-system";

interface ActionButton {
  label: string;
  action: () => void;
  dataCy?: string;
}

const ActionListItem = ({
  title,
  subheading,
  defaultAction,
  actions,
}: {
  title: string;
  subheading: string;
  defaultAction?: () => void;
  actions: ActionButton[];
}) => {
  return (
    <>
      <div className="w-full text-left align-top rounded border bg-canvas-700 py-4 px-4 h-full flex flex-col ">
        <div>
          <div className="whitespace-nowrap overflow-hidden text-ellipsis">
            {title}
          </div>
          <div className="t-mini text-canvas-400 py-1">{subheading}</div>
        </div>
        <div className="flex items-center col-span-10 mt-4 b-0">
          {actions.map((action) => (
            <Button
              key={action.label}
              dataCy={action.dataCy}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                action.action();
              }}
              buttonStyle="secondary"
              className="mx-1"
            >
              {action.label}
            </Button>
          ))}
        </div>
      </div>
    </>
  );
};

export default ActionListItem;

import {
  DocumentType,
  PetitionDocument,
} from "frank-types/dist/frank-backend-types";
import { useTranslation } from "react-i18next";
import { convertJsonDateToString } from "../../util";
import RelativeTimeDisplay from "../RelativeTime";
import DocumentItem from "./DocumentItem";

const PetitionDocumentItem = ({ petition }: { petition: PetitionDocument }) => {
  const { t } = useTranslation();
  return (
    <DocumentItem
      key={petition.id}
      title={petition.title}
      icon="assignment"
      subheading={
        <span>
          {t("published")} <RelativeTimeDisplay date={petition.publishedAt} />
        </span>
      }
      onView={() => alert("view form")}
      label={t("petition")}
    />
  );
};

export default PetitionDocumentItem;

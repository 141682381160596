import * as React from "react";
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useToaster,
  MultiSelect,
  Intent,
} from "@get-frank-eng/design-system";
import { useModals } from "../../Modals";
import { FrankBackendTypes } from "frank-types";
import { searchCoworkersByName } from "../../MentionTextarea/dataAccess/coworkerSearch";
import debounce from "lodash/debounce";
import useAddMember from "../dataAccess/mutations/useAddMember";
import useAreUsersInChannel from "../dataAccess/mutations/useAreUsersInChannel";
import { useTranslation } from "react-i18next";

const AddMemberModal = ({
  channelId,
  topicName,
}: {
  channelId: string;
  topicName: string;
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState([]);

  const { closeModal } = useModals();
  const toaster = useToaster();
  const { addToChannel } = useAddMember();
  const { areUsersInChannel } = useAreUsersInChannel();
  const [loading, setLoading] = React.useState(false);

  const loadOptions = React.useCallback(
    debounce((searchString, callback) => {
      // need to search Frank backend for coworkers and then
      // cross-reference it with who is already in the channel from ChatBackend
      setLoading(true);
      let coworkers: FrankBackendTypes.Coworker[];

      searchCoworkersByName(searchString)
        .then((result) => {
          coworkers = result;
          areUsersInChannel({
            userIds: coworkers.map((c) => c.id),
            channelId,
          }).then((usersAlreadyInChannel) => {
            setLoading(false);
            callback(
              coworkers
                .filter((c) => !usersAlreadyInChannel.includes(c.id))
                .map((r) => ({
                  label: r.name,
                  value: r.id,
                }))
            );
          });
        })
        .catch((e) => {
          toaster.addToast({
            intent: Intent.FAILURE,
            children: t("somethingWentWrong"),
          });
          throw e;
        });
    }, 500),
    [searchCoworkersByName, areUsersInChannel]
  );

  const handleSubmit = async (values) => {
    try {
      await addToChannel({
        userIds: values.map((option) => option.value),
        channelId,
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("successfullyAddedToChannel"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
      throw e;
    }
  };

  return (
    <>
      <ModalHeader title={t("addNewMembersToTopicName", { topicName })} />
      <ModalBody>
        <div className="h-96">
          <MultiSelect
            loadOptions={loadOptions}
            defaultOptions={true}
            onChange={(newValue) => setValue(newValue)}
            isLoading={loading}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          buttonStyle="brand"
          onClick={() => {
            handleSubmit(value);
            closeModal();
          }}
        >
          {t("add")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddMemberModal;

import {
  Button,
  FormGroup,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useModals } from "../../Modals";

import useCustomAction from "../dataAccess/mutations/useCustomActionMutation";
import { useTranslation } from "react-i18next";

interface FormData {
  title: string;
  description: string;
}

const CreateCustomActionModal = ({
  campaignId,
  refetchCampaign,
}: {
  campaignId: string;
  refetchCampaign: () => void;
}) => {
  const { loading, createCustomAction } = useCustomAction({
    campaignId,
  });
  const { register, errors, handleSubmit } = useForm<FormData>();

  const toaster = useToaster();
  const { closeModal } = useModals();

  const { t } = useTranslation();

  const submit = React.useCallback(
    async (fd: FormData) => {
      await createCustomAction({ ...fd });
      toaster.addToast({
        children: t("customActionCreated"),
        intent: Intent.SUCCESS,
      });
      await refetchCampaign();
      await closeModal();
    },
    [createCustomAction, toaster, refetchCampaign, closeModal, t]
  );

  return (
    <>
      <ModalHeader title={t("createCustomAction")} />
      <ModalBody>
        <form
          className="flex-grow"
          onSubmit={handleSubmit(submit)}
          id="create-custom-action"
        >
          <FormGroup label={t("title")} name="title" id="title">
            <Input
              errorText={errors.title && (t("titleIsRequired") as string)}
              type="text"
              register={register}
              registerArgs={{
                required: true,
                maxLength: 200,
              }}
            />
          </FormGroup>
          <FormGroup
            label={t("description")}
            name="description"
            id="description"
          >
            <Textarea
              errorText={errors.title && (t("descriptionIsRequired") as string)}
              maxLength={2000}
              register={register}
              registerArgs={{
                required: true,
                maxLength: 2000,
              }}
            />
          </FormGroup>
          <ModalFooter>
            <Button
              buttonStyle="brand"
              loading={loading}
              form="create-custom-action"
            >
              {t("publishAction")}
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
};

export default CreateCustomActionModal;

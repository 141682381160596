/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import last from "lodash/last";
import * as React from "react";
import { useHistory } from "react-router";
import ForgotPasswordModal from "./Auth/ForgotPasswordModal";
import SupporterOptionsModal from "./CampaignDetail/modals/SupporterOptionsModal";
import NewMemberInviteCollaboratorModal from "./Collaborators/NewMemberInviteCollaboratorModal";
import ConfirmationModal from "./components/ConfirmationModal";
import CoworkerActivityModal from "./Coworkers/modals/CoworkerActivityModal";
import CoworkersModal from "./Coworkers/modals/CoworkersModal";
import LinkInviteModal from "./Invites/CreateLinkInviteModal";
import DefaultInviteModal from "./Invites/DefaultInviteModal";
import EmailInviteModal from "./Invites/EmailInvite";
import PreviewEmailModal from "./Invites/EmailInvite/PreviewEmailModal";
import CertificationsModal from "./Onboarding/modals/CertificationsModal";
import EnterPassphraseModal from "./Onboarding/modals/EnterPassphraseModal";
import VerifyEmailModal from "./Onboarding/modals/VerifyEmailModal";
import FlagModal from "./Profile/FlagModal";
import CampaignBuilderMobileNavModal from "./CampaignBuilder/modals/CampaignBuilderMobileNavModal";
import {
  Sizes,
  ModalBackground,
  ModalContainer,
} from "@get-frank-eng/design-system";
import CreatePollModal from "./Channel/CreatePollModal";
import MessageLinkModal from "./Chat/modals/MessageLinkModal";
import CreateTopicModal from "./Topics/modals/CreateTopicModal";
import SignCampaignFAQModal from "./CampaignDetail/modals/SignCampaignFAQModal";
import SignDemandLetterModal from "./CampaignDetail/modals/SignDemandLetterModal";
import CreateCustomActionModal from "./CampaignDetail/modals/CreateCustomActionModal";
import ActionFAQModal from "./CampaignDetail/modals/ActionFAQModal";
import CoworkersFAQModal from "./Coworkers/modals/CoworkersFAQModal";
import AdmitPendingMemberModal from "./PendingMembers/modals/AdmitPendingMemberModal";
import { DenyPendingMemberModal } from "./PendingMembers/modals/DenyPendingMemberModal";
import WelcomeModal from "./Tour/modals/WelcomeModal";
import ConfirmEmailModal from "./SSO/ConfirmEmailModal";
import CreateGroupModal from "./Group/modals/CreateGroupModal";
import CreatePartnerModal from "./Partner/modals/CreatePartnerModal";
import AddOrganizerModal from "./Partner/modals/AddOrganizerModal";
import MoveWorkerModal from "./Partner/modals/MoveWorkerModal";
import AddMemberModal from "./Topics/modals/AddMemberModal";
import CreateTaskModal from "./Tasks/modals/CreateTaskModal";
import AssignmentDetailModal from "./Tasks/modals/AssignmentDetailModal";
import ViewAssignmentModal from "./Tasks/modals/ListAssignmentsModal";
import AssignTaskModal from "./Tasks/modals/AssignTaskModal";
import CreateAuthCardModal from "./Partner/modals/CreateAuthCardModal";
import GenericModal from "./components/GenericModal";
import CreateCustomResourceModal from "./Partner/modals/CreateCustomResourceModal";

export interface ModalArgs {
  type: string;
  props: any;
  afterClose?: () => void;
  modalBack?: () => void;
  stackSize?: number;
}

interface CreateTopicModalArgs extends ModalArgs {
  type: "createTopicModal";
  props: React.ComponentProps<typeof CreateTopicModal>;
}

interface WelcomeModalArgs extends ModalArgs {
  type: "welcomeModal";
  props: React.ComponentProps<typeof WelcomeModal>;
}

interface MessageLinkModalArgs extends ModalArgs {
  type: "messageLinkModal";
  props: React.ComponentProps<typeof MessageLinkModal>;
}

interface CoworkerActivityModalArgs extends ModalArgs {
  type: "coworkerActivityModal";
  props: React.ComponentProps<typeof CoworkerActivityModal>;
}

interface CreatePollModalArgs extends ModalArgs {
  type: "createPollModal";
  props: React.ComponentProps<typeof CreatePollModal>;
}

interface CustomFormModalArgs extends ModalArgs {
  type: "customFormModal";
  props: React.ComponentProps<typeof GenericModal>;
}

interface CampaignBuilderMobileNavModalArgs extends ModalArgs {
  type: "campaignBuilderMobileNavModal";
  props: React.ComponentProps<typeof CampaignBuilderMobileNavModal>;
}

interface ForgotPasswordModalArgs extends ModalArgs {
  type: "forgotPasswordModal";
  props: React.ComponentProps<typeof ForgotPasswordModal>;
}

interface FlagModalArgs extends ModalArgs {
  type: "flagModal";
  props: React.ComponentProps<typeof FlagModal>;
}

interface NewMemberInviteCollaboratorModalArgs extends ModalArgs {
  type: "newMemberInviteCollaboratorModal";
  props: React.ComponentProps<typeof NewMemberInviteCollaboratorModal>;
}

interface PreviewEmailModalArgs extends ModalArgs {
  type: "previewEmailModal";
  props: React.ComponentProps<typeof PreviewEmailModal>;
}

interface DefaultInviteModalArgs extends ModalArgs {
  type: "defaultInviteModal";
  props: React.ComponentProps<typeof DefaultInviteModal>;
}

interface EmailInviteModalArgs extends ModalArgs {
  type: "emailInviteModal";
  props: React.ComponentProps<typeof EmailInviteModal>;
}

interface LinkInviteModalArgs extends ModalArgs {
  type: "linkInviteModal";
  props: React.ComponentProps<typeof LinkInviteModal>;
}

interface ConfirmationModalArgs extends ModalArgs {
  type: "confirmationModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof ConfirmationModal>;
}

interface CoworkersModalArgs extends ModalArgs {
  type: "coworkersModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof CoworkersModal>;
}

interface CertificationModalArgs extends ModalArgs {
  type: "certificationModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof CertificationsModal>;
}

interface VerifyEmailModalArgs extends ModalArgs {
  type: "verifyEmailModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof VerifyEmailModal>;
}

interface EnterpassphraseModal extends ModalArgs {
  type: "enterPassphraseModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof EnterPassphraseModal>;
}

interface SupporterOptionsModalArgs extends ModalArgs {
  type: "supporterOptionsModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof SupporterOptionsModal>;
}

interface SignCampaignFAQModalArgs extends ModalArgs {
  type: "signCampaignFAQModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof SignCampaignFAQModal>;
}

interface SignDemandLetterModalArgs extends ModalArgs {
  type: "signDemandLetterModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof SignDemandLetterModal>;
}

interface CreateCustomActionModalArgs extends ModalArgs {
  type: "createCustomActionModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof CreateCustomActionModal>;
}

interface ActionFAQModalArgs extends ModalArgs {
  type: "actionFAQModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof ActionFAQModal>;
}

interface CoworkersFAQModalArgs extends ModalArgs {
  type: "coworkersFAQModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof CoworkersFAQModal>;
}

interface AdmitPendingMemberArgs extends ModalArgs {
  type: "reviewPendingMemberModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof AdmitPendingMemberModal>;
}

interface DenyPendingMemberModalArgs extends ModalArgs {
  type: "denyPendingMemberModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof DenyPendingMemberModal>;
}

interface ConfirmEmailModalArgs extends ModalArgs {
  type: "confirmEmailModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof ConfirmEmailModal>;
}

interface CreateGroupModalArgs extends ModalArgs {
  type: "createGroupModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof CreateGroupModal>;
}

interface CreatePartnerModalArgs extends ModalArgs {
  type: "createPartnerModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof CreatePartnerModal>;
}

interface AddOrganizerModalArgs extends ModalArgs {
  type: "addOrganizerModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof AddOrganizerModal>;
}

interface MoveWorkerModalArgs extends ModalArgs {
  type: "moveWorkerModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof MoveWorkerModal>;
}

interface AddMemberModalArgs extends ModalArgs {
  type: "addMemberModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof AddMemberModal>;
}

interface CreateTaskModalArgs extends ModalArgs {
  type: "createTaskModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof CreateTaskModal>;
}
interface AssignmentDetailModalArgs extends ModalArgs {
  type: "assignmentDetailModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof AssignmentDetailModal>;
}

interface ViewAssignmentModalArgs extends ModalArgs {
  type: "viewAssignmentModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof ViewAssignmentModal>;
}

interface AssignTaskModalArgs extends ModalArgs {
  type: "assignTaskModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof AssignTaskModal>;
}

interface CreateAuthCardModalArgs extends ModalArgs {
  type: "createAuthCardModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof CreateAuthCardModal>;
}

interface GenericModalArgs extends ModalArgs {
  type: "genericModal";
  afterClose?: () => void;
  props: React.ComponentProps<typeof GenericModal>;
}

interface CreateCustomResourceModalArgs extends ModalArgs {
  type: "createCustomResourceModal";
  props: React.ComponentProps<typeof CreateCustomResourceModal>;
}

export type ModalTypes =
  | null
  | CreateCustomResourceModalArgs
  | NewMemberInviteCollaboratorModalArgs
  | ForgotPasswordModalArgs
  | CreateTopicModalArgs
  | FlagModalArgs
  | PreviewEmailModalArgs
  | CustomFormModalArgs
  | MessageLinkModalArgs
  | ConfirmationModalArgs
  | CoworkerActivityModalArgs
  | DefaultInviteModalArgs
  | CreatePollModalArgs
  | EmailInviteModalArgs
  | LinkInviteModalArgs
  | VerifyEmailModalArgs
  | EnterpassphraseModal
  | CertificationModalArgs
  | CoworkersModalArgs
  | CampaignBuilderMobileNavModalArgs
  | SupporterOptionsModalArgs
  | SignCampaignFAQModalArgs
  | SignDemandLetterModalArgs
  | CreateCustomActionModalArgs
  | ActionFAQModalArgs
  | WelcomeModalArgs
  | CoworkersFAQModalArgs
  | AdmitPendingMemberArgs
  | DenyPendingMemberModalArgs
  | ConfirmEmailModalArgs
  | CreateGroupModalArgs
  | CreatePartnerModalArgs
  | AddOrganizerModalArgs
  | MoveWorkerModalArgs
  | AddMemberModalArgs
  | CreateTaskModalArgs
  | AssignmentDetailModalArgs
  | ViewAssignmentModalArgs
  | AssignTaskModalArgs
  | CreateAuthCardModalArgs
  | GenericModalArgs;

const modalContext = React.createContext<{
  modalBack: () => void;
  setModal: (m: ModalTypes) => any;
  stackSize: number;
  closeModal: () => void;
}>(null);

const ModalContent = ({
  modal,
  closeModal,
}: {
  modal: ModalTypes;
  closeModal: () => any;
}) => {
  const history = useHistory();

  React.useEffect(() => {
    const unlisten = history.listen(closeModal);
    return unlisten;
  }, [history, closeModal]);

  if (!modal) {
    return null;
  }

  switch (modal.type) {
    case "campaignBuilderMobileNavModal":
      return <CampaignBuilderMobileNavModal {...modal.props} />;
    case "newMemberInviteCollaboratorModal":
      return <NewMemberInviteCollaboratorModal {...modal.props} />;
    case "flagModal":
      return <FlagModal {...modal.props} />;
    case "forgotPasswordModal":
      return <ForgotPasswordModal {...modal.props} />;
    case "messageLinkModal":
      return <MessageLinkModal {...modal.props} />;
    case "previewEmailModal":
      return <PreviewEmailModal {...modal.props} />;
    case "customFormModal":
      return <GenericModal {...modal.props} />;
    case "confirmationModal":
      return <ConfirmationModal {...modal.props} />;
    case "welcomeModal":
      return <WelcomeModal {...modal.props} />;
    case "coworkerActivityModal":
      return <CoworkerActivityModal {...modal.props} />;
    case "defaultInviteModal":
      return <DefaultInviteModal />;
    case "createPollModal":
      return <CreatePollModal {...modal.props} />;
    case "emailInviteModal":
      return <EmailInviteModal view="modal" {...modal.props} />;
    case "linkInviteModal":
      return <LinkInviteModal {...modal.props} />;
    case "coworkersModal":
      return <CoworkersModal {...modal.props} />;
    case "certificationModal":
      return <CertificationsModal {...modal.props} />;
    case "verifyEmailModal":
      return <VerifyEmailModal {...modal.props} />;
    case "enterPassphraseModal":
      return <EnterPassphraseModal {...modal.props} />;
    case "supporterOptionsModal":
      return <SupporterOptionsModal {...modal.props} />;
    case "createTopicModal":
      return <CreateTopicModal {...modal.props} />;
    case "signCampaignFAQModal":
      return <SignCampaignFAQModal {...modal.props} />;
    case "signDemandLetterModal":
      return <SignDemandLetterModal {...modal.props} />;
    case "createCustomActionModal":
      return <CreateCustomActionModal {...modal.props} />;
    case "actionFAQModal":
      return <ActionFAQModal />;
    case "coworkersFAQModal":
      return <CoworkersFAQModal />;
    case "reviewPendingMemberModal":
      return <AdmitPendingMemberModal {...modal.props} />;
    case "denyPendingMemberModal":
      return <DenyPendingMemberModal {...modal.props} />;
    case "confirmEmailModal":
      return <ConfirmEmailModal {...modal.props} />;
    case "createGroupModal":
      return <CreateGroupModal {...modal.props} />;
    case "createPartnerModal":
      return <CreatePartnerModal />;
    case "addOrganizerModal":
      return <AddOrganizerModal {...modal.props} />;
    case "moveWorkerModal":
      return <MoveWorkerModal {...modal.props} />;
    case "addMemberModal":
      return <AddMemberModal {...modal.props} />;
    case "createTaskModal":
      return <CreateTaskModal {...modal.props} />;
    case "assignmentDetailModal":
      return <AssignmentDetailModal {...modal.props} />;
    case "viewAssignmentModal":
      return <ViewAssignmentModal {...modal.props} />;
    case "assignTaskModal":
      return <AssignTaskModal {...modal.props} />;
    case "createAuthCardModal":
      return <CreateAuthCardModal {...modal.props} />;
    case "genericModal":
      return <GenericModal {...modal.props} />;
    case "createCustomResourceModal":
      return <CreateCustomResourceModal {...modal.props} />;
    default:
      return <></>;
  }
};

const modalSizeConfigMap = {
  previewEmailModal: Sizes.XS,
  coworkersModal: Sizes.XL,
  customFormModal: Sizes.XL,
};

type ModalState = ModalTypes[];
type ModalAction =
  | { type: "modal-back" }
  | { type: "set-modal"; modal: ModalTypes }
  | { type: "close-modal" };

const defaultModalState: ModalTypes[] = [];

const modalReducer = (
  prevState: ModalTypes[],
  action: ModalAction
): ModalState => {
  switch (action.type) {
    case "close-modal":
      return [];
    case "modal-back":
      return [...prevState.slice(0, prevState.length - 1)];
    case "set-modal":
      return [...prevState, action.modal];
  }
};

export const ModalProvider = ({ children }) => {
  const [modalStack, dispatch] = React.useReducer(
    modalReducer,
    defaultModalState
  );

  // top of the stack
  const theModal = React.useMemo(() => last(modalStack), [modalStack]);

  React.useEffect(() => {
    if (theModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [theModal]);

  // pops off modal stack
  const modalBack = React.useCallback(() => {
    if (theModal?.afterClose) {
      theModal.afterClose();
    }
    dispatch({ type: "modal-back" });
  }, [theModal, dispatch]);

  // adds to modal stack
  const setModal = React.useCallback(
    (modal: ModalTypes) => {
      dispatch({ type: "set-modal", modal });
    },
    [dispatch]
  );

  // clears stack entirely
  const closeModal = React.useCallback(() => {
    if (theModal?.afterClose) {
      theModal.afterClose();
    }
    dispatch({ type: "close-modal" });
  }, [dispatch, theModal]);

  return (
    <modalContext.Provider
      value={{
        setModal,
        modalBack,
        stackSize: modalStack.length,
        closeModal,
      }}
    >
      {children}

      <ModalBackground onClose={closeModal} isOpen={!!theModal}>
        {modalStack.map((modal, i) => {
          const isLast = i === modalStack.length - 1;
          return (
            <ModalContainer
              // eslint-disable-next-line react/no-array-index-key
              key={`${modal.type}-${i}`}
              onModalBack={modalBack}
              onClose={closeModal}
              stackSize={modalStack.length}
              animateIn={i === 0}
              isVisible={isLast}
              size={modalSizeConfigMap[theModal?.type]}
            >
              <ModalContent modal={modal} closeModal={closeModal} />
            </ModalContainer>
          );
        })}
      </ModalBackground>
    </modalContext.Provider>
  );
};

export const useModals = () => {
  const { setModal, modalBack, stackSize, closeModal } = React.useContext(
    modalContext
  );

  const openConfirmationModal = React.useCallback(
    (modalProps: Omit<ConfirmationModalArgs["props"], "action">) => {
      return new Promise((resolve) => {
        setModal({
          afterClose: () => resolve(false),
          type: "confirmationModal",
          props: { ...modalProps, action: () => resolve(true) },
        });
      });
    },
    [setModal]
  );

  return { setModal, modalBack, openConfirmationModal, stackSize, closeModal };
};

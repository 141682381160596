import { gql, useQuery } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query AuthorizationCardSignature($input: AuthorizationCardSignatureInput!) {
    authorizationCardSignature(input: $input) {
      id
      createdAt
    }
  }
`;

export default function useAuthorizationCardSignature({
  userId,
  groupId,
}: {
  userId: string;
  groupId: string;
}) {
  const { data, loading, error, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "authorizationCardSignature">
  >(QUERY, {
    variables: {
      input: {
        userId,
        groupId,
      },
    },
    fetchPolicy: "network-only",
  });

  return {
    loading,
    error,
    refetch,
    signature: data?.authorizationCardSignature,
  };
}

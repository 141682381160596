import { Button, Sizes } from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";

const svg = (
  <div className="relative">
    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-canvas-700" />
    <svg
      width="153"
      height="116"
      viewBox="0 0 153 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <g clip-path="url(#clip1)">
          <path
            d="M108.046 51.7678L116.178 43.6361C117.741 42.0733 120.272 42.0733 121.835 43.6361C123.397 45.1988 123.397 47.7302 121.835 49.2929L112.996 58.1317C112.02 59.1075 110.436 59.1075 109.46 58.1317C108.485 57.1559 108.485 55.572 109.46 54.5962L116.885 47.1716C117.274 46.7827 117.91 46.7827 118.299 47.1716C118.688 47.5605 118.688 48.1969 118.299 48.5858L111.582 55.3033L112.642 56.364L119.36 49.6465C120.336 48.6707 120.336 47.0867 119.36 46.1109C118.384 45.1351 116.8 45.1351 115.824 46.1109L108.4 53.5355C106.837 55.0983 106.837 57.6297 108.4 59.1924C109.962 60.7551 112.494 60.7551 114.057 59.1924L122.895 50.3536C125.045 48.204 125.045 44.725 122.895 42.5754C120.746 40.4258 117.267 40.4258 115.117 42.5754L106.985 50.7071L108.046 51.7678Z"
            fill="#B6AFAA"
          />
        </g>
        <g clip-path="url(#clip2)">
          <path
            d="M36.2715 42.8528L42.8694 44.6207L47.4544 52.5622L35.3301 59.5622L28.3301 47.4378L36.2715 42.8528ZM35.2715 41.1208L27.3301 45.7058C26.3774 46.2558 26.048 47.4852 26.598 48.4378L33.598 60.5622C34.148 61.5148 35.3774 61.8442 36.3301 61.2942L48.4544 54.2942C49.4071 53.7442 49.7365 52.5148 49.1865 51.5622L44.6015 43.6207C44.3365 43.1617 43.8996 42.8251 43.3855 42.6946L36.7876 40.9267C36.2772 40.7826 35.7305 40.8558 35.2715 41.1208ZM35.0621 55.0981L43.7224 50.0981L44.7224 51.8301L36.0621 56.8301L35.0621 55.0981ZM33.0621 51.634L41.7224 46.634L42.7224 48.366L34.0621 53.366L33.0621 51.634ZM31.0621 48.1699L37.1243 44.6699L38.1243 46.4019L32.0621 49.9019L31.0621 48.1699Z"
            fill="#B6AFAA"
          />
        </g>
        <path
          d="M83.8923 25V39H69.8923V25H83.8923ZM83.8923 23H69.8923C68.7923 23 67.8923 23.9 67.8923 25V39C67.8923 40.1 68.7923 41 69.8923 41H83.8923C84.9923 41 85.8923 40.1 85.8923 39V25C85.8923 23.9 84.9923 23 83.8923 23ZM79.0323 31.86L76.0323 35.73L73.8923 33.14L70.8923 37H82.8923L79.0323 31.86Z"
          fill="#B6AFAA"
        />
        <g clip-path="url(#clip3)">
          <path
            d="M100.388 70.63C98.1775 59.4175 88.33 51 76.5 51C67.1075 51 58.95 56.33 54.8875 64.13C45.105 65.17 37.5 73.4575 37.5 83.5C37.5 94.2575 46.2425 103 57 103H99.25C108.22 103 115.5 95.72 115.5 86.75C115.5 78.17 108.838 71.215 100.388 70.63ZM83 80.25V93.25H70V80.25H60.25L76.5 64L92.75 80.25H83Z"
            fill="#B6AFAA"
          />
        </g>
        <rect x="0.5" width="152" height="116" fill="url(#paint0_linear)" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="152"
            height="116"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(132.441 50) rotate(135)"
          />
        </clipPath>
        <clipPath id="clip2">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(21.5 45.6077) rotate(-30)"
          />
        </clipPath>
        <clipPath id="clip3">
          <rect
            width="78"
            height="78"
            fill="white"
            transform="translate(37.5 38)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
);

const AttachmentsEmptyState = ({ openFileChooser }) => {
  const { t } = useTranslation();

  return (
    <div className="flex-1 flex flex-col items-center justify-center max-w-xs">
      <div className="max-w-md text-center flex flex-col items-center">
        {svg}
        <h1 className="t-regular plus mt-4">{t("thereAreNoFilesHereYet")}</h1>
        <p className="t-small text-canvas-400">
          {t("shareKeyDocumentsWithYourCoworkers")}
        </p>
        <Button
          className="mt-4"
          buttonStyle="outline"
          size={Sizes.MD}
          onClick={openFileChooser}
        >
          {t("upload")}
        </Button>
      </div>
    </div>
  );
};

export default AttachmentsEmptyState;

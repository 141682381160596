import {
  Avatar,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sizes,
} from "@get-frank-eng/design-system";
import { EventTypes, FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";
import useCompleteTour from "../dataAccess/useCompleteTour";
import { useNavigationState } from "../../Navigation/hooks/useNavigationState";
import useFindFriendlyFaces from "../dataAccess/useFindFriendlyFaces";
import { FriendlyFaces } from "../components/FriendlyFaces";
import * as analytics from "../../analytics";
import { useTranslation } from "react-i18next";

export default function WelcomeModal({
  callback,
  workerType,
  setCurrentTour,
  currentGroup,
  partner,
}: {
  callback: () => void;
  workerType: FrankBackendTypes.WorkerType;
  setCurrentTour: (tour: FrankBackendTypes.TourType) => void;
  currentGroup: FrankBackendTypes.Group;
  partner: FrankBackendTypes.Partner;
}) {
  const { t } = useTranslation();
  const { closeModal } = useModals();
  const { completeTour } = useCompleteTour();
  const { refetch } = useNavigationState();

  const onOptOut = React.useCallback(async () => {
    await completeTour(FrankBackendTypes.TourType.Welcome);
    await refetch();
    setCurrentTour(null);
  }, [completeTour, refetch, setCurrentTour]);
  const { friendlyFaces, loadingFriendlyFaces } = useFindFriendlyFaces();

  React.useEffect(() => {
    analytics.capture(EventTypes.AnalyticsEvents.VIEW_HOME_SCREEN);
  }, []);

  const header = React.useMemo(() => {
    if (loadingFriendlyFaces) {
      return (
        <div className="flex -space-x-3 overflow-hidden items-center">
          <div className="rounded-lg w-10 h-10 flex-shrink-0 bg-canvas-400 animate-pulse border z-10" />
          <div className="rounded-lg w-12 h-12 flex-shrink-0 bg-canvas-400 animate-pulse border z-20" />
          <div className="rounded-lg w-14 h-14 flex-shrink-0 bg-canvas-400 animate-pulse border z-30" />
          <div className="rounded-lg w-12 h-12 flex-shrink-0 bg-canvas-400 animate-pulse border z-20" />
          <div className="rounded-lg w-10 h-10 flex-shrink-0 bg-canvas-400 animate-pulse border z-10" />
        </div>
      );
    }
    if (friendlyFaces?.length < 4 || !friendlyFaces) {
      return (
        <Avatar
          size={Sizes.XL}
          src={currentGroup.logo?.url || partner.logoAttachment.url}
        />
      );
    }
    return (
      <FriendlyFaces
        faces={friendlyFaces?.map((f) => ({
          url: f.url,
          altText: "group member avatar",
        }))}
        groupName={currentGroup.name}
        groupAvatar={currentGroup.logo?.url || partner.logoAttachment.url}
      />
    );
  }, [loadingFriendlyFaces, friendlyFaces, currentGroup, partner]);

  const modalFooter = (
    <ModalFooter specialty border={false}>
      <div className="space-y-8">
        <div className="flex flex-row w-full justify-center space-x-2">
          <Button
            buttonStyle="brand"
            onClick={() => {
              closeModal();
              callback();
            }}
          >
            {t("showMeAround")}
          </Button>
          <Button
            id="opt-out-of-welcome-tour"
            buttonStyle="secondary"
            onClick={() => {
              closeModal();
              onOptOut();
            }}
          >
            {t("illTakeItFromHere")}
          </Button>
        </div>
      </div>
    </ModalFooter>
  );

  if (workerType === FrankBackendTypes.WorkerType.WorkerOne) {
    return (
      <>
        <ModalHeader
          icon={<Avatar size={Sizes.XL} src={partner.logoAttachment.url} />}
          specialty
          title={t("welcomeToYourNewGroup")}
          onCloseClick={onOptOut}
        />
        <ModalBody>
          <div className="t-small text-canvas-400 text-center space-y-4">
            {currentGroup.welcomeMessage}
            <div className="t-small plus">- {partner.name}</div>
          </div>
        </ModalBody>
        {modalFooter}
      </>
    );
  }

  return (
    <>
      <ModalHeader
        icon={header}
        specialty
        title={t("welcomeToFrank")}
        onCloseClick={onOptOut}
      />
      <ModalBody specialty>
        <div
          className="t-small text-canvas-400"
          data-cy="welcome-modal-custom-message"
        >
          {currentGroup.welcomeMessage}
        </div>
        <div className="t-small text-canvas-400 pt-2">
          {t("wonderingWhatToDoNextLetUsShowY")}
        </div>
      </ModalBody>
      {modalFooter}
    </>
  );
}

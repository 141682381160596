import {
  ActivityFeed,
  EmptyState,
  IconButton,
  RightSideBarOpenOnMobile,
  ScreenSizes,
  TopBar,
  useLayout,
  useScreenSize,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { Route, Switch, useRouteMatch } from "react-router";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import Channel from "../../Chat";
import Pager, { PaginationStrategy } from "../../components/Pager";
import useHomeFeedData from "../dataAccess/useHomeFeedData";
import useHomePageData from "../dataAccess/useHomePageData";
import { useModals } from "../../Modals";
import PendingMemberList from "../../PendingMembers/pages/PendingMemberList";
import { useTranslation } from "react-i18next";

const Index = ({ channelId }: { channelId: string }) => {
  const { t } = useTranslation();
  const { setMobilePanel } = useLayout();
  const { partner, myProfile } = useCurrentUserData();

  const screensize = useScreenSize();

  return (
    <>
      {screensize <= ScreenSizes.SM && (
        <div className="bg-canvas-700">
          <TopBar
            left={
              <IconButton
                icon="close"
                buttonStyle="minimal"
                onClick={() => setMobilePanel(null)}
              />
            }
            title={t("fromPartnerSlug", { partnerSlug: partner.slug })}
          />
        </div>
      )}
      <div className="p-6 bg-canvas-700 flex-grow">
        {screensize > ScreenSizes.SM && (
          <h1 className="t-title-4 plus">
            {t("fromPartnerSlug", { partnerSlug: partner.slug })}
          </h1>
        )}
        <p className="t-small full-formatting">
          <ReactMarkdown
            children={t("thisIsYourHomePage", {
              partnerName: partner.name,
            })}
          />
        </p>
        <div className="h-8" />
        <div className="space-y-8">
          <div>
            <MembershipRequests
              channelId={channelId}
              coworkerId={myProfile.coworker.id}
            />
          </div>
          <div>
            <h2 className="t-regular plus mb-2">{t("groupActivity")}</h2>
            <GroupActivity />
          </div>
        </div>
      </div>
    </>
  );
};

function MembershipRequests({
  channelId,
  coworkerId,
}: {
  channelId: string;
  coworkerId: string;
}) {
  const { data, loading } = useHomePageData({ coworkerId });
  const { setModal } = useModals();
  const { t } = useTranslation();
  if (loading) {
    return null;
  }
  return (
    <>
      {data?.coworkers.total > 0 ? (
        <PendingMemberList />
      ) : (
        <>
          <h2 className="t-regular plus mb-2">{t("membershipRequests")}</h2>
          <EmptyState
            onClick={() => setModal({ type: "defaultInviteModal", props: {} })}
            title={t("youHaveNoMembershipRequests")}
            buttonLabel={t("inviteCoworkers")}
          >
            {t("thisGroupIsInviteOnlyOnceInvited")}
          </EmptyState>
        </>
      )}
    </>
  );
}

function GroupActivity() {
  const { data, loading, fetchMoreHomeFeed } = useHomeFeedData({
    perPage: 10,
  });
  const { preferredLanguage } = useCurrentUserData();
  if (loading) {
    return null;
  }
  return (
    <Pager<FrankBackendTypes.Activity>
      pagination={data}
      onShowMore={fetchMoreHomeFeed}
      paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
    >
      <ActivityFeed activities={data?.objects} lng={preferredLanguage} />
    </Pager>
  );
}

const FromFrankRightSide = ({ channelId }: { channelId: string }) => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${url}/thread/:threadId`}
        render={({ match }) => {
          return (
            <>
              <RightSideBarOpenOnMobile />
              <Channel
                channelId={channelId}
                parentId={match.params.threadId}
                key={match.params.threadId}
              />
            </>
          );
        }}
      />
      <Route path={`${url}/`}>
        <Index channelId={channelId} />
      </Route>
    </Switch>
  );
};

export default FromFrankRightSide;

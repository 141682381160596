import { Card, Sizes, Icon } from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { QRCodeAndActions } from "../../Invites/components/QRCodeAndActions";
import { useModals } from "../../Modals";
import { CreateLinkToAuthCardModal } from "./CreateLinkToAuthCardModal";
import SendAuthCardViaEmail from "./SendAuthCardViaEmail";

const AuthCardSendMethodsModal = ({
  groupId,
  partnerName,
}: {
  groupId: string;
  partnerName: string;
}) => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useModals();

  const openSendViaEmailModal = React.useCallback(() => {
    setModal({
      type: "genericModal",
      props: {
        body: (
          <SendAuthCardViaEmail closeModal={closeModal} groupId={groupId} />
        ),
        title: t("sendViaEmail"),
      },
    });
  }, [setModal, closeModal, groupId, t]);

  const openCreateLinkModal = React.useCallback(() => {
    setModal({
      type: "genericModal",
      props: {
        body: (
          <CreateLinkToAuthCardModal
            url={`${process.env.REACT_APP_FRONTEND_URL}/sign/authorizationCard/?groupId=${groupId}`}
          />
        ),
        title: t("createALinkToAuthCard"),
      },
    });
  }, [setModal, groupId, t]);

  const openCreateQRCodeModal = React.useCallback(() => {
    setModal({
      type: "genericModal",
      props: {
        body: (
          <QRCodeAndActions
            qrCodeTitle={t("signAuthorizationCardForPartnerName", {
              partnerName,
            })}
            link={`${process.env.REACT_APP_FRONTEND_URL}/sign/authorizationCard/?groupId=${groupId}`}
          />
        ),
        title: t("qrCode"),
      },
    });
  }, [setModal, partnerName, groupId, t]);

  return (
    <>
      <div className="t-regular text-canvas-400 pb-6">
        {t("createLinkToAuthCardExplanationText", {
          interpolation: { escapeValue: false },
        })}
      </div>
      <div className="space-y-3">
        <Card pad size={Sizes.SM} onClick={openSendViaEmailModal}>
          <div className="flex flex-row justify-start items-center">
            <Icon icon="mail" size={Sizes.XL} />
            <div className="ml-3">
              <div className="t-small text-white">{t("sendViaEmail")}</div>
              <div className="t-small text-canvas-400">
                {t("sendEmailToWorkers")}
              </div>
            </div>
          </div>
        </Card>
        <Card pad size={Sizes.SM} onClick={openCreateLinkModal}>
          <div className="flex flex-row justify-start items-center">
            <Icon icon="link" size={Sizes.XL} />
            <div className="ml-3">
              <div className="t-small text-white">{t("createALink")}</div>
              <div className="t-small text-canvas-400">
                {t("createALinkExplanationText")}
              </div>
            </div>
          </div>
        </Card>
        <Card pad size={Sizes.SM} onClick={openCreateQRCodeModal}>
          <div className="flex flex-row justify-start items-center">
            <Icon icon="crop_free" size={Sizes.XL} />
            <div className="ml-3">
              <div className="t-small text-white">{t("createQRCode")}</div>
              <div className="t-small text-canvas-400">
                {t("createQRCodeExplanationText")}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AuthCardSendMethodsModal;

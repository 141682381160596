import * as React from "react";
import { useParams } from "react-router-dom";
import CustomFormResponseEdit from "../../../CustomForm/CustomFormResponseEdit";

interface RouteParams {
  id: string;
  responseId: string;
}

export function CustomFormResponseEmbed(): JSX.Element {
  const { id, responseId } = useParams<RouteParams>();
  return (
    <CustomFormResponseEdit
      customFormId={id}
      customFormResponseId={responseId}
    />
  );
}

import { Button } from "@get-frank-eng/design-system";
import React from "react";
import PendingMemberList from "../../../../PendingMembers/pages/PendingMemberList";
import PrimaryActionItem from "./PrimaryActionItem";

const MembershipRequestPrimaryActionEmbed = ({
  onUpdate,
}: {
  onUpdate: () => Promise<void>;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <PrimaryActionItem
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      expandable
    >
      <>
        <PendingMemberList slim onRefetch={onUpdate} />
        <Button onClick={() => setIsExpanded(false)}>Close</Button>
      </>
    </PrimaryActionItem>
  );
};

export default MembershipRequestPrimaryActionEmbed;

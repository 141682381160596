import * as React from "react";
import { gql, useMutation } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation CreateOnboardingForm($input: CreateOnboardingFormInput!) {
    createOnboardingForm(input: $input) {
      id
      onboardingFormJSON
    }
  }
`;

export default function useCreateOnboardingForm() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "createOnboardingForm">,
    FrankBackendTypes.MutationCreateOnboardingFormArgs
  >(mutation);

  const createOnboardingForm = React.useCallback(
    (input: FrankBackendTypes.MutationCreateOnboardingFormArgs["input"]) => {
      return invokeMutation({
        variables: { input },
      });
    },
    [invokeMutation]
  );

  return {
    createOnboardingForm,
    loading,
    error,
    called,
  };
}

import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import attachmentFragment from "../../../Attachment/dataAccess/attachmentFragment";

const QUERY = gql`
  query Partner($slug: String!) {
    partner(slug: $slug) {
      id
      name
      slug
      defaultWelcomeMessage
      onboardingFormJSON
      authorizationLanguage
      logoAttachment {
        id
        url
      }
      allowAnonymousChannel
      allowFromFrankChannel
      customResources {
        id
        title
        attachment {
          ...AttachmentFragment
        }
      }
      hasCustomForms
    }
  }
  ${attachmentFragment}
`;

export default function usePartner({ partnerSlug }: { partnerSlug: string }) {
  const { data, error, loading, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "partner">,
    FrankBackendTypes.QueryPartnerArgs
  >(QUERY, { variables: { slug: partnerSlug } });

  return {
    loading,
    error,
    partner: data?.partner,
    refetch,
  };
}

import { FormGroup, Input, Switch } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import BackAndContinue from "../components/BackAndContinue";
import ContentContainer from "../components/ContentContainer";
import useUpdateUpdateCampaign from "../dataAccess/mutations/useUpdateCampaign";
import { useTranslation } from "react-i18next";

const Settings = ({ campaign }: { campaign: FrankBackendTypes.Campaign }) => {
  const { register, handleSubmit, errors } = useForm<{
    signatureGoal: string;
  }>();
  const { updateCampaign, loadingUpdateCampaign } = useUpdateUpdateCampaign({
    campaignId: campaign.id,
  });

  const { t } = useTranslation();

  const submitForm = React.useCallback(
    async ({ signatureGoal }: { signatureGoal: string }) => {
      await updateCampaign({
        signatureGoal: Math.round(+signatureGoal),
      });
    },
    [updateCampaign]
  );

  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">{t("customizeYourSettings")}</h2>
      <p className="t-regular text-canvas-400 mb-8">
        {t("chooseCustomSettingsThatAreRightFo")}
      </p>

      <h3 className="t-regular plus my-3">{t("signatures")}</h3>

      <form onSubmit={handleSubmit(submitForm)}>
        <div className="mb-4 flex flex-col sm:flex-row sm:items-center justify-between">
          <div className="mr-6">
            <div className="t-small plus mb-1">{t("signatureGoal")}</div>
            <p className="t-small text-canvas-400">
              {t("chooseTheNumberOfSignaturesRequired")}
            </p>
          </div>
          <FormGroup label={t("goal")} id="signatureGoal" name="signatureGoal">
            <Input
              errorText={
                errors.signatureGoal && (t("signatureGoalIsRequired") as string)
              }
              type="number"
              onBlur={handleSubmit(submitForm)}
              register={register}
              defaultValue={campaign.signatureGoal}
              disabled={loadingUpdateCampaign}
              registerArgs={{
                required: true,
                min: 1,
              }}
            />
          </FormGroup>
        </div>
        <div className="mb-4 flex flex-row items-center justify-between">
          <div className="mr-6 sm:mr-16">
            <div className="t-small plus mb-1">{t("anonymousSignatures")}</div>
            <p className="t-small text-canvas-400">
              {t("byDefaultIndividualNamesWillBeLis")}
            </p>
          </div>
          <Switch
            loading={loadingUpdateCampaign}
            label="signaturesVisible"
            id="signaturesVisible"
            checked={!campaign.signaturesVisible}
            onChange={async () => {
              await updateCampaign({
                signaturesVisible: !campaign.signaturesVisible,
              });
            }}
          />
        </div>
      </form>

      <BackAndContinue workflowState={campaign.workflowState} />
    </ContentContainer>
  );
};

export default Settings;

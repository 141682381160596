"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Textarea = void 0;
const React = __importStar(require("react"));
const useComposeEventHandler_1 = require("../../hooks/useComposeEventHandler");
const useMaxLength_1 = require("../../hooks/useMaxLength");
const FormGroup_1 = require("./FormGroup");
const FormHelpText_1 = require("./FormHelpText");
const Input_1 = require("./Input");
const Textarea = (_a) => {
    var { helpTextRef, charCountRef, helpText, maxLength, register, registerArgs, onChange, onFocus, id, name, errorText, disabled } = _a, inputProps = __rest(_a, ["helpTextRef", "charCountRef", "helpText", "maxLength", "register", "registerArgs", "onChange", "onFocus", "id", "name", "errorText", "disabled"]);
    const { length, onChangeForCounter, onFocusForCounter } = (0, useMaxLength_1.useMaxLength)({
        maxLength
    });
    const [isDirty, setDirty] = React.useState(false);
    const setDirtyTrue = React.useCallback(() => setDirty(true), [setDirty]);
    const composedOnChange = (0, useComposeEventHandler_1.useComposeEventHandler)([
        onChange,
        onChangeForCounter
    ]);
    const composedOnFocus = (0, useComposeEventHandler_1.useComposeEventHandler)([
        onFocus,
        setDirtyTrue,
        onFocusForCounter
    ]);
    return (React.createElement(React.Fragment, null,
        charCountRef && (React.createElement(FormGroup_1.CharCountPortal, { elemRef: charCountRef }, maxLength && isDirty && (React.createElement(FormHelpText_1.CharCounter, { current: length, limit: maxLength })))),
        React.createElement("div", { className: `relative ${!disabled && "hoverable"}`, style: { overflow: "visible" } },
            React.createElement("textarea", Object.assign({ disabled: disabled, ref: register && register(registerArgs), onChange: composedOnChange, onFocus: composedOnFocus, id: id, rows: 5, name: name, className: (0, Input_1.inputClasses)({
                    hasError: !!errorText,
                    disabled: !!disabled
                }) }, inputProps))),
        helpTextRef && (React.createElement(FormGroup_1.HelpTextPortal, { elemRef: helpTextRef },
            helpText && React.createElement(FormHelpText_1.HelpText, null, helpText),
            errorText && React.createElement(FormHelpText_1.ErrorText, null, errorText)))));
};
exports.Textarea = Textarea;

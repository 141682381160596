import * as React from "react";
import {
  Button,
  FormGroup,
  InlineAlert,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiSelect,
  Switch,
  useToaster,
} from "@get-frank-eng/design-system";
import { useForm } from "react-hook-form";
import { useModals } from "../../Modals";
import useCreateOrganizer from "../dataAccess/hooks/mutations/useAddOrganizer";
import { FrankBackendTypes } from "frank-types";
import { useConvertAccountMutation } from "../../generated-hooks";
import sentryClient from "../../sentryClient";
import useGroupReport from "../dataAccess/hooks/queries/useGroupReport";
import { useTranslation } from "react-i18next";

type FormParams = {
  name: string;
  email: string;
};

const AddOrganizerModal = ({ partnerId }: { partnerId: string }) => {
  const { t } = useTranslation();
  const generalError = t("cannotCreateOrganizerAccountForThat");

  const getErrorText = (error: FrankBackendTypes.CreateOrganizerError) => {
    switch (error) {
      case FrankBackendTypes.CreateOrganizerError
        .CurrentOrganizerDifferentPartner:
        return generalError;
      case FrankBackendTypes.CreateOrganizerError.CurrentOrganizerSamePartner:
        return t("thisPersonIsAlreadyAnOrganizerWith");
      case FrankBackendTypes.CreateOrganizerError.CurrentWorkerDifferentPartner:
        return generalError;
      default:
        throw new Error("Error not supported");
    }
  };
  const toaster = useToaster();
  const {
    createOrganizer,
    loading: loadingCreateOrganizer,
  } = useCreateOrganizer();
  const { groups, loading: loadingGroups } = useGroupReport({ partnerId });
  const { register, handleSubmit, watch } = useForm<FormParams>();
  const { closeModal, openConfirmationModal } = useModals();
  const [
    error,
    setError,
  ] = React.useState<FrankBackendTypes.CreateOrganizerError | null>(null);

  const [
    invokeConvertAccount,
    { error: convertAccountError, loading, data },
  ] = useConvertAccountMutation();

  const [addToAllGroups, setAddToAllGroups] = React.useState(true);
  const [groupsToAddTo, setGroupsToAddTo] = React.useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const filterGroups = (inputValue: string) => {
    return groups.objects
      .filter((g) =>
        g.groupName.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((g) => ({
        label: g.groupName,
        value: g.id,
      }));
  };

  const loadOptions = async (searchString) => filterGroups(searchString);

  const name = watch("name");
  const email = watch("email");

  const submitCreateOrganizer = async (formData: FormParams) => {
    try {
      const { error, user } = await createOrganizer({
        name: formData.name,
        email: formData.email.trim(),
        partnerId,
        groupIds: groupsToAddTo.map((g) => g.value),
        addToAllGroups,
      });
      if (error === FrankBackendTypes.CreateOrganizerError.None) {
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("successfullyCreatedOrganizer"),
        });
        closeModal();
      } else if (
        error ===
        FrankBackendTypes.CreateOrganizerError.CurrentWorkerSamePartner
      ) {
        const confirm = await openConfirmationModal({
          actionText: t("confirm"),
          // i18n SKIPPED
          bodyText: (
            <div data-cy="worker-already-exists-text">
              The email you entered matches the worker account
              <span className="t-small plus"> {user.name} </span>
              in
              <span className="t-small plus">
                {" "}
                {user.onboardings[0].group.name}.{" "}
              </span>
              Would you like to make
              <span className="t-small plus"> {user.name} </span>
              at
              <span className="t-small plus"> {formData.email} </span>
              an organizer? This action cannot be undone.
            </div>
          ),
          title: t("changeAccountType"),
        });
        if (confirm) {
          await invokeConvertAccount({
            variables: { input: { email: formData.email, partnerId } },
          });
          toaster.addToast({
            intent: Intent.SUCCESS,
            children: t("success"),
          });
          closeModal();
        }
      } else {
        setError(error);
      }
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });

      sentryClient.captureException(e);

      closeModal();
    }
  };

  const form = (
    <form
      onSubmit={handleSubmit(submitCreateOrganizer)}
      noValidate
      className="space-y-8"
      id="create-organizer-form"
    >
      <section>
        <FormGroup name="name" label={t("organizerName")} id="name">
          <Input
            register={register}
            id="name"
            maxLength={120}
            placeholder={t("organizerName")}
          />
        </FormGroup>
      </section>
      <section>
        <FormGroup name="email" label={t("organizerEmail")} id="email">
          <Input
            register={register}
            id="email"
            maxLength={120}
            placeholder={t("organizerEmail")}
          />
        </FormGroup>
      </section>
      <section>
        <div className="t-small plus pb-1">
          {t("whichGroupsShouldThisOrganizerBeIn")}
        </div>
        <div className="t-mini text-canvas-400">
          {t("thisOrganizerWillStillHaveAccessTo")}
        </div>
        <div className="space-y-3 pt-4">
          <div className="flex justify-between items-center">
            <div className="t-small">{t("allCurrentGroups")}</div>
            <Switch
              checked={addToAllGroups}
              label={t("addToAllCurrentGroups")}
              onChange={() => {
                setAddToAllGroups(!addToAllGroups);
                setGroupsToAddTo([]);
              }}
              disabled={loading}
            />
          </div>
          {!addToAllGroups && (
            <div>
              <MultiSelect
                loadOptions={loadOptions}
                defaultOptions={groups?.objects.map((g) => ({
                  label: g.groupName,
                  value: g.id,
                }))}
                onChange={(newValue) => setGroupsToAddTo(newValue)}
                isLoading={false}
                disabled={addToAllGroups}
              />
              <div className="t-mini text-canvas-400 pt-2 pb-4"></div>
            </div>
          )}
        </div>
      </section>
    </form>
  );

  return (
    <>
      <ModalHeader title={t("addAnOrganizer")} />
      <ModalBody>
        {error && (
          <div className="py-4 w-full">
            <InlineAlert
              intent={Intent.FAILURE}
              title={t("somethingWentWrong")}
            >
              {getErrorText(error)}
            </InlineAlert>
          </div>
        )}
        {form}
      </ModalBody>
      <ModalFooter>
        <Button
          dataCy="submit-create-organizer-form"
          form="create-organizer-form"
          buttonStyle="brand"
          disabled={!email || !name}
          loading={loadingCreateOrganizer}
        >
          {t("save")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddOrganizerModal;

import { Avatar } from "@get-frank-eng/design-system/dist/components/Avatar";
import { Sizes } from "@get-frank-eng/design-system/dist/constants";
import React from "react";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

const WelcomeBanner = () => {
  const { currentGroup, partner } = useCurrentUserData();
  return (
    <div className="w-full text-left align-top pt-4 pb-6 px-2 h-full border rounded border-canvas-500 flex lg:flex-col lg:items-center">
      <div className="mx-4 my-auto">
        <Avatar
          size={Sizes.XL}
          src={currentGroup.logo?.url || partner.logoAttachment.url}
        />
      </div>
      <div className="flex-col mx-2 pt-5 lg:pt-2">
        <div className="lg:text-large plus lg:text-center pb-1">
          {currentGroup.name}
        </div>
        <div className="t-small text-canvas-400 lg:text-center">
          {currentGroup.welcomeMessage}
        </div>
      </div>
    </div>
  );
};

export default WelcomeBanner;

import http from "../axiosClient";

type UploadResp = { attachmentId: string; file: string };

async function uploadSingleFile(file: File): Promise<UploadResp> {
  const formData = new FormData();
  formData.append("file", file);
  const res = await http.post(
    `${process.env.REACT_APP_API_URL}/attachments`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res.data;
}

export default async function uploadAttachments(
  files: File[]
): Promise<UploadResp[]> {
  return Promise.all(files.map(uploadSingleFile));
}

export const openFileChooser = async (onChange) => {
  const input = document.createElement("input");
  input.onchange = onChange;
  input.type = "file";
  input.click();
};

import { CustomFormResponseDocument } from "frank-types/dist/frank-backend-types";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomFormResponseEdit from "../../../CustomForm/CustomFormResponseEdit";
import { useModals } from "../../../Modals";
import RelativeTimeDisplay from "../RelativeTime";
import DocumentItem from "./DocumentItem";

const CustomFormResponseDocumentItem = ({
  response,
  onUpdate,
}: {
  response: CustomFormResponseDocument;
  onUpdate: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useModals();
  const onComplete = () => {
    closeModal();
    onUpdate();
  };
  const viewResponse = React.useCallback(
    () =>
      setModal({
        type: "customFormModal",
        props: {
          body: (
            <CustomFormResponseEdit
              customFormId={response.customFormId}
              customFormResponseId={response.id}
              viewOnly={response.submittedAt}
              onComplete={onComplete}
            />
          ),
          title: t("reviewSubmission"),
        },
      }),
    [setModal, response]
  );
  return (
    <DocumentItem
      key={response.id}
      title={`${response.customFormTitle}${
        response.submittedAt ? "" : ` ${t("formDraftTag")}`
      }`}
      icon="content_paste"
      subheading={
        response.submittedAt ? (
          <span>
            {t("submitted")} <RelativeTimeDisplay date={response.submittedAt} />
          </span>
        ) : (
          <span>
            {t("saved")} <RelativeTimeDisplay date={response.updatedAt} />
          </span>
        )
      }
      onView={viewResponse}
      label={t("formSubmission")}
    />
  );
};

export default CustomFormResponseDocumentItem;

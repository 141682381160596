import {
  DocumentType,
  ResourceDocument,
} from "frank-types/dist/frank-backend-types";
import { TFunction, useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import { convertJsonDateToString } from "../../util";
import RelativeTimeDisplay from "../RelativeTime";
import DocumentItem from "./DocumentItem";

function getLabelForContentType(
  t: TFunction<"translations", undefined>,
  contentType: string
): string {
  switch (contentType) {
    case "application/pdf":
      return t("pdf");
    case "blog":
      return t("blog");
    default:
      return t("resource");
  }
}

const ResourceDocumentItem = ({ resource }: { resource: ResourceDocument }) => {
  const { t } = useTranslation();
  const { currentGroup, partner } = useCurrentUserData();
  const thumbnail =
    resource.thumbnailUrl ||
    currentGroup.logo?.url ||
    partner.logoAttachment.url;
  const openDocument = () => {
    window.open(resource.url, "_blank");
  };
  return (
    <DocumentItem
      key={resource.id}
      title={resource.title}
      icon="file_present"
      subheading={
        resource.publishedAt ? (
          <span>
            {t("uploaded")} <RelativeTimeDisplay date={resource.publishedAt} />
          </span>
        ) : null
      }
      onView={openDocument}
      label={getLabelForContentType(t, resource.contentType)}
      thumbnailUrl={thumbnail}
    />
  );
};

export default ResourceDocumentItem;

import * as React from "react";
import {
  Button,
  FormGroup,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import { useForm } from "react-hook-form";
import { useModals } from "../../Modals";
import useCreateOrUpdateTask from "../dataAccess/mutations/useCreateTask";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

type FormParams = {
  title: string;
  description?: string;
  note?: string;
  dueDate?: string;
};

const CreateTaskModal = ({
  afterSubmit,
  existingTask,
  groupId,
}: {
  afterSubmit: () => any;
  existingTask?: FrankBackendTypes.Task;
  groupId: string;
}) => {
  const { t } = useTranslation();
  const { closeModal, setModal } = useModals();
  const { register, errors, handleSubmit } = useForm<FormParams>();
  const toaster = useToaster();
  const { createOrUpdateTask, error, loading } = useCreateOrUpdateTask();
  const [isDirty, setIsDirty] = React.useState(false);

  const submit = React.useCallback(
    async (formValues: FormParams) => {
      try {
        const task = await createOrUpdateTask({
          title: formValues.title,
          description: formValues.description,
          taskId: existingTask?.id,
        });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: existingTask ? t("taskUpdated") : t("taskCreated"),
        });
        await afterSubmit();
        closeModal();
        setModal({
          type: "assignTaskModal",
          props: {
            task,
            afterSubmit,
            groupId,
          },
        });
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrong"),
        });
      }
    },
    [
      createOrUpdateTask,
      existingTask,
      toaster,
      afterSubmit,
      closeModal,
      setModal,
      groupId,
      t,
    ]
  );

  const form = (
    <form onSubmit={handleSubmit(submit)} id="create-task-form">
      <FormGroup label={t("title")} name="title" id="title">
        <Input
          onChange={() => setIsDirty(true)}
          defaultValue={existingTask?.title}
          register={register}
          errorText={errors.title && errors.title.message}
          maxLength={200}
          registerArgs={{
            required: t("yourTaskNeedsATitle"),
            maxLength: t("taskTitlesHaveAMaximumLengthOf200"),
          }}
        />
      </FormGroup>
      <FormGroup label={t("description")} name="description" id="description">
        <Textarea
          onChange={() => setIsDirty(true)}
          defaultValue={existingTask?.description}
          register={register}
          errorText={errors.description && errors.description.message}
          maxLength={400}
          registerArgs={{
            maxLength: t("taskDescriptionsHaveAMaximumLength"),
          }}
        />
      </FormGroup>
      {existingTask && (
        <div className="t-mini">{t("editingThisTaskWillAlsoEditItFor")}</div>
      )}
    </form>
  );

  return (
    <>
      <ModalHeader title={existingTask ? t("updateTask") : t("createATask")} />
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        <Button
          loading={loading}
          form="create-task-form"
          buttonStyle="brand"
          disabled={!isDirty}
        >
          {t("next")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default CreateTaskModal;

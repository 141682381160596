import { Intent, Select, useToaster } from "@get-frank-eng/design-system";
import * as React from "react";
import { DateIntervals } from "../../utils/Date";
import SecurityFeatureRow from "../components/SecurityFeatureRow";
import { useTranslation } from "react-i18next";

export default ({
  currentExpiration,
  loading,
  update,
  token,
  idModifier = "",
}) => {
  const { t } = useTranslation();
  const toaster = useToaster();

  const [hasClickedUpdate, setHasClickedUpdate] = React.useState(false);

  const updateExpiration = async (expiration) => {
    try {
      await update({ expiration }, token);
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("updateSuccessful"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
    }
  };

  return (
    <SecurityFeatureRow
      id={`expiration${idModifier}`}
      title={t("setExpiration")}
      defaultChecked={!!currentExpiration}
      onToggle={(isChecked, dirty) => {
        if (!isChecked && dirty) {
          updateExpiration(null);
          return;
        }
        if (!isChecked && currentExpiration) {
          updateExpiration(null);
        }
      }}
      contentToShowWhenChecked={(setDirty) => {
        const select = (
          <div className="w-64">
            <Select
              autoFocus
              name="expiration"
              disabled={loading}
              onChange={(e: React.SyntheticEvent<HTMLSelectElement>) => {
                setDirty(true);
                updateExpiration(e.currentTarget.value);
              }}
              defaultValue={currentExpiration || null}
            >
              <optgroup label={t("expiresIn")}>
                <option value={null}>{t("selectAValue")}</option>
                <option value={DateIntervals.ONE_HOUR}>
                  {t("hourWithCount", { count: 1 })}
                </option>
                <option value={DateIntervals.ONE_DAY}>
                  {t("hourWithCount", { count: 24 })}
                </option>
                <option value={DateIntervals.THREE_DAYS}>
                  {t("dayWithCount", { count: 3 })}
                </option>
                <option value={DateIntervals.ONE_WEEK}>
                  {t("weekWithCount", { count: 1 })}
                </option>
              </optgroup>
            </Select>
          </div>
        );

        if (hasClickedUpdate) {
          return select;
        }

        return (
          <>
            {currentExpiration ? (
              <div className="t-small mt-2">
                <span>{t("expiresWithTime", { time: currentExpiration })}</span>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setHasClickedUpdate(true);
                  }}
                  className="ml-2 t-small plus"
                >
                  {t("update")}
                </button>
              </div>
            ) : (
              select
            )}
          </>
        );
      }}
    >
      {t("setThisInviteLinkToExpireAfterAS")}
    </SecurityFeatureRow>
  );
};

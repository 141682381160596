import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { recentlyReviewedCoworkersRestrictions } from "../filters";
import { trustedAtDesc } from "../orders";
import { pendingMemberDetailFragment } from "./usePendingMembersListData";

const QUERY = gql`
  query RecentlyReviewedCoworkersList(
    $pagination: PaginationArgumentDTO!
    $filter: CoworkerFilterDTO!
    $order: CoworkerOrderArgumentDTO
  ) {
    coworkers(pagination: $pagination, filter: $filter, order: $order) {
      ...PendingMemberDetailFragment
    }
  }
  ${pendingMemberDetailFragment}
`;

export default function useRecentlyReviewedMembersListData({
  perPage,
}: {
  perPage: number;
}) {
  const {
    loading: recentlyReviewedLoading,
    data: recentlyReviewedData,
    refetch: refetchRecentlyReviewed,
    error: recentlyReviewedError,
    fetchMore,
  } = useQuery<
    {
      coworkers: FrankBackendTypes.PaginatedCoworker;
    },
    FrankBackendTypes.QueryCoworkersArgs
  >(QUERY, {
    fetchPolicy: "network-only",
    variables: {
      pagination: { page: 0, perPage },
      filter: recentlyReviewedCoworkersRestrictions,
      order: trustedAtDesc,
    },
  });

  const fetchMoreRecentlyReviewedMembers = React.useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          perPage,
          page: Math.ceil(
            recentlyReviewedData?.coworkers.objects.length / perPage
          ),
        },
      },
    });
  }, [fetchMore, perPage, recentlyReviewedData]);

  return {
    recentlyReviewedData: recentlyReviewedData?.coworkers,
    recentlyReviewedLoading,
    recentlyReviewedError,
    refetchRecentlyReviewed,
    fetchMoreRecentlyReviewedMembers,
  };
}

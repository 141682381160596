import React from "react";
import ActionListItem from "./ActionListItem";
import { useModals } from "../../../Modals";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import { useTranslation } from "react-i18next";

const InviteCoworkersActionItem = () => {
  const { t } = useTranslation();
  const { currentGroup } = useCurrentUserData();
  const { setModal } = useModals();
  const openModal = () => {
    setModal({
      type: "defaultInviteModal",
      props: {},
    });
  };
  const actions = [
    {
      label: t("invite"),
      action: openModal,
    },
  ];
  return (
    <ActionListItem
      title={t("inviteCoworkersToGroup", { groupName: currentGroup.name })}
      subheading={t("getCoworkersSetup")}
      actions={actions}
      defaultAction={openModal}
    />
  );
};

export default InviteCoworkersActionItem;

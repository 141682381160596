import {
  Checkbox,
  Delay,
  FormGroup,
  Icon,
  InlineAlert,
  Intent,
  PulseCard,
  Select,
  Sizes,
  useToaster,
} from "@get-frank-eng/design-system";
import { Button } from "@get-frank-eng/design-system/dist/components/Button";
import { Card } from "@get-frank-eng/design-system/dist/components/Card";
import { EmptyState } from "@get-frank-eng/design-system/dist/components/EmptyState";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import {
  useAddFormToGroupMutation,
  useAvailableCustomFormsByPartnerQuery,
  useCustomFormsByGroupQuery,
} from "../../../generated-hooks";
import { useModals } from "../../../Modals";
import sentry from "../../../sentryClient";
import useCustomFormsPathing from "./useCustomFormsPathing";

const formsPerPage = 200;

const FormSettings = ({
  action,
  formId,
}: {
  action: ({
    customFormId,
    justOrganizers,
  }: {
    customFormId: string;
    justOrganizers: boolean;
  }) => Promise<void>;
  formId: string;
}) => {
  const [justOrganizers, setJustOrganizers] = React.useState(0);
  const { t } = useTranslation();

  return (
    <div className="space-y-4">
      <div className="t-small">{t("checkBoxForOrganizerAccess")}</div>
      <label
        htmlFor="visibleToWorkers"
        className="my-3 flex flex-row space-x-2 items-center"
      >
        <Checkbox
          id="visibleToWorkers"
          name="visibleToWorkers"
          value={justOrganizers}
          onChange={(evt) => {
            if (evt.currentTarget.checked) {
              setJustOrganizers(1);
            } else {
              setJustOrganizers(0);
            }
          }}
        />
        <div className="t-small plus">{t("organizerOnlyAccess")}</div>
      </label>
      <div className="pt-4">
        <Button
          onClick={() =>
            action({
              customFormId: formId,
              justOrganizers: !!justOrganizers,
            })
          }
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const FormCard: React.FunctionComponent<{
  title: string;
  description: string;
  organizerOnly?: boolean;
}> = ({ title, description, organizerOnly, children }) => {
  const { t } = useTranslation();
  return (
    <Card pad>
      <div className="space-y-2">
        <h2 className="text-left t-small plus text-clamping-2">{title}</h2>
        <div className="t-small text-clamping text-clamping-2">
          {description}
        </div>
      </div>
      <div className="flex-grow" />
      <div className="mt-6 mb-2 text-center">
        {organizerOnly !== undefined &&
          (organizerOnly === true ? (
            <span className="text-left plus t-mini px-3 py-1 inline">
              <Icon icon="visibility" size={Sizes.SM} aria-hidden="true" />
              &nbsp;{t("visibleToOrganizers")}
            </span>
          ) : (
            <span className="text-left plus t-mini px-3 py-1 inline">
              <Icon icon="visibility" size={Sizes.SM} aria-hidden="true" />
              &nbsp;{t("visibleToAllWorkers")}
            </span>
          ))}
      </div>
      <div className="pt-2">{children}</div>
    </Card>
  );
};

const FormsLoading = () => (
  <Delay delay={500}>
    <section className="pt-8 space-y-4">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        <PulseCard />
        <PulseCard />
        <PulseCard />
      </div>
    </section>
  </Delay>
);

export default function CustomFormList(): JSX.Element {
  const { groupId }: { groupId?: string } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { generateCustomFormUrl } = useCustomFormsPathing();
  const { currentGroup, partner, myProfile } = useCurrentUserData();
  const [selectedGroupId, setSelectedGroupId] = React.useState(
    groupId ?? currentGroup.id
  );
  const [
    invokeAddFormToGroup,
    { error: addFormToGroupError, loading: addFormToGroupLoading },
  ] = useAddFormToGroupMutation();
  const changeGroup = React.useCallback(
    (newGroupId: string) => {
      if (newGroupId !== selectedGroupId) {
        setSelectedGroupId(newGroupId);
      }
    },
    [selectedGroupId, setSelectedGroupId]
  );

  React.useEffect(() => {
    setSelectedGroupId(groupId ?? currentGroup.id);
  }, [groupId]);

  // TODO: implement pagination
  const {
    data: customFormData,
    loading,
    error,
    refetch,
  } = useCustomFormsByGroupQuery({
    variables: {
      groupId: selectedGroupId ?? groupId ?? currentGroup.id,
      pagination: { page: 0, perPage: formsPerPage },
    },
  });

  const toaster = useToaster();
  const { openConfirmationModal, setModal, closeModal } = useModals();

  // TODO: implement pagination
  const {
    data,
    loading: availFormsLoading,
    error: availFormsError,
    refetch: refetchAvailForms,
  } = useAvailableCustomFormsByPartnerQuery({
    variables: {
      partnerId: partner.id,
      groupId: selectedGroupId ?? groupId ?? currentGroup.id,
      pagination: { page: 0, perPage: formsPerPage },
    },
  });

  const refetchAll = React.useCallback(async () => {
    await refetchAvailForms();
    await refetch();
  }, [refetchAvailForms, refetch]);

  const setFormSettingsModal = React.useCallback(
    ({ formId }: { formId: string }) => {
      setModal({
        type: "genericModal",
        props: {
          body: <FormSettings formId={formId} action={addFormToGroup} />,
        },
      });
    },
    [setModal, selectedGroupId]
  );

  const addFormToGroup = React.useCallback(
    async ({
      customFormId,
      justOrganizers,
    }: {
      customFormId: string;
      justOrganizers: boolean;
    }) => {
      {
        t("customFormsForGroupName", {
          groupName: myProfile.groups.find((g) => g.id === selectedGroupId)
            .name,
        });
      }
      const confirm = await openConfirmationModal({
        title: t("confirmAddFormToGroup", {
          groupName: myProfile.groups.find((g) => g.id === selectedGroupId)
            .name,
        }),
        bodyText: t("confirmAddFormToGroupWarning", {
          visible: !justOrganizers
            ? t("visibleToWorkersAndOrganizers")
            : t("visibleToOnlyOrganizers"),
        }),
        actionText: "Continue",
      });

      if (!confirm) {
        return;
      }
      try {
        await invokeAddFormToGroup({
          variables: {
            groupId: selectedGroupId,
            customFormId,
            visibleToWorkers: !justOrganizers,
          },
        });
        toaster.addToast({
          children: t("success"),
          intent: Intent.SUCCESS,
        });
        await refetchAll();
      } catch (e) {
        toaster.addToast({
          children: t("somethingWentWrong"),
          intent: Intent.FAILURE,
        });
        sentry.captureException(e);
      }
      closeModal();
    },
    [selectedGroupId, invokeAddFormToGroup]
  );

  const availableCustomForms = data?.availableCustomFormsByPartner;

  const customFormsByGroup = (loading, error) => {
    if (loading) {
      return <FormsLoading />;
    }

    if (error) {
      return (
        <div className="max-w-xl">
          <InlineAlert
            title="Error loading forms for group"
            intent={Intent.FAILURE}
            actions={
              <Button size={Sizes.SM} onClick={() => refetch()}>
                Reload
              </Button>
            }
          />
        </div>
      );
    }

    if (customFormData.customFormsByGroup.total === 0) {
      return <EmptyState title={t("noCustomFormsForGroup")} />;
    }

    return (
      <div className="space-y-6">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {customFormData?.customFormsByGroup.objects.map((formGroup) => (
            <FormCard
              title={formGroup.customForm.title}
              description={formGroup.customForm.description}
              key={formGroup.customForm.id}
              organizerOnly={!formGroup.visibleToWorkers}
            >
              <div className="flex flex-col space-y-2">
                <Button
                  size={Sizes.SM}
                  onClick={() =>
                    history.push(
                      generateCustomFormUrl(
                        selectedGroupId,
                        formGroup.customForm.id
                      )
                    )
                  }
                >
                  {t("manageCustomForms")}
                </Button>
              </div>
            </FormCard>
          ))}
        </div>
      </div>
    );
  };

  const availCustomForms = (loading, error) => {
    if (loading) {
      return <FormsLoading />;
    }
    if (error) {
      return (
        <div className="max-w-xl">
          <InlineAlert
            title="Error loading available forms"
            intent={Intent.FAILURE}
            actions={
              <Button size={Sizes.SM} onClick={() => refetchAvailForms()}>
                {t("tryAgain")}
              </Button>
            }
          />
        </div>
      );
    }

    if (availableCustomForms?.total === 0) {
      return <EmptyState title={t("noCustomFormsForPartner")} />;
    }

    return (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {data?.availableCustomFormsByPartner.objects.map((form) => (
          <FormCard
            title={form.title}
            description={form.description}
            key={form.id}
          >
            <div className="flex flex-col space-y-2">
              <Button
                size={Sizes.SM}
                onClick={() => setFormSettingsModal({ formId: form.id })}
              >
                {t("addCustomFormToGroup")}
              </Button>
            </div>
          </FormCard>
        ))}
      </div>
    );
  };

  return (
    <div className="pb-48 sm:pb-12 max-w-6xl mx-auto w-full space-y-4">
      <section className="flex flex-row space-x-8 items-center">
        <FormGroup name="groupId" label={t("chooseGroup")} id="groupId">
          <Select
            options={myProfile.groups.map((g) => ({
              value: g.id,
              label: g.name,
              selected: g.id === groupId,
            }))}
            id="groupId"
            placeholder={t("selectAGroup")}
            defaultValue={selectedGroupId}
            onChange={(e) => changeGroup(e.currentTarget.value)}
            helpText={t("selectGroupForForms")}
          />
        </FormGroup>
      </section>
      <section className="pt-8 space-y-4">
        <h2 className="t-large plus">
          {t("customFormsForGroupName", {
            groupName: myProfile.groups.find((g) => g.id === selectedGroupId)
              .name,
          })}
        </h2>
        {customFormsByGroup(loading, error)}
      </section>
      <section className="pt-8 space-y-4">
        <div>
          <h2 className="t-large plus">{t("formLibrary")}</h2>
          <div className="t-small max-w-2xl">
            {t("customFormsForPartnerInstructions", {
              groupName: myProfile.groups.find((g) => g.id === selectedGroupId)
                .name,
            })}
          </div>
        </div>
        {availCustomForms(availFormsLoading, availFormsError)}
      </section>
    </div>
  );
}

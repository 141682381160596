import * as React from "react";
import classNames from "classnames";
import Loading from "../../components/Loading";
import useGetEmailPreferences from "../dataAccess/queries/useEmailPreferences";
import useUpdateEmailPreferences from "../dataAccess/mutations/useMuteEmail";
import { useModals } from "../../Modals";
import {
  Card,
  InlineAlert,
  Intent,
  Switch,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { WorkerType } from "frank-types/dist/frank-backend-types";

const EmailPreferences = () => {
  const {
    emailPreferences,
    loadingEmailPreferences,
    errorEmailPreferences,
    refetchEmailPreferences,
  } = useGetEmailPreferences();

  const {
    muteEmail,
    unmuteEmail,
    muteAll,
    unmuteAll,
  } = useUpdateEmailPreferences(refetchEmailPreferences);

  const { myProfile } = useCurrentUserData();

  const isOrganizer = myProfile.workerType === WorkerType.Organizer;

  const { openConfirmationModal } = useModals();

  const { t } = useTranslation();

  if (loadingEmailPreferences) {
    return <Loading />;
  }

  if (errorEmailPreferences) {
    throw new Error(errorEmailPreferences.message);
  } else if (errorEmailPreferences) {
    return (
      <InlineAlert intent={Intent.FAILURE} title={t("serverError")}>
        {t("somethingWentWrongTryingToLoadEmailPreferences")}
      </InlineAlert>
    );
  }

  return (
    <div className="flex flex-col md:flex-row space-x-8">
      <div className="flex-grow space-y-10 pb-0 md:pb-12">
        <div>
          <p className="t-large plus pb-6">{t("allChannels")}</p>
          <EmailPreferenceRow
            title={t("@Mentions")}
            name={FrankBackendTypes.EmailTemplates.Mention}
            id={FrankBackendTypes.EmailTemplates.Mention}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.Mention
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
          <EmailPreferenceRow
            title={t("discussionThreads")}
            name={FrankBackendTypes.EmailTemplates.UpdateInThread}
            id={FrankBackendTypes.EmailTemplates.UpdateInThread}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.UpdateInThread
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
          <EmailPreferenceRow
            title={t("polls")}
            name={FrankBackendTypes.EmailTemplates.PollPublished}
            id={FrankBackendTypes.EmailTemplates.PollPublished}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.PollPublished
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
        </div>
        {isOrganizer && (
          <div>
            <p className="t-large plus pb-6">{t("organizerEmails")}</p>
            <EmailPreferenceRow
              title={t("formSubmission")}
              name={
                FrankBackendTypes.EmailTemplates.FormResponseSubmissionOrganizer
              }
              id={
                FrankBackendTypes.EmailTemplates.FormResponseSubmissionOrganizer
              }
              checked={
                !emailPreferences.mutedEmails.includes(
                  FrankBackendTypes.EmailTemplates
                    .FormResponseSubmissionOrganizer
                )
              }
              mute={muteEmail}
              unmute={unmuteEmail}
              disabled={emailPreferences.muteAll}
            />
            <EmailPreferenceRow
              title={t("newAuthCardSignature")}
              name={
                FrankBackendTypes.EmailTemplates.NewAuthorizationCardSignature
              }
              id={
                FrankBackendTypes.EmailTemplates.NewAuthorizationCardSignature
              }
              checked={
                !emailPreferences.mutedEmails.includes(
                  FrankBackendTypes.EmailTemplates.NewAuthorizationCardSignature
                )
              }
              mute={muteEmail}
              unmute={unmuteEmail}
              disabled={emailPreferences.muteAll}
            />
          </div>
        )}
        <div>
          <p className="t-large plus pb-6">{t("groupActivity")}</p>
          <EmailPreferenceRow
            title={t("newPetitionsPublished")}
            name={FrankBackendTypes.EmailTemplates.NewCampaignPublished}
            id={FrankBackendTypes.EmailTemplates.NewCampaignPublished}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.NewCampaignPublished
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
          <EmailPreferenceRow
            title={t("milestones")}
            name={FrankBackendTypes.EmailTemplates.GoalReachedMember}
            id={FrankBackendTypes.EmailTemplates.GoalReachedMember}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.GoalReachedMember
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
          <EmailPreferenceRow
            title={t("newMembers")}
            name={FrankBackendTypes.EmailTemplates.InviteAccepted}
            id={FrankBackendTypes.EmailTemplates.InviteAccepted}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.InviteAccepted
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
          <EmailPreferenceRow
            title={t("membersForReview")}
            name={FrankBackendTypes.EmailTemplates.InWaitingRoom}
            id={FrankBackendTypes.EmailTemplates.InWaitingRoom}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.InWaitingRoom
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
        </div>
        <div>
          <p className="t-large plus pb-6">{t("campaignsYouOrganize")}</p>
          <EmailPreferenceRow
            title={t("comments")}
            name={FrankBackendTypes.EmailTemplates.NewDocumentComment}
            id={FrankBackendTypes.EmailTemplates.NewDocumentComment}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.NewDocumentComment
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
          <EmailPreferenceRow
            title={t("signatures")}
            name={FrankBackendTypes.EmailTemplates.NewCampaignSupporter}
            id={FrankBackendTypes.EmailTemplates.NewCampaignSupporter}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.NewCampaignSupporter
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
          <EmailPreferenceRow
            title={t("milestones")}
            name={FrankBackendTypes.EmailTemplates.GoalReachedOrganizer}
            id={FrankBackendTypes.EmailTemplates.GoalReachedOrganizer}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.GoalReachedOrganizer
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
        </div>
        <div>
          <p className="t-large plus pb-6">{t("campaignsYouSupport")}</p>
          <EmailPreferenceRow
            title={t("milestones")}
            name={FrankBackendTypes.EmailTemplates.GoalReachedSupporter}
            id={FrankBackendTypes.EmailTemplates.GoalReachedSupporter}
            checked={
              !emailPreferences.mutedEmails.includes(
                FrankBackendTypes.EmailTemplates.GoalReachedSupporter
              )
            }
            mute={muteEmail}
            unmute={unmuteEmail}
            disabled={emailPreferences.muteAll}
          />
        </div>
      </div>
      <div className="max-w-xs py-12 md:py-0">
        <Card pad>
          <p className="t-regular plus">{t("muteAllEmails")}</p>
          <div className="flex flex-row justify-between flex-grow items-center pt-2">
            <p className="t-small text-canvas-400 pr-6">
              {t(
                "youWillStillReceiveCriticalEmailsForThingsLikeAccountSecurity"
              )}
            </p>
            <Switch
              onChange={async () => {
                if (emailPreferences.muteAll) {
                  unmuteAll();
                } else {
                  const confirm = await openConfirmationModal({
                    bodyText: t(
                      "youMayMissImportantUpdatesOrDiscussionAboutActionsYourCoworkersArePlanningToImproveYourWorkplace"
                    ) as string,
                    actionText: t("confirm"),
                    title: t("areYouSureYouWantToMuteAllNotifications"),
                  });

                  if (!confirm) {
                    return;
                  }
                  muteAll();
                }
              }}
              checked={emailPreferences.muteAll}
              label="muteAll"
              id="muteAll"
              disabled={false}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default EmailPreferences;

const EmailPreferenceRow = ({
  title,
  name,
  id,
  checked,
  mute,
  unmute,
  disabled,
}) => {
  return (
    <div
      className={classNames([
        "flex flex-row justify-between flex-grow py-5 items-center border-b",
      ])}
    >
      <p className="t-small self-center">{title}</p>
      <Switch
        onChange={() => {
          if (checked) {
            mute(name);
          } else {
            unmute(name);
          }
        }}
        checked={checked}
        label={name}
        id={id}
        disabled={disabled}
      />
    </div>
  );
};

import {
  Button,
  ScreenSizes,
  Sizes,
  useBreakpoint,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { Link } from "react-router-dom";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import Loading from "../../components/Loading";
import { useShareOrCopyAndCreateInvite } from "../../Invites/dataAccess/queries/mutations/useCreateInviteLink";
import { useModals } from "../../Modals";
import useGroupReport from "../dataAccess/hooks/queries/useGroupReport";
import { useTranslation } from "react-i18next";
import { QRInviteModal } from "../../Invites/QRInviteModal";

export default function ListGroups({ partnerId }: { partnerId: string }) {
  const { t } = useTranslation();
  const { refetchCurrentUserData } = useCurrentUserData();
  const { groups, loading, refetch } = useGroupReport({ partnerId });
  const isMobile = useBreakpoint() <= ScreenSizes.SM;
  const { setModal } = useModals();
  const supportsShare = !!navigator.share;
  const {
    loading: loadingCreateInvite,
    createInviteLink,
  } = useShareOrCopyAndCreateInvite({
    isMobile,
    supportsShare,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Button
        onClick={() =>
          setModal({
            type: "createGroupModal",
            props: {
              partnerId,
              afterSubmit: async () => {
                await refetch();
                await refetchCurrentUserData();
              },
            },
          })
        }
        buttonStyle="brand"
      >
        {t("createGroup")}
      </Button>
      <div className="h-6" />
      <div className="overflow-x-auto rounded border">
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-left p-2">{t("name")}</th>
              <th className="text-left p-2">{t("members")}</th>
              <th className="text-left p-2">{t("onboarding")}</th>
              <th className="text-left p-2">{t("invited")}</th>
              <th className="text-left p-2">{t("inviteToGroup")}</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-canvas-500">
            {groups.objects?.map((g) => (
              <tr key={g.id}>
                <td className="p-2">
                  <Link className="underline" to={`./groups/${g.id}`}>
                    {g.groupName}
                  </Link>
                </td>
                <td className="p-2">{g.member}</td>
                <td className="p-2">{g.onboarding}</td>
                <td className="p-2">{g.invited}</td>
                <td className="p-2">
                  <div className="space-x-2">
                    <Button
                      buttonStyle="outline"
                      onClick={() => createInviteLink({ groupId: g.id })}
                      size={Sizes.SM}
                      className={loadingCreateInvite && "cursor-wait"}
                    >
                      {t("copyInvite")}
                    </Button>
                    <Button
                      buttonStyle="outline"
                      onClick={() =>
                        setModal({
                          type: "genericModal",
                          props: {
                            body: (
                              <QRInviteModal
                                groupId={g.id}
                                groupName={g.groupName}
                              />
                            ),
                            title: t("qrCodeInvite"),
                          },
                        })
                      }
                      size={Sizes.SM}
                      className={loadingCreateInvite && "cursor-wait"}
                    >
                      {t("qrCode")}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const UPDATE_GROUP = gql`
  mutation UpdateGroupName($input: UpdateGroupName!) {
    updateGroupName(input: $input) {
      id
    }
  }
`;

export default function useUpdateGroupName() {
  const [invokeUpdateGroupName, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationUpdateGroupNameArgs
  >(UPDATE_GROUP);

  const updateGroupName = React.useCallback(
    async ({ name }: { name: string }) => {
      await invokeUpdateGroupName({
        variables: {
          input: {
            name,
          },
        },
      });
    },
    [invokeUpdateGroupName]
  );

  return {
    updateGroupName,
    loading,
    called,
    error,
  };
}

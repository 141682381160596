import * as React from "react";
import { useModals } from "../Modals";
import { Button, ModalBody, ModalHeader } from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";

export const useConfirmEmailModal = () => {
  const { setModal } = useModals();

  const openConfirmEmailModal = React.useCallback(
    ({
      workerTwo,
      invite,
      email,
    }: {
      workerTwo?: boolean;
      invite?: boolean;
      email?: string;
    }) => {
      return new Promise((resolve) => {
        setModal({
          afterClose: () => resolve(false),
          type: "confirmEmailModal",
          props: { action: () => resolve(true), workerTwo, invite, email },
        });
      });
    },
    [setModal]
  );
  return openConfirmEmailModal;
};

const ConfirmEmailModal = ({
  action,
  workerTwo,
  invite,
  email,
}: {
  action: () => void;
  workerTwo?: boolean;
  invite?: boolean;
  email?: string;
}) => {
  const { t } = useTranslation();

  const { closeModal, stackSize, modalBack } = useModals();
  return (
    <>
      <ModalHeader
        specialty
        icon={
          <svg
            width="60"
            height="56"
            viewBox="0 0 60 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.3332 14C51.3332 11.4334 49.2332 9.33337 46.6665 9.33337H9.33317C6.7665 9.33337 4.6665 11.4334 4.6665 14V42C4.6665 44.5667 6.7665 46.6667 9.33317 46.6667H46.6665C49.2332 46.6667 51.3332 44.5667 51.3332 42V14ZM46.6665 14L27.9998 25.6667L9.33317 14H46.6665ZM46.6665 42H9.33317V18.6667L27.9998 30.3334L46.6665 18.6667V42Z"
              fill="#B6AFAA"
            />
            <path
              d="M49 1.5C43.7556 1.5 39.5 5.75557 39.5 11C39.5 16.2444 43.7556 20.5 49 20.5C54.2444 20.5 58.5 16.2444 58.5 11C58.5 5.75557 54.2444 1.5 49 1.5Z"
              fill="#C16058"
              stroke="#252422"
              stroke-width="3"
            />
          </svg>
        }
        title={
          invite
            ? t("areYouUsingOnlyPersonalEmails")
            : t("isThisAPersonalEmailAddress")
        }
      />
      <ModalBody>
        {invite ? (
          <p className="t-small text-center">
            {t("makeSureYoureInvitingYourCoworkers")}
          </p>
        ) : (
          <p className="t-small text-center">
            {t("forPrivacyAndSecurityWeStronglyRe")}
          </p>
        )}
        {workerTwo && (
          <p className="t-small pt-2 text-center">
            {t("ifYourCoworkerInvitedYouUsingACom")}
          </p>
        )}
        <div className="sm:flex sm:flex-row sm:justify-center sm:items-center pt-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <Button
              buttonStyle="secondary"
              onClick={() => {
                action();
                closeModal();
              }}
            >
              {t("useEmail", { email: email || "email anyway" })}
            </Button>
            <Button
              buttonStyle="brand"
              onClick={() => {
                if (stackSize > 1) {
                  modalBack();
                } else {
                  closeModal();
                }
              }}
            >
              {t("useAnotherEmail")}
            </Button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default ConfirmEmailModal;

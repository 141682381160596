import {
  DocumentType,
  AuthCardSignatureDocument,
} from "frank-types/dist/frank-backend-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import { useModals } from "../../../Modals";
import AuthorizationCardForm from "../../../Partner/components/AuthorizationCardForm";
import RelativeTimeDisplay from "../RelativeTime";
import DocumentItem from "./DocumentItem";

const AuthCardDocumentItem = ({
  signature,
}: {
  signature: AuthCardSignatureDocument;
}) => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useModals();
  const { currentGroup } = useCurrentUserData();
  const openAuthCard = React.useCallback(
    () =>
      setModal({
        type: "genericModal",
        props: {
          body: (
            <AuthorizationCardForm
              closeModal={closeModal}
              authorizationLanguage={currentGroup.authorizationLanguage}
              signature={currentGroup.authorizationCard.mySignature}
              employer={currentGroup.company}
            />
          ),
          title: t("reviewAuthCard"),
        },
      }),
    [setModal, signature]
  );
  return (
    <DocumentItem
      key={signature.id}
      title={t("authCardSignature")}
      icon="contact_emergency"
      subheading={
        <span>
          {t("submitted")} <RelativeTimeDisplay date={signature.submittedAt} />
        </span>
      }
      onView={openAuthCard}
      label={t("formSubmission")}
    />
  );
};

export default AuthCardDocumentItem;

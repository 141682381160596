import {
  Intent,
  ScreenSizes,
  useBreakpoint,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import copyText from "../utils/copyText";
import InviteLinkInput from "./components/InviteLinkInput";
import { useShareOrCopyAndCreateInvite } from "./dataAccess/queries/mutations/useCreateInviteLink";
import { LinkSecurityOptions } from "./LinkSecurityOptions";
import UpdateInviteSettings from "./UpdateInvite";

export interface InviteForm {
  maxSeats: number;
  passphrase: string;
  expiration: string;
}

const CreateLinkInvite = ({
  campaignId,
  refetchStats,
  showOptions = true,
}: {
  campaignId?: string;
  refetchStats?: () => any;
  showOptions?: boolean;
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint() <= ScreenSizes.SM;
  const { register, handleSubmit, errors } = useForm<InviteForm>();
  const toaster = useToaster();
  const supportsShare = !!navigator.share;

  const {
    inviteData,
    loading,
    url,
    createInviteLink,
  } = useShareOrCopyAndCreateInvite({
    isMobile,
    supportsShare,
    campaignId,
  });

  const submit = React.useCallback(
    handleSubmit(async (data) => {
      await createInviteLink(data);
      if (refetchStats) {
        await refetchStats();
      }
    }),
    [createInviteLink, refetchStats]
  );

  return (
    <>
      {!inviteData && (
        <>
          <InviteLinkInput
            loading={loading}
            type={inviteData ? "button" : "submit"}
            onClickHandler={async () => {
              await submit();
              toaster.addToast({
                intent: Intent.SUCCESS,
                children:
                  isMobile && supportsShare
                    ? t("success")
                    : t("copiedToYourClipboard"),
              });
            }}
            url={url}
            showCopyButton
          />
          {showOptions && (
            <LinkSecurityOptions errors={errors} register={register} />
          )}
        </>
      )}
      {inviteData && (
        <>
          <InviteLinkInput
            url={url}
            loading={false}
            type="button"
            showCopyButton
            onClickHandler={async () => {
              await copyText(url);
              toaster.addToast({
                intent: Intent.SUCCESS,
                children: t("copiedToYourClipboard"),
              });
            }}
          />
          {showOptions && <UpdateInviteSettings invite={inviteData} />}
        </>
      )}
    </>
  );
};

export default CreateLinkInvite;

import { useHistory } from "react-router";
import axios from "axios";
import createPersistedState from "use-persisted-state";
import { useEffect } from "react";
import useSession from "../Auth/useSession";
import generateAuthHeader from "../Auth/generateAuthHeader";
import { AuthStates } from "../Auth/AuthState";

export const useGroupsLocalStorageState = createPersistedState("groupId");

export default function usePageTracker() {
  const history = useHistory();
  const [groupId] = useGroupsLocalStorageState<string | null>(null);
  const { authState } = useSession({ groupId });
  useEffect(() => {
    // when page first loads we should record view
    // subscription to history hasn't started yet
    if (!groupId) {
      return;
    }
    if (authState === AuthStates.NOT_LOGGED_IN) {
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/analytics/pageview`,
        {
          path: history.location.pathname,
          groupId,
        },
        {
          headers: {
            authorization: generateAuthHeader(),
          },
        }
      )
      .catch(() => {});
  }, [authState, groupId, history]);
  useEffect(() => {
    if (!groupId) {
      return;
    }
    if (authState === AuthStates.LOGGED_IN) {
      history.listen((location) => {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/analytics/pageview`,
            {
              path: location.pathname,
              groupId,
            },
            {
              headers: {
                authorization: generateAuthHeader(),
              },
            }
          )
          .catch(() => {});
      });
    }
  }, [groupId, history, authState]);
}

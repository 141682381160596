import {
  Button,
  Checkbox,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import useDataTableChanger from "../../Coworkers/dataAccess/useDataTableChanger";
import { useModals } from "../../Modals";
import useIndependentAuthenticityVerification from "../dataAccess/mutations/useIndependentAuthenticityVerification";
import { useTranslation, Trans } from "react-i18next";
import i18n from "../../i18n/config";

interface FormData {
  currentWorkerCertification: FrankBackendTypes.OnboardingCertification.CurrentEmployee;
  nonMgmtCertification: FrankBackendTypes.OnboardingCertification.NonMgmt;
  checkEmailAuthenticity: string;
}

const AdmitPendingMemberModal = ({
  pendingMember,
  groupName,
  upvote,
}: {
  pendingMember: FrankBackendTypes.Coworker;
  groupName: string;
  upvote: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { change } = useDataTableChanger({ view: "vouching" });
  const { register, handleSubmit, errors } = useForm<FormData>();
  const { closeModal } = useModals();
  const {
    addIndependentAuthenticityVerification,
  } = useIndependentAuthenticityVerification();

  const hasDangerousEmail = pendingMember.onboardingIssues.issueList.includes(
    FrankBackendTypes.OnboardingIssueType.DangerousEmail
  );

  const review = async () => {
    await Promise.all([
      change({
        id: pendingMember.id,
        employmentType: FrankBackendTypes.CoworkerEmploymentType.Worker,
      }),
      addIndependentAuthenticityVerification(pendingMember.id),
    ]);
    await upvote();
    closeModal();
  };

  return (
    <>
      <ModalHeader
        title={t("admitPendingMemberNameToGroupNa", {
          pendingMemberName: pendingMember.name,
          groupName: groupName,
        })}
      />
      <ModalBody>
        <form
          onSubmit={handleSubmit(review)}
          id="review-pending-coworker-form"
          className="space-y-4"
        >
          <div className="t-small plus">{t("byVotingToAdmitICertifyThat")}</div>
          <section>
            <div className="space-y-4">
              <div>
                <label
                  className="flex flex-row items-center t-small plus"
                  htmlFor="verify-current-worker"
                >
                  <Checkbox
                    dataCy="verify-current-worker"
                    id="verify-current-worker"
                    className="mr-3"
                    name="currentWorkerCertification"
                    ref={register({
                      required: t("onlyCurrentWorkersAreAllowedToJoin"),
                    })}
                    value={
                      FrankBackendTypes.OnboardingCertification.CurrentEmployee
                    }
                  />
                  <div className="t-small">
                    {t("pendingMemberNameIsACurrentWorke", {
                      pendingMemberName: pendingMember.name,
                    })}
                  </div>
                </label>
                {errors.nonMgmtCertification && (
                  <div className="t-mini text-brand-300 ml-6 mt-1">
                    {errors.currentWorkerCertification?.message}
                  </div>
                )}
              </div>
              <div>
                <label
                  className="flex flex-row items-center t-small plus"
                  htmlFor="verify-non-mgmt"
                >
                  <Checkbox
                    dataCy="verify-non-mgmt"
                    id="verify-non-mgmt"
                    className="mr-3"
                    name="nonMgmtCertification"
                    ref={register({
                      required: t("managersAreProhibitedFromJoiningFra"),
                    })}
                    value={FrankBackendTypes.OnboardingCertification.NonMgmt}
                  />
                  <div className="t-small">
                    {t("pendingMemberNameIsNotAManager", {
                      pendingMemberName: pendingMember.name,
                    })}
                  </div>
                </label>
                {errors.nonMgmtCertification && (
                  <div className="t-mini text-brand-300 ml-6 mt-1">
                    {errors.nonMgmtCertification?.message}
                  </div>
                )}
              </div>
            </div>
          </section>

          <section>
            <div className="space-y-4">
              <div>
                <label
                  className="flex flex-row items-center t-small plus"
                  htmlFor="email-authenticity"
                >
                  <Checkbox
                    dataCy="email-authenticity"
                    id="email-authenticity"
                    className="mr-3"
                    name="checkEmailAuthenticity"
                    ref={register({
                      required: t("pleaseEnsureThePersonWithThisEmail", {
                        pendingMemberName: pendingMember.name,
                      }),
                    })}
                    value="verified"
                  />
                  <div className="t-small">
                    {t("pendingMemberNameIsUsingAnEmail", {
                      pendingMemberName: pendingMember.name,
                    })}
                  </div>
                </label>
                {errors.checkEmailAuthenticity && (
                  <div className="t-mini text-brand-300 ml-6 mt-1">
                    {errors.checkEmailAuthenticity?.message}
                  </div>
                )}
              </div>
            </div>
          </section>
          {/* AUTHENTICITY (dangerous email) */}
          {hasDangerousEmail && (
            <section className="t-small">
              <Trans
                i18nKey="areYouSureYouWantToAdmitPendingMemberNameToTheGroup"
                i18n={i18n}
              >
                Are you sure you want to admit{" "}
                {{ pendingMemberName: pendingMember.name }} to the group?
                {{ pendingMemberEmail: pendingMember.email }}was flagged as a
                <span className="text-frank-red-300"> dangerous</span> email
                address by your coworkers.
              </Trans>
            </section>
          )}
          <p className="t-small">{t("pleaseReviewTheseCertificationsCaref")}</p>
        </form>
        <ModalFooter>
          <div className="space-x-2">
            <Button buttonStyle="secondary" onClick={closeModal}>
              {t("cancel")}
            </Button>
            <Button
              dataCy="admit-coworker"
              form="review-pending-coworker-form"
              type="submit"
              buttonStyle="brand"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            >
              {t("voteToAdmit")}
            </Button>
          </div>
        </ModalFooter>
      </ModalBody>
    </>
  );
};

export default AdmitPendingMemberModal;

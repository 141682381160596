import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const CREATE_PARTNER = gql`
  mutation AddOrganizer($input: CreateOrganizerInput!) {
    createOrganizer(input: $input) {
      error
      user {
        id
        name
        onboardings {
          id
          group {
            id
            name
          }
        }
      }
    }
  }
`;

export default function useCreateOrganizer() {
  const [invokeCreateOrganizer, { loading, called, error }] = useMutation<
    { createOrganizer: FrankBackendTypes.CreateOrganizerResult },
    FrankBackendTypes.MutationCreateOrganizerArgs
  >(CREATE_PARTNER);

  const createOrganizer = React.useCallback(
    async ({
      name,
      email,
      partnerId,
      groupIds,
      addToAllGroups,
    }: {
      name: string;
      email: string;
      partnerId: string;
      groupIds?: string[];
      addToAllGroups: boolean;
    }) => {
      const { data } = await invokeCreateOrganizer({
        variables: {
          input: {
            name,
            email,
            partnerId,
            groupIds,
            addToAllGroups,
          },
        },
      });
      return data.createOrganizer;
    },
    [invokeCreateOrganizer]
  );

  return {
    createOrganizer,
    loading,
    called,
    error,
  };
}

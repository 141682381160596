import { gql } from "@apollo/client";

export const taskAssignmentFragment = gql`
  fragment TaskAssignmentFragment on TaskAssignment {
    id
    createdAt
    coworker {
      id
      name
    }
    task {
      id
      title
      description
    }
    assignedByUser {
      id
      name
      profilePic {
        url
      }
    }
    workflowState
    dueDate
    note
  }
`;

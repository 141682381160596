import { Button, IconButton, Sizes } from "@get-frank-eng/design-system";
import * as React from "react";
import { useModals } from "../Modals";
import { useTranslation } from "react-i18next";

const InviteButton = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { setModal } = useModals();

  const openInviteModal = React.useCallback(() => {
    setModal({
      type: "defaultInviteModal",
      props: {},
    });
  }, [setModal]);
  return (
    <Button
      buttonStyle="brand"
      onClick={openInviteModal}
      className={className}
      size={Sizes.SM}
    >
      {t("inviteCoworkers")}
    </Button>
  );
};

export const MobileInviteButton = () => {
  const { t } = useTranslation();

  const { setModal } = useModals();

  const openInviteModal = React.useCallback(() => {
    setModal({
      type: "defaultInviteModal",
      props: {},
    });
  }, [setModal]);
  return (
    <IconButton
      buttonStyle="brand"
      icon="person_add"
      size={Sizes.LG}
      onClick={openInviteModal}
    >
      {t("inviteCoworkers")}
    </IconButton>
  );
};

export default InviteButton;

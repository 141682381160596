import * as React from "react";
import classNames from "classnames";
import uploadAvatar from "../../Auth/UploadAvatar";
import FieldError from "../../components/FieldError";
import { Icon, Sizes } from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";

function useAvatarUpload({
  onUpload,
  setGroupAttachmentId,
  groupId,
}: {
  onUpload: (err, url) => void;
  setGroupAttachmentId?: ({ attachmentId: string }) => Promise<void>;
  groupId?: string;
}) {
  const [avatarError, setAvatarError] = React.useState(null);
  const [avatarUrl, setAvatarUrl] = React.useState(null);
  const uploadAvatarCb = React.useCallback(async () => {
    if (avatarUrl) {
      return;
    }
    uploadAvatar(
      (err, url) => {
        if (err) {
          setAvatarError(err);
          onUpload(err, null);
          return;
        }
        setAvatarUrl(url);
        setAvatarError(null);
        onUpload(null, url);
      },
      setGroupAttachmentId,
      groupId
    );
  }, [
    avatarUrl,
    setAvatarUrl,
    setAvatarError,
    onUpload,
    setGroupAttachmentId,
    groupId,
  ]);
  return { avatarUrl, avatarError, uploadAvatarCb };
}

const AddPhoto = ({
  onUpload,
  defaultUrl,
  missingPhotoError,
  setGroupAttachmentId,
  customIcon,
  groupId,
}: {
  defaultUrl: string;
  onUpload: (err: any, url: string) => void;
  missingPhotoError?: boolean;
  setGroupAttachmentId?: ({ attachmentId: string }) => Promise<void>;
  customIcon?: React.ReactNode;
  groupId?: string;
}) => {
  const { t } = useTranslation();

  const { avatarUrl, avatarError, uploadAvatarCb } = useAvatarUpload({
    onUpload,
    setGroupAttachmentId,
    groupId,
  });
  const classes = [
    "w-20",
    "transition-colors",
    "duration-200",
    "mt-4",
    "mb-2",
    "h-20",
    "bg-canvas-700",
    "rounded",
    "shadow",
    "flex",
    "items-center",
    "justify-center",
    {
      hoverable: !avatarUrl,
      "cursor-pointer": !avatarUrl,
      "cursor-default": !!avatarUrl,
      "border border-dashed": !avatarUrl,
      "border-brand-300": missingPhotoError,
      "bg-brand-800": missingPhotoError,
    },
  ];
  const helperText = missingPhotoError ? t("thisIsRequired") : "";
  return (
    <>
      <button
        type="button"
        data-cy="add-photo"
        onClick={uploadAvatarCb}
        className={classNames(classes)}
      >
        {!avatarUrl &&
          !defaultUrl &&
          (customIcon || (
            <Icon
              icon="person_outline"
              size={Sizes.LG}
              classNames={missingPhotoError && "text-brand-300"}
            />
          ))}
        {(!!avatarUrl || !!defaultUrl) && (
          <img
            src={avatarUrl || defaultUrl}
            className="object-cover rounded object-center w-full h-full"
          />
        )}
      </button>
      {avatarError && (
        <FieldError>{t("thereWasAnErrorUploadingYourPicture")}</FieldError>
      )}
      <div
        id={missingPhotoError ? "testid-photo-error" : ""}
        className={classNames([
          "t-small",
          {
            "text-brand-600": missingPhotoError,
          },
        ])}
      >
        {helperText}
      </div>
    </>
  );
};

export default AddPhoto;

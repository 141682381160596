import { ChatTypes, FrankBackendTypes } from "frank-types";
import {
  AttachmentCard,
  Avatar,
  Button,
  CampaignCard,
  Card,
  Input,
  Intent,
  LinkCard,
  PollCard,
  Sizes,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import useAttachment from "../../Attachment/dataAccess/queries/useAttachment";
import useClosePoll from "../../Channel/dataAccess/mutations/useClosePoll";
import usePoll from "../../Channel/dataAccess/queries/usePoll";
import useCampaign from "../dataAccess/queries/useCampaign";
import useRespondToPoll from "../../Channel/dataAccess/mutations/useRespondToPoll";
import { useHistory } from "react-router";
import useRetakeTour from "../../Tour/dataAccess/useRetakeTour";
import { useCurrentTourContext } from "../../Tour/CurrentTourProvider";
import {
  useChannelIds,
  useNavigationState,
} from "../../Navigation/hooks/useNavigationState";
import { useModals } from "../../Modals";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { useMessages } from "@get-frank-eng/chat-client";
import useUpdateGroupName from "../../Partner/dataAccess/hooks/mutations/useUpdateGroupName";
import { useTranslation } from "react-i18next";
import { dateFormatter } from "../../Partner/pages/ListOrganizers";

const timeOptions = {
  hour: "numeric",
  minute: "numeric",
};

const timeDateFormatter = (lng = "en") =>
  // @ts-ignore
  new Intl.DateTimeFormat(lng, timeOptions);

type RichObjectType =
  | "link"
  | "attachment"
  | "poll"
  | "campaign"
  | "profile-picture"
  | "coworkers"
  | "invite";

const fixedHeightMap: {
  [K in RichObjectType]: string;
} = {
  invite: "90px",
  coworkers: "70px",
  "profile-picture": "125px",
  link: "125px",
  attachment: "250px",
  poll: "500px",
  campaign: "300px",
};

const FixCardHeight = ({ fixedHeight, children }) => (
  <div style={{ maxHeight: fixedHeight }}>{children}</div>
);

const WrappedAttachmentCard = ({
  richObject,
}: {
  richObject: ChatTypes.RichObject;
}) => {
  const { t } = useTranslation();
  const { loading, error, attachment } = useAttachment(
    richObject.foreignIdentifier
  );
  if (loading || error) {
    return null;
  }

  if (attachment.deletedAt) {
    return (
      <div className="t-mini bg-canvas-700 rounded p-2">
        {t("fileDeletedOnDateAtTime", {
          date: dateFormatter.format(new Date(attachment.deletedAt)),
          time: timeDateFormatter("en").format(new Date(attachment.deletedAt)),
          interpolation: { escapeValue: false },
        })}
      </div>
    );
  }
  return <AttachmentCard attachment={attachment} />;
};

const WrappedPollCard = ({
  richObject,
}: {
  richObject: ChatTypes.RichObject;
}) => {
  const { preferredLanguage } = useCurrentUserData();
  const { loading, error, poll } = usePoll(richObject.foreignIdentifier);
  const { loading: respondLoading, respondToPoll } = useRespondToPoll();
  const { closePoll, loading: closeLoading } = useClosePoll();
  if (loading) {
    return (
      <FixCardHeight fixedHeight={fixedHeightMap[richObject.type]}>
        <div />
      </FixCardHeight>
    );
  }
  if (error) {
    return null;
  }
  return (
    <PollCard
      loading={respondLoading || closeLoading}
      closePoll={closePoll}
      respondToPoll={respondToPoll}
      poll={poll}
      lng={preferredLanguage}
    />
  );
};

const WrappedCampaignCard = ({
  richObject,
}: {
  richObject: ChatTypes.RichObject;
}) => {
  const { preferredLanguage } = useCurrentUserData();
  const { campaign, loadingCampaign, errorCampaign } = useCampaign({
    campaignId: richObject.foreignIdentifier,
  });

  if (loadingCampaign || errorCampaign) {
    return null;
  }

  return (
    <CampaignCard
      title={campaign?.title}
      campaignOrganizers={campaign?.campaignOrganizers}
      id={richObject.foreignIdentifier}
      relationshipToMe={campaign?.relationshipToMe}
      signatureCount={campaign?.signatureCount}
      signatureGoal={campaign?.signatureGoal}
      workflowState={campaign?.workflowState}
      stepsToPublish={campaign?.publishingValidationIssues?.length}
      lng={preferredLanguage}
    />
  );
};

const DummyPoll = ({
  inputPoll,
  sendMessage,
}: {
  inputPoll: FrankBackendTypes.Poll;
  sendMessage: (text: string) => Promise<void>;
}) => {
  const { preferredLanguage } = useCurrentUserData();
  const getPollFromLocalStorage = () => {
    const poll = localStorage.getItem("dummyIntroPoll");
    if (poll) {
      return JSON.parse(poll);
    }
    return null;
  };

  const [poll, setPoll] = React.useState<FrankBackendTypes.Poll>(
    getPollFromLocalStorage() || inputPoll
  );

  const setPollInLocalStorage = (poll) => {
    localStorage.setItem("dummyIntroPoll", JSON.stringify(poll));
  };

  const updatePoll = (
    poll: FrankBackendTypes.Poll,
    responseId: string
  ): FrankBackendTypes.Poll => {
    const totalResponses = poll.totalResponses + 1;
    const options = poll.options.map((opt) => {
      if (opt.id === responseId) {
        const totalVotesForOption = opt.totalVotes + 1;
        return {
          ...opt,
          percent: Math.round((totalVotesForOption / totalResponses) * 100),
          totalVotes: totalVotesForOption,
        };
      }
      return {
        ...opt,
        percent: Math.round((opt.totalVotes / totalResponses) * 100),
      };
    });
    const myResponse = options.find((opt) => opt.id === responseId);
    const newPoll = {
      ...poll,
      totalResponses,
      options,
      myResponse,
    };

    setPollInLocalStorage(newPoll);

    return newPoll;
  };

  const respondToPoll = (args: { pollId: string; pollOptionId: string }) => {
    setPoll(updatePoll(poll, args.pollOptionId));
    sendMessage(args.pollOptionId);
  };

  const closePoll = (pollId) => {};

  if (!poll) {
    return null;
  }

  return (
    <PollCard
      loading={false}
      closePoll={closePoll}
      respondToPoll={respondToPoll}
      poll={poll}
      lng={preferredLanguage}
    />
  );
};

const UpdateGroupNameCard = ({ isLastMessage }) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = React.useState<string>();
  const { refetchCurrentUserData } = useCurrentUserData();

  const { updateGroupName } = useUpdateGroupName();

  const submit = async (e) => {
    e.preventDefault();
    await updateGroupName({ name: groupName });
    await refetchCurrentUserData();
  };

  if (!isLastMessage) {
    return null;
  }

  return (
    <form onSubmit={submit} className="flex flex-row space-x-3">
      <Input
        placeholder={t("groupName")}
        value={groupName}
        onChange={(e) => setGroupName(e.currentTarget.value)}
      />
      <Button buttonStyle="brand" type="submit">
        {t("submit")}
      </Button>
    </form>
  );
};

const InviteCoworkersCard = ({
  isLastMessage,
  persist,
}: {
  isLastMessage: boolean;
  persist?: boolean;
}) => {
  const { t } = useTranslation();
  const { setModal } = useModals();

  const openInviteModal = React.useCallback(() => {
    setModal({
      type: "defaultInviteModal",
      props: {},
    });
  }, [setModal]);

  if (isLastMessage || persist) {
    return (
      <Card>
        <Button buttonStyle="outline" onClick={openInviteModal}>
          {t("inviteCoworkers")}
        </Button>
      </Card>
    );
  }
  return null;
};

const CreateTopicCard = ({ isLastMessage }: { isLastMessage: boolean }) => {
  const { t } = useTranslation();
  const { setModal } = useModals();
  const { refetch } = useNavigationState();

  const openCreateTopicModal = React.useCallback(() => {
    setModal({
      type: "createTopicModal",
      props: {
        afterSubmit: refetch,
        redirect: false,
      },
    });
  }, [setModal, refetch]);

  if (isLastMessage) {
    return (
      <Card>
        <Button buttonStyle="outline" onClick={openCreateTopicModal}>
          {t("createTopic")}
        </Button>
      </Card>
    );
  }
  return null;
};

const TakeCoworkersTour = ({ isLastMessage }: { isLastMessage: boolean }) => {
  const history = useHistory();
  const { retakeTour } = useRetakeTour();
  const { setRetakingTour } = useCurrentTourContext();
  const { refetch } = useNavigationState();
  const { t } = useTranslation();

  if (isLastMessage) {
    return (
      <Card>
        <Button
          buttonStyle="outline"
          onClick={async () => {
            setRetakingTour(true);
            await retakeTour(FrankBackendTypes.TourType.Coworkers);
            await refetch();
            history.push("/coworkers");
          }}
        >
          {t("tourCoworkerPage")}
        </Button>
      </Card>
    );
  }
  return null;
};

const SendMessage = ({
  messageText,
  isLastMessage,
  channelName,
}: {
  isLastMessage: boolean;
  messageText: string;
  channelName: string;
}) => {
  const { t } = useTranslation();
  const { myProfile } = useCurrentUserData();
  const channelId = useChannelIds(channelName);
  const { sendMessage, loading } = useMessages({ channelId });
  const { addToast } = useToaster();
  const [sent, setSent] = React.useState(false);

  const send = React.useCallback(async () => {
    await sendMessage({ body: messageText, sentAt: new Date() });
    setSent(true);
    addToast({
      intent: Intent.SUCCESS,
      children: t("yourMessageHasBeenSent"),
    });
  }, [sendMessage, addToast, setSent, t]);

  if (sent || !isLastMessage || !channelId) {
    return null;
  }
  // i18n CONCATENATION
  return (
    <Card pad>
      <div className="flex flex-row space-x-2 mb-4 t-small">
        <Avatar src={myProfile.profilePic?.url} size={Sizes.MD} />
        <div className="">
          <div className="t-small plus">{t("you")}</div>
          <div>{messageText}</div>
        </div>
      </div>
      <Button
        onClick={send}
        loading={loading.sendMessage}
        buttonStyle="outline"
      >
        {t("sendToChannel", { channelName })}
      </Button>
    </Card>
  );
};

const FrankCards = ({
  richObject,
  isLastMessage,
  sendMessage,
}: {
  richObject: ChatTypes.RichObject;
  isLastMessage: boolean;
  sendMessage: (text: string) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const data = JSON.parse(richObject.dataJSON);
  const { setModal } = useModals();

  const openInviteModal = React.useCallback(() => {
    setModal({
      type: "defaultInviteModal",
      props: {},
    });
  }, [setModal]);

  switch (richObject.type) {
    case "send-message":
      return (
        <SendMessage
          messageText={data.messageText}
          isLastMessage={isLastMessage}
          channelName={data.channelName}
        />
      );
    case "update-group-name":
      return <UpdateGroupNameCard isLastMessage={isLastMessage} />;
    case "suggested-response":
      const responses: { displayText: string; effect: string }[] =
        data.responses;
      if (isLastMessage) {
        return (
          <div className="space-y-2 flex flex-col">
            {responses.map((response) => {
              switch (response.effect) {
                case "open-intercom":
                  return (
                    <Button
                      size={Sizes.SM}
                      key={response.displayText}
                      className="intercom-launcher"
                      buttonStyle="outline"
                    >
                      {response.displayText}
                    </Button>
                  );
                case "send-message":
                  return (
                    <Button
                      size={Sizes.SM}
                      key={response.displayText}
                      buttonStyle="outline"
                      onClick={() => sendMessage(response.displayText)}
                    >
                      {response.displayText}
                    </Button>
                  );

                case "open-invite-modal":
                  return (
                    <Button
                      size={Sizes.SM}
                      key={response.displayText}
                      buttonStyle="outline"
                      onClick={openInviteModal}
                    >
                      {response.displayText}
                    </Button>
                  );
              }
            })}
          </div>
        );
      }
      return null;
    case "dummy-poll":
      return <DummyPoll inputPoll={data.poll} sendMessage={sendMessage} />;
    case "take-coworkers-tour":
      return <TakeCoworkersTour isLastMessage={isLastMessage} />;

    case "image":
      return (
        <img
          className="border rounded"
          style={{
            height: data?.height,
            width: data?.width,
            objectFit: "scale-down",
          }}
          src={richObject.previewImageUrl}
        />
      );

    case "invite-button":
      return (
        <InviteCoworkersCard
          isLastMessage={isLastMessage}
          persist={data?.persist}
        />
      );

    case "create-topic":
      return <CreateTopicCard isLastMessage={isLastMessage} />;

    case "link":
      return (
        <FixCardHeight fixedHeight={fixedHeightMap[richObject.type]}>
          <div className="pb-2 t-mini text-canvas-400">{t("link")}</div>
          <LinkCard richObject={richObject} />
        </FixCardHeight>
      );
    case "attachment":
      return (
        <FixCardHeight fixedHeight={fixedHeightMap[richObject.type]}>
          <WrappedAttachmentCard richObject={richObject} />
        </FixCardHeight>
      );
    case "poll":
      return <WrappedPollCard richObject={richObject} />;
    case "campaign":
      return (
        <FixCardHeight fixedHeight={fixedHeightMap[richObject.type]}>
          <div className="pb-2 t-mini text-canvas-400">{t("petition")}</div>
          <WrappedCampaignCard richObject={richObject} />
        </FixCardHeight>
      );
    default:
      console.warn(`Unknown frank card type: ${richObject.type}`);
      return null;
  }
};

export default FrankCards;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sidebar = void 0;
const React = __importStar(require("react"));
const SidebarHeader_1 = require("./SidebarHeader");
const Sidebar = ({ children, otherGroups, footer, switchGroups, groupName, groupLogoUrl, headerLinkTo, createNewGroupMenuItem }) => {
    return (React.createElement("div", { className: "bg-canvas-700 h-screen w-full flex flex-col sm:border-r" },
        React.createElement("div", { className: "flex-shrink-0 border-b" },
            React.createElement(SidebarHeader_1.SidebarHeader, { switchGroups: switchGroups, otherGroups: otherGroups, groupName: groupName, groupLogoUrl: groupLogoUrl, linkTo: headerLinkTo, createNewGroupMenuItem: createNewGroupMenuItem })),
        React.createElement("div", { className: "flex-1 flex flex-col overflow-y-auto py-4 pb-48 sm:pb-4 px-2 sm:px-4" }, children),
        footer && (React.createElement("div", { className: "fixed bottom-0 sm:bottom-auto sm:static w-full sm:flex-shrink-0 border-t bg-canvas-700" }, footer))));
};
exports.Sidebar = Sidebar;

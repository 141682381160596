import { ModalBody, ModalHeader } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import CreateInvite from "./CreateLinkInvite";
import { useTranslation } from "react-i18next";

const CreateLinkInviteModal = ({
  campaignId,
}: {
  campaignId?: FrankBackendTypes.Campaign["id"];
}) => {
  const { t } = useTranslation();
  return (
    <>
      <ModalHeader title={t("inviteViaPrivateLink")} />
      <ModalBody pad>
        <CreateInvite campaignId={campaignId} />
      </ModalBody>
    </>
  );
};

export default CreateLinkInviteModal;

import { ExtendedStep } from ".";
import { FrankBackendTypes } from "frank-types";
import i18n from "../i18n/config";

export const coworkersTourSteps = ({
  isMobile,
  fromFrankName,
}): ExtendedStep[] => [
  {
    target: `.coworkers-page #name-column-workers`,
    title: i18n.t("startTrackingCoworkers"),
    content: i18n.t("buildYourListOfCoworkersAndMarkWh"),
    disableBeacon: true,
  },
  {
    target: `.coworkers-page #employmentType-column-workers`,
    title: i18n.t("sortYourList"),
    content: i18n.t("markCoworkersAsCurrentWorkersForme"),
    disableBeacon: true,
  },
  {
    target: `.coworkers-page #yourTrust-column-workers`,
    title: i18n.t("addTrustworthiness"),
    content: i18n.t("markWhetherOrNotYouTrustThisCowor"),
    disableBeacon: true,
    placement: isMobile ? "left-end" : undefined,
  },
  {
    target: `.coworkers-page #notes-column-workers`,
    title: i18n.t("takeNotes"),
    content: i18n.t("youCanAddNotesAboutTrustworthiness"),
    disableBeacon: true,
  },
  {
    target: `#${FrankBackendTypes.NavigationSubjectTypes.FromFrank}, .mobile-menu`,
    title: i18n.t("finishUpTour"),
    content: i18n.t("youreReadyToHeadBackToFromFrank", { fromFrankName }),
    disableBeacon: true,
  },
];

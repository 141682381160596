import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export const SentryLoggingLevel = Sentry.Severity;

class SentryClient {
  init(options: Sentry.BrowserOptions = {}) {
    Sentry.init({
      integrations: [
        new BrowserTracing({
          tracingOrigins: ["getfrank-api-partner.herokuapp.com"],
        }),
      ],
      sampleRate:
        process.env.REACT_APP_SENTRY_ENVIRONMENT === "production" ? 1.0 : 0,
      tracesSampleRate:
        process.env.REACT_APP_SENTRY_ENVIRONMENT === "production" ? 1.0 : 0,
      dsn: process.env.REACT_APP_SENTRY,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      ...options,
    });
  }

  configureScope(callback: (scope: Sentry.Scope) => void) {
    Sentry.configureScope(callback);
  }

  addBreadcrumb(args: Sentry.Breadcrumb) {
    Sentry.addBreadcrumb(args);
  }

  captureException(e: Error) {
    Sentry.captureException(e);
  }
}

export default new SentryClient();

import { useChannels } from "@get-frank-eng/chat-client";
import {
  Button,
  IconButton,
  IconLinkButton,
  Intent,
  Sizes,
  TopBar,
  UserCard,
  useToaster,
} from "@get-frank-eng/design-system";
import { AbilityAction, ChatTypes } from "frank-types";
import { Topic } from "frank-types/dist/frank-backend-types";
import * as React from "react";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import Pager, { PaginationStrategy } from "../../components/Pager";
import { useModals } from "../../Modals";
import useRemoveMember from "../dataAccess/mutations/useRemoveMember";
import { useTranslation } from "react-i18next";

const Members = ({ topic }: { topic: Topic }) => {
  const { t } = useTranslation();
  const { setModal, openConfirmationModal } = useModals();

  const { channels, loadMoreMembers } = useChannels();
  const thisChannel = useMemo(
    () => channels.find((ch) => ch.id === topic.channelId),
    [channels, topic]
  );

  const { ability, preferredLanguage } = useCurrentUserData();
  const canManageMembers = ability?.can(AbilityAction.Manage, "Group");
  const { myProfile } = useCurrentUserData();
  const { removeFromChannel } = useRemoveMember();
  const toaster = useToaster();
  const history = useHistory();

  const remove = async ({
    isSelf,
    member,
  }: {
    isSelf: boolean;
    member: ChatTypes.User;
  }) => {
    const confirm = await openConfirmationModal({
      bodyText: `${
        isSelf
          ? t("onlyAnOrganizerCanAddYouBackToTh")
          : t("youCanAddThemBackLaterOnIfYouNe")
      }`,
      actionText: t("confirm"),
      title: t("areYouSureYouWantToRemoveNameFromThisChannel", {
        name: isSelf ? "yourself" : member.displayName,
      }),
    });

    if (!confirm) {
      return;
    }

    try {
      removeFromChannel({
        userId: member.id,
        channelId: thisChannel.id,
      });
      toaster.addToast({ intent: Intent.SUCCESS, children: t("success") });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
    }

    if (isSelf) {
      history.push(`/home`);
    }
  };

  if (!thisChannel) {
    return null;
  }

  return (
    <div>
      <TopBar
        classNames="bg-canvas-700"
        title={t("members")}
        left={
          <IconLinkButton
            to={`../${topic.slug}`}
            icon="arrow_back"
            size={Sizes.XL}
            buttonStyle="minimal"
          />
        }
        right={
          canManageMembers ? (
            <IconButton
              buttonStyle="minimal"
              icon="add"
              size={Sizes.XL}
              // loading={loading}
              onClick={() =>
                setModal({
                  type: "addMemberModal",
                  props: {
                    channelId: thisChannel.id,
                    topicName: topic.name,
                  },
                })
              }
            />
          ) : null
        }
      />
      <Pager<ChatTypes.User>
        pagination={thisChannel.members}
        paginationStrategy={PaginationStrategy.SHOW_MORE}
        onShowMore={() => loadMoreMembers({ channelId: thisChannel.id })}
        showAllText={t("showMore")}
      >
        <div className="space-y-2 p-6 bg-canvas-700 flex-grow">
          {thisChannel.members.objects.map((member) => {
            const isSelf = member.id === myProfile.coworker.id;
            return (
              <UserCard
                lng={preferredLanguage}
                key={member.id}
                userId={member.id}
                cta={
                  canManageMembers || isSelf ? (
                    <Button
                      size={Sizes.XS}
                      onClick={async () => remove({ isSelf, member })}
                    >
                      {t("remove")}
                    </Button>
                  ) : null
                }
                profilePicUrl={member.profilePic}
                name={member.displayName}
              />
            );
          })}
        </div>
      </Pager>
    </div>
  );
};

export default Members;

import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { Intent, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

const MUTATION = gql`
  mutation UpdateCampaign($input: UpdateCampaignDTO!) {
    updateCampaign(input: $input) {
      id
      signatureGoal
      signaturesVisible
      title
      categories
    }
  }
`;

export default function useUpdateUpdateCampaign({
  campaignId,
}: {
  campaignId: string;
}) {
  const toaster = useToaster();
  const { t } = useTranslation();

  const [invokeUpdateCampaign, { loading, error }] = useMutation<
    { updateCampaign: FrankBackendTypes.Campaign },
    FrankBackendTypes.MutationUpdateCampaignArgs
  >(MUTATION);

  const updateCampaign = React.useCallback(
    async (
      args: Omit<
        FrankBackendTypes.MutationUpdateCampaignArgs["input"],
        "campaignId"
      >
    ) => {
      try {
        const result = await invokeUpdateCampaign({
          variables: { input: { campaignId, ...args } },
        });
        return result;
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrongSavingYourChanges"),
        });
        throw e;
      }
    },
    [campaignId, invokeUpdateCampaign, t, toaster]
  );

  return {
    updateCampaign,
    loadingUpdateCampaign: loading,
    errorUpdateCampaign: error,
  };
}

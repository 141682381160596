import memoize from "lodash/memoize";
import { getConfig } from "../configuration";
import io from "socket.io-client";
import { ChatTypes } from "frank-types";

type ChannelListen =
  | {
      callback: (messageId: string) => void;
      room: string;
      event: "message-created";
    }
  | {
      callback: (payload: { user: ChatTypes.User; duration: number }) => void;
      room: string;
      event: "user-typed";
    }
  | {
      callback: (details: { childId: string; parentId: string }) => void;
      room: string;
      event: "message-replied-to";
    }
  | {
      callback: (messageId: string) => void;
      room: string;
      event: "message-updated";
    };

type Listen =
  | {
      callback: (args: { channelId: string }) => void;
      event: "channel-updated";
    }
  | {
      callback: (silent?: boolean) => void;
      event: "channel-options-updated";
    };

export const getWebsocket = memoize(async () => {
  const config = await getConfig();

  const socket = io(config.websocketUrl, {
    query: {
      token: config.getAuthorizationHeader(),
    },
    transports: ["websocket"],
  });

  socket.on("disconnect", (reason: string) => {
    console.log(`reason: ${reason}`);
  });

  socket.io.on("reconnect_attempt", () => {
    socket.io.opts.transports = ["polling", "websocket"];
  });

  socket.emit("announce");

  socket.on("reconnect", () => {
    socket.emit("announce");
  });

  return socket;
});

export async function type({
  channelId,
  parentId,
}: {
  channelId: string;
  parentId?: string;
}): Promise<void> {
  const socket = await getWebsocket();
  socket.emit("typing", { channelId, parentId });
}

export async function enterChannel({
  channelId,
  parentId,
}: {
  channelId: string;
  parentId?: string;
}): Promise<void> {
  const socket = await getWebsocket();
  socket.emit("enter-channel", { channelId, parentId });
}

export async function leaveChannel({
  channelId,
  parentId,
}: {
  channelId: string;
  parentId?: string;
}): Promise<void> {
  const socket = await getWebsocket();
  socket.emit("leave-channel", { channelId, parentId });
}

function makeRoomSlug(event: string, room: string) {
  return `${event}$$${room}`;
}

export async function listenOnChannel({
  callback,
  event,
  room,
}: ChannelListen): Promise<void> {
  const socket = await getWebsocket();
  socket.on(makeRoomSlug(event, room), callback);
}

export async function listen({ callback, event }: Listen): Promise<void> {
  const socket = await getWebsocket();
  socket.on(event, callback);
}

export async function unlistenOnChannel({
  callback,
  event,
  room,
}: ChannelListen): Promise<void> {
  const socket = await getWebsocket();
  socket.removeEventListener(makeRoomSlug(event, room), callback);
}

export async function unlisten({ callback, event }: Listen): Promise<void> {
  const socket = await getWebsocket();
  socket.removeEventListener(event, callback);
}

import React from "react";
import {
  Button,
  IconButton,
} from "@get-frank-eng/design-system/dist/components/Button";
import { useLayout } from "@get-frank-eng/design-system/dist/components/Layout";
import { Intent, Sizes } from "@get-frank-eng/design-system/dist/constants";
import {
  ScreenSizes,
  useScreenSize,
} from "@get-frank-eng/design-system/dist/hooks/useBreakpoint";
import { useTranslation } from "react-i18next";
import WrappedPageHeader from "../components/WrappedPageHeader";
import ActionsList from "./components/ActionsList";
import DocumentsList from "./components/DocumentsList";
import { useCurrentUserData } from "../Auth/useCurrentUserData";
import { useHistory } from "react-router-dom";
import GroupInfo from "./components/GroupInfo";
import {
  useActionsForCoworkerQuery,
  useDocumentsForCoworkerQuery,
} from "../generated-hooks";
import { Delay } from "@get-frank-eng/design-system/dist/components/Delay";
import { LargePulseCard } from "@get-frank-eng/design-system/dist/components/Pulse";
import { InlineAlert } from "@get-frank-eng/design-system/dist/components/InlineAlert";
import { useServiceWorker } from "../hooks/useServiceWorker";

const Home = () => {
  const { myProfile } = useCurrentUserData();

  if (!myProfile?.coworker?.id || !myProfile?.id) {
    return (
      <Delay delay={200}>
        <div className="mb-12 px-6 mx-auto flex-grow max-w-5xl w-full flex flex-col py-16">
          <div className="space-y-4">
            <LargePulseCard />
            <LargePulseCard />
          </div>
        </div>
      </Delay>
    );
  }

  return <HomePage coworkerId={myProfile.coworker.id} userId={myProfile.id} />;
};

const HomePage = ({
  coworkerId,
  userId,
}: {
  coworkerId: string;
  userId: string;
}) => {
  const { t } = useTranslation();
  const { setMobilePanel } = useLayout();
  const isMobile = useScreenSize() < ScreenSizes.MD;
  const { push } = useHistory();
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const standaloneMode =
    "standalone" in window.navigator && window.navigator["standalone"];

  const navigateToProfilePage = () => push(`/users/${userId}`);
  const {
    data: documentData,
    loading: documentsLoading,
    error: documentsError,
    refetch: documentsRefetch,
  } = useDocumentsForCoworkerQuery({
    variables: {
      coworkerId: coworkerId,
    },
  });
  const {
    data: actionData,
    loading: actionLoading,
    error: actionError,
    refetch: actionRefetch,
  } = useActionsForCoworkerQuery({
    variables: {
      coworkerId,
    },
  });

  const onUpdate = () => {
    actionRefetch();
    documentsRefetch();
    return Promise.resolve(undefined);
  };

  const header = React.useMemo(
    () => (
      <WrappedPageHeader
        title={t("home")}
        right={
          <div className="flex flex-col items-center text-canvas-200">
            <IconButton
              size={Sizes.XL}
              buttonStyle="minimal"
              icon="account_circle"
              onClick={navigateToProfilePage}
            />
          </div>
        }
      />
    ),
    [t, Sizes, navigateToProfilePage]
  );

  const mobileHeader = React.useMemo(
    () => (
      <div>
        <div className="flex flex-row justify-between pt-4 pb-8 text-canvas-200">
          <IconButton
            size={Sizes.XL}
            buttonStyle="minimal"
            icon="menu"
            onClick={() => setMobilePanel("left")}
          />

          <div className="flex flex-col items-center text-canvas-200">
            <IconButton
              size={Sizes.XL}
              buttonStyle="minimal"
              icon="account_circle"
              onClick={navigateToProfilePage}
            />
          </div>
        </div>
      </div>
    ),
    [navigateToProfilePage, setMobilePanel, Sizes]
  );

  if (actionLoading || documentsLoading) {
    return (
      <Delay delay={200}>
        <div className="mb-12 px-6 mx-auto flex-grow max-w-5xl w-full flex flex-col py-16">
          <div className="space-y-4">
            <LargePulseCard />
            <LargePulseCard />
          </div>
        </div>
      </Delay>
    );
  }

  if (actionError || documentsError) {
    return (
      <InlineAlert
        title={t("couldNotLoadHomepage")}
        intent={Intent.FAILURE}
        actions={
          <Button size={Sizes.SM} onClick={() => onUpdate()}>
            {t("reload")}
          </Button>
        }
      />
    );
  }

  return (
    <div className="mb-12 px-6 mx-auto flex-grow max-w-5xl w-full flex flex-col pb-16">
      {isUpdateAvailable && (
        <div className="py-8">
          <InlineAlert
            intent={Intent.INFO}
            title={t("newContentAvailable")}
            children={
              <div className="pr-4">
                {t("newContentAvailablePlease")}
                {!standaloneMode
                  ? t("refreshAndCloseAllOtherTabs")
                  : t("closeAndReOpenTheApp")}
                {t("toSeeTheLatestChanges")}
              </div>
            }
            actions={
              <Button onClick={updateAssets}>{t("refreshContent")}</Button>
            }
          />
        </div>
      )}
      <div>{isMobile ? mobileHeader : header}</div>
      <div className="my-2 grid grid-cols-12 items-start pb-8">
        <div className="col-span-12 flex flex-col lg:col-span-9 order-2 lg:order-1">
          <ActionsList
            actions={actionData.coworkerActions}
            onUpdate={onUpdate}
          />
          <DocumentsList
            documents={documentData.coworkerDocuments}
            onUpdate={onUpdate}
          />
        </div>
        <div className="col-span-12 lg:col-span-3 order-1 lg:order-2 lg:ml-4">
          <GroupInfo />
        </div>
      </div>
    </div>
  );
};

export default Home;

import { Intent, useToaster } from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  useCreateCustomResourceMutation,
  useRemoveCustomResourceMutation,
} from "../../../../generated-hooks";
import uploadAttachments from "../../../../utils/uploadAttachment";
import Sentry from "../../../../sentryClient";
import { useModals } from "../../../../Modals";

const useCustomResources = () => {
  const [
    invokeCreateCustomResource,
    { error: createCustomResourceError, loading: createCustomResourceLoading },
  ] = useCreateCustomResourceMutation();
  const [
    invokeRemoveCustomResource,
    { error: removeCustomResourceError },
  ] = useRemoveCustomResourceMutation();

  const toaster = useToaster();
  const { t } = useTranslation();
  const { openConfirmationModal } = useModals();

  const uploadAttachment = async (e: any) => {
    const file = e.target.files[0];
    const [attachment] = await uploadAttachments([file]);
    return attachment;
  };

  const createCustomResource = async ({
    refetch,
    groupId,
    title,
    attachmentId,
  }: {
    refetch: () => Promise<any>;
    groupId?: string;
    title: string;
    attachmentId: string;
  }) => {
    try {
      await invokeCreateCustomResource({
        variables: {
          input: {
            attachmentId,
            groupId,
            title,
          },
        },
      });

      await refetch();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("success"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
      Sentry.captureException(e);
    }

    if (createCustomResourceError) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
      Sentry.captureException(createCustomResourceError);
    }
  };

  const removeCustomResource = async ({
    attachmentId,
    refetch,
    groupId,
  }: {
    attachmentId: string;
    refetch: () => Promise<any>;
    groupId?: string;
  }) => {
    const confirm = await openConfirmationModal({
      title: t("resourceRemoveConfirmationModalTitle"),
      actionText: t("confirm"),
      bodyText: t("resourceRemoveConfirmationModalBody"),
    });
    if (!confirm) {
      return;
    }
    try {
      invokeRemoveCustomResource({
        variables: { attachmentId, groupId },
      });
      await refetch();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("success"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
      Sentry.captureException(e);
    }

    if (removeCustomResourceError) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
      Sentry.captureException(removeCustomResourceError);
    }
  };

  return {
    createCustomResource,
    createCustomResourceLoading,
    removeCustomResource,
    uploadAttachment,
  };
};

export default useCustomResources;

import { ScreenSizes, useScreenSize } from "@get-frank-eng/design-system";
import * as React from "react";
import { Link } from "react-router-dom";
import * as spinners from "react-spinners";

export const CoworkerTableCard: React.FC<{
  to: string;
  title: string;
  loading: boolean;
  subtitle: string;
  rightTop?: any;
}> = ({ to, title, loading, rightTop, subtitle, children }) => {
  const screensize = useScreenSize();
  return (
    <div>
      <div
        className="px-4 pb-4 sticky flex flex-row justify-between items-center left-0"
        style={{
          width: screensize < ScreenSizes.MD ? "100vw" : "calc(100vw - 264px)",
        }}
      >
        <div className="flex-1">
          <Link to={to} className="t-small plus">
            {title}
          </Link>
          <div className="t-mini text-canvas-400 max-w-xl">{subtitle}</div>
          {rightTop}
        </div>
      </div>
      {/* {loading && (
        <div
          style={{ height: "460px" }}
          className="flex flex-col items-center justify-center"
        >
          <spinners.BeatLoader size={10} color="white" />
        </div>
      )} */}
      {children}
    </div>
  );
};

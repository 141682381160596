import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query Tasks($pagination: PaginationArgumentDTO!) {
    tasks(pagination: $pagination) {
      hasNext
      total
      objects {
        id
        title
        description
      }
    }
  }
`;

export default function useTasks() {
  const perPage = 25;

  const { loading, error, data, refetch, fetchMore } = useQuery<
    Pick<FrankBackendTypes.Query, "tasks">,
    FrankBackendTypes.QueryTasksArgs
  >(QUERY, {
    variables: {
      pagination: {
        page: 0,
        perPage,
      },
    },
  });

  const nextPage = React.useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          page: Math.round(data?.tasks.objects.length / perPage),
          perPage,
        },
      },
    });
  }, [fetchMore, data]);

  return {
    tasks: data?.tasks,
    loading: loading,
    error: error,
    refetch,
    nextPage,
  };
}

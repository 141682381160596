import * as React from "react";
import {
  Button,
  Checkbox,
  FormGroup,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiSelect,
  SelectOption,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import { useForm } from "react-hook-form";
import { useModals } from "../../Modals";
import useCreateAuthorizationCard from "../dataAccess/hooks/mutations/useCreateAuthorizationCard";
import useGroup from "../dataAccess/hooks/queries/useGroup";
import Loading from "../../components/Loading";
import AuthorizationCardForm from "../components/AuthorizationCardForm";
import { useTranslation } from "react-i18next";

import { startCase } from "lodash";

type FormParams = {
  language: string;
  company: string;
  notify: boolean;
};

const createFormField = (data: SelectOption) => ({
  [data.value]: { title: data.label, type: "string" },
});

const CreateAuthCardModal = ({ groupId }: { groupId: string }) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { createAuthorizationCard, loading } = useCreateAuthorizationCard();
  const { register, handleSubmit, getValues, errors } = useForm<FormParams>();
  const { closeModal, setModal } = useModals();
  const { group, loading: loadingGroup } = useGroup({ groupId });
  const [additionalFields, setAdditionalFields] = React.useState<
    SelectOption[]
  >([]);

  const defaultFields: SelectOption[] = [
    { value: "homeAddress", label: t("homeAddress") },
    { value: "homeCity", label: t("homeCity") },
    { value: "homeState", label: t("homeState") },
    { value: "homeZip", label: t("homeZip") },
    { value: "yourJobTitle", label: t("yourJobTitle") },
    { value: "yourManagersName", label: t("yourManagersName") },
    { value: "yourWorkLocation", label: t("yourWorkLocation") },
  ];

  const currentAdditionalFields: string[] = React.useMemo(() => {
    if (!group) {
      return [];
    }
    if (!group.partner.authorizationCardFormJSON) {
      return [];
    }

    const parsed = JSON.parse(group.partner.authorizationCardFormJSON);

    return Object.keys(parsed.properties);
  }, [group]);

  const potentialAdditionalFields = React.useMemo(() => {
    return defaultFields.filter(
      (f) => !currentAdditionalFields.includes(f.value)
    );
  }, [currentAdditionalFields]);

  const filterFields = (inputValue: string) => {
    return potentialAdditionalFields.filter((f) =>
      f.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // has to be a promise b/c we're using the Async version of the select
  const loadOptions = async (searchString) => filterFields(searchString);

  const values = getValues();

  const submitCreateAuthCard = async (formData: FormParams) => {
    try {
      await createAuthorizationCard({
        groupId,
        language: formData.language,
        notify: formData.notify,
        company: formData.company,
        additionalFields: additionalFields.length
          ? JSON.stringify(
              additionalFields
                .map((f) => createFormField(f))
                .reduce((acc, curr) => {
                  return { ...acc, ...curr };
                }, {})
            )
          : undefined,
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("success"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
    }
    closeModal();
  };

  if (loadingGroup) {
    return <Loading />;
  }

  const form = (
    <form
      onSubmit={handleSubmit(submitCreateAuthCard)}
      noValidate
      className="space-y-8"
      id="create-auth-card-form"
    >
      <section className="t-small">
        {t("youCanUseFrankToCreateACardColle")}
      </section>
      <section>
        <FormGroup
          name="language"
          label={t("authorizationLanguage")}
          id="language"
        >
          <Textarea
            register={register}
            id="language"
            maxLength={2000}
            defaultValue={group.authorizationLanguage}
            registerArgs={{ required: t("thisFieldIsRequired") }}
            errorText={errors?.language?.message}
            helpText={t("authorizationLanguageMayBeEditedBu") as string}
          />
        </FormGroup>
      </section>
      <section>
        <FormGroup name="company" label={t("employer")} id="company">
          <Input
            register={register}
            id="company"
            defaultValue={group.company}
            registerArgs={{ required: t("thisFieldIsRequired") }}
            errorText={errors?.company?.message}
            helpText={t("thisFieldShouldMatchTheNameOfThe") as string}
          />
        </FormGroup>
      </section>
      <section>
        <div className="t-mini plus pb-1">{t("addAdditionalFields")}</div>
        <div className="t-mini text-canvas-400 pb-1">
          {t("byDefaultWeCollectFirstNameLastName")}
          {!!currentAdditionalFields.length &&
            t("partnerNameIsAlsoCurrentlyCollecting", {
              partnerName: group.partner.name,
              additionalFields: currentAdditionalFields
                .map((f) => startCase(f))
                .join(", "),
            })}
          {t("youCanAlsoChooseToAddAdditionalFields")}
        </div>
        <MultiSelect
          value={additionalFields}
          loadOptions={loadOptions}
          defaultOptions={potentialAdditionalFields}
          onChange={(newValue) => {
            setAdditionalFields(newValue);
          }}
          noOptionsMessage={({ inputValue }) => {
            if (!potentialAdditionalFields.length) {
              return t("youAreAlreadyUsingAllAvailableFields");
            }
            return t("noOptions");
          }}
        />
        <div className="t-mini text-canvas-400 pt-2">
          {t("pleaseNoteThatTheseFieldsWillBeAd", {
            partnerName: group.partner.name,
          })}
        </div>
      </section>
      <section>
        <FormGroup
          name="notify"
          label={t("notifyAllCurrentVerifiedMembers")}
          id="notify"
        >
          <div className="flex flex-row space-x-2 items-center">
            <Checkbox ref={register} id="notify" defaultChecked name="notify" />
            <div className="t-small">
              {t("ifYouUncheckThisBoxWorkersWillBe")}
            </div>
          </div>
        </FormGroup>
      </section>
    </form>
  );

  return (
    <>
      <ModalHeader title={t("startCardCollectionCampaign")} />
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        <Button
          onClick={() =>
            setModal({
              type: "genericModal",
              props: {
                body: (
                  <AuthorizationCardForm
                    employer={getValues().company}
                    closeModal={closeModal}
                    isPreview
                    authorizationLanguage={
                      values.language || group.authorizationLanguage
                    }
                    previewAdditionalFields={additionalFields}
                  />
                ),
                title: t("previewAuthorizationCard"),
              },
            })
          }
        >
          {t("previewCard")}
        </Button>
        <Button
          form="create-auth-card-form"
          buttonStyle="brand"
          loading={loading}
        >
          {t("create")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default CreateAuthCardModal;

import {
  Button,
  Intent,
  useResponsive,
  useToaster,
  ScreenSizes,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import partition from "lodash/partition";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import Back from "../components/Back";
import useRecentlyAdded from "../Coworkers/dataAccess/useRecentlyAdded";
import useTableDataFetcher from "../Coworkers/dataAccess/useTableDataFetcher";
import Table from "../Coworkers/table";
import tablePresentations from "../Coworkers/table/tablePresentations";
import useIsScrolled from "../Coworkers/hooks/useIsScrolled";
import { useModals } from "../Modals";
import useCampaignData from "./data-access/useCampaignData";
import useInviteCollaborators from "./data-access/useInviteCollaborators";
import { FieldsForNonMemberCollaborators } from "./NewMemberInviteCollaboratorModal";
import { useTranslation } from "react-i18next";

const InviteCoworkersToCollaborate = () => {
  const { t } = useTranslation();
  const { campaignId } = useParams<{ campaignId: string }>();
  const { campaignData, campaignLoading, campaignRefetch } = useCampaignData(
    campaignId
  );

  const { screenSize } = useResponsive();

  const isMobile = screenSize <= ScreenSizes.SM;

  const [sortOrder, setSortOrder] = React.useState<{
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  }>({ column: null, order: null });

  const {
    inviteCollaborators,
    loading: loadingInvite,
  } = useInviteCollaborators(campaignId);

  const ref = React.useRef<HTMLDivElement>();
  const { isScrolledX } = useIsScrolled<HTMLDivElement>(ref);
  const [selectedCoworkers, setSelectedCoworkers] = React.useState<
    FrankBackendTypes.Coworker[]
  >([]);
  const presentations = tablePresentations.inviteCoworkerToCollaborate;

  const augmentedPresentations = React.useMemo(() => {
    return {
      ...presentations,
      filter: {
        restrictions: [
          ...presentations.filter.restrictions,
          {
            column: FrankBackendTypes.CoworkerFilterableColummns.Id,
            combinator: FrankBackendTypes.Combinators.NotOneOf,
            in: campaignData
              ? campaignData.collaborators.map((collab) => collab.coworker.id)
              : [],
          },
        ],
      } as FrankBackendTypes.CoworkerFilterDto,
    };
  }, [presentations, campaignData]);

  const { openConfirmationModal, setModal } = useModals();
  const history = useHistory();

  const getNewMemberInviteArgsFromModal = React.useCallback(() => {
    return new Promise<FieldsForNonMemberCollaborators>((resolve) => {
      setModal({
        type: "newMemberInviteCollaboratorModal",
        props: {
          campaignName: campaignData.title,
          onSubmit: resolve,
        },
      });
    });
  }, [setModal, campaignData]);

  const toaster = useToaster();

  const invite = React.useCallback(async () => {
    const [coworkersWhoYouCanInvite, coworkersNotAllowed] = partition(
      selectedCoworkers,
      (coworker) => coworker.canInviteToCampaign
    );
    const coworkersYouCanInviteWithWarning = coworkersWhoYouCanInvite.filter(
      (coworker) => coworker.shouldWarnBeforeInvite
    );
    const newCoworkers = coworkersWhoYouCanInvite.filter(
      (coworker) => coworker.status !== FrankBackendTypes.CoworkerStatus.Member
    );

    const showConfirmation =
      coworkersNotAllowed.length + coworkersYouCanInviteWithWarning.length > 0;
    if (showConfirmation) {
      const proceed = await openConfirmationModal({
        bodyText: (
          <>
            {coworkersYouCanInviteWithWarning.length > 0 && (
              <p className="t-small mb-2">
                {t("youSelectedCoworkerCountCoworke", {
                  coworkerCount: coworkersYouCanInviteWithWarning.length,
                })}
              </p>
            )}
            {coworkersNotAllowed.length > 0 && (
              <p className="t-small">
                {t("coworkerCountOfTheCoworkersYouS", {
                  coworkerCount: coworkersNotAllowed.length,
                })}
              </p>
            )}
          </>
        ),
        actionText: t("proceedWithInviteCountInvites", {
          inviteCount: coworkersWhoYouCanInvite.length,
        }),
        title: t("selectionWarning"),
        actionButtonDisabled: coworkersWhoYouCanInvite.length === 0,
      });
      if (!proceed) {
        return;
      }
    }
    const newMemberArgs: FieldsForNonMemberCollaborators = newCoworkers.length
      ? await getNewMemberInviteArgsFromModal()
      : {
          messageForNewMembers: "",
        };

    try {
      await inviteCollaborators({
        coworkerIds: coworkersWhoYouCanInvite.map((c) => c.id),
        ...newMemberArgs,
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
      throw e;
    }

    await campaignRefetch();

    toaster.addToast({
      intent: Intent.SUCCESS,
      children: t("coworkersInvited"),
    });

    history.goBack();
  }, [
    getNewMemberInviteArgsFromModal,
    openConfirmationModal,
    selectedCoworkers,
    inviteCollaborators,
    toaster,
    history,
    campaignRefetch,
    t,
  ]);

  const {
    data,
    loading,
    fetchNextPage,
    refetch: refetchFromBackend,
  } = useTableDataFetcher({
    filter: augmentedPresentations.filter,
    order: sortOrder.column
      ? {
          column: sortOrder.column as FrankBackendTypes.CoworkerOrderColumns,
          order: sortOrder.order as FrankBackendTypes.Order,
        }
      : undefined,
  });

  const {
    changeAndSetRecentlyAdded,
    changeLoading,
    computedCoworkers,
  } = useRecentlyAdded({
    refetchFromBackend,
    coworkers: data?.coworkers.objects,
    allowAdd: presentations.allowAdd,
  });

  if (!data?.coworkers || campaignLoading) {
    return null;
  }
  return (
    <>
      <div className="fixed z-10 bg-canvas-700 top-w w-screen h-16 flex flex-row items-center flex-grow md:grid md:grid-cols-3 md:w-full">
        <div className="flex flex-row t-mini plus items-center">
          <Back />
        </div>
        <div className="flex-grow flex overflow-visible flex-row justify-center t-small plus items-center">
          {isMobile ? "" : "Invite co-organizers to campaign"}
        </div>
        <div className="flex-shrink-0 flex flex-row justify-end items-center">
          <Button
            className="rounded-full mr-2 items-center flex flex-row"
            buttonStyle="minimal"
            onClick={() =>
              setModal({ type: "linkInviteModal", props: { campaignId } })
            }
            iconLeft="link"
          />
          <Button
            className="md:w-48"
            loading={loadingInvite}
            disabled={selectedCoworkers.length === 0}
            buttonStyle="brand"
            onClick={invite}
          >
            {isMobile
              ? "Invite"
              : `Invite ${selectedCoworkers.length} ${
                  selectedCoworkers.length === 1
                    ? "co-organizer"
                    : "co-organizers"
                }`}
          </Button>
        </div>
      </div>
      <div className="mt-16">
        <div className="flex-grow">
          <Table
            changeAndSetRecentlyAdded={changeAndSetRecentlyAdded}
            changeLoading={changeLoading}
            computedCoworkers={computedCoworkers}
            key={JSON.stringify(sortOrder)}
            data={data.coworkers}
            columns={augmentedPresentations.columns}
            allowAdd={augmentedPresentations.allowAdd}
            loading={loading}
            fetchNextPage={fetchNextPage}
            onSelectCoworker={setSelectedCoworkers}
            isScrolledX={isScrolledX}
            customColumns={data?.customColumns}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
        </div>
      </div>
    </>
  );
};

export default InviteCoworkersToCollaborate;

import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query Organizers($partnerId: ID!, $pagination: PaginationArgumentDTO!) {
    organizersByPartner(partnerId: $partnerId, pagination: $pagination) {
      hasNext
      objects {
        id
        createdAt
        userId
        partnerId
        user {
          id
          name
          profilePic {
            id
            url
          }
          organizingGroups {
            id
            name
          }
          currentOnboardings {
            id
            groupId
            group {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default function useOrganizers(partnerId) {
  const perPage = 100;

  const { loading, error, data, refetch, fetchMore } = useQuery<
    Pick<FrankBackendTypes.Query, "organizersByPartner">,
    FrankBackendTypes.QueryOrganizersByPartnerArgs
  >(QUERY, {
    skip: !partnerId,

    variables: {
      pagination: {
        page: 0,
        perPage,
      },
      partnerId,
    },
  });

  const nextPage = React.useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          page: Math.round(data?.organizersByPartner.objects.length / perPage),
          perPage,
        },
        partnerId,
      },
    });
  }, [fetchMore, data, partnerId]);

  return {
    organizers: data?.organizersByPartner,
    loading: loading,
    error: error,
    refetch,
    nextPage,
  };
}

import { FullCenter } from "@get-frank-eng/design-system";
import * as React from "react";
import * as spinner from "react-spinners";

export const RawLoading = () => <spinner.BeatLoader color="#292929" />;

const Loading = () => (
  <FullCenter>
    <spinner.BeatLoader color="#292929" />
  </FullCenter>
);

export default Loading;

import * as React from "react";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { useCurrentUserData } from "../Auth/useCurrentUserData";
import Loading from "../components/Loading";
import useCampaignData from "./data-access/useCampaignData";
import useRemoveCollaborator from "./data-access/useRemoveCollaborator";
import { Avatar, Icon, LinkButton, Sizes } from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";

const ManageCollaborators = ({ campaignId }: { campaignId: string }) => {
  const { campaignData, campaignLoading, campaignRefetch } = useCampaignData(
    campaignId
  );
  const {
    removeCollaborator,
    loadingRemoveCollaborator,
  } = useRemoveCollaborator({ campaignId, onRemove: campaignRefetch });
  const { t } = useTranslation();

  const { myProfile } = useCurrentUserData();

  if (campaignLoading) {
    return <Loading />;
  }
  return (
    <div>
      <div className="t-large plus">{t("currentOrganizers")}</div>
      {campaignData?.collaborators.map((collab) => (
        <div
          key={collab.id}
          className="border-b last:border-b-0 py-6 flex flex-row items-center justify-between"
        >
          <div className="flex flex-row items-center">
            <Avatar
              size={Sizes.MD}
              src={collab.coworker.user?.profilePic?.url}
              altText={collab.coworker.name}
              link={`/users/${collab.coworker.user?.id}`}
            />
            <div className="ml-2">
              <div className="t-small plus">{collab.coworker.name}</div>
              <div className="t-small text-canvas-400">
                {startCase(toLower(collab.workflowState))}
              </div>
            </div>
          </div>

          {myProfile.id !== collab.coworker.user?.id && (
            <button
              type="button"
              onClick={() => removeCollaborator(collab.coworker.id)}
              disabled={loadingRemoveCollaborator}
              className="flex justify-center items-center"
            >
              <Icon size={Sizes.MD} icon="close" />
            </button>
          )}
        </div>
      ))}
      <div className="pt-8 pb-6 w-56">
        <LinkButton
          buttonStyle="outline"
          to={`/campaigns/${campaignId}/inviteCollaborators`}
        >
          {t("inviteMoreOrganizers")}
        </LinkButton>
      </div>
    </div>
  );
};

export default ManageCollaborators;

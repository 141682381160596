import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query AuthorizationCardUnsignedMembers(
    $unsignedMembersPagination: PaginationArgumentDTO!
    $groupId: ID!
  ) {
    authorizationCard(groupId: $groupId) {
      id
      unsignedMembers(pagination: $unsignedMembersPagination) {
        hasNext
        total
        objects {
          id
          name
          email
          joinedAt
        }
      }
    }
  }
`;

export default function useAuthorizationCardUnsignedMembers({
  groupId,
  perPage,
  page,
}) {
  const { loading, error, data, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "authorizationCard">,
    FrankBackendTypes.QueryAuthorizationCardArgs & {
      unsignedMembersPagination: { page: number; perPage: number };
    }
  >(QUERY, {
    variables: {
      unsignedMembersPagination: {
        page,
        perPage,
      },
      groupId,
    },
  });

  return {
    unsignedMembers: data?.authorizationCard
      ? data.authorizationCard.unsignedMembers
      : { objects: [], hasNext: false, total: 0 },
    loading: loading,
    error: error,
    refetchUnsignedMembers: refetch,
  };
}

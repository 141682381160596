"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.showMentionMarkdownText = exports.showMentionText = exports.MentionTextarea = void 0;
const React = __importStar(require("react"));
const useComposeEventHandler_1 = require("../../hooks/useComposeEventHandler");
const useMaxLength_1 = require("../../hooks/useMaxLength");
const FormGroup_1 = require("./FormGroup");
const FormHelpText_1 = require("./FormHelpText");
const react_mentions_1 = require("react-mentions");
const mentions_parser_1 = require("@get-frank-eng/mentions-parser");
const menus_1 = require("../menus");
const renderSuggestion = (entry, search, highlightedDisplay, index, focused) => {
    return (React.createElement(menus_1.MenuItem, { selected: focused },
        React.createElement("div", { className: "flex flex-row items-center space-x-2" },
            entry["preview"] && entry["preview"],
            React.createElement("div", null, highlightedDisplay))));
};
const MentionTextarea = (_a) => {
    var { mentionsInputRef, helpTextRef, charCountRef, helpText, maxLength, register, triggers, registerArgs, onChange, onFocus, id, name, errorText } = _a, textareaProps = __rest(_a, ["mentionsInputRef", "helpTextRef", "charCountRef", "helpText", "maxLength", "register", "triggers", "registerArgs", "onChange", "onFocus", "id", "name", "errorText"]);
    const [innerValue, setInnerValue] = React.useState(textareaProps.defaultValue);
    const { length, onChangeForCounter, onFocusForCounter } = (0, useMaxLength_1.useMaxLength)({
        maxLength
    });
    const [isDirty, setDirty] = React.useState(false);
    const setDirtyTrue = React.useCallback(() => setDirty(true), [setDirty]);
    const composedOnChange = (0, useComposeEventHandler_1.useComposeEventHandler)([
        // @ts-ignore
        event => setInnerValue(event.target.value),
        onChange,
        onChangeForCounter
    ]);
    const composedOnFocus = (0, useComposeEventHandler_1.useComposeEventHandler)([
        onFocus,
        setDirtyTrue,
        onFocusForCounter
    ]);
    if (register) {
        register({ name }, registerArgs);
    }
    return (React.createElement(React.Fragment, null,
        charCountRef && (React.createElement(FormGroup_1.CharCountPortal, { elemRef: charCountRef }, maxLength && isDirty && (React.createElement(FormHelpText_1.CharCounter, { current: length, limit: maxLength })))),
        React.createElement("div", { className: errorText ? "error" : "" },
            React.createElement(react_mentions_1.MentionsInput, Object.assign({ inputRef: mentionsInputRef, onChange: composedOnChange, onFocus: composedOnFocus, id: id, 
                // @ts-ignore
                value: innerValue, rows: 5, name: name, style: {
                    input: { overflow: "auto" }
                } }, textareaProps, { className: "mention-textarea" }), triggers.map((_a, i) => {
                var { type } = _a, triggerProps = __rest(_a, ["type"]);
                return (React.createElement(react_mentions_1.Mention, Object.assign({ appendSpaceOnAdd: true, key: `${i}mention${type}${JSON.stringify(triggerProps.data)}`, renderSuggestion: renderSuggestion, markup: `@(${type})(__display__)(__id__)` }, triggerProps)));
            }))),
        helpTextRef && (React.createElement(FormGroup_1.HelpTextPortal, { elemRef: helpTextRef },
            helpText && React.createElement(FormHelpText_1.HelpText, null, helpText),
            errorText && React.createElement(FormHelpText_1.ErrorText, null, errorText)))));
};
exports.MentionTextarea = MentionTextarea;
function showMentionText({ text, renderMention }) {
    const elements = (0, mentions_parser_1.addMentionsToString)({
        text,
        renderMention
    });
    return React.createElement("div", null, elements);
}
exports.showMentionText = showMentionText;
function showMentionMarkdownText({ text, renderMention }) {
    const parsedText = (0, mentions_parser_1.addMentionsToMarkdownString)({
        text,
        renderMention
    });
    return React.createElement("div", { dangerouslySetInnerHTML: { __html: parsedText } });
}
exports.showMentionMarkdownText = showMentionMarkdownText;

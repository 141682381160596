import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import {
  CoworkersQueryHookResult,
  useCoworkersQuery,
} from "../../../../generated-hooks";

export default function useCoworkerAssessments({ groupId }) {
  const perPage = 25;

  const filter = React.useMemo(
    () => ({
      restrictions: [
        {
          column: FrankBackendTypes.CoworkerFilterableColummns.Status,
          combinator: FrankBackendTypes.Combinators.NotOneOf,
          in: ["organizer", "archived", "deleted"],
        },
      ],
    }),
    []
  );

  const { loading, error, data, fetchMore, refetch } = useCoworkersQuery({
    variables: {
      filter,
      pagination: {
        page: 0,
        perPage,
      },
      explicitGroupId: groupId,
    },
  });

  const fetchNextPage = React.useCallback(() => {
    fetchMore({
      variables: {
        pagination: {
          perPage,
          page: Math.round(data.coworkers.objects.length / perPage),
        },
        filter,
        explicitGroupId: groupId,
      },
      updateQuery(prev: CoworkersQueryHookResult["data"], { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          coworkers: {
            ...fetchMoreResult.coworkers,
            objects: [
              ...prev.coworkers.objects,
              ...fetchMoreResult.coworkers.objects,
            ],
          },
        };
      },
    });
  }, [fetchMore, data, filter, perPage, groupId]);

  return {
    // this is super dumb: what I actually want to do is something more like select from "denormalizedCoworker" where  "status" !=  'organizer' and ("email" is not null or "name" is not null), but surprisingly this is tricky with current architecture
    // so now we have this bandaid
    coworkers: {
      ...data?.coworkers,
      objects: data?.coworkers.objects.filter((c) => c.email || c.name),
    },
    loading,
    error,
    fetchNextPage,
    refetch,
  };
}

import {
  Button,
  IconButton,
  Intent,
  Sizes,
  Tab,
  Tabs,
  TopBar,
  useLayout,
  useToaster,
} from "@get-frank-eng/design-system";
import { AbilityAction } from "frank-types";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";
import { useCurrentUserData } from "../Auth/useCurrentUserData";
import Loading from "../components/Loading";
import usePartner from "../SSO/dataAccess/queries/usePartner";
import ListTasks from "../Tasks/pages/ManageTasks";
import copyText from "../utils/copyText";
import AddCustomForm from "./pages/AddCustomForm";
import Assessments from "./pages/Assessments";
import AuthorizationCards from "./pages/AuthorizationCards";
import CustomForms from "./pages/CustomForms";
import ListGroups from "./pages/ListGroups";
import ListOrganizers from "./pages/ListOrganizers";
import OnboardingFormResponses from "./pages/ListWorkers";
import PartnerSettings from "./pages/PartnerSettings";

export default function PartnerRouter() {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { partnerSlug } = useParams<{ partnerSlug: string }>();
  const toaster = useToaster();

  const { loading, partner, refetch } = usePartner({ partnerSlug });
  const { ability, preferredLanguage } = useCurrentUserData();
  const canAccessPage = ability?.can(AbilityAction.Manage, "Group");
  const { setMobilePanel } = useLayout();

  const location = useLocation();

  const pages = {
    groups: t("groups"),
    organizers: t("organizers"),
    settings: t("settings"),
    workers: t("workersAllGroups"),
    "custom-form": t("addCustomForm"),
    "cust-form": t("customForms"),
    tasks: t("tasks"),
    "authorization-cards": t("authorizationCards"),
    assessments: t("assessments"),
  };

  if (!canAccessPage) {
    return <Redirect to="../../home" />;
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div className="md:hidden fixed z-50 top-0 w-screen">
        <TopBar
          classNames="bg-canvas-800"
          left={
            <IconButton
              size={Sizes.XL}
              onClick={() => setMobilePanel("left")}
              buttonStyle="minimal"
              icon="menu"
            />
          }
          title={pages[location.pathname.split("/").slice(-1)[0]]}
        />
      </div>
      <div className="max-w-5xl mx-auto px-4 md:mt-12 mt-24 w-full">
        <div>
          <div className="flex justify-between pb-3">
            <h1 className="t-title-4 plus">{partner.name}</h1>
            <Button
              iconLeft="content_copy"
              onClick={() => {
                copyText(
                  `${process.env.REACT_APP_FRONTEND_URL}/join/${partnerSlug}/email`
                );
                toaster.addToast({
                  intent: Intent.SUCCESS,
                  icon: "content_copy",
                  children: t("linkCopied"),
                });
              }}
            >
              {t("copySignupLink")}
            </Button>
          </div>
          <Tabs lng={preferredLanguage}>
            <Tab
              active={window.location.href.includes("groups")}
              label={t("groups")}
              to={`${url}/groups`}
            />
            <Tab
              active={window.location.href.includes("organizers")}
              label={t("organizers")}
              to={`${url}/organizers`}
            />
            <Tab
              active={window.location.href.includes("settings")}
              label={t("settings")}
              to={`${url}/settings`}
            />
            <Tab
              active={window.location.href.includes("workers")}
              label={t("workers")}
              to={`${url}/workers`}
            />
            {partner.hasCustomForms ? (
              <Tab
                active={window.location.href.includes("cust-forms")}
                label={t("customForms")}
                to={`${url}/cust-forms`}
              />
            ) : (
              <></>
            )}
            {ability?.can(AbilityAction.Create, "Partner") ? (
              <Tab
                active={window.location.href.includes("custom-form")}
                label={t("addCustomForm")}
                to={`${url}/custom-form`}
              />
            ) : (
              <></>
            )}
            <Tab
              active={window.location.href.includes("tasks")}
              label={t("tasks")}
              to={`${url}/tasks`}
            />
            <Tab
              active={window.location.href.includes("authorization-cards")}
              label={t("authorizationCards")}
              to={`${url}/authorization-cards`}
            />
            <Tab
              active={window.location.href.includes("assessments")}
              label={t("assessments")}
              to={`${url}/assessments`}
            />
          </Tabs>
        </div>
        <div className="h-6" />
        <Switch>
          <Route path={`${url}/groups`}>
            <ListGroups partnerId={partner.id} />
          </Route>

          <Route path={`${url}/organizers`}>
            <ListOrganizers partnerId={partner.id} />
          </Route>

          <Route path={`${url}/settings`}>
            <PartnerSettings partner={partner} refetchPartnerData={refetch} />
          </Route>
          <Route path={`${url}/workers`}>
            <OnboardingFormResponses />
          </Route>
          {partner.hasCustomForms && (
            <Route path={`${url}/cust-forms`}>
              <CustomForms />
            </Route>
          )}
          {ability?.can(AbilityAction.Create, "Partner") && (
            <Route path={`${url}/custom-form`}>
              <AddCustomForm partnerSlug={partner.slug} />
            </Route>
          )}
          <Route path={`${url}/tasks`}>
            <ListTasks />
          </Route>
          <Route path={`${url}/authorization-cards`}>
            <AuthorizationCards />
          </Route>
          <Route path={`${url}/assessments`}>
            <Assessments />
          </Route>

          <Route>
            <Redirect to={`${url}/groups`} />
          </Route>
        </Switch>
        <div className="h-6 md:h-0" />
      </div>
    </>
  );
}

import {
  IconButton,
  ScreenSizes,
  TopBar,
  useScreenSize,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { Switch, Route, useHistory } from "react-router";
import Coworkers from ".";
import { CoworkerTopBarWrapper } from "./components/CoworkerTopBarWrapper";
import { ManagementTable } from "./ManagementTable";
import { MemberTable } from "./MemberTable";
import { UnverifiedCoworkersTable } from "./UnverifiedCoworkersTable";
import { useTranslation } from "react-i18next";

const CoworkersTopBar = ({ title }) => {
  const { push } = useHistory();

  return (
    <TopBar
      left={
        <IconButton
          onClick={() => push("../coworkers")}
          buttonStyle="minimal"
          icon="arrow_back"
        />
      }
      title={
        <div className="flex flex-col items-center justify-center">
          <div>{title}</div>
        </div>
      }
    />
  );
};

export const CoworkerRoutes = () => {
  const screenSize = useScreenSize();
  const isMobile = screenSize < ScreenSizes.MD;
  const { t } = useTranslation();

  return (
    <Switch>
      <Route path="/coworkers/members">
        <div>
          <CoworkerTopBarWrapper>
            <CoworkersTopBar
              title={
                isMobile ? t("verifiedMembers") : t("coworkersVerifiedMembers")
              }
            />
          </CoworkerTopBarWrapper>

          <section className="flex-grow pt-20">
            <MemberTable showAll />
          </section>
          <div className="h-48 sm:h-0" />
        </div>
      </Route>
      <Route path="/coworkers/workers">
        <div>
          <CoworkerTopBarWrapper>
            <CoworkersTopBar
              title={
                isMobile ? t("verifiedMembers") : t("coworkersVerifiedMembers")
              }
            />
          </CoworkerTopBarWrapper>

          <section className="flex-grow pt-20">
            <UnverifiedCoworkersTable showAll />
          </section>
          <div className="h-48 sm:h-0" />
        </div>
      </Route>
      <Route path="/coworkers/management">
        <div>
          <CoworkerTopBarWrapper>
            <CoworkersTopBar
              title={isMobile ? t("management") : t("coworkersManagement")}
            />
          </CoworkerTopBarWrapper>

          <section className="flex-grow pt-20">
            <ManagementTable showAll />
          </section>
          <div className="h-48 sm:h-0" />
        </div>
      </Route>
      <Route path="/">
        <Coworkers />
      </Route>
    </Switch>
  );
};

import * as React from "react";
import { Intent, MenuItem, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useModals } from "../../Modals";
import useCreateOrUpdateTask from "../dataAccess/mutations/useCreateTask";
import { useTranslation } from "react-i18next";

interface Props {
  task: FrankBackendTypes.Task;
  refetch?: () => any;
}

const TaskMenu = ({ task, refetch }: Props) => {
  const { t } = useTranslation();
  const { openConfirmationModal } = useModals();
  const { createOrUpdateTask } = useCreateOrUpdateTask();
  const toaster = useToaster();

  const handleArchive = React.useCallback(
    async (e) => {
      try {
        const confirm = await openConfirmationModal({
          title: t("areYouSureYouWantToArchiveThisTa"),
          bodyText: t("allWorkersWhoAreAssignedThisTaskW"),
          actionText: t("confirm"),
        });
        if (!confirm) {
          return;
        }
        await createOrUpdateTask({
          taskId: task.id,
          archivedAt: new Date(),
          title: task.title,
        });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("success"),
        });
        await refetch();
      } catch (e) {
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("somethingWentWrong"),
        });
        throw e;
      }
    },
    [
      createOrUpdateTask,
      openConfirmationModal,
      refetch,
      task.id,
      task.title,
      toaster,
      t,
    ]
  );

  return (
    <>
      <MenuItem onClick={handleArchive}>{t("archive")}</MenuItem>
    </>
  );
};

export default TaskMenu;

import {
  Button,
  EmptyState,
  Icon,
  InlineAlert,
  Intent,
  Nudge,
  Sizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import { RawLoading } from "../../../components/Loading";
import CustomFormResponseEdit from "../../../CustomForm/CustomFormResponseEdit";
import {
  useCoworkerCustomFormResponseListsQuery,
  useCustomFormResponsesByGroupIdQuery,
} from "../../../generated-hooks";
import { useModals } from "../../../Modals";
import { dateFormatter } from "../../../utils/Date";
import CustomFormResponseSubmissionsTable from "./CustomFormResponseSubmissionsTable";
import {
  CustomFormResponseListResponse,
  CustomFormResponseSubmission,
} from "./models";
import SubmittedByCoworkerTable from "./SubmittedByCoworkerTable";
import useCustomFormsPathing from "./useCustomFormsPathing";

interface RouteParams {
  id: string;
  groupId: string;
}

const resultsPerPage = 200;

const NotSubmittedByCoworkerTable = ({
  inProgressForms,
}: {
  inProgressForms: CustomFormResponseListResponse[];
}): JSX.Element => {
  const { t } = useTranslation();
  const hasUnsubmittedForms = !!inProgressForms.length;

  const statusSwitch = (numberOfResponses: number) => {
    if (numberOfResponses > 0) {
      return t("inProgress");
    }
    return t("notStarted");
  };
  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <div className="t-large plus">{t("notSubmitted")}</div>
      </div>
      {hasUnsubmittedForms ? (
        <div className="overflow-x-auto rounded border">
          <table className="text-sm w-full text-left">
            <thead>
              <tr className="border-b border-canvas-500 bg-canvas-500 divide-x divide-canvas-500">
                <th className="px-6 py-2 bg-canvas-500 border-none">
                  {t("name")}
                </th>
                <th className="px-6 py-2 bg-canvas-500 border-none">
                  {t("email")}
                </th>
                <th className="px-6 py-2 bg-canvas-500 border-none">
                  {t("formStatus")}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-canvas-500">
              {inProgressForms.map((r) => (
                <tr key={r.coworker.id}>
                  <td className="px-6 py-4 border-b border-canvas-500">
                    {r.coworker.name}
                  </td>
                  <td className="px-6 py-4 border-b border-canvas-500">
                    {r.coworker.email}
                  </td>
                  <td className="px-6 py-4 border-b border-canvas-500">
                    {statusSwitch(r.responses.length)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyState title={t("allEligibleWorkersSubmittedForm")} />
      )}
    </>
  );
};

const ManageCustomForm = () => {
  const { id: customFormId, groupId } = useParams<RouteParams>();
  const { t } = useTranslation();
  const { preferredLanguage } = useCurrentUserData();
  const { setModal, closeModal } = useModals();
  const { generateCustomFormUrl } = useCustomFormsPathing();

  const customFormResponsesPage = React.useRef(0);
  const coworkersNotYetSubmittedPage = React.useRef(0);

  // FIXME: we just need to get the custom form here
  const {
    data,
    error,
    loading,
    refetch,
  } = useCustomFormResponsesByGroupIdQuery({
    variables: {
      customFormId,
      groupId,
      pagination: {
        page: customFormResponsesPage.current,
        perPage: resultsPerPage,
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const {
    data: responseListData,
    error: responseListError,
    loading: responseListLoading,
    refetch: responseListRefetch,
  } = useCoworkerCustomFormResponseListsQuery({
    variables: {
      customFormId,
      groupId,
      pagination: {
        page: coworkersNotYetSubmittedPage.current,
        perPage: resultsPerPage,
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const onOrganizerSubmit = React.useCallback(async () => {
    refetch();
    responseListRefetch();
    closeModal();
  }, [refetch, responseListRefetch, closeModal]);

  const submittedResponsesByCoworker = React.useMemo(() => {
    if (!responseListData) {
      return [];
    }

    // Filter out coworkers who only have submitted responses
    const hasSubmitted = responseListData.getCoworkerCustomFormResponseLists.objects
      .filter(
        (c) => c.responses.filter((r) => r.submittedAt !== null).length > 0
      )
      .map((c) => ({
        coworker: c.coworker,
        responses: c.responses.filter((r) => r.submittedAt !== null),
      }));
    return hasSubmitted;
  }, [responseListData]);

  const inProgressFormsByCoworker = React.useMemo(() => {
    if (!responseListData) {
      return [];
    }
    // Filter out coworkers who only have submitted responses
    const notComplete = responseListData.getCoworkerCustomFormResponseLists.objects.filter(
      (c) =>
        c.responses.filter((r) => r.submittedAt === null).length > 0 ||
        c.responses.length === 0
    );
    return notComplete.sort((a, b) => {
      if (a.coworker.name < b.coworker.name) {
        return -1;
      } else if (a.coworker.name === b.coworker.name) {
        return 0;
      } else {
        return 1;
      }
    });
  }, [responseListData]);

  const submittedResponses: CustomFormResponseSubmission[] = React.useMemo(() => {
    const submittedResponses: CustomFormResponseSubmission[] = submittedResponsesByCoworker.reduce(
      (allResponses, coworkerResponses) => {
        return [
          ...allResponses,
          ...coworkerResponses.responses.map((r) => ({
            id: r.id,
            coworker: {
              email: coworkerResponses.coworker.email,
              name: coworkerResponses.coworker.name,
            },
            submittedAt: r.submittedAt,
          })),
        ];
      },
      []
    );
    return submittedResponses.sort((a, b) => {
      if (a.submittedAt < b.submittedAt) {
        return 1;
      }
      if (a.submittedAt > b.submittedAt) {
        return -1;
      }
      return 0;
    });
  }, [submittedResponsesByCoworker]);

  if (loading || responseListLoading) {
    return <RawLoading />;
  }

  if (error || responseListError) {
    return (
      <div className="max-w-xl">
        <InlineAlert
          title="Error loading page"
          intent={Intent.FAILURE}
          actions={
            <Button
              size={Sizes.SM}
              onClick={() => {
                refetch();
                responseListRefetch();
              }}
            >
              {t("reload")}
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="pb-48 sm:pb-12 max-w-6xl mx-auto w-full space-y-4">
      <div>
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-1">
            <li>
              <div>
                <Link
                  to={generateCustomFormUrl(groupId)}
                  className="t-small plus underline"
                >
                  {t("allForms")}
                </Link>
              </div>
            </li>
            <Nudge y={1}>
              <li>
                <div className="flex items-center t-small">
                  <Icon
                    icon="chevron_right"
                    size={Sizes.LG}
                    classNames="flex-shrink-0"
                    aria-hidden="true"
                  />
                  {data.customForm.title}
                </div>
              </li>
            </Nudge>
          </ol>
        </nav>
      </div>
      <section className="border rounded p-4 max-w-sm">
        <h3 className="t-regular plus pb-2">{data.customForm.title}</h3>
        <p className="t-small">
          {t("datePublished")}
          {dateFormatter(preferredLanguage).format(
            new Date(data.customForm.createdAt)
          )}
        </p>
        <p className="t-small">
          {t("totalSubmissions")}: {submittedResponses.length}
        </p>
        <div className="flex flex-row space-x-2 items-center pt-2">
          <Button
            size={Sizes.SM}
            buttonStyle="outline"
            onClick={() =>
              setModal({
                type: "customFormModal",
                props: {
                  title: t("previewForm"),
                  body: (
                    <CustomFormResponseEdit
                      customFormId={customFormId}
                      viewOnly
                    />
                  ),
                },
              })
            }
          >
            {t("previewForm")}
          </Button>
          <Button
            buttonStyle="outline"
            size={Sizes.SM}
            onClick={() =>
              setModal({
                type: "customFormModal",
                props: {
                  title: t("fillOutCustomForm"),
                  body: (
                    <CustomFormResponseEdit
                      customFormId={customFormId}
                      groupId={groupId}
                      onComplete={onOrganizerSubmit}
                    />
                  ),
                },
              })
            }
          >
            {t("fillOutCustomForm")}
          </Button>
        </div>
      </section>
      <section className="pt-8 space-y-4">
        {data.customForm.allowMultipleSubmissions ? (
          <SubmittedByCoworkerTable
            submissions={submittedResponsesByCoworker}
          />
        ) : (
          <CustomFormResponseSubmissionsTable
            submittedResponses={submittedResponsesByCoworker}
            groupId={groupId}
            customFormId={customFormId}
            filteredByCoworker={false}
          />
        )}
      </section>
      <section className="pt-8 space-y-4">
        <NotSubmittedByCoworkerTable
          inProgressForms={inProgressFormsByCoworker}
        />
      </section>
    </div>
  );
};

export default ManageCustomForm;

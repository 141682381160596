import * as React from "react";
import { useModals } from "../Modals";
import {
  Button,
  Card,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sizes,
} from "@get-frank-eng/design-system";
import useMyProfileDateAcceptedNoMgmt from "./dataAccess/queries/useMyProfileNoMgmtAgreement";
import Loading from "../components/Loading";
import { useHistory } from "react-router";
import { useAcceptNoMgmtAgreement } from "./dataAccess/queries/mutations/useAcceptNoMgmtAgreement";
import { useNavigationState } from "../Navigation/hooks/useNavigationState";
import { useCurrentUserData } from "../Auth/useCurrentUserData";
import { useTranslation } from "react-i18next";
import { QRInviteModal } from "./QRInviteModal";
import DefaultInvite from "./DefaultInvite";

// must alter Modals.tsx if you need to add props e.g. <Modal {...modal.props} />

const DefaultInviteModal = () => {
  const { t } = useTranslation();
  const {
    hasAcceptedShareWarning,
    error,
    loading,
    refetch,
  } = useMyProfileDateAcceptedNoMgmt();

  const { acceptNoMgmt } = useAcceptNoMgmtAgreement({ onAccept: refetch });
  const { refetch: refetchNavigation } = useNavigationState();
  const { currentGroup } = useCurrentUserData();

  const history = useHistory();
  if (loading) {
    return <Loading />;
  }

  if (error) {
    throw error;
  }

  if (!hasAcceptedShareWarning) {
    return (
      <>
        <ModalHeader
          icon={
            <Icon icon="warning" classNames="text-brand-400" size={Sizes.XL} />
          }
          specialty
          title={t("noManagersAllowed")}
        />
        <ModalBody specialty>
          <div className="t-small text-canvas-400">
            {t("thisGroupIsOnlyForNonManagementWorkers", {
              groupName: currentGroup.name,
            })}
          </div>
        </ModalBody>
        <ModalFooter specialty>
          <Button onClick={() => history.push("/legal")}>
            {t("readOurPolicies")}
          </Button>
          <Button
            buttonStyle="brand"
            onClick={() => {
              acceptNoMgmt();
              refetchNavigation();
            }}
          >
            {t("iAgree")}
          </Button>
        </ModalFooter>
      </>
    );
  }

  return (
    <>
      <ModalHeader title={t("inviteCoworkers")} />
      <DefaultInvite />
    </>
  );
};

export default DefaultInviteModal;

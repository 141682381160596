import { Button, EmptyState, UserCard } from "@get-frank-eng/design-system";
import { kebabCase } from "lodash";
import * as React from "react";
import Loading from "../../components/Loading";
import { useModals } from "../../Modals";
import { EditOrganizerGroups } from "../components/EditOrganizerGroups";
import useOrganizers from "../dataAccess/hooks/queries/useOrganizers";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

// i18n SKIPPED -- we'll need to do this one eventually, but it's just numbers right now
export const dateFormatter = new Intl.DateTimeFormat("en-US", {
  month: "numeric",
  year: "numeric",
  day: "numeric",
});

const ListOrganizers = ({ partnerId }: { partnerId: string }) => {
  const { preferredLanguage } = useCurrentUserData();
  const { t } = useTranslation();
  const { organizers, nextPage, loading, refetch } = useOrganizers(partnerId);

  const { setModal } = useModals();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="max-w-5xl mx-auto w-full">
      <Button
        dataCy="add-organizer-button"
        buttonStyle="brand"
        className="mb-3"
        onClick={() =>
          setModal({
            type: "addOrganizerModal",
            props: {
              partnerId,
            },

            afterClose: () => refetch(),
          })
        }
      >
        {t("addOrganizer")}
      </Button>
      {organizers.objects.length === 0 ? (
        <EmptyState title={t("noOrganizersYet")}>
          {t("thereAreNoOrganizersInThisOrg")}
        </EmptyState>
      ) : (
        <div className="grid gap-2 grid-cols-1 md:grid-cols-3">
          {organizers.objects.map((o) => {
            return (
              <button
                title={t("editOrganizersGroups")}
                key={o.user.id}
                onClick={() => {
                  setModal({
                    type: "genericModal",
                    props: {
                      title: `${o.user.name}`,
                      body: (
                        <EditOrganizerGroups
                          organizerId={o.id}
                          partnerId={o.partnerId}
                        />
                      ),
                    },
                  });
                }}
              >
                <UserCard
                  lng={preferredLanguage}
                  cta={null}
                  dataCy={kebabCase(o.user.name)}
                  userId={o.user.id}
                  profilePicUrl={o.user.profilePic.url}
                  name={o.user.name}
                  title={t("organizerSinceDate", {
                    date: dateFormatter.format(new Date(o.createdAt)),
                    interpolation: { escapeValue: false },
                  })}
                />
              </button>
            );
          })}
        </div>
      )}
      {organizers.hasNext && (
        <div className="py-8">
          <Button onClick={nextPage}>{t("showMore")}</Button>
        </div>
      )}
    </div>
  );
};

export default ListOrganizers;

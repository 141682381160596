import * as React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../Auth/useCurrentUserData";
import { QRCodeAndActions } from "./components/QRCodeAndActions";
import {
  linkGen,
  useCreateInviteLink,
} from "./dataAccess/queries/mutations/useCreateInviteLink";

export const QRInviteModal = ({
  groupId,
  groupName,
}: {
  groupId?: string;
  groupName?: string;
}) => {
  const { currentGroup } = useCurrentUserData();
  const { token, createInviteLink } = useCreateInviteLink({});
  const [qrCodeTitle, setQrCodeTitle] = React.useState<string>();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (currentGroup) {
      createInviteLink({ groupId: groupId || currentGroup.id });
    }
  }, [currentGroup, createInviteLink, groupId]);

  React.useEffect(() => {
    if (groupName) {
      setQrCodeTitle(t("joinGroupName", { groupName }));
      return;
    }

    if (currentGroup) {
      setQrCodeTitle(t("joinGroupName", { groupName: currentGroup.name }));
    }
  }, [currentGroup, groupName, t]);

  const link = linkGen(token);

  return <QRCodeAndActions link={link} qrCodeTitle={qrCodeTitle} />;
};

import { CachedFormState } from "./onboardingCache";
import * as onboardingState from "./state";

const orderArr = [
  onboardingState.OnboardingStep.CREATE_ACCOUNT,
  onboardingState.OnboardingStep.TELL_US_ABOUT_YOURSELF,
  onboardingState.OnboardingStep.VERIFY,
];
export function onboardingStepToIdx(
  step: onboardingState.OnboardingStep
): number {
  return orderArr.indexOf(step);
}

export function idxToOnboardingStep(
  idx: number
): onboardingState.OnboardingStep {
  return orderArr[idx];
}

export function nextStep(
  step: onboardingState.OnboardingStep
): onboardingState.OnboardingStep | null {
  const idx = onboardingStepToIdx(step);
  return idxToOnboardingStep(idx + 1);
}

export function prevStep(
  step: onboardingState.OnboardingStep
): onboardingState.OnboardingStep | null {
  const idx = onboardingStepToIdx(step);
  return idxToOnboardingStep(idx - 1);
}

export function getLocked(state: onboardingState.State) {
  return {
    [onboardingState.OnboardingStep.CREATE_ACCOUNT]: false,
    [onboardingState.OnboardingStep
      .TELL_US_ABOUT_YOURSELF]: !state.submitted.has(
      onboardingState.OnboardingStep.CREATE_ACCOUNT
    ),
    [onboardingState.OnboardingStep.VERIFY]:
      !state.submitted.has(onboardingState.OnboardingStep.CREATE_ACCOUNT) ||
      !state.submitted.has(
        onboardingState.OnboardingStep.TELL_US_ABOUT_YOURSELF
      ),
  };
}

const defaultState = Object.freeze({
  submitted: new Set<onboardingState.OnboardingStep>(),
  loading: null,
  stepInFocus: null,
  serverError: null,
  hasExistingMembership: null,
});

export function getInitialStateFromCache(
  cache: CachedFormState
): onboardingState.State {
  const hadCache = Object.keys(cache).length > 0;
  if (!hadCache) {
    return defaultState;
  }
  const set = new Set<onboardingState.OnboardingStep>();
  if (cache["createAccount.submitted"]) {
    set.add(onboardingState.OnboardingStep.CREATE_ACCOUNT);
  }
  if (cache["tellUs.submitted"]) {
    set.add(onboardingState.OnboardingStep.TELL_US_ABOUT_YOURSELF);
  }
  return {
    submitted: set,
    loading: null,
    serverError: null,
    stepInFocus:
      cache["createAccount.submitted"] && cache["tellUs.submitted"]
        ? onboardingState.OnboardingStep.VERIFY
        : cache["createAccount.submitted"]
        ? onboardingState.OnboardingStep.TELL_US_ABOUT_YOURSELF
        : onboardingState.OnboardingStep.CREATE_ACCOUNT,
    hasExistingMembership: null,
  };
}

import {
  Button,
  Delay,
  Icon,
  InlineAlert,
  Intent,
  LargePulseCard,
  Sizes,
} from "@get-frank-eng/design-system";
import {
  DataFieldError,
  SubmitDataFieldTableRowStatus,
} from "frank-types/dist/frank-backend-types";
import React, { useEffect } from "react";
import {
  useDataFieldTableAndDataFieldsQuery,
  useDataFieldTableRowsByRowSetIdLazyQuery,
  useCreateOrUpdateDataFieldTableRowsMutation,
} from "../generated-hooks";
import { v4 as uuid } from "uuid";
import DataFieldTableRow, { CellData, RowData } from "./DataFieldTableRow";
import { useTranslation } from "react-i18next";
import useCustomFormConfigContext from "./hooks/useCustomFormConfigContext";

const DataFieldTable = ({
  dataFieldTableId,
  rowSetId,
  disabled,
  onUpdateRowSetId,
}: {
  dataFieldTableId: string;
  rowSetId?: string | null;
  disabled: boolean;
  onUpdateRowSetId: (rowSetId: string | null) => void;
}) => {
  const { t } = useTranslation();
  // Set up our state / refs
  const [tableRows, setTableRows] = React.useState<RowData[]>([]);
  const isNewTable = React.useRef(false);
  const [tableErrors, setTableErrors] = React.useState<DataFieldError[][]>([]);
  // Set up our queries / mutation hooks
  const {
    data: tableData,
    loading,
    error,
    refetch,
  } = useDataFieldTableAndDataFieldsQuery({
    variables: { dataFieldTableId },
  });
  const [
    getResponse,
    { data: tableRowData, loading: tableRowLoading, error: tableRowError },
  ] = useDataFieldTableRowsByRowSetIdLazyQuery({
    defaultOptions: { fetchPolicy: "network-only" },
  });
  const [
    invokeCreateOrUpdateDataFieldTableRows,
  ] = useCreateOrUpdateDataFieldTableRowsMutation();
  // Handle updates to cell data in the table
  const updateRow = React.useCallback(
    (rowNumber: number, newRow: RowData) => {
      const newTableRows = [...tableRows];
      newTableRows[rowNumber] = newRow;
      setTableRows(newTableRows);
    },
    [tableRows, setTableRows]
  );
  // Add a new row to our dataset
  const addRow = React.useCallback(() => {
    if (tableRows.length === 0 && !rowSetId) {
      isNewTable.current = true;
      // When first editing a new table response, call onUpdate to set the rowSetId
      onUpdateRowSetId(uuid());
    }
    const newTableRows = [...tableRows];
    const sortedDataFields = [...tableData.dataFieldTable.dataFields].sort(
      (a, b) => a.order - b.order
    );
    newTableRows.push({
      key: uuid(),
      cells: sortedDataFields.map((field) => ({
        field: field.dataField,
      })),
    });
    setTableRows(newTableRows);
  }, [tableData, tableRows, setTableRows, rowSetId, onUpdateRowSetId]);
  // Delete a row from our dataset
  const deleteRow = React.useCallback(
    (index: number) => {
      const newTableRows = [...tableRows];
      newTableRows.splice(index, 1);
      setTableRows(newTableRows);
      if (newTableRows.length === 0) {
        onUpdateRowSetId(null);
      }
    },
    [tableRows, setTableRows, onUpdateRowSetId]
  );
  const { groupId, updateSaver } = useCustomFormConfigContext();
  // Save the table to the backend
  const saveTable = React.useCallback(async () => {
    // If there's nothing to save, don't
    if (tableRows.length === 0) {
      return true;
    }
    const rowInputs = tableRows.map((row) => ({
      dataFieldTableRowId: row.id,
      responses: row.cells
        .filter((cell) => cell.response && cell.response.data)
        .map((cell) => ({
          id: cell.response.id,
          dataFieldId: cell.response.dataFieldId,
          data: cell.response.data,
        })),
    }));
    const input = {
      dataFieldTableId: dataFieldTableId,
      rowSetId,
      groupId,
      rows: rowInputs,
    };
    const result = await invokeCreateOrUpdateDataFieldTableRows({
      variables: { input },
    });
    if (
      result.data?.createOrUpdateDataFieldTableRows.status !==
      SubmitDataFieldTableRowStatus.Success
    ) {
      setTableErrors(
        result.data?.createOrUpdateDataFieldTableRows.results.map(
          (result) => result.fieldErrors ?? []
        )
      );
      return false;
    }
    setTableErrors([]);
    return true;
  }, [tableRows, invokeCreateOrUpdateDataFieldTableRows]);
  useEffect(() => {
    updateSaver(saveTable);
  }, [saveTable]);

  // If this is an existing table, we should load the rows
  React.useEffect(() => {
    if (rowSetId && !isNewTable.current && !tableRowData) {
      getResponse({
        variables: {
          dataFieldTableId: dataFieldTableId,
          rowSetId,
        },
      });
    }
  }, [rowSetId, isNewTable, tableRowData]);

  // Populate our table cell data from our table + row data from the backend
  React.useEffect(() => {
    if (tableData && tableRowData) {
      const sortedFields = [...tableData.dataFieldTable.dataFields].sort(
        (a, b) => a.order - b.order
      );
      const newRowData = tableRowData.dataFieldTableRowsByRowSetId.map(
        (row) => ({
          id: row.id,
          key: row.id ?? uuid(),
          cells: sortedFields.map((fieldLink) => ({
            field: fieldLink.dataField,
            response: row.dataFieldResponses.find(
              (response) => response.dataFieldId === fieldLink.dataField.id
            ),
            disabled,
            error: null,
          })),
        })
      );
      setTableRows(newRowData);
    }
  }, [tableData, tableRowData]);
  if (loading || tableRowLoading) {
    return (
      <Delay delay={500}>
        <div className="space-y-4">
          <LargePulseCard />
          <LargePulseCard />
        </div>
      </Delay>
    );
  }
  if (error || tableRowError) {
    return (
      <InlineAlert
        title={t("couldNotLoadTable")}
        intent={Intent.FAILURE}
        actions={
          <Button size={Sizes.SM} onClick={() => refetch()}>
            {t("reload")}
          </Button>
        }
      />
    );
  }
  return (
    <>
      <table className="w-full text-sm text-left mb-4 mt-4 border border-canvas-500 rounded">
        <thead className="text-xs text-canvas-200 uppercase">
          <tr className="border-canvas-500 bg-canvas-500">
            {tableData.dataFieldTable.dataFields.map((dataFieldLink) => (
              <th
                key={dataFieldLink.dataField.id}
                className="px-6 py-2 bg-canvas-500 border-none"
              >
                {dataFieldLink.dataField.label} {dataFieldLink.required && "*"}
              </th>
            ))}
            {!disabled && (
              <th className="px-6 py-2 bg-canvas-500 border-none"></th>
            )}
          </tr>
        </thead>
        <tbody>
          {tableRows.length === 0 && (
            <tr>
              <td
                className="px-6 py-4 border-b border-canvas-500"
                colSpan={tableData.dataFieldTable.dataFields.length + 1}
              >
                {disabled ? t("noTableRows") : t("noTableRowsAdd")}
              </td>
            </tr>
          )}
          {tableRows.map((tableRow, rowIndex) => (
            <DataFieldTableRow
              key={tableRow.key}
              disabled={disabled}
              rowIndex={rowIndex}
              tableRow={tableRow}
              deleteRow={deleteRow}
              updateRow={updateRow}
              rowErrors={tableErrors[rowIndex] ?? []}
            />
          ))}
        </tbody>
      </table>
      {!disabled && (
        <div className="text-center">
          <Button onClick={addRow}>
            <Icon icon="add" size={Sizes.SM} />
            <span className="ml-1">{t("addRow")}</span>
          </Button>
        </div>
      )}
    </>
  );
};

export default DataFieldTable;

import { Icon, Sizes, Button, Avatar } from "@get-frank-eng/design-system";
import { MaterialIcons } from "@get-frank-eng/design-system/dist/components/Icon";
import { DocumentType } from "frank-types/dist/frank-backend-types";

const DocumentItem = ({
  icon,
  title,
  subheading,
  label,
  thumbnailUrl,
  onView,
}: {
  icon: MaterialIcons;
  title: string;
  subheading: JSX.Element;
  label: string;
  thumbnailUrl?: string;
  onView: () => void;
}) => {
  return (
    <li>
      <a
        className="w-full text-left align-top rounded border flex px-2 my-2 border-canvas-500 h-full"
        href="#"
        onClick={(evt) => {
          evt.preventDefault();
          onView();
        }}
      >
        <div className="my-auto flex items-center w-full">
          <div className="my-auto capitalize px-1 w-20 h-14 items-center justify-center flex">
            {thumbnailUrl ? (
              <Avatar size={Sizes.XL} src={thumbnailUrl} />
            ) : (
              <Icon size={Sizes.XL} icon={icon} />
            )}
          </div>
          <div className="w-full">
            <div>{title}</div>
            <div className="grid grid-cols-2 w-full">
              <div className="t-mini col-span-2 sm:col-span-1 text-canvas-400">
                {label}
              </div>
              <div className="t-mini col-span-2 sm:col-span-1 sm:text-right text-canvas-400 mr-2">
                {subheading}
              </div>
            </div>
          </div>
        </div>
      </a>
    </li>
  );
};

export default DocumentItem;

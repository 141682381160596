import * as React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router";
import AuthN from "./AuthN";
import usePasswordStrength from "./usePasswordStrength";
import useSession from "./useSession";
import {
  InlineAlert,
  Button,
  FormGroup,
  Intent,
  PasswordStrengthMeter,
  Password,
  Avatar,
  Sizes,
} from "@get-frank-eng/design-system";
import { useModals } from "../Modals";
import Frank from "../components/icons/Frank";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/config";
import { useFinishOrganizerOnboardingMutation } from "../generated-hooks";

interface FormData {
  password: string;
  confirmPassword: string;
}

const PasswordReset = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [serverErr, setServerErr] = React.useState<
    Error | null | { field: string; message: string }[]
  >(null);
  const history = useHistory();
  const goToHome = React.useCallback(() => {
    history.push("/");
  }, [history]);
  const { login } = useSession({
    afterLogin: goToHome,
  });
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  // if these exist for them, it means they're an organizer onboarding to Frank for the 1st time
  const partnerLogoUrl = searchParams.get("partnerLogoUrl");
  const partnerName = searchParams.get("partnerName");
  const isOrganizerOnboarding = !!partnerLogoUrl;

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState,
  } = useForm<FormData>();

  const password = watch("password");
  const passwordStrength = usePasswordStrength(password);

  const { setModal } = useModals();

  const showPasswordError =
    formState.dirtyFields.has("password") &&
    passwordStrength > -1 &&
    passwordStrength < 3;

  const errorsPresent = !!Object.keys(errors).length;

  const [
    invokeFinishOrganizerOnboarding,
  ] = useFinishOrganizerOnboardingMutation();

  const submitHandler = React.useCallback(
    async (data: FormData) => {
      setLoading(true);
      try {
        await AuthN.submitNewPasswordAfterReset(token, data.password);
        await login(email, data.password);
        if (isOrganizerOnboarding) {
          // call into backend to register that we finished onboarding the organizer
          await invokeFinishOrganizerOnboarding();
        }
        setLoading(false);
      } catch (e) {
        setServerErr(e);
        setLoading(false);
        // throw new Error(JSON.stringify(e));
      }
    },
    [token, login, setLoading, setServerErr, email]
  );

  const hasInvalidTokenError =
    serverErr &&
    Array.isArray(serverErr) &&
    serverErr.some(
      (err) => err.field === "token" && err.message === "INVALID_OR_EXPIRED"
    );

  return (
    <div className="relative w-full flex flex-col justify-center items-center h-screen overflow-hidden transform translate-y-0 px-6">
      <a
        href="https://getfrank.com"
        className="absolute top-0 left-0 mt-4 ml-6"
      >
        <Frank />
      </a>
      <form
        onSubmit={handleSubmit(submitHandler)}
        className="self-center md:shadow-lg rounded mx-auto p-6 max-w-md sm:max-w-xl md:p-8 md:w-2/3 lg:w-1/3 bg-canvas-700"
      >
        {isOrganizerOnboarding && (
          <div className="flex flex-col items-center justify-center pb-4 space-y-4">
            <Avatar src={partnerLogoUrl} size={Sizes.XL} />
            <div className="t-title-4 plus">{t("organizeWithFrank")}</div>
            <div className="t-small">
              {t("youveBeenInvitedToJoin", { partnerName })}
            </div>
          </div>
        )}
        {serverErr && (
          <>
            {hasInvalidTokenError ? (
              <div className="pb-4">
                <InlineAlert
                  actions={
                    <Button
                      onClick={() =>
                        setModal({ type: "forgotPasswordModal", props: {} })
                      }
                    >
                      {t("resetAgain")}
                    </Button>
                  }
                  intent={Intent.FAILURE}
                  title={t("passwordResetLinkInvalid")}
                >
                  {t("yourPasswordResetIsNoLongerValid")}
                </InlineAlert>
              </div>
            ) : (
              <div className="pb-4">
                <InlineAlert
                  intent={Intent.FAILURE}
                  title={t("authenticationError")}
                >
                  {t("anErrorOccurredResettingThePassword")}
                </InlineAlert>
              </div>
            )}
          </>
        )}
        <FormGroup label={t("password")} name="password" id="password">
          <Password
            disabled={loading}
            registerArgs={{ required: true, minLength: 6, maxLength: 255 }}
            register={register}
          />
        </FormGroup>
        <PasswordStrengthMeter
          passwordStrength={passwordStrength}
          password={password}
          lng={i18n.language}
        />
        <div className="flex items-center justify-between pt-3">
          <Button
            dataCy="reset-password"
            loading={loading}
            disabled={
              showPasswordError ||
              password?.length < 6 ||
              !password ||
              errorsPresent
            }
          >
            {t("setNewPassword")}
          </Button>
        </div>
      </form>
      <div className="absolute bottom-0 w-full">
        <div className="flex flex-row justify-between pb-6 mx-6">
          <div className="py-0 text-white t-mini flex justify-center items-center">
            {t("getFrankPbc")}
          </div>
          <div className="flex flex-row justify-center space-x-4">
            <a
              rel="noreferrer"
              href="https://twitter.com/GetFrank_com"
              target="_blank"
              className="text-canvas-400 t-mini hover:text-white"
            >
              {t("twitter")}
            </a>
            <a
              rel="noreferrer"
              href="https://medium.com/@getfrank"
              target="_blank"
              className="text-canvas-400 t-mini hover:text-white"
            >
              {t("blog")}
            </a>
            <a
              rel="noreferrer"
              href="https://app.getfrank.com/legal/privacy"
              target="_blank"
              className="text-canvas-400 t-mini hover:text-white"
            >
              {t("privacy")}
            </a>
            <a
              rel="noreferrer"
              href="https://app.getfrank.com/contact"
              target="_blank"
              className="text-canvas-400 t-mini hover:text-white"
            >
              {t("contact")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;

import {
  FormGroup,
  Password,
  PasswordStrengthMeter,
  Button,
  useToaster,
  Intent,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AuthStates, useAuthState } from "../../Auth/AuthState";
import usePasswordStrength from "../../Auth/usePasswordStrength";
import useSession from "../../Auth/useSession";
import { useCreateAccountFromAuthCardMutation } from "../../generated-hooks";
import { useModals } from "../../Modals";
import sentryClient from "../../sentryClient";
import { findLanguage } from "../../utils/findLanguage";

export const ActionSwitch = ({
  hasAccount,
  onboardingWorkflowState,
  email,
  name,
  onboardingId,
  token,
  partnerName,
}: {
  hasAccount: boolean;
  onboardingWorkflowState: FrankBackendTypes.OnboardingWorkflowState;
  email: string;
  name: string;
  onboardingId: string;
  token: string;
  partnerName: string;
}) => {
  const [password, setPassword] = React.useState<string>();
  const passwordStrength = usePasswordStrength(password);
  const { login } = useSession({});
  const history = useHistory();
  const toaster = useToaster();
  const { t } = useTranslation();
  const { authState } = useAuthState();
  const { setModal } = useModals();

  const openForgotPasswordModal = React.useCallback(() => {
    setModal({
      type: "forgotPasswordModal",
      props: {
        defaultEmail: email,
      },
    });
  }, [email, setModal]);

  const [
    invokeCreateAccountFromAuthCard,
    { error: createAccountError },
  ] = useCreateAccountFromAuthCardMutation();

  const submitCreateAccount = React.useCallback(async () => {
    try {
      await invokeCreateAccountFromAuthCard({
        variables: {
          input: {
            password,
            email,
            language: findLanguage(navigator.language),
            name,
            onboardingId,
          },
        },
      });
      await login(email, password);
      history.push(`/onboarding?token=${token}`);
    } catch (e) {
      toaster.addToast({
        children: t("somethingWentWrong"),
        intent: Intent.FAILURE,
      });
      sentryClient.captureException(createAccountError);
    }
  }, [
    createAccountError,
    invokeCreateAccountFromAuthCard,
    password,
    email,
    name,
    onboardingId,
    login,
    history,
    token,
    toaster,
    t,
  ]);

  if (
    onboardingWorkflowState ===
    FrankBackendTypes.OnboardingWorkflowState.Finished
  ) {
    // if they've finished onboarding, they should log in. If they're already logged in, the log in page will redirect them
    return (
      <Button buttonStyle="secondary" onClick={() => history.push("/login")}>
        {t("goToLogIn")}
      </Button>
    );
  }

  if (!hasAccount) {
    // We know they have not finished onboarding and do not have an account, so should create account and then go to onboarding

    return (
      <div>
        <div className="text-canvas-400 t-small">
          {t("youCanAlsoSetAPassword", { partnerName })}
        </div>
        <FormGroup name="password" id="password" label={t("createAPassword")}>
          <Password onChange={(e) => setPassword(e.currentTarget.value)} />
        </FormGroup>
        {!!password?.length && (
          <PasswordStrengthMeter
            password={password}
            passwordStrength={passwordStrength}
            lng={findLanguage(navigator.language)}
          />
        )}
        <Button
          className="mt-4"
          disabled={!password?.length}
          buttonStyle="brand"
          onClick={submitCreateAccount}
        >
          {t("createAccount")}
        </Button>
        <div className="t-small text-canvas-400 pt-4">
          {t("bySigningUpForFrankYouAcknowledgeYouHave")}
          <a
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_FRONTEND_URL}/legal/tos`}
            className="underline"
          >
            {t("policies")}
          </a>
          .
        </div>
      </div>
    );
  }

  if (hasAccount && authState !== AuthStates.LOGGED_IN) {
    // We know they have not finished onboarding and have an account, but are not logged in, so should log in and then go to onboarding

    return (
      <div>
        <div className="t-small text-canvas-400">
          {t("logInToContinueCreatingAccount")}
        </div>
        <FormGroup name="password" id="password" label={t("password")}>
          <Password onChange={(e) => setPassword(e.currentTarget.value)} />
        </FormGroup>
        <Button
          className="mt-4"
          disabled={!password?.length}
          buttonStyle="brand"
          onClick={async () => {
            await login(email, password);
            history.push(`/onboarding?token=${token}`);
          }}
        >
          {t("continueCreatingAccount")}
        </Button>
        <button
          className="text-brand-300 w-full t-small plus mt-4"
          onClick={openForgotPasswordModal}
          data-cy="open-forgot-password"
        >
          {t("forgotYourPassword")}
        </button>
      </div>
    );
  }

  // We know they have not finished onboarding and have an account, and are logged in, so they should finish onboarding
  // could be an issue if they're trying to onboard to a different group?

  return (
    <Button onClick={() => history.push(`/onboarding?token=${token}`)}>
      {t("continueCreatingAccount")}
    </Button>
  );
};

import {
  Button,
  FormGroup,
  Input,
  Intent,
  Switch,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import AddPhoto from "../../Onboarding/components/AddPhoto";
import useCustomResources from "../dataAccess/hooks/mutations/useCustomResources";
import useUpdatePartner from "../dataAccess/hooks/mutations/useUpdatePartner";
import { CustomResources } from "./GroupSettings/components/CustomResources";

type FormData = {
  defaultWelcomeMessage: string;
  name: string;
  authorizationLanguage: string;
};

export default function PartnerSettings({
  partner,
  refetchPartnerData,
}: {
  partner: FrankBackendTypes.Partner;
  refetchPartnerData: () => Promise<any>;
}) {
  const { preferredLanguage } = useCurrentUserData();
  const [allowAnonymousChannel, setAllowAnonymousChannel] = React.useState(
    partner.allowAnonymousChannel
  );
  const [allowFromFrankChannel, setAllowFromFrankChannel] = React.useState(
    partner.allowFromFrankChannel
  );
  const { t } = useTranslation();
  const { updatePartner, loading } = useUpdatePartner();
  const { register, handleSubmit } = useForm();
  const [isDirty, setIsDirty] = React.useState(false);
  const toaster = useToaster();
  const {
    uploadAttachment,
    createCustomResource,
    removeCustomResource,
    createCustomResourceLoading,
  } = useCustomResources();

  const submit = React.useCallback(
    async ({
      defaultWelcomeMessage,
      name,
      authorizationLanguage,
    }: FormData) => {
      try {
        await updatePartner({
          name,
          defaultWelcomeMessage,
          partnerId: partner.id,
          authorizationLanguage,
          allowAnonymousChannel,
          allowFromFrankChannel,
        });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("successfullyUpdatedPartnerDetails"),
        });
        setIsDirty(false);
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrong"),
        });
      }
    },
    [
      updatePartner,
      partner,
      toaster,
      t,
      allowAnonymousChannel,
      allowFromFrankChannel,
    ]
  );

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="pb-48 sm:pb-0 space-y-8 mb-4 max-w-md"
    >
      <FormGroup name="name" label={t("partnerName")} id="name">
        <Input
          register={register}
          id="name"
          maxLength={120}
          placeholder={t("yourOrganization") as string}
          defaultValue={partner.name || null}
          onChange={() => setIsDirty(true)}
        />
      </FormGroup>
      <section>
        <div className="t-mini plus -mb-2">{t("logo")}</div>
        <AddPhoto
          setGroupAttachmentId={async ({ attachmentId }) => {
            await updatePartner({
              partnerId: partner.id,
              logoAttachmentId: attachmentId,
            });
          }}
          defaultUrl={partner.logoAttachment?.url}
          onUpload={(err, url) => {
            toaster.addToast({
              intent: Intent.SUCCESS,
              children: t("successfullyUpdatedPartnerLogo"),
            });
            if (err) {
              toaster.addToast({
                intent: Intent.FAILURE,
                children: t("somethingWentWrongUpdatingYourPartn"),
              });
            }
          }}
        />
      </section>
      <FormGroup
        name="defaultWelcomeMessage"
        label={t("welcomeMessage")}
        id="defaultWelcomeMessage"
      >
        <Textarea
          register={register}
          id="defaultWelcomeMessage"
          maxLength={600}
          placeholder={t("defaultWelcomeMessage")}
          helpText={t("anyNewGroupsYouCreateWillHaveATh", {
            partnerName: partner.name,
          })}
          defaultValue={partner.defaultWelcomeMessage || null}
          onChange={() => setIsDirty(true)}
        />
      </FormGroup>
      <FormGroup
        name="authorizationLanguage"
        label={t("defaultAuthorizationLanguage")}
        id="authorizationLanguage"
      >
        <Textarea
          register={register}
          id="authorizationLanguage"
          maxLength={2000}
          helpText={t("thisIsTheDefaultAuthorizationLangua", {
            partnerName: partner.name,
          })}
          defaultValue={partner.authorizationLanguage}
          onChange={() => setIsDirty(true)}
        />
      </FormGroup>
      <section>
        <div className="flex justify-between">
          <div className="t-small">{t("allowAnonymousChannelPartner")}</div>
          <Switch
            onChange={() => {
              setAllowAnonymousChannel(!allowAnonymousChannel);
              setIsDirty(true);
            }}
            checked={allowAnonymousChannel}
            label="allowAnonymousChannel"
            id="allowAnonymousChannel"
          />
        </div>
        <div className="t-mini text-canvas-400 pt-1">
          {t("additionalAnonymousChannelToggleDescription")}
        </div>
      </section>
      <section>
        <div className="flex justify-between">
          <div className="t-small">
            {t("allowFromFrankChannelPartner", {
              partnerName: partner.slug,
            })}
          </div>
          <Switch
            onChange={() => {
              setAllowFromFrankChannel(!allowFromFrankChannel);
              setIsDirty(true);
            }}
            checked={allowFromFrankChannel}
            label="allowFromFrankChannel"
            id="allowFromFrankChannel"
          />
        </div>
        <div className="t-mini text-canvas-400 pt-1">
          {t("additionalFromFrankChannelToggleDescription")}
        </div>
      </section>
      <CustomResources
        lng={preferredLanguage}
        deleteAttachment={async (attachmentId: string) =>
          removeCustomResource({ attachmentId, refetch: refetchPartnerData })
        }
        resources={partner.customResources}
        uploadFile={async (e, title) => {
          const attachment = await uploadAttachment(e);
          await createCustomResource({
            attachmentId: attachment.attachmentId,
            refetch: refetchPartnerData,
            title,
          });
        }}
        level="partner"
        loading={createCustomResourceLoading}
        logoUrl={partner.logoAttachment.url}
      />
      <Button
        disabled={!isDirty}
        type="submit"
        buttonStyle="brand"
        loading={loading}
      >
        {t("save")}
      </Button>
      <div className="h-8" />
    </form>
  );
}

import React from "react";
import PrimaryActionEmbed from "./PrimaryActionEmbed";
import { Action, ActionType } from "frank-types/dist/frank-backend-types";
import AuthCardActionItem from "./actions/AuthCardActionItem";
import TaskActionItem from "./actions/TaskActionItem";
import FormActionItem from "./actions/FormActionItem";
import MembershipRequestsActionItem from "./actions/MembershipRequestActionItem";
import AddCoworkersActionItem from "./actions/AddCoworkersActionItem";
import StartPetitionActionItem from "./actions/StartPetitionActionItem";
import ChatActionItem from "./actions/ChatActionItem";
import InviteCoworkersActionItem from "./actions/InviteCoworkersActionItem";
import { useTranslation } from "react-i18next";

const ActionsList = ({
  actions,
  onUpdate,
}: {
  actions: Action[];
  onUpdate: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const primaryAction = React.useMemo(() => actions[0], [actions]);
  const otherActions = React.useMemo(() => actions.slice(1) ?? [], [actions]);
  const otherActionItems = React.useMemo(
    () =>
      otherActions.map((action) => {
        let element: JSX.Element;
        let key = "";
        switch (action.type) {
          case ActionType.AuthCard: {
            element = (
              <AuthCardActionItem
                authCard={action.authCard!}
                onUpdate={onUpdate}
              />
            );
            key = action.authCard.id;
            break;
          }
          case ActionType.Task: {
            element = (
              <TaskActionItem task={action.task!} onUpdate={onUpdate} />
            );
            key = action.task.id;
            break;
          }
          case ActionType.Form: {
            element = (
              <FormActionItem form={action.customForm!} onUpdate={onUpdate} />
            );
            key = action.customForm.id;
            break;
          }
          case ActionType.MembershipRequests: {
            element = (
              <MembershipRequestsActionItem
                membershipRequests={action.membershipRequests!}
                onUpdate={onUpdate}
              />
            );
            key = action.membershipRequests[0].id;
            break;
          }
          case ActionType.StartPetition: {
            element = <StartPetitionActionItem />;
            key = "startPetition";
            break;
          }
          case ActionType.Chat: {
            element = <ChatActionItem />;
            key = "chat";
            break;
          }
          case ActionType.InviteCoworkers: {
            element = <InviteCoworkersActionItem />;
            key = "inviteCoworkers";
            break;
          }
          case ActionType.AddCoworkers: {
            element = <AddCoworkersActionItem />;
            key = "addCoworkers";
            break;
          }
          default:
            throw new Error(
              t("unknownActionType", { actionType: action.type })
            );
        }
        return (
          <li key={key} className="col-span-12 sm:col-span-6">
            {element}
          </li>
        );
      }),
    [actions]
  );
  if (primaryAction === undefined) {
    return <div />;
  }
  return (
    <div className="pb-8">
      <h2 className="t-large plus mt-4 mb-3 md:hidden">{t("yourActions")}</h2>
      <div>
        <PrimaryActionEmbed action={primaryAction} onUpdate={onUpdate} />
      </div>
      {otherActionItems.length > 0 && (
        <ul className="grid grid-cols-12 gap-x-2 gap-y-3">
          {otherActionItems}
        </ul>
      )}
    </div>
  );
};

export default ActionsList;

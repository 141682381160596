import { gql, useQuery } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import attachmentFragment from "../../../../Attachment/dataAccess/attachmentFragment";

const QUERY = gql`
  query Group($groupId: ID!) {
    group(groupId: $groupId) {
      inviteMessage
      welcomeMessage
      id
      isCurrentGroup
      name
      company
      size
      partnerId
      authorizationLanguage
      allowFromFrankChannel
      allowAnonymousChannel
      customResources {
        id
        title
        attachment {
          ...AttachmentFragment
        }
      }
      partner {
        id
        name
        slug
        logoAttachment {
          id
          url
        }
        authorizationCardFormJSON
      }
      leadOrganizerId
      leadOrganizer {
        user {
          id
          name
        }
      }
      logo {
        url
      }
      onboardingTasks {
        id
        note
        task {
          id
          title
          description
        }
      }
      organizers {
        id
        user {
          id
          name
          profilePic {
            id
            url
          }
        }
      }
    }
  }
  ${attachmentFragment}
`;

export default function useGroup({ groupId }: { groupId: string }) {
  const { data, loading, error, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "group">
  >(QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      groupId,
    },
  });

  return {
    loading,
    error,
    refetch,
    group: data?.group,
  };
}

import {
  PillBadge,
  Sizes,
  IconButton,
  FormGroup,
  Select,
  Button,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const ChooseNewLead = ({
  groupOrganizers,
  organizersToRemove,
  group,
  refetchGroupData,
  updateGroupSettings,
  closeModal,
}: {
  groupOrganizers: FrankBackendTypes.Organizer[];
  organizersToRemove: { userId: string; name: string }[];
  group: FrankBackendTypes.Group;
  refetchGroupData: () => void;
  updateGroupSettings: (args: any) => void;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const [newLead, setNewLead] = React.useState<string>(null);

  return (
    <section>
      <div className="t-small">
        {t("pleaseChooseANewLeadOrganizerFor", { groupName: group.name })}
      </div>
      <FormGroup
        name="leadOrganizerId"
        label={t("leadOrganizer")}
        id="leadOrganizerId"
      >
        <Select
          options={groupOrganizers
            .filter((o) => {
              if (organizersToRemove.map((o) => o.userId).includes(o.user.id)) {
                return false;
              }
              return true;
            })
            .map((o) => ({
              value: o.user.id,
              label: o.user.name,
            }))}
          id="leadOrganizerId"
          placeholder={t("chooseANewLeadOrganizer")}
          onChange={(e) => setNewLead(e.currentTarget.value)}
          helpText={t("ifYouDontSeeTheOrganizerYouWant") as string}
        />
      </FormGroup>
      <div>
        <Button
          buttonStyle="brand"
          onClick={async () => {
            await updateGroupSettings({
              leadOrganizerId: newLead,
            });
            await refetchGroupData();
            closeModal();
          }}
        >
          {t("select")}
        </Button>
      </div>
    </section>
  );
};

export const OrganizerPill = ({
  organizer,
  setOrganizersToRemove,
  organizersToRemove,
}: {
  organizer: FrankBackendTypes.Organizer;
  setOrganizersToRemove: (
    organizers: { userId: string; name: string }[]
  ) => void;
  organizersToRemove: { userId: string; name: string }[];
}) => {
  const { t } = useTranslation();
  const remove = React.useMemo(() => {
    if (organizersToRemove.map((o) => o.userId).includes(organizer.user.id)) {
      return true;
    }
    return false;
  }, [organizersToRemove, organizer]);

  return (
    <PillBadge
      size={Sizes.MD}
      backgroundColor={remove ? "frank-red-500" : "canvas-600"}
      textColor="canvas-100"
    >
      <div className="flex items-center justify-between">
        <div>{organizer.user.name}</div>
        <div className="flex-grow" />
        <div>
          <IconButton
            buttonStyle="outline"
            icon="close"
            type="button"
            title={t("removeOrganizerFromThisGroup")}
            onClick={async () => {
              if (remove) {
                setOrganizersToRemove(
                  organizersToRemove.filter(
                    (o) => o.userId !== organizer.user.id
                  )
                );
              } else {
                setOrganizersToRemove([
                  ...organizersToRemove,
                  { userId: organizer.user.id, name: organizer.user.name },
                ]);
              }
            }}
          />
        </div>
      </div>
    </PillBadge>
  );
};

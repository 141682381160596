import React from "react";
import ActionListItem from "./ActionListItem";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChatActionItem = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const sayHello = () => push("/topics/general");
  // TODO: don't hardcode to #general
  const actions = [
    {
      label: t("sayHi"),
      action: sayHello,
    },
  ];
  return (
    <ActionListItem
      title={t("chatWithYourCoworkers")}
      subheading={t("sayHelloInChannelName", { channelName: "#general" })}
      actions={actions}
      defaultAction={sayHello}
    />
  );
};

export default ChatActionItem;

import * as React from "react";
import PendingMemberSection from "../components/PendingMemberSection";
import TaskRow from "../components/TaskRow";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

export default function Eligibility({
  pendingMember,
}: {
  pendingMember: FrankBackendTypes.Coworker;
}) {
  const { t } = useTranslation();
  const { currentGroup } = useCurrentUserData();

  return (
    <PendingMemberSection
      title={t("eligibility")}
      tooltip={JSON.stringify(
        pendingMember.onboardingIssues.eligibilityIssues.map((i) => i.type),
        null,
        2
      )}
      complete={pendingMember.onboardingIssues.eligibilityIssues.length === 0}
    >
      <TaskRow
        dataCy="pending-coworker-eligibility"
        complete={pendingMember.onboardingIssues.eligibilityIssues.length === 0}
        title={t("pendingMemberNameMustBeACurrent", {
          pendingMemberName: pendingMember.name,
          groupName: currentGroup.name,
          interpolation: { escapeValue: false },
        })}
        description={`${t("pendingMemberNameSelfCertifiedThe", {
          pendingMemberName: pendingMember.name,
          groupName: currentGroup.name,
          interpolation: { escapeValue: false },
        })}${
          pendingMember.onboardingIssues.eligibilityIssues.length === 0
            ? t("thisWasVerifiedByAtLeastOneOther")
            : t("thisHasNotBeenVerifiedByAnyoneEls")
        }`}
      />
    </PendingMemberSection>
  );
}

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
type Maybe<T> = T | null;
type InputMaybe<T> = Maybe<T>;
type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
};

type Action = {
  __typename?: 'Action';
  authCard?: Maybe<AuthCardAction>;
  customForm?: Maybe<FormAction>;
  membershipRequests?: Maybe<Array<MembershipRequest>>;
  task?: Maybe<TaskAction>;
  type: ActionType;
};

type Activity = {
  __typename?: 'Activity';
  actor: DbRef;
  campaign?: Maybe<Campaign>;
  count?: Maybe<Scalars['Int']>;
  foreignId: DbRef;
  htmlDescription?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  invite?: Maybe<Invite>;
  link?: Maybe<Scalars['String']>;
  object: DbRef;
  objectUser?: Maybe<User>;
  quoteText?: Maybe<Scalars['String']>;
  targetUser?: Maybe<User>;
  time: Scalars['DateTime'];
  timeAgo: Scalars['String'];
  user?: Maybe<User>;
  verb: ActivityVerb;
};

enum ActivityVerb {
  AcceptNoMgmtInvite = 'ACCEPT_NO_MGMT_INVITE',
  ActionDownvote = 'ACTION_DOWNVOTE',
  ActionUpvote = 'ACTION_UPVOTE',
  AddedRecipientForPetition = 'ADDED_RECIPIENT_FOR_PETITION',
  AddCollaborator = 'ADD_COLLABORATOR',
  AssessCoworker = 'ASSESS_COWORKER',
  AssignTask = 'ASSIGN_TASK',
  BulkInvite = 'BULK_INVITE',
  CampaignArchive = 'CAMPAIGN_ARCHIVE',
  CampaignComment = 'CAMPAIGN_COMMENT',
  CampaignCommentReply = 'CAMPAIGN_COMMENT_REPLY',
  CampaignPageview = 'CAMPAIGN_PAGEVIEW',
  CampaignPublish = 'CAMPAIGN_PUBLISH',
  CancelAssignment = 'CANCEL_ASSIGNMENT',
  CompleteAssignment = 'COMPLETE_ASSIGNMENT',
  CoworkerArchive = 'COWORKER_ARCHIVE',
  CoworkerFlag = 'COWORKER_FLAG',
  CoworkerSetCustomColumn = 'COWORKER_SET_CUSTOM_COLUMN',
  CoworkerSetEmail = 'COWORKER_SET_EMAIL',
  CoworkerSetEmploymentType = 'COWORKER_SET_EMPLOYMENT_TYPE',
  CoworkerSetName = 'COWORKER_SET_NAME',
  CoworkerSetNotes = 'COWORKER_SET_NOTES',
  CoworkerSetTitle = 'COWORKER_SET_TITLE',
  CoworkerUnarchive = 'COWORKER_UNARCHIVE',
  CreateGroup = 'CREATE_GROUP',
  CreateInviteLink = 'CREATE_INVITE_LINK',
  DisableInvite = 'DISABLE_INVITE',
  EnableInvite = 'ENABLE_INVITE',
  EnteredWaitingRoom = 'ENTERED_WAITING_ROOM',
  JoinGroup = 'JOIN_GROUP',
  Mention = 'MENTION',
  NewAuthorizationCard = 'NEW_AUTHORIZATION_CARD',
  PollExpired = 'POLL_EXPIRED',
  PollExpiring = 'POLL_EXPIRING',
  PollPublish = 'POLL_PUBLISH',
  ProfilePageview = 'PROFILE_PAGEVIEW',
  Publish = 'Publish',
  ReceiveAssignment = 'RECEIVE_ASSIGNMENT',
  RemovedRecipientForPetition = 'REMOVED_RECIPIENT_FOR_PETITION',
  ReplyToPost = 'REPLY_TO_POST',
  SignAuthorizationCard = 'SIGN_AUTHORIZATION_CARD',
  SignCampaign = 'SIGN_CAMPAIGN',
  TopicArchive = 'TOPIC_ARCHIVE',
  TopicPublish = 'TOPIC_PUBLISH',
  UnsignCampaign = 'UNSIGN_CAMPAIGN',
  UpdatedExpirationOnInvite = 'UPDATED_EXPIRATION_ON_INVITE',
  UpdatedMaxSeatsOnInvite = 'UPDATED_MAX_SEATS_ON_INVITE',
  UpdatedPassphraseOnInvite = 'UPDATED_PASSPHRASE_ON_INVITE',
  UpdatedRecipientForPetition = 'UPDATED_RECIPIENT_FOR_PETITION',
  UpdateCampaign = 'UPDATE_CAMPAIGN',
  UpdateGroup = 'UPDATE_GROUP',
  Update = 'Update'
}

type AddCollaboratorsDto = {
  campaignId: Scalars['ID'];
  coworkerIds: Array<Scalars['ID']>;
  groupId: Scalars['ID'];
  messageForNewMembers: Scalars['String'];
};

type AddCoworkerColumnDto = {
  name: Scalars['String'];
};

type AddPetitionRecipientDto = {
  campaignId: Scalars['ID'];
  email: Scalars['String'];
  fullName: Scalars['String'];
};

type AddToChannelInput = {
  channelId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

type AreUsersInChannel = {
  channelId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

type AssessCoworkerInput = {
  assessmentOptionId: Scalars['ID'];
  coworkerId: Scalars['ID'];
  groupId: Scalars['ID'];
};

type AssessmentOption = {
  __typename?: 'AssessmentOption';
  description: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  partnerId: Scalars['ID'];
  value: Scalars['Int'];
};

type AssignTaskInput = {
  assignToGroup: Scalars['Boolean'];
  coworkerIds: Array<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<TaskEvent>;
  groupId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  taskId: Scalars['ID'];
};

enum AssignmentWorkflowState {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

type Attachment = {
  __typename?: 'Attachment';
  contentType: Scalars['String'];
  currentUserCanRemove: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fileType: Scalars['String'];
  filename: Scalars['String'];
  humanReadableSize?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  preview: Preview;
  size: Scalars['Float'];
  thumbnail?: Maybe<Attachment>;
  url: Scalars['String'];
  user?: Maybe<User>;
};

type AuthCardAction = {
  __typename?: 'AuthCardAction';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  language: Scalars['String'];
};

type AuthCardSignatureDocument = {
  __typename?: 'AuthCardSignatureDocument';
  authCardId: Scalars['ID'];
  id: Scalars['ID'];
  submittedAt: Scalars['DateTime'];
};

type AuthorizationCard = {
  __typename?: 'AuthorizationCard';
  additionalFields?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  groupId: Scalars['String'];
  id: Scalars['ID'];
  language: Scalars['String'];
  mySignature?: Maybe<AuthorizationCardSignature>;
  publishedByUser: User;
  publishedByUserId: Scalars['String'];
  signatures: PaginatedAuthorizationCardSignature;
  unsignedMembers: PaginatedCoworker;
};


type AuthorizationCardSignaturesArgs = {
  pagination: PaginationArgumentDto;
};


type AuthorizationCardUnsignedMembersArgs = {
  pagination: PaginationArgumentDto;
};

type AuthorizationCardSignature = {
  __typename?: 'AuthorizationCardSignature';
  authCardFormResponseJSON?: Maybe<Scalars['String']>;
  authorizationCard: AuthorizationCard;
  authorizationCardId: Scalars['ID'];
  authorizationLanguage: Scalars['String'];
  confirmedByUser?: Maybe<Scalars['DateTime']>;
  coworkerId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  employer: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  signature: Scalars['String'];
};

type AuthorizationCardSignatureInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

type BulkCreateTopicInput = {
  topics: Array<CreateTopicInput>;
};

type BulkInviteByCoworkerDto = {
  coworkerIds: Array<Scalars['String']>;
  expiration?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
  netTrustNeeded: Scalars['Int'];
  passphrase?: InputMaybe<Scalars['String']>;
};

type BulkInviteByEmailDto = {
  emails: Array<Scalars['String']>;
  expiration?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['String']>;
  netTrustNeeded: Scalars['Int'];
  passphrase?: InputMaybe<Scalars['String']>;
};

type Campaign = {
  __typename?: 'Campaign';
  actions: Array<CustomActionDto>;
  activity: PaginatedActivity;
  attachments: PaginatedAttachment;
  campaignOrganizers: Array<User>;
  categories: Array<Scalars['String']>;
  channelId: Scalars['ID'];
  cleanCampaignTitle?: Maybe<Scalars['String']>;
  collaboratorInvite?: Maybe<Collaborator>;
  collaboratorWorkflowState?: Maybe<CollaboratorWorkflowState>;
  collaborators: Array<Collaborator>;
  comments: Array<DocumentComment>;
  contentJSON: Scalars['String'];
  contentPreview: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Group;
  id: Scalars['ID'];
  links: Array<Link>;
  petitionRecipients: Array<PetitionRecipient>;
  polls: PaginatedPoll;
  publishedAt: Scalars['DateTime'];
  publishingValidationIssueCount: Scalars['Float'];
  publishingValidationIssues: Array<PublishingValidationProblem>;
  relationshipToMe: CampaignUserRelationship;
  signatureCount: Scalars['Int'];
  signatureGoal?: Maybe<Scalars['Int']>;
  signatures: PaginatedSignature;
  signaturesVisible: Scalars['Boolean'];
  signers: PaginatedUser;
  templateId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedAtTimeAgo: Scalars['String'];
  workflowState: CampaignWorkflowState;
};


type CampaignActivityArgs = {
  pagination: PaginationArgumentDto;
};


type CampaignAttachmentsArgs = {
  pagination: PaginationArgumentDto;
};


type CampaignPollsArgs = {
  pagination: PaginationArgumentDto;
};


type CampaignSignaturesArgs = {
  pagination: PaginationArgumentDto;
};


type CampaignSignersArgs = {
  pagination: PaginationArgumentDto;
};

enum CampaignUserRelationship {
  InvitedNotSupporter = 'INVITED_NOT_SUPPORTER',
  InvitedSupporter = 'INVITED_SUPPORTER',
  None = 'NONE',
  Organizer = 'ORGANIZER',
  Supporter = 'SUPPORTER'
}

enum CampaignWorkflowState {
  Archived = 'ARCHIVED',
  Delivered = 'DELIVERED',
  Draft = 'DRAFT',
  GoalReached = 'GOAL_REACHED',
  Published = 'PUBLISHED'
}

type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
};

type ChannelOptions = {
  __typename?: 'ChannelOptions';
  allowAnonymousChannel: Scalars['Boolean'];
  allowFromFrankChannel: Scalars['Boolean'];
};

enum ChannelType {
  Anonymous = 'ANONYMOUS',
  FromFrank = 'FROM_FRANK',
  Membership = 'MEMBERSHIP',
  PrivateRoom = 'PRIVATE_ROOM',
  PublicRoom = 'PUBLIC_ROOM'
}

type ChatChannel = {
  __typename?: 'ChatChannel';
  campaignId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  topicId?: Maybe<Scalars['ID']>;
  topicSlug?: Maybe<Scalars['String']>;
};

enum CloseableSectionNames {
  Campaigns = 'CAMPAIGNS',
  Topics = 'TOPICS'
}

type Collaborator = {
  __typename?: 'Collaborator';
  campaign: Campaign;
  coworker: Coworker;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inviter: User;
  onboardingState: CollaboratorOnboardingState;
  workflowState: CollaboratorWorkflowState;
};

enum CollaboratorOnboardingState {
  NotOnboarded = 'NOT_ONBOARDED',
  Onboarded = 'ONBOARDED'
}

enum CollaboratorWorkflowState {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

enum Combinators {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  LessThan = 'LESS_THAN',
  NotEquals = 'NOT_EQUALS',
  NotOneOf = 'NOT_ONE_OF',
  OneOf = 'ONE_OF'
}

type CompanySearchResult = {
  __typename?: 'CompanySearchResult';
  domain?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  industry?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rank: Scalars['Float'];
  searchFeedback: Scalars['String'];
};

type ConfigureGroupInput = {
  company?: InputMaybe<Scalars['String']>;
  logoAttachmentId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

type ConfigureUserInput = {
  certifications: Array<OnboardingCertification>;
  name: Scalars['String'];
};

type ConvertAccountInput = {
  email: Scalars['String'];
  partnerId: Scalars['ID'];
};

type Coworker = {
  __typename?: 'Coworker';
  activity: PaginatedActivity;
  archived: Scalars['Boolean'];
  assessment?: Maybe<CoworkerAssessment>;
  canArchive: Scalars['Boolean'];
  canFlag: Scalars['Boolean'];
  canInvite: Scalars['Boolean'];
  canInviteToCampaign: Scalars['Boolean'];
  canUnArchive: Scalars['Boolean'];
  canVoteOnTrust: Scalars['Boolean'];
  coworkerInvite: CoworkerInvite;
  customData: Array<CoworkerCustomValue>;
  distrustedBy: Array<User>;
  editable: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  employmentType?: Maybe<CoworkerEmploymentType>;
  id?: Maybe<Scalars['ID']>;
  isEmailValid: Scalars['Boolean'];
  isEmpty: Scalars['Boolean'];
  joinedAt?: Maybe<Scalars['DateTime']>;
  joinedAtFormatted?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  netTrust: Scalars['Int'];
  noteCount: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
  notifications: CoworkerNotifications;
  onboardingIssues: OnboardingIssueList;
  onboardingStartedAt: Scalars['DateTime'];
  onboardingStartedTimeAgo: Scalars['String'];
  shouldWarnBeforeInvite: Scalars['Boolean'];
  status: CoworkerStatus;
  title?: Maybe<Scalars['String']>;
  totalTrustDownvotes: Scalars['Int'];
  totalTrustUpvotes: Scalars['Int'];
  trustJudgementCount: Scalars['Int'];
  trustedBy: Array<User>;
  user?: Maybe<User>;
  yourTrustDate?: Maybe<Scalars['DateTime']>;
  yourTrustLevel?: Maybe<Scalars['Int']>;
};


type CoworkerActivityArgs = {
  pagination: PaginationArgumentDto;
};

type CoworkerAssessment = {
  __typename?: 'CoworkerAssessment';
  assessmentOption: AssessmentOption;
  assessmentOptionId: Scalars['ID'];
  coworker?: Maybe<RawCoworker>;
  coworkerId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  ratedBy?: Maybe<User>;
  ratedByUserId: Scalars['ID'];
  value?: Maybe<Scalars['Float']>;
};

type CoworkerChangeDto = {
  custom?: InputMaybe<CoworkerCustomValueDto>;
  email?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<CoworkerEmploymentType>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

type CoworkerChangeResponseDto = {
  __typename?: 'CoworkerChangeResponseDTO';
  coworker?: Maybe<Coworker>;
  error?: Maybe<CoworkerError>;
  id: Scalars['ID'];
  isNew: Scalars['Boolean'];
};

type CoworkerCustomColumn = {
  __typename?: 'CoworkerCustomColumn';
  id: Scalars['ID'];
  name: Scalars['String'];
};

type CoworkerCustomFormResponseList = {
  __typename?: 'CoworkerCustomFormResponseList';
  coworker: Coworker;
  responses: Array<CustomFormResponse>;
};

type CoworkerCustomValue = {
  __typename?: 'CoworkerCustomValue';
  column: CoworkerCustomColumn;
  columnId: Scalars['ID'];
  coworkerId: Scalars['ID'];
  id: Scalars['ID'];
  key: Scalars['String'];
  value: Scalars['String'];
};

type CoworkerCustomValueDto = {
  columnId: Scalars['ID'];
  value: Scalars['String'];
};

enum CoworkerEmploymentType {
  DoesNotWorkHere = 'DOES_NOT_WORK_HERE',
  Management = 'MANAGEMENT',
  Worker = 'WORKER'
}

type CoworkerError = {
  __typename?: 'CoworkerError';
  column: Scalars['String'];
  coworkerId: Scalars['ID'];
  message: Scalars['String'];
  shortMessage: Scalars['String'];
  type: CoworkerErrorType;
};

enum CoworkerErrorType {
  EmailDuplicateError = 'EMAIL_DUPLICATE_ERROR'
}

type CoworkerFilterDto = {
  restrictions: Array<CoworkerRestriction>;
};

enum CoworkerFilterableColummns {
  AverageSupport = 'AVERAGE_SUPPORT',
  Email = 'EMAIL',
  EmploymentType = 'EMPLOYMENT_TYPE',
  Id = 'ID',
  JoinedAt = 'JOINED_AT',
  Name = 'NAME',
  Notes = 'NOTES',
  Status = 'STATUS',
  Support = 'SUPPORT',
  Title = 'TITLE',
  UserId = 'USER_ID',
  YourTrustDate = 'YOUR_TRUST_DATE',
  YourTrustLevel = 'YOUR_TRUST_LEVEL'
}

type CoworkerInvite = {
  __typename?: 'CoworkerInvite';
  id: Scalars['ID'];
  inviteType: Scalars['String'];
  invitedAt: Scalars['String'];
  inviter?: Maybe<User>;
  requiresPassphrase: Scalars['Boolean'];
};

enum CoworkerNotifications {
  Mentioned = 'MENTIONED',
  Read = 'READ',
  Unread = 'UNREAD'
}

type CoworkerOrderArgumentDto = {
  column?: InputMaybe<CoworkerOrderColumns>;
  order?: InputMaybe<Order>;
};

type CoworkerSummaryDto = {
  __typename?: 'CoworkerSummaryDTO';
  archived: Scalars['Int'];
  flagged: Scalars['Int'];
  inReview: Scalars['Int'];
  invited: Scalars['Int'];
  lead: Scalars['Int'];
  member: Scalars['Int'];
  onboarding: Scalars['Int'];
  organizer: Scalars['Int'];
  rejected: Scalars['Int'];
};

type CoworkerTrustVoteDto = {
  coworkerId: Scalars['ID'];
  rejectionDetail?: InputMaybe<Scalars['String']>;
  rejectionReason?: InputMaybe<CoworkerRejectionReason>;
  trust: Scalars['Float'];
};

type CreateAccountDto = {
  email?: InputMaybe<Scalars['String']>;
  onboardingId: Scalars['ID'];
  password: Scalars['String'];
  token: Scalars['String'];
};

type CreateAccountFromAuthCardInput = {
  email: Scalars['String'];
  language: Scalars['String'];
  name: Scalars['String'];
  onboardingId: Scalars['ID'];
  password: Scalars['String'];
};

type CreateAccountResponseDto = {
  __typename?: 'CreateAccountResponseDTO';
  email: Scalars['String'];
  hasAccount: Scalars['Boolean'];
  userId: Scalars['ID'];
};

type CreateAuthorizationCardInput = {
  additionalFields?: InputMaybe<Scalars['String']>;
  company: Scalars['String'];
  groupId: Scalars['ID'];
  language: Scalars['String'];
  notify: Scalars['Boolean'];
};

type CreateCampaignDto = {
  categories?: InputMaybe<Array<Scalars['String']>>;
  templateId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

type CreateCampaignLinkDto = {
  campaignId: Scalars['ID'];
  url: Scalars['String'];
};

type CreateCustomActionDto = {
  campaignId: Scalars['String'];
  description: Scalars['String'];
  title: Scalars['String'];
};

type CreateCustomResourceInput = {
  attachmentId: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

type CreateInviteLinkDto = {
  campaignId?: InputMaybe<Scalars['ID']>;
  coworkerId?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['DateTime']>;
  maxSeats?: InputMaybe<Scalars['Float']>;
  netTrustNeeded: Scalars['Int'];
  passphrase?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

type CreateOnboardingFormInput = {
  onboardingFormFields?: InputMaybe<Scalars['String']>;
  partnerId: Scalars['ID'];
};

type CreateOrUpdateCustomFormResponseInput = {
  customFormId: Scalars['ID'];
  customFormResponseId?: InputMaybe<Scalars['ID']>;
  groupId?: InputMaybe<Scalars['ID']>;
  responses: Array<DataFieldResponseInput>;
  submittedAt?: InputMaybe<Scalars['DateTime']>;
};

type CreateOrUpdateDataFieldTableRowInput = {
  dataFieldTableRowId?: InputMaybe<Scalars['ID']>;
  responses: Array<DataFieldResponseInput>;
};

type CreateOrUpdateDataFieldTableRowSetInput = {
  dataFieldTableId: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
  rowSetId: Scalars['ID'];
  rows: Array<CreateOrUpdateDataFieldTableRowInput>;
};

type CreateOrUpdateTaskInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

enum CreateOrganizerError {
  CurrentOrganizerDifferentPartner = 'CURRENT_ORGANIZER_DIFFERENT_PARTNER',
  CurrentOrganizerSamePartner = 'CURRENT_ORGANIZER_SAME_PARTNER',
  CurrentWorkerDifferentPartner = 'CURRENT_WORKER_DIFFERENT_PARTNER',
  CurrentWorkerSamePartner = 'CURRENT_WORKER_SAME_PARTNER',
  None = 'NONE'
}

type CreateOrganizerInput = {
  addToAllGroups: Scalars['Boolean'];
  email: Scalars['String'];
  groupIds: Array<Scalars['ID']>;
  name: Scalars['String'];
  partnerId: Scalars['ID'];
};

type CreateOrganizerResult = {
  __typename?: 'CreateOrganizerResult';
  error: CreateOrganizerError;
  user?: Maybe<User>;
};

type CreatePartnerGroupInput = {
  addAllOrganizers: Scalars['Boolean'];
  name: Scalars['String'];
  organizerUserIds: Array<Scalars['ID']>;
  partnerId: Scalars['ID'];
};

type CreatePartnerInput = {
  defaultWelcomeMessage: Scalars['String'];
  logoAttachmentId: Scalars['ID'];
  name: Scalars['String'];
};

type CreatePollDto = {
  canChangeResponse: Scalars['Boolean'];
  expiration?: InputMaybe<Scalars['DateTime']>;
  isAnonymous: Scalars['Boolean'];
  options: Array<Scalars['String']>;
  title: Scalars['String'];
};

type CreateTopicInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: Scalars['String'];
};

type CustomActionDto = {
  __typename?: 'CustomActionDTO';
  action: Scalars['String'];
  description: Scalars['String'];
  downvotes: Scalars['Int'];
  id: Scalars['ID'];
  title: Scalars['String'];
  upvotes: Scalars['Int'];
  yourVote?: Maybe<Scalars['Int']>;
};

type CustomForm = {
  __typename?: 'CustomForm';
  allowMultipleSubmissions: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  customFormFields: Array<CustomFormField>;
  customFormResponses: Array<CustomFormResponse>;
  customFormSections: Array<CustomFormSection>;
  description: Scalars['String'];
  id: Scalars['String'];
  partnerId: Scalars['String'];
  title: Scalars['String'];
};


type CustomFormCustomFormResponsesArgs = {
  groupId?: InputMaybe<Scalars['ID']>;
};

type CustomFormField = {
  __typename?: 'CustomFormField';
  customFormSectionId: Scalars['ID'];
  dataField: DataField;
  dataFieldId: Scalars['ID'];
  id: Scalars['ID'];
  order: Scalars['Float'];
  required: Scalars['Boolean'];
};

type CustomFormFieldError = {
  __typename?: 'CustomFormFieldError';
  customFormFieldId: Scalars['ID'];
  error: CustomFormFieldErrors;
};

type CustomFormFieldResponse = {
  __typename?: 'CustomFormFieldResponse';
  id: Scalars['ID'];
};

enum CustomFormFieldType {
  Boolean = 'boolean',
  Currency = 'currency',
  Datetime = 'datetime',
  Number = 'number',
  Select = 'select',
  Table = 'table',
  Text = 'text'
}

type CustomFormGroup = {
  __typename?: 'CustomFormGroup';
  customForm: CustomForm;
  id: Scalars['ID'];
  visibleToWorkers: Scalars['Boolean'];
};

type CustomFormResponse = {
  __typename?: 'CustomFormResponse';
  coworker: RawCoworker;
  customForm: CustomForm;
  customFormFieldResponses: Array<CustomFormFieldResponse>;
  dataFieldResponses: Array<DataFieldResponse>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  status: CustomFormResponseStatus;
  submittedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
};

type CustomFormResponseDocument = {
  __typename?: 'CustomFormResponseDocument';
  customFormId: Scalars['ID'];
  customFormTitle: Scalars['String'];
  id: Scalars['ID'];
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

type CustomFormResponseDownloadToken = {
  __typename?: 'CustomFormResponseDownloadToken';
  customFormResponseId: Scalars['ID'];
  token: Scalars['String'];
};

enum CustomFormResponseStatus {
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
  Submitted = 'SUBMITTED'
}

type CustomFormSection = {
  __typename?: 'CustomFormSection';
  id: Scalars['ID'];
  instructions: Scalars['String'];
  order: Scalars['Float'];
  title: Scalars['String'];
};

type CustomResource = {
  __typename?: 'CustomResource';
  attachment: Attachment;
  id: Scalars['ID'];
  title: Scalars['String'];
};

type DbRef = {
  __typename?: 'DBRef';
  id: Scalars['ID'];
  type: Scalars['String'];
};

type DataField = {
  __typename?: 'DataField';
  config: Scalars['JSONObject'];
  id: Scalars['ID'];
  label: Scalars['String'];
  tooltip?: Maybe<Scalars['String']>;
  type: CustomFormFieldType;
};

type DataFieldError = {
  __typename?: 'DataFieldError';
  dataFieldId: Scalars['ID'];
  error: SubmitDataFieldResponseError;
};

type DataFieldResponse = {
  __typename?: 'DataFieldResponse';
  data: Scalars['JSONObject'];
  dataFieldId: Scalars['String'];
  id: Scalars['ID'];
};

type DataFieldResponseInput = {
  data: Scalars['JSONObject'];
  dataFieldId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
};

type DataFieldTable = {
  __typename?: 'DataFieldTable';
  createdAt: Scalars['DateTime'];
  creatorId?: Maybe<Scalars['String']>;
  dataFields: Array<DataFieldTableDataField>;
  id: Scalars['String'];
  partnerId: Scalars['String'];
};

type DataFieldTableDataField = {
  __typename?: 'DataFieldTableDataField';
  dataField: DataField;
  dataFieldId: Scalars['String'];
  dataFieldTableId: Scalars['String'];
  id: Scalars['String'];
  order: Scalars['Float'];
  required: Scalars['Boolean'];
};

type DataFieldTableRow = {
  __typename?: 'DataFieldTableRow';
  coworkerId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dataFieldResponses: Array<DataFieldResponse>;
  dataFieldTableId: Scalars['String'];
  id: Scalars['String'];
  rowSetId?: Maybe<Scalars['String']>;
};

type DocumentComment = {
  __typename?: 'DocumentComment';
  authorType: Scalars['String'];
  comment: Scalars['String'];
  commenterName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  documentId: Scalars['ID'];
  id: Scalars['ID'];
  isReplyable: Scalars['Boolean'];
  parent: DocumentComment;
  profilePicUrl?: Maybe<Scalars['String']>;
  replies: Array<DocumentComment>;
  timeAgo: Scalars['String'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

type EmailPreferences = {
  __typename?: 'EmailPreferences';
  muteAll: Scalars['Boolean'];
  mutedEmails: Array<EmailTemplates>;
  userEmailPreferences: EmailPreferences;
};

enum EmailTemplates {
  AssignmentCancelled = 'ASSIGNMENT_CANCELLED',
  AssignmentComplete = 'ASSIGNMENT_COMPLETE',
  AuthorizationCardAvailable = 'AUTHORIZATION_CARD_AVAILABLE',
  AuthorizationCardReceipt = 'AUTHORIZATION_CARD_RECEIPT',
  AuthCardSignatureStatus = 'AUTH_CARD_SIGNATURE_STATUS',
  ConfirmAuthorizationCard = 'CONFIRM_AUTHORIZATION_CARD',
  FormResponseSubmissionOrganizer = 'FORM_RESPONSE_SUBMISSION_ORGANIZER',
  FormResponseSubmissionReceipt = 'FORM_RESPONSE_SUBMISSION_RECEIPT',
  GoalReachedMember = 'GOAL_REACHED_MEMBER',
  GoalReachedOrganizer = 'GOAL_REACHED_ORGANIZER',
  GoalReachedSupporter = 'GOAL_REACHED_SUPPORTER',
  Invite = 'INVITE',
  InviteAccepted = 'INVITE_ACCEPTED',
  InviteOrganizerToCampaign = 'INVITE_ORGANIZER_TO_CAMPAIGN',
  InWaitingRoom = 'IN_WAITING_ROOM',
  Mention = 'MENTION',
  NewAssignment = 'NEW_ASSIGNMENT',
  NewAuthorizationCardSignature = 'NEW_AUTHORIZATION_CARD_SIGNATURE',
  NewCampaignPublished = 'NEW_CAMPAIGN_PUBLISHED',
  NewCampaignSupporter = 'NEW_CAMPAIGN_SUPPORTER',
  NewDocumentComment = 'NEW_DOCUMENT_COMMENT',
  NewFormAvailable = 'NEW_FORM_AVAILABLE',
  NewGroupCreated = 'NEW_GROUP_CREATED',
  NewLeadOrganizer = 'NEW_LEAD_ORGANIZER',
  NewWorkerJoinedGroup = 'NEW_WORKER_JOINED_GROUP',
  NotificationsDigest = 'NOTIFICATIONS_DIGEST',
  NotifyFlaggedUserOfOutcome = 'NOTIFY_FLAGGED_USER_OF_OUTCOME',
  NotifyFlaggerOfOutcome = 'NOTIFY_FLAGGER_OF_OUTCOME',
  NotifyOrganizerOfNewFlag = 'NOTIFY_ORGANIZER_OF_NEW_FLAG',
  OnboardingNextSteps = 'ONBOARDING_NEXT_STEPS',
  OrganizerAccount = 'ORGANIZER_ACCOUNT',
  OrganizerAddedToGroup = 'ORGANIZER_ADDED_TO_GROUP',
  OrganizerRemovedFromGroup = 'ORGANIZER_REMOVED_FROM_GROUP',
  PollPublished = 'POLL_PUBLISHED',
  RequestInvitation = 'REQUEST_INVITATION',
  ResetPassword = 'RESET_PASSWORD',
  ResetPasswordConfirmation = 'RESET_PASSWORD_CONFIRMATION',
  ResponseToCollaborationInvitation = 'RESPONSE_TO_COLLABORATION_INVITATION',
  SendAuthCard = 'SEND_AUTH_CARD',
  SetOrganizerPassword = 'SET_ORGANIZER_PASSWORD',
  SignatureConfirmation = 'SIGNATURE_CONFIRMATION',
  UpdateInThread = 'UPDATE_IN_THREAD',
  UserFlagged = 'USER_FLAGGED',
  VerifyEmail = 'VERIFY_EMAIL',
  Welcome = 'WELCOME',
  WelcomeWorkerOne = 'WELCOME_WORKER_ONE',
  WorkerRemovedConfirmation = 'WORKER_REMOVED_CONFIRMATION'
}

type FlagUserInput = {
  category: Scalars['String'];
  notes: Scalars['String'];
  userIds: Array<Scalars['ID']>;
};

type FormAction = {
  __typename?: 'FormAction';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

type FrankDocument = {
  __typename?: 'FrankDocument';
  authCardSignature?: Maybe<AuthCardSignatureDocument>;
  customFormResponse?: Maybe<CustomFormResponseDocument>;
  petition?: Maybe<PetitionDocument>;
  resource?: Maybe<ResourceDocument>;
  type: DocumentType;
};

type Group = {
  __typename?: 'Group';
  activity: PaginatedActivity;
  allowAnonymousChannel: Scalars['Boolean'];
  allowFromFrankChannel: Scalars['Boolean'];
  assessmentActivity: PaginatedActivity;
  authorizationCard?: Maybe<AuthorizationCard>;
  authorizationLanguage: Scalars['String'];
  campaigns: Array<Campaign>;
  channelOptions: ChannelOptions;
  company?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customResources: Array<CustomResource>;
  emailSenderName: Scalars['String'];
  id: Scalars['ID'];
  industry?: Maybe<Scalars['String']>;
  inviteMessage: Scalars['String'];
  invites: Array<Invite>;
  isCurrentGroup: Scalars['Boolean'];
  leadOrganizer?: Maybe<Organizer>;
  leadOrganizerId?: Maybe<Scalars['ID']>;
  logo?: Maybe<Attachment>;
  members: Array<User>;
  membershipCount: Scalars['Float'];
  memberships: Array<Membership>;
  name: Scalars['String'];
  onboardingTasks?: Maybe<Array<ScheduledTask>>;
  organizers: Array<Organizer>;
  partner: Partner;
  partnerId: Scalars['ID'];
  size: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<User>;
  welcomeMessage: Scalars['String'];
};


type GroupActivityArgs = {
  pagination: PaginationArgumentDto;
};


type GroupAssessmentActivityArgs = {
  pagination: PaginationArgumentDto;
};

type GroupActivity = {
  __typename?: 'GroupActivity';
  groupId: Scalars['ID'];
  unseenChatActivity: Scalars['Boolean'];
  unseenNotifications: Scalars['Boolean'];
};

type GroupReport = {
  __typename?: 'GroupReport';
  company?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  draftCampaigns: Scalars['Int'];
  groupName: Scalars['String'];
  id: Scalars['ID'];
  inGroupReview: Scalars['Int'];
  inviteLinks: Scalars['Int'];
  invited: Scalars['Int'];
  lead: Scalars['Int'];
  member: Scalars['Int'];
  mostRecentCoworkerCreated: Scalars['DateTime'];
  mostRecentPageview: Scalars['DateTime'];
  name: Scalars['String'];
  notAllowed: Scalars['Int'];
  onboarding: Scalars['Int'];
  pageviews: Scalars['Int'];
  partnerId: Scalars['ID'];
  publishedCampaigns: Scalars['Int'];
  rejected: Scalars['Int'];
  topics: Scalars['Int'];
};

type ImportTemplateDto = {
  templateId: Scalars['ID'];
};

type Invite = {
  __typename?: 'Invite';
  activity: PaginatedActivity;
  createdAt: Scalars['DateTime'];
  disabled: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  emailMask?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['DateTime']>;
  expiresIn?: Maybe<Scalars['String']>;
  groupId: Scalars['ID'];
  groupName: Scalars['String'];
  id: Scalars['ID'];
  inviteType: Scalars['String'];
  inviter?: Maybe<Inviter>;
  inviterId?: Maybe<Scalars['ID']>;
  maxSeats?: Maybe<Scalars['Float']>;
  netTrustNeeded: Scalars['Int'];
  passphrase?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


type InviteActivityArgs = {
  pagination: PaginationArgumentDto;
};

type InviteStats = {
  __typename?: 'InviteStats';
  accountsCreated: Scalars['Int'];
  emailsSent: Scalars['Int'];
  linksCreated: Scalars['Int'];
};

type Inviter = {
  __typename?: 'Inviter';
  name?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
};

type Link = {
  __typename?: 'Link';
  addedByUser?: Maybe<User>;
  id: Scalars['ID'];
  preview: Preview;
  url: Scalars['String'];
};

type Membership = {
  __typename?: 'Membership';
  createdAt: Scalars['DateTime'];
  dateAcceptedNoMgmt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  onboarding?: Maybe<Onboarding>;
  title?: Maybe<Scalars['String']>;
  user: User;
  workplaceIssues: Array<Scalars['String']>;
};

type MembershipRequest = {
  __typename?: 'MembershipRequest';
  id: Scalars['ID'];
};

type MissingCompanyInput = {
  domain?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  industry: Scalars['String'];
  locality: Scalars['String'];
  name: Scalars['String'];
  queryText?: InputMaybe<Scalars['String']>;
};

type MoveWorkerInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

type Mutation = {
  __typename?: 'Mutation';
  acceptCollaboratorInvite: NothingDto;
  acceptNoMgmtInvite: NothingDto;
  addAttachmentToCampaign: NothingDto;
  addCollaborators: NothingDto;
  addColumn: CoworkerCustomColumn;
  addFormToGroup: NothingDto;
  addIndependentAuthenticityVerification: CoworkerChangeResponseDto;
  addOrganizersToGroups: NothingDto;
  addPetitionRecipient: PetitionRecipient;
  addToChannel: NothingDto;
  addVote: CustomActionDto;
  archiveCampaign: NothingDto;
  archiveCoworkers: NothingDto;
  archiveTopic: NothingDto;
  areUsersInChannel: Array<Scalars['String']>;
  assessCoworker: CoworkerAssessment;
  assignTask: NothingDto;
  bulkCreateTopics: NothingDto;
  bulkInviteByCoworker: NothingDto;
  bulkInviteByEmail: Array<Scalars['ID']>;
  campaignComment: NothingDto;
  changeCoworker: CoworkerChangeResponseDto;
  closeNavSection: NothingDto;
  closePoll: Poll;
  completeTour: NothingDto;
  configureGroup: NothingDto;
  configureUser: OnboardingWorkflowState;
  confirmAuthorizationCard: NothingDto;
  convertAccount: NothingDto;
  coworkerTrustVote: Coworker;
  createAccount: CreateAccountResponseDto;
  createAccountFromAuthCard: Onboarding;
  createAuthorizationCard: AuthorizationCard;
  createCampaign: Campaign;
  createCampaignLink: NothingDto;
  createChannelAssnForAttachment?: Maybe<NothingDto>;
  createCustomAction: NothingDto;
  createCustomResource: NothingDto;
  createGroup: Group;
  createInviteLink: Invite;
  createOnboardingForm: Partner;
  createOrUpdateDataFieldTableRows: SubmitDataFieldTableRowSetResult;
  createOrUpdateFormResponse: SubmitCustomFormResponseResult;
  createOrUpdateTask: Task;
  createOrganizer: CreateOrganizerResult;
  createPartner: NothingDto;
  createPoll: Poll;
  createTopic: Topic;
  deleteAttachment?: Maybe<NothingDto>;
  deleteCampaignLink: NothingDto;
  deleteColumn: NothingDto;
  deleteFormResponseDraft: NothingDto;
  deleteGroup: NothingDto;
  deleteOnboardingForm: NothingDto;
  deletePoll: NothingDto;
  disableInvite: NothingDto;
  enableInvite: NothingDto;
  enterPassphrase: Scalars['Boolean'];
  finishOnboarding: Onboarding;
  finishOrganizerOnboarding: NothingDto;
  flagUser: NothingDto;
  getAllResponsesForGroupDownloadToken: CustomFormResponseDownloadToken;
  getCustomFormResponseDownloadToken: CustomFormResponseDownloadToken;
  importTemplate: Campaign;
  login: OnboardingWorkflowState;
  markAllNotificationsAsSeen: NothingDto;
  markNotificationsAsRead: NothingDto;
  missingCompany: NothingDto;
  moveWorker: NothingDto;
  muteAllEmail: NothingDto;
  muteEmail: NothingDto;
  onboardingForm: NothingDto;
  openNavSection: NothingDto;
  publishCampaign: Campaign;
  recordEmailVerified: NothingDto;
  rejectCollaboratorInvite: NothingDto;
  removeAttachmentFromCampaign: NothingDto;
  removeCollaborator: NothingDto;
  removeCoworkerTrustVote: Coworker;
  removeCustomResource: NothingDto;
  removeFlag: NothingDto;
  removeFromChannel: NothingDto;
  removeOrganizer: NothingDto;
  removeOrganizersFromGroup: NothingDto;
  removePetitionRecipient: NothingDto;
  removeScheduledTask: NothingDto;
  removeWorkerFromGroup: NothingDto;
  requestInvite: NothingDto;
  resendConfirmAuthCardEmail: NothingDto;
  resolveCampaignComment: NothingDto;
  respondToPoll: Poll;
  retakeTour: NothingDto;
  selfCertifyNotManagement: NothingDto;
  sendAuthorizationCard: NothingDto;
  sendConfirmationEmail: NothingDto;
  sendVerifyEmail: NothingDto;
  setOnboardingCache: NothingDto;
  setProfilePic: NothingDto;
  signAuthorizationCard: AuthorizationCardSignature;
  signAuthorizationCardExternal: Onboarding;
  signCampaign: NothingDto;
  signUp: WorkerOneOnboardingResult;
  startOnboarding: Onboarding;
  tellUsMoreAboutYourself: NothingDto;
  unArchiveCoworkers: NothingDto;
  unmuteAllEmail: NothingDto;
  unmuteEmail: NothingDto;
  unsignCampaign: NothingDto;
  updateCampaign: Campaign;
  updateCustomFormResponseNotes: NothingDto;
  updateGroupDetails: Group;
  updateGroupName: Group;
  updateInviteLinkExpiration: NothingDto;
  updateInviteLinkMaxSeats: NothingDto;
  updateInviteLinkPassphrase: NothingDto;
  updatePartner: Partner;
  updateProfile: NothingDto;
  updateTaskAssignment: TaskAssignment;
  upholdFlag: NothingDto;
  verifyEmail: Onboarding;
  verifyEmailFromAuthCard: VerifyEmailFromAuthCard;
  viewCoworkerActivity: Coworker;
};


type MutationAcceptCollaboratorInviteArgs = {
  campaignId: Scalars['ID'];
};


type MutationAddAttachmentToCampaignArgs = {
  attachmentId: Scalars['ID'];
  campaignId: Scalars['ID'];
};


type MutationAddCollaboratorsArgs = {
  input: AddCollaboratorsDto;
};


type MutationAddColumnArgs = {
  input: AddCoworkerColumnDto;
};


type MutationAddFormToGroupArgs = {
  customFormId: Scalars['ID'];
  groupId: Scalars['ID'];
  visibleToWorkers: Scalars['Boolean'];
};


type MutationAddIndependentAuthenticityVerificationArgs = {
  coworkerId: Scalars['ID'];
};


type MutationAddOrganizersToGroupsArgs = {
  groupIds: Array<Scalars['ID']>;
  userIds: Array<Scalars['ID']>;
};


type MutationAddPetitionRecipientArgs = {
  input: AddPetitionRecipientDto;
};


type MutationAddToChannelArgs = {
  input: AddToChannelInput;
};


type MutationAddVoteArgs = {
  input: VoteOnActionDto;
};


type MutationArchiveCampaignArgs = {
  campaignId: Scalars['ID'];
};


type MutationArchiveCoworkersArgs = {
  coworkerIds: Array<Scalars['ID']>;
};


type MutationArchiveTopicArgs = {
  topicId: Scalars['ID'];
};


type MutationAreUsersInChannelArgs = {
  input: AreUsersInChannel;
};


type MutationAssessCoworkerArgs = {
  input: AssessCoworkerInput;
};


type MutationAssignTaskArgs = {
  input: AssignTaskInput;
};


type MutationBulkCreateTopicsArgs = {
  input: BulkCreateTopicInput;
};


type MutationBulkInviteByCoworkerArgs = {
  input: BulkInviteByCoworkerDto;
};


type MutationBulkInviteByEmailArgs = {
  input: BulkInviteByEmailDto;
};


type MutationCampaignCommentArgs = {
  input: PostCommentDto;
};


type MutationChangeCoworkerArgs = {
  change: CoworkerChangeDto;
};


type MutationCloseNavSectionArgs = {
  section: CloseableSectionNames;
};


type MutationClosePollArgs = {
  pollId: Scalars['ID'];
};


type MutationCompleteTourArgs = {
  tour: TourType;
};


type MutationConfigureGroupArgs = {
  input: ConfigureGroupInput;
};


type MutationConfigureUserArgs = {
  input: ConfigureUserInput;
};


type MutationConfirmAuthorizationCardArgs = {
  signatureId: Scalars['ID'];
};


type MutationConvertAccountArgs = {
  input: ConvertAccountInput;
};


type MutationCoworkerTrustVoteArgs = {
  input: CoworkerTrustVoteDto;
};


type MutationCreateAccountArgs = {
  input: CreateAccountDto;
};


type MutationCreateAccountFromAuthCardArgs = {
  input: CreateAccountFromAuthCardInput;
};


type MutationCreateAuthorizationCardArgs = {
  input: CreateAuthorizationCardInput;
};


type MutationCreateCampaignArgs = {
  input: CreateCampaignDto;
};


type MutationCreateCampaignLinkArgs = {
  input: CreateCampaignLinkDto;
};


type MutationCreateChannelAssnForAttachmentArgs = {
  attachmentId: Scalars['ID'];
  campaignId?: InputMaybe<Scalars['ID']>;
  topicId?: InputMaybe<Scalars['ID']>;
};


type MutationCreateCustomActionArgs = {
  input: CreateCustomActionDto;
};


type MutationCreateCustomResourceArgs = {
  input: CreateCustomResourceInput;
};


type MutationCreateGroupArgs = {
  input: CreatePartnerGroupInput;
};


type MutationCreateInviteLinkArgs = {
  input: CreateInviteLinkDto;
};


type MutationCreateOnboardingFormArgs = {
  input: CreateOnboardingFormInput;
};


type MutationCreateOrUpdateDataFieldTableRowsArgs = {
  groupId?: InputMaybe<Scalars['String']>;
  input: CreateOrUpdateDataFieldTableRowSetInput;
};


type MutationCreateOrUpdateFormResponseArgs = {
  input: CreateOrUpdateCustomFormResponseInput;
};


type MutationCreateOrUpdateTaskArgs = {
  input: CreateOrUpdateTaskInput;
};


type MutationCreateOrganizerArgs = {
  input: CreateOrganizerInput;
};


type MutationCreatePartnerArgs = {
  input: CreatePartnerInput;
};


type MutationCreatePollArgs = {
  campaignId?: InputMaybe<Scalars['ID']>;
  input: CreatePollDto;
  topicId?: InputMaybe<Scalars['ID']>;
};


type MutationCreateTopicArgs = {
  input: CreateTopicInput;
};


type MutationDeleteAttachmentArgs = {
  attachmentId: Scalars['ID'];
};


type MutationDeleteCampaignLinkArgs = {
  campaignId: Scalars['ID'];
  campaignLinkId: Scalars['ID'];
};


type MutationDeleteColumnArgs = {
  columnId: Scalars['ID'];
};


type MutationDeleteFormResponseDraftArgs = {
  formResponseId: Scalars['ID'];
};


type MutationDeleteGroupArgs = {
  groupId: Scalars['ID'];
};


type MutationDeleteOnboardingFormArgs = {
  partnerId: Scalars['ID'];
};


type MutationDeletePollArgs = {
  pollId: Scalars['ID'];
};


type MutationDisableInviteArgs = {
  inviteId: Scalars['ID'];
};


type MutationEnableInviteArgs = {
  inviteId: Scalars['ID'];
};


type MutationEnterPassphraseArgs = {
  onboardingId: Scalars['ID'];
  passphrase: Scalars['String'];
};


type MutationFinishOnboardingArgs = {
  onboardingId: Scalars['ID'];
};


type MutationFlagUserArgs = {
  input: FlagUserInput;
};


type MutationGetAllResponsesForGroupDownloadTokenArgs = {
  groupId: Scalars['ID'];
};


type MutationGetCustomFormResponseDownloadTokenArgs = {
  customFormResponseId: Scalars['ID'];
};


type MutationImportTemplateArgs = {
  input: ImportTemplateDto;
};


type MutationMarkNotificationsAsReadArgs = {
  ids: Array<Scalars['String']>;
};


type MutationMissingCompanyArgs = {
  input: MissingCompanyInput;
};


type MutationMoveWorkerArgs = {
  input: MoveWorkerInput;
};


type MutationMuteEmailArgs = {
  emailType: EmailTemplates;
};


type MutationOnboardingFormArgs = {
  input: OnboardingFormInput;
};


type MutationOpenNavSectionArgs = {
  section: CloseableSectionNames;
};


type MutationPublishCampaignArgs = {
  campaignId: Scalars['ID'];
};


type MutationRecordEmailVerifiedArgs = {
  onboardingId: Scalars['ID'];
};


type MutationRejectCollaboratorInviteArgs = {
  campaignId: Scalars['ID'];
};


type MutationRemoveAttachmentFromCampaignArgs = {
  attachmentId: Scalars['ID'];
  campaignId: Scalars['ID'];
};


type MutationRemoveCollaboratorArgs = {
  input: RemoveCollaboratorDto;
};


type MutationRemoveCoworkerTrustVoteArgs = {
  coworkerId: Scalars['ID'];
};


type MutationRemoveCustomResourceArgs = {
  attachmentId: Scalars['ID'];
  groupId?: InputMaybe<Scalars['ID']>;
};


type MutationRemoveFlagArgs = {
  input: RemoveFlagInput;
};


type MutationRemoveFromChannelArgs = {
  input: RemoveFromChannelInput;
};


type MutationRemoveOrganizerArgs = {
  organizerId: Scalars['ID'];
};


type MutationRemoveOrganizersFromGroupArgs = {
  groupId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};


type MutationRemovePetitionRecipientArgs = {
  recipientId: Scalars['ID'];
};


type MutationRemoveScheduledTaskArgs = {
  scheduledTaskId: Scalars['ID'];
};


type MutationRemoveWorkerFromGroupArgs = {
  coworkerId: Scalars['ID'];
};


type MutationRequestInviteArgs = {
  input: RequestInviteInput;
};


type MutationResendConfirmAuthCardEmailArgs = {
  coworkerId: Scalars['ID'];
};


type MutationResolveCampaignCommentArgs = {
  campaignId: Scalars['ID'];
  commentId: Scalars['ID'];
};


type MutationRespondToPollArgs = {
  input: PollResponseDto;
};


type MutationRetakeTourArgs = {
  tour: TourType;
};


type MutationSelfCertifyNotManagementArgs = {
  certifications: Array<OnboardingCertification>;
  onboardingId: Scalars['ID'];
};


type MutationSendAuthorizationCardArgs = {
  input: SendAuthCardInput;
};


type MutationSendVerifyEmailArgs = {
  input: SendVerifyEmailInput;
};


type MutationSetOnboardingCacheArgs = {
  input: SetOnboardingCacheDto;
};


type MutationSetProfilePicArgs = {
  attachmentId: Scalars['ID'];
};


type MutationSignAuthorizationCardArgs = {
  input: SignAuthorizationCardInput;
};


type MutationSignAuthorizationCardExternalArgs = {
  input: SignAuthorizationCardExternalInput;
};


type MutationSignCampaignArgs = {
  input: SignDto;
};


type MutationSignUpArgs = {
  input: SignUpInput;
};


type MutationStartOnboardingArgs = {
  token: Scalars['String'];
};


type MutationTellUsMoreAboutYourselfArgs = {
  input: TellUsMoreAboutYourselfDto;
};


type MutationUnArchiveCoworkersArgs = {
  coworkerIds: Array<Scalars['ID']>;
};


type MutationUnmuteEmailArgs = {
  emailType: EmailTemplates;
};


type MutationUnsignCampaignArgs = {
  campaignId: Scalars['ID'];
};


type MutationUpdateCampaignArgs = {
  input: UpdateCampaignDto;
};


type MutationUpdateCustomFormResponseNotesArgs = {
  customFormResponseId: Scalars['ID'];
  notes: Scalars['String'];
};


type MutationUpdateGroupDetailsArgs = {
  input: UpdateGroupDto;
};


type MutationUpdateGroupNameArgs = {
  input: UpdateGroupName;
};


type MutationUpdateInviteLinkExpirationArgs = {
  input: UpdateInviteLinkExpirationDto;
};


type MutationUpdateInviteLinkMaxSeatsArgs = {
  input: UpdateInviteLinkMaxSeatsDto;
};


type MutationUpdateInviteLinkPassphraseArgs = {
  input: UpdateInviteLinkPassphraseDto;
};


type MutationUpdatePartnerArgs = {
  input: UpdatePartnerInput;
};


type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


type MutationUpdateTaskAssignmentArgs = {
  input: UpdateTaskAssignmentInput;
};


type MutationUpholdFlagArgs = {
  input: UpholdFlagInput;
};


type MutationVerifyEmailArgs = {
  input: VerifyEmailDto;
};


type MutationVerifyEmailFromAuthCardArgs = {
  onboardingId: Scalars['ID'];
};


type MutationViewCoworkerActivityArgs = {
  coworkerId: Scalars['ID'];
};

type MyProfile = {
  __typename?: 'MyProfile';
  abilityRulesJson: Scalars['String'];
  activity: PaginatedActivity;
  coworker: Coworker;
  createdAt: Scalars['DateTime'];
  currentGroup: Group;
  currentOnboardings?: Maybe<Array<Onboarding>>;
  email: Scalars['String'];
  emailVerificationDate?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  groups: Array<Group>;
  homeFeed: PaginatedActivity;
  id: Scalars['ID'];
  intercomHash: Scalars['String'];
  invite: Invite;
  inviteActivity: PaginatedActivity;
  isYou: Scalars['Boolean'];
  language: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  membership: Membership;
  name?: Maybe<Scalars['String']>;
  notifications: PaginatedNotificationActivity;
  onboardingWorkflowState: OnboardingWorkflowState;
  onboardings?: Maybe<Array<Onboarding>>;
  organizedCampaigns: Array<Campaign>;
  organizingGroups: Array<Group>;
  phone?: Maybe<Scalars['String']>;
  profileAttachment?: Maybe<Attachment>;
  profilePic?: Maybe<Attachment>;
  shortName?: Maybe<Scalars['String']>;
  signedCampaigns: PaginatedCampaign;
  unseenNotificationsCount: Scalars['Float'];
  workerType: WorkerType;
};


type MyProfileActivityArgs = {
  pagination: PaginationArgumentDto;
};


type MyProfileHomeFeedArgs = {
  pagination: PaginationArgumentDto;
};


type MyProfileInviteActivityArgs = {
  pagination: PaginationArgumentDto;
};


type MyProfileNotificationsArgs = {
  pagination: PaginationArgumentDto;
};


type MyProfileSignedCampaignsArgs = {
  pagination: PaginationArgumentDto;
};

type Navigation = {
  __typename?: 'Navigation';
  friendlyFaces: Array<Attachment>;
  incompleteTours: Array<TourType>;
  sections: Array<NavigationSection>;
};

type NavigationItem = {
  __typename?: 'NavigationItem';
  badge?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['ID']>;
  identifier?: Maybe<Scalars['ID']>;
  label: Scalars['String'];
  navigationType: NavigationItemType;
  subjectType: NavigationSubjectTypes;
  unread?: Maybe<Scalars['Boolean']>;
};

enum NavigationItemType {
  Button = 'BUTTON',
  Link = 'LINK'
}

type NavigationSection = {
  __typename?: 'NavigationSection';
  addLabel?: Maybe<Scalars['String']>;
  addable: Scalars['Boolean'];
  closeableSectionName?: Maybe<CloseableSectionNames>;
  identifier?: Maybe<Scalars['String']>;
  isOpen: Scalars['Boolean'];
  isTitleVisible: Scalars['Boolean'];
  items: Array<NavigationItem>;
  title: Scalars['String'];
};

enum NavigationSubjectTypes {
  Campaign = 'CAMPAIGN',
  CreateCampaign = 'CREATE_CAMPAIGN',
  CreateTopic = 'CREATE_TOPIC',
  DraftCampaigns = 'DRAFT_CAMPAIGNS',
  FrankAdmin = 'FRANK_ADMIN',
  FromFrank = 'FROM_FRANK',
  Group = 'GROUP',
  GroupSettings = 'GROUP_SETTINGS',
  Help = 'HELP',
  Home = 'HOME',
  InstallApp = 'INSTALL_APP',
  Invite = 'INVITE',
  PartnerAdmin = 'PARTNER_ADMIN',
  PrivateTopic = 'PRIVATE_TOPIC',
  PublicTopic = 'PUBLIC_TOPIC',
  Resources = 'RESOURCES'
}

type NothingDto = {
  __typename?: 'NothingDTO';
  nothing: Scalars['String'];
};

type NotificationActivity = {
  __typename?: 'NotificationActivity';
  activities: Array<Activity>;
  activityCount: Scalars['Float'];
  actorCount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  createdTimeAgo: Scalars['String'];
  group: Scalars['String'];
  htmlDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  isSeen: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  verb: ActivityVerb;
};

type Onboarding = {
  __typename?: 'Onboarding';
  certifications: Array<OnboardingCertification>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  group: Group;
  groupId: Scalars['ID'];
  hasAccount: Scalars['Boolean'];
  id: Scalars['ID'];
  intercomHash: Scalars['String'];
  inviteType: Scalars['String'];
  inviter?: Maybe<Inviter>;
  issues: OnboardingIssueList;
  onboardingCache: Array<OnboardingCacheItem>;
  partner: Partner;
  requiresPassphrase: Scalars['Boolean'];
  token: Scalars['String'];
  userId: Scalars['String'];
  validationEmail?: Maybe<Scalars['String']>;
  workflowState: OnboardingWorkflowState;
};

type OnboardingCacheItem = {
  __typename?: 'OnboardingCacheItem';
  boolValue?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

enum OnboardingCertification {
  CurrentEmployee = 'CURRENT_EMPLOYEE',
  NonMgmt = 'NON_MGMT'
}

type OnboardingFormInput = {
  responses: Scalars['String'];
};

type OnboardingFormResponse = {
  __typename?: 'OnboardingFormResponse';
  id: Scalars['ID'];
  responseJSON: Scalars['String'];
};

type OnboardingIssue = {
  __typename?: 'OnboardingIssue';
  type: OnboardingIssueType;
};

type OnboardingIssueList = {
  __typename?: 'OnboardingIssueList';
  authenticityIndependentlyVerified: Scalars['Boolean'];
  authenticityIssues: Array<OnboardingIssue>;
  authenticityIssuesVisibleToOnboarder: Array<OnboardingIssue>;
  eligibilityIssues: Array<OnboardingIssue>;
  eligibilityIssuesVisibleToOnboarder: Array<OnboardingIssue>;
  id: Scalars['ID'];
  issueList: Array<OnboardingIssueType>;
  onboardingIssues: Array<OnboardingIssue>;
  shouldAllowTrust: Scalars['Boolean'];
  trustworthinessIssues: Array<OnboardingIssue>;
  trustworthinessIssuesVisibleToOnboarder: Array<OnboardingIssue>;
};

enum OnboardingIssueType {
  DangerousEmail = 'DANGEROUS_EMAIL',
  EmailOwnershipUnproven = 'EMAIL_OWNERSHIP_UNPROVEN',
  NeedsCurrentEmployeeCertification = 'NEEDS_CURRENT_EMPLOYEE_CERTIFICATION',
  NeedsMgmtCertification = 'NEEDS_MGMT_CERTIFICATION',
  NotEnoughCoworkerTrust = 'NOT_ENOUGH_COWORKER_TRUST',
  PassphraseMismatch = 'PASSPHRASE_MISMATCH',
  PassphraseNeeded = 'PASSPHRASE_NEEDED',
  UnknownEmail = 'UNKNOWN_EMAIL',
  WorkerIneligible = 'WORKER_INELIGIBLE'
}

enum OnboardingWorkflowState {
  CreatingNewGroup = 'CREATING_NEW_GROUP',
  Finished = 'FINISHED',
  InGroupReview = 'IN_GROUP_REVIEW',
  InOnboarding = 'IN_ONBOARDING',
  Rejected = 'REJECTED',
  SecurityVerification = 'SECURITY_VERIFICATION'
}

type Organizer = {
  __typename?: 'Organizer';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  partner: User;
  partnerId: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};

type PaginatedActivity = {
  __typename?: 'PaginatedActivity';
  hasNext: Scalars['Boolean'];
  objects: Array<Activity>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedAttachment = {
  __typename?: 'PaginatedAttachment';
  hasNext: Scalars['Boolean'];
  objects: Array<Attachment>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedAuthorizationCardSignature = {
  __typename?: 'PaginatedAuthorizationCardSignature';
  hasNext: Scalars['Boolean'];
  objects: Array<AuthorizationCardSignature>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedCampaign = {
  __typename?: 'PaginatedCampaign';
  hasNext: Scalars['Boolean'];
  objects: Array<Campaign>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedCoworker = {
  __typename?: 'PaginatedCoworker';
  hasNext: Scalars['Boolean'];
  objects: Array<Coworker>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedCustomForm = {
  __typename?: 'PaginatedCustomForm';
  hasNext: Scalars['Boolean'];
  objects: Array<CustomForm>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedCustomFormGroup = {
  __typename?: 'PaginatedCustomFormGroup';
  hasNext: Scalars['Boolean'];
  objects: Array<CustomFormGroup>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedCustomFormResponse = {
  __typename?: 'PaginatedCustomFormResponse';
  hasNext: Scalars['Boolean'];
  objects: Array<CustomFormResponse>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedGroupReport = {
  __typename?: 'PaginatedGroupReport';
  hasNext: Scalars['Boolean'];
  objects: Array<GroupReport>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedInvite = {
  __typename?: 'PaginatedInvite';
  hasNext: Scalars['Boolean'];
  objects: Array<Invite>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedNotificationActivity = {
  __typename?: 'PaginatedNotificationActivity';
  hasNext: Scalars['Boolean'];
  objects: Array<NotificationActivity>;
  total?: Maybe<Scalars['Float']>;
  unread: Scalars['Float'];
  unseen: Scalars['Float'];
};

type PaginatedOnboardingFormResponse = {
  __typename?: 'PaginatedOnboardingFormResponse';
  hasNext: Scalars['Boolean'];
  objects: Array<OnboardingFormResponse>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedOrganizer = {
  __typename?: 'PaginatedOrganizer';
  hasNext: Scalars['Boolean'];
  objects: Array<Organizer>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedPartner = {
  __typename?: 'PaginatedPartner';
  hasNext: Scalars['Boolean'];
  objects: Array<Partner>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedPoll = {
  __typename?: 'PaginatedPoll';
  hasNext: Scalars['Boolean'];
  objects: Array<Poll>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedSignature = {
  __typename?: 'PaginatedSignature';
  hasNext: Scalars['Boolean'];
  objects: Array<Signature>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedTask = {
  __typename?: 'PaginatedTask';
  hasNext: Scalars['Boolean'];
  objects: Array<Task>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedTaskAssignment = {
  __typename?: 'PaginatedTaskAssignment';
  hasNext: Scalars['Boolean'];
  objects: Array<TaskAssignment>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatedUser = {
  __typename?: 'PaginatedUser';
  hasNext: Scalars['Boolean'];
  objects: Array<User>;
  total?: Maybe<Scalars['Float']>;
};

type PaginatinedCoworkerCustomFormResponseList = {
  __typename?: 'PaginatinedCoworkerCustomFormResponseList';
  hasNext: Scalars['Boolean'];
  objects: Array<CoworkerCustomFormResponseList>;
  total?: Maybe<Scalars['Float']>;
};

type PaginationArgumentDto = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

type Partner = {
  __typename?: 'Partner';
  allowAnonymousChannel: Scalars['Boolean'];
  allowFromFrankChannel: Scalars['Boolean'];
  authorizationCardFormId?: Maybe<Scalars['ID']>;
  authorizationCardFormJSON?: Maybe<Scalars['String']>;
  authorizationLanguage: Scalars['String'];
  customResources: Array<CustomResource>;
  defaultWelcomeMessage: Scalars['String'];
  hasCustomForms: Scalars['Boolean'];
  id: Scalars['ID'];
  logoAttachment: Attachment;
  name: Scalars['String'];
  onboardingFormJSON?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  stats: PartnerStats;
};

type PartnerStats = {
  __typename?: 'PartnerStats';
  groupCount: Scalars['Float'];
  organizerCount: Scalars['Float'];
};

type PetitionDocument = {
  __typename?: 'PetitionDocument';
  id: Scalars['ID'];
  publishedAt: Scalars['DateTime'];
  title: Scalars['String'];
};

type PetitionRecipient = {
  __typename?: 'PetitionRecipient';
  addedByUser: User;
  campaign: Campaign;
  campaignId: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
};

type Poll = {
  __typename?: 'Poll';
  canChangeResponse: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  expiration?: Maybe<Scalars['DateTime']>;
  expiresIn?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  myResponse?: Maybe<ResponseResult>;
  options: Array<ResponseResult>;
  relationshipToMe: PollUserRelationship;
  status: PollStatus;
  title: Scalars['String'];
  totalResponses: Scalars['Int'];
  user?: Maybe<User>;
};

type PollResponseDto = {
  pollId: Scalars['ID'];
  pollOptionId: Scalars['ID'];
};

enum PollStatus {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

enum PollUserRelationship {
  Creator = 'CREATOR',
  None = 'NONE',
  Responder = 'RESPONDER'
}

type PostCommentDto = {
  comment: Scalars['String'];
  commentId: Scalars['ID'];
  documentId: Scalars['ID'];
  parentId?: InputMaybe<Scalars['ID']>;
};

type Preview = {
  __typename?: 'Preview';
  imageUrl?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

type PublishingValidationProblem = {
  __typename?: 'PublishingValidationProblem';
  category: Scalars['String'];
  message: Scalars['String'];
};

type Query = {
  __typename?: 'Query';
  activity: PaginatedActivity;
  assessmentOptions: Array<AssessmentOption>;
  attachment: Attachment;
  attachments: PaginatedAttachment;
  authorizationCard?: Maybe<AuthorizationCard>;
  authorizationCardSignature?: Maybe<AuthorizationCardSignature>;
  authorizationCardSignatureById: AuthorizationCardSignature;
  availableCustomFormsByPartner: PaginatedCustomForm;
  campaign: Campaign;
  campaigns: Array<Campaign>;
  categories: Array<Category>;
  channel: ChatChannel;
  collaboratorInvite?: Maybe<Collaborator>;
  companies: Array<CompanySearchResult>;
  coworker: Coworker;
  coworkerActions: Array<Action>;
  coworkerDocuments: Array<FrankDocument>;
  coworkerSummary: CoworkerSummaryDto;
  coworkers: PaginatedCoworker;
  coworkersYetToSubmit: PaginatedCoworker;
  customColumns: Array<CoworkerCustomColumn>;
  customForm: CustomForm;
  customFormResponse?: Maybe<CustomFormResponse>;
  customFormResponsesByGroupId: PaginatedCustomFormResponse;
  customFormsByCoworker: PaginatedCustomForm;
  customFormsByGroup: PaginatedCustomFormGroup;
  dataFieldTable: DataFieldTable;
  dataFieldTableRow: DataFieldTableRow;
  dataFieldTableRowsByRowSetId: Array<DataFieldTableRow>;
  emailForOnboarding?: Maybe<Scalars['String']>;
  getCoworkerCustomFormResponseLists: PaginatinedCoworkerCustomFormResponseList;
  group: Group;
  groupDetails: Group;
  groupReports: PaginatedGroupReport;
  invite: Invite;
  inviteStats: InviteStats;
  links: PaginatedInvite;
  myPartner: Partner;
  myProfile: MyProfile;
  navigation: Navigation;
  onboarding?: Maybe<Onboarding>;
  onboardingFormResponses: PaginatedOnboardingFormResponse;
  organizer: Organizer;
  organizersByPartner: PaginatedOrganizer;
  partner: Partner;
  partners: PaginatedPartner;
  poll: Poll;
  polls: PaginatedPoll;
  profile: User;
  resources: Array<Resource>;
  taskAssignments: PaginatedTaskAssignment;
  tasks: PaginatedTask;
  template: Template;
  templates: Array<Template>;
  topic: Topic;
  unseenActivityForGroups: Array<GroupActivity>;
  userEmailPreferences: EmailPreferences;
  workerOneOnboarding: WorkerOneOnboardingDto;
};


type QueryActivityArgs = {
  id: Scalars['ID'];
  pagination: PaginationArgumentDto;
  type: Scalars['String'];
};


type QueryAttachmentArgs = {
  attachmentId: Scalars['ID'];
};


type QueryAttachmentsArgs = {
  campaignId?: InputMaybe<Scalars['ID']>;
  pagination: PaginationArgumentDto;
  topicId?: InputMaybe<Scalars['ID']>;
};


type QueryAuthorizationCardArgs = {
  groupId: Scalars['ID'];
};


type QueryAuthorizationCardSignatureArgs = {
  input: AuthorizationCardSignatureInput;
};


type QueryAuthorizationCardSignatureByIdArgs = {
  customFormId?: InputMaybe<Scalars['ID']>;
  signatureId: Scalars['ID'];
};


type QueryAvailableCustomFormsByPartnerArgs = {
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
  partnerId: Scalars['ID'];
};


type QueryCampaignArgs = {
  campaignId: Scalars['ID'];
};


type QueryCampaignsArgs = {
  relationshipToMe?: InputMaybe<CampaignUserRelationship>;
  workflowState?: InputMaybe<CampaignWorkflowState>;
};


type QueryChannelArgs = {
  channelId: Scalars['ID'];
};


type QueryCollaboratorInviteArgs = {
  campaignId: Scalars['ID'];
};


type QueryCompaniesArgs = {
  queryText: Scalars['String'];
};


type QueryCoworkerArgs = {
  coworkerId: Scalars['ID'];
};


type QueryCoworkerActionsArgs = {
  coworkerId: Scalars['ID'];
};


type QueryCoworkerDocumentsArgs = {
  coworkerId: Scalars['ID'];
};


type QueryCoworkersArgs = {
  explicitGroupId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CoworkerFilterDto>;
  order?: InputMaybe<CoworkerOrderArgumentDto>;
  pagination: PaginationArgumentDto;
  secondOrder?: InputMaybe<CoworkerOrderArgumentDto>;
};


type QueryCoworkersYetToSubmitArgs = {
  customFormId: Scalars['ID'];
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
};


type QueryCustomFormArgs = {
  customFormId: Scalars['ID'];
};


type QueryCustomFormResponseArgs = {
  formResponseId?: InputMaybe<Scalars['ID']>;
};


type QueryCustomFormResponsesByGroupIdArgs = {
  customFormId: Scalars['ID'];
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
};


type QueryCustomFormsByCoworkerArgs = {
  coworkerId: Scalars['ID'];
  pagination: PaginationArgumentDto;
};


type QueryCustomFormsByGroupArgs = {
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
};


type QueryDataFieldTableArgs = {
  dataFieldTableId: Scalars['ID'];
};


type QueryDataFieldTableRowArgs = {
  dataFieldTableRowId: Scalars['ID'];
};


type QueryDataFieldTableRowsByRowSetIdArgs = {
  dataFieldTableId: Scalars['ID'];
  rowSetId: Scalars['ID'];
};


type QueryEmailForOnboardingArgs = {
  onboardingId?: InputMaybe<Scalars['ID']>;
};


type QueryGetCoworkerCustomFormResponseListsArgs = {
  coworkerId?: InputMaybe<Scalars['ID']>;
  customFormId: Scalars['ID'];
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
};


type QueryGroupArgs = {
  groupId: Scalars['ID'];
};


type QueryGroupDetailsArgs = {
  groupId: Scalars['String'];
};


type QueryGroupReportsArgs = {
  pagination: PaginationArgumentDto;
  partnerId: Scalars['String'];
};


type QueryInviteArgs = {
  inviteId: Scalars['ID'];
};


type QueryLinksArgs = {
  pagination: PaginationArgumentDto;
};


type QueryOnboardingFormResponsesArgs = {
  pagination: PaginationArgumentDto;
};


type QueryOrganizerArgs = {
  organizerId: Scalars['ID'];
};


type QueryOrganizersByPartnerArgs = {
  pagination: PaginationArgumentDto;
  partnerId: Scalars['ID'];
};


type QueryPartnerArgs = {
  slug: Scalars['String'];
};


type QueryPartnersArgs = {
  pagination: PaginationArgumentDto;
};


type QueryPollArgs = {
  pollId: Scalars['ID'];
};


type QueryPollsArgs = {
  campaignId?: InputMaybe<Scalars['ID']>;
  pagination: PaginationArgumentDto;
  topicId?: InputMaybe<Scalars['ID']>;
};


type QueryProfileArgs = {
  userId: Scalars['ID'];
};


type QueryResourcesArgs = {
  tag: Scalars['String'];
};


type QueryTaskAssignmentsArgs = {
  coworkerId?: InputMaybe<Scalars['ID']>;
  groupId?: InputMaybe<Scalars['ID']>;
  pagination: PaginationArgumentDto;
  workflowState?: InputMaybe<AssignmentWorkflowState>;
};


type QueryTasksArgs = {
  pagination: PaginationArgumentDto;
};


type QueryTemplateArgs = {
  id: Scalars['ID'];
};


type QueryTemplatesArgs = {
  categoryId?: InputMaybe<Scalars['String']>;
};


type QueryTopicArgs = {
  slug: Scalars['String'];
};


type QueryWorkerOneOnboardingArgs = {
  onboardingId: Scalars['ID'];
};

type RawCoworker = {
  __typename?: 'RawCoworker';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

type RemoveCollaboratorDto = {
  campaignId: Scalars['ID'];
  collaboratorCoworkerId: Scalars['ID'];
};

type RemoveFlagInput = {
  flagId: Scalars['ID'];
};

type RemoveFromChannelInput = {
  channelId: Scalars['ID'];
  userId: Scalars['ID'];
};

type RequestInviteInput = {
  companyId: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
};

type Resource = {
  __typename?: 'Resource';
  creator?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  label: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

type ResourceDocument = {
  __typename?: 'ResourceDocument';
  contentType: Scalars['String'];
  id: Scalars['ID'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

type ResponseResult = {
  __typename?: 'ResponseResult';
  id: Scalars['ID'];
  name: Scalars['String'];
  percent: Scalars['Float'];
  totalVotes: Scalars['Float'];
};

type ScheduledTask = {
  __typename?: 'ScheduledTask';
  assignedBy: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  event: TaskEvent;
  groupId: Scalars['String'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  task: Task;
  taskId: Scalars['String'];
};

type SendAuthCardInput = {
  emails: Array<Scalars['String']>;
  groupId: Scalars['ID'];
};

type SendVerifyEmailInput = {
  email: Scalars['String'];
  groupId: Scalars['String'];
};

type SetOnboardingCacheDto = {
  boolValue?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
  token: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

type SignAuthorizationCardExternalInput = {
  authorizationCardFormResponses?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  employer: Scalars['String'];
  firstName: Scalars['String'];
  groupId: Scalars['ID'];
  lastName: Scalars['String'];
  onboardingId?: InputMaybe<Scalars['ID']>;
  phone: Scalars['String'];
  signature: Scalars['String'];
};

type SignAuthorizationCardInput = {
  authorizationCardFormResponses?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  employer: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  signature: Scalars['String'];
};

type SignDto = {
  campaignId: Scalars['ID'];
  statement?: InputMaybe<Scalars['String']>;
};

type SignUpInput = {
  email: Scalars['String'];
  partnerSlug: Scalars['String'];
  templateId?: InputMaybe<Scalars['ID']>;
};

type Signature = {
  __typename?: 'Signature';
  campaign: Campaign;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  statement?: Maybe<Scalars['String']>;
  timeAgo: Scalars['String'];
  user: User;
};

type SubmitCustomFormResponseResult = {
  __typename?: 'SubmitCustomFormResponseResult';
  error?: Maybe<SubmitCustomFormError>;
  fieldErrors?: Maybe<Array<CustomFormFieldError>>;
  responseData?: Maybe<CustomFormResponse>;
  status: SubmitCustomFormStatus;
};

type SubmitDataFieldTableRowResult = {
  __typename?: 'SubmitDataFieldTableRowResult';
  fieldErrors?: Maybe<Array<DataFieldError>>;
  status: SubmitDataFieldTableRowStatus;
};

type SubmitDataFieldTableRowSetResult = {
  __typename?: 'SubmitDataFieldTableRowSetResult';
  results: Array<SubmitDataFieldTableRowResult>;
  status: SubmitDataFieldTableRowStatus;
};

type Task = {
  __typename?: 'Task';
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  partnerId: Scalars['String'];
  title: Scalars['String'];
};

type TaskAction = {
  __typename?: 'TaskAction';
  assignmentId: Scalars['ID'];
  assignmentWorkflowState: AssignmentWorkflowState;
  createdAt: Scalars['DateTime'];
  dateAssigned: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

type TaskAssignment = {
  __typename?: 'TaskAssignment';
  assignedByUser: User;
  completedAt?: Maybe<Scalars['DateTime']>;
  coworker: Coworker;
  coworkerId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dueDate?: Maybe<Scalars['DateTime']>;
  groupId: Scalars['String'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  task: Task;
  taskId: Scalars['String'];
  workflowState: AssignmentWorkflowState;
};

enum TaskEvent {
  FinishWorkerTwoOnboarding = 'FINISH_WORKER_TWO_ONBOARDING'
}

type TellUsMoreAboutYourselfDto = {
  onboardingFormResponses?: InputMaybe<Scalars['String']>;
  profileName: Scalars['String'];
};

type Template = {
  __typename?: 'Template';
  about: Scalars['String'];
  attachments: Array<Attachment>;
  author: TemplateAuthor;
  categories: Array<Category>;
  content: Scalars['String'];
  contentJSON?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  links: Array<Link>;
  publishedAt: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  workflowState: Scalars['String'];
};

type TemplateAuthor = {
  __typename?: 'TemplateAuthor';
  bio: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<TemplateAuthorImage>;
  longBio?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  socialLink?: Maybe<Scalars['String']>;
};

type TemplateAuthorImage = {
  __typename?: 'TemplateAuthorImage';
  id: Scalars['ID'];
  url: Scalars['String'];
};

type Topic = {
  __typename?: 'Topic';
  archivedAt?: Maybe<Scalars['DateTime']>;
  attachments: PaginatedAttachment;
  channelId: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  polls: PaginatedPoll;
  resources: Array<Resource>;
  slug: Scalars['String'];
  supportsFiles: Scalars['Boolean'];
  type: ChannelType;
};


type TopicAttachmentsArgs = {
  pagination: PaginationArgumentDto;
};


type TopicPollsArgs = {
  pagination: PaginationArgumentDto;
};

enum TourType {
  Coworkers = 'COWORKERS',
  Welcome = 'WELCOME'
}

type UpdateCampaignDto = {
  campaignId: Scalars['ID'];
  categories?: InputMaybe<Array<Scalars['String']>>;
  signatureGoal?: InputMaybe<Scalars['Int']>;
  signaturesVisible?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

type UpdateGroupDto = {
  allowAnonymousChannel?: InputMaybe<Scalars['Boolean']>;
  allowFromFrankChannel?: InputMaybe<Scalars['Boolean']>;
  companyName?: InputMaybe<Scalars['String']>;
  emailSenderName?: InputMaybe<Scalars['String']>;
  groupId: Scalars['String'];
  industry?: InputMaybe<Scalars['String']>;
  inviteMessage?: InputMaybe<Scalars['String']>;
  leadOrganizerId?: InputMaybe<Scalars['ID']>;
  logoAttachmentId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  welcomeMessage?: InputMaybe<Scalars['String']>;
};

type UpdateGroupName = {
  name: Scalars['String'];
};

type UpdateInviteLinkExpirationDto = {
  expiration?: InputMaybe<Scalars['DateTime']>;
  token: Scalars['ID'];
};

type UpdateInviteLinkMaxSeatsDto = {
  maxSeats?: InputMaybe<Scalars['Float']>;
  token: Scalars['ID'];
};

type UpdateInviteLinkPassphraseDto = {
  passphrase?: InputMaybe<Scalars['String']>;
  token: Scalars['ID'];
};

type UpdatePartnerInput = {
  allowAnonymousChannel?: InputMaybe<Scalars['Boolean']>;
  allowFromFrankChannel?: InputMaybe<Scalars['Boolean']>;
  authorizationLanguage?: InputMaybe<Scalars['String']>;
  defaultWelcomeMessage?: InputMaybe<Scalars['String']>;
  logoAttachmentId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  partnerId: Scalars['ID'];
};

type UpdateProfileInput = {
  language?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

type UpdateTaskAssignmentInput = {
  assignmentId: Scalars['ID'];
  completedAt?: InputMaybe<Scalars['DateTime']>;
  workflowState: AssignmentWorkflowState;
};

type UpholdFlagInput = {
  flagId: Scalars['ID'];
};

type User = {
  __typename?: 'User';
  activity: PaginatedActivity;
  createdAt: Scalars['DateTime'];
  currentOnboardings?: Maybe<Array<Onboarding>>;
  emailVerificationDate?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invite: Invite;
  isYou: Scalars['Boolean'];
  language: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  membership: Membership;
  name?: Maybe<Scalars['String']>;
  onboardings?: Maybe<Array<Onboarding>>;
  organizedCampaigns: Array<Campaign>;
  organizingGroups: Array<Group>;
  profileAttachment?: Maybe<Attachment>;
  profilePic: Attachment;
  shortName?: Maybe<Scalars['String']>;
  signedCampaigns: PaginatedCampaign;
};


type UserActivityArgs = {
  pagination: PaginationArgumentDto;
};


type UserSignedCampaignsArgs = {
  pagination: PaginationArgumentDto;
};

type VerifyEmailDto = {
  token: Scalars['ID'];
};

type VerifyEmailFromAuthCard = {
  __typename?: 'VerifyEmailFromAuthCard';
  email: Scalars['String'];
  name: Scalars['String'];
  onboarding: Onboarding;
  partnerName: Scalars['String'];
};

type VoteOnActionDto = {
  action: Scalars['String'];
  campaignId: Scalars['ID'];
  vote: Scalars['Int'];
};

type WorkerOneOnboardingDto = {
  __typename?: 'WorkerOneOnboardingDTO';
  email: Scalars['String'];
  groupId: Scalars['ID'];
  hasSetPassword: Scalars['Boolean'];
  onboardingFormJSON?: Maybe<Scalars['String']>;
  onboardingStartedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};

enum WorkerOneOnboardingError {
  ExistingUser = 'EXISTING_USER',
  None = 'NONE'
}

type WorkerOneOnboardingResult = {
  __typename?: 'WorkerOneOnboardingResult';
  error: WorkerOneOnboardingError;
  groupId?: Maybe<Scalars['ID']>;
};

enum WorkerType {
  Organizer = 'ORGANIZER',
  WorkerOne = 'WORKER_ONE',
  WorkerTwo = 'WORKER_TWO'
}

enum ActionType {
  AddCoworkers = 'addCoworkers',
  AuthCard = 'authCard',
  Chat = 'chat',
  Form = 'form',
  InviteCoworkers = 'inviteCoworkers',
  MembershipRequests = 'membershipRequests',
  StartPetition = 'startPetition',
  Task = 'task'
}

enum CoworkerOrderColumns {
  AverageSupport = 'AVERAGE_SUPPORT',
  CreatedAt = 'CREATED_AT',
  EmploymentType = 'EMPLOYMENT_TYPE',
  IsMeaningful = 'IS_MEANINGFUL',
  JoinedAt = 'JOINED_AT',
  Name = 'NAME',
  OnboardingStartedAt = 'ONBOARDING_STARTED_AT',
  Status = 'STATUS',
  Support = 'SUPPORT',
  Title = 'TITLE',
  TotalTrustDownvotes = 'TOTAL_TRUST_DOWNVOTES',
  TrustJudgementCount = 'TRUST_JUDGEMENT_COUNT',
  YourTrustDate = 'YOUR_TRUST_DATE'
}

enum CoworkerRejectionReason {
  Manager = 'MANAGER',
  NotCurrentEmployee = 'NOT_CURRENT_EMPLOYEE',
  NotWhoTheySayTheyAre = 'NOT_WHO_THEY_SAY_THEY_ARE',
  Other = 'OTHER',
  UnfriendlyToOrganizing = 'UNFRIENDLY_TO_ORGANIZING'
}

type CoworkerRestriction = {
  column: CoworkerFilterableColummns;
  combinator: Combinators;
  in?: InputMaybe<Array<Scalars['String']>>;
  numberValue?: InputMaybe<Scalars['Float']>;
  stringValue?: InputMaybe<Scalars['String']>;
};

enum CoworkerStatus {
  Admin = 'ADMIN',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Flagged = 'FLAGGED',
  Invited = 'INVITED',
  InGroupReview = 'IN_GROUP_REVIEW',
  Lead = 'LEAD',
  Member = 'MEMBER',
  NotAllowed = 'NOT_ALLOWED',
  Onboarding = 'ONBOARDING',
  Organizer = 'ORGANIZER',
  Rejected = 'REJECTED'
}

enum CustomFormFieldErrors {
  Required = 'REQUIRED'
}

enum DocumentType {
  AuthCardSignature = 'authCardSignature',
  CustomFormResponse = 'customFormResponse',
  Petition = 'petition',
  Resource = 'resource'
}

enum Order {
  Asc = 'ASC',
  Desc = 'DESC'
}

enum SubmitCustomFormError {
  Invalid = 'INVALID',
  MultipleSubmissionsNotAllowed = 'MULTIPLE_SUBMISSIONS_NOT_ALLOWED',
  ResponseAlreadySubmitted = 'RESPONSE_ALREADY_SUBMITTED'
}

enum SubmitCustomFormStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

enum SubmitDataFieldResponseError {
  Required = 'REQUIRED'
}

enum SubmitDataFieldTableRowStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

type DeleteAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['ID'];
}>;


type DeleteAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteAttachment?: Maybe<(
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  )> }
);

type CreateOrUpdateCustomFormResponseMutationVariables = Exact<{
  input: CreateOrUpdateCustomFormResponseInput;
}>;


type CreateOrUpdateCustomFormResponseMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateFormResponse: (
    { __typename?: 'SubmitCustomFormResponseResult' }
    & Pick<SubmitCustomFormResponseResult, 'error' | 'status'>
    & { fieldErrors?: Maybe<Array<(
      { __typename?: 'CustomFormFieldError' }
      & Pick<CustomFormFieldError, 'customFormFieldId' | 'error'>
    )>>, responseData?: Maybe<(
      { __typename?: 'CustomFormResponse' }
      & Pick<CustomFormResponse, 'id'>
    )> }
  ) }
);

type CreateOrUpdateDataFieldTableRowsMutationVariables = Exact<{
  input: CreateOrUpdateDataFieldTableRowSetInput;
}>;


type CreateOrUpdateDataFieldTableRowsMutation = (
  { __typename?: 'Mutation' }
  & { createOrUpdateDataFieldTableRows: (
    { __typename?: 'SubmitDataFieldTableRowSetResult' }
    & Pick<SubmitDataFieldTableRowSetResult, 'status'>
    & { results: Array<(
      { __typename?: 'SubmitDataFieldTableRowResult' }
      & { fieldErrors?: Maybe<Array<(
        { __typename?: 'DataFieldError' }
        & Pick<DataFieldError, 'dataFieldId' | 'error'>
      )>> }
    )> }
  ) }
);

type DeleteFormResponseDraftMutationVariables = Exact<{
  formResponseId: Scalars['ID'];
}>;


type DeleteFormResponseDraftMutation = (
  { __typename?: 'Mutation' }
  & { deleteFormResponseDraft: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type CustomFormAndSectionsAndFieldsQueryVariables = Exact<{
  customFormId: Scalars['ID'];
}>;


type CustomFormAndSectionsAndFieldsQuery = (
  { __typename?: 'Query' }
  & { customForm: (
    { __typename?: 'CustomForm' }
    & Pick<CustomForm, 'id' | 'title' | 'description' | 'allowMultipleSubmissions'>
    & { customFormSections: Array<(
      { __typename?: 'CustomFormSection' }
      & Pick<CustomFormSection, 'id' | 'title' | 'instructions' | 'order'>
    )>, customFormFields: Array<(
      { __typename?: 'CustomFormField' }
      & Pick<CustomFormField, 'id' | 'customFormSectionId' | 'dataFieldId' | 'required' | 'order'>
      & { dataField: (
        { __typename?: 'DataField' }
        & Pick<DataField, 'id' | 'type' | 'label' | 'tooltip' | 'config'>
      ) }
    )> }
  ) }
);

type CustomFormResponseQueryVariables = Exact<{
  formResponseId?: Maybe<Scalars['ID']>;
}>;


type CustomFormResponseQuery = (
  { __typename?: 'Query' }
  & { customFormResponse?: Maybe<(
    { __typename?: 'CustomFormResponse' }
    & Pick<CustomFormResponse, 'id' | 'submittedAt' | 'notes'>
    & { dataFieldResponses: Array<(
      { __typename?: 'DataFieldResponse' }
      & Pick<DataFieldResponse, 'id' | 'dataFieldId' | 'data'>
    )> }
  )> }
);

type CustomFormsByCoworkerQueryVariables = Exact<{
  coworkerId: Scalars['ID'];
  pagination: PaginationArgumentDto;
}>;


type CustomFormsByCoworkerQuery = (
  { __typename?: 'Query' }
  & { customFormsByCoworker: (
    { __typename?: 'PaginatedCustomForm' }
    & Pick<PaginatedCustomForm, 'hasNext' | 'total'>
    & { objects: Array<(
      { __typename?: 'CustomForm' }
      & Pick<CustomForm, 'id' | 'title' | 'description' | 'allowMultipleSubmissions'>
      & { customFormResponses: Array<(
        { __typename?: 'CustomFormResponse' }
        & Pick<CustomFormResponse, 'id' | 'submittedAt'>
      )> }
    )> }
  ) }
);

type DataFieldTableAndDataFieldsQueryVariables = Exact<{
  dataFieldTableId: Scalars['ID'];
}>;


type DataFieldTableAndDataFieldsQuery = (
  { __typename?: 'Query' }
  & { dataFieldTable: (
    { __typename?: 'DataFieldTable' }
    & Pick<DataFieldTable, 'id'>
    & { dataFields: Array<(
      { __typename?: 'DataFieldTableDataField' }
      & Pick<DataFieldTableDataField, 'required' | 'order'>
      & { dataField: (
        { __typename?: 'DataField' }
        & Pick<DataField, 'id' | 'type' | 'label' | 'tooltip' | 'config'>
      ) }
    )> }
  ) }
);

type DataFieldTableRowsByRowSetIdQueryVariables = Exact<{
  dataFieldTableId: Scalars['ID'];
  rowSetId: Scalars['ID'];
}>;


type DataFieldTableRowsByRowSetIdQuery = (
  { __typename?: 'Query' }
  & { dataFieldTableRowsByRowSetId: Array<(
    { __typename?: 'DataFieldTableRow' }
    & Pick<DataFieldTableRow, 'id'>
    & { dataFieldResponses: Array<(
      { __typename?: 'DataFieldResponse' }
      & Pick<DataFieldResponse, 'id' | 'dataFieldId' | 'data'>
    )> }
  )> }
);

type ActionsForCoworkerQueryVariables = Exact<{
  coworkerId: Scalars['ID'];
}>;


type ActionsForCoworkerQuery = (
  { __typename?: 'Query' }
  & { coworkerActions: Array<(
    { __typename?: 'Action' }
    & Pick<Action, 'type'>
    & { task?: Maybe<(
      { __typename?: 'TaskAction' }
      & Pick<TaskAction, 'id' | 'title' | 'description' | 'assignmentId' | 'assignmentWorkflowState' | 'dueDate' | 'dateAssigned' | 'createdAt'>
    )>, customForm?: Maybe<(
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id' | 'title' | 'createdAt'>
    )>, authCard?: Maybe<(
      { __typename?: 'AuthCardAction' }
      & Pick<AuthCardAction, 'id' | 'language' | 'createdAt'>
    )>, membershipRequests?: Maybe<Array<(
      { __typename?: 'MembershipRequest' }
      & Pick<MembershipRequest, 'id'>
    )>> }
  )> }
);

type DocumentsForCoworkerQueryVariables = Exact<{
  coworkerId: Scalars['ID'];
}>;


type DocumentsForCoworkerQuery = (
  { __typename?: 'Query' }
  & { coworkerDocuments: Array<(
    { __typename?: 'FrankDocument' }
    & Pick<FrankDocument, 'type'>
    & { customFormResponse?: Maybe<(
      { __typename?: 'CustomFormResponseDocument' }
      & Pick<CustomFormResponseDocument, 'id' | 'submittedAt' | 'updatedAt' | 'customFormId' | 'customFormTitle'>
    )>, authCardSignature?: Maybe<(
      { __typename?: 'AuthCardSignatureDocument' }
      & Pick<AuthCardSignatureDocument, 'id' | 'submittedAt' | 'authCardId'>
    )>, petition?: Maybe<(
      { __typename?: 'PetitionDocument' }
      & Pick<PetitionDocument, 'id' | 'title' | 'publishedAt'>
    )>, resource?: Maybe<(
      { __typename?: 'ResourceDocument' }
      & Pick<ResourceDocument, 'id' | 'title' | 'url' | 'contentType' | 'publishedAt' | 'thumbnailUrl'>
    )> }
  )> }
);

type AddFormToGroupMutationVariables = Exact<{
  customFormId: Scalars['ID'];
  groupId: Scalars['ID'];
  visibleToWorkers: Scalars['Boolean'];
}>;


type AddFormToGroupMutation = (
  { __typename?: 'Mutation' }
  & { addFormToGroup: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type AddOrganizersToGroupsMutationVariables = Exact<{
  groupIds: Array<Scalars['ID']> | Scalars['ID'];
  userIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


type AddOrganizersToGroupsMutation = (
  { __typename?: 'Mutation' }
  & { addOrganizersToGroups: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type AssessCoworkerMutationVariables = Exact<{
  input: AssessCoworkerInput;
}>;


type AssessCoworkerMutation = (
  { __typename?: 'Mutation' }
  & { assessCoworker: (
    { __typename?: 'CoworkerAssessment' }
    & Pick<CoworkerAssessment, 'id'>
    & { assessmentOption: (
      { __typename?: 'AssessmentOption' }
      & Pick<AssessmentOption, 'id' | 'value' | 'label'>
    ) }
  ) }
);

type ConfirmAuthCardMutationVariables = Exact<{
  signatureId: Scalars['ID'];
}>;


type ConfirmAuthCardMutation = (
  { __typename?: 'Mutation' }
  & { confirmAuthorizationCard: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type ConvertAccountMutationVariables = Exact<{
  input: ConvertAccountInput;
}>;


type ConvertAccountMutation = (
  { __typename?: 'Mutation' }
  & { convertAccount: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type CreateAccountFromAuthCardMutationVariables = Exact<{
  input: CreateAccountFromAuthCardInput;
}>;


type CreateAccountFromAuthCardMutation = (
  { __typename?: 'Mutation' }
  & { createAccountFromAuthCard: (
    { __typename?: 'Onboarding' }
    & Pick<Onboarding, 'id' | 'token' | 'workflowState'>
  ) }
);

type CreateCustomResourceMutationVariables = Exact<{
  input: CreateCustomResourceInput;
}>;


type CreateCustomResourceMutation = (
  { __typename?: 'Mutation' }
  & { createCustomResource: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;


type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type AllResponsesForGroupDownloadTokenMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;


type AllResponsesForGroupDownloadTokenMutation = (
  { __typename?: 'Mutation' }
  & { getAllResponsesForGroupDownloadToken: (
    { __typename?: 'CustomFormResponseDownloadToken' }
    & Pick<CustomFormResponseDownloadToken, 'token'>
  ) }
);

type DownloadCustomFormResponseMutationVariables = Exact<{
  customFormResponseId: Scalars['ID'];
}>;


type DownloadCustomFormResponseMutation = (
  { __typename?: 'Mutation' }
  & { getCustomFormResponseDownloadToken: (
    { __typename?: 'CustomFormResponseDownloadToken' }
    & Pick<CustomFormResponseDownloadToken, 'token'>
  ) }
);

type FinishOrganizerOnboardingMutationVariables = Exact<{ [key: string]: never; }>;


type FinishOrganizerOnboardingMutation = (
  { __typename?: 'Mutation' }
  & { finishOrganizerOnboarding: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type RemoveCustomResourceMutationVariables = Exact<{
  attachmentId: Scalars['ID'];
  groupId?: Maybe<Scalars['ID']>;
}>;


type RemoveCustomResourceMutation = (
  { __typename?: 'Mutation' }
  & { removeCustomResource: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type RemoveOrganizersFromGroupMutationVariables = Exact<{
  userIds: Array<Scalars['ID']> | Scalars['ID'];
  groupId: Scalars['ID'];
}>;


type RemoveOrganizersFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeOrganizersFromGroup: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type RemoveWorkerFromGroupMutationVariables = Exact<{
  coworkerId: Scalars['ID'];
}>;


type RemoveWorkerFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeWorkerFromGroup: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type ResendConfirmAuthCardMutationVariables = Exact<{
  coworkerId: Scalars['ID'];
}>;


type ResendConfirmAuthCardMutation = (
  { __typename?: 'Mutation' }
  & { resendConfirmAuthCardEmail: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type UpdateCustomFormResponseNotesMutationVariables = Exact<{
  customFormResponseId: Scalars['ID'];
  notes: Scalars['String'];
}>;


type UpdateCustomFormResponseNotesMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomFormResponseNotes: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type SendAuthCardMutationVariables = Exact<{
  input: SendAuthCardInput;
}>;


type SendAuthCardMutation = (
  { __typename?: 'Mutation' }
  & { sendAuthorizationCard: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);

type AssessmentOptionsQueryVariables = Exact<{ [key: string]: never; }>;


type AssessmentOptionsQuery = (
  { __typename?: 'Query' }
  & { assessmentOptions: Array<(
    { __typename?: 'AssessmentOption' }
    & Pick<AssessmentOption, 'id' | 'label' | 'value' | 'description'>
  )> }
);

type AuthorizationCardSignatureByIdQueryVariables = Exact<{
  signatureId: Scalars['ID'];
  customFormId?: Maybe<Scalars['ID']>;
}>;


type AuthorizationCardSignatureByIdQuery = (
  { __typename?: 'Query' }
  & { authorizationCardSignatureById: (
    { __typename?: 'AuthorizationCardSignature' }
    & Pick<AuthorizationCardSignature, 'id' | 'firstName' | 'lastName' | 'createdAt' | 'employer' | 'email' | 'phone' | 'authCardFormResponseJSON' | 'authorizationLanguage' | 'signature' | 'authorizationCardId' | 'coworkerId'>
    & { authorizationCard: (
      { __typename?: 'AuthorizationCard' }
      & Pick<AuthorizationCard, 'id'>
    ) }
  ) }
);

type AvailableCustomFormsByPartnerQueryVariables = Exact<{
  partnerId: Scalars['ID'];
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
}>;


type AvailableCustomFormsByPartnerQuery = (
  { __typename?: 'Query' }
  & { availableCustomFormsByPartner: (
    { __typename?: 'PaginatedCustomForm' }
    & Pick<PaginatedCustomForm, 'hasNext' | 'total'>
    & { objects: Array<(
      { __typename?: 'CustomForm' }
      & Pick<CustomForm, 'id' | 'title' | 'description'>
    )> }
  ) }
);

type CoworkerCustomFormResponseListsQueryVariables = Exact<{
  customFormId: Scalars['ID'];
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
  coworkerId?: Maybe<Scalars['ID']>;
}>;


type CoworkerCustomFormResponseListsQuery = (
  { __typename?: 'Query' }
  & { getCoworkerCustomFormResponseLists: (
    { __typename?: 'PaginatinedCoworkerCustomFormResponseList' }
    & Pick<PaginatinedCoworkerCustomFormResponseList, 'hasNext' | 'total'>
    & { objects: Array<(
      { __typename?: 'CoworkerCustomFormResponseList' }
      & { coworker: (
        { __typename?: 'Coworker' }
        & Pick<Coworker, 'id' | 'name' | 'email'>
      ), responses: Array<(
        { __typename?: 'CustomFormResponse' }
        & Pick<CustomFormResponse, 'id' | 'submittedAt' | 'notes'>
      )> }
    )> }
  ) }
);

type CoworkersQueryVariables = Exact<{
  pagination: PaginationArgumentDto;
  order?: Maybe<CoworkerOrderArgumentDto>;
  filter: CoworkerFilterDto;
  explicitGroupId: Scalars['ID'];
}>;


type CoworkersQuery = (
  { __typename?: 'Query' }
  & { coworkers: (
    { __typename?: 'PaginatedCoworker' }
    & Pick<PaginatedCoworker, 'hasNext' | 'total'>
    & { objects: Array<(
      { __typename?: 'Coworker' }
      & Pick<Coworker, 'id' | 'name' | 'email' | 'status'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, assessment?: Maybe<(
        { __typename?: 'CoworkerAssessment' }
        & Pick<CoworkerAssessment, 'value' | 'label' | 'id' | 'createdAt'>
        & { assessmentOption: (
          { __typename?: 'AssessmentOption' }
          & Pick<AssessmentOption, 'id'>
        ), ratedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name'>
        )> }
      )> }
    )> }
  ) }
);

type CoworkersYetToSubmitQueryVariables = Exact<{
  customFormId: Scalars['ID'];
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
}>;


type CoworkersYetToSubmitQuery = (
  { __typename?: 'Query' }
  & { coworkersYetToSubmit: (
    { __typename?: 'PaginatedCoworker' }
    & Pick<PaginatedCoworker, 'hasNext' | 'total'>
    & { objects: Array<(
      { __typename?: 'Coworker' }
      & Pick<Coworker, 'id' | 'name' | 'email'>
    )> }
  ) }
);

type GetCustomFormQueryVariables = Exact<{
  customFormId: Scalars['ID'];
}>;


type GetCustomFormQuery = (
  { __typename?: 'Query' }
  & { customForm: (
    { __typename?: 'CustomForm' }
    & Pick<CustomForm, 'id' | 'title' | 'createdAt' | 'allowMultipleSubmissions'>
  ) }
);

type CustomFormResponsesByGroupIdQueryVariables = Exact<{
  customFormId: Scalars['ID'];
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
}>;


type CustomFormResponsesByGroupIdQuery = (
  { __typename?: 'Query' }
  & { customForm: (
    { __typename?: 'CustomForm' }
    & Pick<CustomForm, 'id' | 'title' | 'createdAt' | 'allowMultipleSubmissions'>
  ), customFormResponsesByGroupId: (
    { __typename?: 'PaginatedCustomFormResponse' }
    & Pick<PaginatedCustomFormResponse, 'hasNext' | 'total'>
    & { objects: Array<(
      { __typename?: 'CustomFormResponse' }
      & Pick<CustomFormResponse, 'id' | 'submittedAt' | 'status'>
      & { coworker: (
        { __typename?: 'RawCoworker' }
        & Pick<RawCoworker, 'id' | 'name' | 'email'>
      ), user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )> }
  ) }
);

type CustomFormsByGroupQueryVariables = Exact<{
  groupId: Scalars['ID'];
  pagination: PaginationArgumentDto;
}>;


type CustomFormsByGroupQuery = (
  { __typename?: 'Query' }
  & { customFormsByGroup: (
    { __typename?: 'PaginatedCustomFormGroup' }
    & Pick<PaginatedCustomFormGroup, 'hasNext' | 'total'>
    & { objects: Array<(
      { __typename?: 'CustomFormGroup' }
      & Pick<CustomFormGroup, 'id' | 'visibleToWorkers'>
      & { customForm: (
        { __typename?: 'CustomForm' }
        & Pick<CustomForm, 'id' | 'title' | 'description'>
      ) }
    )> }
  ) }
);

type GroupAuthCardAndPartnerQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


type GroupAuthCardAndPartnerQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'company' | 'name'>
    & { partner: (
      { __typename?: 'Partner' }
      & Pick<Partner, 'id' | 'name' | 'slug'>
      & { logoAttachment: (
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'id' | 'url'>
      ) }
    ), authorizationCard?: Maybe<(
      { __typename?: 'AuthorizationCard' }
      & Pick<AuthorizationCard, 'id' | 'createdAt' | 'language' | 'additionalFields'>
    )> }
  ) }
);

type OnboardingTasksQueryVariables = Exact<{
  groupId: Scalars['ID'];
}>;


type OnboardingTasksQuery = (
  { __typename?: 'Query' }
  & { group: (
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
    & { onboardingTasks?: Maybe<Array<(
      { __typename?: 'ScheduledTask' }
      & Pick<ScheduledTask, 'id' | 'note'>
      & { task: (
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'title' | 'description'>
      ) }
    )>> }
  ) }
);

type OrganizerQueryVariables = Exact<{
  organizerId: Scalars['ID'];
}>;


type OrganizerQuery = (
  { __typename?: 'Query' }
  & { organizer: (
    { __typename?: 'Organizer' }
    & Pick<Organizer, 'id' | 'createdAt' | 'userId' | 'partnerId'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
      & { profilePic: (
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'id' | 'url'>
      ), organizingGroups: Array<(
        { __typename?: 'Group' }
        & Pick<Group, 'id' | 'name'>
      )>, currentOnboardings?: Maybe<Array<(
        { __typename?: 'Onboarding' }
        & Pick<Onboarding, 'id' | 'groupId'>
        & { group: (
          { __typename?: 'Group' }
          & Pick<Group, 'id' | 'name'>
        ) }
      )>> }
    ) }
  ) }
);

type SignAuthCardExternalMutationVariables = Exact<{
  input: SignAuthorizationCardExternalInput;
}>;


type SignAuthCardExternalMutation = (
  { __typename?: 'Mutation' }
  & { signAuthorizationCardExternal: (
    { __typename?: 'Onboarding' }
    & Pick<Onboarding, 'id' | 'token' | 'workflowState'>
    & { onboardingCache: Array<(
      { __typename?: 'OnboardingCacheItem' }
      & Pick<OnboardingCacheItem, 'key' | 'value' | 'boolValue'>
    )> }
  ) }
);

type VerifyEmailFromAuthCardMutationVariables = Exact<{
  onboardingId: Scalars['ID'];
}>;


type VerifyEmailFromAuthCardMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmailFromAuthCard: (
    { __typename?: 'VerifyEmailFromAuthCard' }
    & Pick<VerifyEmailFromAuthCard, 'email' | 'name' | 'partnerName'>
    & { onboarding: (
      { __typename?: 'Onboarding' }
      & Pick<Onboarding, 'id' | 'workflowState' | 'token'>
      & { onboardingCache: Array<(
        { __typename?: 'OnboardingCacheItem' }
        & Pick<OnboardingCacheItem, 'key' | 'value' | 'boolValue'>
      )> }
    ) }
  ) }
);

type EmailForOnboardingQueryVariables = Exact<{
  onboardingId?: Maybe<Scalars['ID']>;
}>;


type EmailForOnboardingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'emailForOnboarding'>
);

type ArchiveTopicMutationVariables = Exact<{
  topicId: Scalars['ID'];
}>;


type ArchiveTopicMutation = (
  { __typename?: 'Mutation' }
  & { archiveTopic: (
    { __typename?: 'NothingDTO' }
    & Pick<NothingDto, 'nothing'>
  ) }
);


export const DeleteAttachmentDocument = gql`
    mutation DeleteAttachment($attachmentId: ID!) {
  deleteAttachment(attachmentId: $attachmentId) {
    nothing
  }
}
    `;
export type DeleteAttachmentMutationFn = Apollo.MutationFunction<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(DeleteAttachmentDocument, options);
      }
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = Apollo.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;
export const CreateOrUpdateCustomFormResponseDocument = gql`
    mutation CreateOrUpdateCustomFormResponse($input: CreateOrUpdateCustomFormResponseInput!) {
  createOrUpdateFormResponse(input: $input) {
    error
    fieldErrors {
      customFormFieldId
      error
    }
    status
    responseData {
      id
    }
  }
}
    `;
export type CreateOrUpdateCustomFormResponseMutationFn = Apollo.MutationFunction<CreateOrUpdateCustomFormResponseMutation, CreateOrUpdateCustomFormResponseMutationVariables>;

/**
 * __useCreateOrUpdateCustomFormResponseMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCustomFormResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCustomFormResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCustomFormResponseMutation, { data, loading, error }] = useCreateOrUpdateCustomFormResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateCustomFormResponseMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCustomFormResponseMutation, CreateOrUpdateCustomFormResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCustomFormResponseMutation, CreateOrUpdateCustomFormResponseMutationVariables>(CreateOrUpdateCustomFormResponseDocument, options);
      }
export type CreateOrUpdateCustomFormResponseMutationHookResult = ReturnType<typeof useCreateOrUpdateCustomFormResponseMutation>;
export type CreateOrUpdateCustomFormResponseMutationResult = Apollo.MutationResult<CreateOrUpdateCustomFormResponseMutation>;
export type CreateOrUpdateCustomFormResponseMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCustomFormResponseMutation, CreateOrUpdateCustomFormResponseMutationVariables>;
export const CreateOrUpdateDataFieldTableRowsDocument = gql`
    mutation CreateOrUpdateDataFieldTableRows($input: CreateOrUpdateDataFieldTableRowSetInput!) {
  createOrUpdateDataFieldTableRows(input: $input) {
    results {
      fieldErrors {
        dataFieldId
        error
      }
    }
    status
  }
}
    `;
export type CreateOrUpdateDataFieldTableRowsMutationFn = Apollo.MutationFunction<CreateOrUpdateDataFieldTableRowsMutation, CreateOrUpdateDataFieldTableRowsMutationVariables>;

/**
 * __useCreateOrUpdateDataFieldTableRowsMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateDataFieldTableRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateDataFieldTableRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateDataFieldTableRowsMutation, { data, loading, error }] = useCreateOrUpdateDataFieldTableRowsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateDataFieldTableRowsMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateDataFieldTableRowsMutation, CreateOrUpdateDataFieldTableRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateDataFieldTableRowsMutation, CreateOrUpdateDataFieldTableRowsMutationVariables>(CreateOrUpdateDataFieldTableRowsDocument, options);
      }
export type CreateOrUpdateDataFieldTableRowsMutationHookResult = ReturnType<typeof useCreateOrUpdateDataFieldTableRowsMutation>;
export type CreateOrUpdateDataFieldTableRowsMutationResult = Apollo.MutationResult<CreateOrUpdateDataFieldTableRowsMutation>;
export type CreateOrUpdateDataFieldTableRowsMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateDataFieldTableRowsMutation, CreateOrUpdateDataFieldTableRowsMutationVariables>;
export const DeleteFormResponseDraftDocument = gql`
    mutation DeleteFormResponseDraft($formResponseId: ID!) {
  deleteFormResponseDraft(formResponseId: $formResponseId) {
    nothing
  }
}
    `;
export type DeleteFormResponseDraftMutationFn = Apollo.MutationFunction<DeleteFormResponseDraftMutation, DeleteFormResponseDraftMutationVariables>;

/**
 * __useDeleteFormResponseDraftMutation__
 *
 * To run a mutation, you first call `useDeleteFormResponseDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormResponseDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormResponseDraftMutation, { data, loading, error }] = useDeleteFormResponseDraftMutation({
 *   variables: {
 *      formResponseId: // value for 'formResponseId'
 *   },
 * });
 */
export function useDeleteFormResponseDraftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormResponseDraftMutation, DeleteFormResponseDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormResponseDraftMutation, DeleteFormResponseDraftMutationVariables>(DeleteFormResponseDraftDocument, options);
      }
export type DeleteFormResponseDraftMutationHookResult = ReturnType<typeof useDeleteFormResponseDraftMutation>;
export type DeleteFormResponseDraftMutationResult = Apollo.MutationResult<DeleteFormResponseDraftMutation>;
export type DeleteFormResponseDraftMutationOptions = Apollo.BaseMutationOptions<DeleteFormResponseDraftMutation, DeleteFormResponseDraftMutationVariables>;
export const CustomFormAndSectionsAndFieldsDocument = gql`
    query CustomFormAndSectionsAndFields($customFormId: ID!) {
  customForm(customFormId: $customFormId) {
    id
    title
    description
    allowMultipleSubmissions
    customFormSections {
      id
      title
      instructions
      order
    }
    customFormFields {
      id
      customFormSectionId
      dataFieldId
      required
      order
      dataField {
        id
        type
        label
        tooltip
        config
      }
    }
  }
}
    `;

/**
 * __useCustomFormAndSectionsAndFieldsQuery__
 *
 * To run a query within a React component, call `useCustomFormAndSectionsAndFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFormAndSectionsAndFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFormAndSectionsAndFieldsQuery({
 *   variables: {
 *      customFormId: // value for 'customFormId'
 *   },
 * });
 */
export function useCustomFormAndSectionsAndFieldsQuery(baseOptions: Apollo.QueryHookOptions<CustomFormAndSectionsAndFieldsQuery, CustomFormAndSectionsAndFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomFormAndSectionsAndFieldsQuery, CustomFormAndSectionsAndFieldsQueryVariables>(CustomFormAndSectionsAndFieldsDocument, options);
      }
export function useCustomFormAndSectionsAndFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomFormAndSectionsAndFieldsQuery, CustomFormAndSectionsAndFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomFormAndSectionsAndFieldsQuery, CustomFormAndSectionsAndFieldsQueryVariables>(CustomFormAndSectionsAndFieldsDocument, options);
        }
export type CustomFormAndSectionsAndFieldsQueryHookResult = ReturnType<typeof useCustomFormAndSectionsAndFieldsQuery>;
export type CustomFormAndSectionsAndFieldsLazyQueryHookResult = ReturnType<typeof useCustomFormAndSectionsAndFieldsLazyQuery>;
export type CustomFormAndSectionsAndFieldsQueryResult = Apollo.QueryResult<CustomFormAndSectionsAndFieldsQuery, CustomFormAndSectionsAndFieldsQueryVariables>;
export function refetchCustomFormAndSectionsAndFieldsQuery(variables: CustomFormAndSectionsAndFieldsQueryVariables) {
      return { query: CustomFormAndSectionsAndFieldsDocument, variables: variables }
    }
export const CustomFormResponseDocument = gql`
    query CustomFormResponse($formResponseId: ID) {
  customFormResponse(formResponseId: $formResponseId) {
    id
    submittedAt
    notes
    dataFieldResponses {
      id
      dataFieldId
      data
    }
  }
}
    `;

/**
 * __useCustomFormResponseQuery__
 *
 * To run a query within a React component, call `useCustomFormResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFormResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFormResponseQuery({
 *   variables: {
 *      formResponseId: // value for 'formResponseId'
 *   },
 * });
 */
export function useCustomFormResponseQuery(baseOptions?: Apollo.QueryHookOptions<CustomFormResponseQuery, CustomFormResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomFormResponseQuery, CustomFormResponseQueryVariables>(CustomFormResponseDocument, options);
      }
export function useCustomFormResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomFormResponseQuery, CustomFormResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomFormResponseQuery, CustomFormResponseQueryVariables>(CustomFormResponseDocument, options);
        }
export type CustomFormResponseQueryHookResult = ReturnType<typeof useCustomFormResponseQuery>;
export type CustomFormResponseLazyQueryHookResult = ReturnType<typeof useCustomFormResponseLazyQuery>;
export type CustomFormResponseQueryResult = Apollo.QueryResult<CustomFormResponseQuery, CustomFormResponseQueryVariables>;
export function refetchCustomFormResponseQuery(variables?: CustomFormResponseQueryVariables) {
      return { query: CustomFormResponseDocument, variables: variables }
    }
export const CustomFormsByCoworkerDocument = gql`
    query CustomFormsByCoworker($coworkerId: ID!, $pagination: PaginationArgumentDTO!) {
  customFormsByCoworker(coworkerId: $coworkerId, pagination: $pagination) {
    hasNext
    objects {
      id
      title
      description
      allowMultipleSubmissions
      customFormResponses {
        id
        submittedAt
      }
    }
    total
  }
}
    `;

/**
 * __useCustomFormsByCoworkerQuery__
 *
 * To run a query within a React component, call `useCustomFormsByCoworkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFormsByCoworkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFormsByCoworkerQuery({
 *   variables: {
 *      coworkerId: // value for 'coworkerId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCustomFormsByCoworkerQuery(baseOptions: Apollo.QueryHookOptions<CustomFormsByCoworkerQuery, CustomFormsByCoworkerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomFormsByCoworkerQuery, CustomFormsByCoworkerQueryVariables>(CustomFormsByCoworkerDocument, options);
      }
export function useCustomFormsByCoworkerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomFormsByCoworkerQuery, CustomFormsByCoworkerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomFormsByCoworkerQuery, CustomFormsByCoworkerQueryVariables>(CustomFormsByCoworkerDocument, options);
        }
export type CustomFormsByCoworkerQueryHookResult = ReturnType<typeof useCustomFormsByCoworkerQuery>;
export type CustomFormsByCoworkerLazyQueryHookResult = ReturnType<typeof useCustomFormsByCoworkerLazyQuery>;
export type CustomFormsByCoworkerQueryResult = Apollo.QueryResult<CustomFormsByCoworkerQuery, CustomFormsByCoworkerQueryVariables>;
export function refetchCustomFormsByCoworkerQuery(variables: CustomFormsByCoworkerQueryVariables) {
      return { query: CustomFormsByCoworkerDocument, variables: variables }
    }
export const DataFieldTableAndDataFieldsDocument = gql`
    query DataFieldTableAndDataFields($dataFieldTableId: ID!) {
  dataFieldTable(dataFieldTableId: $dataFieldTableId) {
    id
    dataFields {
      required
      order
      dataField {
        id
        type
        label
        tooltip
        config
      }
    }
  }
}
    `;

/**
 * __useDataFieldTableAndDataFieldsQuery__
 *
 * To run a query within a React component, call `useDataFieldTableAndDataFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataFieldTableAndDataFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataFieldTableAndDataFieldsQuery({
 *   variables: {
 *      dataFieldTableId: // value for 'dataFieldTableId'
 *   },
 * });
 */
export function useDataFieldTableAndDataFieldsQuery(baseOptions: Apollo.QueryHookOptions<DataFieldTableAndDataFieldsQuery, DataFieldTableAndDataFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataFieldTableAndDataFieldsQuery, DataFieldTableAndDataFieldsQueryVariables>(DataFieldTableAndDataFieldsDocument, options);
      }
export function useDataFieldTableAndDataFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataFieldTableAndDataFieldsQuery, DataFieldTableAndDataFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataFieldTableAndDataFieldsQuery, DataFieldTableAndDataFieldsQueryVariables>(DataFieldTableAndDataFieldsDocument, options);
        }
export type DataFieldTableAndDataFieldsQueryHookResult = ReturnType<typeof useDataFieldTableAndDataFieldsQuery>;
export type DataFieldTableAndDataFieldsLazyQueryHookResult = ReturnType<typeof useDataFieldTableAndDataFieldsLazyQuery>;
export type DataFieldTableAndDataFieldsQueryResult = Apollo.QueryResult<DataFieldTableAndDataFieldsQuery, DataFieldTableAndDataFieldsQueryVariables>;
export function refetchDataFieldTableAndDataFieldsQuery(variables: DataFieldTableAndDataFieldsQueryVariables) {
      return { query: DataFieldTableAndDataFieldsDocument, variables: variables }
    }
export const DataFieldTableRowsByRowSetIdDocument = gql`
    query DataFieldTableRowsByRowSetId($dataFieldTableId: ID!, $rowSetId: ID!) {
  dataFieldTableRowsByRowSetId(
    dataFieldTableId: $dataFieldTableId
    rowSetId: $rowSetId
  ) {
    id
    dataFieldResponses {
      id
      dataFieldId
      data
    }
  }
}
    `;

/**
 * __useDataFieldTableRowsByRowSetIdQuery__
 *
 * To run a query within a React component, call `useDataFieldTableRowsByRowSetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataFieldTableRowsByRowSetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataFieldTableRowsByRowSetIdQuery({
 *   variables: {
 *      dataFieldTableId: // value for 'dataFieldTableId'
 *      rowSetId: // value for 'rowSetId'
 *   },
 * });
 */
export function useDataFieldTableRowsByRowSetIdQuery(baseOptions: Apollo.QueryHookOptions<DataFieldTableRowsByRowSetIdQuery, DataFieldTableRowsByRowSetIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataFieldTableRowsByRowSetIdQuery, DataFieldTableRowsByRowSetIdQueryVariables>(DataFieldTableRowsByRowSetIdDocument, options);
      }
export function useDataFieldTableRowsByRowSetIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataFieldTableRowsByRowSetIdQuery, DataFieldTableRowsByRowSetIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataFieldTableRowsByRowSetIdQuery, DataFieldTableRowsByRowSetIdQueryVariables>(DataFieldTableRowsByRowSetIdDocument, options);
        }
export type DataFieldTableRowsByRowSetIdQueryHookResult = ReturnType<typeof useDataFieldTableRowsByRowSetIdQuery>;
export type DataFieldTableRowsByRowSetIdLazyQueryHookResult = ReturnType<typeof useDataFieldTableRowsByRowSetIdLazyQuery>;
export type DataFieldTableRowsByRowSetIdQueryResult = Apollo.QueryResult<DataFieldTableRowsByRowSetIdQuery, DataFieldTableRowsByRowSetIdQueryVariables>;
export function refetchDataFieldTableRowsByRowSetIdQuery(variables: DataFieldTableRowsByRowSetIdQueryVariables) {
      return { query: DataFieldTableRowsByRowSetIdDocument, variables: variables }
    }
export const ActionsForCoworkerDocument = gql`
    query ActionsForCoworker($coworkerId: ID!) {
  coworkerActions(coworkerId: $coworkerId) {
    type
    task {
      id
      title
      description
      assignmentId
      assignmentWorkflowState
      dueDate
      dateAssigned
      createdAt
    }
    customForm {
      id
      title
      createdAt
    }
    authCard {
      id
      language
      createdAt
    }
    membershipRequests {
      id
    }
  }
}
    `;

/**
 * __useActionsForCoworkerQuery__
 *
 * To run a query within a React component, call `useActionsForCoworkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionsForCoworkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionsForCoworkerQuery({
 *   variables: {
 *      coworkerId: // value for 'coworkerId'
 *   },
 * });
 */
export function useActionsForCoworkerQuery(baseOptions: Apollo.QueryHookOptions<ActionsForCoworkerQuery, ActionsForCoworkerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionsForCoworkerQuery, ActionsForCoworkerQueryVariables>(ActionsForCoworkerDocument, options);
      }
export function useActionsForCoworkerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionsForCoworkerQuery, ActionsForCoworkerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionsForCoworkerQuery, ActionsForCoworkerQueryVariables>(ActionsForCoworkerDocument, options);
        }
export type ActionsForCoworkerQueryHookResult = ReturnType<typeof useActionsForCoworkerQuery>;
export type ActionsForCoworkerLazyQueryHookResult = ReturnType<typeof useActionsForCoworkerLazyQuery>;
export type ActionsForCoworkerQueryResult = Apollo.QueryResult<ActionsForCoworkerQuery, ActionsForCoworkerQueryVariables>;
export function refetchActionsForCoworkerQuery(variables: ActionsForCoworkerQueryVariables) {
      return { query: ActionsForCoworkerDocument, variables: variables }
    }
export const DocumentsForCoworkerDocument = gql`
    query DocumentsForCoworker($coworkerId: ID!) {
  coworkerDocuments(coworkerId: $coworkerId) {
    type
    customFormResponse {
      id
      submittedAt
      updatedAt
      customFormId
      customFormTitle
    }
    authCardSignature {
      id
      submittedAt
      authCardId
    }
    petition {
      id
      title
      publishedAt
    }
    resource {
      id
      title
      url
      contentType
      publishedAt
      thumbnailUrl
    }
  }
}
    `;

/**
 * __useDocumentsForCoworkerQuery__
 *
 * To run a query within a React component, call `useDocumentsForCoworkerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsForCoworkerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsForCoworkerQuery({
 *   variables: {
 *      coworkerId: // value for 'coworkerId'
 *   },
 * });
 */
export function useDocumentsForCoworkerQuery(baseOptions: Apollo.QueryHookOptions<DocumentsForCoworkerQuery, DocumentsForCoworkerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsForCoworkerQuery, DocumentsForCoworkerQueryVariables>(DocumentsForCoworkerDocument, options);
      }
export function useDocumentsForCoworkerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsForCoworkerQuery, DocumentsForCoworkerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsForCoworkerQuery, DocumentsForCoworkerQueryVariables>(DocumentsForCoworkerDocument, options);
        }
export type DocumentsForCoworkerQueryHookResult = ReturnType<typeof useDocumentsForCoworkerQuery>;
export type DocumentsForCoworkerLazyQueryHookResult = ReturnType<typeof useDocumentsForCoworkerLazyQuery>;
export type DocumentsForCoworkerQueryResult = Apollo.QueryResult<DocumentsForCoworkerQuery, DocumentsForCoworkerQueryVariables>;
export function refetchDocumentsForCoworkerQuery(variables: DocumentsForCoworkerQueryVariables) {
      return { query: DocumentsForCoworkerDocument, variables: variables }
    }
export const AddFormToGroupDocument = gql`
    mutation AddFormToGroup($customFormId: ID!, $groupId: ID!, $visibleToWorkers: Boolean!) {
  addFormToGroup(
    customFormId: $customFormId
    groupId: $groupId
    visibleToWorkers: $visibleToWorkers
  ) {
    nothing
  }
}
    `;
export type AddFormToGroupMutationFn = Apollo.MutationFunction<AddFormToGroupMutation, AddFormToGroupMutationVariables>;

/**
 * __useAddFormToGroupMutation__
 *
 * To run a mutation, you first call `useAddFormToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFormToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFormToGroupMutation, { data, loading, error }] = useAddFormToGroupMutation({
 *   variables: {
 *      customFormId: // value for 'customFormId'
 *      groupId: // value for 'groupId'
 *      visibleToWorkers: // value for 'visibleToWorkers'
 *   },
 * });
 */
export function useAddFormToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddFormToGroupMutation, AddFormToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFormToGroupMutation, AddFormToGroupMutationVariables>(AddFormToGroupDocument, options);
      }
export type AddFormToGroupMutationHookResult = ReturnType<typeof useAddFormToGroupMutation>;
export type AddFormToGroupMutationResult = Apollo.MutationResult<AddFormToGroupMutation>;
export type AddFormToGroupMutationOptions = Apollo.BaseMutationOptions<AddFormToGroupMutation, AddFormToGroupMutationVariables>;
export const AddOrganizersToGroupsDocument = gql`
    mutation AddOrganizersToGroups($groupIds: [ID!]!, $userIds: [ID!]!) {
  addOrganizersToGroups(groupIds: $groupIds, userIds: $userIds) {
    nothing
  }
}
    `;
export type AddOrganizersToGroupsMutationFn = Apollo.MutationFunction<AddOrganizersToGroupsMutation, AddOrganizersToGroupsMutationVariables>;

/**
 * __useAddOrganizersToGroupsMutation__
 *
 * To run a mutation, you first call `useAddOrganizersToGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizersToGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizersToGroupsMutation, { data, loading, error }] = useAddOrganizersToGroupsMutation({
 *   variables: {
 *      groupIds: // value for 'groupIds'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useAddOrganizersToGroupsMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizersToGroupsMutation, AddOrganizersToGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizersToGroupsMutation, AddOrganizersToGroupsMutationVariables>(AddOrganizersToGroupsDocument, options);
      }
export type AddOrganizersToGroupsMutationHookResult = ReturnType<typeof useAddOrganizersToGroupsMutation>;
export type AddOrganizersToGroupsMutationResult = Apollo.MutationResult<AddOrganizersToGroupsMutation>;
export type AddOrganizersToGroupsMutationOptions = Apollo.BaseMutationOptions<AddOrganizersToGroupsMutation, AddOrganizersToGroupsMutationVariables>;
export const AssessCoworkerDocument = gql`
    mutation AssessCoworker($input: AssessCoworkerInput!) {
  assessCoworker(input: $input) {
    id
    assessmentOption {
      id
      value
      label
    }
  }
}
    `;
export type AssessCoworkerMutationFn = Apollo.MutationFunction<AssessCoworkerMutation, AssessCoworkerMutationVariables>;

/**
 * __useAssessCoworkerMutation__
 *
 * To run a mutation, you first call `useAssessCoworkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssessCoworkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assessCoworkerMutation, { data, loading, error }] = useAssessCoworkerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssessCoworkerMutation(baseOptions?: Apollo.MutationHookOptions<AssessCoworkerMutation, AssessCoworkerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssessCoworkerMutation, AssessCoworkerMutationVariables>(AssessCoworkerDocument, options);
      }
export type AssessCoworkerMutationHookResult = ReturnType<typeof useAssessCoworkerMutation>;
export type AssessCoworkerMutationResult = Apollo.MutationResult<AssessCoworkerMutation>;
export type AssessCoworkerMutationOptions = Apollo.BaseMutationOptions<AssessCoworkerMutation, AssessCoworkerMutationVariables>;
export const ConfirmAuthCardDocument = gql`
    mutation ConfirmAuthCard($signatureId: ID!) {
  confirmAuthorizationCard(signatureId: $signatureId) {
    nothing
  }
}
    `;
export type ConfirmAuthCardMutationFn = Apollo.MutationFunction<ConfirmAuthCardMutation, ConfirmAuthCardMutationVariables>;

/**
 * __useConfirmAuthCardMutation__
 *
 * To run a mutation, you first call `useConfirmAuthCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAuthCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAuthCardMutation, { data, loading, error }] = useConfirmAuthCardMutation({
 *   variables: {
 *      signatureId: // value for 'signatureId'
 *   },
 * });
 */
export function useConfirmAuthCardMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmAuthCardMutation, ConfirmAuthCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmAuthCardMutation, ConfirmAuthCardMutationVariables>(ConfirmAuthCardDocument, options);
      }
export type ConfirmAuthCardMutationHookResult = ReturnType<typeof useConfirmAuthCardMutation>;
export type ConfirmAuthCardMutationResult = Apollo.MutationResult<ConfirmAuthCardMutation>;
export type ConfirmAuthCardMutationOptions = Apollo.BaseMutationOptions<ConfirmAuthCardMutation, ConfirmAuthCardMutationVariables>;
export const ConvertAccountDocument = gql`
    mutation ConvertAccount($input: ConvertAccountInput!) {
  convertAccount(input: $input) {
    nothing
  }
}
    `;
export type ConvertAccountMutationFn = Apollo.MutationFunction<ConvertAccountMutation, ConvertAccountMutationVariables>;

/**
 * __useConvertAccountMutation__
 *
 * To run a mutation, you first call `useConvertAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertAccountMutation, { data, loading, error }] = useConvertAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertAccountMutation(baseOptions?: Apollo.MutationHookOptions<ConvertAccountMutation, ConvertAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertAccountMutation, ConvertAccountMutationVariables>(ConvertAccountDocument, options);
      }
export type ConvertAccountMutationHookResult = ReturnType<typeof useConvertAccountMutation>;
export type ConvertAccountMutationResult = Apollo.MutationResult<ConvertAccountMutation>;
export type ConvertAccountMutationOptions = Apollo.BaseMutationOptions<ConvertAccountMutation, ConvertAccountMutationVariables>;
export const CreateAccountFromAuthCardDocument = gql`
    mutation CreateAccountFromAuthCard($input: CreateAccountFromAuthCardInput!) {
  createAccountFromAuthCard(input: $input) {
    id
    token
    workflowState
  }
}
    `;
export type CreateAccountFromAuthCardMutationFn = Apollo.MutationFunction<CreateAccountFromAuthCardMutation, CreateAccountFromAuthCardMutationVariables>;

/**
 * __useCreateAccountFromAuthCardMutation__
 *
 * To run a mutation, you first call `useCreateAccountFromAuthCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountFromAuthCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountFromAuthCardMutation, { data, loading, error }] = useCreateAccountFromAuthCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountFromAuthCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountFromAuthCardMutation, CreateAccountFromAuthCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountFromAuthCardMutation, CreateAccountFromAuthCardMutationVariables>(CreateAccountFromAuthCardDocument, options);
      }
export type CreateAccountFromAuthCardMutationHookResult = ReturnType<typeof useCreateAccountFromAuthCardMutation>;
export type CreateAccountFromAuthCardMutationResult = Apollo.MutationResult<CreateAccountFromAuthCardMutation>;
export type CreateAccountFromAuthCardMutationOptions = Apollo.BaseMutationOptions<CreateAccountFromAuthCardMutation, CreateAccountFromAuthCardMutationVariables>;
export const CreateCustomResourceDocument = gql`
    mutation CreateCustomResource($input: CreateCustomResourceInput!) {
  createCustomResource(input: $input) {
    nothing
  }
}
    `;
export type CreateCustomResourceMutationFn = Apollo.MutationFunction<CreateCustomResourceMutation, CreateCustomResourceMutationVariables>;

/**
 * __useCreateCustomResourceMutation__
 *
 * To run a mutation, you first call `useCreateCustomResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomResourceMutation, { data, loading, error }] = useCreateCustomResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomResourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomResourceMutation, CreateCustomResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomResourceMutation, CreateCustomResourceMutationVariables>(CreateCustomResourceDocument, options);
      }
export type CreateCustomResourceMutationHookResult = ReturnType<typeof useCreateCustomResourceMutation>;
export type CreateCustomResourceMutationResult = Apollo.MutationResult<CreateCustomResourceMutation>;
export type CreateCustomResourceMutationOptions = Apollo.BaseMutationOptions<CreateCustomResourceMutation, CreateCustomResourceMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($groupId: ID!) {
  deleteGroup(groupId: $groupId) {
    nothing
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const AllResponsesForGroupDownloadTokenDocument = gql`
    mutation AllResponsesForGroupDownloadToken($groupId: ID!) {
  getAllResponsesForGroupDownloadToken(groupId: $groupId) {
    token
  }
}
    `;
export type AllResponsesForGroupDownloadTokenMutationFn = Apollo.MutationFunction<AllResponsesForGroupDownloadTokenMutation, AllResponsesForGroupDownloadTokenMutationVariables>;

/**
 * __useAllResponsesForGroupDownloadTokenMutation__
 *
 * To run a mutation, you first call `useAllResponsesForGroupDownloadTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllResponsesForGroupDownloadTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allResponsesForGroupDownloadTokenMutation, { data, loading, error }] = useAllResponsesForGroupDownloadTokenMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useAllResponsesForGroupDownloadTokenMutation(baseOptions?: Apollo.MutationHookOptions<AllResponsesForGroupDownloadTokenMutation, AllResponsesForGroupDownloadTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AllResponsesForGroupDownloadTokenMutation, AllResponsesForGroupDownloadTokenMutationVariables>(AllResponsesForGroupDownloadTokenDocument, options);
      }
export type AllResponsesForGroupDownloadTokenMutationHookResult = ReturnType<typeof useAllResponsesForGroupDownloadTokenMutation>;
export type AllResponsesForGroupDownloadTokenMutationResult = Apollo.MutationResult<AllResponsesForGroupDownloadTokenMutation>;
export type AllResponsesForGroupDownloadTokenMutationOptions = Apollo.BaseMutationOptions<AllResponsesForGroupDownloadTokenMutation, AllResponsesForGroupDownloadTokenMutationVariables>;
export const DownloadCustomFormResponseDocument = gql`
    mutation DownloadCustomFormResponse($customFormResponseId: ID!) {
  getCustomFormResponseDownloadToken(customFormResponseId: $customFormResponseId) {
    token
  }
}
    `;
export type DownloadCustomFormResponseMutationFn = Apollo.MutationFunction<DownloadCustomFormResponseMutation, DownloadCustomFormResponseMutationVariables>;

/**
 * __useDownloadCustomFormResponseMutation__
 *
 * To run a mutation, you first call `useDownloadCustomFormResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadCustomFormResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadCustomFormResponseMutation, { data, loading, error }] = useDownloadCustomFormResponseMutation({
 *   variables: {
 *      customFormResponseId: // value for 'customFormResponseId'
 *   },
 * });
 */
export function useDownloadCustomFormResponseMutation(baseOptions?: Apollo.MutationHookOptions<DownloadCustomFormResponseMutation, DownloadCustomFormResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadCustomFormResponseMutation, DownloadCustomFormResponseMutationVariables>(DownloadCustomFormResponseDocument, options);
      }
export type DownloadCustomFormResponseMutationHookResult = ReturnType<typeof useDownloadCustomFormResponseMutation>;
export type DownloadCustomFormResponseMutationResult = Apollo.MutationResult<DownloadCustomFormResponseMutation>;
export type DownloadCustomFormResponseMutationOptions = Apollo.BaseMutationOptions<DownloadCustomFormResponseMutation, DownloadCustomFormResponseMutationVariables>;
export const FinishOrganizerOnboardingDocument = gql`
    mutation FinishOrganizerOnboarding {
  finishOrganizerOnboarding {
    nothing
  }
}
    `;
export type FinishOrganizerOnboardingMutationFn = Apollo.MutationFunction<FinishOrganizerOnboardingMutation, FinishOrganizerOnboardingMutationVariables>;

/**
 * __useFinishOrganizerOnboardingMutation__
 *
 * To run a mutation, you first call `useFinishOrganizerOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishOrganizerOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishOrganizerOnboardingMutation, { data, loading, error }] = useFinishOrganizerOnboardingMutation({
 *   variables: {
 *   },
 * });
 */
export function useFinishOrganizerOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<FinishOrganizerOnboardingMutation, FinishOrganizerOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishOrganizerOnboardingMutation, FinishOrganizerOnboardingMutationVariables>(FinishOrganizerOnboardingDocument, options);
      }
export type FinishOrganizerOnboardingMutationHookResult = ReturnType<typeof useFinishOrganizerOnboardingMutation>;
export type FinishOrganizerOnboardingMutationResult = Apollo.MutationResult<FinishOrganizerOnboardingMutation>;
export type FinishOrganizerOnboardingMutationOptions = Apollo.BaseMutationOptions<FinishOrganizerOnboardingMutation, FinishOrganizerOnboardingMutationVariables>;
export const RemoveCustomResourceDocument = gql`
    mutation RemoveCustomResource($attachmentId: ID!, $groupId: ID) {
  removeCustomResource(attachmentId: $attachmentId, groupId: $groupId) {
    nothing
  }
}
    `;
export type RemoveCustomResourceMutationFn = Apollo.MutationFunction<RemoveCustomResourceMutation, RemoveCustomResourceMutationVariables>;

/**
 * __useRemoveCustomResourceMutation__
 *
 * To run a mutation, you first call `useRemoveCustomResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomResourceMutation, { data, loading, error }] = useRemoveCustomResourceMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useRemoveCustomResourceMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCustomResourceMutation, RemoveCustomResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCustomResourceMutation, RemoveCustomResourceMutationVariables>(RemoveCustomResourceDocument, options);
      }
export type RemoveCustomResourceMutationHookResult = ReturnType<typeof useRemoveCustomResourceMutation>;
export type RemoveCustomResourceMutationResult = Apollo.MutationResult<RemoveCustomResourceMutation>;
export type RemoveCustomResourceMutationOptions = Apollo.BaseMutationOptions<RemoveCustomResourceMutation, RemoveCustomResourceMutationVariables>;
export const RemoveOrganizersFromGroupDocument = gql`
    mutation RemoveOrganizersFromGroup($userIds: [ID!]!, $groupId: ID!) {
  removeOrganizersFromGroup(userIds: $userIds, groupId: $groupId) {
    nothing
  }
}
    `;
export type RemoveOrganizersFromGroupMutationFn = Apollo.MutationFunction<RemoveOrganizersFromGroupMutation, RemoveOrganizersFromGroupMutationVariables>;

/**
 * __useRemoveOrganizersFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizersFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizersFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizersFromGroupMutation, { data, loading, error }] = useRemoveOrganizersFromGroupMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useRemoveOrganizersFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganizersFromGroupMutation, RemoveOrganizersFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrganizersFromGroupMutation, RemoveOrganizersFromGroupMutationVariables>(RemoveOrganizersFromGroupDocument, options);
      }
export type RemoveOrganizersFromGroupMutationHookResult = ReturnType<typeof useRemoveOrganizersFromGroupMutation>;
export type RemoveOrganizersFromGroupMutationResult = Apollo.MutationResult<RemoveOrganizersFromGroupMutation>;
export type RemoveOrganizersFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveOrganizersFromGroupMutation, RemoveOrganizersFromGroupMutationVariables>;
export const RemoveWorkerFromGroupDocument = gql`
    mutation RemoveWorkerFromGroup($coworkerId: ID!) {
  removeWorkerFromGroup(coworkerId: $coworkerId) {
    nothing
  }
}
    `;
export type RemoveWorkerFromGroupMutationFn = Apollo.MutationFunction<RemoveWorkerFromGroupMutation, RemoveWorkerFromGroupMutationVariables>;

/**
 * __useRemoveWorkerFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveWorkerFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkerFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkerFromGroupMutation, { data, loading, error }] = useRemoveWorkerFromGroupMutation({
 *   variables: {
 *      coworkerId: // value for 'coworkerId'
 *   },
 * });
 */
export function useRemoveWorkerFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWorkerFromGroupMutation, RemoveWorkerFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWorkerFromGroupMutation, RemoveWorkerFromGroupMutationVariables>(RemoveWorkerFromGroupDocument, options);
      }
export type RemoveWorkerFromGroupMutationHookResult = ReturnType<typeof useRemoveWorkerFromGroupMutation>;
export type RemoveWorkerFromGroupMutationResult = Apollo.MutationResult<RemoveWorkerFromGroupMutation>;
export type RemoveWorkerFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveWorkerFromGroupMutation, RemoveWorkerFromGroupMutationVariables>;
export const ResendConfirmAuthCardDocument = gql`
    mutation ResendConfirmAuthCard($coworkerId: ID!) {
  resendConfirmAuthCardEmail(coworkerId: $coworkerId) {
    nothing
  }
}
    `;
export type ResendConfirmAuthCardMutationFn = Apollo.MutationFunction<ResendConfirmAuthCardMutation, ResendConfirmAuthCardMutationVariables>;

/**
 * __useResendConfirmAuthCardMutation__
 *
 * To run a mutation, you first call `useResendConfirmAuthCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmAuthCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmAuthCardMutation, { data, loading, error }] = useResendConfirmAuthCardMutation({
 *   variables: {
 *      coworkerId: // value for 'coworkerId'
 *   },
 * });
 */
export function useResendConfirmAuthCardMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmAuthCardMutation, ResendConfirmAuthCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmAuthCardMutation, ResendConfirmAuthCardMutationVariables>(ResendConfirmAuthCardDocument, options);
      }
export type ResendConfirmAuthCardMutationHookResult = ReturnType<typeof useResendConfirmAuthCardMutation>;
export type ResendConfirmAuthCardMutationResult = Apollo.MutationResult<ResendConfirmAuthCardMutation>;
export type ResendConfirmAuthCardMutationOptions = Apollo.BaseMutationOptions<ResendConfirmAuthCardMutation, ResendConfirmAuthCardMutationVariables>;
export const UpdateCustomFormResponseNotesDocument = gql`
    mutation UpdateCustomFormResponseNotes($customFormResponseId: ID!, $notes: String!) {
  updateCustomFormResponseNotes(
    customFormResponseId: $customFormResponseId
    notes: $notes
  ) {
    nothing
  }
}
    `;
export type UpdateCustomFormResponseNotesMutationFn = Apollo.MutationFunction<UpdateCustomFormResponseNotesMutation, UpdateCustomFormResponseNotesMutationVariables>;

/**
 * __useUpdateCustomFormResponseNotesMutation__
 *
 * To run a mutation, you first call `useUpdateCustomFormResponseNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomFormResponseNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomFormResponseNotesMutation, { data, loading, error }] = useUpdateCustomFormResponseNotesMutation({
 *   variables: {
 *      customFormResponseId: // value for 'customFormResponseId'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateCustomFormResponseNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomFormResponseNotesMutation, UpdateCustomFormResponseNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomFormResponseNotesMutation, UpdateCustomFormResponseNotesMutationVariables>(UpdateCustomFormResponseNotesDocument, options);
      }
export type UpdateCustomFormResponseNotesMutationHookResult = ReturnType<typeof useUpdateCustomFormResponseNotesMutation>;
export type UpdateCustomFormResponseNotesMutationResult = Apollo.MutationResult<UpdateCustomFormResponseNotesMutation>;
export type UpdateCustomFormResponseNotesMutationOptions = Apollo.BaseMutationOptions<UpdateCustomFormResponseNotesMutation, UpdateCustomFormResponseNotesMutationVariables>;
export const SendAuthCardDocument = gql`
    mutation SendAuthCard($input: SendAuthCardInput!) {
  sendAuthorizationCard(input: $input) {
    nothing
  }
}
    `;
export type SendAuthCardMutationFn = Apollo.MutationFunction<SendAuthCardMutation, SendAuthCardMutationVariables>;

/**
 * __useSendAuthCardMutation__
 *
 * To run a mutation, you first call `useSendAuthCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAuthCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAuthCardMutation, { data, loading, error }] = useSendAuthCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendAuthCardMutation(baseOptions?: Apollo.MutationHookOptions<SendAuthCardMutation, SendAuthCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAuthCardMutation, SendAuthCardMutationVariables>(SendAuthCardDocument, options);
      }
export type SendAuthCardMutationHookResult = ReturnType<typeof useSendAuthCardMutation>;
export type SendAuthCardMutationResult = Apollo.MutationResult<SendAuthCardMutation>;
export type SendAuthCardMutationOptions = Apollo.BaseMutationOptions<SendAuthCardMutation, SendAuthCardMutationVariables>;
export const AssessmentOptionsDocument = gql`
    query AssessmentOptions {
  assessmentOptions {
    id
    label
    value
    description
  }
}
    `;

/**
 * __useAssessmentOptionsQuery__
 *
 * To run a query within a React component, call `useAssessmentOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssessmentOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AssessmentOptionsQuery, AssessmentOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssessmentOptionsQuery, AssessmentOptionsQueryVariables>(AssessmentOptionsDocument, options);
      }
export function useAssessmentOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessmentOptionsQuery, AssessmentOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssessmentOptionsQuery, AssessmentOptionsQueryVariables>(AssessmentOptionsDocument, options);
        }
export type AssessmentOptionsQueryHookResult = ReturnType<typeof useAssessmentOptionsQuery>;
export type AssessmentOptionsLazyQueryHookResult = ReturnType<typeof useAssessmentOptionsLazyQuery>;
export type AssessmentOptionsQueryResult = Apollo.QueryResult<AssessmentOptionsQuery, AssessmentOptionsQueryVariables>;
export function refetchAssessmentOptionsQuery(variables?: AssessmentOptionsQueryVariables) {
      return { query: AssessmentOptionsDocument, variables: variables }
    }
export const AuthorizationCardSignatureByIdDocument = gql`
    query AuthorizationCardSignatureById($signatureId: ID!, $customFormId: ID) {
  authorizationCardSignatureById(
    signatureId: $signatureId
    customFormId: $customFormId
  ) {
    id
    firstName
    lastName
    createdAt
    employer
    email
    phone
    authCardFormResponseJSON
    authorizationLanguage
    signature
    authorizationCardId
    authorizationCard {
      id
    }
    coworkerId
  }
}
    `;

/**
 * __useAuthorizationCardSignatureByIdQuery__
 *
 * To run a query within a React component, call `useAuthorizationCardSignatureByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizationCardSignatureByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizationCardSignatureByIdQuery({
 *   variables: {
 *      signatureId: // value for 'signatureId'
 *      customFormId: // value for 'customFormId'
 *   },
 * });
 */
export function useAuthorizationCardSignatureByIdQuery(baseOptions: Apollo.QueryHookOptions<AuthorizationCardSignatureByIdQuery, AuthorizationCardSignatureByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthorizationCardSignatureByIdQuery, AuthorizationCardSignatureByIdQueryVariables>(AuthorizationCardSignatureByIdDocument, options);
      }
export function useAuthorizationCardSignatureByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthorizationCardSignatureByIdQuery, AuthorizationCardSignatureByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthorizationCardSignatureByIdQuery, AuthorizationCardSignatureByIdQueryVariables>(AuthorizationCardSignatureByIdDocument, options);
        }
export type AuthorizationCardSignatureByIdQueryHookResult = ReturnType<typeof useAuthorizationCardSignatureByIdQuery>;
export type AuthorizationCardSignatureByIdLazyQueryHookResult = ReturnType<typeof useAuthorizationCardSignatureByIdLazyQuery>;
export type AuthorizationCardSignatureByIdQueryResult = Apollo.QueryResult<AuthorizationCardSignatureByIdQuery, AuthorizationCardSignatureByIdQueryVariables>;
export function refetchAuthorizationCardSignatureByIdQuery(variables: AuthorizationCardSignatureByIdQueryVariables) {
      return { query: AuthorizationCardSignatureByIdDocument, variables: variables }
    }
export const AvailableCustomFormsByPartnerDocument = gql`
    query AvailableCustomFormsByPartner($partnerId: ID!, $groupId: ID!, $pagination: PaginationArgumentDTO!) {
  availableCustomFormsByPartner(
    partnerId: $partnerId
    groupId: $groupId
    pagination: $pagination
  ) {
    hasNext
    objects {
      id
      title
      description
    }
    total
  }
}
    `;

/**
 * __useAvailableCustomFormsByPartnerQuery__
 *
 * To run a query within a React component, call `useAvailableCustomFormsByPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCustomFormsByPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCustomFormsByPartnerQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      groupId: // value for 'groupId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAvailableCustomFormsByPartnerQuery(baseOptions: Apollo.QueryHookOptions<AvailableCustomFormsByPartnerQuery, AvailableCustomFormsByPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableCustomFormsByPartnerQuery, AvailableCustomFormsByPartnerQueryVariables>(AvailableCustomFormsByPartnerDocument, options);
      }
export function useAvailableCustomFormsByPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableCustomFormsByPartnerQuery, AvailableCustomFormsByPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableCustomFormsByPartnerQuery, AvailableCustomFormsByPartnerQueryVariables>(AvailableCustomFormsByPartnerDocument, options);
        }
export type AvailableCustomFormsByPartnerQueryHookResult = ReturnType<typeof useAvailableCustomFormsByPartnerQuery>;
export type AvailableCustomFormsByPartnerLazyQueryHookResult = ReturnType<typeof useAvailableCustomFormsByPartnerLazyQuery>;
export type AvailableCustomFormsByPartnerQueryResult = Apollo.QueryResult<AvailableCustomFormsByPartnerQuery, AvailableCustomFormsByPartnerQueryVariables>;
export function refetchAvailableCustomFormsByPartnerQuery(variables: AvailableCustomFormsByPartnerQueryVariables) {
      return { query: AvailableCustomFormsByPartnerDocument, variables: variables }
    }
export const CoworkerCustomFormResponseListsDocument = gql`
    query CoworkerCustomFormResponseLists($customFormId: ID!, $groupId: ID!, $pagination: PaginationArgumentDTO!, $coworkerId: ID) {
  getCoworkerCustomFormResponseLists(
    groupId: $groupId
    pagination: $pagination
    customFormId: $customFormId
    coworkerId: $coworkerId
  ) {
    objects {
      coworker {
        id
        name
        email
      }
      responses {
        id
        submittedAt
        notes
      }
    }
    hasNext
    total
  }
}
    `;

/**
 * __useCoworkerCustomFormResponseListsQuery__
 *
 * To run a query within a React component, call `useCoworkerCustomFormResponseListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoworkerCustomFormResponseListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoworkerCustomFormResponseListsQuery({
 *   variables: {
 *      customFormId: // value for 'customFormId'
 *      groupId: // value for 'groupId'
 *      pagination: // value for 'pagination'
 *      coworkerId: // value for 'coworkerId'
 *   },
 * });
 */
export function useCoworkerCustomFormResponseListsQuery(baseOptions: Apollo.QueryHookOptions<CoworkerCustomFormResponseListsQuery, CoworkerCustomFormResponseListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoworkerCustomFormResponseListsQuery, CoworkerCustomFormResponseListsQueryVariables>(CoworkerCustomFormResponseListsDocument, options);
      }
export function useCoworkerCustomFormResponseListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoworkerCustomFormResponseListsQuery, CoworkerCustomFormResponseListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoworkerCustomFormResponseListsQuery, CoworkerCustomFormResponseListsQueryVariables>(CoworkerCustomFormResponseListsDocument, options);
        }
export type CoworkerCustomFormResponseListsQueryHookResult = ReturnType<typeof useCoworkerCustomFormResponseListsQuery>;
export type CoworkerCustomFormResponseListsLazyQueryHookResult = ReturnType<typeof useCoworkerCustomFormResponseListsLazyQuery>;
export type CoworkerCustomFormResponseListsQueryResult = Apollo.QueryResult<CoworkerCustomFormResponseListsQuery, CoworkerCustomFormResponseListsQueryVariables>;
export function refetchCoworkerCustomFormResponseListsQuery(variables: CoworkerCustomFormResponseListsQueryVariables) {
      return { query: CoworkerCustomFormResponseListsDocument, variables: variables }
    }
export const CoworkersDocument = gql`
    query Coworkers($pagination: PaginationArgumentDTO!, $order: CoworkerOrderArgumentDTO, $filter: CoworkerFilterDTO!, $explicitGroupId: ID!) {
  coworkers(
    pagination: $pagination
    order: $order
    filter: $filter
    explicitGroupId: $explicitGroupId
  ) {
    hasNext
    total
    objects {
      id
      name
      email
      status
      user {
        id
      }
      assessment {
        value
        label
        id
        createdAt
        assessmentOption {
          id
        }
        ratedBy {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useCoworkersQuery__
 *
 * To run a query within a React component, call `useCoworkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoworkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoworkersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *      explicitGroupId: // value for 'explicitGroupId'
 *   },
 * });
 */
export function useCoworkersQuery(baseOptions: Apollo.QueryHookOptions<CoworkersQuery, CoworkersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoworkersQuery, CoworkersQueryVariables>(CoworkersDocument, options);
      }
export function useCoworkersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoworkersQuery, CoworkersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoworkersQuery, CoworkersQueryVariables>(CoworkersDocument, options);
        }
export type CoworkersQueryHookResult = ReturnType<typeof useCoworkersQuery>;
export type CoworkersLazyQueryHookResult = ReturnType<typeof useCoworkersLazyQuery>;
export type CoworkersQueryResult = Apollo.QueryResult<CoworkersQuery, CoworkersQueryVariables>;
export function refetchCoworkersQuery(variables: CoworkersQueryVariables) {
      return { query: CoworkersDocument, variables: variables }
    }
export const CoworkersYetToSubmitDocument = gql`
    query CoworkersYetToSubmit($customFormId: ID!, $groupId: ID!, $pagination: PaginationArgumentDTO!) {
  coworkersYetToSubmit(
    groupId: $groupId
    pagination: $pagination
    customFormId: $customFormId
  ) {
    objects {
      id
      name
      email
    }
    hasNext
    total
  }
}
    `;

/**
 * __useCoworkersYetToSubmitQuery__
 *
 * To run a query within a React component, call `useCoworkersYetToSubmitQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoworkersYetToSubmitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoworkersYetToSubmitQuery({
 *   variables: {
 *      customFormId: // value for 'customFormId'
 *      groupId: // value for 'groupId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCoworkersYetToSubmitQuery(baseOptions: Apollo.QueryHookOptions<CoworkersYetToSubmitQuery, CoworkersYetToSubmitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoworkersYetToSubmitQuery, CoworkersYetToSubmitQueryVariables>(CoworkersYetToSubmitDocument, options);
      }
export function useCoworkersYetToSubmitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoworkersYetToSubmitQuery, CoworkersYetToSubmitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoworkersYetToSubmitQuery, CoworkersYetToSubmitQueryVariables>(CoworkersYetToSubmitDocument, options);
        }
export type CoworkersYetToSubmitQueryHookResult = ReturnType<typeof useCoworkersYetToSubmitQuery>;
export type CoworkersYetToSubmitLazyQueryHookResult = ReturnType<typeof useCoworkersYetToSubmitLazyQuery>;
export type CoworkersYetToSubmitQueryResult = Apollo.QueryResult<CoworkersYetToSubmitQuery, CoworkersYetToSubmitQueryVariables>;
export function refetchCoworkersYetToSubmitQuery(variables: CoworkersYetToSubmitQueryVariables) {
      return { query: CoworkersYetToSubmitDocument, variables: variables }
    }
export const GetCustomFormDocument = gql`
    query GetCustomForm($customFormId: ID!) {
  customForm(customFormId: $customFormId) {
    id
    title
    createdAt
    allowMultipleSubmissions
  }
}
    `;

/**
 * __useGetCustomFormQuery__
 *
 * To run a query within a React component, call `useGetCustomFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFormQuery({
 *   variables: {
 *      customFormId: // value for 'customFormId'
 *   },
 * });
 */
export function useGetCustomFormQuery(baseOptions: Apollo.QueryHookOptions<GetCustomFormQuery, GetCustomFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomFormQuery, GetCustomFormQueryVariables>(GetCustomFormDocument, options);
      }
export function useGetCustomFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFormQuery, GetCustomFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomFormQuery, GetCustomFormQueryVariables>(GetCustomFormDocument, options);
        }
export type GetCustomFormQueryHookResult = ReturnType<typeof useGetCustomFormQuery>;
export type GetCustomFormLazyQueryHookResult = ReturnType<typeof useGetCustomFormLazyQuery>;
export type GetCustomFormQueryResult = Apollo.QueryResult<GetCustomFormQuery, GetCustomFormQueryVariables>;
export function refetchGetCustomFormQuery(variables: GetCustomFormQueryVariables) {
      return { query: GetCustomFormDocument, variables: variables }
    }
export const CustomFormResponsesByGroupIdDocument = gql`
    query CustomFormResponsesByGroupId($customFormId: ID!, $groupId: ID!, $pagination: PaginationArgumentDTO!) {
  customForm(customFormId: $customFormId) {
    id
    title
    createdAt
    allowMultipleSubmissions
  }
  customFormResponsesByGroupId(
    groupId: $groupId
    customFormId: $customFormId
    pagination: $pagination
  ) {
    objects {
      id
      submittedAt
      status
      coworker {
        id
        name
        email
      }
      user {
        id
        name
      }
    }
    hasNext
    total
  }
}
    `;

/**
 * __useCustomFormResponsesByGroupIdQuery__
 *
 * To run a query within a React component, call `useCustomFormResponsesByGroupIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFormResponsesByGroupIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFormResponsesByGroupIdQuery({
 *   variables: {
 *      customFormId: // value for 'customFormId'
 *      groupId: // value for 'groupId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCustomFormResponsesByGroupIdQuery(baseOptions: Apollo.QueryHookOptions<CustomFormResponsesByGroupIdQuery, CustomFormResponsesByGroupIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomFormResponsesByGroupIdQuery, CustomFormResponsesByGroupIdQueryVariables>(CustomFormResponsesByGroupIdDocument, options);
      }
export function useCustomFormResponsesByGroupIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomFormResponsesByGroupIdQuery, CustomFormResponsesByGroupIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomFormResponsesByGroupIdQuery, CustomFormResponsesByGroupIdQueryVariables>(CustomFormResponsesByGroupIdDocument, options);
        }
export type CustomFormResponsesByGroupIdQueryHookResult = ReturnType<typeof useCustomFormResponsesByGroupIdQuery>;
export type CustomFormResponsesByGroupIdLazyQueryHookResult = ReturnType<typeof useCustomFormResponsesByGroupIdLazyQuery>;
export type CustomFormResponsesByGroupIdQueryResult = Apollo.QueryResult<CustomFormResponsesByGroupIdQuery, CustomFormResponsesByGroupIdQueryVariables>;
export function refetchCustomFormResponsesByGroupIdQuery(variables: CustomFormResponsesByGroupIdQueryVariables) {
      return { query: CustomFormResponsesByGroupIdDocument, variables: variables }
    }
export const CustomFormsByGroupDocument = gql`
    query CustomFormsByGroup($groupId: ID!, $pagination: PaginationArgumentDTO!) {
  customFormsByGroup(groupId: $groupId, pagination: $pagination) {
    hasNext
    objects {
      id
      customForm {
        id
        title
        description
      }
      visibleToWorkers
    }
    total
  }
}
    `;

/**
 * __useCustomFormsByGroupQuery__
 *
 * To run a query within a React component, call `useCustomFormsByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFormsByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFormsByGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCustomFormsByGroupQuery(baseOptions: Apollo.QueryHookOptions<CustomFormsByGroupQuery, CustomFormsByGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomFormsByGroupQuery, CustomFormsByGroupQueryVariables>(CustomFormsByGroupDocument, options);
      }
export function useCustomFormsByGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomFormsByGroupQuery, CustomFormsByGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomFormsByGroupQuery, CustomFormsByGroupQueryVariables>(CustomFormsByGroupDocument, options);
        }
export type CustomFormsByGroupQueryHookResult = ReturnType<typeof useCustomFormsByGroupQuery>;
export type CustomFormsByGroupLazyQueryHookResult = ReturnType<typeof useCustomFormsByGroupLazyQuery>;
export type CustomFormsByGroupQueryResult = Apollo.QueryResult<CustomFormsByGroupQuery, CustomFormsByGroupQueryVariables>;
export function refetchCustomFormsByGroupQuery(variables: CustomFormsByGroupQueryVariables) {
      return { query: CustomFormsByGroupDocument, variables: variables }
    }
export const GroupAuthCardAndPartnerDocument = gql`
    query GroupAuthCardAndPartner($groupId: ID!) {
  group(groupId: $groupId) {
    id
    company
    name
    partner {
      id
      name
      slug
      logoAttachment {
        id
        url
      }
    }
    authorizationCard {
      id
      createdAt
      language
      additionalFields
    }
  }
}
    `;

/**
 * __useGroupAuthCardAndPartnerQuery__
 *
 * To run a query within a React component, call `useGroupAuthCardAndPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupAuthCardAndPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupAuthCardAndPartnerQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupAuthCardAndPartnerQuery(baseOptions: Apollo.QueryHookOptions<GroupAuthCardAndPartnerQuery, GroupAuthCardAndPartnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupAuthCardAndPartnerQuery, GroupAuthCardAndPartnerQueryVariables>(GroupAuthCardAndPartnerDocument, options);
      }
export function useGroupAuthCardAndPartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupAuthCardAndPartnerQuery, GroupAuthCardAndPartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupAuthCardAndPartnerQuery, GroupAuthCardAndPartnerQueryVariables>(GroupAuthCardAndPartnerDocument, options);
        }
export type GroupAuthCardAndPartnerQueryHookResult = ReturnType<typeof useGroupAuthCardAndPartnerQuery>;
export type GroupAuthCardAndPartnerLazyQueryHookResult = ReturnType<typeof useGroupAuthCardAndPartnerLazyQuery>;
export type GroupAuthCardAndPartnerQueryResult = Apollo.QueryResult<GroupAuthCardAndPartnerQuery, GroupAuthCardAndPartnerQueryVariables>;
export function refetchGroupAuthCardAndPartnerQuery(variables: GroupAuthCardAndPartnerQueryVariables) {
      return { query: GroupAuthCardAndPartnerDocument, variables: variables }
    }
export const OnboardingTasksDocument = gql`
    query OnboardingTasks($groupId: ID!) {
  group(groupId: $groupId) {
    id
    onboardingTasks {
      id
      note
      task {
        id
        title
        description
      }
    }
  }
}
    `;

/**
 * __useOnboardingTasksQuery__
 *
 * To run a query within a React component, call `useOnboardingTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingTasksQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useOnboardingTasksQuery(baseOptions: Apollo.QueryHookOptions<OnboardingTasksQuery, OnboardingTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingTasksQuery, OnboardingTasksQueryVariables>(OnboardingTasksDocument, options);
      }
export function useOnboardingTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingTasksQuery, OnboardingTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingTasksQuery, OnboardingTasksQueryVariables>(OnboardingTasksDocument, options);
        }
export type OnboardingTasksQueryHookResult = ReturnType<typeof useOnboardingTasksQuery>;
export type OnboardingTasksLazyQueryHookResult = ReturnType<typeof useOnboardingTasksLazyQuery>;
export type OnboardingTasksQueryResult = Apollo.QueryResult<OnboardingTasksQuery, OnboardingTasksQueryVariables>;
export function refetchOnboardingTasksQuery(variables: OnboardingTasksQueryVariables) {
      return { query: OnboardingTasksDocument, variables: variables }
    }
export const OrganizerDocument = gql`
    query Organizer($organizerId: ID!) {
  organizer(organizerId: $organizerId) {
    id
    createdAt
    userId
    partnerId
    user {
      id
      name
      profilePic {
        id
        url
      }
      organizingGroups {
        id
        name
      }
      currentOnboardings {
        id
        groupId
        group {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useOrganizerQuery__
 *
 * To run a query within a React component, call `useOrganizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useOrganizerQuery(baseOptions: Apollo.QueryHookOptions<OrganizerQuery, OrganizerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizerQuery, OrganizerQueryVariables>(OrganizerDocument, options);
      }
export function useOrganizerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizerQuery, OrganizerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizerQuery, OrganizerQueryVariables>(OrganizerDocument, options);
        }
export type OrganizerQueryHookResult = ReturnType<typeof useOrganizerQuery>;
export type OrganizerLazyQueryHookResult = ReturnType<typeof useOrganizerLazyQuery>;
export type OrganizerQueryResult = Apollo.QueryResult<OrganizerQuery, OrganizerQueryVariables>;
export function refetchOrganizerQuery(variables: OrganizerQueryVariables) {
      return { query: OrganizerDocument, variables: variables }
    }
export const SignAuthCardExternalDocument = gql`
    mutation SignAuthCardExternal($input: SignAuthorizationCardExternalInput!) {
  signAuthorizationCardExternal(input: $input) {
    id
    token
    workflowState
    onboardingCache {
      key
      value
      boolValue
    }
  }
}
    `;
export type SignAuthCardExternalMutationFn = Apollo.MutationFunction<SignAuthCardExternalMutation, SignAuthCardExternalMutationVariables>;

/**
 * __useSignAuthCardExternalMutation__
 *
 * To run a mutation, you first call `useSignAuthCardExternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignAuthCardExternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signAuthCardExternalMutation, { data, loading, error }] = useSignAuthCardExternalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignAuthCardExternalMutation(baseOptions?: Apollo.MutationHookOptions<SignAuthCardExternalMutation, SignAuthCardExternalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignAuthCardExternalMutation, SignAuthCardExternalMutationVariables>(SignAuthCardExternalDocument, options);
      }
export type SignAuthCardExternalMutationHookResult = ReturnType<typeof useSignAuthCardExternalMutation>;
export type SignAuthCardExternalMutationResult = Apollo.MutationResult<SignAuthCardExternalMutation>;
export type SignAuthCardExternalMutationOptions = Apollo.BaseMutationOptions<SignAuthCardExternalMutation, SignAuthCardExternalMutationVariables>;
export const VerifyEmailFromAuthCardDocument = gql`
    mutation VerifyEmailFromAuthCard($onboardingId: ID!) {
  verifyEmailFromAuthCard(onboardingId: $onboardingId) {
    email
    name
    partnerName
    onboarding {
      id
      workflowState
      token
      onboardingCache {
        key
        value
        boolValue
      }
    }
  }
}
    `;
export type VerifyEmailFromAuthCardMutationFn = Apollo.MutationFunction<VerifyEmailFromAuthCardMutation, VerifyEmailFromAuthCardMutationVariables>;

/**
 * __useVerifyEmailFromAuthCardMutation__
 *
 * To run a mutation, you first call `useVerifyEmailFromAuthCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailFromAuthCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailFromAuthCardMutation, { data, loading, error }] = useVerifyEmailFromAuthCardMutation({
 *   variables: {
 *      onboardingId: // value for 'onboardingId'
 *   },
 * });
 */
export function useVerifyEmailFromAuthCardMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailFromAuthCardMutation, VerifyEmailFromAuthCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailFromAuthCardMutation, VerifyEmailFromAuthCardMutationVariables>(VerifyEmailFromAuthCardDocument, options);
      }
export type VerifyEmailFromAuthCardMutationHookResult = ReturnType<typeof useVerifyEmailFromAuthCardMutation>;
export type VerifyEmailFromAuthCardMutationResult = Apollo.MutationResult<VerifyEmailFromAuthCardMutation>;
export type VerifyEmailFromAuthCardMutationOptions = Apollo.BaseMutationOptions<VerifyEmailFromAuthCardMutation, VerifyEmailFromAuthCardMutationVariables>;
export const EmailForOnboardingDocument = gql`
    query EmailForOnboarding($onboardingId: ID) {
  emailForOnboarding(onboardingId: $onboardingId)
}
    `;

/**
 * __useEmailForOnboardingQuery__
 *
 * To run a query within a React component, call `useEmailForOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailForOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailForOnboardingQuery({
 *   variables: {
 *      onboardingId: // value for 'onboardingId'
 *   },
 * });
 */
export function useEmailForOnboardingQuery(baseOptions?: Apollo.QueryHookOptions<EmailForOnboardingQuery, EmailForOnboardingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailForOnboardingQuery, EmailForOnboardingQueryVariables>(EmailForOnboardingDocument, options);
      }
export function useEmailForOnboardingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailForOnboardingQuery, EmailForOnboardingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailForOnboardingQuery, EmailForOnboardingQueryVariables>(EmailForOnboardingDocument, options);
        }
export type EmailForOnboardingQueryHookResult = ReturnType<typeof useEmailForOnboardingQuery>;
export type EmailForOnboardingLazyQueryHookResult = ReturnType<typeof useEmailForOnboardingLazyQuery>;
export type EmailForOnboardingQueryResult = Apollo.QueryResult<EmailForOnboardingQuery, EmailForOnboardingQueryVariables>;
export function refetchEmailForOnboardingQuery(variables?: EmailForOnboardingQueryVariables) {
      return { query: EmailForOnboardingDocument, variables: variables }
    }
export const ArchiveTopicDocument = gql`
    mutation ArchiveTopic($topicId: ID!) {
  archiveTopic(topicId: $topicId) {
    nothing
  }
}
    `;
export type ArchiveTopicMutationFn = Apollo.MutationFunction<ArchiveTopicMutation, ArchiveTopicMutationVariables>;

/**
 * __useArchiveTopicMutation__
 *
 * To run a mutation, you first call `useArchiveTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTopicMutation, { data, loading, error }] = useArchiveTopicMutation({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useArchiveTopicMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveTopicMutation, ArchiveTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveTopicMutation, ArchiveTopicMutationVariables>(ArchiveTopicDocument, options);
      }
export type ArchiveTopicMutationHookResult = ReturnType<typeof useArchiveTopicMutation>;
export type ArchiveTopicMutationResult = Apollo.MutationResult<ArchiveTopicMutation>;
export type ArchiveTopicMutationOptions = Apollo.BaseMutationOptions<ArchiveTopicMutation, ArchiveTopicMutationVariables>;
import { useChannels } from "@get-frank-eng/chat-client";
import * as React from "react";
import AuthN from "./AuthN";
import { useAuthState, AuthStates } from "./AuthState";
import SentryClient from "../sentryClient";

const Logout = () => {
  const [isLoggedOut, setLoggedOut] = React.useState(false);
  const { resetChannels, leaveRoom } = useChannels();
  const { setAuthState } = useAuthState();
  React.useEffect(() => {
    SentryClient.configureScope((scope) => {
      scope.setUser(null);
    });
    AuthN.logout().then(() => {
      setLoggedOut(true);
      leaveRoom();
      resetChannels();
    });
    setAuthState({ state: AuthStates.NOT_LOGGED_IN });
  }, [resetChannels, setAuthState, leaveRoom]);
  if (isLoggedOut) {
    window.location.href = "/login";
  }
  return null;
};

export default Logout;

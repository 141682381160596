import {
  Button,
  FormRenderer,
  InlineAlert,
  Intent,
  ScreenSizes,
  useScreenSize,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import FullScreenLoading from "../../components/FullScreenLoading";
import Loading from "../../components/Loading";
import {
  StepContainer,
  StepDescription,
  StepLabel,
  StepTitle,
} from "../components";
import { CreateNewGroupLayout } from "../CreateNewGroupLayout";
import useOnboardingForm from "../dataAccess/mutations/useOnboardingFormMutation";
import useMyPartner from "../dataAccess/queries/useMyPartner";
import { useNewGroupLayout } from "../state";
import { useTranslation } from "react-i18next";
import { findLanguage } from "../../utils/findLanguage";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function PartnerQuestions() {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm<FormData>();
  const { partner, loading: myPartnerLoading } = useMyPartner();
  const { state } = useNewGroupLayout();

  const {
    submitOnboardingForm,
    loading: loadingSubmit,
    error,
  } = useOnboardingForm();
  const [loading, setLoading] = React.useState(false);
  const { push, go } = useHistory();
  const isMobile = useScreenSize() <= ScreenSizes.SM;
  const onSubmit = React.useCallback(
    async (formData) => {
      try {
        setLoading(true);
        await submitOnboardingForm({ responses: formData });
        await sleep(1500);
        setLoading(false);
        push(isMobile ? "/home" : "/from-welcomebot");
      } catch (e) {
        setLoading(false);
      }
    },
    [submitOnboardingForm, setLoading, isMobile, error]
  );

  if (loading) {
    return <FullScreenLoading text={t("preparingYourGroup")} />;
  }

  if (myPartnerLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <InlineAlert intent={Intent.FAILURE} title={t("formError")}>
        {t("thereWasAProblemSubmittingYourForm")}
      </InlineAlert>
    );
  }

  return (
    <CreateNewGroupLayout>
      <StepContainer>
        <StepLabel>
          {t("newGroupStepCurrentStepOfTo", {
            currentStep: state.steps,
            totalSteps: state.steps,
          })}
        </StepLabel>
        <StepTitle>
          {t("someQuestionsFromPartnerName", { partnerName: partner.name })}
        </StepTitle>
        <StepDescription>
          {t("partnerNameWillUseThisInformati", { partnerName: partner.name })}
        </StepDescription>
        <div className="h-8" />
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="space-y-8"
        >
          <FormRenderer
            errors={errors}
            schema={JSON.parse(partner.onboardingFormJSON)}
            register={register}
            lng={findLanguage(navigator.language)}
          />
          <div className="flex flex-row space-x-3 pb-12">
            <Button
              dataCy="continue-to-app"
              type="submit"
              buttonStyle="brand"
              loading={loadingSubmit}
              iconRight="arrow_forward"
            >
              {t("continue")}
            </Button>
          </div>
        </form>
      </StepContainer>
    </CreateNewGroupLayout>
  );
}

import { Button } from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PrimaryActionItem from "./PrimaryActionItem";

const ChatPrimaryActionEmbed = ({}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  return (
    <PrimaryActionItem
      expandable={false}
      isExpanded={false}
      setIsExpanded={() => null}
    >
      <div className="pt-4">
        <div className="mb-2">
          {t("sayHelloInChannelName", { channelName: "#general" })}
        </div>
        <div className="t-mini mb-2">{t("sayHi")}!</div>
        <div className="mt-4">
          <Button
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              push("/topics/general");
            }}
            buttonStyle="brand"
            className="mx-2"
          >
            {t("sayHi")}
          </Button>
        </div>
      </div>
    </PrimaryActionItem>
  );
};

export default ChatPrimaryActionEmbed;

import * as React from "react";
import {
  Button,
  FormGroup,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiSelect,
  Switch,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import { useForm } from "react-hook-form";
import { useModals } from "../../Modals";
import { debounce, values } from "lodash";
import { searchCoworkersByName } from "../../MentionTextarea/dataAccess/coworkerSearch";
import useAssignTask from "../dataAccess/mutations/useAssignTask";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

type FormParams = {
  note?: string;
  dueDate?: string;
  assignToGroup: boolean;
};

const AssignTaskModal = ({
  afterSubmit,
  groupId,
  task,
}: {
  afterSubmit: () => any;
  groupId: string;
  task: FrankBackendTypes.Task;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModals();
  const { register, handleSubmit } = useForm<FormParams>();
  const toaster = useToaster();
  const [assignees, setAssignees] = React.useState([]);
  const [assignToGroup, setAssignToGroup] = React.useState(false);
  const [assignToNewMembers, setAssignToNewMembers] = React.useState(false);
  const [loadingOptions, setLoadingOptions] = React.useState(false);
  const { assignTask, loading } = useAssignTask();

  const submit = React.useCallback(
    async (formValues: FormParams) => {
      try {
        if (!assignToGroup && !assignees.length && !assignToNewMembers) {
          toaster.addToast({
            intent: Intent.FAILURE,
            children: t("chooseAtLeast1Worker"),
          });
          return;
        }
        await assignTask({
          note: formValues.note,
          coworkerIds: assignToGroup ? [] : assignees.map((v) => v.value),
          taskId: task.id,
          dueDate: !!formValues.dueDate ? formValues.dueDate : undefined,
          groupId,
          assignToGroup,
          event: assignToNewMembers
            ? FrankBackendTypes.TaskEvent.FinishWorkerTwoOnboarding
            : null,
        });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("taskAssigned"),
        });
        afterSubmit();
        closeModal();
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrong"),
        });
      }
    },
    [
      assignToGroup,
      assignees,
      assignTask,
      task.id,
      groupId,
      assignToNewMembers,
      toaster,
      afterSubmit,
      closeModal,
      t,
    ]
  );

  const loadOptions = React.useCallback(
    debounce((searchString, callback) => {
      setLoadingOptions(true);
      searchCoworkersByName(searchString, groupId)
        .then((result) => {
          setLoadingOptions(false);
          callback(
            result.map((r) => ({
              label: r.name,
              value: r.id,
            }))
          );
        })
        .catch((e) => {
          toaster.addToast({
            intent: Intent.FAILURE,
            children: t("somethingWentWrong"),
          });
          throw e;
        });
    }, 500),
    [searchCoworkersByName]
  );

  const form = (
    <form
      onSubmit={handleSubmit(submit)}
      id="assign-task-form"
      className="space-y-6"
    >
      <div>
        {!assignToGroup && !assignToNewMembers && (
          <>
            <div className="t-mini plus pb-1">{t("assignToGroupMemberS")}</div>
            <MultiSelect
              loadOptions={loadOptions}
              defaultOptions={true}
              onChange={(newValue) => setAssignees(newValue)}
              isLoading={loadingOptions}
              disabled={assignToGroup}
            />
            <div className="t-mini text-canvas-400 pt-2 pb-4">
              {t("selectMemberSToAssignThisTaskTo")}
            </div>
          </>
        )}
      </div>
      <div>
        {!assignees.length && (
          <div>
            <div className="t-mini plus pb-1">{t("assignToGroup")}</div>
            <div className="space-y-1">
              <div className="flex justify-between items-center">
                <div className="t-small">{t("allCurrentVerifiedMembers")}</div>
                <Switch
                  checked={assignToGroup}
                  label={t("assignToGroup")}
                  onChange={() => {
                    setAssignToGroup(!assignToGroup);
                    setAssignees([]);
                  }}
                  disabled={loading}
                />
              </div>
              <div className="flex justify-between items-center">
                <div className="t-small">
                  {t("allNewMembersUponJoiningTheGroup")}
                </div>
                <Switch
                  checked={assignToNewMembers}
                  label={t("assignToNewMembers")}
                  onChange={() => {
                    setAssignToNewMembers(!assignToNewMembers);
                    setAssignees([]);
                  }}
                  disabled={loading}
                />
              </div>
              <div className="t-mini text-canvas-400">
                {t("youCanSeeCurrentOnboardingTasksIn")}
              </div>
            </div>
          </div>
        )}
      </div>
      {!assignToNewMembers && (
        <FormGroup label={t("dueDate")} name="dueDate" id="dueDate">
          <Input
            type="date"
            register={register}
            helpText={t("whenShouldThisBeDoneByOptional")}
          />
        </FormGroup>
      )}
      <FormGroup label={t("note")} name="note" id="note">
        <Textarea
          register={register}
          maxLength={400}
          helpText={t("addALittleMoreContextForThePeople")}
          registerArgs={{
            maxLength: t("notesHaveMaximumLengthOf400Charact"),
          }}
        />
      </FormGroup>
    </form>
  );

  return (
    <>
      <ModalHeader title={t("assignTask")} />
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        <Button loading={loading} buttonStyle="outline" onClick={closeModal}>
          {t("cancel")}
        </Button>
        <Button
          loading={loading}
          form="assign-task-form"
          buttonStyle="brand"
          disabled={!values.length}
        >
          {t("assign")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AssignTaskModal;

import { LinkButton, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import useBuilderNavLogic from "../hooks/useBuilderNavLogic";
import { useTranslation } from "react-i18next";

export default function BackAndContinue({
  workflowState,
}: {
  workflowState: FrankBackendTypes.CampaignWorkflowState;
}) {
  const { lastStepUrl, nextStepUrl } = useBuilderNavLogic({ workflowState });

  const { t } = useTranslation();

  return (
    <>
      <div className="invisible md:visible flex flex-row mt-6">
        {lastStepUrl && (
          <LinkButton
            size={Sizes.LG}
            to={`./${lastStepUrl}`}
            buttonStyle="minimal"
            iconLeft="arrow_back"
            className="mr-2"
          >
            {t("previous")}
          </LinkButton>
        )}
        {nextStepUrl && (
          <LinkButton
            size={Sizes.LG}
            to={`./${nextStepUrl}`}
            iconRight="arrow_forward"
          >
            {t("continue")}
          </LinkButton>
        )}
      </div>
    </>
  );
}

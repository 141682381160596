import { useTranslation } from "react-i18next";

export default ({ complete }: { complete: boolean }) => {
  const { t } = useTranslation();
  if (complete) {
    return {
      login: t("signOut"),
      stepLabel: t("stepOneComplete"),
      sectionHeader: t("accountCreated"),
      subText: "",
    };
  }
  return {
    login: t("logIn"),
    stepLabel: t("step13"),
    sectionHeader: t("createYourAccount"),
    subText: t("makeSureYoureUsingPersonalInformat"),
  };
};

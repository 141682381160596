import React from "react";
import { Button } from "@get-frank-eng/design-system";
import { TaskAction } from "frank-types/dist/frank-backend-types";
import { useModals } from "../../../Modals";
import { useUpdateAssignment } from "../../../Tasks/hooks/useUpdateAssignment";
import { convertJsonDateToString } from "../../util";
import ActionListItem from "./ActionListItem";
import { useTranslation } from "react-i18next";

const TaskActionItem = ({
  task,
  onUpdate,
}: {
  task: TaskAction;
  onUpdate: () => Promise<void>;
}) => {
  const { setModal, closeModal } = useModals();
  const { t } = useTranslation();
  const { updateAssignment } = useUpdateAssignment({
    callback: onUpdate,
  });
  const completeTask = React.useCallback(() => {
    updateAssignment({
      id: task.assignmentId,
      workflowState: task.assignmentWorkflowState,
    });
    closeModal();
  }, [closeModal]);
  const defaultAction = () =>
    setModal({
      type: "genericModal",
      props: {
        title: t("taskAssigned"),
        body: (
          <>
            <div className="mb-2">{task.title}</div>
            {task.description && (
              <div className="t-mini mb-2">{task.description}</div>
            )}
            <div className="text-center mt-4">
              <Button
                onClick={completeTask}
                buttonStyle="secondary"
                className="mx-2"
              >
                {t("complete")}
              </Button>
            </div>
          </>
        ),
      },
    });
  const actions = [
    {
      label: t("seeDetails"),
      action: defaultAction,
    },
    {
      label: t("complete"),
      action: completeTask,
    },
  ];
  return (
    <ActionListItem
      key={task.id}
      title={task.title}
      subheading={
        task.dueDate
          ? `${t("dueDate")}: ${convertJsonDateToString(task.dueDate)}`
          : `${t("dateAssigned")}: ${convertJsonDateToString(
              task.dateAssigned
            )}`
      }
      actions={actions}
      defaultAction={defaultAction}
    />
  );
};

export default TaskActionItem;

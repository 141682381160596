import {
  EmptyState,
  Button,
  SmallAttachmentCard,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";
import { openFileChooser } from "../../../../utils/uploadAttachment";
import * as React from "react";
import { useModals } from "../../../../Modals";

export const CustomResources = ({
  resources,
  lng,
  uploadFile,
  deleteAttachment,
  level,
  loading,
  logoUrl,
}: {
  resources: FrankBackendTypes.CustomResource[];
  lng: string;
  uploadFile: (e: any, title: string) => void;
  deleteAttachment: (attachmentId) => Promise<void>;
  level: "group" | "partner";
  loading: boolean;
  logoUrl: string;
}) => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useModals();

  const dummyThumbnail = { url: logoUrl };

  const openTitleResourceModal = React.useCallback(() => {
    return new Promise((resolve) => {
      setModal({
        afterClose: () => {
          closeModal();
          resolve(false);
        },
        type: "createCustomResourceModal",
        props: {
          resolve,
        },
      });
    });
  }, [setModal, closeModal]);

  return (
    <section className="space-y-2">
      <div className="t-mini plus">
        {level === "group"
          ? t("groupCustomResources")
          : t("partnerCustomResources")}
      </div>
      <div className="t-mini text-canvas-400">
        {t("customResourcesDescription")}
      </div>

      <div className="space-y-2">
        {!!resources?.length ? (
          resources.map((resource) => (
            <SmallAttachmentCard
              key={resource.attachment.id}
              attachment={{
                ...resource.attachment,
                filename: resource.title,
                thumbnail:
                  resource.attachment.thumbnail ||
                  (dummyThumbnail as FrankBackendTypes.Attachment),
              }}
              deleteAttachment={deleteAttachment}
              lng={lng}
            />
          ))
        ) : (
          <EmptyState title={t("resourcesEmptyState")} />
        )}
      </div>

      <Button
        type="button"
        onClick={async () => {
          const title: any = await openTitleResourceModal();
          if (!title) return;
          await openFileChooser((e) => uploadFile(e, title));
        }}
        loading={loading}
      >
        {t("addResource")}
      </Button>
    </section>
  );
};

import * as React from "react";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { useModals } from "../Modals";
import CreatePollForm from "./CreatePoll";
import { useTranslation } from "react-i18next";

const CreatePollModal = ({
  afterSubmit,
  isAnonymous = false,
}: {
  afterSubmit: (pollId?: string) => any;
  isAnonymous?: boolean;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModals();
  const { form, footer } = CreatePollForm({
    isAnonymous,
    afterSubmit: (pollId) => {
      afterSubmit(pollId);
      closeModal();
    },
  });

  return (
    <>
      <ModalHeader title={t("createAPoll")} />
      {isAnonymous && (
        <div className="px-6 pt-4 t-small">
          {t("youAreCreatingAPollInAnAnonymous")}
        </div>
      )}
      <ModalBody>{form}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </>
  );
};

export default CreatePollModal;

import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

import * as React from "react";

const activityFeedItemFragment = gql`
  fragment FeedFragment on PaginatedActivity {
    hasNext
    total
    objects {
      time
      id
      quoteText
      verb
      timeAgo
      htmlDescription
      campaign {
        id
        title
      }
      user {
        id
        shortName
        isYou
        profilePic {
          id
          url
        }
      }
    }
  }
`;

const LOAD_CAMPAIGN_ACTIVITY = gql`
  query CampaignActivity(
    $campaignId: ID!
    $pagination: PaginationArgumentDTO!
  ) {
    campaign(campaignId: $campaignId) {
      id
      activity(pagination: $pagination) {
        ...FeedFragment
      }
    }
  }
  ${activityFeedItemFragment}
`;

const perPage = 10;

export default function useCampaignActivity(campaignId: string) {
  const { data, loading, error, fetchMore } = useQuery<
    Pick<FrankBackendTypes.Query, "campaign">,
    FrankBackendTypes.QueryCampaignArgs & FrankBackendTypes.CampaignActivityArgs
  >(LOAD_CAMPAIGN_ACTIVITY, {
    variables: { campaignId, pagination: { page: 0, perPage } },
  });

  const loadMore = React.useCallback(() => {
    fetchMore({
      variables: {
        campaignId,
        pagination: {
          perPage,
          page: Math.round(data?.campaign.activity.objects.length / perPage),
        },
      },
    });
  }, [fetchMore, campaignId, data]);

  return {
    loading,
    error,
    loadMore,
    activity: data?.campaign.activity,
  };
}

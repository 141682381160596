import {
  DropzoneProvider,
  MentionTrigger,
  MessageBar,
  useResponsive,
} from "@get-frank-eng/design-system";
import { ChannelType, ChatTypes } from "frank-types";
import React from "react";
import Loading from "../../components/Loading";
import {
  searchCoworkers,
  useSearchPrivateCoworkers,
} from "../../MentionTextarea/dataAccess/coworkerSearch";
import { useModals } from "../../Modals";

export type MessageBarProps = {
  loading: boolean;
  coworkersMentionable?: boolean;
  onSend: (params: {
    text: string;
    files: File[];
    links: { href: string; value: string; type: string }[];
    pollId?: string;
  }) => void;
  mentionTriggers: MentionTrigger[];
  placeholder?: string;
  onChange?: (val: string) => void;
  editingBody: string;
  onCancel: () => void;
  defaultValue?: string;
  isAnonymous: boolean;
  disabled?: boolean;
  error?: string | undefined | null;
  disableMentions?: boolean;
  disableFileUpload?: boolean;
  channel: ChatTypes.Channel;
  lng: string;
};

function WrappedMessageBar({
  coworkersMentionable = true,
  placeholder,
  onSend,
  loading,
  mentionTriggers,
  onCancel,
  isAnonymous,
  editingBody,
  onChange,
  defaultValue,
  disabled,
  error,
  disableMentions = false,
  disableFileUpload = false,
  channel,
  lng
}: MessageBarProps) {
  const { screenSize } = useResponsive();
  const searchPrivateCoworkers = useSearchPrivateCoworkers(channel.id);
  const triggers = React.useMemo(() => {
    const toRet: MentionTrigger[] = [...mentionTriggers];
    if (coworkersMentionable) {
      toRet.push({
        data:
          channel.type === ChannelType.PRIVATE_ROOM
            ? searchPrivateCoworkers
            : searchCoworkers,
        trigger: "@",
        type: "coworker",
      });
    }
    return toRet;
  }, [coworkersMentionable, mentionTriggers, searchPrivateCoworkers, channel]);

  const { setModal } = useModals();

  const onLinkRequest = React.useCallback(
    (args: { selectedText: string }) => {
      return new Promise<{ href: string; value: string }>((resolve, reject) => {
        setModal({
          type: "messageLinkModal",
          props: {
            defaultValue: args.selectedText,
            onSubmit: resolve,
          },
          afterClose: reject,
        });
      });
    },
    [setModal]
  );

  const createPoll = React.useCallback(() => {
    setModal({
      type: "createPollModal",
      props: {
        isAnonymous,
        afterSubmit: (pollId) => {
          onSend({
            pollId,
            files: [],
            text: "",
            links: [],
          });
        },
      },
    });
  }, [setModal, onSend]);

  const DropzoneOrFragment = disableFileUpload
    ? React.Fragment
    : DropzoneProvider;

  return (
    <>
      <DropzoneOrFragment>
        <MessageBar
          disableMentions={disableMentions}
          screenSize={screenSize}
          loading={loading}
          mentionTriggers={triggers}
          editingBody={editingBody}
          onCancel={onCancel}
          placeholder={placeholder}
          onLinkRequest={onLinkRequest}
          sendMessage={onSend}
          createPoll={createPoll}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={disabled}
          error={error}
          disableFileUpload={disableFileUpload}
          lng={lng}
        />
      </DropzoneOrFragment>
    </>
  );
}

export default WrappedMessageBar;

import * as React from "react";
import {
  Avatar,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nudge,
  OutlinePillBadge,
  Sizes,
} from "@get-frank-eng/design-system";
import { useModals } from "../../Modals";
import { FrankBackendTypes } from "frank-types";
import { dateFormatter } from "../../utils/Date";
import { useUpdateAssignment } from "../hooks/useUpdateAssignment";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

const AssignmentDetailModal = ({
  assignment,
  afterSubmit,
}: {
  assignment: FrankBackendTypes.TaskAssignment;
  afterSubmit: () => any;
}) => {
  const { preferredLanguage } = useCurrentUserData();
  const { t } = useTranslation();
  const { closeModal } = useModals();
  const isComplete =
    assignment.workflowState ===
    FrankBackendTypes.AssignmentWorkflowState.Complete;

  const { updateAssignment, loading } = useUpdateAssignment({
    callback: async () => {
      await afterSubmit();
      closeModal();
    },
  });

  return (
    <>
      <ModalHeader title="" border={false} />
      <ModalBody>
        <section className="space-y-8 -mt-6">
          <div>
            <Nudge x={-4}>
              {assignment.workflowState ===
              FrankBackendTypes.AssignmentWorkflowState.Incomplete ? (
                <OutlinePillBadge
                  size={Sizes.MD}
                  textColor="frank-gold-300"
                  borderColor="frank-gold-300"
                >
                  {t("newOnDate", {
                    date: dateFormatter(preferredLanguage).format(
                      new Date(assignment.createdAt)
                    ),
                  })}
                </OutlinePillBadge>
              ) : (
                <OutlinePillBadge
                  size={Sizes.MD}
                  textColor="frank-green-300"
                  borderColor="frank-green-300"
                >
                  {t("completed")}
                </OutlinePillBadge>
              )}
            </Nudge>
            <div className="py-2">
              <div className="t-large plus pb-1">{assignment.task.title}</div>
            </div>
            <div className="space-y-2">
              <div className="text-canvas-400 t-label-4">
                {t("organizer_one")}
              </div>
              <Avatar
                size={Sizes.XS}
                src={assignment.assignedByUser.profilePic.url}
                altText={assignment.assignedByUser.name!}
                link={`/users/${assignment.assignedByUser.id}`}
              />
            </div>
          </div>

          {assignment.task.description && (
            <div className="t-small space-y-1">
              <div className="t-small plus">{t("description")}</div>
              <div className="t-mini">{assignment.task.description}</div>
            </div>
          )}
          {assignment.note && (
            <div className="space-y-1">
              <div className="t-small plus">
                {t("aNoteFromName", { name: assignment.assignedByUser.name })}
              </div>
              <div className="t-serif-mini">{assignment.note}</div>
            </div>
          )}
          {assignment.dueDate && (
            <div className="t-mini plus text-brand-300">
              {t("dueByDate", {
                date: dateFormatter(preferredLanguage).format(
                  new Date(assignment.dueDate)
                ),
              })}
            </div>
          )}
        </section>
      </ModalBody>
      <ModalFooter>
        <Button loading={loading} buttonStyle="outline" onClick={closeModal}>
          {t("close")}
        </Button>
        <Button
          loading={loading}
          buttonStyle="brand"
          onClick={() => updateAssignment(assignment)}
        >
          {isComplete ? t("reopen") : t("complete")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AssignmentDetailModal;

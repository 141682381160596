import {
  ActivityFeed,
  Delay,
  FullCenter,
  PulseMessage,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { EmptyState } from "../../components/EmptyState";
import Pager, { PaginationStrategy } from "../../components/Pager";
import useCampaignActivity from "../dataAccess/queries/useCampaignActivity";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

export const CampaignActivity = ({ campaignId }: { campaignId: string }) => {
  const { loading, activity, loadMore } = useCampaignActivity(campaignId);
  const { t } = useTranslation();
  const { preferredLanguage } = useCurrentUserData();

  if (loading) {
    return (
      <Delay delay={500}>
        <div className="space-y-6 mt-16">
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
          <PulseMessage />
        </div>
      </Delay>
    );
  }

  return (
    <div>
      {!!activity.objects.length ? (
        <Pager<FrankBackendTypes.Activity>
          paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
          pagination={activity}
          onShowMore={loadMore}
        >
          <ActivityFeed activities={activity.objects} lng={preferredLanguage} />
        </Pager>
      ) : (
        <FullCenter className="pt-32">
          <EmptyState text={t("noRecentActivity")} />
        </FullCenter>
      )}
    </div>
  );
};

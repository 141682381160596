import * as React from "react";
import { useForm } from "react-hook-form";
import {
  ActivityFeed,
  Button,
  Card,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollToMe,
  Sizes,
} from "@get-frank-eng/design-system";
import Loading from "../../components/Loading";
import useCoworkerHistoryFetcher from "../dataAccess/useCoworkerActivityFetcher";
import last from "lodash/last";
import Crossroads from "../../components/Crossroads";
import useViewCoworkerActivity from "../dataAccess/mutations/useViewCoworkerActivity";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

interface Props {
  coworkerId: string;
  change: (newValue: string) => any;
}

const CoworkerHistory = ({
  activity,
  loadNextPage,
  hasNextPage,
}: {
  activity: FrankBackendTypes.Activity[];
  loadNextPage: () => any;
  hasNextPage: boolean;
}) => {
  const reversedArr = React.useMemo(() => [...(activity || [])].reverse(), [
    activity,
  ]);
  const { preferredLanguage } = useCurrentUserData();

  if (!activity) {
    return <></>;
  }
  return (
    <>
      {hasNextPage && <Crossroads onEntering={loadNextPage} />}
      <ActivityFeed activities={reversedArr} lng={preferredLanguage} />
      {reversedArr?.length > 0 && <ScrollToMe key={last(reversedArr).id} />}
    </>
  );
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const CoworkerActivityModal = ({ coworkerId, change }: Props) => {
  const { t } = useTranslation();
  const {
    loadingCoworkerActivity,
    activity,
    hasNextPage,
    loadNextPage,
    name,
    refetchCoworkerActivity,
  } = useCoworkerHistoryFetcher(coworkerId);

  const { viewCoworkerActivity } = useViewCoworkerActivity({ coworkerId });

  React.useEffect(() => {
    viewCoworkerActivity();
  }, [viewCoworkerActivity]);

  const { register, handleSubmit, reset } = useForm();

  const [loading, setLoading] = React.useState(false);
  const submit = React.useCallback(
    async ({ notes }) => {
      setLoading(true);
      await change(notes);
      await sleep(350);
      refetchCoworkerActivity();
      await viewCoworkerActivity();

      reset();
      setLoading(false);
    },
    [change, refetchCoworkerActivity, setLoading, reset, viewCoworkerActivity]
  );

  const footer = (
    <form
      noValidate
      onSubmit={handleSubmit(submit)}
      className="items-center flex flex-row flex-grow"
    >
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        className="p-2 rounded-sm flex-grow bg-transparent focus:ring-canvas-600 focus:ring-2 focus:outline-none t-small"
        placeholder={t("addYourNote")}
        name="notes"
        ref={register({ required: true, maxLength: 1000 })}
        id="notes"
      />
      <Button
        type="submit"
        loading={loading}
        size={Sizes.MD}
        className="self-end ml-2 t-mini-plus w-32"
      >
        {t("addNote")}
      </Button>
    </form>
  );

  return (
    <>
      <ModalHeader title={name || t("newCoworker")} border />
      <ModalBody>
        <>
          <Card pad>{t("theseNotesAreVisibleByAllVerified")}</Card>
          {loadingCoworkerActivity ? (
            <Loading />
          ) : (
            <>
              <CoworkerHistory
                activity={activity}
                hasNextPage={hasNextPage}
                loadNextPage={loadNextPage}
              />
              {activity?.length === 0 && <div className="h-48" />}
            </>
          )}
          <ModalFooter additionalClasses={["pb-1", "pt-4"]}>
            {loadingCoworkerActivity ? <></> : footer}
          </ModalFooter>
        </>
      </ModalBody>
    </>
  );
};

export default CoworkerActivityModal;

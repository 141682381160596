import {
  LinkButton,
  Sizes,
  CampaignWorkflowStateLabel,
  Nudge,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import CampaignBuilderNav from "./Nav";

import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../Auth/useCurrentUserData";

const CampaignBuilderSidebar = ({
  campaign,
  showSaveAndExit = true,
}: {
  campaign: FrankBackendTypes.Campaign;
  showSaveAndExit?: boolean;
}) => {
  const { t } = useTranslation();
  const { preferredLanguage } = useCurrentUserData();

  return (
    <div className="flex flex-col flex-grow">
      {showSaveAndExit && (
        <Nudge x={-9} className="pb-4">
          <LinkButton
            to={
              campaign.workflowState ===
              FrankBackendTypes.CampaignWorkflowState.Published
                ? `/campaigns/${campaign.id}`
                : "/campaigns"
            }
            buttonStyle="minimal"
            size={Sizes.LG}
          >
            {t("saveAndExit")}
          </LinkButton>
        </Nudge>
      )}
      <div>
        <CampaignWorkflowStateLabel
          workflowState={campaign.workflowState}
          lng={preferredLanguage}
        />
        <h1 className="t-title-4 plus mb-8">
          {campaign.title ? campaign.title : t("newPetition")}
        </h1>
      </div>
      <CampaignBuilderNav campaign={campaign} />
    </div>
  );
};

export default CampaignBuilderSidebar;

import { Action, ActionType } from "frank-types/dist/frank-backend-types";
import { useTranslation } from "react-i18next";
import AddCoworkersPrimaryActionEmbed from "./actions/primaryActions/AddCoworkersPrimaryActionEmbed";
import AuthCardPrimaryActionEmbed from "./actions/primaryActions/AuthCardPrimaryActionEmbed";
import ChatPrimaryActionEmbed from "./actions/primaryActions/ChatPrimaryActionEmbed";
import CustomFormPrimaryActionEmbed from "./actions/primaryActions/CustomFormPrimaryActionEmbed";
import InviteCoworkersPrimaryActionEmbed from "./actions/primaryActions/InviteCoworkersPrimaryActionEmbed";
import MembershipRequestPrimaryActionEmbed from "./actions/primaryActions/MembershipRequestPrimaryActionEmbed";
import StartPetitionPrimaryActionEmbed from "./actions/primaryActions/StartPetitionPrimaryActionEmbed";
import TaskPrimaryActionEmbed from "./actions/primaryActions/TaskPrimaryActionEmbed";

const PrimaryActionEmbed = ({
  action,
  onUpdate,
}: {
  action: Action;
  onUpdate: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  let embed = undefined;
  switch (action.type) {
    case ActionType.AuthCard: {
      embed = (
        <AuthCardPrimaryActionEmbed
          authCard={action.authCard!}
          onUpdate={onUpdate}
        />
      );
      break;
    }
    case ActionType.Task: {
      embed = (
        <TaskPrimaryActionEmbed task={action.task!} onUpdate={onUpdate} />
      );
      break;
    }
    case ActionType.Form: {
      embed = (
        <CustomFormPrimaryActionEmbed
          customForm={action.customForm!}
          onUpdate={onUpdate}
        />
      );
      break;
    }
    case ActionType.MembershipRequests: {
      embed = <MembershipRequestPrimaryActionEmbed onUpdate={onUpdate} />;
      break;
    }
    case ActionType.StartPetition: {
      embed = <StartPetitionPrimaryActionEmbed />;
      break;
    }
    case ActionType.InviteCoworkers: {
      embed = <InviteCoworkersPrimaryActionEmbed />;
      break;
    }
    case ActionType.Chat: {
      embed = <ChatPrimaryActionEmbed />;
      break;
    }
    case ActionType.AddCoworkers: {
      embed = <AddCoworkersPrimaryActionEmbed />;
      break;
    }
    default:
      throw new Error(t("unknownActionType", { actionType: action.type }));
  }
  return embed;
};

export default PrimaryActionEmbed;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateCard = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const Avatar_1 = require("../components/Avatar");
const Card_1 = require("../components/Card");
const TemplateCard = ({ url, title, authorName, authorImage, authorBio, category, subcategory }) => (React.createElement(Card_1.FoldedCard, { pad: true, to: url, size: constants_1.Sizes.SM },
    React.createElement("div", { className: "flex flex-col justify-between flex-grow relative cursor-pointer", style: { minHeight: "228px" } },
        React.createElement("div", null,
            React.createElement("span", { className: "t-mini plus text-canvas-400" },
                category,
                subcategory && ` • ${subcategory}`),
            React.createElement("div", { className: "text-clamping-4 t-small plus" }, title)),
        React.createElement("div", { className: "flex-grow" }),
        React.createElement("div", null,
            React.createElement("div", { className: "pb-2" },
                React.createElement(Avatar_1.Avatar, { src: authorImage, size: constants_1.Sizes.XS, altText: authorName })),
            React.createElement("div", { className: "flex flex-col justify-between" },
                React.createElement("p", { className: "t-mini plus" }, authorName),
                React.createElement("p", { className: "t-mini text-canvas-400 text-clamping-2" }, authorBio))))));
exports.TemplateCard = TemplateCard;

import { FrankBackendTypes } from "frank-types";
import DataFieldBooleanInput from "./DataFieldBooleanInput";
import DataFieldCurrencyInput from "./DataFieldCurrencyInput";
import DataFieldDateTimeInput from "./DataFieldDateTimeInput";
import DataFieldNumberInput from "./DataFieldNumberInput";
import DataFieldSelectInput from "./DataFieldSelectInput";
import DataFieldTableInput from "./DataFieldTableInput";
import DataFieldTextInput from "./DataFieldTextInput";
import { DataFieldResponseDataInput } from "./types/common";
import {
  DataFieldConfigDefault,
  DataFieldConfigSelect,
  DataFieldConfigDateTime,
  DataFieldConfigCurrency,
  DataFieldConfigTable,
} from "./types/data-field-config";
import {
  DataFieldResponseStringData,
  DataFieldResponseNumberData,
  DataFieldResponseSelectData,
  DataFieldResponseBooleanData,
  DataFieldResponseDateTimeData,
  DataFieldResponseCurrencyData,
  DataFieldResponseTableData,
} from "./types/data-field-response-data";
import EventEmitter from "./util";

const DataFieldResponseInput = ({
  field,
  onUpdate,
  response,
  disabled,
}: {
  field: FrankBackendTypes.DataField;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  response?: DataFieldResponseDataInput;
  disabled: boolean;
}) => {
  // TODO: validate data type corresponds to field type
  // Map the field type to the proper input control
  switch (field.type) {
    case "text":
      return (
        <DataFieldTextInput
          field={field}
          config={field.config as DataFieldConfigDefault}
          onUpdate={onUpdate}
          data={response?.data as DataFieldResponseStringData | undefined}
          disabled={disabled}
        />
      );
    case "number":
      return (
        <DataFieldNumberInput
          field={field}
          config={field.config as DataFieldConfigDefault}
          onUpdate={onUpdate}
          data={response?.data as DataFieldResponseNumberData | undefined}
          disabled={disabled}
        />
      );
    case "select":
      return (
        <DataFieldSelectInput
          field={field}
          config={field.config as DataFieldConfigSelect}
          onUpdate={onUpdate}
          data={response?.data as DataFieldResponseSelectData | undefined}
          disabled={disabled}
        />
      );
    case "boolean":
      return (
        <DataFieldBooleanInput
          field={field}
          config={field.config as DataFieldConfigDefault}
          onUpdate={onUpdate}
          data={response?.data as DataFieldResponseBooleanData | undefined}
          disabled={disabled}
        />
      );
    case "datetime":
      return (
        <DataFieldDateTimeInput
          field={field}
          config={field.config as DataFieldConfigDateTime}
          onUpdate={onUpdate}
          data={response?.data as DataFieldResponseDateTimeData | undefined}
          disabled={disabled}
        />
      );
    case "currency":
      return (
        <DataFieldCurrencyInput
          field={field}
          config={field.config as DataFieldConfigCurrency}
          onUpdate={onUpdate}
          data={response?.data as DataFieldResponseCurrencyData | undefined}
          disabled={disabled}
        />
      );
    case "table":
      return (
        <DataFieldTableInput
          field={field}
          config={field.config as DataFieldConfigTable}
          onUpdate={onUpdate}
          data={response?.data as DataFieldResponseTableData | undefined}
          disabled={disabled}
        />
      );
    default:
      return <div>Error: unknown field type</div>;
  }
};

export default DataFieldResponseInput;

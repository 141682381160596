import { Avatar, Button, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import Back from "../../components/Back";
import { useTranslation } from "react-i18next";

export const SidebarHeader = ({ title, authorUrl, authorBio, authorName }) => {
  const { t } = useTranslation();
  return (
    <div className="pb-8 border-b">
      <div className="t-label-4">{t("petitionTemplate")}</div>
      <h1 className="t-title-4 plus mb-4">{title}</h1>
      <div className="flex">
        <Avatar src={authorUrl} size={Sizes.SM} altText={authorName} />
        <div className="flex flex-col justify-center ml-2">
          <div className="t-mini plus">{authorName}</div>
          <div className="t-mini text-canvas-400">{authorBio}</div>
        </div>
      </div>
    </div>
  );
};

export const SidebarFooter = ({
  importTemplate,
  templateId,
  isMobile = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className="fixed bottom-0 md:static p-3 md:p-4 z-10 bg-canvas-700 md:bg-canvas-700 border-t border-r w-full flex-shrink-0">
      <Button
        full
        buttonStyle={isMobile ? "secondary" : "brand"}
        onClick={async () => importTemplate(templateId)}
      >
        {t("useTemplate")}
      </Button>
    </div>
  );
};

export const SidebarheaderVeryTop = ({ isMobile = false }) => (
  <div className="flex-shrink-0 my-4 md:mb-0 md:mt-4 flex flex-row justify-between">
    <Back backStyle={isMobile ? "close" : "back-button"} />
  </div>
);

export const SidebarMain = ({
  template,
}: {
  template: FrankBackendTypes.Template;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="py-8 border-b">
        <div className="t-regular plus mb-2">{t("aboutTheTemplate")}</div>
        <div className="t-small">
          <ReactMarkdown source={template.about} />
        </div>
      </div>
      <div className="py-8 border-b">
        <div className="t-regular plus mb-2">
          {t("whatsIncludedInTheTemplate")}
        </div>
        <div>
          <ul className="t-small">
            <li>{t("suggestedTitle")}</li>
            <li>{t("completePetitionWithAnnotations")}</li>
            <li>{t("attachmentsAndLinks")}</li>
            <li>{t("suggestedActions")}</li>
          </ul>
        </div>
      </div>
      <div className="py-8 mb-16 ">
        <div className="t-regular plus mb-2">{t("aboutTheAuthor")}</div>
        <div className="t-small">
          <ReactMarkdown source={template.author.longBio} />
        </div>
      </div>
    </>
  );
};

export default function ViewTemplateSidebar({ template, importTemplate }) {
  return (
    <>
      <div className="px-4 flex-grow overflow-y-auto bg-canvas-700 border-r">
        <SidebarheaderVeryTop />
        <div className="mt-6">
          <SidebarHeader
            title={template.title}
            authorUrl={template.author.image.url}
            authorName={template.author.name}
            authorBio={template.author.bio}
          />
          <>
            <SidebarMain template={template} />
          </>
        </div>
      </div>
      <SidebarFooter importTemplate={importTemplate} templateId={template.id} />
    </>
  );
}

/* eslint-disable no-restricted-syntax */
import * as React from "react";
import DocumentTitle from "react-document-title";
import {
  Redirect,
  Route as RawRoute,
  Switch,
  useLocation,
} from "react-router-dom";
import { AuthStates, useAuthState } from "./Auth/AuthState";
import Logout from "./Auth/Logout";
import PasswordReset from "./Auth/PasswordReset";
import CampaignBuilder from "./CampaignBuilder";
import CampaignDraftListPage from "./CampaignList";
import InviteCoworkersToCollaborate from "./Collaborators/InviteCoworkersToCollaborate";
import Contact from "./Contact";
import usePageTracker from "./hooks/usePageTracker";
import Login from "./Login";
import VerifyEmail from "./Onboarding/VerifyEmail";
import Profile from "./Profile";
import Route, { RouteWithModals } from "./RouteComponents";
import Templates from "./Templates";
import PreviewTemplate from "./Templates/pages/PreviewTemplate";
import CampaignCollaboratorInvite from "./Campaigns/CampaignCollaboratorInvite";
import CampaignDetail from "./CampaignDetail";
import FinishOnboardingLater from "./Onboarding/FinishOnboardingLater";
import RedirectToProfileFromCoworkerId from "./Profile/RedirectToProfileFromCoworkerId";
import { InitialOnboarding } from "./Onboarding";
import TopicDetail from "./Topics/pages/TopicDetail";
import CreateNewGroup from "./SSO";
import Account from "./Account";
import RouteToMessage from "./Chat/RouteToMessage";
import { FrankBackendTypes } from "frank-types";
import RedirectToTopicOrCampaignFromChannelId from "./Channel/RedirectToTopicOrCampaignFromChannelId";
import { CoworkerRoutes } from "./Coworkers/CoworkerRoutes";
import RedirectOutsideOfRouter from "./components/RedirectOutsideOfRouter";
import JoinGroup from "./JoinGroup";
import FromFrankDetail from "./FromFrank/pages/FromFrankDetail";
import FlagMgmt from "./Flags";
import useQuery from "./hooks/useQuery";
import useSession from "./Auth/useSession";
import PartnerRouter from "./Partner";
import { GroupSettings } from "./Partner/pages/GroupSettings";
import ListPartners from "./Partner/pages/ListPartners";
import { SignAuthCard } from "./SignAuthCardExternal/pages/SignAuthCard";
import ConfirmAuthCard from "./SignAuthCardExternal/pages/ConfirmAuthCard";
import CustomForm from "./CustomForm/CustomForm";
import Home from "./Home";

const RedirectToExternalAsset = () => {
  // this is so that we can proxy the images to facilitate printing with html2canvas, and also be able to view the asset in the browser for attachments
  const { pathname, search } = window.location;

  React.useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      return null;
    }

    const [first, restOfPathname] = pathname.split("/s3-assets/");

    window.location.href = `https://${process.env.REACT_APP_S3_ENDPOINT}/${restOfPathname}${search}`;
  });

  return null;
};

function useScrollToTopOnPageChange() {
  const { pathname } = useLocation();
  React.useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
}

function stripSwitchGroupFromURL() {
  const url = new URL(window.location.href);
  url.searchParams.delete("switchGroup");
  return url.toString();
}

const Routes = () => {
  useScrollToTopOnPageChange();
  usePageTracker();
  const { authState, onboardingState } = useAuthState();
  const { switchGroup } = useSession({});

  const qs = useQuery();

  React.useEffect(() => {
    const groupId = qs.get("switchGroup");
    if (groupId) {
      switchGroup(groupId, stripSwitchGroupFromURL());
    }
  }, [qs, switchGroup]);

  return (
    <DocumentTitle title="Frank">
      <Switch>
        <RouteWithModals path="/sign/authorizationCard">
          <SignAuthCard />
        </RouteWithModals>
        <Route path="/confirm/authorizationCard">
          <ConfirmAuthCard />
        </Route>
        <RouteWithModals path="/join">
          <CreateNewGroup />
        </RouteWithModals>

        <RouteWithModals path="/join-group">
          <JoinGroup />
        </RouteWithModals>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route auth sidebar path={`/partners/:partnerSlug/groups/:groupId`}>
          <GroupSettings />
        </Route>
        <Route auth sidebar path="/partners/:partnerSlug">
          <PartnerRouter />
        </Route>

        <Route auth sidebar path="/partners">
          <ListPartners />
        </Route>

        <Route auth path="/coworkerProfiles/:coworkerId">
          <RedirectToProfileFromCoworkerId />
        </Route>

        <Route auth path="/channel/:channelId">
          <RedirectToTopicOrCampaignFromChannelId />
        </Route>

        <Route sidebar auth path="/coworkers">
          <CoworkerRoutes />
        </Route>

        <Route auth path="/messages/:messageId">
          <RouteToMessage />
        </Route>

        <Route onboarding path="/verifyEmail">
          <VerifyEmail />
        </Route>

        <Route path="/onboarding">
          <InitialOnboarding />
        </Route>

        <Route auth path="/flags/:flagId">
          <FlagMgmt />
        </Route>

        <Route auth onboarding path="/finish-onboarding">
          <FinishOnboardingLater />
        </Route>

        <Route auth sidebar path="/custom-forms/:id/:responseId?">
          <CustomForm />
        </Route>

        <Route
          onboarding={
            onboardingState !==
            FrankBackendTypes.OnboardingWorkflowState.Finished
          }
          sidebar={
            authState === AuthStates.LOGGED_IN &&
            onboardingState ===
              FrankBackendTypes.OnboardingWorkflowState.Finished
          }
          auth={
            authState === AuthStates.LOGGED_IN &&
            onboardingState ===
              FrankBackendTypes.OnboardingWorkflowState.Finished
          }
          path="/contact"
        >
          <Contact />
        </Route>
        <RawRoute path="/legal">
          <RedirectOutsideOfRouter to="https://getfrank.notion.site/Legal-Documents-c4008b6c92a24f0482df71ccf6060120" />
        </RawRoute>
        <Route auth sidebar path="/home">
          <Home />
        </Route>
        <Route auth sidebar path="/campaigns/:campaignId/collaboratorInvite">
          <CampaignCollaboratorInvite />
        </Route>
        <Route auth path="/campaigns/:campaignId/inviteCollaborators">
          <InviteCoworkersToCollaborate />
        </Route>
        <Route auth path="/campaigns/:campaignId/builder">
          <CampaignBuilder />
        </Route>
        <Route auth sidebar path="/topics/:slug">
          <TopicDetail />
        </Route>
        <Route auth sidebar path="/from-welcomebot">
          <FromFrankDetail />
        </Route>
        <Route auth sidebar path="/campaigns/drafts">
          <CampaignDraftListPage />
        </Route>
        <Route auth sidebar path="/campaigns/:campaignId">
          <CampaignDetail />
        </Route>
        <Route auth sidebar path="/users/:userId">
          <Profile />
        </Route>
        <Route auth path="/templates/:templateId/preview">
          <PreviewTemplate />
        </Route>
        <Route auth path="/templates">
          <Templates />
        </Route>
        <Route auth sidebar path="/account">
          <Account />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/passwordReset">
          <PasswordReset />
        </Route>
        <Route path="/s3-assets">
          <RedirectToExternalAsset />
        </Route>
        <Route>
          <Redirect to="/home" />
        </Route>
      </Switch>
    </DocumentTitle>
  );
};

export default Routes;

import * as React from "react";
import { Input } from "@get-frank-eng/design-system/dist/components/forms/Input";
import { DataField } from "frank-types/dist/frank-backend-types";
import { DataFieldResponseDataInput } from "./types/common";
import { DataFieldConfigDateTime } from "./types/data-field-config";
import { DataFieldResponseDateTimeData } from "./types/data-field-response-data";

export default function DataFieldDateTimeInput({
  field,
  config,
  onUpdate,
  data,
  disabled,
}: {
  field: DataField;
  config: DataFieldConfigDateTime;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  data?: DataFieldResponseDateTimeData;
  disabled: boolean;
}): JSX.Element {
  const handleUpdate = (updatedValue) => {
    onUpdate({
      dataFieldId: field.id,
      data: { value: updatedValue },
    });
  };
  const includeTime = config.includeTime ?? false;
  const inputType = includeTime ? "datetime" : "date";
  return (
    <Input
      type={inputType}
      onChange={(evt) => handleUpdate(evt.currentTarget.value)}
      defaultValue={data?.value ?? ""}
      disabled={disabled}
    />
  );
}

import i18next from "../../i18n/config";

const columnDefs = {
  name: {
    Header: i18next.t("name"),
    accessor: "name",
    info: i18next.t("useTheNameMostPeopleWillKnowThis"),
    sticky: "left",
    editable: true,
    placeholder: i18next.t("workerName"),
    isAlwaysInteractable: true,
  },

  employmentType: {
    Header: i18next.t("employmentType"),
    accessor: "employmentType",
    width: 180,
    editable: true,
    info: i18next.t("frankIsOnlyForNonManagersAnyEmai"),
    placeholder: i18next.t("employmentType"),
    isAlwaysInteractable: true,
  },

  email: {
    Header: i18next.t("emailAddress"),
    accessor: "email",
    info: i18next.t("prePopulatingTheEmailAddressOfYour"),
    editable: true,
    placeholder: i18next.t("emailAddress"),
  },

  trust: {
    Header: i18next.t("trustworthiness"),
    accessor: "yourTrust",
    info: i18next.t("newWorkersRequireTheTrustOfAtLeas"),
    minWidth: 215,
    editable: false,
    sortType: (rowA, rowB) => {
      if (rowA.original.netTrust > rowB.original.netTrust) {
        return 1;
      }
      if (rowB.original.netTrust > rowA.original.netTrust) {
        return -1;
      }

      return 0;
    },
  },

  status: {
    Header: i18next.t("status"),
    info: i18next.t("followUpWithCoworkersWhoWereInvite"),
    accessor: "status",
    minWidth: 150,
    editable: false,
  },

  joinedAt: {
    Header: i18next.t("dateJoined"),
    accessor: "joinedAtFormatted",
    minWidth: 150,
    editable: false,
  },

  notes: {
    Header: i18next.t("notes"),
    accessor: "notes",
    info: i18next.t("shareInformationAboutThisWorkerWh"),
    editable: true,
    placeholder: i18next.t("notes"),
    maxWidth: 150,
    isAlwaysInteractable: true,
    disableSortBy: true,
  },
};

export default columnDefs;

import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const MUTATION = gql`
  mutation OnboardingForm($input: OnboardingFormInput!) {
    onboardingForm(input: $input) {
      nothing
    }
  }
`;

export default function useOnboardingForm() {
  const [invokeOnboardingForm, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationOnboardingFormArgs
  >(MUTATION);

  const submitOnboardingForm = React.useCallback(
    async ({ responses }: { responses: Record<string, any> }) => {
      await invokeOnboardingForm({
        variables: { input: { responses: JSON.stringify(responses) } },
      });
    },
    [invokeOnboardingForm]
  );

  return {
    submitOnboardingForm: submitOnboardingForm,
    loading,
    called,
    error,
  };
}

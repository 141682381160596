import {
  ResponsiveProvider,
  RightSidePanel,
  ScreenSizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useMemo } from "react";
import { useChannels } from "@get-frank-eng/chat-client";
import Channel from "../../Chat";
import Loading from "../../components/Loading";
import FromFrankRightSide from "../components/FromFrankRightSide";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";

const FromFrankDetail: React.FC = () => {
  const { t } = useTranslation();
  const { channels } = useChannels();
  const { partner, currentGroup } = useCurrentUserData();
  const fromFrankChannel = useMemo(
    () => channels.find((ch) => ch.type === "from-frank"),
    [channels]
  );

  if (!currentGroup.channelOptions.allowFromFrankChannel) {
    // this is going to cause a bug where if this channel happens to be turned back on while you're logged in, if you try to go to the page before you refresh, you won't be able to see the channel. Going to wait on fixing until someone complains
    return <Redirect to="home" />;
  }

  if (!fromFrankChannel) {
    return <Loading />;
  }

  return (
    <>
      <Channel
        title={t("fromPartnerSlug", { partnerSlug: partner.slug })}
        channelId={fromFrankChannel.id}
        key={fromFrankChannel.id}
      />
      <RightSidePanel>
        <ResponsiveProvider screenSize={ScreenSizes.XS}>
          <FromFrankRightSide channelId={fromFrankChannel.id} />
        </ResponsiveProvider>
      </RightSidePanel>
    </>
  );
};

export default FromFrankDetail;

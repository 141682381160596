import { Button, Sizes } from "@get-frank-eng/design-system";
import * as React from "react";
import { useModals } from "../../Modals";
import { useTranslation } from "react-i18next";

const svg = (
  <div className="relative">
    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-canvas-700" />
    <svg
      width="153"
      height="116"
      viewBox="0 0 153 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <g clip-path="url(#clip1)">
          <rect
            x="17"
            y="16.5"
            width="119"
            height="11"
            rx="5.5"
            fill="#252422"
          />
          <rect x="16.5" y="16" width="101" height="50" fill="#686460" />
        </g>
        <rect
          x="17"
          y="16.5"
          width="119"
          height="11"
          rx="5.5"
          stroke="#686460"
        />
        <g clip-path="url(#clip2)">
          <rect
            x="17"
            y="34.5"
            width="119"
            height="11"
            rx="5.5"
            fill="#252422"
          />
          <rect x="16.5" y="34" width="88" height="50" fill="#686460" />
        </g>
        <rect
          x="17"
          y="34.5"
          width="119"
          height="11"
          rx="5.5"
          stroke="#686460"
        />
        <g clip-path="url(#clip3)">
          <rect
            x="17"
            y="52.5"
            width="119"
            height="11"
            rx="5.5"
            fill="#252422"
          />
          <rect x="16.5" y="52" width="64" height="50" fill="#686460" />
        </g>
        <rect
          x="17"
          y="52.5"
          width="119"
          height="11"
          rx="5.5"
          stroke="#686460"
        />
        <g clip-path="url(#clip4)">
          <rect
            x="17"
            y="70.5"
            width="119"
            height="11"
            rx="5.5"
            fill="#252422"
          />
          <rect x="16.5" y="70" width="38.4375" height="50" fill="#686460" />
        </g>
        <rect
          x="17"
          y="70.5"
          width="119"
          height="11"
          rx="5.5"
          stroke="#686460"
        />
        <g clip-path="url(#clip5)">
          <rect
            x="17"
            y="88.5"
            width="119"
            height="11"
            rx="5.5"
            fill="#252422"
          />
          <rect x="16.5" y="88" width="21" height="50" fill="#686460" />
        </g>
        <rect
          x="17"
          y="88.5"
          width="119"
          height="11"
          rx="5.5"
          stroke="#686460"
        />
        <rect
          x="1"
          y="0.5"
          width="151"
          height="115"
          rx="7.5"
          stroke="#686460"
        />
        <rect x="-72" width="298" height="116" fill="url(#paint0_linear)" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="152"
            height="116"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1"></clipPath>
        <clipPath id="clip2"></clipPath>
        <clipPath id="clip3"></clipPath>
        <clipPath id="clip4"></clipPath>
        <clipPath id="clip5"></clipPath>
      </defs>
    </svg>
  </div>
);

const PollEmptyState = ({ sendMessage, refetch, isAnonymous }) => {
  const { t } = useTranslation();

  const { setModal } = useModals();
  return (
    <div className="flex-1 flex flex-col items-center justify-center max-w-xs">
      <div className="max-w-md text-center flex flex-col items-center">
        {svg}
        <h1 className="t-regular plus mt-4">{t("thereAreNoPollsHereYet")}</h1>
        <p className="t-small text-canvas-400">
          {t("createACustomPollToFindOutWhatYo")}
        </p>
        <Button
          className="mt-4"
          buttonStyle="outline"
          size={Sizes.MD}
          onClick={() =>
            setModal({
              type: "createPollModal",
              props: {
                isAnonymous,
                afterSubmit: async (pollId) => {
                  await sendMessage({
                    sentAt: new Date(),
                    body: "",
                    richObjects: [{ type: "poll", foreignIdentifier: pollId }],
                  });
                  await refetch();
                },
              },
              afterClose: refetch,
            })
          }
        >
          {t("createPoll")}
        </Button>
      </div>
    </div>
  );
};

export default PollEmptyState;

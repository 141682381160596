import { Intent, useToaster } from "@get-frank-eng/design-system";
import axios from "axios";
import { EventTypes } from "frank-types";
import * as React from "react";
import generateAuthHeader from "../../Auth/generateAuthHeader";
import { useTranslation } from "react-i18next";

export const useRequestDataExport = () => {
  const { t } = useTranslation();
  const toaster = useToaster();

  const requestDataExport = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/analytics/track`,
        {
          event: EventTypes.AnalyticsEvents.REQUEST_DATA_EXPORT,
        },
        {
          headers: {
            authorization: generateAuthHeader(),
          },
        }
      );
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("successfullyRequestedDataExport"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrongContactFrankGetf"),
      });
    }
  };

  return requestDataExport;
};

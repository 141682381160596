import * as React from "react";
import { gql, useMutation } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation RemoveOrganizer($organizerId: ID!) {
    removeOrganizer(organizerId: $organizerId) {
      nothing
    }
  }
`;

export default function useRemoveOrganizer() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "removeOrganizer">,
    FrankBackendTypes.MutationRemoveOrganizerArgs
  >(mutation);

  const removeOrganizer = React.useCallback(
    (organizerId: string) => {
      return invokeMutation({
        variables: { organizerId },
      });
    },
    [invokeMutation]
  );

  return {
    removeOrganizer,
    loading,
    error,
    called,
  };
}

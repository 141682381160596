import * as React from "react";
import { gql, useMutation } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation DeleteOnboardingForm($partnerId: ID!) {
    deleteOnboardingForm(partnerId: $partnerId) {
      nothing
    }
  }
`;

export default function useDeleteOnboardingForm() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "deleteOnboardingForm">,
    FrankBackendTypes.MutationDeleteOnboardingFormArgs
  >(mutation);
  const deleteOnboardingForm = React.useCallback(
    (
      partnerId: FrankBackendTypes.MutationDeleteOnboardingFormArgs["partnerId"]
    ) => {
      return invokeMutation({
        variables: { partnerId },
      });
    },
    [invokeMutation]
  );
  return {
    deleteOnboardingForm,
    loading,
    error,
    called,
  };
}

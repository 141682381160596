import { useQuery, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query Coworker($coworkerId: ID!, $pagination: PaginationArgumentDTO!) {
    coworker(coworkerId: $coworkerId) {
      id
      name
      activity(pagination: $pagination) {
        hasNext
        total
        objects {
          id
          time
          htmlDescription
          quoteText
          verb
          timeAgo
          user {
            id
            shortName
            isYou
            profilePic {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export default function useCoworkerHistoryFetcher(coworkerId: string) {
  const perPage = 20;
  const { loading, data, refetch, fetchMore } = useQuery<
    { coworker: FrankBackendTypes.Coworker },
    FrankBackendTypes.QueryCoworkerArgs & {
      pagination: FrankBackendTypes.PaginationArgumentDto;
    }
  >(QUERY, {
    fetchPolicy: "network-only",
    variables: {
      coworkerId,
      pagination: { page: 0, perPage },
    },
  });

  const refetchCoworkerActivity = React.useCallback(() => {
    return refetch({
      coworkerId,
      pagination: {
        page: 0,
        perPage,
      },
    });
  }, [refetch, coworkerId]);

  const loadNextPage = React.useCallback(() => {
    fetchMore({
      variables: {
        coworkerId,
        pagination: {
          perPage,
          page: Math.round(data?.coworker.activity.objects.length / perPage),
        },
      },
    });
  }, [fetchMore, data, coworkerId]);

  return {
    loadingCoworkerActivity: loading,
    refetchCoworkerActivity,
    hasNextPage: data?.coworker.activity.hasNext,
    activity: data?.coworker.activity.objects,
    name: data?.coworker.name,
    loadNextPage,
  };
}

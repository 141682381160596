import React from "react";
import { Button } from "@get-frank-eng/design-system/dist/components/Button";
import { DocumentsForCoworkerQueryResult } from "../../generated-hooks";
import {
  DocumentType,
  FrankDocument,
} from "frank-types/dist/frank-backend-types";
import AuthCardDocumentItem from "./documents/AuthCardDocumentItem";
import CustomFormResponseDocumentItem from "./documents/CustomFormResponseDocumentItem";
import PetitionDocumentItem from "./documents/PetitionDocumentItem";
import ResourceDocumentItem from "./documents/ResourceDocumentItem";
import { useTranslation } from "react-i18next";

const buildDocumentItem = (
  document: FrankDocument,
  onUpdate: () => Promise<void>
) => {
  switch (document.type) {
    case DocumentType.Resource: {
      return (
        <ResourceDocumentItem
          key={document.resource.id}
          resource={document.resource!}
        />
      );
    }
    case DocumentType.CustomFormResponse: {
      return (
        <CustomFormResponseDocumentItem
          key={document.customFormResponse.id}
          response={document.customFormResponse!}
          onUpdate={onUpdate}
        />
      );
    }
    case DocumentType.Petition: {
      return (
        <PetitionDocumentItem
          key={document.petition.id}
          petition={document.petition!}
        />
      );
    }
    case DocumentType.AuthCardSignature: {
      return (
        <AuthCardDocumentItem
          key={document.authCardSignature.id}
          signature={document.authCardSignature!}
        />
      );
    }
    default:
      throw new Error(`Unknown document type: ${document.type}`);
  }
};

const DocumentsList = ({
  documents,
  onUpdate,
}: {
  documents: FrankDocument[];
  onUpdate: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const perPage = 6;
  const [currentItems, setCurrentItems] = React.useState<FrankDocument[]>([]);
  const [currentItemPage, setCurrentItemPage] = React.useState(1);
  React.useEffect(() => {
    if (documents === undefined) {
      return;
    }
    setCurrentItems(documents.slice(0, perPage * currentItemPage));
  }, [documents, currentItemPage]);
  const documentItems = React.useMemo(
    () => currentItems.map((doc) => buildDocumentItem(doc, onUpdate)),
    [currentItems]
  );
  if (currentItems.length === 0) {
    return <div />;
  }
  return (
    <div className="pb-4">
      <h2 className="t-large plus mb-3">Your Documents</h2>
      <ul className="grid space-y-2">{documentItems}</ul>
      {documents.length > currentItems.length && (
        <div className="w-full text-center py-4">
          <Button onClick={() => setCurrentItemPage(currentItemPage + 1)}>
            {t("showMore")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DocumentsList;

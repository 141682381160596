import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation AssignTask($input: AssignTaskInput!) {
    assignTask(input: $input) {
      nothing
    }
  }
`;

export default function useAssignTask() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "assignTask">,
    FrankBackendTypes.MutationAssignTaskArgs
  >(mutation);

  const assignTask = React.useCallback(
    async (input: FrankBackendTypes.AssignTaskInput) => {
      const { data } = await invokeMutation({
        variables: { input },
      });
      return data?.assignTask;
    },
    [invokeMutation]
  );

  return {
    assignTask,
    loading,
    error,
    called,
  };
}

import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import useCampaignList from "./dataAccess/queries/useCampaignList";
import Loading from "../components/Loading";
import CampaignList from "../Campaigns/CampaignList";
import { CardGrid } from "../Campaigns/CampaignCardWrapper";
import { EmptyState } from "../components/EmptyState";
import { TemplateGallery } from "../Templates";
import useImportTemplate from "../Templates/dataAccess/mutations/useImportTemplate";
import {
  Icon,
  LinkButton,
  ResourceCard,
  Sizes,
  ScreenSizes,
  useResponsive,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { CampaignWorkflowState } from "frank-types/dist/frank-backend-types";
import { useTranslation } from "react-i18next";

export const PageLayout = ({ children }) => {
  return (
    <div className="md:mx-auto px-3 md:px-16 py-8 md:py-20 md:max-w-6xl">
      {children}
    </div>
  );
};

const CampaignDraftListPage = () => {
  const { resources, loading, campaigns, error } = useCampaignList({
    workflowState: CampaignWorkflowState.Draft,
  });

  const { importTemplateCalled, importTemplateData } = useImportTemplate();

  const { screenSize } = useResponsive();
  const { t } = useTranslation();

  if (error) {
    throw new Error(error.message);
  }
  if (loading) {
    return <Loading />;
  }

  if (importTemplateCalled && importTemplateData) {
    return (
      <Redirect
        to={`/campaigns/${importTemplateData.importTemplate.id}/builder`}
      />
    );
  }

  const campaignsNotEmpty = campaigns.length > 0;

  return (
    <PageLayout>
      <div className="flex flex-row justify-between items-center border-b pb-6">
        <h1 className="t-title-4 plus">{t("petitions")}</h1>
        {screenSize > ScreenSizes.SM ? (
          <LinkButton iconRight="add" buttonStyle="outline" to="/templates">
            {t("newPetition")}
          </LinkButton>
        ) : (
          <Link to="/templates">
            <div className="t-small plus flex flex-row items-center">
              <Icon size={Sizes.MD} icon="add" />
              {t("new")}
            </div>
          </Link>
        )}
      </div>
      {!campaignsNotEmpty && (
        <div className="flex flex-row justify-center py-32 px-16">
          <EmptyState text={t("noPetitionsYet")} />
        </div>
      )}
      {campaignsNotEmpty && (
        <section id="your-organized-campaigns" className="border-b py-12">
          <h2 className="t-large plus mb-6">{t("draftPetitions")}</h2>
          <CampaignList
            campaigns={campaigns.filter(
              (c) =>
                c.workflowState !==
                FrankBackendTypes.CampaignWorkflowState.Archived
            )}
          />
        </section>
      )}

      <section id="templates" className="border-b py-12">
        <h2 className="t-large mb-6">{t("templates")}</h2>
        <TemplateGallery showBlank />
      </section>

      <section id="recommended-resources" className="pt-12 pb-16 sm:py-12">
        <h2 className="t-large plus mb-6">{t("recommendedResources")}</h2>
        <CardGrid size={screenSize <= ScreenSizes.SM ? undefined : "medium"}>
          {resources.map((resource) => (
            <ResourceCard
              topic={t("labor")}
              resourceLabel={resource.label}
              title={resource.title}
              url={resource.url}
              key={resource.url}
              imageUrl={resource.imageUrl}
            />
          ))}
        </CardGrid>
      </section>
    </PageLayout>
  );
};

export default CampaignDraftListPage;

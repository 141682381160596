import * as React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import {
  Button,
  Icon,
  JumboAvatar,
  Nudge,
  Sizes,
  Tooltip,
} from "@get-frank-eng/design-system";
import SecurityShield from "../../components/icons/SecurityShield";
import { useModals } from "../../Modals";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import useAuthorizationCardSignature from "../../Partner/dataAccess/hooks/queries/useAuthorizationCardSignature";
import { AbilityAction } from "frank-types";
import { useTranslation, Trans } from "react-i18next";

const dateFormatter = (lng) =>
  new Intl.DateTimeFormat(lng, {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

const ProfileSide = ({ uploadAvatarCallback, profile }) => {
  const { t } = useTranslation();
  const { preferredLanguage } = useCurrentUserData();
  const { setModal } = useModals();
  const openFlagModal = React.useCallback(() => {
    setModal({
      type: "flagModal",
      props: {
        toFlag: [{ id: profile.id, label: profile.shortName }],
      },
    });
  }, [setModal, profile]);

  const {
    currentGroup: { organizers, id },
    ability,
  } = useCurrentUserData();

  const { signature } = useAuthorizationCardSignature({
    userId: profile.id,
    groupId: id,
  });

  const organizesCurrentGroup = React.useMemo(() => {
    return organizers.find((u) => u.userId === profile?.id);
  }, [organizers, profile]);

  const signedCardIcon = (
    <div
      className="border-4 rounded-full border-canvas-100 px-1"
      style={{ backgroundColor: "#1d706b" }}
    >
      <Nudge y={1}>
        <Icon icon="how_to_vote" size={Sizes.MD} />
      </Nudge>
    </div>
  );

  return (
    <div className="sticky border" style={{ top: "125px" }}>
      <div className="flex flex-col items-center">
        <div
          className={classNames("py-4 relative", {
            "cursor-pointer": profile?.isYou,
          })}
        >
          <button
            type="button"
            className={classNames("focus:outline-none", {
              "pointer-events-none": !profile?.isYou,
            })}
            onClick={profile?.isYou ? uploadAvatarCallback : undefined}
            disabled={!profile?.isYou}
          >
            <JumboAvatar
              altText={profile?.shortName}
              src={profile?.profilePic?.url}
            />
          </button>
          <Nudge x={100} y={-25}>
            <SecurityShield />
          </Nudge>
        </div>
        <div className="t-title-4 plus-plus text-center">{profile?.name}</div>
        <p className="t-small">
          {t("joinedDate", {
            date:
              profile?.createdAt &&
              dateFormatter(preferredLanguage).format(
                new Date(profile.createdAt)
              ),
          })}
        </p>
        <div className="border-b w-4/5 my-6" />
      </div>
      <div className="mt-1 mb-2 p-4">
        <p className="t-regular plus-plus py-1">{t("verifiedWorker")}</p>
        {profile.invite.inviter && (
          <div className="flex flex-col py-1">
            <div className="flex-row flex items-center">
              <div className="flex-grow">
                <p className="t-small leading-loose">
                  <Trans i18nKey="invitedByInviterName">
                    Invited by{" "}
                    <Link to={`/users/${profile.invite.inviterId}`}>
                      {{ inviterName: profile.invite.inviter.name }}
                    </Link>
                  </Trans>
                </p>
                <p className="t-micro text-canvas-400">
                  {t("invitedInvitedDate", {
                    date:
                      profile.invite.createdAt &&
                      dateFormatter(preferredLanguage).format(
                        new Date(profile.invite.createdAt)
                      ),
                  })}
                </p>
              </div>
              <div className="flex-shrink-0">
                <SecurityShield />
              </div>
            </div>
          </div>
        )}
        {profile.emailVerificationDate && (
          <div className="flex flex-col py-1">
            <div className="flex-row flex items-center">
              <div className="flex-grow">
                <p className="t-small leading-loose">{t("emailVerified")}</p>
                <p className="t-micro text-canvas-400">
                  {t("verifiedDate", {
                    date: dateFormatter(preferredLanguage).format(
                      new Date(profile.emailVerificationDate)
                    ),
                  })}
                </p>
              </div>
              <div className="flex-shrink-0">
                <SecurityShield />
              </div>
            </div>
          </div>
        )}
        {signature &&
          (profile.isYou ||
            ability.can(AbilityAction.Manage, "AuthorizationCard")) && (
            <div className="flex flex-col py-1">
              <div className="flex-row flex items-center">
                <div className="flex-grow">
                  <p className="t-small leading-loose">{t("cardSigned")}</p>
                  <p className="t-micro text-canvas-400">
                    {t("signedAuthCardDate", {
                      date: dateFormatter(preferredLanguage).format(
                        new Date(signature.createdAt)
                      ),
                    })}
                  </p>
                </div>
                <div className="flex-shrink-0">{signedCardIcon}</div>
              </div>
            </div>
          )}
        <div className="flex flex-col py-1">
          <div className="flex flex-col py-2">
            {!profile?.isYou && !organizesCurrentGroup && (
              <Tooltip
                trigger="hover"
                nudgeY={10}
                tooltip={t("youWillBeAskedToShareInformationAbout")}
              >
                <Button
                  className="text-brand-400"
                  full
                  onClick={openFlagModal}
                  buttonStyle="outline"
                  iconRight="help"
                >
                  {t("flag")}
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSide;

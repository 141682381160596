import * as React from "react";
import { useModals } from "../Modals";
import { Button, ModalBody, ModalHeader } from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";

interface ConfirmationModalProps {
  action: () => any;
  title: string;
  bodyText: string | React.ReactChildren | React.ReactChild;
  actionText: string;
  actionButtonDisabled?: boolean;
  cancel?: () => any;
  cancelText?: string; 
}

const ConfirmationModal = ({
  action,
  title,
  bodyText,
  actionText,
  actionButtonDisabled = false,
  cancel,
  cancelText
}: ConfirmationModalProps) => {
  const { closeModal, stackSize, modalBack } = useModals();
  const { t } = useTranslation();
  return (
    <>
      <ModalHeader title={title} />
      <ModalBody>
        <p className="t-small">{bodyText}</p>
        <div className="sm:flex sm:flex-row sm:justify-end sm:items-center pt-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <Button buttonStyle="secondary" onClick={cancel ?? closeModal}>
              {cancelText ?? t("cancel")}
            </Button>
            <Button
              dataCy="confirmation-modal-confirm-button"
              disabled={actionButtonDisabled}
              buttonStyle="brand"
              onClick={() => {
                action();
                if (stackSize > 1) {
                  modalBack();
                } else {
                  closeModal();
                }
              }}
            >
              {actionText}
            </Button>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default ConfirmationModal;

import * as React from "react";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";

const GenericModal: React.FC<{
  title?: string;
  footer?: React.ReactChild;
  body: React.ReactElement;
}> = ({ title, body, footer }) => {
  return (
    <>
      {title && <ModalHeader title={title} />}
      <ModalBody>{body}</ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </>
  );
};

export default GenericModal;

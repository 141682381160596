import { Intent, useToaster } from "@get-frank-eng/design-system";
import { useModals } from "../../Modals";
import { useTranslation } from "react-i18next";

const useConfirmUnsignCampaignModal = ({
  unsign,
  refetch,
  afterUnsign,
}: {
  unsign: () => void;
  refetch: () => void;
  afterUnsign?: () => void;
}) => {
  const { t } = useTranslation();
  const { openConfirmationModal } = useModals();
  const toaster = useToaster();
  const useConfirmUnsign = async () => {
    const confirm = await openConfirmationModal({
      bodyText: t("thisCampaignNeedsYourSupportAndYou") as string,
      actionText: t("confirm"),
      title: t("areYouSureYouWantToRemoveYourSig"),
    });
    if (confirm) {
      await unsign();
      await refetch();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("youHaveRemovedYourSignature"),
      });
    }
    if (afterUnsign) {
      afterUnsign();
    }
  };

  return useConfirmUnsign;
};

export default useConfirmUnsignCampaignModal;

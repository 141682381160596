import {
  IconButton,
  Sizes,
  Button,
  OutlinePillBadge,
} from "@get-frank-eng/design-system";
import React from "react";

const PrimaryActionItem = ({
  expandable,
  isExpanded,
  setIsExpanded,
  children,
}: {
  expandable: boolean;
  isExpanded: boolean;
  setIsExpanded: (val: boolean) => void;
  children: JSX.Element;
}) => {
  const expand = React.useCallback(() => !isExpanded && setIsExpanded(true), [
    isExpanded,
    setIsExpanded,
  ]);
  const retract = React.useCallback(() => isExpanded && setIsExpanded(false), [
    isExpanded,
    setIsExpanded,
  ]);
  return (
    <>
      <div className="w-full text-left align-top rounded border bg-canvas-700 pb-4 px-4 mb-3 relative">
        <div
          className="flex flex-row justify-between pt-4"
          onClick={isExpanded ? retract : expand}
        >
          <div>
            <OutlinePillBadge
              size={Sizes.MD}
              textColor="brand-300"
              borderColor="brand-300"
            >
              Action needed
            </OutlinePillBadge>
          </div>
          {expandable && (
            <span>
              <IconButton
                icon={isExpanded ? "expand_less" : "expand_more"}
                size={Sizes.XL}
                buttonStyle="minimal"
              />
            </span>
          )}
        </div>
        <div
          className={isExpanded || !expandable ? "" : "h-40 overflow-hidden"}
        >
          {children}
        </div>
        {!isExpanded && expandable && (
          <>
            <div
              className="text-center absolute bottom-0 w-full pb-4 -ml-4 from-canvas-700 bg-gradient-to-t h-60"
              onClick={expand}
            ></div>
            <div className="w-full pt-4">
              <Button
                buttonStyle="brand"
                onClick={expand}
                dataCy="review-pending-membership-requests"
              >
                Get started
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PrimaryActionItem;

/* eslint-disable react/jsx-props-no-spreading */
import {
  FullCenter,
  Icon,
  Sizes,
  useScreenSize,
  ScreenSizes,
  useResponsive,
  Checkbox,
  Disclosure,
} from "@get-frank-eng/design-system";
import classNames from "classnames";
import keyBy from "lodash/keyBy";
import groupBy from "lodash/groupBy";
import * as React from "react";
import {
  useBlockLayout,
  useRowSelect,
  useTable,
  useResizeColumns,
  useSortBy,
} from "react-table";
import { useSticky } from "react-table-sticky";
import Loading from "../../components/Loading";
import { useModals } from "../../Modals";
import ColumnHeader from "../components/ColumnHeader";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import useColumns from "../dataAccess/mutations/useColumns";
import useCoworkerTrust from "../dataAccess/mutations/useCoworkerTrust";
import EditableTextCell from "../cellTypes/EditableTextCell";
import InnerCellSwitch from "./InnerCellSwitch";
import { FrankBackendTypes } from "frank-types";
import uniqueId from "lodash/uniqueId";
import Crossroads from "../../components/Crossroads";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";
export interface ActionBarParams {
  containerRef: React.MutableRefObject<HTMLDivElement>;
  toggleAllRowsSelected: () => any;
  selectedCoworkers: FrankBackendTypes.Coworker[];
}

const columnSizes = {
  [ScreenSizes.XXL]: 410,
  [ScreenSizes.XL]: 360,
  [ScreenSizes.LG]: 280,
  [ScreenSizes.MD]: 210,
  [ScreenSizes.SM]: 195,
  [ScreenSizes.XS]: 160,
};

const mockLoadingCoworkers = new Array(40).fill(null).map(
  (x) =>
    ({
      id: uniqueId(),
      archived: false,
      noteCount: 0,
    } as FrankBackendTypes.Coworker)
);
const Table = ({
  isScrolledX,
  loading,
  coworkerErrors = [],
  data,
  noHeaderOffset,
  fetchNextPage,
  allowAdd,
  allowSelect = true,
  renderActionBar,
  onSelectCoworker,
  columns,
  changeAndSetRecentlyAdded,
  changeLoading,
  computedCoworkers,
  customColumns,
  refetch,
  sortOrder,
  setSortOrder,
  infinite,
  view = "workers",
  addCoworker,
  currentlyHiddenColumns = [],
  setHiddenColumns,
}: {
  data: FrankBackendTypes.PaginatedCoworker;
  coworkerErrors?: FrankBackendTypes.CoworkerError[];
  noHeaderOffset?: boolean;
  isScrolledX: boolean;
  fetchNextPage: () => any;
  renderActionBar?: (
    params: ActionBarParams
  ) => FrankBackendTypes.Maybe<JSX.Element>;
  loading: boolean;
  allowAdd: boolean;
  allowSelect?: boolean;
  onSelectCoworker?: (selectedCoworkers: FrankBackendTypes.Coworker[]) => void;
  columns: any[];
  changeLoading?: boolean;
  computedCoworkers: FrankBackendTypes.Coworker[];
  changeAndSetRecentlyAdded?: (ch: any) => void;
  customColumns: FrankBackendTypes.CoworkerCustomColumn[];
  refetch?: () => void;
  sortOrder: {
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  };
  setSortOrder: (sortOrder: {
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  }) => void;
  infinite?: boolean;
  view?: "workers" | "management" | "members";
  addCoworker?: () => void;
  currentlyHiddenColumns?: string[];
  setHiddenColumns?: (string) => void;
}) => {
  const { t } = useTranslation();
  const breakpoint = useResponsive();
  const screenSize = useScreenSize();
  const smallScreen = breakpoint.screenSize <= ScreenSizes.SM;
  const evenColumnWidth = screenSize / columns.length;
  const defaultColumnWidth = Math.max(evenColumnWidth, 150);
  const { addColumn } = useColumns();

  const { setModal } = useModals();

  const {
    coworkerTrustVote,
    loadingCoworkerTrustVote: trustLoading,
  } = useCoworkerTrust();

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 175,
      width: smallScreen
        ? columnSizes[breakpoint.screenSize]
        : defaultColumnWidth,
      maxWidth: 600,
    }),
    [smallScreen, defaultColumnWidth, breakpoint]
  );

  const {
    getTableBodyProps,
    // @ts-ignore
    toggleAllRowsSelected,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    state: { selectedRowIds, hiddenColumns },
    allColumns,
  } = useTable<FrankBackendTypes.Coworker>(
    {
      columns,
      data: loading ? mockLoadingCoworkers : computedCoworkers,
      defaultColumn,
      // @ts-ignore
      autoResetSelectedRows: false,
      initialState: {
        hiddenColumns: currentlyHiddenColumns,
      },
    },
    useResizeColumns,
    useBlockLayout,
    useSticky,
    useSortBy,
    useRowSelect,
    (hooks) => {
      const width = allowSelect ? 56 : 0;
      if (view !== "members") {
        hooks.visibleColumns.push((existingColumns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            minWidth: width,
            width,
            maxWidth: width,
            sticky: "left",
            // @ts-ignore
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <FullCenter>
                {allowSelect && (
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                )}
              </FullCenter>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) =>
              allowSelect && (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              ),
            disableSortBy: true,
          },
          ...existingColumns,
        ]);
      }

      if (allowAdd) {
        hooks.visibleColumns.push((existingColumns) => [
          ...existingColumns,
          {
            id: "addColumn",
            minWidth: 35,
            width: 150,
            maxWidth: 200,
            Header: () => (
              <EditableTextCell
                changeCell={async (e) => {
                  if (e.length) {
                    await addColumn({ name: e });
                    await refetch();
                  }
                }}
                cell={{ value: null }}
                placeholder={t("addCustomColumn")}
                classNames="bg-canvas-700 t-micro plus-plus text-white"
              />
            ),
          },
        ]);
      }
    }
  );

  React.useEffect(() => {
    if (!setHiddenColumns) {
      return;
    }
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  const coworkerErrorsByCoworkerId = React.useMemo(
    () => groupBy(coworkerErrors, "coworkerId"),
    [coworkerErrors]
  );

  const ref = React.useRef<HTMLDivElement>();

  const openCoworkerHistory = React.useCallback(
    (coworkerId: string) => {
      setModal({
        type: "coworkerActivityModal",
        props: {
          change: (notes: string) => {
            changeAndSetRecentlyAdded({
              notes,
              id: coworkerId,
            });
          },
          coworkerId,
        },
      });
    },
    [setModal, changeAndSetRecentlyAdded]
  );

  const coworkersById = React.useMemo(() => keyBy(computedCoworkers, "id"), [
    computedCoworkers,
  ]);

  const selectedCoworkerIds = React.useMemo(
    () =>
      Object.keys(selectedRowIds).map((rowIdx) => computedCoworkers[rowIdx].id),
    [computedCoworkers, selectedRowIds]
  );

  const selectedCoworkers = React.useMemo(
    () => selectedCoworkerIds.map((id) => coworkersById[id]),
    [selectedCoworkerIds, coworkersById]
  );

  React.useEffect(() => {
    if (onSelectCoworker) {
      onSelectCoworker(selectedCoworkers);
    }
  }, [selectedCoworkers, onSelectCoworker]);

  if (!data.objects) {
    return (
      <div className="h-88">
        <Loading />
      </div>
    );
  }

  const tableProps = getTableBodyProps();
  tableProps.style = {
    ...(tableProps.style || {}),
    borderSpacing: "0",
    borderCollapse: "collapse",
  };
  return (
    <>
      <div
        className="px-4 mb-4 sticky left-0 self-start"
        // ugh gross I know - this matches the CSS in "CoworkerTableCard" to get it to stick to the left instead of scrolling with table
        style={{
          width: screenSize < ScreenSizes.MD ? "100vw" : "calc(100vw - 264px)",
        }}
      >
        <Disclosure
          title={<div className="t-mini plus">{t("hideColumns")}</div>}
        >
          <div className="max-w-xl grid grid-cols-4 gap-2">
            {allColumns.map((column) => {
              const props = column.getToggleHiddenProps();
              return (
                <label
                  htmlFor={column.id}
                  className="space-x-1"
                  key={column.id}
                >
                  <Checkbox
                    name={column.id}
                    checked={props.checked}
                    onChange={props.onChange}
                  />
                  <span className="t-mini">
                    {startCase(
                      typeof column.Header === "string"
                        ? column.Header
                        : // i18n TODO -- this causes "Add Column" to not be translated
                          startCase(column.id)
                    )}
                  </span>
                </label>
              );
            })}
          </div>
        </Disclosure>
      </div>
      <div
        ref={ref}
        {...tableProps}
        className={classNames([
          "coworker-table t-small",
          { "is-scrolled-x": isScrolledX },
          { "no-header-offset": noHeaderOffset },
        ])}
      >
        {renderActionBar &&
          renderActionBar({
            containerRef: ref,
            selectedCoworkers,
            toggleAllRowsSelected,
          })}
        <div className="header-group">
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps();

            return (
              <div {...headerGroupProps} className="header-row">
                {headerGroup.headers.map((column) => {
                  return (
                    <ColumnHeader
                      key={column.id}
                      refetch={refetch}
                      customColumns={customColumns}
                      column={column}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                      view={view}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        <div {...getTableBodyProps()} className="relative">
          {rows.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            rowProps.key = row.original.id;

            return (
              <div
                id={`c${row.original.id}`}
                {...rowProps}
                className={classNames([
                  { editable: row.original.editable },
                  "row",
                  {
                    "selected-row": selectedCoworkerIds.includes(
                      row.original.id
                    ),
                  },
                ])}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps();
                  cellProps.style.display = "flex";
                  const column = columns.find(
                    (c) => c.Header === cell.column.Header
                  );

                  if (column) {
                    if (typeof column.accessor === "function") {
                      cellProps.style.flexDirection = "column";
                    }
                  }
                  const errorsForCoworker =
                    coworkerErrorsByCoworkerId[row.original.id];
                  const errorsForColumn = errorsForCoworker?.filter(
                    (error) => error.column === cell.column.id
                  );

                  const innerSwitch = loading ? (
                    <div>
                      <div className="bg-canvas-700 h-2 animate-pulse" />
                    </div>
                  ) : (
                    <InnerCellSwitch
                      trustLoading={trustLoading}
                      coworkerTrustVote={coworkerTrustVote}
                      openCoworkerHistory={openCoworkerHistory}
                      key={`${row.original.id}-${cell.value}`}
                      change={changeAndSetRecentlyAdded}
                      cell={cell}
                      customColumns={customColumns}
                      addRow={addCoworker}
                      view={view}
                    />
                  );

                  const isEditable =
                    (row.original.editable && cell.column["editable"]) ||
                    cell.column["isAlwaysInteractable"];

                  return (
                    <div
                      className={`cell ${view ? view : ""} relative ${
                        isEditable && "hoverable"
                      } cell-type-${cell.column.id} ${
                        errorsForColumn?.length ? "error" : ""
                      }`}
                      {...cellProps}
                    >
                      <div className="flex-grow w-full">
                        {innerSwitch}
                        {errorsForColumn?.length > 0 && (
                          <>
                            <div className="truncate text-brand-600 t-micro">
                              {errorsForColumn[0]?.shortMessage}
                            </div>
                          </>
                        )}
                      </div>
                      {errorsForColumn?.length > 0 && (
                        <>
                          <span className="text-brand-600 ">
                            <Icon icon="error" size={Sizes.MD} />
                          </span>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {infinite && data?.hasNext && <Crossroads onEntering={fetchNextPage} />}
      </div>
    </>
  );
};

export default Table;

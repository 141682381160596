import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import useTableDataFetcher from "./dataAccess/useTableDataFetcher";
import useIsScrolled from "./hooks/useIsScrolled";
import tablePresentations from "./table/tablePresentations";
import { transformRawColumnData } from "./util";
import Table from "./table";
import { Button, LinkButton, Sizes } from "@get-frank-eng/design-system";
import { CoworkerTableCard } from "./components/CoworkerTableCard";
import useRecentlyAdded from "./dataAccess/useRecentlyAdded";
import { CoworkerEmploymentType } from "frank-types/dist/frank-backend-types";
import { useTranslation } from "react-i18next";

export const ManagementTable = ({ showAll = false }: { showAll?: boolean }) => {
  const setting = tablePresentations["management"];
  const ref = React.useRef<HTMLDivElement>();
  const { isScrolledX } = useIsScrolled<HTMLDivElement>(ref);

  const [sortOrder, setSortOrder] = React.useState<{
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  }>({
    column: null,
    order: null,
  });

  const { t } = useTranslation();
  const { data, loading, fetchNextPage, refetch } = useTableDataFetcher({
    filter: setting.filter,
    order: sortOrder.column ? sortOrder : undefined,
  });

  const customColumns = data?.customColumns;

  const computedColumns = React.useMemo(
    () => setting.columns.concat(transformRawColumnData(customColumns)),
    [customColumns, setting.columns]
  );

  // this doesn't actually show or hide columns in the table - it helps us preserve what's hidden through refetching data, which is important for sorting
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([]);

  const {
    computedCoworkers,
    changeAndSetRecentlyAdded,
    changeLoading,
    coworkerErrors,
    addCoworker,
  } = useRecentlyAdded({
    coworkers: data?.coworkers.objects,
    allowAdd: setting.allowAdd,
    defaultForNewRows: { employmentType: CoworkerEmploymentType.Management },
    refetchFromBackend: refetch,
  });

  if (data?.coworkers.total < 1) {
    return null;
  }

  return (
    <>
      <CoworkerTableCard
        rightTop={
          <Button onClick={addCoworker} size={Sizes.SM} className="mt-4">
            {t("addRow")}
          </Button>
        }
        subtitle={t("noManagersAreAllowedToUseFrank")}
        title={t("blockedManagers")}
        to="/coworkers/management"
        loading={!data?.coworkers}
      >
        {data?.coworkers && (
          <Table
            key={`${JSON.stringify(setting.filter)} ${JSON.stringify(
              sortOrder
            )}`}
            data={data.coworkers}
            columns={setting.allowAdd ? computedColumns : setting.columns}
            allowAdd={setting.allowAdd}
            loading={loading}
            fetchNextPage={fetchNextPage}
            coworkerErrors={coworkerErrors}
            changeAndSetRecentlyAdded={changeAndSetRecentlyAdded}
            changeLoading={changeLoading}
            isScrolledX={isScrolledX}
            computedCoworkers={computedCoworkers}
            customColumns={customColumns}
            refetch={refetch}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            infinite={showAll}
            view="management"
            addCoworker={addCoworker}
            setHiddenColumns={setHiddenColumns}
            currentlyHiddenColumns={hiddenColumns}
          />
        )}
      </CoworkerTableCard>

      {data?.coworkers.hasNext && !showAll && (
        <div className="inline-block sticky left-0">
          <LinkButton
            to="/coworkers/management"
            style={{ justifyContent: "flex-start" }}
            buttonStyle="outline"
            className="mt-4 ml-4"
          >
            {t("showAllRowCount", { rowCount: data.coworkers.total })}
          </LinkButton>
        </div>
      )}
    </>
  );
};

import * as React from "react";
import { DataField } from "frank-types/dist/frank-backend-types";
import { Checkbox } from "@get-frank-eng/design-system/dist/components/forms/Checkbox";

interface HasToString {
  toString: () => string;
}

const simpleHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
};

export default function DataFieldCheckbox<T extends HasToString>({
  field,
  value,
  disabled,
  label,
  checked,
  onCheck,
  onUncheck,
}: {
  field: DataField;
  value: T;
  disabled: boolean;
  label: string;
  checked: boolean;
  onCheck: (field: DataField, val: T) => void;
  onUncheck: (field: DataField, val: T) => void;
}): JSX.Element {
  const fieldId = React.useMemo(
    () => simpleHash(`${field.id}-${value.toString()}`),
    [field, value]
  );
  const onClick = React.useCallback(() => {
    if (disabled) {
      return;
    }
    if (!checked) {
      onCheck(field, value);
    } else {
      onUncheck(field, value);
    }
  }, [onCheck, onUncheck, checked, disabled]);
  return (
    <>
      <Checkbox
        id={fieldId}
        name={fieldId}
        checked={checked}
        disabled={disabled}
        onChange={onClick}
      />
      <label className="ml-2 t-label-2" onClick={onClick}>
        {label}
      </label>
    </>
  );
}

import {
  FormGroup,
  Input,
  FormRenderer,
  Button,
  SelectOption,
  Avatar,
  Sizes,
  useToaster,
  Intent,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useConfirmAuthCardMutation } from "../../generated-hooks";
import sentryClient from "../../sentryClient";

type FormParams = {
  firstName: string;
  lastName: string;
  email: string;
  employer: string;
  phone: string;
  signature: string;
};

export const AuthorizationCardComponent = ({
  onSubmit,
  authorizationLanguage,
  isPreview = false,
  signature,
  closeModal,
  employer,
  previewAdditionalFields,
  myProfile,
  additionalFields,
  preferredLanguage,
  loading,
  showCancel = true,
  partnerLogoUrl,
  partnerName,
  refetch,
  email,
}: {
  onSubmit: (args: any) => void;
  isPreview?: boolean;
  authorizationLanguage: string;
  signature?: FrankBackendTypes.AuthorizationCardSignature;
  closeModal?: () => void;
  employer?: string;
  previewAdditionalFields?: SelectOption[];
  myProfile?: FrankBackendTypes.MyProfile;
  additionalFields?: string;
  preferredLanguage: string;
  loading: boolean;
  showCancel?: boolean;
  partnerLogoUrl: string;
  partnerName: string;
  refetch?: () => void;
  email?: string;
}) => {
  const toaster = useToaster();
  const { t } = useTranslation();
  const [showConfirmWarning, setShowConfirmWarning] = React.useState<boolean>(
    false
  );

  const { register, handleSubmit, errors, watch } = useForm<FormParams>();

  const values = watch();

  const isIncomplete = React.useCallback(() => {
    return (
      Object.values(values)
        .map((v) => v.trim())
        .filter((v) => !v.length).length > 0
    );
  }, [values]);

  const [
    invokeConfirmAuthCard,
    { error: invokeConfirmAuthCardError },
  ] = useConfirmAuthCardMutation();

  React.useEffect(() => {
    if (!signature) {
      return setShowConfirmWarning(false);
    }

    if (signature.confirmedByUser) {
      return setShowConfirmWarning(false);
    }

    setShowConfirmWarning(true);
  }, [signature]);

  const confirmAuthCard = React.useCallback(async () => {
    try {
      await invokeConfirmAuthCard({
        variables: { signatureId: signature?.id },
      });
      refetch && (await refetch());
      toaster.addToast({ children: t("success"), intent: Intent.SUCCESS });
    } catch (e) {
      toaster.addToast({
        children: t("somethingWentWrong"),
        intent: Intent.FAILURE,
      });

      sentryClient.captureException(e);
    }
    if (invokeConfirmAuthCardError) {
      toaster.addToast({
        children: t("somethingWentWrong"),
        intent: Intent.FAILURE,
      });
      sentryClient.captureException(invokeConfirmAuthCardError);
    }
    setShowConfirmWarning(false);
  }, [
    invokeConfirmAuthCardError,
    invokeConfirmAuthCard,
    signature,
    toaster,
    t,
    refetch,
  ]);

  return (
    <div className="space-y-4">
      {showConfirmWarning && (
        <div>
          <div className="space-y-2 border rounded p-4 mb-8">
            <div className="t-label-2">{t("important")}</div>
            <div className="t-regular">{t("signatureNotVerifiedWarning")}</div>
            <div className="flex flex-row justify-center">
              <Button
                buttonStyle="brand"
                size={Sizes.LG}
                onClick={confirmAuthCard}
              >
                {t("verifySignature")}
              </Button>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${
          showConfirmWarning && "border border-canvas-100 p-4 rounded"
        }`}
      >
        <div className="flex items-center space-x-4">
          <Avatar src={partnerLogoUrl} size={Sizes.XL} />
          <div>
            <h2 className="t-large plus">{t("authorizationCard")}</h2>
            <p className="t-small max-w-2xl">
              {t("bySigningThisCardYouAuthorizePar", {
                partnerName,
              })}
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} id="authorization-card-form">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-2">
            <FormGroup label={t("firstName")} name="firstName" id="firstName">
              <Input
                defaultValue={
                  isPreview
                    ? null
                    : signature?.firstName || myProfile?.firstName
                }
                disabled={!!signature || isPreview}
                register={register}
                registerArgs={{
                  required: t("thisFieldIsRequired"),
                }}
                errorText={errors?.firstName?.message}
                name="firstName"
              />
            </FormGroup>
            <FormGroup label={t("lastName")} name="lastName" id="lastName">
              <Input
                defaultValue={
                  isPreview ? null : signature?.lastName || myProfile?.lastName
                }
                disabled={!!signature || isPreview}
                register={register}
                registerArgs={{
                  required: t("thisFieldIsRequired"),
                }}
                errorText={errors?.lastName?.message}
                name="lastName"
              />
            </FormGroup>
            <FormGroup label={t("email")} name="email" id="email">
              <Input
                defaultValue={isPreview ? null : myProfile?.email || email}
                disabled={!!myProfile?.email || !!email}
                register={register}
                registerArgs={{
                  required: t("thisFieldIsRequired"),
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: t("pleaseEnterAValidEmail"),
                  },
                }}
                errorText={errors?.email?.message}
                name="email"
              />
            </FormGroup>
            <FormGroup label={t("date")} name="date" id="date">
              <Input
                defaultValue={
                  signature
                    ? new Date(signature.createdAt).toLocaleString()
                    : new Date().toLocaleDateString()
                }
                disabled
                name="date"
              />
            </FormGroup>
            <FormGroup label={t("employer")} name="employer" id="employer">
              <Input
                defaultValue={employer || signature?.employer}
                disabled
                register={register}
                registerArgs={{
                  required: t("thisFieldIsRequired"),
                }}
                errorText={errors?.employer?.message}
                name="employer"
              />
            </FormGroup>
            <FormGroup label={t("phone")} name="phone" id="phone">
              <Input
                defaultValue={isPreview ? null : signature?.phone}
                disabled={!!signature || isPreview}
                register={register}
                helpText={t("pleaseUseThisFormat1234567890") as string}
                registerArgs={{
                  required: t("thisFieldIsRequired"),
                  pattern: {
                    value: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i,
                    message: t("pleaseEnterAValidPhoneNumberWithF"),
                  },
                }}
                errorText={errors?.phone?.message}
                name="phone"
              />
            </FormGroup>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-2">
            {previewAdditionalFields &&
              previewAdditionalFields.map((f) => (
                <FormGroup label={f.label} name={f.value} id={f.value}>
                  <Input
                    defaultValue={null}
                    disabled
                    register={register}
                    name={f.value}
                  />
                </FormGroup>
              ))}
          </div>
          {additionalFields && (
            <FormRenderer
              className="grid grid-cols-1 sm:grid-cols-2 gap-x-2"
              errors={errors}
              schema={JSON.parse(additionalFields)}
              register={register}
              currentData={signature?.authCardFormResponseJSON}
              disableFields={!!signature || isPreview}
              lng={preferredLanguage}
            />
          )}

          <div className="max-w-2xl pt-8">
            <div className="t-small">{authorizationLanguage}</div>
            <FormGroup label={t("signature")} name="signature" id="signature">
              <Input
                style={{ fontFamily: "cursive" }}
                defaultValue={isPreview ? null : signature?.signature}
                disabled={!!signature || isPreview}
                register={register}
                registerArgs={{ required: t("thisFieldIsRequired") }}
                errorText={errors?.signature?.message}
                name="signature"
              />
            </FormGroup>
            {!signature && (
              <div className="flex space-x-2">
                <Button
                  form="authorization-card-form"
                  disabled={isPreview || isIncomplete()}
                  buttonStyle="brand"
                  loading={loading}
                >
                  {t("submit")}
                </Button>
                {showCancel && (
                  <Button
                    type="button"
                    buttonStyle="secondary"
                    onClick={closeModal}
                    disabled={isPreview}
                  >
                    {t("cancel")}
                  </Button>
                )}
              </div>
            )}
            {signature && (
              <div className="t-mini">
                {t("youHaveAlreadySignedThisAuthorizati")}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

import {
  Button,
  EmptyState,
  IconButton,
  InlineAlert,
  Intent,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import Loading from "../../components/Loading";
import { useModals } from "../../Modals";
import useCustomOnboardingForm from "../dataAccess/hooks/queries/useCustomOnboardingFormQuery";
import { useRequestDataExport } from "../utilityHooks/useRequestDataExport";
import { useTranslation } from "react-i18next";

const OnboardingFormResponses = () => {
  const { t } = useTranslation();
  const {
    onboardingFormResponses,
    keys,
    hasNext,
    error,
    loadNextPage,
    loading,
    refetch,
  } = useCustomOnboardingForm();
  const { partner } = useCurrentUserData();

  const { setModal } = useModals();

  const requestDataExport = useRequestDataExport();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <InlineAlert title={t("errorLoadingWorkers")} intent={Intent.FAILURE}>
        {t("somethingWentWrongWhileLoadingWorkers")}
      </InlineAlert>
    );
  }

  return (
    <div className="pb-48 sm:pb-0 max-w-6xl mx-auto w-full space-y-4">
      <Button iconLeft="import_export" onClick={requestDataExport}>
        {t("requestExport")}
      </Button>
      {onboardingFormResponses.length === 0 ? (
        <EmptyState title={t("noWorkersYet")}>
          {t("noneOfTheGroupsInPartnerNameHa", { partnerName: partner.name })}
        </EmptyState>
      ) : (
        <div className="mt-6 space-y-2 mb-16 border rounded overflow-x-auto">
          <table className="text-sm w-full">
            <thead>
              {keys.onboardingForm.length > 0 && (
                <tr className="border-b text-xs divide-x divide-canvas-500">
                  <th
                    className="text-left p-2  bg-canvas-700"
                    colSpan={keys.onboardingForm.length}
                  >
                    {t("customOnboardingForm")}
                  </th>
                  <th className="text-left p-2" colSpan={keys.coworker.length}>
                    {t("frankInformation")}
                  </th>
                </tr>
              )}
              <tr className="border-b divide-x divide-canvas-500">
                {keys.onboardingForm.map((onboardingFormKey) => (
                  <th
                    className="text-left p-2  bg-canvas-700"
                    key={onboardingFormKey}
                  >
                    {onboardingFormKey}
                  </th>
                ))}
                {keys.coworker.map((coworkerKey) => (
                  <th className="text-left p-2" key={coworkerKey}>
                    {coworkerKey}
                  </th>
                ))}
                <th className="text-left p-2">{t("moveOrRemoveWorker")}</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-canvas-500">
              {onboardingFormResponses.map((o) => (
                <tr className="divide-x divide-canvas-500" key={o.id}>
                  {keys.onboardingForm.map((onboardingFormKey) => (
                    <td className="p-2" key={`${o.id}-${onboardingFormKey}`}>
                      {o.onboardingForm[onboardingFormKey]}
                    </td>
                  ))}
                  {keys.coworker.map((coworkerKey) => (
                    <td className="p-2" key={`${o.id}-${coworkerKey}`}>
                      {o.coworker[coworkerKey]}
                    </td>
                  ))}
                  <td className="p-2 text-center">
                    <IconButton
                      icon="compare_arrows"
                      buttonStyle="outline"
                      onClick={() =>
                        setModal({
                          afterClose: refetch,
                          type: "moveWorkerModal",
                          props: {
                            partnerId: partner.id,
                            coworker: o.coworker,
                          },
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {hasNext ? (
        <div className="py-8">
          <Button onClick={loadNextPage}>{t("showMore")}</Button>
        </div>
      ) : (
        <div className="pt-48" />
      )}
    </div>
  );
};

export default OnboardingFormResponses;

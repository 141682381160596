import { useMutation, gql } from "@apollo/client";
import {
  Button,
  FormGroup,
  FormRenderer,
  Input,
  Sizes,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import createPersistedState from "use-persisted-state";
import AddPhoto from "./components/AddPhoto";
import { DispatcherAPI } from "./state/dispatcher";
import { CacheAPI, CachedFormState } from "./state/onboardingCache";
import { OnboardingStep } from "./state/state";
import omit from "lodash/omit";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { findLanguage } from "../utils/findLanguage";

const useGroupsLocalStorageState = createPersistedState("groupId");

const TELL_US_ABOUT_YOURSELF = gql`
  mutation TellUsAboutYourself($input: TellUsMoreAboutYourselfDTO!) {
    tellUsMoreAboutYourself(input: $input) {
      nothing
    }
  }
`;

interface FormData {
  profileName: string;
}

function useSubmit({
  onSubmit,
  setServerError,
  setLoading,
}: {
  onSubmit: any;
  setServerError: DispatcherAPI["setServerError"];
  setLoading: DispatcherAPI["setLoading"];
}) {
  const [invokeTellUs, { error: saveError }] = useMutation<
    any,
    FrankBackendTypes.MutationTellUsMoreAboutYourselfArgs
  >(TELL_US_ABOUT_YOURSELF);

  const submit = React.useCallback(
    async (formdata: FormData) => {
      setLoading(OnboardingStep.TELL_US_ABOUT_YOURSELF);
      try {
        const onboardingFormValues = omit(formdata, ["profileName"]);
        await invokeTellUs({
          variables: {
            input: {
              profileName: formdata.profileName,
              onboardingFormResponses: isEmpty(onboardingFormValues)
                ? undefined
                : JSON.stringify(onboardingFormValues),
            },
          },
        });
        onSubmit(formdata);
      } catch (e) {
        setServerError("tellUsAboutYourself", "tell us error");
        throw e;
      }
    },
    [setLoading, onSubmit, setServerError, invokeTellUs]
  );

  return { submit, saveError };
}

const TellUsAboutYourself = ({
  onSubmit,
  setCache,
  cache,
  setLoading,
  setServerError,
  isLoading,
  onboardingFormSchema,
}: {
  onSubmit: any;
  cache: CachedFormState;
  setCache: CacheAPI["setCache"];
  setLoading: DispatcherAPI["setLoading"];
  setServerError: DispatcherAPI["setServerError"];
  isLoading: boolean;
  isSubmitted: boolean;
  onboardingFormSchema?: string;
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setValue } = useForm<FormData>();

  const profilePicRef = React.useRef(null);

  React.useEffect(() => {
    setValue("avatarComplete", !!cache["tellUs.profilePictureUrl"]);
  }, [setValue, cache]);

  const { submit } = useSubmit({
    onSubmit,
    setServerError,
    setLoading,
  });

  const fieldsDisabled = isLoading;

  return (
    <div id="tell-us-about-yourself" className="mt-12">
      <form className="text-left space-y-8" onSubmit={handleSubmit(submit)}>
        <div ref={profilePicRef} className="flex items-center flex-col">
          <div className="t-regular plus">{t("addYourProfilePhoto")}</div>
          <div className="t-small">
            {t("yourCoworkersShouldRecognizeYouInT")}
          </div>
          <AddPhoto
            defaultUrl={cache["tellUs.profilePictureUrl"]}
            onUpload={(err, url) => {
              console.log(err, url);
              if (err) {
                return;
              }
              setCache("tellUs.profilePictureUrl", url);
            }}
          />
        </div>
        <FormGroup name="profileName" label={t("fullName")} id="profile-name">
          <Input
            errorText={
              errors.profileName && (
                <>
                  {errors.profileName.type === "required" &&
                    t("profileNameIsRequiredToUseFrank")}
                  {errors.profileName.type === "maxLength" &&
                    t("profileNameIsTooLong")}
                </>
              )
            }
            helpText={t("whatIsTheNameYouUseAtWorkThisW")}
            autoFocus
            type="text"
            disabled={fieldsDisabled}
            onBlur={(e) =>
              setCache(
                "tellUs.profileName",
                (e.target as HTMLInputElement).value
              )
            }
            defaultValue={cache["tellUs.profileName"]}
            register={register}
            registerArgs={{ required: true, maxLength: 70 }}
            dataCy="profile-name"
          />
        </FormGroup>
        {onboardingFormSchema && (
          <FormRenderer
            errors={errors}
            schema={JSON.parse(onboardingFormSchema)}
            register={register}
            lng={findLanguage(navigator.language)}
          />
        )}

        <div className="text-center">
          <Button
            type="submit"
            buttonStyle="brand"
            loading={isLoading}
            dataCy="submit-tell-us-about-yourself"
            size={Sizes.LG}
            full
          >
            {t("continueToStep3")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default TellUsAboutYourself;

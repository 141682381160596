import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query Coworkers(
    $pagination: PaginationArgumentDTO!
    $order: CoworkerOrderArgumentDTO
    $filter: CoworkerFilterDTO!
  ) {
    coworkerSummary {
      lead
      onboarding
      member
      archived
      flagged
      invited
      inReview
      organizer
    }
    customColumns {
      id
      name
    }
    coworkers(pagination: $pagination, order: $order, filter: $filter) {
      hasNext
      total
      objects {
        id
        name
        email
        title
        employmentType
        shouldWarnBeforeInvite
        status
        notes
        notifications
        noteCount
        editable
        joinedAtFormatted
        totalTrustUpvotes
        totalTrustDownvotes
        yourTrustLevel
        trustJudgementCount
        netTrust
        canArchive
        canUnArchive
        canVoteOnTrust
        canFlag
        canInvite
        canInviteToCampaign
        isEmpty
        customData {
          id
          value
          key
        }
        user {
          id
          name
          profilePic {
            id
            url
          }
        }
      }
    }
  }
`;

const useTableDataFetcher = ({
  order,
  filter,
  limit,
}: {
  filter: FrankBackendTypes.CoworkerFilterDto;
  order?: FrankBackendTypes.CoworkerOrderArgumentDto;
  limit?: number;
}) => {
  const perPage = limit || 200;
  const { loading, error, data, refetch, fetchMore, called } = useQuery<
    {
      coworkers: FrankBackendTypes.PaginatedCoworker;
      coworkerSummary: FrankBackendTypes.CoworkerSummaryDto;
      customColumns: FrankBackendTypes.CoworkerCustomColumn[];
    },
    FrankBackendTypes.QueryCoworkersArgs
  >(QUERY, {
    variables: {
      pagination: { page: 0, perPage },
      order,
      filter,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const fetchNextPage = React.useCallback(() => {
    fetchMore({
      variables: {
        pagination: {
          perPage,
          page: Math.round(data.coworkers.objects.length / perPage),
        },
        filter,
        order,
      },
    });
  }, [fetchMore, order, data, filter, perPage]);

  return { fetchNextPage, loading, error, data, refetch, called };
};

export default useTableDataFetcher;

import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Frank from "../../components/icons/Frank";
import ShortFrank from "../../components/icons/ShortFrank";
import Loading from "../../components/Loading";
import {
  useConfirmAuthCardMutation,
  useVerifyEmailFromAuthCardMutation,
} from "../../generated-hooks";
import useQuery from "../../hooks/useQuery";
import sentryClient from "../../sentryClient";
import { ActionSwitch } from "../components/ActionSwitch";

type VerifyEmailFromAuthCard = {
  email: string;
  name: string;
  onboarding: FrankBackendTypes.Onboarding;
  partnerName: string;
};

type VerifyEmailFromAuthCardMutation = { __typename?: "Mutation" } & {
  verifyEmailFromAuthCard: { __typename?: "VerifyEmailFromAuthCard" } & Pick<
    VerifyEmailFromAuthCard,
    "email" | "name" | "partnerName"
  > & {
      onboarding: { __typename?: "Onboarding" } & Pick<
        FrankBackendTypes.Onboarding,
        "id" | "workflowState" | "token"
      > & {
          onboardingCache: Array<
            { __typename?: "OnboardingCacheItem" } & Pick<
              FrankBackendTypes.OnboardingCacheItem,
              "key" | "value" | "boolValue"
            >
          >;
        };
    };
};

const ConfirmAuthCard = () => {
  const { t } = useTranslation();

  const qs = useQuery();

  const onboardingId = qs.get("onboardingId");
  const signatureId = qs.get("signatureId");

  const [
    invokeVerifyEmailFromAuthCard,
    { error: invokeVerifyEmailError },
  ] = useVerifyEmailFromAuthCardMutation();

  const [
    invokeConfirmAuthCard,
    { error: invokeConfirmAuthCardError },
  ] = useConfirmAuthCardMutation();

  const [data, setData] = React.useState<VerifyEmailFromAuthCardMutation>();

  const verifyEmail = React.useCallback(async () => {
    try {
      const result = await invokeVerifyEmailFromAuthCard({
        variables: { onboardingId },
      });

      setData(result.data);
    } catch (e) {
      sentryClient.captureException(e);
    }

    if (invokeVerifyEmailError) {
      sentryClient.captureException(invokeVerifyEmailError);
    }
  }, [invokeVerifyEmailFromAuthCard, onboardingId, invokeVerifyEmailError]);

  const confirmAuthCard = React.useCallback(async () => {
    try {
      await invokeConfirmAuthCard({ variables: { signatureId } });
    } catch (e) {
      sentryClient.captureException(e);
    }
    if (invokeConfirmAuthCardError) {
      sentryClient.captureException(invokeConfirmAuthCardError);
    }
  }, [invokeConfirmAuthCard, signatureId, invokeConfirmAuthCardError]);

  React.useEffect(() => {
    verifyEmail();
  }, [verifyEmail]);

  React.useEffect(() => {
    confirmAuthCard();
  }, [confirmAuthCard]);

  if (!data) {
    return <Loading />;
  }

  return (
    <div className="mx-4 sm:mx-0 w-full flex flex-col justify-center items-center">
      <a
        href="https://getfrank.com"
        className="absolute top-0 left-0 mt-4 ml-6"
      >
        <Frank />
      </a>
      <div className="flex flex-row items-center justify-center sm:pt-32 sm:pt-0 sm:h-screen md:pb-32 bg-canvas-800 w-full">
        <div className="flex-1 flex flex-col self-center container sm:max-w-xl sm:mx-auto w-full">
          <div className="flex flex-col items-center justify-center text-center space-y-4">
            <div className="h-12 w-12 rounded-lg bg-canvas-700 flex flex-row justify-center items-center">
              <ShortFrank />
            </div>
            <div>{t("confirmAuthCard")}</div>
            <ActionSwitch
              hasAccount={
                data.verifyEmailFromAuthCard.onboarding.onboardingCache.find(
                  (v) => v.key === "createAccount.submitted"
                )?.boolValue
              }
              onboardingWorkflowState={
                data.verifyEmailFromAuthCard.onboarding.workflowState
              }
              email={data.verifyEmailFromAuthCard.email}
              name={data.verifyEmailFromAuthCard.name}
              onboardingId={data.verifyEmailFromAuthCard.onboarding.id}
              token={data.verifyEmailFromAuthCard.onboarding.token}
              partnerName={data.verifyEmailFromAuthCard.partnerName}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAuthCard;

import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import useTableDataFetcher from "./dataAccess/useTableDataFetcher";
import useIsScrolled from "./hooks/useIsScrolled";
import tablePresentations from "./table/tablePresentations";
import { transformRawColumnData } from "./util";
import Table from "./table";
import { LinkButton } from "@get-frank-eng/design-system";
import startCase from "lodash/startCase";
import { CoworkerTableCard } from "./components/CoworkerTableCard";
import { useCurrentUserData } from "../Auth/useCurrentUserData";
import { useTranslation } from "react-i18next";

export const MemberTable = ({ showAll = false }: { showAll?: boolean }) => {
  const { t } = useTranslation();
  const { currentGroup } = useCurrentUserData();
  const setting = tablePresentations["members"];
  const ref = React.useRef<HTMLDivElement>();
  const { isScrolledX } = useIsScrolled<HTMLDivElement>(ref);

  // this doesn't actually show or hide columns in the table - it helps us preserve what's hidden through refetching data, which is important for sorting
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([]);


  const [sortOrder, setSortOrder] = React.useState<{
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  }>({
    column: null,
    order: null,
  });

  const { data, loading, fetchNextPage, refetch } = useTableDataFetcher({
    filter: setting.filter,
    order: sortOrder.column ? sortOrder : undefined,
    limit: 10,
  });

  const counts = React.useMemo(() => {
    const { coworkerSummary } = data || {};
    return {
      workers:
        coworkerSummary?.lead +
        coworkerSummary?.onboarding +
        coworkerSummary?.invited +
        coworkerSummary?.inReview,
      members: coworkerSummary?.member + coworkerSummary?.organizer,
    };
  }, [data]);

  const customColumns = data?.customColumns;

  const computedColumns = React.useMemo(
    () => setting.columns.concat(transformRawColumnData(customColumns)),
    [customColumns, setting.columns]
  );

  return (
    <>
      <CoworkerTableCard
        subtitle={t("currentVerifiedMembersInGroupName", {
          groupName: currentGroup.name,
        })}
        title={
          counts.members
            ? startCase(
                t("verifiedMemberWithCount", {
                  count: counts.members,
                })
              )
            : t("unknownVerifiedMembers")
        }
        to="/coworkers/members"
        loading={!data?.coworkers}
      >
        {data?.coworkers && (
          <Table
            key={`${JSON.stringify(setting.filter)} ${JSON.stringify(
              sortOrder
            )}`}
            data={data.coworkers}
            columns={setting.allowAdd ? computedColumns : setting.columns}
            allowAdd={setting.allowAdd}
            loading={loading}
            fetchNextPage={fetchNextPage}
            isScrolledX={isScrolledX}
            computedCoworkers={data.coworkers.objects}
            customColumns={customColumns}
            refetch={refetch}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            infinite={showAll}
            view="members"
            setHiddenColumns={setHiddenColumns}
            currentlyHiddenColumns={hiddenColumns}
          />
        )}
      </CoworkerTableCard>

      {data?.coworkers.hasNext && !showAll && (
        <div className="inline-block sticky left-0">
          <LinkButton
            to="/coworkers/members"
            style={{ justifyContent: "flex-start" }}
            buttonStyle="outline"
            className="mt-4 ml-4"
          >
            {t("showAllRows")}
          </LinkButton>
        </div>
      )}
    </>
  );
};

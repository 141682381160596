import {
  Avatar,
  Button,
  FeatherIcon,
  Icon,
  Sizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation, Trans } from "react-i18next";

type InstallPromptContext = {
  showInstallPromo: boolean;
  onClickInstall: () => Promise<void>;
  standaloneMode: boolean;
  isMobileIos: boolean;
  isSafari: boolean;
};

const installPromptContext = React.createContext<InstallPromptContext>({
  showInstallPromo: false,
  onClickInstall: async () => {},
  standaloneMode: false,
  isMobileIos: false,
  isSafari: false,
});

export const InstallPromptProvider = ({ children }) => {
  // native browser events - for Android + Desktop Chrome
  const [deferredPrompt, setDeferredPrompt] = React.useState(null);
  const [showInstallPromo, setShowInstallPromo] = React.useState(false);

  // booleans to test if we should show special prompt for mobile iOS
  const [standaloneMode, setStandaloneMode] = React.useState();
  const [isMobileIos, setIsMobileIos] = React.useState(false);

  const [isSafari, setIsSafari] = React.useState(false);

  React.useEffect(() => {
    setStandaloneMode(
      "standalone" in window.navigator && window.navigator["standalone"]
    );
  }, [setStandaloneMode]);

  React.useEffect(() => {
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf("Apple") > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") === -1 &&
      navigator.userAgent.indexOf("FxiOS") === -1;

    setIsSafari(isSafari);
  }, [setIsSafari]);

  React.useEffect(() => {
    // note: this tests positive when messing around with responsive window sizing on desktop -- shouldn't be a problem with "real" users
    const isMobile = /Mobi/.test(navigator.userAgent);

    const isIos =
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document);

    setIsMobileIos(isIos && isMobile);
  }, [setIsMobileIos]);

  React.useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA
      setShowInstallPromo(true);
      // Optionally, send analytics event that PWA install promo was shown.
    });
  });

  React.useEffect(() => {
    window.addEventListener("appinstalled", () => {
      // Hide the app-provided install promotion
      setShowInstallPromo(false);
      // Clear the deferredPrompt so it can be garbage collected
      setDeferredPrompt(null);
      // Optionally, send analytics event to indicate successful install
    });
  });

  const onClickInstall = async () => {
    setShowInstallPromo(false);
    deferredPrompt.prompt();

    // not doing anything with this right now, but maybe in the future ...
    const { outcome } = await deferredPrompt?.userChoice;

    setDeferredPrompt(null);
  };

  return (
    <installPromptContext.Provider
      value={{
        showInstallPromo,
        onClickInstall,
        standaloneMode,
        isMobileIos,
        isSafari,
      }}
    >
      {children}
    </installPromptContext.Provider>
  );
};

export const useInstallPromptContext = () => {
  const {
    showInstallPromo,
    onClickInstall,
    standaloneMode,
    isMobileIos,
    isSafari,
  } = React.useContext(installPromptContext);

  return {
    onClickInstall,
    showInstallPromo,
    standaloneMode,
    isMobileIos,
    isSafari,
  };
};

export const InstallAppModal = ({ onClickDownload, onClickContinue }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex space-x-4 items-center">
          <Avatar
            size={Sizes.SM}
            src={`${process.env.REACT_APP_FRONTEND_URL}/favicon.png`}
          />
          <div className="t-small plus">{t("app")}</div>
        </div>
        <div>
          <Button onClick={onClickDownload} buttonStyle="brand" size={Sizes.XS}>
            {t("downloadApp")}
          </Button>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center">
        <div className="flex space-x-4 items-center">
          <Icon icon="public" size={Sizes.XL} />
          <div className="t-small plus">{t("currentBrowser")}</div>
        </div>
        <div>
          <Button
            onClick={onClickContinue}
            buttonStyle="outline"
            size={Sizes.XS}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const AddToHomeScreenModal = ({ onClose, isSafari }) => {
  const { t } = useTranslation();
  let safariStep = !isSafari ? 1 : 0;

  return (
    <div className="space-y-4 t-small">
      <div>{t("installThisWebAppOnYourHomescreen")}</div>
      {!isSafari && (
        <>
          <div>
            <Trans i18nKey="noteInstallingThisAppIsNotSupported">
              <span className="t-small plus">Note: </span>Installing this app is
              not supported in your current browser. Please navigate to Safari
              and follow these prompts there.
            </Trans>
          </div>
          <div className="flex items-center space-x-1">
            <div>{t("openSafari")}</div>
          </div>
        </>
      )}
      <div className="flex items-center space-x-1">
        <div>{t("stepTapOn", { step: safariStep + 1 })}</div>
        <FeatherIcon size={Sizes.MD} icon="Share" />{" "}
        <div>{t("atTheBottomOfYourScreen")}</div>
      </div>
      <div className="flex items-center space-x-1">
        <div>{t("stepSelect", { step: safariStep + 2 })}</div>
        <div className="t-small plus bg-canvas-600 p-1 rounded flex items-center space-x-1">
          <div className="pr-16">{t("addToHomeScreen")}</div>
          <FeatherIcon size={Sizes.LG} icon="PlusSquare" />
        </div>
      </div>
      <div className="flex items-center space-x-1">
        <div> {t("stepTap", { step: safariStep + 3 })}</div>
        <div className="t-small plus bg-canvas-600 p-1 rounded flex items-center space-x-1">
          {t("add")}
        </div>
      </div>
      <div className="flex items-center space-x-1">
        <div>{safariStep + 4}. </div>
        <Avatar
          size={Sizes.SM}
          src={`${process.env.REACT_APP_FRONTEND_URL}/icon-dark-64.png`}
        />
        <div>{t("launchAppFromYourHomeScreen")}</div>
      </div>
      <div className="flex flex-row space-x-2 justify-end">
        <Button size={Sizes.SM} buttonStyle="brand" onClick={onClose}>
          {t("gotIt")}
        </Button>
      </div>
    </div>
  );
};

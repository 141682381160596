import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation CreateOrUpdateTask($input: CreateOrUpdateTaskInput!) {
    createOrUpdateTask(input: $input) {
      id
    }
  }
`;

export default function useCreateOrUpdateTask() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "createOrUpdateTask">,
    FrankBackendTypes.MutationCreateOrUpdateTaskArgs
  >(mutation);

  const createOrUpdateTask = React.useCallback(
    async (input: FrankBackendTypes.CreateOrUpdateTaskInput) => {
      const { data } = await invokeMutation({
        variables: { input },
      });
      return data?.createOrUpdateTask;
    },
    [invokeMutation]
  );

  return {
    createOrUpdateTask,
    loading,
    error,
    called,
  };
}

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormGroup = exports.CharCountPortal = exports.HelpTextPortal = void 0;
const React = __importStar(require("react"));
const react_dom_1 = require("react-dom");
const usePortal_1 = require("../../hooks/usePortal");
const PortalFromRef = ({ elemRef, children }) => {
    const childElem = (0, usePortal_1.usePortal)({ parentRef: elemRef });
    return (0, react_dom_1.createPortal)(children, childElem);
};
const HelpTextPortal = ({ elemRef, children }) => {
    return (React.createElement(PortalFromRef, { elemRef: elemRef },
        React.createElement("div", { className: "mt-2" }, children)));
};
exports.HelpTextPortal = HelpTextPortal;
const CharCountPortal = ({ elemRef, children }) => {
    return React.createElement(PortalFromRef, { elemRef: elemRef }, children);
};
exports.CharCountPortal = CharCountPortal;
const FormGroup = ({ children, label, hasError, id, name, className }) => {
    const helpTextRef = React.useRef(null);
    const charCountRef = React.useRef(null);
    return (React.createElement("div", { className: `group my-3 ${className}` },
        React.createElement("div", { className: "flex justify-between items-center" },
            React.createElement("div", null,
                React.createElement("label", { htmlFor: id, className: `block cursor-pointer capitalize t-mini plus ${hasError &&
                        "text-frank-red-400"}` }, label)),
            React.createElement("div", { ref: charCountRef, className: "t-mini text-canvas-400" })),
        React.createElement("div", { className: "mt-1 relative" }, React.cloneElement(children, { helpTextRef, charCountRef, name, id })),
        React.createElement("div", { ref: helpTextRef })));
};
exports.FormGroup = FormGroup;

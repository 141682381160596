import { gql, useMutation } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const CREATE_GROUP = gql`
  mutation CreateGroup($input: CreatePartnerGroupInput!) {
    createGroup(input: $input) {
      id
    }
  }
`;

export default function useCreateGroup() {
  const [invokeCreateGroup, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationCreateGroupArgs
  >(CREATE_GROUP);

  const createGroup = React.useCallback(
    async ({
      name,
      partnerId,
      organizerUserIds,
      addAllOrganizers,
    }: {
      name: string;
      partnerId: string;
      organizerUserIds?: string[];
      addAllOrganizers: boolean;
    }) => {
      const result = await invokeCreateGroup({
        variables: {
          input: {
            name,
            partnerId,
            organizerUserIds,
            addAllOrganizers,
          },
        },
      });
      return result.data?.createGroup;
    },
    [invokeCreateGroup]
  );

  return {
    createGroup,
    loading,
    called,
    error,
  };
}

import {
  IconButton,
  Sizes,
  Tab,
  Tabs,
  TopBar,
  useLayout,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { AbilityAction } from "frank-types";
import { useCurrentUserData } from "../Auth/useCurrentUserData";
import EmailPreferences from "./pages/EmailPreferences";
import { ProfileSettings } from "./pages/ProfileSettings";
import { useTranslation } from "react-i18next";

export default function Account() {
  const { setMobilePanel } = useLayout();
  const { url } = useRouteMatch();
  const { ability } = useCurrentUserData();
  const { partner, currentGroup, preferredLanguage } = useCurrentUserData();

  const canEditGroup = ability?.can(AbilityAction.Update, "Group");

  const { t } = useTranslation();

  return (
    <>
      <div className="md:hidden fixed z-50 top-0 w-screen">
        <TopBar
          classNames="bg-canvas-800"
          left={
            <IconButton
              size={Sizes.XL}
              onClick={() => setMobilePanel("left")}
              buttonStyle="minimal"
              icon="menu"
            />
          }
          title={t("account")}
        />
      </div>
      <div className="max-w-5xl mx-auto px-4 md:mt-12 mt-24 w-full">
        <h1 className="t-title-4 plus">{t("settings")}</h1>
        <Tabs lng={preferredLanguage}>
          <Tab
            active={window.location.href.includes("profile")}
            label={t("profile")}
            to={`${url}/profile`}
          />
          <Tab
            active={window.location.href.includes("notification-preferences")}
            label={t("emails")}
            to={`${url}/notification-preferences`}
          />
          {canEditGroup ? (
            <Tab
              active={window.location.href.includes("group")}
              label={t("group")}
              to={`/partners/${partner.slug}/groups/${currentGroup.id}`}
            />
          ) : (
            <></>
          )}
        </Tabs>
        <div className="h-6" />
        <Switch>
          <Route path={`${url}/profile`}>
            <ProfileSettings />
          </Route>
          <Route path={`${url}/notification-preferences`}>
            <EmailPreferences />
          </Route>
          <Route>
            <Redirect to={`${url}/profile`} />
          </Route>
        </Switch>
        <div className="h-48 sm:h-0" />
      </div>
    </>
  );
}

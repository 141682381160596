"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toast = exports.ToastContainer = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../constants");
const Button_1 = require("./Button");
const Icon_1 = require("./Icon");
const Nudge_1 = require("./Nudge");
const intentMap = {
    [constants_1.Intent.SUCCESS]: {
        icon: "frank-green-300"
    },
    [constants_1.Intent.FAILURE]: {
        icon: "frank-red-300"
    },
    [constants_1.Intent.WARNING]: {
        icon: "frank-gold-500"
    },
    [constants_1.Intent.INFO]: {
        icon: "canvas-100"
    },
    [constants_1.Intent.NONE]: {
        icon: "canvas-100"
    }
};
const ToastContainer = ({ children }) => {
    return (React.createElement("div", { className: "fixed z-50 w-screen left-0 bottom-0 px-3 pb-6 pointer-events-none sm:p-3 flex flex-row items-end justify-around sm:justify-end" },
        React.createElement("div", { className: "grid grid-cols-1 gap-3 sm:max-w-sm flex-1 " }, children)));
};
exports.ToastContainer = ToastContainer;
const Toast = ({ action, children, icon, intent, onClose }) => {
    if (!icon && intent === constants_1.Intent.FAILURE) {
        icon = "error";
    }
    if (!icon && intent === constants_1.Intent.SUCCESS) {
        icon = "check_circle";
    }
    const classesForIntent = intentMap[intent];
    return (React.createElement("div", { className: "z-50 w-full bg-canvas-700 text-canvas-100 shadow-xs-and-l rounded pointer-events-auto overflow-hidden" },
        React.createElement("div", { className: "p-3" },
            React.createElement("div", { className: "flex items-center space-x-3" },
                React.createElement("div", { className: `flex-shrink-0 text-${classesForIntent.icon}` }, icon && (React.createElement(Nudge_1.Nudge, { y: 4 },
                    React.createElement(Icon_1.Icon, { size: constants_1.Sizes.LG, icon: icon })))),
                React.createElement("div", { className: "w-0 flex-1 pt-0.5" },
                    React.createElement("p", { className: "t-small text-clamping-3" }, children)),
                React.createElement("div", { className: "flex-shrink-0 flex flex-row space-x-1" },
                    action && (React.createElement("div", { className: "flex-shrink-0 flex" },
                        React.createElement(Button_1.Button, { buttonStyle: "minimal", onClick: action.onClick, size: constants_1.Sizes.SM }, action.label))),
                    React.createElement(Button_1.IconButton, { size: constants_1.Sizes.LG, icon: "close", onClick: onClose, buttonStyle: "minimal" }))))));
};
exports.Toast = Toast;

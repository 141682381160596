import {
  InlineAlert,
  Intent,
  ResponsiveProvider,
  RightSidePanel,
  ScreenSizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import Channel from "../../Chat";
import Loading from "../../components/Loading";
import { useChannelIds } from "../../Navigation/hooks/useNavigationState";
import TopicRightSide from "../components/TopicRightSide";
import useTopic from "../dataAccess/queries/useTopic";
import { useTranslation } from "react-i18next";

const TopicDetail: React.FC = () => {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const { topic, loading, error, refetch } = useTopic(slug);
  const channelId = useChannelIds(slug);

  const channelIdToUse = channelId || topic?.channelId;

  if (topic?.archivedAt) {
    return <Redirect to="/home" />;
  }

  if (!channelIdToUse) {
    return <Loading />;
  }

  return (
    <>
      <Channel
        title={topic?.name}
        channelId={channelIdToUse}
        key={channelIdToUse}
      />
      <RightSidePanel>
        <ResponsiveProvider screenSize={ScreenSizes.XS}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {error ? (
                <InlineAlert
                  title={t("errorLoadingTopic")}
                  intent={Intent.FAILURE}
                />
              ) : (
                <TopicRightSide topic={topic} refetch={refetch} />
              )}
            </>
          )}
        </ResponsiveProvider>
      </RightSidePanel>
    </>
  );
};

export default TopicDetail;

import * as H from "history";
import { EventTypes } from "frank-types";

export function init(params: { userId?: string; history: H.History }) {
  window.analytics.identify(params.userId);
}

export function identify(userId: string, traits?: { [index: string]: any }) {
  window.analytics.identify(userId, traits);
}

export function capture(name: EventTypes.AnalyticsEvents, meta?: any) {
  window.analytics.track(name, meta);
}

export function rawCapture(name: string, meta?: any) {
  window.analytics.track(name, meta);
}

export function logout() {
  try {
    window.analytics.reset();
  } catch (e) {
    console.warn("could not reset analytics", e);
  }
}

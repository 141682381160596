import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const MOVE_WORKER = gql`
  mutation MoveWorker($input: MoveWorkerInput!) {
    moveWorker(input: $input) {
      nothing
    }
  }
`;

export default function useMoveWorker() {
  const [invokeMoveWorker, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationMoveWorkerArgs
  >(MOVE_WORKER);

  const moveWorker = React.useCallback(
    async ({ userId, groupId }: { userId; groupId }) => {
      await invokeMoveWorker({
        variables: {
          input: {
            userId,
            groupId,
          },
        },
      });
    },
    [invokeMoveWorker]
  );

  return {
    moveWorker,
    loading,
    called,
    error,
  };
}

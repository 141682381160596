"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalContainer = void 0;
const react_1 = require("@headlessui/react");
const React = __importStar(require("react"));
const __1 = require("../..");
const useInternalModalApi_1 = require("./useInternalModalApi");
const modalSizes = {
    [__1.Sizes.XS]: "md:w-88",
    [__1.Sizes.SM]: "md:max-w-md",
    [__1.Sizes.MD]: "md:max-w-xl",
    [__1.Sizes.LG]: "md:max-w-3xl",
    [__1.Sizes.XL]: "md:max-w-6xl"
};
const ModalContainer = ({ children, size = __1.Sizes.MD, animateIn, isVisible, stackSize, onClose, onModalBack }) => {
    const defaultClasses = `inline-block bg-canvas-700 border rounded text-left overflow-hidden shadow-xxl transform transition-all m-2 sm:m-4 md:mx-0 md:my-8 align-middle ${modalSizes[size]} w-full ${!isVisible ? "hidden" : ""}`;
    const footerRef = React.useRef(null);
    const contents = (React.createElement("div", { style: { maxHeight: "75vh" }, className: "flex flex-col flex-grow overflow-hidden" },
        children,
        React.createElement("div", { ref: footerRef })));
    return (React.createElement(useInternalModalApi_1.InteralModalProvider, { stackSize: stackSize, footerRef: footerRef, onClose: onClose, onModalBack: onModalBack }, animateIn ? (React.createElement(react_1.Transition.Child, { enter: "ease-out duration-300", leave: "ease-in duration-200" // child is just disappearing, not being a good child and transitioning
        , leaveFrom: "opacity-100 translate-y-0 sm:scale-100", leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95", enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95", enterTo: "opacity-100 translate-y-0 sm:scale-100", className: defaultClasses, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" }, contents)) : (React.createElement("div", { className: defaultClasses, role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline" }, contents))));
};
exports.ModalContainer = ModalContainer;

module.exports = {
  plugins: [require("@tailwindcss/forms")],
  prefix: "",
  important: false,
  purge: [
    "./src/**/*.tsx",
    "./stories/**/*.tsx",
    "../frontend/src/**/*.tsx",
    "../emails/src/**/*.html",
    "../landing/**/*.tsx",
    ,
    "../landing/**/*.html",
    "../landing/**/*.jsx"
  ],
  separator: ":",
  theme: {
    linearGradientColors: theme => theme("colors"),
    radialGradientColors: theme => theme("colors"),
    conicGradientColors: theme => theme("colors"),
    screens: {
      sm: "600px",
      md: "959px",
      lg: "1439px",
      xl: "1599px",
      xxl: "1919px"
    },
    colors: {
      transparent: "transparent",
      current: "currentColor",
      overlay: "#F9F7F4",
      "transparent-white": {
        100: "rgba(255, 255, 255, 0.1)"
      },
      canvas: {
        "50": "#FBFAF9",
        "100": "#F7F3F1",
        "200": "#E7E4E2",
        "300": "#D5D3D1",
        "400": "#ABAAA9",
        "500": "#5F5F5F",
        "600": "#3A3A3A",
        "700": "#292929",
        "800": "#191919",
        "900": "#0F0F0F"
      },
      brand: {
        "50": "#F9F1EB",
        "100": "#EED2BE",
        "200": "#EABA97",
        "300": "#DC9B6D",
        "400": "#D68B55",
        "500": "#B86B33",
        "600": "#955527",
        "700": "#643819",
        "800": "#422713",
        "900": "#2C2017"
      },
      "frank-green": {
        "50": "#EFFAF1",
        "100": "#D5F1DE",
        "200": "#B1DBC0",
        "300": "#84BF97",
        "400": "#64A478",
        "500": "#4B7C5B",
        "600": "#3D6E4D",
        "700": "#2B4B36",
        "800": "#243429",
        "900": "#18231C"
      },
      "frank-gold": {
        "50": "#FFF9F1",
        "100": "#F6E9D1",
        "200": "#F1D29E",
        "300": "#EBBF7D",
        "400": "#E8B565",
        "500": "#D8A451",
        "600": "#B78638",
        "700": "#946A2C",
        "800": "#5B431E",
        "900": "#30220C"
      },
      "frank-blue": {
        "50": "#F2F6FC",
        "100": "#DAE6F5",
        "200": "#A2BAD3",
        "300": "#87A9CE",
        "400": "#6C93C0",
        "500": "#4D719B",
        "600": "#3E5773",
        "700": "#304052",
        "800": "#24303D",
        "900": "#171F28"
      },
      "frank-red": {
        "50": "#FFF5F4",
        "100": "#F3D7D4",
        "200": "#E0AAA5",
        "300": "#DC857E",
        "400": "#C16058",
        "500": "#A4463E",
        "600": "#883A33",
        "700": "#6C2E29",
        "800": "#512623",
        "900": "#341614"
      },
      black: "#000",
      white: "#fff"
    },
    spacing: {
      px: "1px",
      0: "0",
      "0.5": "0.125rem",
      1: "0.25rem",
      "1.5": "0.375rem",
      2: "0.5rem",
      "2.5": "0.625rem",
      3: "0.75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      8: "2rem",
      10: "2.5rem",
      12: "3rem",
      14: "3.5rem",
      16: "4rem",
      20: "5rem",
      24: "6rem",
      28: "7rem",
      32: "8rem",
      40: "10rem",
      44: "11rem",
      48: "12rem",
      52: "rem",
      56: "14rem",
      60: "15rem",
      64: "16rem",
      72: "18rem",
      80: "20rem",
      88: "22rem",
      96: "24rem",
      iphone: "375px"
    },
    backgroundColor: theme => theme("colors"),
    backgroundPosition: {
      bottom: "bottom",
      center: "center",
      left: "left",
      "left-bottom": "left bottom",
      "left-top": "left top",
      right: "right",
      "right-bottom": "right bottom",
      "right-top": "right top",
      top: "top"
    },
    backgroundSize: {
      auto: "auto",
      cover: "cover",
      contain: "contain"
    },
    borderColor: theme => ({
      ...theme("colors"),
      DEFAULT: theme("colors.canvas.600", "currentColor")
    }),
    borderRadius: {
      none: "0",
      sm: "2px",
      DEFAULT: "4px",
      lg: "6px",
      xl: "8px",
      full: "99999px"
    },
    borderWidth: {
      DEFAULT: "1px",
      "0p5": "0.5px",
      0: "0",
      "1p5": "1.5px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    boxShadow: {
      DEFAULT:
        "0px 1px 4px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.1)",
      xs: "0px 0px 0px 1px rgba(255, 255, 255, 0.16)",
      sm: "0px 1px 1px rgba(0, 0, 0, 0.3)",
      md:
        "0px 4px 6px -1px rgba(0, 0, 0, 0.15), 0px 2px 4px -1px rgba(0, 0, 0, 0.1)",
      lg:
        "0px 10px 15px -3px rgba(0, 0, 0, 0.15), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
      xl:
        "0px 20px 25px -5px rgba(0, 0, 0, 0.2), 0px 10px 10px -5px rgba(0, 0, 0, 0.1)",
      xxl: "0px 25px 50px -12px rgba(0, 0, 0, 0.4)",
      "xs-and-l":
        "0px 0px 0px 1px rgba(255, 255, 255, 0.16), 0px 20px 25px -5px rgba(0, 0, 0, 0.2), 0px 10px 10px -5px rgba(0, 0, 0, 0.1)",
      outline: "0px 0px 0px 2px rgba(255, 255, 255, 0.16)",
      nav: "0px 0px 0px 1px var(--canvas-800)",
      none: "none"
    },
    container: {},
    cursor: {
      auto: "auto",
      DEFAULT: "default",
      pointer: "pointer",
      wait: "wait",
      text: "text",
      move: "move",
      "not-allowed": "not-allowed"
    },
    fill: {
      current: "currentColor"
    },
    flex: {
      1: "1 1 0%",
      auto: "1 1 auto",
      initial: "0 1 auto",
      none: "none"
    },
    flexGrow: {
      0: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      DEFAULT: "1"
    },
    flexShrink: {
      0: "0",
      DEFAULT: "1"
    },
    fontFamily: {
      sans: [
        "Inter var",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        '"Noto Sans"',
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ],
      serif: ["Laura", "Cambria", '"Times New Roman"', "Times", "serif"],
      mono: [
        "Menlo",
        "Monaco",
        "Consolas",
        '"Liberation Mono"',
        '"Courier New"',
        "monospace"
      ]
    },
    fontSize: {
      xxs: "8px",
      xs: "0.75rem",
      sm: "0.875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "5xl": "3rem",
      "6xl": "4rem"
    },
    fontWeight: {
      hairline: "100",
      thin: "200",
      light: "300",
      normal: "400",
      medium: "500",
      semibold: "600",
      bold: "700",
      extrabold: "800",
      black: "900"
    },
    height: theme => ({
      auto: "auto",
      ...theme("spacing"),
      full: "100%",
      screen: "100vh"
    }),
    inset: {
      0: "0",
      auto: "auto"
    },
    letterSpacing: {
      tighter: "-0.05em",
      tight: "-0.025em",
      normal: "0",
      wide: "0.025em",
      wider: "0.05em",
      widest: "0.1em"
    },
    lineHeight: {
      none: "1",
      tight: "1.25",
      snug: "1.375",
      normal: "1.5",
      relaxed: "1.625",
      loose: "2"
    },
    listStyleType: {
      none: "none",
      disc: "disc",
      decimal: "decimal"
    },
    margin: (theme, { negative }) => ({
      auto: "auto",
      ...theme("spacing"),
      ...negative(theme("spacing"))
    }),
    maxHeight: theme => ({
      full: "100%",
      screen: "100vh",
      ...theme("spacing")
    }),
    maxWidth: theme => ({
      xs: "20rem",
      sm: "24rem",
      md: "28rem",
      lg: "32rem",
      xl: "36rem",
      "2xl": "42rem",
      "3xl": "48rem",
      "4xl": "56rem",
      "5xl": "64rem",
      "6xl": "72rem",
      "screen-lg": theme("screen.lg"),
      "screen-xl": theme("screen.xl"),
      full: "100%"
    }),
    minHeight: {
      0: "0",
      full: "100%",
      screen: "100vh"
    },
    minWidth: {
      0: "0",
      full: "100%"
    },
    objectPosition: {
      bottom: "bottom",
      center: "center",
      left: "left",
      "left-bottom": "left bottom",
      "left-top": "left top",
      right: "right",
      "right-bottom": "right bottom",
      "right-top": "right top",
      top: "top"
    },
    opacity: {
      0: "0",
      2: ".02",
      25: "0.25",
      50: "0.5",
      60: "0.60",
      75: "0.75",
      100: "1"
    },
    order: {
      first: "-9999",
      last: "9999",
      none: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12"
    },
    padding: theme => theme("spacing"),
    placeholderColor: theme => theme("colors"),
    stroke: {
      current: "currentColor"
    },
    textColor: theme => theme("colors"),
    width: theme => ({
      auto: "auto",
      ...theme("spacing"),
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      "1/5": "20%",
      "2/5": "40%",
      "3/5": "60%",
      "4/5": "80%",
      "1/6": "16.666667%",
      "2/6": "33.333333%",
      "3/6": "50%",
      "4/6": "66.666667%",
      "5/6": "83.333333%",
      "1/12": "8.333333%",
      "2/12": "16.666667%",
      "3/12": "25%",
      "4/12": "33.333333%",
      "5/12": "41.666667%",
      "6/12": "50%",
      "7/12": "58.333333%",
      "8/12": "66.666667%",
      "9/12": "75%",
      "10/12": "83.333333%",
      "11/12": "91.666667%",
      full: "100%",
      screen: "100vw"
    }),
    zIndex: {
      auto: "auto",
      0: "0",
      10: "10",
      20: "20",
      30: "30",
      40: "40",
      50: "50"
    }
  },
  variants: {
    gridColumn: ["responsive"],
    accessibility: ["responsive", "focus"],
    alignContent: ["responsive"],
    alignItems: ["responsive"],
    alignSelf: ["responsive"],
    appearance: ["responsive"],
    backgroundAttachment: ["responsive"],
    backgroundColor: [
      "responsive",
      "hover",
      "focus",
      "focus-within",
      "disabled"
    ],
    backgroundPosition: ["responsive"],
    backgroundRepeat: ["responsive"],
    backgroundSize: ["responsive"],
    borderCollapse: ["responsive"],
    backgroundImage: ["responsive", "hover"],
    borderColor: [
      "responsive",
      "hover",
      "focus",
      "group-hover",
      "focus-within"
    ],
    borderRadius: ["responsive"],
    borderStyle: ["responsive"],
    borderWidth: ["responsive", "hover", "focus", "last", "first"],
    boxShadow: ["responsive", "hover", "focus"],
    cursor: ["responsive"],
    display: ["responsive", "group-hover"],
    fill: ["responsive"],
    flex: ["responsive"],
    flexDirection: ["responsive"],
    flexGrow: ["responsive"],
    flexShrink: ["responsive"],
    flexWrap: ["responsive"],
    float: ["responsive"],
    fontFamily: ["responsive"],
    fontSize: ["responsive"],
    fontSmoothing: ["responsive"],
    fontStyle: ["responsive"],
    fontWeight: ["responsive", "hover", "focus"],
    height: ["responsive"],
    inset: ["responsive"],
    justifyContent: ["responsive"],
    letterSpacing: ["responsive"],
    lineHeight: ["responsive"],
    listStylePosition: ["responsive"],
    listStyleType: ["responsive"],
    margin: ["responsive"],
    maxHeight: ["responsive"],
    maxWidth: ["responsive"],
    minHeight: ["responsive"],
    minWidth: ["responsive"],
    objectFit: ["responsive"],
    objectPosition: ["responsive"],
    opacity: [
      "responsive",
      "hover",
      "focus",
      "group-hover",
      "group-focus",
      "focus-within"
    ],
    order: ["responsive"],
    outline: ["responsive", "focus"],
    overflow: ["responsive"],
    padding: ["responsive", "first", "last"],
    placeholderColor: ["responsive", "focus"],
    pointerEvents: ["responsive"],
    position: ["responsive"],
    resize: ["responsive"],
    stroke: ["responsive"],
    tableLayout: ["responsive"],
    textAlign: ["responsive"],
    textColor: ["responsive", "hover", "focus", "group-hover", "disabled"],
    textDecoration: ["responsive", "hover", "focus"],
    textTransform: ["responsive"],
    userSelect: ["responsive"],
    verticalAlign: ["responsive"],
    visibility: ["responsive"],
    whitespace: ["responsive"],
    width: ["responsive"],
    wordBreak: ["responsive"],
    zIndex: ["responsive"]
  },
  corePlugins: {}
};

import {
  Button,
  Sizes,
  Icon,
  EmptyState,
  IconButton,
} from "@get-frank-eng/design-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import { CustomFormResponseListResponse } from "./models";
import { dateFormatter } from "../../../utils/Date";

const SubmittedByCoworkerTable = ({
  submissions,
}: {
  submissions: CustomFormResponseListResponse[];
}): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { preferredLanguage } = useCurrentUserData();
  const latestSubmission = React.useCallback(
    (responses: { submittedAt?: string }[]) =>
      responses.reduce((mostRecent, response) => {
        if (mostRecent < response.submittedAt) {
          return response.submittedAt;
        }
        return mostRecent;
      }, "0"),
    []
  );
  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <div className="t-large plus">{t("submitted")}</div>
      </div>
      {!!submissions.length ? (
        <div className="overflow-x-auto rounded border">
          <table className="text-sm w-full text-left">
            <thead>
              <tr className="border-b border-canvas-500 bg-canvas-500 divide-x divide-canvas-500">
                <th className="px-4 py-2 bg-canvas-500 border-none"></th>
                <th className="px-4 py-2 bg-canvas-500 border-none">
                  {t("name")}
                </th>
                <th className="px-4 py-2 bg-canvas-500 border-none hidden md:table-cell">
                  {t("email")}
                </th>
                <th className="px-4 py-2 bg-canvas-500 border-none">
                  {t("numberOfSubmissions")}
                </th>
                <th className="px-4 py-2 bg-canvas-500 border-none hidden md:table-cell">
                  {t("lastSubmissionDate")}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-canvas-500">
              {submissions.map((r) => (
                <tr key={r.coworker.id}>
                  <td className="px-4 py-4 border-b border-canvas-500">
                    <Button
                      buttonStyle="outline"
                      size={Sizes.XS}
                      onClick={() =>
                        history.push(`${location.pathname}/${r.coworker.id}`)
                      }
                    >
                      {t("viewAll")}
                    </Button>
                  </td>
                  <td className="px-4 py-4 border-b border-canvas-500">
                    {r.coworker.name}
                  </td>
                  <td className="px-4 py-4 border-b border-canvas-500 hidden md:table-cell">
                    {r.coworker.email}
                  </td>
                  <td className="px-4 py-4 border-b border-canvas-500">
                    {r.responses.length}
                  </td>
                  <td className="px-4 py-4 border-b border-canvas-500 hidden md:table-cell">
                    {dateFormatter(preferredLanguage).format(
                      new Date(latestSubmission(r.responses))
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyState title={t("noSubmissionsYet")} />
      )}
    </>
  );
};

export default SubmittedByCoworkerTable;

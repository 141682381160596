import {
  Accordion,
  ModalBody,
  ModalHeader,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";

const SignCampaignFAQModal = ({
  typeOfSignatures,
  recipientNames,
}: {
  typeOfSignatures: "public" | "anonymous";
  recipientNames: string[];
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader title={t("help")} />
      <ModalBody>
        <h2 className="t-large plus">{t("faQs")} </h2>
        <Accordion defaultHidden={false} title={t("whatHappensNext")}>
          {t("yourSignatureWillBeAddedToThePeti")}
        </Accordion>
        <Accordion defaultHidden title={t("willMyNameBeSharedWithMyCompany")}>
          <>
            <p className="pb-2">
              {t("theCampaignOrganizerHasEnabledSigna", { typeOfSignatures })}
            </p>
            <p>{t("choosingToShareSignersNamesGeneral")}</p>
          </>
        </Accordion>
        <Accordion defaultHidden title={t("whoReceivesThisLetter")}>
          <>
            {t("theCampaignOrganizerHasChosenToSen")}{" "}
            <ul>
              {recipientNames.map((name) => (
                <li key={name} className="pt-1">
                  - {name}
                </li>
              ))}
            </ul>
          </>
        </Accordion>
        <Accordion defaultHidden title={t("canIRemoveMySignatureIfISignBut")}>
          {t("youCanRemoveYourSignatureUpUntilA")}
        </Accordion>
      </ModalBody>
    </>
  );
};

export default SignCampaignFAQModal;

/**
 * https://www.figma.com/proto/B9GE74RWzNwJ59HhZPTOJv/Campaigns-1.0?node-id=1284%3A173&scaling=min-zoom
 */

import * as React from "react";
import { useHistory } from "react-router";
import usePublish from "../dataAccess/mutations/usePublish";
import ContentContainer from "../components/ContentContainer";
import { InlineAlert, Button, Intent } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";

import { useTranslation } from "react-i18next";

const Publish = ({ campaign }: { campaign: FrankBackendTypes.Campaign }) => {
  const { publish, loadingPublish } = usePublish({ campaignId: campaign.id });

  const { t } = useTranslation();

  const { push } = useHistory();
  const publishAndRedirect = React.useCallback(async () => {
    await publish();
    push(`/campaigns/${campaign.id}`);
  }, [publish, push, campaign]);

  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">{t("publishYourPetition")}</h2>

      <h3 className="t-regular plus">{t("whatHappensWhenIPublish")}</h3>
      <p className="t-regular text-canvas-400 mb-8">
        {t("oncePetitionsArePublishedCoworkers")}
      </p>

      <h3 className="t-regular plus">{t("whoCanSeeMyPetition")}</h3>
      <p className="t-regular text-canvas-400 mb-8">
        {t("onlyVerifiedCoworkersInYourGroupCa")}
      </p>
      {campaign.publishingValidationIssues.length > 0 && (
        // <section className="mb-8">
        //   <h3 className="t-regular plus">
        //     Before you can publish, please address the issues below
        //   </h3>
        //   <ul className="list-disc ml-4">
        //     {campaign.publishingValidationIssues.map(issue => (
        //       <li key={issue.category}>{issue.message}</li>
        //     ))}
        //   </ul>
        // </section>
        <InlineAlert
          title="Before you can publish, please address the issues below"
          intent={Intent.WARNING}
        >
          <ul className="list-disc ml-4">
            {campaign.publishingValidationIssues.map((issue) => (
              <li key={issue.category}>{issue.message}</li>
            ))}
          </ul>
        </InlineAlert>
      )}

      <div className="mt-12 flex flex-row">
        <Button
          buttonStyle="brand"
          loading={loadingPublish}
          onClick={publishAndRedirect}
          disabled={campaign.publishingValidationIssues.length > 0}
        >
          {t("publishToGroup")}
        </Button>
      </div>
    </ContentContainer>
  );
};

export default Publish;

import { ModalBody, ModalHeader } from "@get-frank-eng/design-system";
import { AssignmentCard } from "@get-frank-eng/design-system/dist/cards/Assignment";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import Loading from "../../components/Loading";
import Pager, { PaginationStrategy } from "../../components/Pager";
import { useModals } from "../../Modals";
import useTaskAssignments from "../dataAccess/queries/useTaskAssignments";
import { useUpdateAssignment } from "../hooks/useUpdateAssignment";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

export default function AssignmentModal({
  coworkerId,
}: {
  coworkerId: string;
}) {
  const { preferredLanguage } = useCurrentUserData();
  const { t } = useTranslation();
  const { taskAssignments, loading, nextPage, refetch } = useTaskAssignments({
    coworkerId,
  });

  const { setModal } = useModals();

  const { incomplete, complete } = React.useMemo(() => {
    const base = { incomplete: [], complete: [] };
    if (!taskAssignments) {
      return base;
    }
    return taskAssignments?.objects.reduce((acc, task) => {
      switch (task.workflowState) {
        case FrankBackendTypes.AssignmentWorkflowState.Complete:
          acc.complete = [...acc.complete, task];
          return acc;
        case FrankBackendTypes.AssignmentWorkflowState.Incomplete:
          acc.incomplete = [...acc.incomplete, task];
          return acc;
        default:
          throw new Error("Workflow state not supported");
      }
    }, base);
  }, [taskAssignments]);

  const {
    updateAssignment,
    loading: updateAssignmentLoading,
  } = useUpdateAssignment({
    callback: refetch,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ModalHeader title="" border={false} />
      <ModalBody pad>
        <Pager<FrankBackendTypes.TaskAssignment>
          pagination={taskAssignments}
          onShowMore={nextPage}
          paginationStrategy={PaginationStrategy.SHOW_MORE}
          showAllText="Show more"
        >
          <div className="grid grid-cols-1 gap-2 -mt-6">
            <div className="t-large plus">{t("new")}</div>
            {incomplete.map((assignment) => (
              <AssignmentCard
                loading={updateAssignmentLoading}
                key={assignment.id}
                assignment={assignment}
                onUpdate={() => updateAssignment(assignment)}
                onSeeDetail={() => {
                  setModal({
                    type: "assignmentDetailModal",
                    props: {
                      assignment,
                      afterSubmit: refetch,
                    },
                  });
                }}
                lng={preferredLanguage}
              />
            ))}
            {!!complete.length && (
              <>
                <div className="t-large plus pt-8">{t("done")}</div>
                {complete.map((assignment) => (
                  <AssignmentCard
                    lng={preferredLanguage}
                    loading={updateAssignmentLoading}
                    key={assignment.id}
                    assignment={assignment}
                    onUpdate={() => updateAssignment(assignment)}
                    onSeeDetail={() => {
                      setModal({
                        type: "assignmentDetailModal",
                        props: {
                          assignment,
                          afterSubmit: refetch,
                        },
                      });
                    }}
                  />
                ))}
              </>
            )}
          </div>
        </Pager>
      </ModalBody>
    </>
  );
}

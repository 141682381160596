import * as React from "react";
import classNames from "classnames";
import { Icon, SegmentedControl, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { CoworkerStatus } from "frank-types/dist/frank-backend-types";
import { useTranslation } from "react-i18next";

export default function TrustButtons({
  id,
  yourTrustLevel,
  totalTrustDownvotes,
  totalTrustUpvotes,
  coworkerTrustVote,
  disabled,
  status,
  loading,
}: Pick<
  FrankBackendTypes.Coworker,
  "id" | "yourTrustLevel" | "totalTrustUpvotes" | "totalTrustDownvotes"
> & {
  loading: boolean;
  status: CoworkerStatus;
  disabled: boolean;
  coworkerTrustVote: (args: {
    coworkerId: FrankBackendTypes.Coworker["id"];
    trust: number;
  }) => any;
}) {
  const [currentTrust, setCurrentTrust] = React.useState(null);
  const [error, setError] = React.useState(false);
  const { t } = useTranslation();
  const radioOptions = React.useMemo(
    () => [
      {
        id: `${id}-trust-coworker`,
        value: 1,
        label: (
          <div className="flex flex-row justify-center space-x-1 items-center">
            <Icon icon="arrow_upward" size={Sizes.SM} />
            <div>
              {/** @ts-ignore */}
              {t("trusted", {
                count:
                  status === CoworkerStatus.Member
                    ? "-"
                    : totalTrustUpvotes || 0,
              })}
            </div>
          </div>
        ),
        defaultChecked:
          status !== CoworkerStatus.Member && yourTrustLevel === 1,
      },
      {
        id: `${id}-distrust-coworker`,
        value: -1,
        label: (
          <div className="flex flex-row justify-center space-x-1 items-center">
            <Icon icon="arrow_downward" size={Sizes.SM} />
            <div>
              {/** @ts-ignore */}
              {t("notTrusted", {
                count:
                  status === CoworkerStatus.Member
                    ? "-"
                    : totalTrustDownvotes || 0,
              })}
            </div>
          </div>
        ),
        defaultChecked:
          status !== CoworkerStatus.Member && yourTrustLevel === -1,
      },
    ],
    [id, status, totalTrustUpvotes, yourTrustLevel, totalTrustDownvotes, t]
  );

  React.useEffect(() => {
    setCurrentTrust(yourTrustLevel);
  }, [setCurrentTrust, yourTrustLevel]);

  React.useEffect(() => {
    if (!disabled) {
      setError(false);
    }
  }, [disabled, setError]);

  return (
    <div
      className={classNames([
        {
          "cursor-wait": loading,
        },
      ])}
    >
      <SegmentedControl
        legendTitle={t("trustCoworkers")}
        name={`${id}-coworker-trust`}
        options={radioOptions}
        labelStyles={classNames([
          "t-micro",
          {
            "cursor-pointer hoverable": !loading,
            "cursor-wait": loading,
          },
        ])}
        onClick={async (e) => {
          const target = e.target as HTMLInputElement;
          const trust = +target.value;

          if (currentTrust === trust) {
            return;
          }
          if (disabled) {
            setError(true);
            return;
          }
          setCurrentTrust(trust);
          setError(false);
          await coworkerTrustVote({ coworkerId: id, trust });
        }}
      />

      {error && (
        <div className="t-mini pt-1 text-frank-red-400">
          {t("setEmploymentTypeAndNameOrEmail")}
        </div>
      )}
    </div>
  );
}

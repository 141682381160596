import * as React from "react";
import { QRCodeSVG } from "qrcode.react";
import {
  IconButton,
  Intent,
  ScreenSizes,
  Sizes,
  useBreakpoint,
  useToaster,
} from "@get-frank-eng/design-system";
import { useInstallPromptContext } from "../../providers/InstallPromptProvider";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { kebabCase } from "lodash";

export const useQRCodeUtils = () => {
  const toaster = useToaster();
  const { t } = useTranslation();

  const copyCanvasContentsToClipboard = React.useCallback(
    (canvas) => {
      canvas.toBlob(function (blob) {
        let data = [new ClipboardItem({ [blob.type]: blob })];

        navigator.clipboard.write(data).then(
          function () {
            toaster.addToast({
              children: t("copiedToYourClipboard"),
              intent: Intent.SUCCESS,
            });
          },
          function (err) {
            console.log("err", err);
            toaster.addToast({
              children: t("somethingWentWrong"),
              intent: Intent.FAILURE,
            });
          }
        );
      });
    },
    [toaster, t]
  );

  const copyDownloadShareQRCode = React.useCallback(
    async ({
      action,
      qrCodeTitle,
    }: {
      action: "copy" | "download" | "share";
      qrCodeTitle: string;
    }) => {
      const domElement = document.getElementById("qr-code");
      const title = document.getElementById("qr-code-title");
      //@ts-ignore
      title.style = "color: black";
      switch (action) {
        case "download":
          html2canvas(domElement).then((canvas) => {
            const img = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.href = img;
            link.download = kebabCase(qrCodeTitle) + ".png";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
          break;
        case "copy":
          html2canvas(domElement).then((canvas) => {
            if (navigator.clipboard) {
              copyCanvasContentsToClipboard(canvas);
            }
          });
          break;
        case "share":
          html2canvas(domElement).then(async (canvas) => {
            canvas.toBlob(async (blob) => {
              try {
                await navigator.share({
                  files: [new File([blob], kebabCase(qrCodeTitle) + ".png")],
                });
              } catch (e) {
                console.error("Error using native share", e);
              }
            });
          });
          break;
      }

      //@ts-ignore
      title.style = "color: #FBFAF9";
    },
    [copyCanvasContentsToClipboard]
  );

  return { copyDownloadShareQRCode };
};

export const QRCodeAndActions = ({
  qrCodeTitle,
  link,
}: {
  qrCodeTitle: string;
  link: string;
}) => {
  // copying the image does not work in safari for some reason - possibly due to how we're calling clipboard.write: https://developer.apple.com/forums/thread/691873
  const { isSafari } = useInstallPromptContext();
  const { copyDownloadShareQRCode } = useQRCodeUtils();
  const supportsShare = !!navigator.share;
  const isMobile = useBreakpoint() <= ScreenSizes.SM;

  return (
    <div className="flex flex-col justify-center items-center">
      <div>
        <div
          id="qr-code"
          className="p-4 flex flex-col justify-center items-center"
        >
          <div className="t-small plus" id="qr-code-title">
            {qrCodeTitle}
          </div>
          <div className="border rounded p-4 m-4">
            <QRCodeSVG value={link} />
          </div>
        </div>

        <div className="flex justify-center px-8 -mt-4 space-x-4">
          <IconButton
            icon="cloud_download"
            size={Sizes.XL}
            onClick={async () =>
              copyDownloadShareQRCode({ action: "download", qrCodeTitle })
            }
          />
          {!isSafari && window.ClipboardItem && !isMobile && (
            <IconButton
              icon="content_copy"
              size={Sizes.XL}
              onClick={async () =>
                copyDownloadShareQRCode({ action: "copy", qrCodeTitle })
              }
            />
          )}
          {supportsShare && isMobile && (
            <IconButton
              icon="share"
              size={Sizes.XL}
              onClick={async () =>
                copyDownloadShareQRCode({ action: "share", qrCodeTitle })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

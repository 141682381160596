import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { useModals } from "../../Modals";
import { Intent, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

const REMOVE_COLLABORATOR = gql`
  mutation RemoveCollaborator($input: RemoveCollaboratorDTO!) {
    removeCollaborator(input: $input) {
      nothing
    }
  }
`;

export default function useRemoveCollaborator({
  campaignId,
  onRemove,
}: {
  campaignId: string;
  onRemove: () => any;
}) {
  const { t } = useTranslation();

  const { openConfirmationModal } = useModals();
  const toaster = useToaster();
  const [invokeRemoveCollaborator, { loading, error }] = useMutation<
    any,
    { input: FrankBackendTypes.RemoveCollaboratorDto }
  >(REMOVE_COLLABORATOR);

  const removeCollaborator = React.useCallback(
    async (coworkerId: FrankBackendTypes.Coworker["id"]) => {
      const confirmRemove = await openConfirmationModal({
        title: t("areYouSureYouWantToRemoveThisOrg"),
        bodyText: t("ifYouRemoveThemTheyWillNoLonger") as string,
        actionText: t("confirm"),
      });
      if (confirmRemove) {
        try {
          await invokeRemoveCollaborator({
            variables: {
              input: {
                campaignId,
                collaboratorCoworkerId: coworkerId,
              },
            },
          });

          toaster.addToast({
            intent: Intent.SUCCESS,
            children: t("organizerSuccessfullyRemoved"),
          });

          onRemove();
        } catch (e) {
          toaster.addToast({
            intent: Intent.FAILURE,
            children: t("somethingWentWrongRemovingTheOrgani"),
          });
          throw e;
        }
      }
    },
    [
      openConfirmationModal,
      invokeRemoveCollaborator,
      campaignId,
      onRemove,
      toaster,
      t,
    ]
  );

  return {
    removeCollaborator,
    loadingRemoveCollaborator: loading,
    errorRemoveCollaborator: error,
  };
}

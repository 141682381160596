import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import useRecentlyAdded from "./dataAccess/useRecentlyAdded";
import useTableDataFetcher from "./dataAccess/useTableDataFetcher";
import useIsScrolled from "./hooks/useIsScrolled";
import tablePresentations from "./table/tablePresentations";
import { transformRawColumnData } from "./util";
import { useRenderActionBar } from "./hooks/useRenderActionBar";
import Table from "./table";
import { Button, LinkButton, Sizes } from "@get-frank-eng/design-system";
import { CoworkerTableCard } from "./components/CoworkerTableCard";
import { CoworkerEmploymentType } from "frank-types/dist/frank-backend-types";
import { useTranslation } from "react-i18next";

export const UnverifiedCoworkersTable = ({
  showAll = false,
}: {
  showAll?: boolean;
}) => {
  const { t } = useTranslation();

  const setting = tablePresentations["workers"];
  const ref = React.useRef<HTMLDivElement>();
  const { isScrolledX } = useIsScrolled<HTMLDivElement>(ref);

  const [sortOrder, setSortOrder] = React.useState<{
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  }>({
    column: null,
    order: null,
  });

  // this doesn't actually show or hide columns in the table - it helps us preserve what's hidden through refetching data, which is important for sorting
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>([]);

  const { data, loading, fetchNextPage, refetch } = useTableDataFetcher({
    filter: setting.filter,
    order: sortOrder.column
      ? sortOrder
      : {
          column: FrankBackendTypes.CoworkerOrderColumns.CreatedAt,
          order: FrankBackendTypes.Order.Asc,
        },
  });

  const customColumns = data?.customColumns;

  const computedColumns = React.useMemo(
    () => setting.columns.concat(transformRawColumnData(customColumns)),
    [customColumns, setting.columns]
  );

  const {
    changeAndSetRecentlyAdded,
    changeLoading,
    computedCoworkers,
    addCoworker,
    coworkerErrors,
  } = useRecentlyAdded({
    coworkers: data?.coworkers.objects,
    allowAdd: setting.allowAdd,
    defaultForNewRows: {
      employmentType: CoworkerEmploymentType.Worker,
      email: null,
      name: null,
    },
    refetchFromBackend: refetch,
    addEmptyRows: true,
  });

  const renderActionBar = useRenderActionBar({
    tablePresentations: setting,
    refetch,
    addCoworker,
  });

  return (
    <>
      <CoworkerTableCard
        subtitle={t("trackPotentialGroupMembers")}
        title={t("unknownUnverifiedWorkers")}
        to="/coworkers/workers"
        loading={!data?.coworkers}
        rightTop={
          <div className="pt-4 flex flex-row space-x-2">
            <Button onClick={addCoworker} size={Sizes.SM} buttonStyle="brand">
              {t("addWorker")}
            </Button>
            <LinkButton
              to="#"
              onClick={(e) => {
                window.location.href =
                  "mailto:frank@getfrank.com?subject=Upload data to Coworkers page";
                e.preventDefault();
              }}
              size={Sizes.SM}
              buttonStyle="secondary"
            >
              {t("upload")}
            </LinkButton>
          </div>
        }
      >
        {data?.coworkers && (
          <Table
            key={`${JSON.stringify(setting.filter)} ${JSON.stringify(
              sortOrder
            )}`}
            data={data.coworkers}
            columns={setting.allowAdd ? computedColumns : setting.columns}
            coworkerErrors={coworkerErrors}
            allowAdd={setting.allowAdd}
            loading={loading}
            fetchNextPage={fetchNextPage}
            isScrolledX={isScrolledX}
            renderActionBar={renderActionBar}
            changeAndSetRecentlyAdded={changeAndSetRecentlyAdded}
            changeLoading={changeLoading}
            computedCoworkers={computedCoworkers}
            customColumns={customColumns}
            refetch={refetch}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            infinite={showAll}
            view="workers"
            addCoworker={addCoworker}
            setHiddenColumns={setHiddenColumns}
            currentlyHiddenColumns={hiddenColumns}
          />
        )}
      </CoworkerTableCard>

      {data?.coworkers.hasNext && !showAll && (
        <div className="inline-block sticky left-0">
          <LinkButton
            to="/coworkers/workers"
            style={{ justifyContent: "flex-start" }}
            buttonStyle="outline"
            className="mt-4 ml-4"
          >
            {t("showAllRows")}
          </LinkButton>
        </div>
      )}
    </>
  );
};

import {
  InlineAlert,
  Intent,
  Button,
  Sizes,
  Nudge,
  Icon,
} from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import { RawLoading } from "../../../components/Loading";
import React from "react";
import {
  useCoworkerCustomFormResponseListsQuery,
  useGetCustomFormQuery,
} from "../../../generated-hooks";
import { dateFormatter } from "../../../utils/Date";
import CustomFormResponseSubmissionsTable from "./CustomFormResponseSubmissionsTable";
import useCustomFormsPathing from "./useCustomFormsPathing";

const CoworkerCustomFormResponses = (): JSX.Element => {
  const { t } = useTranslation();
  const { preferredLanguage } = useCurrentUserData();
  const { generateCustomFormUrl } = useCustomFormsPathing();
  const { id, groupId, coworkerId } = useParams<{
    id: string;
    groupId: string;
    coworkerId: string;
  }>();
  const {
    data: customFormData,
    loading: customFormLoading,
    error: customFormError,
  } = useGetCustomFormQuery({
    variables: { customFormId: id },
  });

  const {
    data,
    error,
    loading,
    refetch,
  } = useCoworkerCustomFormResponseListsQuery({
    variables: {
      customFormId: id,
      groupId,
      coworkerId,
      pagination: {
        page: 0,
        perPage: 200,
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const relevantCoworkerData = React.useMemo(() => {
    if (!data || data.getCoworkerCustomFormResponseLists.objects.length === 0) {
      return null;
    }
    const relevant = data.getCoworkerCustomFormResponseLists.objects[0];
    return {
      ...relevant,
      responses: relevant.responses.filter((r) => r.submittedAt !== null),
    };
  }, [data]);

  if (loading || customFormLoading) {
    return <RawLoading />;
  }
  if (error || customFormError || relevantCoworkerData === null) {
    return (
      <div className="max-w-xl">
        <InlineAlert
          title="Error loading page"
          intent={Intent.FAILURE}
          actions={
            <Button
              size={Sizes.SM}
              onClick={() => {
                refetch();
              }}
            >
              {t("reload")}
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="pb-48 sm:pb-12 max-w-6xl mx-auto w-full space-y-4">
      <div>
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-1">
            <li>
              <div>
                <Link
                  to={generateCustomFormUrl(groupId)}
                  className="t-small plus underline"
                >
                  {t("allForms")}
                </Link>
              </div>
            </li>
            <li>
              <Nudge y={3}>
                <Icon
                  icon="chevron_right"
                  size={Sizes.LG}
                  classNames="flex-shrink-0"
                  aria-hidden="true"
                />
              </Nudge>
            </li>
            <li>
              <Link
                to={generateCustomFormUrl(groupId, id)}
                className="t-small plus underline"
              >
                {customFormData.customForm.title}
              </Link>
            </li>
            <li>
              <Nudge y={3}>
                <Icon
                  icon="chevron_right"
                  size={Sizes.LG}
                  classNames="flex-shrink-0"
                  aria-hidden="true"
                />
              </Nudge>
            </li>
            <li>
              <div className="flex items-center t-small">
                {relevantCoworkerData.coworker.name}
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <section className="border rounded p-4 max-w-sm">
        <h3 className="t-regular plus pb-2">
          {relevantCoworkerData.coworker.name} -&nbsp;
          {customFormData.customForm.title}
        </h3>
        <p className="t-small">
          {t("datePublished")}
          {dateFormatter(preferredLanguage).format(
            new Date(customFormData.customForm.createdAt)
          )}
        </p>
        <p className="t-small">
          {t("totalSubmissions")}: {relevantCoworkerData.responses.length}
        </p>
      </section>
      <section className="pt-8 space-y-4">
        <CustomFormResponseSubmissionsTable
          submittedResponses={[relevantCoworkerData]}
          groupId={groupId}
          customFormId={id}
          filteredByCoworker
        />
      </section>
    </div>
  );
};

export default CoworkerCustomFormResponses;

import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useModals } from "../Modals";
import {
  InlineAlert,
  Button,
  FormGroup,
  Intent,
  Select,
  Textarea,
  useToaster,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

interface Props {
  toFlag: Array<{ label: string; id: string }>;
  onSubmit?: () => any;
}

interface FormProps {
  category: string;
  notes: string;
}

const FLAG = gql`
  mutation Flag($input: FlagUserInput!) {
    flagUser(input: $input) {
      nothing
    }
  }
`;

const FlagModal = ({ toFlag, onSubmit }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToaster();
  const { closeModal } = useModals();

  const { register, handleSubmit } = useForm<FormProps>();

  const [invokeFlag, { called, loading, error: saveError }] = useMutation<
    any,
    { input: FrankBackendTypes.FlagUserInput }
  >(FLAG);

  const namePart =
    toFlag.length === 1
      ? toFlag[0].label
      : t("workerWithCount", { count: toFlag.length });

  const submit = React.useCallback(
    async ({ category, notes }) => {
      await invokeFlag({
        variables: {
          input: {
            userIds: toFlag.map((f) => f.id),
            category,
            notes,
          },
        },
      });

      addToast({
        intent: Intent.SUCCESS,
        children: t("hasBeenReported", {
          count: toFlag.length,
          namePart,
        }),
      });

      if (onSubmit) {
        onSubmit();
      }
    },
    [invokeFlag, toFlag, addToast, namePart, onSubmit, t]
  );

  if (called && !loading && !saveError) {
    closeModal();
  }

  return (
    <>
      <ModalHeader title={t("flagName", { namePart })} />
      <ModalBody>
        {saveError && (
          <InlineAlert title={t("serverError")} intent={Intent.FAILURE}>
            {t("thereWasAProblemFlaggingThisUser")}
          </InlineAlert>
        )}
        <form id="flag-form" onSubmit={handleSubmit(submit)} noValidate>
          <div className="my-2 py-2">
            <FormGroup id="category" name="category" label={t("reason")}>
              <Select placeholder="..." register={register}>
                <option value="manager">
                  {t("theyAreAManagerAtOurCompany")}
                </option>
                <option value="harassment">
                  {t("theyHarassedMeAndOrAnotherWorkerI")}
                </option>
                <option value="no-longer-employed">
                  {t("theyAreNoLongerEmployedAtOurCompa")}
                </option>
                <option value="other">{t("other")}</option>
              </Select>
            </FormGroup>
          </div>
          <FormGroup
            label={t("giveUsSomeContextOnTheSituation")}
            name="notes"
            id="notes"
          >
            <Textarea
              maxLength={1200}
              data-cy="description-of-flag"
              register={register}
              registerArgs={{ required: true, maxLength: 1200 }}
            />
          </FormGroup>
          <ModalFooter>
            <>
              <Button buttonStyle="minimal" onClick={closeModal}>
                {t("cancel")}
              </Button>
              <Button
                form="flag-form"
                onClick={handleSubmit(submit)}
                loading={loading}
                data-cy="send-report"
                type="submit"
              >
                {t("report")}
              </Button>
            </>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
};

export default FlagModal;

import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

export const homeFeedFragment = gql`
  fragment HomeFeedFragment on PaginatedActivity {
    hasNext
    total
    objects {
      link
      id
      time
      quoteText
      htmlDescription
      verb
      timeAgo
      campaign {
        id
        title
      }
      user {
        id
        shortName
        isYou
        profilePic {
          id
          url
        }
      }
    }
  }
`;

const query = gql`
  query HomeFeed($feedPagination: PaginationArgumentDTO!) {
    myProfile {
      id
      homeFeed(pagination: $feedPagination) {
        ...HomeFeedFragment
      }
    }
  }
  ${homeFeedFragment}
`;

export default function useHomeFeedData({ perPage }: { perPage: number }) {
  const { data, loading, error, fetchMore } = useQuery<
    { myProfile: FrankBackendTypes.MyProfile },
    { feedPagination: FrankBackendTypes.PaginationArgumentDto }
  >(query, {
    variables: {
      feedPagination: { page: 0, perPage },
    },
  });

  const fetchMoreHomeFeed = React.useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          perPage,
          page: Math.ceil(data?.myProfile.homeFeed.objects.length / perPage),
        },
      },
    });
  }, [fetchMore, perPage, data]);

  return { data: data?.myProfile.homeFeed, loading, error, fetchMoreHomeFeed };
}

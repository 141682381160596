export const findLanguage = (langStr) => {
  if (/^en\b/.test(langStr)) {
    return "en";
  }
  if (/^es\b/.test(langStr)) {
    return "es";
  }

  return "en";
};

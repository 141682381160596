import { Button } from "@get-frank-eng/design-system";
import { TaskAction } from "frank-types/dist/frank-backend-types";
import { useTranslation } from "react-i18next";
import { useUpdateAssignment } from "../../../../Tasks/hooks/useUpdateAssignment";
import PrimaryActionItem from "./PrimaryActionItem";

const TaskPrimaryActionEmbed = ({
  task,
  onUpdate,
}: {
  task: TaskAction;
  onUpdate: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { updateAssignment } = useUpdateAssignment({
    callback: onUpdate,
  });
  const actions = [
    {
      label: t("complete"),
      action: () =>
        updateAssignment({
          id: task.assignmentId,
          workflowState: task.assignmentWorkflowState,
        }),
    },
  ];
  return (
    <PrimaryActionItem
      expandable={false}
      isExpanded={false}
      setIsExpanded={() => null}
    >
      <div className="pt-4">
        <div className="mb-2">{task.title}</div>
        {task.description && (
          <div className="t-mini mb-2">{task.description}</div>
        )}
        <div className="mt-4">
          {actions.map((action) => (
            <Button
              key={action.label}
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                action.action();
              }}
              buttonStyle="brand"
              className="mx-2"
            >
              {action.label}
            </Button>
          ))}
        </div>
      </div>
    </PrimaryActionItem>
  );
};

export default TaskPrimaryActionEmbed;

import LeadOrganzer from "./LeadOrganizer";
import WelcomeBanner from "./WelcomeBanner";

const GroupInfo = () => {
  return (
    <div className="grid grid-cols-12 auto-rows-max space-x-0 sm:space-x-4 lg:space-x-0 mb-2 space-y-4 sm:space-y-0 lg:space-y-4">
      <div className="col-span-12 sm:col-span-8 lg:col-span-12">
        <WelcomeBanner />
      </div>
      <div className="col-span-12 sm:col-span-4 lg:col-span-12">
        <LeadOrganzer />
      </div>
    </div>
  );
};

export default GroupInfo;

import {
  Accordion,
  Button,
  ModalBody,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";
import { useNavigationState } from "../../Navigation/hooks/useNavigationState";
import { useCurrentTourContext } from "../../Tour/CurrentTourProvider";
import useRetakeTour from "../../Tour/dataAccess/useRetakeTour";
import { useTranslation } from "react-i18next";

// must alter Modals.tsx if you need to add props e.g. <Modal {...modal.props} />

const CoworkersFAQModal = () => {
  const { retakeTour } = useRetakeTour();
  const { setRetakingTour, setCurrentTour } = useCurrentTourContext();
  const { refetch } = useNavigationState();
  const { closeModal } = useModals();
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader title={t("help")} />
      <ModalBody>
        <>
          <h2 className="t-large plus">{t("faQs")} </h2>

          <Accordion title={t("howDoIUseThisPage")}>
            <p className="t-small">{t("theCoworkersPageHelpsYouOrganizeYo")}</p>
          </Accordion>

          <Accordion title={t("whatDoesItMeanToTrustSomeone")}>
            <p className="t-small">{t("groupMembersHaveControlOverWhoIsO")}</p>
          </Accordion>

          <Accordion title={t("howDoesVouchingWork")}>
            <p className="t-small">{t("inOrderToJoinYouNeedToBeEligibl")}</p>
          </Accordion>
          <div className="pt-4">
            <Button
              onClick={() => {
                setRetakingTour(true);
                retakeTour(FrankBackendTypes.TourType.Coworkers);
                setCurrentTour(FrankBackendTypes.TourType.Welcome);
                refetch();
                closeModal();
              }}
            >
              {t("showProductTour")}
            </Button>
          </div>
        </>
      </ModalBody>
    </>
  );
};

export default CoworkersFAQModal;

import {
  Button,
  IconButton,
  Intent,
  MultiSelect,
  PillBadge,
  SelectOption,
  Sizes,
  useToaster,
} from "@get-frank-eng/design-system";
import { AbilityAction } from "frank-types";
import * as React from "react";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import Loading from "../../components/Loading";
import {
  useAddOrganizersToGroupsMutation,
  useOrganizerQuery,
  useRemoveOrganizersFromGroupMutation,
} from "../../generated-hooks";
import { useModals } from "../../Modals";
import Sentry from "../../sentryClient";
import useRemoveOrganizer from "../dataAccess/hooks/mutations/useRemoveOrganizer";
import useGroupReport from "../dataAccess/hooks/queries/useGroupReport";
import { useTranslation } from "react-i18next";

export const EditOrganizerGroups: React.FC<{
  organizerId: string;
  partnerId: string;
}> = ({ organizerId, partnerId }) => {
  const { t } = useTranslation();
  const { openConfirmationModal } = useModals();
  const [
    invokeRemoveOrganizerFromGroup,
  ] = useRemoveOrganizersFromGroupMutation();

  const [
    invokeAddOrganizersToGroupMutation,
  ] = useAddOrganizersToGroupsMutation();

  const { myProfile, ability, currentGroup } = useCurrentUserData();
  const { removeOrganizer } = useRemoveOrganizer();
  const { data: organizerData, refetch } = useOrganizerQuery({
    variables: {
      organizerId,
    },
  });

  const organizer = organizerData?.organizer;

  const [newGroups, setNewGroups] = React.useState<SelectOption[]>([]);

  const { groups, loading } = useGroupReport({
    partnerId,
  });

  const toaster = useToaster();

  const canRemoveOrganizer = ability?.can(AbilityAction.Create, "Partner");

  const possibleGroupsToJoin = React.useMemo(() => {
    if (!groups || !organizer) {
      return [];
    }
    return groups.objects.filter(
      (g) =>
        !organizer.user.currentOnboardings.map((o) => o.groupId).includes(g.id)
    );
  }, [groups, organizer]);

  const filterGroups = (inputValue: string) => {
    return possibleGroupsToJoin
      .filter((g) =>
        g.groupName.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((g) => ({
        label: g.groupName,
        value: g.id,
      }));
  };

  // this is a promise b/c we're using the Async version of the select
  const loadOptions = async (searchString) => filterGroups(searchString);

  const submitRemoveFromAllGroups = async (o) => {
    const confirm = await openConfirmationModal({
      bodyText: t("nameSAccountWillBeLockedAndTh", {
        name: o.user.name,
      }) as string,
      actionText: `Confirm`,
      title: t("areYouSureYouWantToRemoveNameFromAllGroups", {
        name: o.user.name,
      }),
    });
    if (!confirm) {
      return;
    }

    try {
      await removeOrganizer(o.id);
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("successfullyRemovedOrganizer"),
      });
      await refetch();
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
    }
  };

  if (!organizer) {
    return <Loading />;
  }

  return (
    <div className="space-y-6">
      <section>
        <div className="t-mini plus pb-2"> {t("currentGroups")}</div>
        <div className="grid grid-cols-2 gap-1">
          {organizer.user.currentOnboardings.map((o) => (
            <PillBadge
              key={o.id}
              size={Sizes.MD}
              backgroundColor="canvas-600"
              textColor="canvas-100"
            >
              <div className="flex items-center justify-between">
                <div>{o.group.name}</div>
                <div className="flex-grow" />
                <div>
                  <IconButton
                    icon="close"
                    type="button"
                    title={t("removeOrganizerFromThisGroup")}
                    onClick={async () => {
                      if (
                        o.groupId === currentGroup.id &&
                        organizer.userId === myProfile.id
                      ) {
                        return openConfirmationModal({
                          actionText: t("gotIt"),
                          bodyText: t("youCannotRemoveYourselfAsAnOrganiz", {
                            groupName: o.group.name,
                          }) as string,
                          title: t("cannotCompleteAction"),
                        });
                      }
                      if (organizer.user.currentOnboardings.length === 1) {
                        return openConfirmationModal({
                          actionText: t("gotIt"),
                          bodyText: t("thisIsTheOnlyGroupThatNameIs", {
                            name: organizer.user.name,
                          }) as string,
                          title: t("removeOrganizer"),
                        });
                      }
                      try {
                        const confirm = await openConfirmationModal({
                          actionText: t("confirm"),
                          bodyText: t("areYouSureYouWantToRemoveName", {
                            name: organizer.user.name,
                            groupName: o.group.name,
                          }) as string,
                          title: t("removeOrganizer"),
                        });
                        if (confirm) {
                          await invokeRemoveOrganizerFromGroup({
                            variables: {
                              userIds: [organizer.user.id],
                              groupId: o.group.id,
                            },
                          });
                          await refetch();
                          toaster.addToast({
                            intent: Intent.SUCCESS,
                            children: t("successfullyRemovedOrganizer"),
                          });
                        }
                      } catch (e) {
                        toaster.addToast({
                          intent: Intent.FAILURE,
                          children: t("somethingWentWrong"),
                        });
                        Sentry.captureException(e);
                      }
                    }}
                  />
                </div>
              </div>
            </PillBadge>
          ))}
        </div>
      </section>
      <section>
        <div className="t-mini plus pb-2">{t("addOrganizerToGroupS")}</div>
        <MultiSelect
          value={newGroups}
          loadOptions={loadOptions}
          defaultOptions={possibleGroupsToJoin?.map((g) => ({
            label: g.groupName,
            value: g.id,
          }))}
          onChange={(newValue) => setNewGroups(newValue)}
          isLoading={loading}
        />
        <div className="pt-2">
          <Button
            buttonStyle="brand"
            size={Sizes.SM}
            disabled={!newGroups.length}
            onClick={async () => {
              try {
                await invokeAddOrganizersToGroupMutation({
                  variables: {
                    groupIds: newGroups.map((g) => g.value),
                    userIds: [organizer.user.id],
                  },
                });
                setNewGroups([]);
                await refetch();
                toaster.addToast({
                  intent: Intent.SUCCESS,
                  children: t("successfullyAdded"),
                });
              } catch (e) {
                toaster.addToast({
                  intent: Intent.FAILURE,
                  children: t("somethingWentWrong"),
                });
                Sentry.captureException(e);
              }
            }}
          >
            {t("add")}
          </Button>
        </div>
      </section>
      {canRemoveOrganizer && organizer.user.id !== myProfile.id && (
        <section className="space-y-2">
          <div className="t-regular plus">{t("dangerZone")}</div>
          <Button onClick={() => submitRemoveFromAllGroups(organizer)}>
            {t("permanentlyRemoveOrganizerFromAllPa")}
          </Button>
        </section>
      )}
    </div>
  );
};

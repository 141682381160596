import { gql, useQuery } from "@apollo/client";
import { Ability } from "@casl/ability";
import { AbilityAction, FrankBackendTypes } from "frank-types";
import * as React from "react";
import { Redirect } from "react-router";
import { identify } from "../analytics";
import SentryClient from "../sentryClient";
import useIntercom from "../useIntercom";
import { useAuthState } from "./AuthState";
import i18n from "../i18n/config";

type Subjects =
  | FrankBackendTypes.Group
  | "Group"
  | "all"
  | "Partner"
  | FrankBackendTypes.Partner
  | "AuthorizationCardSignature"
  | "AuthorizationCard"
  | "Topic"
  | "VouchSettings";

interface CurrentUserData {
  myProfile: FrankBackendTypes.MyProfile;
  refetchCurrentUserData: () => void;
  ability: Ability<[AbilityAction, Subjects]> | null;
  unseenActivityForGroups: FrankBackendTypes.GroupActivity[];
}

const currentUserContext = React.createContext<CurrentUserData | null>(null);

const getMyProfile = gql`
  query Profile {
    myProfile {
      id
      name
      intercomHash
      email
      abilityRulesJson
      shortName
      firstName
      lastName
      language
      groups {
        channelOptions {
          allowAnonymousChannel
          allowFromFrankChannel
        }
        authorizationCard {
          additionalFields
          language
          id
          mySignature {
            email
            firstName
            lastName
            employer
            phone
            signature
            id
            createdAt
            authCardFormResponseJSON
            confirmedByUser
          }
        }
        inviteMessage
        welcomeMessage
        id
        isCurrentGroup
        leadOrganizer {
          id
          user {
            id
            name
            profilePic {
              url
            }
          }
        }
        name
        company
        size
        partnerId
        organizers {
          userId
        }
        partner {
          id
          authorizationCardFormId
          name
          slug
          logoAttachment {
            id
            url
          }
        }
        logo {
          url
        }
      }
      profilePic {
        id
        url
      }
      workerType
      onboardingWorkflowState
      coworker {
        id
      }
    }
    unseenActivityForGroups {
      groupId
      unseenChatActivity
      unseenNotifications
    }
  }
`;

export const CurrentUserProvider = ({ children }) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "myProfile"> &
      Pick<FrankBackendTypes.Query, "unseenActivityForGroups">
  >(getMyProfile);

  const { setOnboardingState } = useAuthState();

  const currentGroup: FrankBackendTypes.Group = React.useMemo(() => {
    if (data?.myProfile) {
      return data?.myProfile.groups.find((group) => group.isCurrentGroup);
    }
    return {} as FrankBackendTypes.Group;
  }, [data]);

  useIntercom({
    userId: data?.myProfile.id,
    intercomHash: data?.myProfile.intercomHash,
    email: data?.myProfile.email,
    groupId: currentGroup?.id,
    groupName: currentGroup?.name,
  });

  const ability = React.useMemo(() => {
    if (!data) {
      return null;
    }
    const ab = new Ability<[AbilityAction, Subjects]>(
      JSON.parse(data.myProfile.abilityRulesJson),
      {
        detectSubjectType: (object) => object["__typename"],
      }
    );
    return ab;
  }, [data]);

  React.useEffect(() => {
    if (data) {
      try {
        SentryClient.configureScope((scope) => {
          scope.setTag("groupId", currentGroup?.id);
          scope.setUser({ id: data?.myProfile.id });
        });
        setOnboardingState({
          onboardingState: data.myProfile.onboardingWorkflowState,
        });
        identify(data.myProfile.id);
      } catch (e) {}
    }
  }, [data, currentGroup, setOnboardingState]);

  if (loading) {
    return null;
  }

  if (error) {
    return <Redirect to="/logout" />;
  }

  return (
    <currentUserContext.Provider
      value={{
        ability,
        myProfile: data?.myProfile,
        refetchCurrentUserData: refetch,
        unseenActivityForGroups: data?.unseenActivityForGroups,
      }}
    >
      {children}
    </currentUserContext.Provider>
  );
};

export function useCurrentUserData() {
  const {
    myProfile,
    ability,
    refetchCurrentUserData,
    unseenActivityForGroups,
  } = React.useContext(currentUserContext);

  const [preferredLanguage, setPreferredLanguage] = React.useState("en");

  const currentGroup: FrankBackendTypes.Group = React.useMemo(() => {
    if (myProfile) {
      const currentGroup = myProfile.groups.find(
        (group) => group.isCurrentGroup
      );
      if (!currentGroup) {
        window.location.href = `${process.env.PUBLIC_URL}/logout`;
      }
      return myProfile.groups.find((group) => group.isCurrentGroup);
    }
    return {} as FrankBackendTypes.Group;
  }, [myProfile]);

  const notCurrentGroups: FrankBackendTypes.Group[] = React.useMemo(() => {
    if (myProfile) {
      return myProfile.groups.filter((group) => !group.isCurrentGroup);
    }
    return [] as FrankBackendTypes.Group[];
  }, [myProfile]);

  React.useEffect(() => {
    if (myProfile) {
      localStorage.setItem("i18n-language", myProfile.language);
      i18n.changeLanguage(myProfile.language);
      setPreferredLanguage(myProfile.language);
    }
  }, [myProfile, setPreferredLanguage]);

  return {
    myProfile,
    ability,
    currentGroup,
    partner: currentGroup.partner,
    notCurrentGroups,
    refetchCurrentUserData,
    unseenActivityForGroups,
    preferredLanguage,
  };
}

import html2canvas from "html2canvas";
import jsPdf from "jspdf";

export function printPDF({
  id,
  width,
  filename,
  x,
  y,
  height,
}: {
  id: string;
  width: number;
  filename: string;
  x?: number;
  y?: number;
  height?: number;
}) {
  const domElement = document.getElementById(id);
  html2canvas(domElement).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPdf();
    pdf.addImage(
      imgData,
      "JPEG",
      x || 40,
      y || 25,
      width,
      height || domElement.clientHeight * 0.25
    );
    pdf.save(filename);
  });
}

export async function printElementsToPages({
  className,
  width,
  filename,
}: {
  className: string;
  width: number;
  filename: string;
}) {
  const pdf = new jsPdf();

  const domElements = document.querySelectorAll(className);

  for (const domElement of domElements) {
    const canvas = await html2canvas(domElement as HTMLElement, {
      onclone: (document) => {
        document.getElementById("download-button").style.visibility = "hidden";
      },
    });
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(
      imgData,
      "JPEG",
      40,
      25,
      width,
      domElement.clientHeight * 0.25
    );
    pdf.addPage();
  }
  pdf.deletePage(domElements.length + 1);
  pdf.save(filename);
}

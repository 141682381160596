import * as React from "react";
import { useForm } from "react-hook-form";
import ContentContainer from "../components/ContentContainer";
import BackAndContinue from "../components/BackAndContinue";
import usePetitionRecipients from "../dataAccess/mutations/usePetitionRecipients";
import {
  Button,
  FormGroup,
  Icon,
  Input,
  Sizes,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

const PetitionRecipientCard = ({ recipient, onRemove }) => {
  return (
    <div className="flex flex-row w-full items-center pb-8">
      <div className="w-full">
        <div className="border-b bg-transparent py-3 mb-3">
          <div>
            <div className="t-regular">{recipient.fullName}</div>
          </div>
        </div>
        <div className="border-b bg-transparent py-3">
          <div>
            <div className="t-regular">{recipient.email}</div>
          </div>
        </div>
      </div>
      <button className="self-start ml-4" type="button" onClick={onRemove}>
        <Icon icon="close" size={Sizes.MD} />
      </button>
    </div>
  );
};

const PetitionRecipients = ({
  campaign,
  refetch,
}: {
  campaign: FrankBackendTypes.Campaign;
  refetch: () => any;
}) => {
  const [nameFieldDirty, setNameFieldDirty] = React.useState(false);
  const [emailFieldDirty, setEmailFieldDirty] = React.useState(false);
  const {
    addRecipient,
    loadingAddRecipient: loading,
    removeRecipient,
  } = usePetitionRecipients({ campaignId: campaign.id });
  const { t } = useTranslation();

  const { errors, handleSubmit, register, reset } = useForm<{
    fullName: string;
    email: string;
  }>();

  const submit = async ({ email, fullName }) => {
    await addRecipient({ fullName, email });
    await refetch();
    reset({ email: "", fullName: "" });
  };
  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">{t("addRecipientsForYourPetition")}</h2>

      <p className="t-regular text-canvas-400 mb-6">
        {t("whomWouldYouLikeToSendThePetition")}
      </p>
      <section className="pb-8">
        {!!campaign?.petitionRecipients.length &&
          campaign.petitionRecipients.map((recipient) => (
            <PetitionRecipientCard
              recipient={recipient}
              key={recipient.id}
              onRemove={async () => {
                await removeRecipient(recipient.id);
                await refetch();
              }}
            />
          ))}
      </section>

      <form
        onSubmit={handleSubmit(submit)}
        noValidate
        onBlur={handleSubmit(submit)}
        className="pb-6"
      >
        <FormGroup label={t("name")} name="fullName" id="fullName">
          <Input
            errorText={
              errors.fullName &&
              nameFieldDirty &&
              (t("pleaseEnterAName") as string)
            }
            disabled={!campaign}
            type="text"
            register={register}
            registerArgs={{ required: true }}
            onChange={() => setNameFieldDirty(true)}
          />
        </FormGroup>
        <FormGroup label={t("email")} name="email" id="email">
          <Input
            errorText={
              errors.email &&
              emailFieldDirty &&
              (t("pleaseEnterAValidEmail") as string)
            }
            disabled={!campaign}
            type="email"
            register={register}
            registerArgs={{ required: true, pattern: /^\S+@\S+$/i }}
            onChange={() => setEmailFieldDirty(true)}
          />
        </FormGroup>
        <Button
          buttonStyle="outline"
          type="submit"
          iconLeft="add"
          className="flex flex-row items-center"
          loading={loading}
        >
          {t("addAnotherRecipient")}
        </Button>
      </form>

      <BackAndContinue workflowState={campaign.workflowState} />
    </ContentContainer>
  );
};

export default PetitionRecipients;

import { Intent, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import useUpdateTaskAssignment from "../dataAccess/mutations/useUpdateTask";
import { useTranslation } from "react-i18next";

export const useUpdateAssignment = ({
  callback,
}: {
  callback: () => Promise<any>;
}) => {
  const { t } = useTranslation();
  const { updateTaskAssignment, loading } = useUpdateTaskAssignment();

  const toaster = useToaster();

  const updateAssignment = async (assignment) => {
    const futureState =
      assignment.workflowState ===
      FrankBackendTypes.AssignmentWorkflowState.Complete
        ? FrankBackendTypes.AssignmentWorkflowState.Incomplete
        : FrankBackendTypes.AssignmentWorkflowState.Complete;
    try {
      await updateTaskAssignment({
        assignmentId: assignment.id,
        workflowState: futureState,
        completedAt:
          futureState === FrankBackendTypes.AssignmentWorkflowState.Complete
            ? new Date()
            : null,
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("success"),
      });
      await callback();
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
    }
  };

  return {
    updateAssignment,
    loading,
  };
};

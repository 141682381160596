import { Button } from "@get-frank-eng/design-system";
import { AbilityAction } from "frank-types";
import * as React from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import Loading from "../../components/Loading";
import { useModals } from "../../Modals";
import usePartners from "../dataAccess/hooks/queries/usePartners";
import { useTranslation } from "react-i18next";

const ListPartners = () => {
  const { t } = useTranslation();
  const { ability } = useCurrentUserData();
  const { partners, nextPage, loading, refetch } = usePartners();
  const canAccessPage = ability?.can(AbilityAction.Manage, "Partner");

  const { setModal } = useModals();

  if (!canAccessPage) {
    return <Redirect to="../home" />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="max-w-4xl mx-auto px-4 md:mt-12 mt-24 w-full">
      <div className="flex flex-row items-center justify-between">
        <h1 className="t-title-4 plus">{t("partners")}</h1>
        <Button
          onClick={() =>
            setModal({
              type: "createPartnerModal",
              props: {},
              afterClose: refetch,
            })
          }
        >
          {t("addPartner")}
        </Button>
      </div>
      <div className="mt-6 space-y-2">
        {partners.objects.map((p) => (
          <div>
            <Link to={`/partners/${p.slug}/groups`}>{p.name}</Link>
          </div>
        ))}
      </div>
      {partners.hasNext && (
        <div className="py-8">
          <Button onClick={nextPage}>{t("showMore")}</Button>
        </div>
      )}
    </div>
  );
};

export default ListPartners;

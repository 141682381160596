import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query CustomOnboardingFormResponses($pagination: PaginationArgumentDTO!) {
    onboardingFormResponses(pagination: $pagination) {
      hasNext
      objects {
        id
        responseJSON
      }
    }
  }
`;

const perPage = 25;

export default function useCustomOnboardingForm() {
  const { data, error, loading, fetchMore, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "onboardingFormResponses">,
    FrankBackendTypes.QueryOnboardingFormResponsesArgs
  >(QUERY, { variables: { pagination: { page: 0, perPage } } });

  const parsed: {
    id: string;
    coworker: any;
    onboardingForm: any;
  }[] = React.useMemo(() => {
    if (loading) {
      return [];
    }
    return data?.onboardingFormResponses?.objects.map((resp) => {
      return { id: resp.id, ...JSON.parse(resp.responseJSON) };
    });
  }, [data?.onboardingFormResponses, loading]);

  const keys: {
    coworker: string[];
    onboardingForm: string[];
  } = React.useMemo(() => {
    const keyLengths = parsed.map(
      (rec) => Object.keys(rec.onboardingForm).length
    );
    const firstNonBlankRecord =
      parsed.find((rec, i) => keyLengths[i] > 0) || parsed[0];
    if (!firstNonBlankRecord) {
      return {
        coworker: [],
        onboardingForm: [],
      };
    }
    return {
      coworker: Object.keys(firstNonBlankRecord.coworker).filter(
        (k) =>
          ![
            "id",
            "userId",
            "fullCount",
            "groupId",
            "employmentType",
            "status",
          ].includes(k)
      ),
      onboardingForm: Object.keys(firstNonBlankRecord.onboardingForm),
    };
  }, [parsed]);

  const loadNextPage = React.useCallback(() => {
    fetchMore({
      variables: {
        pagination: {
          perPage,
          page: Math.round(
            data?.onboardingFormResponses.objects.length / perPage
          ),
        },
      },
    });
  }, [fetchMore, data]);

  return {
    loading,
    hasNext: data?.onboardingFormResponses.hasNext,
    keys,
    error,
    loadNextPage,
    onboardingFormResponses: parsed,
    refetch,
  };
}

import * as React from "react";
import { gql, useMutation } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation UpdatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      id
      name
      defaultWelcomeMessage
    }
  }
`;

export default function useUpdatePartner() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "updatePartner">,
    FrankBackendTypes.MutationUpdatePartnerArgs
  >(mutation);

  const updatePartner = React.useCallback(
    (input: FrankBackendTypes.MutationUpdatePartnerArgs["input"]) => {
      return invokeMutation({
        variables: { input },
      });
    },
    [invokeMutation]
  );

  return {
    updatePartner,
    loading,
    error,
    called,
  };
}

import {
  useResponsive,
  ScreenSizes,
  TopBar,
  IconButton,
  useLayout,
  AttachedBadge,
  Sizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { UnverifiedCoworkersTable } from "./UnverifiedCoworkersTable";
import { MemberTable } from "./MemberTable";
import { ManagementTable } from "./ManagementTable";
import { CoworkerTopBarWrapper } from "./components/CoworkerTopBarWrapper";
import { useNavigationState } from "../Navigation/hooks/useNavigationState";
import { FrankBackendTypes } from "frank-types";
import { useCurrentTourContext } from "../Tour/CurrentTourProvider";
import { useTranslation } from "react-i18next";

const Coworkers = () => {
  const { screenSize } = useResponsive();
  const { setMobilePanel } = useLayout();
  const { navigation } = useNavigationState();
  const { setCurrentTour, currentTour } = useCurrentTourContext();
  const [showPing, setShowPing] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (
      navigation?.incompleteTours.includes(FrankBackendTypes.TourType.Coworkers)
    ) {
      setCurrentTour(FrankBackendTypes.TourType.Coworkers);
      setShowPing(true);
    }
  }, [navigation, setCurrentTour, setShowPing]);

  const top = (
    <CoworkerTopBarWrapper>
      <TopBar
        left={
          screenSize < ScreenSizes.MD && (
            <AttachedBadge
              color="brand-300"
              position="upper-right"
              size={Sizes.MD}
              ping
              show={
                currentTour === FrankBackendTypes.TourType.Coworkers || showPing
              }
            >
              <IconButton
                onClick={() => {
                  setShowPing(false);
                  setMobilePanel("left");
                }}
                buttonStyle="minimal"
                className="mobile-menu"
                icon="menu"
                size={Sizes.XL}
              />
            </AttachedBadge>
          )
        }
        title={
          <div className="flex flex-col items-center justify-center">
            <div>{t("coworkers")}</div>
            <div className="t-mini text-canvas-400 hidden sm:block">
              {t("manageAndTrackMembershipInYourGroup")}
            </div>
          </div>
        }
      />
    </CoworkerTopBarWrapper>
  );

  return (
    <>
      {top}
      <div className="coworkers-page">
        <section className="flex-grow pt-20">
          <MemberTable />
        </section>

        <section className="flex-grow pt-12">
          <UnverifiedCoworkersTable />
        </section>
        <section className="flex-grow pt-12">
          <ManagementTable />
        </section>
        <div className="h-48 sm:h-0" />
      </div>
    </>
  );
};

export default Coworkers;

import * as React from "react";
import last from "lodash/last";
import * as onboardingState from "../Onboarding/state/state";

export default function useScrollMgmt({
  state,
  sectionRefs,
  dispatch,
}: {
  state: onboardingState.State;
  sectionRefs: { [key: string]: React.MutableRefObject<HTMLDivElement> };
  dispatch: React.Dispatch<onboardingState.Action>;
}) {
  // listen and dispatch `stepInFocus` while scrolling
  const [
    lastStep,
    setLastStep,
  ] = React.useState<onboardingState.OnboardingStep | null>(null);
  const [atTop, setAtTop] = React.useState<boolean>(!state.stepInFocus);
  const onScrollCallback = React.useCallback(
    function onScrollCallback(e) {
      if (e.target.scrollTop === 0) {
        requestAnimationFrame(() => setAtTop(true));
      }
      if (e.target.scrollTop > 0) {
        requestAnimationFrame(() => setAtTop(false));
      }
      const sectionInView = last(
        Object.keys(sectionRefs).filter((key) => {
          const ref = sectionRefs[key];
          return (
            ref.current.querySelector(".step-label").getBoundingClientRect()
              .top <
            window.scrollY + 55
          );
        })
      ) as onboardingState.OnboardingStep;
      if (lastStep !== sectionInView) {
        dispatch({ type: "setStepInFocus", step: sectionInView });
      }
      setLastStep(sectionInView);
    },
    [...Object.values(sectionRefs), lastStep, setLastStep, atTop, setAtTop]
  );
  React.useEffect(() => {
    document.body.addEventListener("scroll", onScrollCallback);
    return () => document.body.removeEventListener("scroll", onScrollCallback);
  }, [...Object.values(sectionRefs), lastStep, setLastStep, atTop, setAtTop]);
  return { atTop };
}

import * as React from "react";
import { getChannel, getChannels } from "../core/channels/queries";
import { getWebsocket, listen, unlisten } from "../core/sockets";
import { createChannel as coreCreateChannel } from "../core/channels/mutations";

import {
  channelReducer,
  defaultChannelState,
  ChannelState,
} from "../state/channels";
import { ChannelType } from "frank-types";

const channelContext = React.createContext<
  ChannelState & {
    createChannel: ReturnType<typeof useChannelsInternal>["createChannel"];
    resetChannels: () => void;
    enterRoom: () => void;
    leaveRoom: () => void;
    clearUnread: (channelId: string) => void;
    loadChannels: () => Promise<void>;
    loadMoreMembers: ({ channelId }: { channelId: string }) => any;
    reloadChannel: ({ channelId }: { channelId: string }) => any;
  }
>({
  ...defaultChannelState,
  createChannel(a) {
    return Promise.resolve();
  },
  resetChannels() {},
  enterRoom() {},
  leaveRoom() {},
  clearUnread() {},
  async loadChannels() {},
  async loadMoreMembers() {},
  async reloadChannel() {},
});

export const ChannelsProvider: React.FC = ({ children }) => {
  const {
    channels,
    errors,
    loading,
    enterRoom,
    leaveRoom,
    createChannel,
    clearUnread,
    resetChannels,
    loadChannels,
    loadMoreMembers,
    reloadChannel,
  } = useChannelsInternal();
  React.useEffect(() => {
    enterRoom();

    return () => leaveRoom();
    // eslint-disable-next-line
  }, []);

  return (
    <channelContext.Provider
      value={{
        channels,
        errors,
        loading,
        createChannel,
        resetChannels,
        enterRoom,
        clearUnread,
        leaveRoom,
        loadChannels,
        loadMoreMembers,
        reloadChannel,
      }}
    >
      {children}
    </channelContext.Provider>
  );
};

export function useChannels() {
  return React.useContext(channelContext);
}

function useChannelsInternal() {
  const memberPaginationPerPage = 25;
  const [memberPage, setMemberPage] = React.useState(0);

  const [state, dispatch] = React.useReducer(
    channelReducer,
    defaultChannelState
  );

  const clearUnread = React.useCallback(
    (channelId) => {
      dispatch({
        type: "clear-unread",
        channelId,
      });
    },
    [dispatch]
  );

  const loadChannels = React.useCallback(
    async (silent: boolean = true) => {
      try {
        if (!silent) {
          dispatch({
            type: "loading",
            loadingType: "getChannels",
          });
        }

        const channels = await getChannels({
          memberPagination: {
            perPage: memberPaginationPerPage,
            page: memberPage,
          },
        });

        dispatch({
          type: "channels-loaded",
          channels,
        });

        dispatch({
          type: "finish-loading",
          loadingType: "getChannels",
        });
      } catch (e) {
        dispatch({
          type: "error",
          errorType: "getChannels",
          error: "Unable to load channels",
        });
      }
    },
    [dispatch, memberPage]
  );

  const reloadChannel = React.useCallback(
    async ({ channelId }: { channelId: string }) => {
      if (state.channels.map((c) => c.id).includes(channelId)) {
        const channel = await getChannel(channelId, {
          perPage: memberPaginationPerPage,
          page: memberPage,
        });
        dispatch({ type: "channel-changed", channel });
      } else {
        loadChannels();
      }
    },
    [dispatch, state.channels, loadChannels, memberPage]
  );

  const loadMoreMembers = React.useCallback(
    async ({ channelId }: { channelId: string }) => {
      setMemberPage(memberPage + 1);

      if (state.channels.map((c) => c.id).includes(channelId)) {
        const thisChannel = state.channels.find((c) => c.id === channelId);
        if (thisChannel) {
          const channel = await getChannel(channelId, {
            perPage: memberPaginationPerPage,
            page: Math.round(
              thisChannel?.members.objects.length / memberPaginationPerPage
            ),
          });
          dispatch({ type: "channel-changed", channel });
        }
      } else {
        loadChannels();
      }
    },
    [dispatch, state.channels, loadChannels, memberPage]
  );

  const createChannel = React.useCallback(
    async ({ name }: { name: string }) => {
      try {
        dispatch({
          type: "loading",
          loadingType: "createChannel",
        });

        await coreCreateChannel({ name, type: ChannelType.PUBLIC_ROOM });

        await loadChannels();

        dispatch({
          type: "finish-loading",
          loadingType: "createChannel",
        });
      } catch (e) {
        dispatch({
          type: "error",
          errorType: "createChannel",
          error: "Unable to load channels",
        });
      }
    },
    [dispatch, loadChannels]
  );

  const resetChannels = React.useCallback(() => {
    dispatch({
      type: "reset-channels",
    });
  }, [dispatch]);

  const enterRoom = React.useCallback(() => {
    loadChannels(false);
    listen({ event: "channel-updated", callback: reloadChannel });
    listen({
      event: "channel-options-updated",
      callback: loadChannels,
    });
    getWebsocket().then((s) => {
      s.on("reconnect", loadChannels);
    });
  }, [loadChannels, reloadChannel]);

  const leaveRoom = React.useCallback(() => {
    // loadChannels();
    unlisten({ event: "channel-updated", callback: reloadChannel });
    unlisten({
      event: "channel-options-updated",
      callback: loadChannels,
    });
    getWebsocket().then((s) => {
      s.off("reconnect", loadChannels);
    });
  }, [reloadChannel, loadChannels]);

  return {
    channels: state.channels,
    loading: state.loading,
    errors: state.errors,
    createChannel,
    leaveRoom,
    enterRoom,
    clearUnread,
    resetChannels,
    loadChannels,
    loadMoreMembers,
    reloadChannel,
  };
}

import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import IntercomShouldShow from "../components/IntercomShouldShow";
import {
  JumboAvatar,
  ScreenSizes,
  useResponsive,
} from "@get-frank-eng/design-system";
import useScrollMgmt from "../hooks/useScrollMgmt";
import Bottom from "./components/Bottom";
import Top from "./components/Top";
import { SectionHeader, StepLabel } from "./components/Typography";
import CreateAccountForm from "./CreateAccountForm";
import useFinishOnboarding from "./dataAccess/mutations/useFinishOnboarding";
import OnboardingFail from "./OnboardingFail";
import useDispatcher from "./state/dispatcher";
import { getInitialStateFromCache, getLocked, nextStep } from "./state/logic";
import makeCache from "./state/onboardingCache";
import * as onboardingState from "./state/state";
import stepOneText from "./stepOneText";
import TellUsAboutYourself from "./TellUsAboutYourself";
import WaitingRoom from "./WaitingRoom";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";
import { useModals } from "../Modals";
import { AuthStates, useAuthState } from "../Auth/AuthState";

export const Section = React.forwardRef<
  HTMLDivElement,
  { children: any; id?: string }
>(({ children, id }, ref) => {
  return (
    <div
      id={id}
      ref={ref}
      className="sm:text-center py-12 sm:py-16 border-b last:border-b-0 mx-4 sm:max-w-xl sm:mx-auto sm:px-10"
    >
      {children}
    </div>
  );
});

const { OnboardingStep } = onboardingState;

const OnboardingComponent = ({
  onboardingData,
  onboardingRefetch,
  token,
}: {
  onboardingData: FrankBackendTypes.Onboarding;
  onboardingRefetch: () => Promise<any>;
  token: string;
}) => {
  const { t } = useTranslation();
  const { setModal } = useModals();
  const { setCache, hydrateCache } = makeCache(token);
  const { authState } = useAuthState();

  const cache = React.useMemo(() => {
    return hydrateCache(onboardingData.onboardingCache);
  }, [onboardingData.onboardingCache, hydrateCache]);

  const [state, _dispatch] = React.useReducer(
    onboardingState.reducer,
    getInitialStateFromCache(cache)
  );

  const sectionRefs = {
    [OnboardingStep.CREATE_ACCOUNT]: React.useRef<HTMLDivElement>(),
    [OnboardingStep.TELL_US_ABOUT_YOURSELF]: React.useRef<HTMLDivElement>(),
    [OnboardingStep.VERIFY]: React.useRef<HTMLDivElement>(),
  };

  const scrollToStep = React.useCallback(
    (step: onboardingState.OnboardingStep, smooth = true) => {
      if (step && sectionRefs[step].current) {
        const rect = sectionRefs[step].current.getBoundingClientRect();
        window.scrollTo({
          top: rect.y,
          behavior: "smooth",
        });
      }
    },
    [...Object.values(sectionRefs)]
  );

  const dispatchSideEffects = React.useCallback(
    (action: onboardingState.Action) => {
      // set cache for submits
      if (action.type === "setSubmitted") {
        const stepToCacheKey = {
          [OnboardingStep.CREATE_ACCOUNT]: "createAccount.submitted",
          [OnboardingStep.TELL_US_ABOUT_YOURSELF]: "tellUs.submitted",
        };
        setCache(stepToCacheKey[action.step], true);
        scrollToStep(nextStep(action.step));
      }
    },
    [setCache, scrollToStep]
  );

  const dispatch = React.useCallback(
    (action: onboardingState.Action) => {
      _dispatch(action);
      dispatchSideEffects(action);
    },
    [_dispatch, dispatchSideEffects]
  );

  const {
    setLoading,
    setCreateAccountSubmitted,
    setTellUsSubmitted,
    setServerError,
    setHasExistingMembership,
  } = useDispatcher({
    dispatch,
  });

  // scroll to correct section at beginning
  React.useEffect(() => {
    if (!sectionRefs[onboardingState.OnboardingStep.CREATE_ACCOUNT].current) {
      return;
    }
    scrollToStep(state.stepInFocus, false);
  }, [
    scrollToStep,
    state.stepInFocus,
    sectionRefs[onboardingState.OnboardingStep.CREATE_ACCOUNT],
  ]);

  const { finishOnboarding } = useFinishOnboarding({ onboardingData });

  const enterWaitingRoom = React.useCallback(async () => {
    setTellUsSubmitted();
    await finishOnboarding();
    await onboardingRefetch();
  }, [onboardingRefetch, finishOnboarding, setTellUsSubmitted]);

  const { atTop } = useScrollMgmt({
    dispatch,
    sectionRefs,
    state,
  });

  const noManagersText = (
    <div>
      {t("theOwnerOfThisEmailAddressHasBeenFlagged")}
      <Link className="text-brand-300" to="/legal/toc">
        {t("learnMoreAboutOurPolicies")}
      </Link>
    </div>
  );

  const locked = getLocked(state);
  const { screenSize } = useResponsive();
  const isMobilePhone = screenSize < ScreenSizes.SM;

  const [dangerousEmail, setDangerousEmail] = React.useState(false);

  const stepTwoCompleted = state.submitted.has(
    onboardingState.OnboardingStep.TELL_US_ABOUT_YOURSELF
  );
  const stepOneCompleted = state.submitted.has(
    onboardingState.OnboardingStep.CREATE_ACCOUNT
  );

  const selectedStepOneText = stepOneText({
    complete: stepOneCompleted,
  });

  const focusInputAfterAnimation = React.useCallback(() => {
    if (!state.submitted.has(onboardingState.OnboardingStep.CREATE_ACCOUNT)) {
      const id = onboardingData.email ? "password" : "email";

      document.getElementById(id).focus();
    }
  }, [onboardingData, state.submitted]);

  if (dangerousEmail) {
    return (
      <OnboardingFail
        title={t("managersNotAllowed")}
        icon="not_interested"
        text={noManagersText}
        rotate={false}
      />
    );
  }

  if (state.hasExistingMembership) {
    setModal({
      type: "genericModal",
      props: {
        body: (
          <div data-cy="already-a-member-in-group-modal">
            {t("youAlreadyHaveAMembership")}
          </div>
        ),
      },
    });

    return <Redirect to="/login" />;
  }

  // doing this because sometimes people can get to 2nd step without being logged in, if their cache says they've already submitted account, but they need to log in.
  // hasExistingMembership is only set if you actually complete the createAccount form

  if (
    state.submitted.has(onboardingState.OnboardingStep.CREATE_ACCOUNT) &&
    !(authState === AuthStates.LOGGED_IN)
  ) {
    setModal({
      type: "genericModal",
      props: {
        body: (
          <div data-cy="already-a-member-in-group-modal">
            {t("youAlreadyHaveAMembership")}
          </div>
        ),
      },
    });

    return <Redirect to="/login" />;
  }

  return (
    <div className="w-screen">
      <IntercomShouldShow />
      <Top />
      <div className="pt-16 sm:pt-12  mx-auto sm:max-w-lg">
        <AnimatePresence>
          <motion.div
            key="step-one"
            animate={{ opacity: 1, y: -50 }}
            transition={{
              duration: 0.5,
              delay: state.submitted.has(
                onboardingState.OnboardingStep.CREATE_ACCOUNT
              )
                ? 0
                : 0.5,
            }}
            initial={{ opacity: 0, y: 50 }}
            onAnimationComplete={focusInputAfterAnimation}
          >
            <>
              <div className="flex flex-row justify-center">
                <JumboAvatar
                  src={onboardingData.partner.logoAttachment.url}
                  altText={`${onboardingData.partner.name} logo`}
                />
              </div>
              <Section ref={sectionRefs[OnboardingStep.CREATE_ACCOUNT]}>
                <StepLabel>{selectedStepOneText.stepLabel}</StepLabel>
                <SectionHeader>
                  {selectedStepOneText.sectionHeader}
                </SectionHeader>
                {!stepOneCompleted && (
                  <div
                    className={classNames([
                      "mt-2",
                      isMobilePhone ? "t-small" : "t-regular",
                    ])}
                  >
                    {selectedStepOneText.subText}
                  </div>
                )}
                {!state.submitted.has(
                  onboardingState.OnboardingStep.CREATE_ACCOUNT
                ) && (
                  <CreateAccountForm
                    setDangerousEmail={setDangerousEmail}
                    setLoading={setLoading}
                    setServerError={setServerError}
                    isLoading={
                      state.loading ===
                      onboardingState.OnboardingStep.CREATE_ACCOUNT
                    }
                    token={token}
                    onboardingData={onboardingData}
                    onSubmit={({ hasExistingMembership }) => {
                      setCreateAccountSubmitted();
                      setHasExistingMembership(hasExistingMembership);
                    }}
                  />
                )}
              </Section>

              <Section ref={sectionRefs[OnboardingStep.TELL_US_ABOUT_YOURSELF]}>
                <StepLabel>
                  {stepTwoCompleted ? t("step2Complete") : t("step2of3")}
                </StepLabel>
                <SectionHeader>
                  {stepTwoCompleted
                    ? t("profileCreated")
                    : t("createYourProfile")}
                </SectionHeader>
                {!stepTwoCompleted && (
                  <div
                    className={classNames([
                      "mt-2",
                      isMobilePhone ? "t-small" : "t-regular",
                    ])}
                  >
                    {t("thisInformationWillBeVisible")}
                  </div>
                )}
                {!locked[OnboardingStep.TELL_US_ABOUT_YOURSELF] &&
                  !stepTwoCompleted && (
                    <TellUsAboutYourself
                      isLoading={
                        state.loading ===
                        onboardingState.OnboardingStep.TELL_US_ABOUT_YOURSELF
                      }
                      setCache={setCache}
                      setServerError={setServerError}
                      setLoading={setLoading}
                      cache={cache}
                      onboardingFormSchema={
                        onboardingData.partner.onboardingFormJSON
                      }
                      onSubmit={enterWaitingRoom}
                      isSubmitted={state.submitted.has(
                        onboardingState.OnboardingStep.TELL_US_ABOUT_YOURSELF
                      )}
                    />
                  )}
              </Section>

              <Section
                id="verify-container"
                ref={sectionRefs[OnboardingStep.VERIFY]}
              >
                <>
                  <StepLabel>{t("step3of3")}</StepLabel>
                  <SectionHeader>{t("getVerified")}</SectionHeader>
                  <div
                    className={classNames([
                      "mt-2 mb-6",
                      isMobilePhone ? "t-small" : "t-regular",
                    ])}
                  >
                    {t("allWorkersMustProveTheirAuthenticity")}
                  </div>
                  {!locked.verify &&
                    onboardingData.workflowState !==
                      FrankBackendTypes.OnboardingWorkflowState
                        .InOnboarding && <WaitingRoom />}

                  <div className="hidden sm:block" style={{ height: "75vh" }} />
                </>
              </Section>
            </>
          </motion.div>
        </AnimatePresence>
      </div>
      {!isMobilePhone && (
        <Bottom
          partnerName={onboardingData.partner.name}
          partnerLogo={onboardingData.partner.logoAttachment}
          inviter={onboardingData.inviter}
        />
      )}
    </div>
  );
};

export default OnboardingComponent;

import { TimelineItem, TimelineWrapper } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

import { useTranslation } from "react-i18next";

export const CampaignTimeline = ({
  campaign,
}: {
  campaign: FrankBackendTypes.Campaign;
}) => {
  const { t } = useTranslation();

  // i18n CONCATENATION
  const demandRecipientsDescription = (
    <span>
      {t("thePetitionWillBeSentTo")}
      <span className="t-small plus">
        {campaign.petitionRecipients
          .map((recipient) => recipient.fullName)
          .join(", ")}
      </span>
    </span>
  );

  return (
    <div>
      <TimelineWrapper>
        <TimelineItem
          title={t("petitionPublished")}
          description={t("organizersPublishedThePetitionToThe")}
          dateCompleted={campaign.publishedAt}
          status="complete"
        />
        <TimelineItem
          title={t("reachSignatureGoal")}
          description={t("thisPetitionMustReachAtLeastSign", {
            signatureGoal: campaign.signatureGoal,
          })}
          status="in-progress"
        />
        <TimelineItem
          title={t("deliverPetition")}
          description={demandRecipientsDescription}
          status="open"
        />
        <TimelineItem
          title={t("receiveAResponse")}
          description={t("theRecipientOfYourPetitionWillHave")}
          status="open"
        />
        <TimelineItem
          title={t("reachAResolution")}
          description={t("theOrganizersWillHaveTheFinalSayW")}
          status="open"
          last
        />
      </TimelineWrapper>
    </div>
  );
};

import {
  Button,
  FormGroup,
  InlineAlert,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { FormProps, useForm } from "react-hook-form";
import { useModals } from "../../Modals";
import useSignCampaign from "../dataAccess/mutations/useSignCampaign";
import { useTranslation } from "react-i18next";

const SignDemandLetterModal = ({ campaignId }: { campaignId: string }) => {
  const { addToast } = useToaster();
  const { register, handleSubmit } = useForm<FormProps>();
  const { sign, error } = useSignCampaign({ campaignId });
  const { closeModal } = useModals();

  const { t } = useTranslation();

  const submit = React.useCallback(
    async ({ statement }) => {
      await sign({ statement });
      addToast({
        children: t("youveSignedThePetition"),
        intent: Intent.SUCCESS,
      });
      closeModal();
    },
    [sign, addToast, closeModal]
  );

  return (
    <>
      <ModalHeader title="Sign Petition" />
      <ModalBody>
        <form onSubmit={handleSubmit(submit)} id="sign-demand-letter-form">
          {error && (
            <InlineAlert intent={Intent.FAILURE} title={t("serverError")}>
              {t("thereWasAProblemSigningThisPetitio")}
            </InlineAlert>
          )}
          <FormGroup
            label={t("addYourStatementOfSupportOptional")}
            name="statement"
            id="statement"
          >
            <Textarea
              placeholder={t("addStatementHere")}
              maxLength={1500}
              data-cy="statement"
              register={register}
              registerArgs={{ required: false, maxLength: 1500 }}
              autoFocus
            />
          </FormGroup>
          <p className="t-small text-canvas-400">
            {t("thisWillBeDisplayedNextToYourSign")}
          </p>
          <ModalFooter>
            <Button
              buttonStyle="outline"
              className="mr-2"
              onClick={() => closeModal()}
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              buttonStyle="brand"
              form="sign-demand-letter-form"
            >
              {t("signPetition")}
            </Button>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
};

export default SignDemandLetterModal;

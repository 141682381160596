import {
  Button,
  Delay,
  FullCenter,
  IconLinkButton,
  Intent,
  PulseActivity,
  Sizes,
  TopBar,
  useToaster,
} from "@get-frank-eng/design-system";
import { SmallAttachmentCard } from "@get-frank-eng/design-system/dist/cards/Attachment";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import uploadAttachments, { openFileChooser } from "../utils/uploadAttachment";
import Pager, { PaginationStrategy } from "../components/Pager";
import AttachmentsEmptyState from "./components/AttachmentsEmptyState";
import useAttachments from "./dataAccess/queries/useAttachments";
import useCreateAttachmentForChannel from "./dataAccess/queries/useCreateAttachmentForChannel";
import { useTranslation } from "react-i18next";
import { useDeleteAttachmentMutation } from "../generated-hooks";
import { useModals } from "../Modals";
import { useCurrentUserData } from "../Auth/useCurrentUserData";

const ChannelAttachments = ({
  campaignId,
  topicId,
  topicSlug,
}: {
  campaignId?: string;
  topicId?: string;
  topicSlug?: string;
}) => {
  const { t } = useTranslation();
  const { openConfirmationModal } = useModals();
  const { attachments, loading, loadMore, refetch } = useAttachments({
    campaignId,
    topicId,
  });
  const { createAttachmentForChannel } = useCreateAttachmentForChannel({
    campaignId,
    topicId,
  });
  const { preferredLanguage } = useCurrentUserData();

  const [
    deleteAttachmentMutation,
    { data, error, loading: loadingDeleteAttachment },
  ] = useDeleteAttachmentMutation();

  const [uploadLoading, setUploadLoading] = React.useState(false);
  const toaster = useToaster();

  const deleteAttachment = async (attachmentId) => {
    try {
      const confirm = await openConfirmationModal({
        actionText: t("confirm"),
        bodyText: t("deletingFileWillNotDeleteMessage"),
        title: t("areYouSureYouWantToDeleteFile"),
      });

      if (!confirm) {
        return;
      }
      await deleteAttachmentMutation({ variables: { attachmentId } });
      await refetch();
      toaster.addToast({
        children: t("success"),
        intent: Intent.SUCCESS,
      });
    } catch (e) {
      toaster.addToast({
        children: t("somethingWentWrong"),
        intent: Intent.FAILURE,
      });
    }
  };

  if (loading) {
    return (
      <Delay delay={500}>
        <div className="p-6 space-y-2 bg-canvas-700">
          <PulseActivity />
          <PulseActivity />
          <PulseActivity />
          <PulseActivity />
          <PulseActivity />
          <PulseActivity />
        </div>
      </Delay>
    );
  }

  const uploadFile = async (e: any) => {
    setUploadLoading(true);
    const file = e.target.files[0];
    const [attachment] = await uploadAttachments([file]);
    await createAttachmentForChannel(attachment.attachmentId);
    refetch();
    toaster.addToast({
      intent: Intent.SUCCESS,
      children: t("fileUploaded"),
    });
    setUploadLoading(false);
  };

  return (
    <div className="bg-canvas-700 h-screen">
      <TopBar
        title={t("files")}
        left={
          <IconLinkButton
            to={campaignId ? `../${campaignId}` : `../${topicSlug}`}
            icon="arrow_back"
            size={Sizes.XL}
            buttonStyle="minimal"
          />
        }
        right={
          <Button
            size={Sizes.SM}
            loading={uploadLoading}
            buttonStyle="outline"
            onClick={(e) => openFileChooser(uploadFile)}
          >
            {t("upload")}
          </Button>
        }
      />
      {!attachments.objects.length ? (
        <div className="pt-12">
          <FullCenter>
            <AttachmentsEmptyState
              openFileChooser={(e) => openFileChooser(uploadFile)}
            />
          </FullCenter>
        </div>
      ) : (
        <div className="space-y-2 p-6 bg-canvas-700 flex-grow">
          <Pager<FrankBackendTypes.Attachment>
            pagination={attachments}
            paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
            onShowMore={loadMore}
          >
            <div className="space-y-2 bg-canvas-700 flex-grow">
              {attachments.objects.map((attachment) => (
                <SmallAttachmentCard
                  key={attachment.id}
                  attachment={attachment}
                  deleteAttachment={deleteAttachment}
                  lng={preferredLanguage}
                />
              ))}
            </div>
          </Pager>
        </div>
      )}
    </div>
  );
};

export default ChannelAttachments;

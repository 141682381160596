import * as React from "react";
import useArchiveCampaign from "../CampaignBuilder/dataAccess/mutations/useArchiveCampaign";
import { useModals } from "../Modals";
import { MenuItem } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

interface Props {
  campaignId: string;
  workflowState: FrankBackendTypes.CampaignWorkflowState;
  relationshipToMe: FrankBackendTypes.CampaignUserRelationship;
  refetch?: () => any;
}

const CampaignMenu = ({
  campaignId,
  workflowState,
  relationshipToMe,
  refetch,
}: Props) => {
  const { t } = useTranslation();

  const { openConfirmationModal } = useModals();
  const { archiveCampaign } = useArchiveCampaign({ campaignId, refetch });

  const handleArchive = React.useCallback(
    async (e) => {
      e.preventDefault();
      const confirmed = await openConfirmationModal({
        title: t("areYouSureYouWantToArchiveThis"),
        bodyText: t("atTheMomentThereIsNoWayFor") as string,
        actionText: t("confirmArchive"),
      });
      if (confirmed) {
        archiveCampaign();
      }
    },
    [openConfirmationModal, archiveCampaign, t]
  );

  if (workflowState === FrankBackendTypes.CampaignWorkflowState.Draft) {
    return (
      <>
        <MenuItem onClick={handleArchive}>{t("archive")}</MenuItem>
        <MenuItem to={`/campaigns/${campaignId}/inviteCollaborators`}>
          {t("inviteOrganizers")}
        </MenuItem>
      </>
    );
  }

  if (
    relationshipToMe === FrankBackendTypes.CampaignUserRelationship.Organizer
  ) {
    return (
      <>
        {workflowState ===
          FrankBackendTypes.CampaignWorkflowState.Published && (
          <MenuItem onClick={(e) => handleArchive(e)}>{t("archive")}</MenuItem>
        )}
        <MenuItem to={`/campaigns/${campaignId}/inviteCollaborators`}>
          {t("inviteOrganizers")}
        </MenuItem>
        <MenuItem to={`/campaigns/${campaignId}/builder`}>{t("edit")}</MenuItem>
      </>
    );
  }

  if (relationshipToMe === FrankBackendTypes.CampaignUserRelationship.None) {
    return (
      <>
        {/* <Menu onClose={onClose}> */}
        <MenuItem to={`/campaigns/${campaignId}/sign`}>{t("sign")}</MenuItem>
        {/* </Menu> */}
      </>
    );
  }

  if (
    relationshipToMe ===
      FrankBackendTypes.CampaignUserRelationship.InvitedNotSupporter ||
    relationshipToMe ===
      FrankBackendTypes.CampaignUserRelationship.InvitedSupporter
  ) {
    return (
      <>
        <MenuItem to={`/campaigns/${campaignId}/collaboratorInvite`}>
          {t("viewInvitation")}
        </MenuItem>
      </>
    );
  }

  return null;
};

export default CampaignMenu;

import {
  Button,
  FormGroup,
  Input,
  Intent,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useSendAuthCardMutation } from "../../generated-hooks";
import { onPasteEmails } from "../../utils/onPasteEmails";

const SendAuthCardViaEmail = ({
  closeModal,
  groupId,
}: {
  closeModal: () => void;
  groupId: string;
}) => {
  const { t } = useTranslation();
  const [invokeSendAuthCard, { error }] = useSendAuthCardMutation();

  const toaster = useToaster();

  const [inputtedEmails, setInputtedEmails] = React.useState<{
    emails?: string;
  }>({
    emails: "",
  });

  const { register, handleSubmit, errors, watch } = useForm<{
    emails: string;
    message: string;
  }>();

  const submit = React.useCallback(
    async (data) => {
      try {
        invokeSendAuthCard({
          variables: {
            input: {
              emails: data.emails
                .split(",")
                .map((email: string) => email.trim()),
              groupId,
            },
          },
        });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("success"),
        });
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrong"),
        });
      }

      if (error) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrong"),
        });
      }

      closeModal();
    },
    [error, closeModal, invokeSendAuthCard, groupId, toaster, t]
  );

  return (
    <form
      id="email-invite-form"
      onSubmit={handleSubmit(submit)}
      noValidate
      className="space-y-4"
    >
      <div className="t-small text-canvas-400">
        {t("typeOrPasteInAListOfEmails")}
      </div>
      <FormGroup
        label={t("emailAddressesCommaSeparated")}
        name="emails"
        id="emails"
      >
        <Input
          errorText={
            errors.emails && (
              <>
                {errors.emails.type === "required" &&
                  t("emailIsRequiredAndMustBeValid")}
                {errors.emails.type === "pattern" &&
                  t("oneOrMoreOfTheEmailAddressesYouE")}
              </>
            )
          }
          onChange={() => {
            setInputtedEmails(watch(["emails"]));
          }}
          register={register}
          onPaste={onPasteEmails}
          type="email"
          registerArgs={{
            required: true,
            pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/gi,
          }}
        />
      </FormGroup>
      <div className="my-3 py-4 flex flex-row">
        <Button
          buttonStyle="brand"
          form="email-invite-form"
          type="submit"
          loading={false}
          disabled={!inputtedEmails.emails?.length}
        >
          {t("sendCard")}
        </Button>
      </div>
    </form>
  );
};

export default SendAuthCardViaEmail;

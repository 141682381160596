function triggerChangeOfInput(el: HTMLInputElement, value: string) {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  ).set;
  nativeInputValueSetter.call(el, value);

  const ev2 = new Event("input", { bubbles: true });
  el.dispatchEvent(ev2);
}

export const onPasteEmails = (
  e: React.SyntheticEvent<HTMLInputElement, ClipboardEvent>
) => {
  e.preventDefault();
  // eslint-disable-next-line dot-notation
  const clipboard = e["clipboardData"].getData("text");
  const newEmails = clipboard.split("\n");
  const existingEmails = e.currentTarget.value.split(",");
  const arr = [...existingEmails, ...newEmails]
    .map((str) => str.trim().toLowerCase())
    .filter((str) => !!str);

  // e.currentTarget.value = arr.join(", ");
  triggerChangeOfInput(e.currentTarget, arr.join(", "));
};

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendingMemberCard = void 0;
const frank_types_1 = require("frank-types");
const React = __importStar(require("react"));
const __1 = require("../..");
const ReviewStatus_1 = require("./ReviewStatus");
const PendingMemberCard = ({ onClick, pendingMember, children, dataCy, lng, isExpanded }) => {
    var _a, _b, _c, _d;
    return (React.createElement(__1.Card, { className: "border-canvas-500" },
        React.createElement("div", { className: "flex flex-row items-center" },
            React.createElement("button", { "data-cy": dataCy, type: "button", className: "p-4 flex flex-row items-center flex-grow focus:outline-none text-left", onClick: onClick },
                React.createElement("div", { className: "flex-row flex items-center flex-grow" },
                    React.createElement("div", { className: "flex-shrink-0" },
                        React.createElement(__1.Avatar, { altText: ((_a = pendingMember.user) === null || _a === void 0 ? void 0 : _a.name) || undefined, src: (_c = (_b = pendingMember.user) === null || _b === void 0 ? void 0 : _b.profilePic) === null || _c === void 0 ? void 0 : _c.url, size: __1.Sizes.LG, link: `/users/${(_d = pendingMember.user) === null || _d === void 0 ? void 0 : _d.id}` })),
                    React.createElement("div", { className: "ml-3 leading-3", style: { lineHeight: "0.75rem" } },
                        React.createElement("div", { className: "t-small plus" }, pendingMember.name),
                        React.createElement("div", null,
                            React.createElement(__1.CoworkerStatus, { size: __1.Sizes.SM, status: pendingMember.status ||
                                    frank_types_1.FrankBackendTypes.CoworkerStatus.Lead, lng: lng }),
                            React.createElement("span", { className: "mx-1 t-mini text-canvas-400" }, "•"),
                            React.createElement(ReviewStatus_1.ReviewStatus, { pendingMember: pendingMember, lng: lng })))),
                React.createElement("div", null, isExpanded !== undefined && (React.createElement(__1.IconButton, { icon: isExpanded ? "expand_less" : "expand_more", size: __1.Sizes.XL, buttonStyle: "minimal" }))))),
        children));
};
exports.PendingMemberCard = PendingMemberCard;

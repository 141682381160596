import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useTranslation } from "react-i18next";

const PetitionRecipientHeader = ({
  petitionRecipients,
}: {
  petitionRecipients: FrankBackendTypes.PetitionRecipient[];
}) => {
  const { t } = useTranslation();

  const recipients = petitionRecipients
    .map((recipient) => recipient.fullName)
    .join(", ");

  return (
    <span>
      <span className="t-small">{t("to")}</span>
      <span className="t-regular plus">{recipients}</span>
    </span>
  );
};

export default PetitionRecipientHeader;

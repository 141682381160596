import {
  Layout,
  LeftSideBar,
  Sidebar,
  SidebarDivider,
  SidebarFooter,
  SidebarItem,
  SidebarSection,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useNewGroupLayout } from "./state";
import { useTranslation } from "react-i18next";
import { findLanguage } from "../utils/findLanguage";

export const CreateNewGroupLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { state } = useNewGroupLayout();
  return (
    <Layout>
      <LeftSideBar>
        <Sidebar
          groupLogoUrl={state.groupLogoUrl}
          switchGroups={() => {}}
          otherGroups={[]}
          groupName={state.groupName || t("newGroup")}
          footer={
            <SidebarFooter
              lng={findLanguage(navigator.language)}
              name={state.user.name}
              avatarUrl={state.user.profilePictureThumbnailUrl}
            />
          }
        >
          <SidebarSection disabled isOpen>
            <SidebarItem to="" icon="Home">
              {t("home")}
            </SidebarItem>
            <SidebarItem to="" icon="Briefcase">
              {t("coworkers")}
            </SidebarItem>
          </SidebarSection>
          <SidebarDivider />
          <SidebarSection disabled isOpen header={t("topics")}>
            {[
              <SidebarItem icon="Hash" to="" key="anonymous">
                {t("anonymous")}
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="general">
                {t("general")}
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="pay">
                {t("pay")}
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="benefits">
                {t("benefits")}
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="work-conditions">
                {t("work-conditions")}
              </SidebarItem>,
              <SidebarItem icon="Hash" to="" key="membership">
                {t("membership")}
              </SidebarItem>,
            ]
              .concat(
                ...state.topics.map((topic, i) => (
                  <SidebarItem icon="Hash" to="" key={topic.name + "-" + i}>
                    {topic.name}
                  </SidebarItem>
                ))
              )
              .concat(
                <SidebarItem to="" key="new-topic" icon="Plus">
                  {t("newTopic")}
                </SidebarItem>
              )}
          </SidebarSection>
          <SidebarDivider />
          <SidebarSection disabled isOpen header="Petitions">
            <SidebarItem to="" icon="File" key="drafts">
              {t("drafts")}
            </SidebarItem>
            <SidebarItem to="" key="new-campaign" icon="Plus">
              {t("newPetition")}
            </SidebarItem>
          </SidebarSection>
        </Sidebar>
      </LeftSideBar>
      {children}
    </Layout>
  );
};

import * as React from "react";
import SecurityFeatureRow from "../components/SecurityFeatureRow";
import {
  FormGroup,
  Icon,
  Input,
  Intent,
  Sizes,
  useToaster,
} from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";

export default ({
  currentPassphrase,
  update,
  token,
  loading,
  idModifier = "",
}) => {
  const { t } = useTranslation();

  const passphraseToShow = currentPassphrase || "";
  const [passphrase, setPassphrase] = React.useState(passphraseToShow);
  const toaster = useToaster();

  const updatePassphrase = async (passphrase) => {
    try {
      await update({ passphrase }, token);
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("updateSuccessful"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
    }
  };

  return (
    <SecurityFeatureRow
      id={`require-passphrase${idModifier}`}
      defaultChecked={!!passphrase}
      title={t("requirePasscode")}
      onToggle={(isChecked, dirty) => {
        if (!isChecked && dirty) {
          updatePassphrase(null);
          return;
        }
        if (!isChecked && currentPassphrase) {
          updatePassphrase(null);
        }
      }}
      contentToShowWhenChecked={(setDirty) => (
        <div className="flex flex-row w-64">
          <FormGroup label={t("passPhrase")} name="passphrase" id="passphrase">
            <Input
              value={passphrase}
              autoFocus
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  updatePassphrase(passphrase);
                }
              }}
              onBlur={(e) => {
                if (currentPassphrase !== e.currentTarget.value) {
                  updatePassphrase(passphrase);
                }
              }}
              onChange={(e: any) => {
                setDirty(true);
                setPassphrase(e.currentTarget.value);
              }}
            />
          </FormGroup>
          <button
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              updatePassphrase(passphrase);
            }}
            type="button"
            className="self-center mt-4 text-canvas-400 hover:text-canvas-600"
          >
            <Icon icon="chevron_right" size={Sizes.XL} />
          </button>
        </div>
      )}
    >
      {t("addASecretPasscodeToThisInviteLin")}
    </SecurityFeatureRow>
  );
};

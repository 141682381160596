import {
  FormGroup,
  IconButton,
  Input,
  Sizes,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Range } from "slate";
import { useEditor } from "slate-react";
import FloatAroundSelection from "./FloatAroundSelection";
import { insertLink, isLinkActive, unwrapLink } from "./plugins/link";
import { useTranslation } from "react-i18next";

const LinkForm = ({
  setFollowUp,
  lastDomSelection,
  linkRange,
}: {
  linkRange: Range;
  setFollowUp: any;
  lastDomSelection: Selection;
}) => {
  const { t } = useTranslation();
  const { handleSubmit, register, errors } = useForm<{ link: string }>();

  const editor = useEditor();
  const submit = React.useCallback(
    ({ link }: { link: string }) => {
      const isActive = isLinkActive(editor);

      if (isActive) {
        return unwrapLink(editor);
      }
      insertLink(editor, link, linkRange);
      setFollowUp(null);
    },
    [editor, linkRange, setFollowUp]
  );

  return (
    <FloatAroundSelection
      onClickOutside={() => {
        setFollowUp(null);
      }}
      selection={lastDomSelection}
    >
      <form
        noValidate
        className="flex flex-row items-center"
        onSubmit={handleSubmit(submit)}
      >
        <FormGroup name="link" id="link" label={t("URL")}>
          <Input
            errorText={
              errors.link && (
                <>
                  {errors.link.type === "required" && t("urlRequired")}
                  {errors.link.type === "pattern" && t("invalidURL")}
                </>
              )
            }
            register={register}
            registerArgs={{
              required: true,
              pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
            }}
            type="url"
          />
        </FormGroup>
        <IconButton
          className="ml-2"
          type="submit"
          icon="check"
          size={Sizes.LG}
        />
      </form>
    </FloatAroundSelection>
  );
};
export default LinkForm;

import * as React from "react";
import { DataField } from "frank-types/dist/frank-backend-types";
import { DataFieldResponseDataInput } from "./types/common";
import { DataFieldResponseBooleanData } from "./types/data-field-response-data";
import { DataFieldConfigDefault } from "./types/data-field-config";
import DataFieldCheckbox from "./common/DataFieldCheckbox";

export default function DataFieldBooleanInput({
  field,
  onUpdate,
  data,
  disabled,
}: {
  field: DataField;
  config: DataFieldConfigDefault;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  data?: DataFieldResponseBooleanData;
  disabled: boolean;
}): JSX.Element {
  const onCheck = React.useCallback(
    (_: DataField, val: boolean) => {
      onUpdate({
        dataFieldId: field.id,
        data: { value: val },
      });
    },
    [onUpdate]
  );
  const onUncheck = React.useCallback(
    (_: DataField, val: boolean) => {
      onUpdate({
        dataFieldId: field.id,
        data: undefined,
      });
    },
    [onUpdate]
  );
  return (
    <>
      <span className="p-2">
        <DataFieldCheckbox
          field={field}
          value={true}
          disabled={disabled}
          label="Yes"
          checked={data?.value === true}
          onCheck={onCheck}
          onUncheck={onUncheck}
        />
      </span>
      <span className="p-2 ml-6">
        <DataFieldCheckbox
          field={field}
          value={false}
          disabled={disabled}
          label="No"
          checked={data?.value === false}
          onCheck={onCheck}
          onUncheck={onUncheck}
        />
      </span>
    </>
  );
}

import { UserCard } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

const dateFormatter = (lng = "en") =>
  new Intl.DateTimeFormat(lng, {
    month: "long",
    year: "numeric",
    day: "numeric",
  });

const timeFormatter = (lng = "en") =>
  new Intl.DateTimeFormat(lng, {
    hour: "numeric",
    minute: "numeric",
  });

const CampaignInfo = ({
  campaign,
  lng = "en",
}: {
  campaign: FrankBackendTypes.Campaign;
  lng?: string;
}) => {
  const { t } = useTranslation();
  const { preferredLanguage } = useCurrentUserData();

  return (
    <div>
      <section className="border-b pb-6 space-y-3">
        <div className="t-small plus text-canvas-400">{t("organizers")}</div>
        {campaign.campaignOrganizers.map((organizer) => (
          <UserCard
            name={organizer.name}
            userId={organizer.id}
            profilePicUrl={organizer.profilePic.url}
            status={FrankBackendTypes.CoworkerStatus.Member}
            lng={preferredLanguage}
          />
        ))}
      </section>
      <section className="border-b py-6 space-y-1">
        <div className="t-small plus text-canvas-400">{t("published")}</div>
        <p className="t-small text-white">
          {dateFormatter(lng).format(new Date(campaign.publishedAt))} •{" "}
          {timeFormatter(lng).format(new Date(campaign.publishedAt))}
        </p>
      </section>
      <section className="border-b py-6 space-y-1">
        <div className="t-small plus text-canvas-400">{t("signatureGoal")}</div>
        <p className="t-small text-white">
          {t("signaturesCount", { signatureCount: campaign.signatureGoal })}
        </p>
      </section>
      <section className="border-b py-6 space-y-1">
        <div className="t-small plus text-canvas-400">{t("recipients")}</div>
        <div className="t-small pb-4">
          {t("thePetitionWillBeDeliveredToTheFo")}
        </div>
        <p className="text-white t-small plus">
          {campaign.petitionRecipients.map((recipient) => (
            <p key={recipient.email}>{recipient.email}</p>
          ))}
        </p>
      </section>
    </div>
  );
};

export default CampaignInfo;

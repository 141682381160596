import * as React from "react";
import { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import useQuery from "../hooks/useQuery";
import PartnerQuestions from "./pages/PartnerQuestions";
import Email from "./pages/Email";
import EmailConfirmation from "./pages/EmailConfirmation";
import Password from "./pages/Password";
import Profile from "./pages/Profile";
import { CreateNewGroupStateProvider } from "./state";

const CreateNewGroup = () => {
  const { url } = useRouteMatch();

  const [stateFromQueryParams, setStateFromQueryParams] = React.useState<{
    onboardingId: string;
    token: string;
  } | null>(null);
  const params = useQuery();

  useEffect(() => {
    if (stateFromQueryParams) {
      return;
    }
    if (params.get("onboardingId") && params.get("token")) {
      setStateFromQueryParams({
        onboardingId: params.get("onboardingId"),
        token: params.get("token"),
      });
    }
  }, [params, setStateFromQueryParams, stateFromQueryParams]);

  return (
    <CreateNewGroupStateProvider>
      <Switch>
        <Route path={`${url}/email-confirmation`}>
          <EmailConfirmation />
        </Route>
        <Route path={`${url}/check-email`}>
          <EmailConfirmation resent />
        </Route>
        <Route path={`${url}/password`}>
          {stateFromQueryParams ? <Password {...stateFromQueryParams} /> : null}
        </Route>

        {/* <Route path={`${url}/group`}>
          <Group />
        </Route> */}

        <Route path={`${url}/profile`}>
          <Profile />
        </Route>
        <Route path={`${url}/partner-questions`}>
          <PartnerQuestions />
        </Route>

        <Route path={`${url}/:partnerSlug`}>
          <Email />
        </Route>

        {/* <Route path={`${url}/topics`}>
          <Topics />
        </Route>
        <Route path={`${url}/coworkers`}>
          <Coworkers />
        </Route> */}
      </Switch>
    </CreateNewGroupStateProvider>
  );
};

export default CreateNewGroup;

import * as React from "react";
import {
  Button,
  FormGroup,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import { useForm } from "react-hook-form";
import useCreatePartner from "../dataAccess/hooks/mutations/useCreatePartner";
import AddPhoto from "../../Onboarding/components/AddPhoto";
import { useModals } from "../../Modals";
import { useTranslation } from "react-i18next";

type FormParams = {
  name: string;
  defaultWelcomeMessage: string;
};

// must alter Modals.tsx if you need to add props e.g. <Modal {...modal.props} />

const CreatePartnerModal = () => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { createPartner, loading } = useCreatePartner();
  const { register, handleSubmit, watch } = useForm<FormParams>();
  const [attachmentId, setAttachmentId] = React.useState(null);
  const { closeModal } = useModals();

  const name = watch("name");

  const [defaultMessage, setDefaultMessage] = React.useState("");

  React.useEffect(() => {
    setDefaultMessage(
      t("welcomeToPartnerNamePoweredByF", { partnerName: name })
    );
  }, [setDefaultMessage, name, t]);

  const submitCreatePartner = async (formData: FormParams) => {
    try {
      await createPartner({
        name: formData.name,
        defaultWelcomeMessage: formData.defaultWelcomeMessage,
        attachmentId,
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("successfullyCreatedPartner"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrongCreatingThisPartn"),
      });
    }
    closeModal();
  };

  const form = (
    <form
      onSubmit={handleSubmit(submitCreatePartner)}
      id="create-partner-form"
      noValidate
      className="space-y-8"
    >
      <section>
        <FormGroup name="name" label={t("partnerName")} id="name">
          <Input
            register={register}
            id="name"
            maxLength={120}
            placeholder={t("partnerName")}
            helpText="E.g. SEIU"
          />
        </FormGroup>
      </section>
      <section>
        <FormGroup
          name="defaultWelcomeMessage"
          label={t("defaultWelcomeMessage")}
          id="defaultWelcomeMessage"
        >
          <Textarea
            register={register}
            id="defaultWelcomeMessage"
            maxLength={2000}
            value={defaultMessage}
            onChange={(e) => {
              setDefaultMessage(e.currentTarget.value);
            }}
          />
        </FormGroup>
      </section>
      <section>
        <div className="t-mini plus -mb-2">{t("partnerLogo")}</div>
        <AddPhoto
          defaultUrl={null}
          setGroupAttachmentId={async ({ attachmentId }) =>
            setAttachmentId(attachmentId)
          }
          onUpload={(err, url) => {
            if (err) {
              toaster.addToast({
                intent: Intent.FAILURE,
                children: t("somethingWentWrong"),
              });
            }
          }}
        />
      </section>
    </form>
  );

  return (
    <>
      <ModalHeader title={t("createAPartner")} />
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        <Button
          loading={loading}
          form="create-partner-form"
          buttonStyle="brand"
          disabled={!attachmentId || !name}
        >
          {t("createPartner")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default CreatePartnerModal;

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  Reference,
  FieldPolicy,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import AuthN from "./Auth/AuthN";
import generateAuthHeader from "./Auth/generateAuthHeader";
import apolloLogger from "apollo-link-logger";
import { GraphQLError } from "graphql";

type KeyArgs = FieldPolicy<any>["keyArgs"];

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext(async (_, { headers, groupId }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  await AuthN.waitForValidSession();
  const authHeader = generateAuthHeader(groupId);
  return {
    headers: {
      ...headers,
      authorization: authHeader || "",
    },
  };
});

const hasUnauthorizedGraphQLError = (errors: readonly GraphQLError[]) => {
  return errors.filter((error) => {
    if (error?.extensions?.exception?.response?.statusCode === 401) {
      return true;
    }
    return false;
  });
};

const hasNoOnboardingForGroup = (errors: readonly GraphQLError[]) => {
  return errors.filter((error) => {
    if (error?.message === "No onboarding for this group") {
      return true;
    }
    return false;
  });
};

const logoutLink = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  if (!graphQLErrors) {
    // returning because we only do stuff with graphQL errors
    return;
  }
  const unauthorizedGraphQLError = hasUnauthorizedGraphQLError(graphQLErrors);
  if (unauthorizedGraphQLError.length) {
    AuthN.hasValidSession().then((validSession) => {
      if (!validSession) {
        AuthN.logout();
      }
      if (hasNoOnboardingForGroup(unauthorizedGraphQLError).length) {
        AuthN.logout();
      }
    });
  }
});

const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeQuery: true,
    includeError: true,
  },
});

const linkArr = [authLink, logoutLink, sentryLink, httpLink];

if (process.env.REACT_APP_ENABLE_APOLLO_LOGGER) {
  linkArr.unshift(apolloLogger);
}

const client = new ApolloClient({
  link: ApolloLink.from(linkArr),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          taskAssignments: offsetLimitPagination([
            "groupId",
            "coworkerId",
            "workflowState",
          ]),
          attachments: offsetLimitPagination(["campaignId", "topicId"]),
          coworkers: offsetLimitPagination([
            "order",
            "filter",
            "secondOrder",
            "explicitGroupId",
          ]),
          onboardingFormResponses: offsetLimitPagination(),
          tasks: offsetLimitPagination(),
          partners: offsetLimitPagination(),
          organizers: offsetLimitPagination(["partnerId"]),
          polls: offsetLimitPagination(["campaignId", "topicId"]),
        },
      },
      AuthorizationCard: {
        fields: {
          signatures: offsetLimitPagination(),
          unsignedMembers: offsetLimitPagination(),
        },
      },
      MyProfile: {
        fields: {
          notifications: offsetLimitPagination(),
          homeFeed: offsetLimitPagination(),
        },
      },
      Campaign: {
        fields: {
          activity: offsetLimitPagination(),
          signatures: offsetLimitPagination(),
        },
      },
      Coworker: {
        fields: {
          activity: offsetLimitPagination(),
        },
      },
      User: {
        fields: {
          activity: offsetLimitPagination(),
          signedCampaigns: offsetLimitPagination(),
          // I'm not sure why but I'm pretty sure this will work: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
          currentOnboardings: {
            merge(
              existing: any[],
              incoming: any[],
              { readField, mergeObjects }
            ) {
              return incoming;
            },
          },
        },
      },
      Group: {
        fields: {
          assessmentActivity: offsetLimitPagination(["groupId"]),
        },
      },
    },
  }),
});

export default client;

type PaginatedResource<T> = { objects: T[]; hasNext: boolean; total?: number };

export function offsetLimitPagination<T = Reference>(
  keyArgs: KeyArgs = false
): FieldPolicy<PaginatedResource<T>> {
  return {
    keyArgs,
    merge(
      existing: PaginatedResource<T>,
      incoming: PaginatedResource<T>,
      { args }
    ) {
      const objects = existing ? existing.objects.slice(0) : [];
      const incomingObjects = incoming.objects;

      if (!incoming.objects) {
        return {
          ...incoming,
          objects,
        };
      }

      const pagination: { perPage: number; page: number } = args.pagination;

      if (pagination) {
        const beforeCurrentPage = pagination.page * pagination.perPage;

        for (let i = 0; i < incomingObjects.length; ++i) {
          objects[beforeCurrentPage + i] = incomingObjects[i];
        }
      } else {
        throw new Error("Must have pagination argument");
      }
      return {
        ...incoming,
        objects,
      };
    },
  };
}

import {
  Avatar,
  Button,
  Intent,
  LinkButton,
  Sizes,
  useToaster,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { CampaignWorkflowState } from "frank-types/dist/frank-backend-types";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { useCollaboratorInviteActions } from "../CampaignDetail/dataAccess/mutations/useCollaboratorActions";
import useCollaboratorInviteQuery from "../CampaignDetail/dataAccess/queries/useCollaboratorInviteQuery";
import DetailLayout from "../components/layouts/Detail";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";

const CampaignCollaboratorInvite = () => {
  const { t } = useTranslation();

  const { campaignId } = useParams<{ campaignId: string }>();

  const {
    collaboratorInvite,
    loadingCollaboratorInvite,
    errorCollaboratorInvite,
  } = useCollaboratorInviteQuery({
    campaignId,
  });

  const { push } = useHistory();
  const toaster = useToaster();

  const {
    accept,
    reject,
    loadingAccept,
    loadingReject,
  } = useCollaboratorInviteActions({
    campaignId,
  });

  const acceptInvite = async () => {
    try {
      await accept();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("youAreAnOrganizerOnThisCampaign"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrongAcceptingThisInvi"),
      });
      throw e;
    }
    push(
      collaboratorInvite?.campaign.workflowState === CampaignWorkflowState.Draft
        ? `/campaigns/${campaignId}/builder`
        : `/campaigns/${campaignId}`
    );
  };

  const rejectInvite = async () => {
    try {
      await reject();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("youHaveDeclinedThisInvitation"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrongDecliningThisInvi"),
      });
      throw e;
    }
    push(`/home`);
  };

  if (errorCollaboratorInvite) {
    throw new Error(errorCollaboratorInvite.message);
  }

  if (loadingCollaboratorInvite) {
    return <Loading />;
  }

  if (!collaboratorInvite) {
    return <div>{t("youAreNotInvitedToThisCampaign")}</div>;
  }

  if (
    collaboratorInvite?.campaign.workflowState !==
    FrankBackendTypes.CampaignWorkflowState.Draft
  ) {
    push(`/campaigns/${campaignId}`);
  }

  return (
    <>
      <DetailLayout
        title={t("inviteToCollaborateOn", {
          campaignTitle: collaboratorInvite?.campaign?.title,
        })}
      >
        <div className="max-w-lg mx-auto mt-3 sm:mt-16">
          <Avatar
            size={Sizes.LG}
            src={collaboratorInvite?.inviter?.profilePic.url}
            altText={collaboratorInvite?.inviter?.shortName}
            link={`/users/${collaboratorInvite?.inviter.id}`}
          />
          <h1 className="t-title-3 plus-plus mt-6">
            {t("inviterNameInvitedYouToBeAnOrg", {
              inviterName: collaboratorInvite?.inviter?.shortName,
            })}
          </h1>

          <p className="my-3 t-regular">
            {/** SPECIAL CASE i18n revisit */}
            {collaboratorInvite?.inviter?.shortName} invited you to help
            organize the campaign,{" "}
            <span className="t-regular plus-plus">
              {collaboratorInvite?.campaign?.title}
            </span>
          </p>
          {collaboratorInvite?.campaign.collaboratorWorkflowState ===
          FrankBackendTypes.CollaboratorWorkflowState.Rejected ? (
            <>
              <p className="my-3 t-regular">
                {t("youHaveDeclinedThisInvitationIfYou")}
              </p>
              <div className="my-4 flex flex-row items-center">
                <div>
                  <LinkButton to="/campaigns" className="mr-3">
                    {t("goBackToPetitions")}
                  </LinkButton>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="my-3 t-regular">{t("asACampaignOrganizer")}</p>
              <div className="my-4 flex flex-row items-center">
                <div className="flex flex-row">
                  <Button
                    onClick={acceptInvite}
                    loading={loadingAccept}
                    className="mr-3"
                  >
                    {t("acceptInvitation")}
                  </Button>
                  <Button
                    onClick={rejectInvite}
                    loading={loadingReject}
                    buttonStyle="minimal"
                  >
                    {t("declineInvitation")}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </DetailLayout>
    </>
  );
};

export default CampaignCollaboratorInvite;

import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const CREATE_PARTNER = gql`
  mutation CreatePartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      nothing
    }
  }
`;

export default function useCreatePartner() {
  const [invokeCreatePartner, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationCreatePartnerArgs
  >(CREATE_PARTNER);

  const createPartner = React.useCallback(
    async ({
      name,
      attachmentId,
      defaultWelcomeMessage,
    }: {
      name: string;
      attachmentId: string;
      defaultWelcomeMessage;
    }) => {
      await invokeCreatePartner({
        variables: {
          input: {
            name,
            defaultWelcomeMessage,
            logoAttachmentId: attachmentId,
          },
        },
      });
    },
    [invokeCreatePartner]
  );

  return {
    createPartner,
    loading,
    called,
    error,
  };
}

import * as React from "react";
import classNames from "classnames";
import Loading from "../../components/Loading";
import ReadOnlyDocument from "../../DocumentEditor/ReadOnlyDocument";
import { colorTypes } from "@get-frank-eng/design-system/dist/colors";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

export const DocumentHeader = ({ children }) => (
  <div className="pb-6 border-b mb-6">{children}</div>
);

export const DocumentFooter = ({ children }) => (
  <div className="pt-6 mt-6 border-t">{children}</div>
);

export const Preview: React.FunctionComponent<{
  showFullDocument: boolean;
  documentHeaderContent?: any;
  onClick?: () => void;
  bgColor?: colorTypes;
}> = ({
  children,
  bgColor = "canvas-700",
  showFullDocument,
  documentHeaderContent,
  onClick,
}) => {
  const { t } = useTranslation();
  const [showFullLetter, setShowFullLetter] = React.useState(showFullDocument);
  return (
    <button
      className={classNames(
        "relative flex w-full text-left overflow-y-hidden",
        {
          "pb-8": showFullDocument,
        }
      )}
      style={{ height: showFullLetter ? "auto" : "400px" }}
      onClick={onClick}
      type="button"
    >
      <div
        className={`flex-grow bg-${bgColor} mb-16 md:mb-0 p-8 shadow-sm border overflow-hidden h-full`}
        style={{ minHeight: "150px" }}
      >
        {documentHeaderContent && (
          <DocumentHeader>{documentHeaderContent}</DocumentHeader>
        )}
        {children}
      </div>
      {!showFullDocument && (
        <button
          className={`absolute bottom-0 w-full h-16 bg-${bgColor} z-10 t-small plus border`}
          type="button"
          onClick={() => setShowFullLetter(!showFullLetter)}
        >
          {showFullLetter ? t("hideFullPetition") : t("showFullPetition")}
        </button>
      )}
    </button>
  );
};

const PreviewDocument = ({
  contentJSON,
  showFullDocument,
  documentHeaderContent,
  onClick,
}: {
  contentJSON: string;
  showFullDocument: boolean;
  documentHeaderContent?: any;
  onClick?: () => void;
}) => {
  return (
    <Preview
      onClick={onClick}
      showFullDocument={showFullDocument}
      documentHeaderContent={documentHeaderContent}
    >
      <div className="full-formatting">
        <React.Suspense fallback={<Loading />}>
          <Sentry.ErrorBoundary>
            <ReadOnlyDocument doc={JSON.parse(contentJSON)} />
          </Sentry.ErrorBoundary>
        </React.Suspense>
      </div>
    </Preview>
  );
};

export default PreviewDocument;

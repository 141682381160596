import {
  Button,
  Sizes,
  Icon,
  EmptyState,
  Textarea,
  IconButton,
} from "@get-frank-eng/design-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import CustomFormResponseEdit from "../../../CustomForm/CustomFormResponseEdit";
import {
  useDownloadCustomFormResponseMutation,
  useAllResponsesForGroupDownloadTokenMutation,
  useUpdateCustomFormResponseNotesMutation,
} from "../../../generated-hooks";
import { useModals } from "../../../Modals";
import { dateFormatter } from "../../../utils/Date";
import { CustomFormResponseListResponse } from "./models";

const NotesField = ({
  customFormResponseId,
  notes,
}: {
  customFormResponseId: string;
  notes?: string;
}) => {
  const [notesText, setNotesText] = React.useState(notes);
  const [isEditing, setIsEditing] = React.useState(false);
  const [
    invokeUpdateCustomFormResponseNotes,
  ] = useUpdateCustomFormResponseNotesMutation();
  const saveNotes = React.useCallback(() => {
    invokeUpdateCustomFormResponseNotes({
      variables: {
        customFormResponseId,
        notes: notesText,
      },
    });
    setIsEditing(false);
  }, [setIsEditing, notesText]);
  const cancel = React.useCallback(() => {
    setIsEditing(false);
    setNotesText(notes);
  }, [notes, setNotesText, setIsEditing]);
  React.useEffect(() => {
    if (notes !== notesText) {
      setNotesText(notes);
    }
  }, [notes]);
  if (isEditing) {
    return (
      <span>
        <Textarea
          defaultValue={notesText}
          onChange={(evt) => setNotesText(evt.currentTarget.value)}
        />
        <div className="py-2 space-x-2">
          <Button buttonStyle="brand" onClick={saveNotes}>
            Save
          </Button>
          <Button onClick={cancel}>Cancel</Button>
        </div>
      </span>
    );
  }
  return (
    <div className="flex justify-between items-center">
      <div>{notesText}</div>
      <IconButton
        icon={notesText ? "edit" : "add"}
        buttonStyle="minimal"
        onClick={() => setIsEditing(true)}
      />
    </div>
  );
};

const CustomFormResponseSubmissionsTable = ({
  submittedResponses,
  groupId,
  customFormId,
  filteredByCoworker,
}: {
  submittedResponses: CustomFormResponseListResponse[];
  groupId: string;
  customFormId: string;
  filteredByCoworker: boolean;
}): JSX.Element => {
  const { preferredLanguage } = useCurrentUserData();
  const { t } = useTranslation();
  const { setModal } = useModals();

  const [
    invokeDownloadCustomFormResponseToken,
  ] = useDownloadCustomFormResponseMutation();

  const downloadForm = React.useCallback(
    async (customFormResponseId: string, format: string) => {
      const result = await invokeDownloadCustomFormResponseToken({
        variables: {
          customFormResponseId,
        },
      });
      const token = result.data?.getCustomFormResponseDownloadToken.token;
      if (token) {
        // TODO: update query above to return the URL, not the token (so we don't have to generate the URL here)
        window.location.href = `${process.env.REACT_APP_API_URL}/custom-forms/${customFormResponseId}/${format}?token=${token}`;
      }
    },
    []
  );
  const [
    invokeAllResponsesForGroupDownloadToken,
  ] = useAllResponsesForGroupDownloadTokenMutation();

  const downloadAllResponses = React.useCallback(async (format: string) => {
    const result = await invokeAllResponsesForGroupDownloadToken({
      variables: {
        groupId,
      },
    });
    const token = result.data?.getAllResponsesForGroupDownloadToken.token;
    if (token) {
      // TODO: update query above to return the URL, not the token (so we don't have to generate the URL here)
      window.location.href = `${process.env.REACT_APP_API_URL}/custom-forms/group/${groupId}/${customFormId}/${format}?token=${token}`;
    }
  }, []);

  const coworkerResponses = React.useMemo(() => {
    return submittedResponses
      .reduce((allResponses, currentCoworker) => {
        return allResponses.concat(
          currentCoworker.responses.map((r) => ({
            ...r,
            coworker: currentCoworker.coworker,
          }))
        );
      }, [])
      .sort((a, b) => {
        if (a.submittedAt < b.submittedAt) {
          return 1;
        }
        if (a.submittedAt > b.submittedAt) {
          return -1;
        }
        return 0;
      });
  }, [submittedResponses]);
  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <div className="t-large plus">{t("submissions")}</div>
        {submittedResponses.length && !filteredByCoworker && (
          <div className="space-x-2">
            <Button
              loading={false}
              size={Sizes.SM}
              className="outline"
              onClick={async () => {
                downloadAllResponses("pdf");
              }}
            >
              {t("downloadAll")}
            </Button>
          </div>
        )}
      </div>
      {submittedResponses.length ? (
        <div className="mt-6 space-y-2 mb-16 border rounded overflow-x-auto">
          <table className="w-full text-sm text-left">
            <thead>
              <tr className="border-b border-canvas-500 bg-canvas-500 divide-x divide-canvas-500">
                <th className="px-6 py-2 bg-canvas-500 border-none">
                  {t("view")}
                </th>
                {!filteredByCoworker && (
                  <>
                    <th className="px-6 py-2 bg-canvas-500 border-none">
                      {t("name")}
                    </th>
                    <th className="px-6 py-2 bg-canvas-500 border-none">
                      {t("email")}
                    </th>
                  </>
                )}
                <th className="px-6 py-2 bg-canvas-500 border-none">
                  {t("dateSubmitted")}
                </th>
                <th
                  className={`px-6 py-2 bg-canvas-500 border-none ${
                    filteredByCoworker ? "w-1/2" : "w-1/3"
                  }`}
                >
                  {t("notes")}
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-canvas-500">
              {coworkerResponses.map((r) => {
                return (
                  <tr key={r.id}>
                    <td className="px-8 py-4 border-b border-canvas-500">
                      <IconButton
                        icon="visibility"
                        buttonStyle="outline"
                        size={Sizes.MD}
                        onClick={() =>
                          setModal({
                            type: "customFormModal",
                            props: {
                              title: t("formSubmission"),
                              body: (
                                <CustomFormResponseEdit
                                  customFormId={customFormId}
                                  customFormResponseId={r.id}
                                />
                              ),
                              footer: (
                                <>
                                  <Button
                                    id="download-button"
                                    size={Sizes.SM}
                                    className="mt-4"
                                    onClick={() => downloadForm(r.id, "pdf")}
                                  >
                                    <div className="flex flex-row justify-between items-center space-x-2">
                                      <div>{t("download")}</div>
                                      <Icon
                                        icon="cloud_download"
                                        size={Sizes.MD}
                                      />
                                    </div>
                                  </Button>
                                </>
                              ),
                            },
                          })
                        }
                      />
                    </td>
                    {!filteredByCoworker && (
                      <>
                        <td className="px-6 py-4 border-b border-canvas-500">
                          {r.coworker.name}
                        </td>
                        <td className="px-6 py-4 border-b border-canvas-500">
                          {r.coworker.email}
                        </td>
                      </>
                    )}
                    <td className="px-6 py-4 border-b border-canvas-500">
                      {dateFormatter(preferredLanguage).format(
                        new Date(r.submittedAt)
                      )}
                    </td>
                    <td className="px-6 py-4 border-b border-canvas-500">
                      <NotesField customFormResponseId={r.id} notes={r.notes} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyState title={t("noSubmissionsYet")} />
      )}
    </>
  );
};

export default CustomFormResponseSubmissionsTable;

import {
  Button,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";
import { useModals } from "../../Modals";
import * as React from "react";

const CreateCustomResourceModal = ({ resolve }) => {
  const { t } = useTranslation();

  const { closeModal } = useModals();

  const [title, setTitle] = React.useState("");

  return (
    <>
      <ModalHeader title={t("createCustomResourceModalTitle")} />
      <ModalBody>
        <section>
          <FormGroup
            name="resourceTitle"
            label={t("resourceTitle")}
            id="resourceTitle"
          >
            <Input
              id="resourceTitle"
              helpText={t("resourceTitleInputHelpText")}
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
          </FormGroup>
        </section>
      </ModalBody>
      <ModalFooter>
        <div>
          <Button
            onClick={() => {
              resolve(title);
              closeModal();
            }}
          >
            {t("chooseFile")}
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

export default CreateCustomResourceModal;

import { FullCenter } from "@get-frank-eng/design-system";
import { OnboardingWorkflowState } from "frank-types/dist/frank-backend-types";
import { Redirect } from "react-router";
import { AuthStates } from "../Auth/AuthState";
import useSession from "../Auth/useSession";
import Loading from "../components/Loading";
import FullPageError from "../FullPageError";
import { useModals } from "../Modals";
import useStartOnboarding from "./dataAccess/mutations/useStartOnboarding";
import OnboardingFail from "./OnboardingFail";
import Onboarding from "./OnboardingMain";
import { useTranslation } from "react-i18next";

export const InitialOnboarding = () => {
  const { setModal } = useModals();
  const {
    onboardingData,
    onboardingError,
    onboardingLoading,
    onboardingRefetch,
    token,
  } = useStartOnboarding();
  const { t } = useTranslation();

  const { authState } = useSession({
    groupId: onboardingData?.groupId,
    afterLogin: onboardingRefetch,
  });

  if (onboardingLoading || authState === AuthStates.LOADING_EXISTING_SESSION) {
    return (
      <div className="h-screen">
        <FullCenter>
          <Loading />
        </FullCenter>
      </div>
    );
  }

  if (onboardingData?.workflowState === OnboardingWorkflowState.Finished) {
    setModal({
      type: "genericModal",
      props: {
        body: (
          <div data-cy="already-a-member-in-group-modal">
            {t("youAlreadyHaveAMembership")}
          </div>
        ),
      },
    });
    return <Redirect to="/login" />;
  }

  if (onboardingError) {
    if (onboardingError.message.includes("cannot onboard")) {
      return <OnboardingFail />;
    }
    console.error(onboardingError);
    return <FullPageError />;
  }
  if (onboardingData) {
    return (
      <Onboarding
        token={token}
        onboardingData={onboardingData}
        onboardingRefetch={onboardingRefetch}
      />
    );
  }

  return null;
};

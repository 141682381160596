import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const MUTATION = gql`
  mutation CreateAuthorizationCard($input: CreateAuthorizationCardInput!) {
    createAuthorizationCard(input: $input) {
      id
    }
  }
`;

export default function useCreateAuthorizationCard() {
  const [
    invokeCreateAuthorizationCard,
    { loading, called, error },
  ] = useMutation<any, FrankBackendTypes.MutationCreateAuthorizationCardArgs>(
    MUTATION
  );

  const createAuthorizationCard = React.useCallback(
    async ({
      groupId,
      language,
      notify,
      company,
      additionalFields,
    }: {
      groupId: string;
      language: string;
      notify: boolean;
      company: string;
      additionalFields: string;
    }) => {
      await invokeCreateAuthorizationCard({
        variables: {
          input: {
            groupId,
            language,
            notify,
            company,
            additionalFields,
          },
        },
      });
    },
    [invokeCreateAuthorizationCard]
  );

  return {
    createAuthorizationCard,
    loading,
    called,
    error,
  };
}

import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useHistory } from "react-router";
import * as analytics from "../../../analytics";
import useIntercom from "../../../useIntercom";
import SentryClient from "../../../sentryClient";

export const onboardingFragment = gql`
  fragment OnboardingFragment on Onboarding {
    groupId
    id
    intercomHash
    userId
    hasAccount
    workflowState
    partner {
      id
      name
      onboardingFormJSON
      logoAttachment {
        id
        url
      }
    }
    token
    group {
      id
      name
      company
    }
    email
    inviteType
    workflowState
    inviter {
      name
      profilePicUrl
    }
    onboardingCache {
      key
      value
      boolValue
    }
  }
`;

const ONBOARDING = gql`
  query Onboarding {
    onboarding {
      ...OnboardingFragment
    }
  }
  ${onboardingFragment}
`;

export default function useOnboardingQuery() {
  const history = useHistory();

  const { data, loading, refetch, error: onboardingError } = useQuery<{
    onboarding: FrankBackendTypes.Onboarding;
  }>(ONBOARDING);

  const onboardingData = data?.onboarding;

  useIntercom({
    userId: onboardingData?.userId,
    intercomHash: onboardingData?.intercomHash,
    email: onboardingData?.email,
    groupId: onboardingData?.groupId,
    groupName: onboardingData?.group.name,
  });

  React.useEffect(() => {
    if (!onboardingData) {
      return;
    }
    analytics.init({
      history,
      userId: onboardingData.userId,
    });

    if (onboardingData.intercomHash) {
      SentryClient.configureScope(function (scope) {
        scope.setTag("group", onboardingData?.groupId);
        scope.setUser({ id: onboardingData?.userId, workerType: "worker-two" });
      });
    }
  }, [onboardingData, history]);
  return {
    onboardingLoading: loading,
    onboardingError,
    onboardingData,
    onboardingRefetch: refetch,
  };
}

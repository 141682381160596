import {
  InlineAlert,
  Button,
  FormGroup,
  Input,
  Intent,
  Textarea,
  useToaster,
  FeatherIcon,
  Sizes,
} from "@get-frank-eng/design-system";
import axios from "axios";
import * as React from "react";
import { useForm } from "react-hook-form";
import IntercomShouldShow from "./components/IntercomShouldShow";
import TopNavForPublicPages from "./components/TopNavForPublicPages";
import { useTranslation } from "react-i18next";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
};

interface FormData {
  name: string;
  email: string;
  message: string;
}

const Contact = () => {
  const { t } = useTranslation();
  const { register, errors, handleSubmit, reset } = useForm<FormData>();
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const toaster = useToaster();

  const submit = async (formData: FormData) => {
    try {
      setLoading(true);
      await axios("/", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: encode({ "form-name": "contact", ...formData }),
      });
      toaster.addToast({
        children: t("thanksForContactingUs"),
        intent: Intent.SUCCESS,
      });
      reset();
      setLoading(false);
    } catch (e) {
      setError(e);
      toaster.addToast({
        children: t("thereWasAProblemSubmittingTheForm"),
        intent: Intent.FAILURE,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <TopNavForPublicPages />
      <IntercomShouldShow />
      <div className="mx-4 md:mx-0 md:w-2/3 mx-2 md:mx-auto flex flex-col md:flex-row mt-20 md:mt-32">
        <div className="md:flex-grow-2 md:mr-20 lg:mr-32">
          <h2 className="t-title-3 plus-plus">{t("contactFrank")}</h2>
          <p className="t-regular">{t("weTakeCustomerSupportToHeartIfYo")}</p>
          {error && (
            <InlineAlert title={t("serverError")} intent={Intent.FAILURE}>
              {t("sorryWeCouldntProcessYourMessage")}
            </InlineAlert>
          )}
          <form className="mt-4" onSubmit={handleSubmit(submit)} noValidate>
            <FormGroup name="name" id="name" label={t("name")}>
              <Input
                errorText={errors.name && t("youMustProvideAName")}
                type="text"
                register={register}
                registerArgs={{
                  required: true,
                }}
              />
            </FormGroup>
            <FormGroup name="email" id="email" label={t("email")}>
              <Input
                errorText={errors.email && t("youMustProvideAnEmail")}
                type="email"
                register={register}
                registerArgs={{
                  required: true,
                  pattern: /^\S+@\S+$/i,
                }}
              />
            </FormGroup>

            <FormGroup name="message" id="message" label={t("message")}>
              <Textarea
                errorText={errors.message && t("youMustProvideAMessage")}
                register={register}
                registerArgs={{
                  required: true,
                }}
              />
            </FormGroup>
            <Button loading={isLoading} type="submit" buttonStyle="brand">
              {t("sendMessage")}
            </Button>
          </form>
        </div>
        <div className="md:w-56 lg:w-72 flex-shrink-0 h-64 flex flex-col">
          <a
            href="mailto:frank@getfrank.com"
            className="flex flex-row flex-shrink-0 items-center h-16 border-b justify-between text-canvas-400 cursor-pointer"
          >
            <div>{t("emailFrankGetfrankCom")}</div>
            <div>
              <FeatherIcon
                icon="AtSign"
                size={Sizes.MD}
                classNames="text-brand-300"
              />
            </div>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/GetFrank_com"
            className="flex flex-row flex-shrink-0 items-center h-16 border-b justify-between text-canvas-400 cursor-pointer"
          >
            <div>{t("followUsOnTwitter")}</div>
            <div>
              <FeatherIcon
                icon="Twitter"
                classNames="text-brand-300"
                size={Sizes.MD}
              />
            </div>
          </a>

          <a
            href="https://www.linkedin.com/company/getfrankpbc/"
            target="_blank"
            rel="noreferrer"
            className="flex flex-row flex-shrink-0 items-center h-16 border-b justify-between text-canvas-400 cursor-pointer"
          >
            <div>{t("visitOurLinkedInPage")}</div>
            <div>
              <FeatherIcon
                icon="Linkedin"
                classNames="text-brand-300"
                size={Sizes.MD}
              />
            </div>
          </a>

          <div className="flex flex-row flex-shrink-0 items-center py-6 justify-between text-canvas-400 cursor-pointer">
            <div>
              <p>{t("getFrankPbc")}</p>
              <p>{t("poBox13470")}</p>
              {t("chicagoIl60613")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

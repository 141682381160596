import React from "react";
import ActionListItem from "./ActionListItem";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddCoworkersActionItem = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const start = () => push("/coworkers");
  const actions = [
    {
      label: t("start"),
      action: start,
    },
  ];
  return (
    <ActionListItem
      title={t("mapYourWorkplace")}
      subheading={t("whyMapWorkplace")}
      actions={actions}
      defaultAction={start}
    />
  );
};

export default AddCoworkersActionItem;

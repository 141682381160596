import * as React from "react";
import { Action, OnboardingStep, ServerErrorTypes } from "./state";

type SetLoading = (step: OnboardingStep) => void;
type SetCreateAccountSubmitted = () => void;
type SetTellUsSubmitted = () => void;
type SetServerError = (type: ServerErrorTypes, message: string) => void;
type SetHasExistingMembership = (type: boolean) => void;

export interface DispatcherAPI {
  setLoading: SetLoading;
  setCreateAccountSubmitted: SetCreateAccountSubmitted;
  setTellUsSubmitted: SetTellUsSubmitted;
  setServerError: SetServerError;
  setHasExistingMembership: SetHasExistingMembership;
}

export default function useDispatcher({
  dispatch,
}: {
  dispatch: React.Dispatch<Action>;
}): DispatcherAPI {
  const setHasExistingMembership = React.useCallback(
    function setHasExistingMembership(hasExistingMembership: boolean) {
      dispatch({ type: "setHasExistingMembership", hasExistingMembership });
    },
    [dispatch]
  );

  const setLoading = React.useCallback(
    function setLoading(step: OnboardingStep) {
      dispatch({ type: "setLoading", step });
    },
    [dispatch]
  );

  const setCreateAccountSubmitted = React.useCallback(
    function setSubmitted() {
      dispatch({ type: "setSubmitted", step: OnboardingStep.CREATE_ACCOUNT });
    },
    [dispatch]
  );

  const setTellUsSubmitted = React.useCallback(
    function setSubmitted() {
      dispatch({
        type: "setSubmitted",
        step: OnboardingStep.TELL_US_ABOUT_YOURSELF,
      });
    },
    [dispatch]
  );

  const setServerError = React.useCallback(
    function setSubmitted(type: ServerErrorTypes, message: string) {
      dispatch({
        type: "setServerError",
        message,
        errorType: type,
      });
    },
    [dispatch]
  );

  return {
    setLoading,
    setCreateAccountSubmitted,
    setTellUsSubmitted,
    setServerError,
    setHasExistingMembership,
  };
}

import * as React from "react";
import {
  Button,
  FormGroup,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MultiSelect,
  Switch,
  useToaster,
} from "@get-frank-eng/design-system";
import { useForm } from "react-hook-form";
import useCreateGroup from "../dataAccess/mutations/useCreateGroup";
import { useModals } from "../../Modals";
import useOrganizers from "../../Partner/dataAccess/hooks/queries/useOrganizers";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { useTranslation } from "react-i18next";
import { FrankBackendTypes } from "frank-types";

type FormParams = {
  name: string;
};

const CreateGroupModal = ({
  afterSubmit,
  partnerId,
}: {
  afterSubmit: (id) => any;
  partnerId?: string;
}) => {
  const { t } = useTranslation();
  const { currentGroup, myProfile } = useCurrentUserData();
  const { closeModal } = useModals();
  const { register, errors, handleSubmit } = useForm<FormParams>();
  const { createGroup, loading } = useCreateGroup();
  const toaster = useToaster();
  const { organizers, loading: loadingOrganizers } = useOrganizers(
    partnerId || currentGroup.partnerId
  );
  const [organizersToAdd, setOrganizersToAdd] = React.useState<
    {
      label: string;
      value: string;
    }[]
  >([{ label: myProfile.name, value: myProfile.id }]);
  const [addAllOrganizers, setAddAllOrganizers] = React.useState(true);

  const filterOrganizers = (inputValue: string) => {
    return organizers.objects
      .filter((o) =>
        o.user.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((o) => ({
        label: o.user.name,
        value: o.userId,
      }));
  };

  // sometimes a Frank ADMIN will be creating a group. But since they're not an organizer, they should not be suggested as someone to be lead organizer of the group
  const defaultOption =
    myProfile.workerType === FrankBackendTypes.WorkerType.Organizer
      ? [{ label: myProfile.name, value: myProfile.id }]
      : [];

  // this is a promise b/c we're using the Async version of the select
  const loadOptions = async (searchString) => filterOrganizers(searchString);

  const submit = React.useCallback(
    async (formValues: FormParams) => {
      try {
        const group = await createGroup({
          name: formValues.name,
          partnerId: partnerId || currentGroup.partnerId,
          organizerUserIds: organizersToAdd.map((o) => o.value),
          addAllOrganizers,
        });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("groupNameHasBeenCreated", {
            groupName: formValues.name,
          }) as string,
        });
        afterSubmit(group.id);
        closeModal();
      } catch (e) {
        console.log("e", e);
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("thereWasAnErrorCreatingGroupName", {
            groupName: formValues.name,
          }) as string,
        });
      }
    },
    [
      createGroup,
      partnerId,
      currentGroup.partnerId,
      organizersToAdd,
      addAllOrganizers,
      toaster,
      afterSubmit,
      closeModal,
      t,
    ]
  );

  const form = (
    <form
      onSubmit={handleSubmit(submit)}
      id="create-group-form"
      className="space-y-6"
    >
      <FormGroup label={t("name")} name="name" id="name">
        <Input
          register={register}
          errorText={errors.name && errors.name.message}
          maxLength={80}
          helpText={t("whatDoYouWantToCallThisGroup") as string}
          registerArgs={{
            required: t("yourGroupNeedsAName"),
            maxLength: t("groupNamesHaveAMaximumLengthOf80"),
          }}
        />
      </FormGroup>
      <section>
        <div className="t-small plus pb-1">
          {t("whichOrganizersShouldBeInThisGroup")}
        </div>
        <div className="t-mini text-canvas-400">
          {t("allOrganizersWillHaveAccessToTheN")}
        </div>
        <div className="space-y-3 pt-4">
          <div className="flex justify-between items-center">
            <div className="t-small">{t("allCurrentOrganizers")}</div>
            <Switch
              checked={addAllOrganizers}
              label={t("addAllCurrentOrganizers")}
              onChange={() => {
                setAddAllOrganizers(!addAllOrganizers);
                if (addAllOrganizers) {
                  setOrganizersToAdd([
                    { label: myProfile.name, value: myProfile.id },
                  ]);
                } else {
                  setOrganizersToAdd([]);
                }
              }}
              disabled={loading}
            />
          </div>
          {!addAllOrganizers && (
            <div>
              <MultiSelect
                loadOptions={loadOptions}
                defaultOptions={organizers?.objects.map((o) => ({
                  label: o.user.name,
                  value: o.userId,
                }))}
                onChange={(newValue) => setOrganizersToAdd(newValue)}
                isLoading={loadingOrganizers}
                disabled={addAllOrganizers}
                defaultValue={defaultOption}
              />
              <div className="t-mini text-canvas-400 pt-2 pb-4">
                {t("youllBeAbleToAddOrRemoveOrganize")}
              </div>
            </div>
          )}
        </div>
      </section>
    </form>
  );

  return (
    <>
      <ModalHeader title={t("createAGroup")} />
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        <Button loading={loading} form="create-group-form" buttonStyle="brand">
          {t("createGroup")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default CreateGroupModal;

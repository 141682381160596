import debounce from "lodash/debounce";
import last from "lodash/last";
import first from "lodash/first";
import * as React from "react";
import {
  getMessage as coreGetMessage,
  getMessages,
} from "../core/messages/queries";
import {
  reactToMessage as coreReactToMessage,
  editMessage as coreEditMessage,
  sendMessage as coreSendMessage,
  deleteMessage as coreDeleteMessage,
} from "../core/messages/mutations";
import {
  enterChannel as coreEnterChannel,
  listenOnChannel,
  leaveChannel as coreLeaveChannel,
  unlistenOnChannel,
  type,
  getWebsocket,
} from "../core/sockets";

import { messageReducer, defaultMessageState } from "../state/messages";
import { setViewCursor as coreSetViewCursor } from "../core/channels/mutations";
import { ChatTypes } from "frank-types";
import { useDrafts } from "./useDrafts";
import { chatEmitter } from "../chatEmitter";
import { useChannels } from "./useChannels";
import intersection from "lodash/intersection";

export function useMessages({
  channelId,
  parentId,
  startOfPage,
}: {
  channelId: string;
  parentId?: string;
  startOfPage?: Date;
}) {
  const { clearUnread } = useChannels();
  const { drafts, setDraft } = useDrafts();
  const setDraftForThisChannel = React.useCallback(
    (text: string) => {
      setDraft(parentId || channelId, text);
    },
    [setDraft, channelId, parentId]
  );
  const [state, dispatch] = React.useReducer(
    messageReducer,
    defaultMessageState
  );

  // React.useEffect(() => {
  //   const interval = setInterval(
  //     () => dispatch({ type: "filter-expired-typing" }),
  //     500
  //   );
  //   return () => clearInterval(interval);
  // }, [dispatch]);

  const addMessage = React.useCallback(
    async (messageId: string) => {
      const message = await coreGetMessage(messageId);

      if (message.channelId === channelId) {
        dispatch({ type: "message-created", message });
      }
      if (message.parent) {
        dispatch({ type: "parent-updated", message: message.parent });
      }
    },
    [dispatch, channelId]
  );

  const updateMessage = React.useCallback(
    async (messageId: string) => {
      const message = await coreGetMessage(messageId);
      if (message.channelId === channelId) {
        dispatch({ type: "message-updated", message });
      }
    },
    [dispatch, channelId]
  );

  const reactToUserTyped = React.useCallback(
    ({ user, duration }: { duration: number; user: ChatTypes.User }) => {
      dispatch({ type: "user-typed", user, duration });
      setTimeout(() => dispatch({ type: "user-done-typing", user }), duration);
    },
    [dispatch]
  );

  const oldestMessageDate = React.useMemo(() => {
    if (state.messages.length === 0) {
      return startOfPage || new Date();
    }
    return first(state.messages)!.createdAt;
  }, [state.messages, startOfPage]);

  const youngestMessageDate = React.useMemo(() => {
    if (state.messages.length === 0) {
      return new Date();
    }
    return last(state.messages)!.createdAt;
  }, [state.messages]);

  const canSendMessage = React.useMemo(() => {
    const lastMessage = last(state.messages);
    if (!lastMessage || !lastMessage.richObjects) {
      return true;
    }

    const richObjectTypesThatDisableMessage: string[] = [
      // "update-company-name",
      // "create-topic",
      // "suggested-response",
    ];

    // you can send a message as long as the last message doesn't have a rich object on the deny list

    const types = lastMessage.richObjects.map((ro) => {
      return ro.type;
    });

    const intersectionWithDenyList = intersection(
      types,
      richObjectTypesThatDisableMessage
    );

    return intersectionWithDenyList.length === 0;
  }, [state.messages]);

  const loadOlderMessages = React.useCallback(async () => {
    if (state.loading.getMessages) {
      return;
    }
    try {
      dispatch({
        type: "loading",
        loadingType: "getMessages",
      });

      const {
        messages: { messages, parent },
      } = await getMessages({
        channelId,
        parentId,
        paginationInput: {
          beforeDate: oldestMessageDate,
          perPage: 36,
        },
      });

      dispatch({
        type: "older-messages-loaded",
        messages: messages.objects,
        oldest: messages.oldest,
        newest: messages.newest,
        parent,
      });
      dispatch({
        type: "finish-loading",
        loadingType: "getMessages",
      });
    } catch (e) {
      dispatch({
        type: "error",
        errorType: "getMessages",
        error: `Unable to load message: ${e}`,
      });
    }
  }, [channelId, parentId, oldestMessageDate, state.loading]);

  const loadNewerMessages = React.useCallback(async () => {
    if (state.loading.getMessages) {
      return;
    }

    try {
      dispatch({
        type: "loading",
        loadingType: "getMessages",
      });

      const {
        messages: { messages, parent },
      } = await getMessages({
        channelId,
        parentId,
        paginationInput: {
          afterDate: youngestMessageDate,
          perPage: 36,
        },
      });

      dispatch({
        type: "newer-messages-loaded",
        messages: messages.objects,
        oldest: messages.oldest,
        newest: messages.newest,
        parent,
      });
      dispatch({
        type: "finish-loading",
        loadingType: "getMessages",
      });
    } catch (e) {
      dispatch({
        type: "error",
        errorType: "getMessages",
        error: "Unable to load messages",
      });
    }
  }, [channelId, parentId, youngestMessageDate, state.loading]);

  const resetMessages = React.useCallback(async () => {
    dispatch({
      type: "messages-reset",
      oldest: null,
      newest: null,
      messages: [],
      parent: null,
    });
    dispatch({
      type: "loading",
      loadingType: "getMessages",
    });

    const {
      messages: { messages, parent },
    } = await getMessages({
      channelId,
      parentId,
      paginationInput: {
        beforeDate: new Date(),
        perPage: 36,
      },
    });

    dispatch({
      type: "older-messages-loaded",
      messages: messages.objects,
      oldest: messages.oldest,
      newest: messages.newest,
      parent,
    });
    dispatch({
      type: "finish-loading",
      loadingType: "getMessages",
    });
  }, [dispatch, parentId, channelId]);

  const reactToMessage = React.useCallback(
    async (messageId, reaction) => {
      try {
        dispatch({
          type: "loading",
          loadingType: "reactToMessage",
        });

        await coreReactToMessage({ messageId, reaction });

        dispatch({
          type: "finish-loading",
          loadingType: "reactToMessage",
        });
      } catch (e) {
        dispatch({
          type: "error",
          errorType: "reactToMessage",
          error: "Unable to react to message",
        });
      }
    },
    [dispatch]
  );

  const editMessage = React.useCallback(
    async (messageId, body) => {
      try {
        dispatch({
          type: "loading",
          loadingType: "editMessage",
        });

        await coreEditMessage({ messageId, body });

        dispatch({
          type: "finish-loading",
          loadingType: "editMessage",
        });
      } catch (e) {
        dispatch({
          type: "error",
          errorType: "editMessage",
          error: "Unable to edit message",
        });
      }
    },
    [dispatch]
  );

  const deleteMessage = React.useCallback(
    async (messageId) => {
      try {
        dispatch({
          type: "loading",
          loadingType: "deleteMessage",
        });

        await coreDeleteMessage({ messageId });

        dispatch({
          type: "finish-loading",
          loadingType: "deleteMessage",
        });
      } catch (e) {
        console.error(e);
        dispatch({
          type: "error",
          errorType: "deleteMessage",
          error: "Unable to delete message",
        });
      }
    },
    [dispatch]
  );

  const sendMessage = React.useCallback(
    async (input: Omit<ChatTypes.SendMessageInput, "channelId">) => {
      try {
        const messageInput = {
          ...input,
          channelId,
          parentId,
          sentAt: new Date(),
        };
        dispatch({
          type: "loading",
          loadingType: "sendMessage",
        });

        dispatch({
          type: "sending-message",
          message: {
            sentAt: messageInput.sentAt,
            body: messageInput.body,
          },
        });

        await coreSendMessage(messageInput);
        await coreSetViewCursor(channelId);
        chatEmitter.emit("message-created", channelId);
        dispatch({
          type: "finish-loading",
          loadingType: "sendMessage",
        });
      } catch (e) {
        dispatch({
          type: "error",
          errorType: "sendMessage",
          error: "Unable to send message",
        });
        dispatch({
          type: "finish-loading",
          loadingType: "sendMessage",
        });
      }
    },
    [dispatch, channelId, parentId, state.messages]
  );

  const updateMessagefromRepliedTo = React.useCallback(
    ({ parentId }) => {
      return updateMessage(parentId);
    },
    [updateMessage]
  );

  const enterChannel = React.useCallback(() => {
    coreEnterChannel({ channelId, parentId });
    listenOnChannel({
      room: parentId || channelId,
      event: "message-created",
      callback: addMessage,
    });
    listenOnChannel({
      room: parentId || channelId,
      event: "user-typed",
      callback: reactToUserTyped,
    });
    listenOnChannel({
      event: "message-updated",
      room: parentId || channelId,
      callback: updateMessage,
    });
    listenOnChannel({
      event: "message-replied-to",
      room: parentId || channelId,
      callback: updateMessagefromRepliedTo,
    });

    loadOlderMessages();
    getWebsocket().then((socket) => {
      socket.on("reconnect", () => {
        coreEnterChannel({ channelId, parentId });
        resetMessages();
      });
    });
  }, [
    channelId,
    parentId,
    addMessage,
    updateMessagefromRepliedTo,
    reactToUserTyped,
    resetMessages,
    updateMessage,
    loadOlderMessages,
  ]);

  const setViewCursor = React.useCallback(() => {
    coreSetViewCursor(channelId);
  }, [channelId]);

  const leaveChannel = React.useCallback(() => {
    clearUnread(channelId);
    coreLeaveChannel({ channelId, parentId });
    unlistenOnChannel({
      room: parentId || channelId,
      event: "message-created",
      callback: addMessage,
    });
    unlistenOnChannel({
      room: parentId || channelId,
      event: "user-typed",
      callback: reactToUserTyped,
    });
    unlistenOnChannel({
      event: "message-updated",
      room: parentId || channelId,
      callback: updateMessage,
    });
    unlistenOnChannel({
      event: "message-replied-to",
      room: parentId || channelId,
      callback: updateMessagefromRepliedTo,
    });
    getWebsocket().then((socket) => {
      socket.off("reconnect", resetMessages);
    });
    setViewCursor();
  }, [
    channelId,
    parentId,
    clearUnread,
    setViewCursor,
    addMessage,
    reactToUserTyped,
    resetMessages,
    updateMessage,
    updateMessagefromRepliedTo,
  ]);

  const typingUsers = React.useMemo(
    () => state.typingUsers.map((u) => u.user),
    [state.typingUsers]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sendTypingSignal = React.useCallback(
    debounce(() => {
      type({ channelId, parentId });
    }, 200),
    [channelId, parentId]
  );

  return {
    errors: state.errors,
    messages: state.messages,
    messagesInFlight: state.messagesInFlight,
    hasOlderMessages: state.oldest && oldestMessageDate > state.oldest,
    hasNewerMessages: state.newest && youngestMessageDate < state.newest,
    typingUsers: typingUsers,
    loading: state.loading,
    parent: state.parent,
    sendTypingSignal,
    loadNewerMessages,
    loadOlderMessages,
    sendMessage,
    setViewCursor,
    draft: drafts[parentId || channelId],
    setDraft: setDraftForThisChannel,
    enterChannel,
    leaveChannel,
    canSendMessage,
    reactToMessage,
    editMessage,
    deleteMessage,
  };
}

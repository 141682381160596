import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import PendingMemberSection from "../components/PendingMemberSection";
import TaskRow from "../components/TaskRow";
import { useTranslation } from "react-i18next";

export default function Authenticity({
  pendingMember,
}: {
  pendingMember: FrankBackendTypes.Coworker;
}) {
  const { t } = useTranslation();
  const hasDangerousEmail = pendingMember.onboardingIssues.issueList.includes(
    FrankBackendTypes.OnboardingIssueType.DangerousEmail
  );

  const hasUnknownEmail = pendingMember.onboardingIssues.issueList.includes(
    FrankBackendTypes.OnboardingIssueType.UnknownEmail
  );

  return (
    <PendingMemberSection
      title={t("authenticity")}
      tooltip={JSON.stringify(
        pendingMember.onboardingIssues.authenticityIssues.map((i) => i.type),
        null,
        2
      )}
      complete={pendingMember.onboardingIssues.authenticityIssues.length === 0}
      showWarning={hasDangerousEmail}
    >
      <TaskRow
        complete
        title={t("invitedByAVerifiedGroupMember")}
        description={t("invitedViaInviteTypeByInviterN", {
          inviteType: pendingMember.coworkerInvite.inviteType,
          inviterName:
            // this is kind of a hack ... done so people who were "invited" by signing an authorization card
            pendingMember.coworkerInvite.inviter?.name || t("aVerifiedMember"),
        })}
      />
      {hasDangerousEmail && (
        <TaskRow
          showWarning={hasDangerousEmail}
          complete={false}
          title={t("emailFlaggedAsDangerous")}
          description={t("thisMightNotReallyBePendingMember", {
            pendingMemberName: pendingMember.name,
          })}
        />
      )}
      <TaskRow
        dataCy="email-address-authenticity"
        complete={
          !pendingMember.onboardingIssues.issueList.includes(
            FrankBackendTypes.OnboardingIssueType.UnknownEmail
          )
        }
        title={
          hasUnknownEmail
            ? t("emailAddressNotPreTrusted")
            : t("emailAuthenticityVerified")
        }
        description={
          hasUnknownEmail
            ? t("pendingMemberEmailWasConfirmedBy", {
                pendingMemberEmail: pendingMember.email,
                pendingMemberName: pendingMember.name,
              })
            : ""
        }
      />

      {pendingMember.coworkerInvite.requiresPassphrase && (
        <TaskRow
          complete
          title={t("passcodeCorrectlyEntered")}
          description={t("pendingMemberNameEnteredTheCorrec", {
            pendingMemberName: pendingMember.name,
          })}
        />
      )}
    </PendingMemberSection>
  );
}

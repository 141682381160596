import * as React from "react";
import {
  Button,
  FormGroup,
  Input,
  Intent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Switch,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import { useForm } from "react-hook-form";
import useCreateTopic from "../dataAccess/mutations/useCreateTopic";
import { useModals } from "../../Modals";
import { useHistory } from "react-router";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { AbilityAction, ChannelType } from "frank-types";
import { useTranslation } from "react-i18next";

type FormParams = {
  name: string;
  description?: string;
};

const CreateTopicModal = ({
  afterSubmit,
  redirect = true,
}: {
  afterSubmit: () => any;
  redirect?: boolean;
}) => {
  const { t } = useTranslation();
  const [isPrivateTopic, setIsPrivateTopic] = React.useState(false);
  const { closeModal, setModal } = useModals();
  const { register, errors, handleSubmit } = useForm<FormParams>();
  const { createTopic, loading } = useCreateTopic();
  const { ability } = useCurrentUserData();
  const canCreatePrivateTopic = ability?.can(AbilityAction.Manage, "Group");
  const toaster = useToaster();
  const history = useHistory();

  const submit = React.useCallback(
    async (formValues: FormParams) => {
      try {
        const topic = await createTopic({
          ...formValues,
          type: isPrivateTopic
            ? ChannelType.PRIVATE_ROOM
            : ChannelType.PUBLIC_ROOM,
        });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("topicNameHasBeenCreated", {
            topicName: formValues.name,
          }),
        });
        closeModal();
        if (redirect) {
          history.push(`/topics/${topic.slug}`);
          if (isPrivateTopic) {
            setModal({
              type: "addMemberModal",
              props: {
                channelId: topic.channelId,
                topicName: topic.name,
              },
            });
          }
        }
        afterSubmit();
      } catch (e) {
        if (e.message?.includes("nameUnique")) {
          toaster.addToast({
            intent: Intent.FAILURE,
            children: `Duplicate topic. Try a new name.`,
          });
          return;
        }
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("thereWasAnErrorCreatingTopicName", {
            topicName: formValues.name,
          }),
        });
      }
    },
    [
      afterSubmit,
      closeModal,
      createTopic,
      toaster,
      history,
      redirect,
      isPrivateTopic,
      setModal,
      t,
    ]
  );

  const form = (
    <form onSubmit={handleSubmit(submit)} id="create-topic-form">
      <FormGroup label={t("name")} name="name" id="name">
        <Input
          register={register}
          errorText={errors.name && errors.name.message}
          maxLength={80}
          helpText={t("someExamplesCouldBeParentalLeave")}
          registerArgs={{
            required: t("yourTopicNeedsAName"),
            maxLength: t("topicNamesHaveAMaximumLengthOf80"),
            validate: (text) =>
              !text.includes("#") || 'Name cannot include "#"',
          }}
        />
      </FormGroup>
      <FormGroup label={t("description")} name="description" id="description">
        <Textarea
          register={register}
          errorText={errors.description && errors.description.message}
          maxLength={500}
          registerArgs={{
            maxLength: t("topicDescriptionsHaveAMaximumLength"),
          }}
        />
      </FormGroup>
      {canCreatePrivateTopic && (
        <div>
          <div className="t-mini plus pb-1">{t("makePrivate")}</div>
          <div className="flex justify-between">
            <div className="t-small">
              {t("privateChannelsCanOnlyBeJoinedOrV")}
            </div>
            <Switch
              onChange={() => setIsPrivateTopic(!isPrivateTopic)}
              checked={isPrivateTopic}
              label={t("makePrivate")}
              id="topic-type-switch"
            />
          </div>
        </div>
      )}
    </form>
  );

  return (
    <>
      <ModalHeader title={t("createATopic")} />
      <ModalBody>{form}</ModalBody>
      <ModalFooter>
        <Button loading={loading} form="create-topic-form" buttonStyle="brand">
          {t("createTopic")}
        </Button>
      </ModalFooter>
    </>
  );
};

export default CreateTopicModal;

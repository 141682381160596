import { Button } from "@get-frank-eng/design-system";
import { useCurrentUserData } from "../../../../Auth/useCurrentUserData";
import DefaultInvite from "../../../../Invites/DefaultInvite";
import { useModals } from "../../../../Modals";
import PrimaryActionItem from "./PrimaryActionItem";

const InviteCoworkersPrimaryActionEmbed = ({}) => {
  const { currentGroup } = useCurrentUserData();
  return (
    <PrimaryActionItem
      expandable={false}
      isExpanded={false}
      setIsExpanded={() => null}
    >
      <>
        <DefaultInvite />
      </>
    </PrimaryActionItem>
  );
};

export default InviteCoworkersPrimaryActionEmbed;

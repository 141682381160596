import * as React from "react";
import {
  useCreateOrUpdateCustomFormResponseMutation,
  useCustomFormAndSectionsAndFieldsQuery,
  useCustomFormResponseLazyQuery,
  useDeleteFormResponseDraftMutation,
} from "../generated-hooks";
import {
  Button,
  Delay,
  Intent,
  LargePulseCard,
  useToaster,
  InlineAlert,
  Sizes,
  IconButton,
} from "@get-frank-eng/design-system";
import { TFunction, useTranslation } from "react-i18next";
import { omit } from "lodash";
import { DataFieldResponseDataInput } from "./types/common";
import CustomFormSectionResponse from "./CustomFormSectionResponse";
import { useModals } from "../Modals";
import sentryClient from "../sentryClient";
import { FrankBackendTypes } from "frank-types";
import EventEmitter from "./util";
import { CustomFormConfigProvider } from "./hooks/useCustomFormConfigContext";

function humanReadableError(
  formError: FrankBackendTypes.SubmitCustomFormError,
  t: TFunction<"translations", undefined>
): string {
  switch (formError) {
    case FrankBackendTypes.SubmitCustomFormError.Invalid:
      return t("someResponsesInvalid");
    case FrankBackendTypes.SubmitCustomFormError.MultipleSubmissionsNotAllowed:
      return t("cannotSubmitMultiple");
    default:
      return t("unableToCreateResponse");
  }
}

export default function CustomFormResponseEdit({
  customFormId,
  customFormResponseId,
  groupId,
  onComplete,
  viewOnly,
  close,
}: {
  customFormId: string;
  customFormResponseId?: string;
  groupId?: string;
  onComplete?: (submittedAt?: Date) => void;
  viewOnly?: boolean;
  close?: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const { openConfirmationModal, modalBack } = useModals();
  const toaster = useToaster();
  const saveEmitter = React.useRef(new EventEmitter());
  const [
    invokeCreateOrUpdateCustomFormResponse,
    {
      data: createOrUpdateData,
      error: createOrUpdateError,
      loading: createOrUpdateLoading,
    },
  ] = useCreateOrUpdateCustomFormResponseMutation();

  const {
    data,
    loading,
    error,
    refetch,
  } = useCustomFormAndSectionsAndFieldsQuery({
    variables: {
      customFormId,
    },
  });

  const [
    getResponse,
    {
      data: formResponseData,
      loading: formResponseLoading,
      error: formResponseError,
    },
  ] = useCustomFormResponseLazyQuery({
    defaultOptions: { fetchPolicy: "network-only" },
  });

  React.useEffect(() => {
    if (customFormResponseId) {
      getResponse({ variables: { formResponseId: customFormResponseId } });
    }
  }, [customFormResponseId]);

  const [responses, setResponses] = React.useState<
    DataFieldResponseDataInput[]
  >([]);
  const [responseId, setResponseId] = React.useState<string | null>(
    customFormResponseId ?? null
  );
  const [fieldErrors, setFieldErrors] = React.useState<
    FrankBackendTypes.CustomFormFieldError[]
  >([]);
  const [
    formError,
    setFormError,
  ] = React.useState<FrankBackendTypes.SubmitCustomFormError | null>(null);

  const disabled =
    formResponseData?.customFormResponse?.submittedAt || viewOnly;

  const hasRequiredFields = React.useMemo(() => {
    if (!data) {
      return false;
    }
    return data.customForm.customFormFields.find((f) => f.required);
  }, [data]);

  React.useEffect(() => {
    if (formResponseData && formResponseData.customFormResponse) {
      setResponses(
        formResponseData.customFormResponse.dataFieldResponses.map((r) =>
          omit(r, "__typename", "id")
        )
      );
    }
  }, [formResponseData]);

  React.useEffect(() => {
    if (!createOrUpdateData) {
      return;
    }
    setFieldErrors(createOrUpdateData.createOrUpdateFormResponse.fieldErrors);
    setFormError(createOrUpdateData.createOrUpdateFormResponse.error);
  }, [createOrUpdateData]);

  const createOrUpdate = React.useCallback(
    async (submittedAt?: Date) => {
      if (submittedAt) {
        const confirm = await openConfirmationModal({
          title: t("confirmFormSubmit"),
          bodyText: t("confirmFormSubmitWarning"),
          actionText: t("confirm"),
          cancel: modalBack,
          cancelText: t("edit"),
        });
        if (!confirm) {
          return;
        }
      }
      try {
        // Try to save any artifacts in the form (e.g. tables) before submitting the form itself
        const dependencies = await saveEmitter.current.trigger();
        if (dependencies) {
          const result = await invokeCreateOrUpdateCustomFormResponse({
            variables: {
              input: {
                customFormId,
                responses,
                customFormResponseId: responseId,
                submittedAt,
                groupId,
              },
            },
          });
          const success =
            result.data.createOrUpdateFormResponse.status ===
            FrankBackendTypes.SubmitCustomFormStatus.Success;
          if (success && onComplete) {
            setResponseId(
              result.data.createOrUpdateFormResponse.responseData.id
            );
            onComplete(submittedAt);
          }
          if (success)
            toaster.addToast({
              children: t("success"),
              intent: Intent.SUCCESS,
            });
          else {
            toaster.addToast({
              children: t("pleaseResolveErrors"),
              intent: Intent.FAILURE,
            });
          }
        } else {
          toaster.addToast({
            children: t("pleaseResolveErrors"),
            intent: Intent.FAILURE,
          });
        }
      } catch (e) {
        sentryClient.captureException(e);
        toaster.addToast({
          children: t("somethingWentWrong"),
          intent: Intent.FAILURE,
        });
      }
    },
    [
      invokeCreateOrUpdateCustomFormResponse,
      customFormId,
      responses,
      responseId,
      setResponseId,
    ]
  );

  const updateResponse = React.useCallback(
    (val: DataFieldResponseDataInput) => {
      let exists = false;
      const updatedResponses = responses.reduce((prev, current) => {
        if (current.dataFieldId === val.dataFieldId) {
          exists = true;
          // Only add it to our responses if the new value has data
          if (val.data) {
            // Update the data with our new data
            prev.push({ ...current, data: val.data });
          } else {
            // skip it, we don't want it if it is empty
            return prev;
          }
        } else {
          // This isn't the field we're looking for; just add it back in as is
          prev.push(current);
        }
        return prev;
      }, []);
      // If we didn't find the response in our existing, add it
      if (!exists && val.data) {
        updatedResponses.push(val);
      }
      setResponses(updatedResponses);
    },
    [responses, setResponses]
  );
  const sections = React.useMemo(
    () =>
      data
        ? data.customForm.customFormSections
            .map((section) => ({
              ...section,
              customFormFields: data.customForm.customFormFields
                .filter((field) => field.customFormSectionId === section.id)
                .sort((a, b) => a.order - b.order),
            }))
            .sort((a, b) => a.order - b.order)
        : [],
    [data]
  );

  const [invokeDeleteFormResponseDraft] = useDeleteFormResponseDraftMutation();

  const deleteResponseDraft = React.useCallback(async () => {
    const confirm = await openConfirmationModal({
      title: t("confirmFormDraftDelete"),
      bodyText: t("confirmFormDraftDelete"),
      actionText: t("confirm"),
      cancel: modalBack,
      cancelText: t("cancel"),
    });
    if (!confirm) {
      return;
    }
    await invokeDeleteFormResponseDraft({
      variables: { formResponseId: responseId },
    });
    if (onComplete) {
      onComplete();
    }
  }, [invokeDeleteFormResponseDraft, close, openConfirmationModal]);

  const isDraft = formResponseData?.customFormResponse?.submittedAt === null;

  if (loading || (formResponseLoading && customFormResponseId)) {
    return (
      <Delay delay={500}>
        <div className="space-y-4">
          <LargePulseCard />
          <LargePulseCard />
        </div>
      </Delay>
    );
  }
  if (error || formResponseError) {
    console.log(error);
    return (
      <InlineAlert
        title={t("couldNotLoadForm")}
        intent={Intent.FAILURE}
        actions={
          <Button size={Sizes.SM} onClick={() => refetch()}>
            {t("reload")}
          </Button>
        }
      />
    );
  }

  return (
    <CustomFormConfigProvider groupId={groupId} saveEmitterRef={saveEmitter}>
      <div className="pb-8">
        <div className="t-title-4 plus">
          {data.customForm.title}
          {isDraft && ` ${t("formDraftTag")}`}
        </div>
        <div className="t-small">{data.customForm.description}</div>
        {formResponseData?.customFormResponse?.notes && (
          <div className="t-small mt-4">
            <div className="underline pb-2">Notes:</div>
            {formResponseData.customFormResponse?.notes}
          </div>
        )}
      </div>
      {hasRequiredFields && (
        <div className="t-mini plus text-right pb-2">
          {t("requiredFieldsInstruction")}
        </div>
      )}
      {sections.map((section) => (
        <CustomFormSectionResponse
          fieldErrors={fieldErrors}
          key={section.id}
          section={section}
          fields={section.customFormFields}
          onUpdateResponse={updateResponse}
          responses={responses}
          disabled={disabled}
        />
      ))}
      {!disabled && (
        <div className="flex flex-row space-x-4 items-end">
          <Button
            onClick={() => createOrUpdate(new Date())}
            buttonStyle="brand"
          >
            {t("submit")}
          </Button>
          <Button
            onClick={() => createOrUpdate()}
            buttonStyle="secondary"
            disabled={responses.length === 0}
          >
            {t("saveProgress")}
          </Button>
          {close && (
            <Button onClick={close} buttonStyle="secondary">
              {t("cancel")}
            </Button>
          )}
          {isDraft && responseId && (
            <Button buttonStyle="secondary" onClick={deleteResponseDraft}>
              {t("deleteDraft")}
            </Button>
          )}
          {formError && (
            <div className="text-frank-red-400 t-mini pt-3">
              {humanReadableError(formError, t)}
            </div>
          )}
        </div>
      )}
    </CustomFormConfigProvider>
  );
}

import * as React from "react";
import { IntersectionOptions, useInView } from "react-intersection-observer";

const Crossroads = ({
  onEntering,
  onLeaving,
  id,
  options = {},
}: {
  onLeaving?: () => void;
  onEntering: () => void;
  id?: string;
  options?: IntersectionOptions;
}) => {
  const { ref, inView } = useInView(options);

  React.useEffect(() => {
    if (inView) {
      onEntering();
    } else if (onLeaving) {
      onLeaving();
    }
  }, [inView, onEntering, onLeaving]);

  return <div style={{ height: "1px", width: "1px" }} ref={ref} id={id} />;
};

export default Crossroads;

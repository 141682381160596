import { ApolloProvider } from "@apollo/client";
import "typeface-lora";
import * as React from "react";

import * as ReactDOM from "react-dom";
import "@get-frank-eng/design-system/dist/tailwind.css";
import "@get-frank-eng/design-system/dist/colorVars.css";
import "@get-frank-eng/design-system/dist/typography.css";
import "@get-frank-eng/design-system/dist/mentions.css";
import "@get-frank-eng/design-system/dist/coworkerTable.css";
import "@get-frank-eng/design-system/dist/segmentedControl.css";
import "@get-frank-eng/design-system/dist/upploadCustomization.css";
import { BrowserRouter } from "react-router-dom";
import client from "./gqlClient";
import Routes from "./Routes";
import { AuthProvider } from "./Auth/AuthState";
import { LocationHistoryProvider } from "./hooks/useLocationHistory";
import FullPageError from "./FullPageError";
import {
  LayoutProvider,
  ResponsiveProvider,
  ToastProvider,
  useBreakpoint,
} from "@get-frank-eng/design-system";
import { DraftProvider, ChannelsProvider } from "@get-frank-eng/chat-client";
import configureChatBackend from "./Chat/util/configure";
import { NavigationStateProvider } from "./Navigation/hooks/useNavigationState";
import SentryClient from "./sentryClient";
import * as Sentry from "@sentry/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { InstallPromptProvider } from "./providers/InstallPromptProvider";
import { ServiceWorkerProvider } from "./hooks/useServiceWorker";
import { useApocalypseHandler } from "./hooks/useApocalypseHandler";
import "./i18n/config";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/config";
SentryClient.init();

configureChatBackend();

const App = () => {
  const screenSize = useBreakpoint();
  useApocalypseHandler();

  return (
    // @ts-ignore
    <ServiceWorkerProvider>
      <BrowserRouter>
        <InstallPromptProvider>
          <Sentry.ErrorBoundary fallback={<FullPageError />}>
            <LocationHistoryProvider>
              <ApolloProvider client={client}>
                <AuthProvider>
                  <NavigationStateProvider>
                    <I18nextProvider i18n={i18n}>
                      <ResponsiveProvider screenSize={screenSize}>
                        <LayoutProvider>
                          <ToastProvider>
                            <ChannelsProvider>
                              <DraftProvider>
                                <Routes />
                              </DraftProvider>
                            </ChannelsProvider>
                          </ToastProvider>
                        </LayoutProvider>
                      </ResponsiveProvider>
                    </I18nextProvider>
                  </NavigationStateProvider>
                </AuthProvider>
              </ApolloProvider>
            </LocationHistoryProvider>
          </Sentry.ErrorBoundary>
        </InstallPromptProvider>
      </BrowserRouter>
    </ServiceWorkerProvider>
  );
};

const root = document.getElementById("root") as HTMLElement;
ReactDOM.render(<App />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

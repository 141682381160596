import * as React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router";
import Loading from "../components/Loading";
import useCampaignDetailQuery from "./dataAccess/queries/useCampaignDetailQuery";
import Channel from "../Chat";
import {
  IconLinkButton,
  InlineAlert,
  Intent,
  ResponsiveProvider,
  RightSideBarClosedOnMobile,
  RightSideBarOpenOnMobile,
  RightSidePanel,
  ScreenSizes,
  Sizes,
  TopBar,
} from "@get-frank-eng/design-system";
import DemandLetter from "./pages/Demand";
import { FrankBackendTypes } from "frank-types";
import Actions from "./pages/Actions";
import { CampaignActivity } from "./pages/Activity";
import CampaignInfo from "./pages/Info";
import Signatures from "./pages/Signatures";
import { CampaignTimeline } from "./pages/Timeline";
import CampaignIndex from "./pages";
import Polls from "../Channel/Polls";
import ChannelAttachments from "../Channel/ChannelAttachments";
import { useChannelIds } from "../Navigation/hooks/useNavigationState";
import { RightSideRoute } from "../RouteComponents";
import { useTranslation } from "react-i18next";

export default function CampaignDefaultRouterSwitch() {
  const { t } = useTranslation();

  const { campaignId } = useParams<{ campaignId: string }>();

  const {
    campaign,
    errorCampaign,
    loadingCampaign,
    refetchCampaign,
  } = useCampaignDetailQuery({
    campaignId,
  });
  const { url } = useRouteMatch();
  const channelId = useChannelIds(campaignId);

  if (loadingCampaign || !campaign) {
    return <Loading />;
  }

  return (
    <>
      <Switch>
        <Route path={`${url}/demand`}>
          {loadingCampaign ? (
            <Loading />
          ) : (
            <>
              {errorCampaign ? (
                <InlineAlert
                  title={t("errorLoadingPetition")}
                  intent={Intent.FAILURE}
                />
              ) : (
                <DemandLetter
                  campaign={campaign}
                  refetchCampaign={refetchCampaign}
                />
              )}
            </>
          )}
        </Route>
        <Route>
          {channelId || campaign.channelId ? (
            <Channel
              title={campaign?.title}
              key={channelId || campaign.channelId}
              channelId={channelId || campaign.channelId}
            />
          ) : (
            <Loading />
          )}
          <RightSidePanel>
            {loadingCampaign ? (
              <Loading />
            ) : (
              <>
                {errorCampaign ? (
                  <InlineAlert
                    title={t("errorLoadingPetition")}
                    intent={Intent.FAILURE}
                  />
                ) : (
                  <ResponsiveProvider screenSize={ScreenSizes.XS}>
                    <CampaignRightSide
                      campaign={campaign}
                      refetchCampaign={refetchCampaign}
                    />
                  </ResponsiveProvider>
                )}
              </>
            )}
          </RightSidePanel>
        </Route>
      </Switch>
    </>
  );
}

const CampaignRightSide = ({
  campaign,
  refetchCampaign,
}: {
  campaign: FrankBackendTypes.Campaign;
  refetchCampaign: () => void;
}) => {
  const { t } = useTranslation();

  const { url } = useRouteMatch();

  const back = (
    <IconLinkButton
      to={`../${campaign.id}`}
      icon="arrow_back"
      size={Sizes.XL}
      buttonStyle="minimal"
    />
  );

  return (
    <Switch>
      <RightSideRoute path={`${url}/files`}>
        <ChannelAttachments campaignId={campaign.id} />
      </RightSideRoute>
      <RightSideRoute path={`${url}/timeline`}>
        <div className="space-y-2 bg-canvas-700 flex-grow">
          <TopBar title={t("timeline")} left={back} />
          <div className="p-6">
            <CampaignTimeline campaign={campaign} />
          </div>
        </div>
      </RightSideRoute>
      <RightSideRoute path={`${url}/info`}>
        <div className="bg-canvas-700 flex-grow">
          <TopBar title={t("info")} left={back} />
          <div className="p-6">
            <CampaignInfo campaign={campaign} />
          </div>
        </div>
      </RightSideRoute>
      <RightSideRoute path={`${url}/activity`}>
        <div className="bg-canvas-700 flex-grow">
          <TopBar title={t("activity")} left={back} />
          <div className="px-6">
            <CampaignActivity campaignId={campaign.id} />
          </div>
        </div>
      </RightSideRoute>
      <RightSideRoute path={`${url}/signatures`}>
        <div className="bg-canvas-700 flex-grow">
          <TopBar
            title={t("signatureWithCount", {
              count: campaign.signatures.total,
            })}
            left={back}
          />
          <div className="p-6">
            <Signatures campaignId={campaign.id} />
          </div>
        </div>
      </RightSideRoute>
      <RightSideRoute path={`${url}/polls`}>
        <div className="bg-canvas-700 flex-grow">
          <Polls channelId={campaign.channelId} campaignId={campaign.id} />
        </div>
      </RightSideRoute>
      <RightSideRoute path={`${url}/actions`}>
        <div className="bg-canvas-700 flex-grow">
          <Actions
            campaignId={campaign.id}
            actions={campaign.actions}
            relationshipToMe={campaign.relationshipToMe}
            refetchCampaign={refetchCampaign}
          />
        </div>
      </RightSideRoute>

      <Route
        path={`${url}/thread/:threadId`}
        render={({ match }) => {
          return (
            <>
              <RightSideBarOpenOnMobile />
              <Channel
                key={match.params.threadId}
                channelId={campaign.channelId}
                parentId={match.params.threadId}
              />
            </>
          );
        }}
      />

      <Route path={`${url}/`}>
        <RightSideBarClosedOnMobile />
        <CampaignIndex campaign={campaign} refetchCampaign={refetchCampaign} />
      </Route>
    </Switch>
  );
};

import {
  Intent,
  ScreenSizes,
  useBreakpoint,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";
import InviteLinkInput from "../../Invites/components/InviteLinkInput";
import copyText from "../../utils/copyText";

export const CreateLinkToAuthCardModal = ({ url }: { url }) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const isMobile = useBreakpoint() <= ScreenSizes.SM;
  const supportsShare = !!navigator.share;

  return (
    <InviteLinkInput
      loading={false}
      type="button"
      onClickHandler={async () => {
        await copyText(url);
        if (supportsShare && isMobile) {
          try {
            await navigator.share({
              title: t("signYourAuthorizationCard"),
              url,
            });
          } catch (e) {
            console.error("Error using native share", e);
          }
        }
        toaster.addToast({
          intent: Intent.SUCCESS,
          children:
            isMobile && supportsShare
              ? t("success")
              : t("copiedToYourClipboard"),
        });
      }}
      url={url}
      showCopyButton
    />
  );
};

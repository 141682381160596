import React from "react";
import { FormAction } from "frank-types/dist/frank-backend-types";
import { useTranslation } from "react-i18next";
import CustomFormResponseEdit from "../../../CustomForm/CustomFormResponseEdit";
import { useModals } from "../../../Modals";
import { convertJsonDateToString } from "../../util";
import ActionListItem from "./ActionListItem";

const FormActionItem = ({
  form,
  onUpdate,
}: {
  form: FormAction;
  onUpdate: () => Promise<void>;
}) => {
  const { setModal, closeModal } = useModals();
  const { t } = useTranslation();
  const onComplete = () => {
    closeModal();
    onUpdate();
  };
  const defaultAction = () =>
    setModal({
      type: "customFormModal",
      props: {
        body: (
          <CustomFormResponseEdit
            customFormId={form.id}
            onComplete={onComplete}
          />
        ),
        title: `${t("fillOutCustomForm")} ${form.title}`,
      },
    });
  const actions = [
    {
      label: t("start"),
      action: defaultAction,
    },
  ];
  return (
    <ActionListItem
      key={form.id}
      title={form.title}
      subheading={`${t("dateAssigned")}: ${convertJsonDateToString(
        form.createdAt
      )}`}
      actions={actions}
      defaultAction={defaultAction}
    />
  );
};

export default FormActionItem;

import { Tooltip } from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function PendingMemberSection({
  title,
  complete,
  children,
  tooltip,
  showWarning,
}: {
  title: string;
  complete: boolean;
  children: any;
  tooltip?: any;
  showWarning?: boolean;
}) {
  const { t } = useTranslation();
  const getColor = (isComplete, shouldShowWarning) => {
    if (shouldShowWarning) {
      return "text-frank-red-300";
    }
    if (isComplete) {
      return "text-frank-green-300";
    }
    return "text-frank-gold-300";
  };

  const getText = (isComplete, shouldShowWarning) => {
    if (shouldShowWarning) {
      return t("warning");
    }
    if (isComplete) {
      return t("verified");
    }
    return t("notVerified");
  };

  return (
    <section className="rounded border my-3">
      <header className="p-4 border-b flex flex-row items-center">
        <div>
          <h1 className="t-small plus">{title}</h1>
          <Tooltip hideArrow={false} tooltip={tooltip} trigger="right-click">
            <div className={`t-small ${getColor(complete, showWarning)}`}>
              <div className="t-mini">{getText(complete, showWarning)}</div>
            </div>
          </Tooltip>
        </div>
      </header>
      <div>{children}</div>
    </section>
  );
}

import * as React from "react";
import {
  Input,
  Button,
  EmptyState,
  Sizes,
  PureMenuItem,
  InlineAlert,
  Intent,
  PureMenu,
  FormGroup,
  IconButton,
} from "@get-frank-eng/design-system";
import { StepLabel, StepTitle } from "../../SSO/components";
import gql from "graphql-tag";
import Downshift, { PropGetters } from "downshift";
import { useQuery } from "@apollo/client";
import { FrankBackendTypes } from "../../../../frank-types/dist";
import debounce from "lodash/debounce";
import { titleize } from "../../utils/string";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import useRequestInvite from "../dataAccess/mutations/useRequestInvite";
import { useCallback } from "react";
import { useEffect } from "react";
import { MinimalFormLayout } from "../layout";
import useQueryParams from "../../hooks/useQueryParams";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import useSignUp from "../../SSO/dataAccess/mutations/useSignUp";
import * as analytics from "../../analytics";
import { EventTypes } from "frank-types";

const query = gql`
  query Search($queryText: String!) {
    companies(queryText: $queryText) {
      id
      searchFeedback
      name
      locality
      domain
    }
  }
`;

function preventResetOnBlur(state, changes) {
  if (changes.type === Downshift.stateChangeTypes.blurInput) {
    return {}; // no-changes
  }
  return changes;
}

type FormFields = {
  email?: string;
  companyId: string;
  onboardingId?: string;
  companyName: string;
};

const RequestInvite = () => {
  const [currInputValue, setCurrInputValue] = React.useState("");
  const debounceSetCurrInputValue = React.useRef(null);

  const qs = useQueryParams();

  const email = useMemo(() => {
    return qs.get("email");
  }, [qs]);

  if (!debounceSetCurrInputValue.current) {
    debounceSetCurrInputValue.current = debounce(setCurrInputValue, 150);
  }

  const {
    register,
    setValue,
    handleSubmit,
    errors,
    getValues,
  } = useForm<FormFields>();

  const { requestInvite, loading, error, called } = useRequestInvite();
  const history = useHistory();

  const { signUp, loading: signUpLoading, error: signUpError } = useSignUp();

  const formValid = () => {
    const formValues = getValues();

    if (!formValues.email && !email) {
      return false;
    }

    if (!formValues.companyId) {
      return false;
    }

    return true;
  };

  // const createGroupInstead = useCallback(async () => {
  //   const formValues = getValues();
  //   if (qs.get("email") || formValues.email) {
  //     await signUp(qs.get("email") || formValues.email);
  //     history.push("/create-group/email-confirmation");
  //   } else {
  //     history.push("/create-group/email");
  //   }
  // }, [qs, history, getValues]);

  const submit = useCallback(
    async (fd: FormFields) => {
      await requestInvite({
        companyId: fd.companyId,
        email: fd.email,
        companyName: fd.companyName,
      });
      analytics.capture(EventTypes.AnalyticsEvents.REQUEST_INVITATION);
    },
    [requestInvite]
  );

  useEffect(() => {
    register(
      {
        name: "companyId",
      },
      { required: true }
    );
    register(
      {
        name: "companyName",
      },
      { required: true }
    );
  }, [register]);

  const confirmation = (
    <>
      <div className="space-y-4">
        <div className="text-center">
          <i style={{ fontSize: "75px" }} className="material-icons-outlined">
            thumb_up
          </i>
          <StepTitle>Request submitted</StepTitle>
        </div>
        <p className="text-canvas-400 t-regular mb-3">
          Thanks for your interest in Frank. If there is a Frank group at the
          workplace you selected, we will notify them that new members are
          interested in joining. You will be notified if this happens.
        </p>
        <p className="text-canvas-400 t-regular">
          In the meantime, we suggest you talk with your trusted coworkers to
          receive an invitation directly. It is possible your workplace has no
          group on Frank, in which case, you can{" "}
          <Link
            to="/create-group/email"
            className="text-brand-400 cursor-pointer"
          >
            start your own
          </Link>
          .
        </p>
      </div>
    </>
  );

  const form = (
    <>
      <div className="space-y-3">
        <div className="space-y-1">
          <StepLabel>Join a group</StepLabel>
          <StepTitle>Request an invitation</StepTitle>
        </div>
        <p className="text-canvas-400 t-regular">
          All groups are invite-only. We suggest creating a group or talking to
          your coworkers to request an invitation. Alternatively, you can apply
          for an invitation here. If a group expresses interest in receiving new
          members, we'll notify you.
        </p>
      </div>

      {error && (
        <InlineAlert title="Error requesting invite">
          An error occurred while you requested your invite.
        </InlineAlert>
      )}

      <form onSubmit={handleSubmit(submit)} className="space-y-3">
        <FormGroup
          className={`${email ? "hidden" : ""}`}
          name="email"
          id="email"
          label="Personal email"
        >
          <Input
            register={register}
            value={email}
            errorText={
              errors.email && (
                <>
                  {errors.email.type === "pattern" && "Invalid email"}
                  {errors.email.type === "required" && "Email required"}
                </>
              )
            }
            size={Sizes.LG}
            registerArgs={{ required: true, pattern: /^\S+@\S+$/i }}
            type="email"
          />
        </FormGroup>
        <Downshift<FrankBackendTypes.CompanySearchResult>
          onInputValueChange={debounceSetCurrInputValue.current}
          // onChange={(selectedItem) => alert(selectedItem.name)}
          itemToString={(item) => (item ? titleize(item.name) : "")}
          stateReducer={preventResetOnBlur}
          onChange={(company) => {
            if (company) {
              setValue("companyId", company.id);
              setValue("companyName", company.name);
            } else {
              setValue("companyId", null);
              setValue("companyName", null);
            }
          }}
        >
          {({
            inputValue,
            getInputProps,
            getLabelProps,
            getMenuProps,
            getItemProps,
            getToggleButtonProps,
            selectedItem,
            highlightedIndex,
            isOpen,
            clearSelection,
          }) => {
            return (
              <div className="relative">
                <FormGroup label="Employer name" id="company" name="company">
                  <div className="relative">
                    <Input
                      disabled={!!selectedItem}
                      size={Sizes.LG}
                      errorText={
                        errors.companyId && (
                          <>
                            {errors.companyId.type === "required" &&
                              "Company required"}
                          </>
                        )
                      }
                      {...getInputProps({
                        isOpen,
                        placeholder: "Search by name, location, or website",
                      })}
                    />
                    {selectedItem && (
                      <div className="absolute right-0 inset-y-0 flex items-center justify-center p-2">
                        <IconButton
                          buttonStyle="minimal"
                          icon="close"
                          onClick={() => clearSelection()}
                          className=""
                        />
                      </div>
                    )}
                  </div>
                </FormGroup>
                {isOpen && (
                  <>
                    {currInputValue && currInputValue.length > 3 && (
                      <SearchResults
                        getItemProps={getItemProps}
                        getMenuProps={getMenuProps}
                        highlightedIndex={highlightedIndex}
                        selectedItem={selectedItem}
                        queryText={currInputValue}
                        email={email}
                      />
                    )}
                  </>
                )}
              </div>
            );
          }}
        </Downshift>

        <div className="t-small">
          Frank doesn’t share any information with companies ever. Frank groups
          are private and not affiliated with employers. A company name
          appearing in this search does not indicate employees are using Frank.
        </div>
        <div className="flex space-x-2 py-6">
          <Button
            loading={loading}
            buttonStyle="brand"
            type="submit"
            disabled={!formValid()}
          >
            Request invitation
          </Button>
          {/* <Button
            buttonStyle="outline"
            onClick={createGroupInstead}
            loading={signUpLoading}
          >
            Create new group
          </Button> */}
        </div>
      </form>
    </>
  );

  return <MinimalFormLayout>{called ? confirmation : form}</MinimalFormLayout>;
};

function SearchResults({
  queryText,
  highlightedIndex,
  getItemProps,
  getMenuProps,
  selectedItem,
  email,
}: {
  selectedItem: FrankBackendTypes.CompanySearchResult;
  highlightedIndex: number;
  getItemProps: PropGetters<FrankBackendTypes.CompanySearchResult>["getItemProps"];
  getMenuProps: PropGetters<FrankBackendTypes.CompanySearchResult>["getMenuProps"];
  queryText: string;
  email?: string;
}) {
  const { data, loading, error } = useQuery<
    Pick<FrankBackendTypes.Query, "companies">,
    FrankBackendTypes.QueryCompaniesArgs
  >(query, {
    variables: { queryText },
  });
  const history = useHistory();

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <InlineAlert title="Error loading companies" intent={Intent.FAILURE}>
        Something went wrong while loading companies. Please try again later
      </InlineAlert>
    );
  }

  if (!loading && !error && data.companies.length === 0) {
    return (
      <EmptyState
        title="We're sorry"
        buttonLabel={`Tell us about ${queryText}`}
        onClick={() => {
          const url = email
            ? `/join-group/missing-company?email=${email}`
            : "/join-group/missing-company";
          history.push(url, { queryText });
        }}
      >
        Your employer doesn't appear in the public database we use, but we'd
        like to add it to make our data better. Help us by anonymously adding
        your employer.
      </EmptyState>
    );
  }

  return (
    <PureMenu {...getMenuProps()} className="list-none  space-y-1 p-2 w-full">
      {data.companies.map((result, index) => (
        <PureMenuItem
          key={result.id}
          {...getItemProps({
            item: result,
            index,
            /* @ts-ignore */
            isActive: highlightedIndex === index,
            isSelected: selectedItem === result,
          })}
        >
          <div
            className={`space-y-1 ${
              highlightedIndex === index ? "bg-canvas-600" : ""
            } px-2 py-1 rounded`}
          >
            <div
              className="search-feedback t-regular plus"
              dangerouslySetInnerHTML={{ __html: result.searchFeedback }}
            />
            <div className="t-mini text-canvas-400">
              {titleize(result.locality)}
            </div>
            <div className="t-mini text-canvas-400">{result.domain}</div>
          </div>
        </PureMenuItem>
      ))}
    </PureMenu>
  );
}

export default RequestInvite;

import * as React from "react";
import { useTranslation } from "react-i18next";

const svg = (
  <svg
    width="152"
    height="120"
    viewBox="0 0 152 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="6" fill="#3A3937" />
    <rect x="40" width="32" height="4" rx="2" fill="#3A3937" />
    <rect x="40" y="8" width="112" height="4" rx="2" fill="#3A3937" />
    <rect x="40" y="16" width="112" height="4" rx="2" fill="#3A3937" />
    <rect x="40" y="24" width="112" height="4" rx="2" fill="#3A3937" />
    <rect y="44" width="32" height="32" rx="6" fill="#3A3937" />
    <rect x="40" y="44" width="32" height="4" rx="2" fill="#3A3937" />
    <rect x="40" y="52" width="112" height="4" rx="2" fill="#3A3937" />
    <rect x="40" y="60" width="112" height="4" rx="2" fill="#3A3937" />
    <rect x="40" y="68" width="112" height="4" rx="2" fill="#3A3937" />
    <rect y="88" width="32" height="32" rx="6" fill="#3A3937" />
    <rect x="40" y="88" width="32" height="4" rx="2" fill="#3A3937" />
    <rect x="40" y="96" width="112" height="4" rx="2" fill="#3A3937" />
    <rect x="40" y="104" width="112" height="4" rx="2" fill="#3A3937" />
    <rect x="40" y="112" width="112" height="4" rx="2" fill="#3A3937" />
    <rect width="152" height="120" fill="url(#paint0_linear)" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="76"
        y1="0"
        x2="76"
        y2="120"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#191918" stop-opacity="0" />
        <stop offset="1" stop-color="#191918" />
      </linearGradient>
    </defs>
  </svg>
);

const ChatEmptyState = ({
  isAnonymous = false,
  areMessagesHiddenBeforeMembership = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex-1 flex flex-col items-center justify-center">
      <div className="max-w-md text-center flex flex-col items-center">
        {svg}
        <h1 className="t-regular plus mt-4">
          {isAnonymous
            ? t("thisIsTheBeginningOfANewAnonymous")
            : t("thisIsTheBeginningOfANewDiscussio")}
        </h1>
        <p className="t-small text-canvas-400">
          {isAnonymous
            ? t("messagesAndPollsPostedInThisChanne")
            : t("postAMessageOrACreateAPollToGet")}
          {areMessagesHiddenBeforeMembership &&
            t("inThisChannelYouOnlySeeMessagesS")}
        </p>
      </div>
    </div>
  );
};

export default ChatEmptyState;

import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const UPDATE_GROUP = gql`
  mutation UpdateGroupDetails($input: UpdateGroupDTO!) {
    updateGroupDetails(input: $input) {
      id
    }
  }
`;

export default function useUpdateGroupDetails() {
  const [invokeUpdateGroupDetails, { loading, called, error }] = useMutation<
    any,
    FrankBackendTypes.MutationUpdateGroupDetailsArgs
  >(UPDATE_GROUP);

  const updateGroupDetails = React.useCallback(
    async ({
      name,
      size,
      industry,
      attachmentId,
      companyName,
      welcomeMessage,
      inviteMessage,
      emailSenderName,
      leadOrganizerId,
      groupId,
      allowAnonymousChannel,
      allowFromFrankChannel,
    }: {
      name?: string;
      size?: number;
      industry?: string;
      attachmentId?: string;
      companyName?: string;
      welcomeMessage?: string;
      inviteMessage?: string;
      emailSenderName?: string;
      leadOrganizerId?: string;
      groupId: string;
      allowAnonymousChannel?: boolean;
      allowFromFrankChannel?: boolean;
    }) => {
      await invokeUpdateGroupDetails({
        variables: {
          input: {
            name,
            size,
            industry,
            logoAttachmentId: attachmentId,
            companyName,
            welcomeMessage,
            inviteMessage,
            emailSenderName,
            leadOrganizerId,
            groupId,
            allowAnonymousChannel,
            allowFromFrankChannel,
          },
        },
      });
    },
    [invokeUpdateGroupDetails]
  );

  return {
    updateGroupDetails,
    loading,
    called,
    error,
  };
}

import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation RemoveScheduledTask($scheduledTaskId: ID!) {
    removeScheduledTask(scheduledTaskId: $scheduledTaskId) {
      nothing
    }
  }
`;

export default function useRemoveScheduledTask() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "removeScheduledTask">,
    FrankBackendTypes.MutationRemoveScheduledTaskArgs
  >(mutation);

  const removeScheduledTask = React.useCallback(
    async (scheduledTaskId: string) => {
      await invokeMutation({
        variables: { scheduledTaskId },
      });
    },
    [invokeMutation]
  );

  return {
    removeScheduledTask,
    loading,
    error,
    called,
  };
}

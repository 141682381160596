import * as React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import CoworkerCustomFormResponses from "../components/CustomForms/CoworkerCustomFormResponses";
import CustomFormList from "../components/CustomForms/CustomFormList";
import { CustomFormResponseEmbed } from "../components/CustomForms/CustomFormResponseEmbed";
import ManageCustomForm from "../components/CustomForms/ManageCustomForm";

export default function CustomForms(): JSX.Element {
  const { url } = useRouteMatch();
  return (
    <>
      <Route exact path={`${url}/embed/:id/:responseId?`}>
        <CustomFormResponseEmbed />
      </Route>
      <Route exact path={`${url}/:groupId?`}>
        <CustomFormList />
      </Route>
      <Route exact path={`${url}/:groupId/:id`}>
        <ManageCustomForm />
      </Route>
      <Route exact path={`${url}/:groupId/:id/:coworkerId`}>
        <CoworkerCustomFormResponses />
      </Route>
    </>
  );
}

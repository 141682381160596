import { gql, useMutation } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const mutation = gql`
  mutation RemoveFromChannel($input: RemoveFromChannelInput!) {
    removeFromChannel(input: $input) {
      nothing
    }
  }
`;

export default function useRemoveMember() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "removeFromChannel">,
    FrankBackendTypes.MutationRemoveFromChannelArgs
  >(mutation);

  const removeFromChannel = React.useCallback(
    async (input: FrankBackendTypes.RemoveFromChannelInput) => {
      await invokeMutation({
        variables: { input },
      });
    },
    [invokeMutation]
  );

  return {
    removeFromChannel,
    loading,
    error,
    called,
  };
}

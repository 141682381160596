import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { pendingMemberCoworkersRestriction } from "../filters";
import {
  distrustedByFragment,
  trustedByFragment,
  userFragment,
} from "../fragments";
import { defaultPendingMemberOrder } from "../orders";

export const pendingMemberDataFragment = gql`
  fragment PendingMemberData on Coworker {
    id
    status
    name
    employmentType
    netTrust
    totalTrustUpvotes
    totalTrustDownvotes
    canInvite
    email
    trustJudgementCount
    onboardingStartedTimeAgo
    yourTrustLevel
    canVoteOnTrust
    coworkerInvite {
      id
      inviteType
      requiresPassphrase
      inviter {
        id
        shortName
        name
      }
    }
    onboardingIssues {
      id
      issueList
      authenticityIssues {
        type
      }
      trustworthinessIssues {
        type
      }
      eligibilityIssues {
        type
      }
      shouldAllowTrust
      authenticityIndependentlyVerified
    }
  }
`;

export const pendingMemberDetailFragment = gql`
  fragment PendingMemberDetailFragment on PaginatedCoworker {
    hasNext
    objects {
      ...PendingMemberData
      ...TrustedByFragment
      ...DistrustedByFragment
      ...UserFragment
    }
    total
  }
  ${pendingMemberDataFragment}
  ${userFragment}
  ${trustedByFragment}
  ${distrustedByFragment}
`;

const QUERY = gql`
  query PendingCoworkersList(
    $pagination: PaginationArgumentDTO!
    $filter: CoworkerFilterDTO!
    $order: CoworkerOrderArgumentDTO
    $secondOrder: CoworkerOrderArgumentDTO
  ) {
    coworkers(
      pagination: $pagination
      filter: $filter
      order: $order
      secondOrder: $secondOrder
    ) {
      ...PendingMemberDetailFragment
    }
  }
  ${pendingMemberDetailFragment}
`;

export default function usePendingMembersListData({
  perPage,
}: {
  perPage: number;
}) {
  const { loading, data, refetch, error, fetchMore } = useQuery<
    {
      coworkers: FrankBackendTypes.PaginatedCoworker;
    },
    FrankBackendTypes.QueryCoworkersArgs
  >(QUERY, {
    fetchPolicy: "network-only",
    variables: {
      pagination: { page: 0, perPage },
      filter: pendingMemberCoworkersRestriction,
      order: defaultPendingMemberOrder.order,
      secondOrder: defaultPendingMemberOrder.secondOrder,
    },
  });

  const fetchMorePendingMembers = React.useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          perPage,
          page: Math.ceil(data?.coworkers.objects.length / perPage),
        },
      },
    });
  }, [fetchMore, perPage, data]);

  return {
    pendingMemberData: data?.coworkers,
    loadingPendingMembers: loading,
    errorPendingMembers: error,
    refetchPendingMembers: refetch,
    fetchMorePendingMembers,
  };
}

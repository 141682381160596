import { AuthCardAction } from "frank-types/dist/frank-backend-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../../../Auth/useCurrentUserData";
import AuthorizationCardForm from "../../../../Partner/components/AuthorizationCardForm";
import PrimaryActionItem from "./PrimaryActionItem";

const AuthCardPrimaryActionEmbed = ({
  authCard,
  onUpdate,
}: {
  authCard: AuthCardAction;
  onUpdate: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { currentGroup } = useCurrentUserData();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const cancel = React.useCallback(() => {
    setIsExpanded(false);
  }, [setIsExpanded]);
  return (
    <PrimaryActionItem
      expandable
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <AuthorizationCardForm
        employer={currentGroup.company}
        authorizationLanguage={authCard.language}
        closeModal={cancel}
        onSubmit={onUpdate}
      />
    </PrimaryActionItem>
  );
};

export default AuthCardPrimaryActionEmbed;

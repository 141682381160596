import * as React from "react";
import SecurityFeatureRow from "../components/SecurityFeatureRow";
import { DateIntervals } from "../../utils/Date";
import { FormGroup, Input, Nudge, Select } from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";

const BulkInviteAdvancedOptions = ({
  register,
  errors,
  canUpdateVouchSettings,
  setValue,
}: {
  register: () => any;
  errors: any;
  canUpdateVouchSettings: boolean;
  setValue?: (value: any) => any;
}) => {
  const { t } = useTranslation();
  return (
    <div className="my-6">
      <SecurityFeatureRow
        id="bulkRequirePassphrase"
        title={t("requirePasscode")}
        contentToShowWhenChecked={() => (
          <div className="flex flex-row w-64">
            <FormGroup
              label={t("passPhrase")}
              name="bulkRequirePassphrase"
              id="bulkRequirePassphrase"
            >
              <Input
                errorText={errors.passphrase && (t("requiredField") as string)}
                register={register}
                registerArgs={{ required: true }}
                autoFocus
              />
            </FormGroup>
          </div>
        )}
      >
        {t("addASecretPasscodeToThisInviteLin")}
      </SecurityFeatureRow>

      <SecurityFeatureRow
        id="bulkExpiration"
        title={t("setExpiration")}
        contentToShowWhenChecked={() => (
          <div className="w-64">
            <Select
              name="bulkExpiration"
              defaultValue={DateIntervals.ONE_HOUR}
              register={register}
            >
              <option value={DateIntervals.ONE_HOUR}>
                {t("hourWithCount", { count: 1 })}
              </option>
              <option value={DateIntervals.ONE_DAY}>
                {t("hourWithCount", { count: 24 })}
              </option>
              <option value={DateIntervals.THREE_DAYS}>
                {t("dayWithCount", { count: 3 })}
              </option>
              <option value={DateIntervals.ONE_WEEK}>
                {t("weekWithCount", { count: 1 })}
              </option>
            </Select>
          </div>
        )}
      >
        {t("setThisInviteLinkToExpireAfterAS", { count: 1 })}
      </SecurityFeatureRow>

      {canUpdateVouchSettings && (
        <SecurityFeatureRow
          setValue={setValue}
          id="skipVouch"
          title={
            <div>
              <div>
                {t("skipVouching")}{" "}
                <span
                  style={{ width: "fit-content" }}
                  className="ml-2 px-2 py-0.5 border border-frank-red-400 text-frank-red-400 rounded-full t-mini whitespace-nowrap"
                >
                  {t("organizerOnly")}
                </span>
              </div>
            </div>
          }
        >
          {t("skipVouchingDescription")}
        </SecurityFeatureRow>
      )}
    </div>
  );
};

export default BulkInviteAdvancedOptions;

import { FormAction } from "frank-types/dist/frank-backend-types";
import React from "react";
import CustomFormResponseEdit from "../../../../CustomForm/CustomFormResponseEdit";
import PrimaryActionItem from "./PrimaryActionItem";
import { v4 as uuid } from "uuid";

const CustomFormPrimaryActionEmbed = ({
  customForm,
  onUpdate,
}: {
  customForm: FormAction;
  onUpdate: () => Promise<void>;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [formKey, setFormKey] = React.useState(uuid());
  const finishForm = (submittedAt?: Date) => {
    if (submittedAt) {
      setFormKey(uuid());
    }
    onUpdate();
  };
  return (
    <PrimaryActionItem
      expandable
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <CustomFormResponseEdit
        key={formKey}
        customFormId={customForm.id}
        onComplete={finishForm}
        close={() => setIsExpanded(false)}
      />
    </PrimaryActionItem>
  );
};

export default CustomFormPrimaryActionEmbed;

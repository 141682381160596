import {
  Button,
  FeatherIcon,
  Icon,
  IconButton,
  IconLinkButton,
  Intent,
  RightSideBarOpenOnMobile,
  ScreenSizes,
  SectionHeader,
  Sizes,
  TopBar,
  useLayout,
  useScreenSize,
  useToaster,
} from "@get-frank-eng/design-system";
import { AbilityAction, FrankBackendTypes } from "frank-types";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import ChannelAttachments from "../../Channel/ChannelAttachments";
import Polls from "../../Channel/Polls";
import Channel from "../../Chat";
import { RightSideRoute } from "../../RouteComponents";
import { TopicResources } from "../pages/Resources";
import ReactMarkdown from "react-markdown";
import Members from "../pages/Members";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { useModals } from "../../Modals";
import { useArchiveTopicMutation } from "../../generated-hooks";
import { useTranslation } from "react-i18next";

const Index = ({
  topic,
  refetch,
}: {
  topic: FrankBackendTypes.Topic;
  refetch: () => void;
}) => {
  const { t } = useTranslation();
  const { setModal, closeModal } = useModals();
  const { url } = useRouteMatch();
  const { setMobilePanel } = useLayout();
  const screensize = useScreenSize();
  const { ability } = useCurrentUserData();
  const canManageMembers = ability?.can(AbilityAction.Manage, "Group");
  const canArchiveTopic = ability?.can(AbilityAction.Delete, "Topic");

  const [
    invokeArchiveTopic,
    { error, loading, data },
  ] = useArchiveTopicMutation();

  const toaster = useToaster();

  const isPrivateChannel =
    topic.type === FrankBackendTypes.ChannelType.PrivateRoom;

  const showAddMemberButton = canManageMembers && isPrivateChannel;
  const isMobile = screensize <= ScreenSizes.SM;

  const iconSize = isMobile ? Sizes.MD : Sizes.XL;

  const archiveTopic = () =>
    setModal({
      type: "genericModal",
      props: {
        title: t("archiveTopic"),
        body: (
          <div className="t-small">
            {t("clickConfirmToArchiveThisTopicWhe")}
          </div>
        ),
        footer: (
          <Button
            onClick={async () => {
              try {
                await invokeArchiveTopic({
                  variables: { topicId: topic.id },
                });
                toaster.addToast({
                  intent: Intent.SUCCESS,
                  children: t("topicArchived"),
                });
                refetch();
              } catch (e) {
                toaster.addToast({
                  intent: Intent.FAILURE,
                  children: t("somethingWentWrong"),
                });
              }
              closeModal();
            }}
          >
            {t("confirm")}
          </Button>
        ),
      },
    });

  const addMemberButton = (
    <Button
      size={isMobile ? Sizes.XS : Sizes.SM}
      buttonStyle="outline"
      className="mt-1 sm:mt-0"
      onClick={() =>
        setModal({
          type: "addMemberModal",
          props: {
            channelId: topic.channelId,
            topicName: topic.name,
          },
        })
      }
    >
      {t("addMembers")}
    </Button>
  );

  const pageDisplayName = (
    <div className="flex flex-row items-center">
      {topic.type === FrankBackendTypes.ChannelType.PrivateRoom ? (
        <FeatherIcon icon="Lock" size={iconSize} classNames="mr-1 sm:pb-1" />
      ) : (
        <FeatherIcon icon="Hash" size={iconSize} />
      )}
      <div>{topic.name}</div>
    </div>
  );

  if (topic.archivedAt) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      {isMobile && (
        <div className="bg-canvas-700">
          <TopBar
            left={
              <IconButton
                icon="close"
                buttonStyle="minimal"
                onClick={() => setMobilePanel(null)}
              />
            }
            title={
              canArchiveTopic ? (
                <button
                  className="hoverable px-2 py-1 -ml-2 rounded"
                  onClick={archiveTopic}
                >
                  <div className="flex flex-row items-center space-x-1">
                    <div className="t-small plus">{pageDisplayName}</div>
                    <Icon icon="arrow_drop_down" size={Sizes.MD} />
                  </div>
                </button>
              ) : (
                pageDisplayName
              )
            }
            right={showAddMemberButton && addMemberButton}
          />
        </div>
      )}
      <div className="p-6 bg-canvas-700 flex-grow">
        {screensize > ScreenSizes.SM && (
          <div className="flex justify-between items-center pb-2">
            {canArchiveTopic ? (
              <button
                className="hoverable px-2 py-1 -ml-2 rounded"
                onClick={archiveTopic}
              >
                <div className="flex space-x-2 items-center">
                  <h1 className="t-title-4 plus">{pageDisplayName}</h1>
                  <Icon icon="arrow_drop_down" size={Sizes.XL} />
                </div>
              </button>
            ) : (
              <h1 className="t-title-4 plus">{pageDisplayName}</h1>
            )}

            {showAddMemberButton && addMemberButton}
          </div>
        )}
        <p className="t-small full-formatting">
          <ReactMarkdown children={topic.description} />
        </p>
        <div className="h-8" />
        <div className="space-y-4">
          <SectionHeader to={url + "/polls"}>{t("polls")}</SectionHeader>
          {topic.supportsFiles && (
            <SectionHeader to={url + "/files"}>{t("files")}</SectionHeader>
          )}
          {!!topic.resources.length && (
            <SectionHeader to={url + "/resources"}>
              {t("resources")}
            </SectionHeader>
          )}
          {isPrivateChannel && (
            <SectionHeader to={url + "/members"}>{t("members")}</SectionHeader>
          )}
        </div>
      </div>
    </>
  );
};

const TopicRightSide = ({
  topic,
  refetch,
}: {
  topic: FrankBackendTypes.Topic;
  refetch: () => void;
}) => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <RightSideRoute path={`${url}/files`}>
        <ChannelAttachments topicId={topic.id} topicSlug={topic.slug} />
      </RightSideRoute>
      <RightSideRoute path={`${url}/polls`}>
        <div className="bg-canvas-700 flex-grow">
          <Polls
            channelId={topic.channelId}
            topicId={topic.id}
            topicSlug={topic.slug}
          />
        </div>
      </RightSideRoute>
      <RightSideRoute path={`${url}/resources`}>
        <TopBar
          classNames="bg-canvas-700"
          title="Resources"
          left={
            <IconLinkButton
              to={`../${topic.slug}`}
              icon="arrow_back"
              size={Sizes.XL}
              buttonStyle="minimal"
            />
          }
        />
        <div className="bg-canvas-700 flex-grow">
          <div className="p-6">
            <TopicResources topic={topic} />
          </div>
        </div>
      </RightSideRoute>
      <RightSideRoute path={`${url}/members`}>
        <div className="bg-canvas-700 flex-grow">
          <Members topic={topic} />
        </div>
      </RightSideRoute>

      <Route
        path={`${url}/thread/:threadId`}
        render={({ match }) => {
          return (
            <>
              <RightSideBarOpenOnMobile />
              <Channel
                channelId={topic.channelId}
                parentId={match.params.threadId}
                key={match.params.threadId}
              />
            </>
          );
        }}
      />

      <Route path={`${url}/`}>
        <Index topic={topic} refetch={refetch} />
      </Route>
    </Switch>
  );
};

export default TopicRightSide;

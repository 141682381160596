import React from "react";
import { useLocation } from "react-router";

export default function useCustomFormsPathing() {
  const location = useLocation();
  const generateCustomFormUrl = React.useCallback(
    (groupId: string, customFormId?: string, coworkerId?: string) => {
      const pathParts = location.pathname.split("/");
      const customFormPathParts = pathParts.slice(1, 4);
      customFormPathParts.push(groupId);
      if (customFormId) {
        customFormPathParts.push(customFormId);
        if (coworkerId) {
          customFormPathParts.push(coworkerId);
        }
      }
      return `/${customFormPathParts.join("/")}`;
    },
    [location.pathname]
  );

  return {
    generateCustomFormUrl,
  };
}

import * as React from "react";
import { DataField } from "frank-types/dist/frank-backend-types";
import { DataFieldResponseDataInput } from "./types/common";
import { DataFieldConfigSelect } from "./types/data-field-config";
import { DataFieldResponseSelectData } from "./types/data-field-response-data";
import { Select } from "@get-frank-eng/design-system/dist/components/forms/Select";
import { MultiSelect } from "@get-frank-eng/design-system";
import DataFieldCheckbox from "./common/DataFieldCheckbox";

function DataFieldCheckboxSelectInput({
  field,
  config,
  onUpdate,
  data,
  disabled,
}: {
  field: DataField;
  config: DataFieldConfigSelect;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  data?: DataFieldResponseSelectData;
  disabled: boolean;
}): JSX.Element {
  const onCheck = React.useCallback(
    (field: DataField, val: string) => {
      let newData = data;
      if (!data) {
        newData = { value: [] };
      }
      if (!newData.value.includes(val)) {
        if (config.multiSelect) {
          newData.value.push(val);
        } else {
          newData.value = [val];
        }
      }
      if (newData.value.length === 0) {
        newData = undefined;
      }
      onUpdate({
        dataFieldId: field.id,
        data: newData,
      });
    },
    [onUpdate, config]
  );
  const onUncheck = React.useCallback(
    (field: DataField, val: string) => {
      let newData = data;
      if (!newData) {
        return;
      }
      if (newData.value.includes(val)) {
        newData.value.splice(newData.value.indexOf(val), 1);
      }
      if (newData.value.length === 0) {
        newData = undefined;
      }
      onUpdate({
        dataFieldId: field.id,
        data: newData,
      });
    },
    [onUpdate]
  );
  const checkboxes = config.options.map((opt, ind) => (
    <div key={opt} className="whitespace-nowrap">
      <DataFieldCheckbox
        field={field}
        value={opt}
        disabled={disabled}
        label={opt}
        checked={data?.value.includes(opt) ?? false}
        onCheck={onCheck}
        onUncheck={onUncheck}
      />
    </div>
  ));
  return <>{checkboxes}</>;
}

function DataFieldDropdownSelectInput({
  field,
  config,
  onUpdate,
  data,
  disabled,
}: {
  field: DataField;
  config: DataFieldConfigSelect;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  data?: DataFieldResponseSelectData;
  disabled: boolean;
}): JSX.Element {
  const options = React.useMemo(() => {
    const options = config.options.map((opt) => ({
      value: opt,
      label: opt,
      selected: data?.value?.includes(opt) ?? false,
    }));
    return [{ value: "", label: "", selected: !data }, ...options];
  }, [config, data]);
  const currentValues = React.useMemo(
    () => (data ? data.value.map((opt) => ({ value: opt, label: opt })) : []),
    [data]
  );
  const updateValue = React.useCallback(
    (field: DataField, values: string[]) => {
      const realValues = values.filter((val) => val);
      if (realValues.length === 0) {
        onUpdate({
          dataFieldId: field.id,
          data: undefined,
        });
      } else {
        onUpdate({
          dataFieldId: field.id,
          data: { value: realValues },
        });
      }
    },
    [data, onUpdate]
  );
  if (!config.multiSelect) {
    return (
      <Select
        onChange={(evt) => updateValue(field, [evt.currentTarget.value])}
        options={options}
        disabled={disabled}
      />
    );
  }
  return (
    <MultiSelect
      options={options}
      disabled={disabled}
      value={currentValues}
      loadOptions={(_, callback) => callback(options)}
      defaultOptions={options}
      onChange={(newValues) =>
        updateValue(
          field,
          newValues.map((opt) => opt.value)
        )
      }
    />
  );
}

export default function DataFieldSelectInput({
  field,
  config,
  onUpdate,
  data,
  disabled,
}: {
  field: DataField;
  config: DataFieldConfigSelect;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  data?: DataFieldResponseSelectData;
  disabled: boolean;
}): JSX.Element {
  const selectType = config.selectType ?? "dropdown";
  if (selectType === "checkbox") {
    return (
      <DataFieldCheckboxSelectInput
        field={field}
        config={config}
        onUpdate={onUpdate}
        data={data}
        disabled={disabled}
      />
    );
  }
  return (
    <DataFieldDropdownSelectInput
      field={field}
      config={config}
      onUpdate={onUpdate}
      data={data}
      disabled={disabled}
    />
  );
}

import * as React from "react";
import Loading from "../../components/Loading";
import Pager, { PaginationStrategy } from "../../components/Pager";
import useCampaignSignatures from "../dataAccess/queries/useSignatures";
import { WorkerStatementRow } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

export default function Signatures({
  campaignId,
}: {
  campaignId: FrankBackendTypes.Campaign["id"];
}) {
  const { loading, signatures, loadMore } = useCampaignSignatures(campaignId);
  const { preferredLanguage } = useCurrentUserData();

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Pager<FrankBackendTypes.Signature>
          pagination={signatures}
          onShowMore={loadMore}
          paginationStrategy={PaginationStrategy.FOREVER_SCROLL}
        >
          <div className="grid grid-cols-1 gap-3">
            {signatures.objects.map((signature) => (
              <WorkerStatementRow
                lng={preferredLanguage}
                key={signature.id}
                name={signature.user.name}
                date={new Date(signature.createdAt)}
                statement={signature.statement}
                profilePicUrl={signature.user.profilePic.url}
              />
            ))}
          </div>
        </Pager>
      )}
    </>
  );
}

import { FormGroup, Icon, Input, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import BackAndContinue from "../components/BackAndContinue";
import ContentContainer from "../components/ContentContainer";
import LittleMinus from "../components/icons/LittleMinus";
import LittlePlus from "../components/icons/LittlePlus";
import useUpdateUpdateCampaign from "../dataAccess/mutations/useUpdateCampaign";
import { useTranslation } from "react-i18next";

const Tip = ({ leftIcon, children }) => {
  return (
    <div className="mb-2 flex flex-row items-center">
      {leftIcon && <div className="mr-1">{leftIcon}</div>}
      {children}
    </div>
  );
};

const Title = ({
  campaign,
  refetch,
}: {
  campaign: FrankBackendTypes.Campaign;
  refetch: () => void;
}) => {
  const { t } = useTranslation();

  const { register, handleSubmit, errors } = useForm<{ title: string }>();
  const { updateCampaign, loadingUpdateCampaign } = useUpdateUpdateCampaign({
    campaignId: campaign.id,
  });
  const changeTitle = React.useCallback(
    async ({ title }: { title: string }) => {
      await updateCampaign({
        title,
      });
      await refetch();
    },
    [updateCampaign, refetch]
  );

  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">{t("giveYourPetitionATitle")}</h2>
      <p className="t-regular text-canvas-400 mb-8">
        {t("titlesWillBeTheFirstThingThatAttr")}
      </p>
      <form onSubmit={handleSubmit(changeTitle)} className="mb-4">
        <FormGroup label={t("title")} name="title" id="title">
          <Input
            errorText={
              (errors?.title?.type === "required" &&
                (t("titleIsRequired") as string)) ||
              (errors?.title?.type === "maxLength" &&
                (t("titleMustBeLessThan65Characters") as string))
            }
            type="text"
            onBlur={handleSubmit(changeTitle)}
            register={register}
            defaultValue={campaign.title}
            disabled={loadingUpdateCampaign}
            registerArgs={{ required: true, minLength: 0, maxLength: 65 }}
          />
        </FormGroup>
      </form>
      <section className="mt-8">
        <h3 className="t-small plus flex flex-row items-center mb-2">
          <div className=" mr-2">
            <Icon rotate="50" icon="wb_incandescent" size={Sizes.LG} />
          </div>
          {t("tipsForAStrongTitle")}
        </h3>
        <div className="grid grid-cols-2 gap-2 t-mini">
          <div>
            <Tip
              leftIcon={
                <div className="text-frank-green-400">
                  <LittlePlus />
                </div>
              }
            >
              {t("keepItShortAndToThePoint")}
            </Tip>

            <Tip
              leftIcon={
                <div className="text-frank-green-400">
                  <LittlePlus />
                </div>
              }
            >
              {t("makeItACallToAction")}
            </Tip>
            <Tip
              leftIcon={
                <div className="text-frank-green-400">
                  <LittlePlus />
                </div>
              }
            >
              {t("keepItSimple")}
            </Tip>
          </div>
          <div>
            <Tip
              leftIcon={
                <div className="text-brand-600">
                  <LittleMinus />
                </div>
              }
            >
              {t("avoidBeingVagueAndIndirect")}
            </Tip>
            <Tip
              leftIcon={
                <div className="text-brand-600">
                  <LittleMinus />
                </div>
              }
            >
              {t("keepOutTheNamesOfOrganizers")}
            </Tip>
          </div>
        </div>
      </section>
      <section className="mt-8 mb-16">
        <h3 className="t-small plus flex flex-row items-center">
          <div className="mr-2">
            <Icon rotate="50" icon="wb_incandescent" size={Sizes.LG} />
          </div>
          {t("examples")}
        </h3>
        <div className="t-regular py-6 border-b">
          {t("endForcedArbitrationForSexualMiscon")}{" "}
        </div>
        <div className="t-regular py-6 border-b">
          {t("401KMatchingForAllWorkersAtOrgani")}
        </div>
        <div className="t-regular py-6 border-b">
          {t("releaseWorkerSchedules2WeeksInAdva")}{" "}
        </div>
      </section>
      <BackAndContinue workflowState={campaign.workflowState} />
    </ContentContainer>
  );
};

export default Title;

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { findLanguage } from "../utils/findLanguage";

const i18n = i18next.createInstance();
i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng:
    localStorage.getItem("i18n-language") || findLanguage(navigator.language),
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    es: {
      translations: require("./locales/es/translations.json"),
    },
  },
  interpolation: { escapeValue: false },
  ns: ["translations"],
  defaultNS: "translations",
  nonExplicitSupportedLngs: true,
  supportedLngs: ["en", "es"],
});

export default i18n;

import {
  Button,
  FormGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useModals } from "../Modals";
import { useTranslation } from "react-i18next";

export type FieldsForNonMemberCollaborators = Pick<
  FrankBackendTypes.AddCollaboratorsDto,
  "messageForNewMembers"
>;

interface Props {
  campaignName: string;
  onSubmit: (addCollaboratorsDTO: FieldsForNonMemberCollaborators) => void;
}

const NewMemberCollaboratorInviteModal = ({
  onSubmit,
  campaignName,
}: Props) => {
  const { t } = useTranslation();
  const { closeModal } = useModals();
  const submit = React.useCallback(
    async (args: FieldsForNonMemberCollaborators) => {
      onSubmit({
        messageForNewMembers: args.messageForNewMembers,
      });
      closeModal();
    },
    [onSubmit, closeModal]
  );

  const { register, handleSubmit } = useForm<FieldsForNonMemberCollaborators>();

  return (
    <>
      <ModalHeader title={t("inviteNewMembers")} />
      <ModalBody>
        <form id="email-invite-form" onSubmit={handleSubmit(submit)}>
          <p className="t-small">{t("someOfTheCoworkersYoureInvitingAr")}</p>
          <FormGroup
            name="messageForNewMembers"
            id="messageForNewMembers"
            label={t("personalNoteToNewMembers")}
          >
            <Textarea
              maxLength={1600}
              register={register}
              registerArgs={{ required: true, maxLength: 1600 }}
              defaultValue={
                t("idLikeToAskYouToJoinMeToOrgani", {
                  petitionName: campaignName,
                }) as string
              }
            />
          </FormGroup>
          <ModalFooter>
            <>
              <Button buttonStyle="minimal" onClick={closeModal}>
                {t("cancel")}
              </Button>
              <Button
                form="email-invite-form"
                type="submit"
                data-cy="invite-button"
              >
                {t("invite")}
              </Button>
            </>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
};

export default NewMemberCollaboratorInviteModal;

import {
  Button,
  FormGroup,
  InlineAlert,
  Input,
  Intent,
  MenuItem,
  MultiSelect,
  Select,
  SelectOption,
  Sizes,
  Switch,
  TaskCard,
  Textarea,
  useToaster,
} from "@get-frank-eng/design-system";
import { AbilityAction } from "frank-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import Loading from "../../../components/Loading";
import {
  useAddOrganizersToGroupsMutation,
  useDeleteGroupMutation,
  useRemoveOrganizersFromGroupMutation,
} from "../../../generated-hooks";
import { useModals } from "../../../Modals";
import AddPhoto from "../../../Onboarding/components/AddPhoto";
import Sentry from "../../../sentryClient";
import usePartner from "../../../SSO/dataAccess/queries/usePartner";
import useRemoveScheduledTask from "../../../Tasks/dataAccess/mutations/useRemoveScheduledTask";
import useCustomResources from "../../dataAccess/hooks/mutations/useCustomResources";
import useUpdateGroupDetails from "../../dataAccess/hooks/mutations/useUpdateGroup";
import useGroup from "../../dataAccess/hooks/queries/useGroup";
import useOrganizers from "../../dataAccess/hooks/queries/useOrganizers";
import { CustomResources, OrganizerPill } from "./components";
import { ChooseNewLead } from "./components/OrganizerPill";

type FormParams = {
  name?: string;
  size?: number;
  companyName?: string;
  emailSenderName?: string;
  welcomeMessage?: string;
  inviteMessage?: string;
  leadOrganizerId?: string;
};

export const GroupSettings = () => {
  const { partnerSlug, groupId } = useParams<{
    partnerSlug: string;
    groupId;
  }>();
  const { t } = useTranslation();
  const { loading: loadingPartner, partner } = usePartner({ partnerSlug });
  const {
    ability,
    currentGroup,
    myProfile,
    preferredLanguage,
  } = useCurrentUserData();
  const canAccessPage = ability?.can(AbilityAction.Manage, "Partner");
  const { group, loading: loadingGroup, refetch } = useGroup({ groupId });
  const toaster = useToaster();
  const { updateGroupDetails, loading } = useUpdateGroupDetails();
  const {
    organizers,
    loading: loadingOrganizers,
    refetch: refetchOrganizers,
  } = useOrganizers(partner?.id);
  const { register, handleSubmit } = useForm<FormParams>();
  const [isDirty, setIsDirty] = React.useState(false);
  const { removeScheduledTask } = useRemoveScheduledTask();
  const { openConfirmationModal, setModal, closeModal } = useModals();
  const [invokeDeleteGroupMutation] = useDeleteGroupMutation();
  const [
    invokeAddOrganizersToGroupsMutation,
  ] = useAddOrganizersToGroupsMutation();

  const [
    invokeRemoveOrganizerFromGroup,
  ] = useRemoveOrganizersFromGroupMutation();

  const {
    createCustomResource,
    createCustomResourceLoading,
    removeCustomResource,
    uploadAttachment,
  } = useCustomResources();

  const [groupDeleted, setGroupDeleted] = React.useState(false);
  const canDeleteGroup = ability.can(AbilityAction.Manage, "Partner");
  const [deleteGroupError, setDeleteGroupError] = React.useState(false);
  const [organizersToAdd, setOrganizersToAdd] = React.useState<SelectOption[]>(
    []
  );
  const [organizersToRemove, setOrganizersToRemove] = React.useState<
    { userId: string; name: string }[]
  >([]);
  const [allowAnonymousChannel, setAllowAnonymousChannel] = React.useState(
    false
  );
  const [allowFromFrankChannel, setAllowFromFrankChannel] = React.useState(
    false
  );

  React.useEffect(() => {
    if (!group) {
      return;
    }
    setAllowAnonymousChannel(group.allowAnonymousChannel);
    setAllowFromFrankChannel(group.allowFromFrankChannel);
  }, [group]);

  const organizersNotInCurrentGroup = React.useMemo(() => {
    if (!group || !organizers) {
      return;
    }
    return organizers.objects.filter(
      (o) => !group.organizers.map((o) => o.id).includes(o.id)
    );
  }, [group, organizers]);

  const filterOrganizers = (inputValue: string) => {
    return organizersNotInCurrentGroup
      .filter((o) =>
        o.user.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((o) => ({
        label: o.user.name,
        value: o.userId,
      }));
  };

  // has to be a promise b/c we're using the Async version of the select
  const loadOptions = async (searchString) => filterOrganizers(searchString);

  const addOrganizers = async (organizersToAdd: SelectOption[]) => {
    try {
      await invokeAddOrganizersToGroupsMutation({
        variables: {
          userIds: organizersToAdd.map((o) => o.value),
          groupIds: [groupId],
        },
      });
      setOrganizersToAdd([]);
      refetchOrganizers();
      // we need to refetch group info too, but we do that down later ...
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrongAddingOrganizerTo"),
      });
    }
  };

  const removeOrganizers = async () => {
    try {
      await invokeRemoveOrganizerFromGroup({
        variables: {
          userIds: organizersToRemove.map((o) => o.userId),
          groupId: group.id,
        },
      });
      await refetch();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("successfullyRemovedOrganizer", {
          count: organizersToRemove.length,
        }),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
      Sentry.captureException(e);
    }
  };

  const removeOrganizersFlow = async (
    organizersToRemove: { userId: string; name: string }[]
  ) => {
    if (group.id === currentGroup.id) {
      if (organizersToRemove.map((o) => o.userId).includes(myProfile.id)) {
        return openConfirmationModal({
          actionText: t("gotIt"),
          bodyText: t("youCannotRemoveYourselfAsAnOrganiz", {
            groupName: group.name,
          }) as string,
          title: t("cannotCompleteAction"),
        });
      }
    }
    if (organizersToRemove.length === group.organizers.length) {
      return openConfirmationModal({
        actionText: t("gotIt"),
        bodyText: t("completingThisActionWouldRemoveAll", {
          groupName: group.name,
        }) as string,
        title: t("cannotCompleteAction"),
      });
    }

    const lead = organizersToRemove.find(
      (o) => o.userId === group.leadOrganizerId
    );

    const bodyText = (
      <div className="space-y-2">
        <p>
          {t("areYouSureYouWantToRemoveTheFoll", { groupName: group.name })}
        </p>
        <div>
          {organizersToRemove.map((o) => (
            <p key={o.userId}>- {o.name}</p>
          ))}
        </div>

        {lead && (
          <p>
            {t("leadNameIsTheLeadOrganizerOf", {
              leadName: lead.name,
              groupName: group.name,
            })}
          </p>
        )}
      </div>
    );
    const confirm = await openConfirmationModal({
      actionText: t("confirm"),
      bodyText,
      title: t("removeOrganizersConfirm"),
    });
    if (!confirm) {
      return;
    }
    if (confirm) {
      if (lead) {
        setModal({
          type: "genericModal",
          props: {
            title: t("chooseANewLeadOrganizer"),
            body: (
              <ChooseNewLead
                groupOrganizers={group.organizers}
                organizersToRemove={organizersToRemove}
                group={group}
                refetchGroupData={refetch}
                updateGroupSettings={async ({ leadOrganizerId }) => {
                  try {
                    await updateGroupDetails({
                      leadOrganizerId,
                      groupId,
                    });
                    await refetch();
                    toaster.addToast({
                      intent: Intent.SUCCESS,
                      children: t("successfullyUpdatedLeadOrganizer"),
                    });
                  } catch (e) {
                    Sentry.captureException(e);
                    toaster.addToast({
                      intent: Intent.FAILURE,
                      children: t("somethingWentWrongUpdatingLeadOrgan"),
                    });
                    throw e;
                  }
                  await removeOrganizers();
                }}
                closeModal={closeModal}
              />
            ),
          },
        });
      } else {
        removeOrganizers();
      }
      setOrganizersToRemove([]);
    }
  };

  const saveUpdate = async (formData: FormParams) => {
    if (organizersToAdd.length) {
      await addOrganizers(organizersToAdd);
    }
    try {
      await updateGroupDetails({
        name: formData.name,
        size: formData.size ? +formData.size : undefined,
        companyName: formData.companyName,
        emailSenderName: formData.emailSenderName,
        welcomeMessage: formData.welcomeMessage,
        inviteMessage: formData.inviteMessage,
        leadOrganizerId: !!formData.leadOrganizerId
          ? formData.leadOrganizerId
          : null,
        groupId,
        allowAnonymousChannel,
        allowFromFrankChannel,
      });
      await refetch();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("successfullyUpdatedGroupDetails"),
      });
      setIsDirty(false);
    } catch (e) {
      Sentry.captureException(e);
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrongUpdatingGroupDeta"),
      });
    }
  };

  const deleteGroup = async () => {
    const confirm = await openConfirmationModal({
      actionText: t("deleteGroup"),
      title: t("areYouSureYouWantToDeleteThisGro"),
      bodyText: t("clickingDeleteGroupWillDeleteThis") as string,
    });
    if (confirm) {
      try {
        await invokeDeleteGroupMutation({ variables: { groupId } });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("success"),
        });
        setGroupDeleted(true);
        refetch();
      } catch (e) {
        setDeleteGroupError(true);
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrong"),
        });
      }
    }
  };

  if (groupDeleted) {
    return <Redirect to={`../partners/${partnerSlug}/groups`} />;
  }

  if (loadingGroup || loadingPartner || loadingOrganizers) {
    return <Loading />;
  }

  if (!canAccessPage) {
    <Redirect to="/home" />;
  }

  return (
    <>
      <div className="max-w-5xl mx-auto px-4 md:mt-12 mt-24 w-full">
        <h1 className="t-title-4 plus">
          <Link
            to={`/partners/${partnerSlug}`}
            className="text-canvas-400 hover:text-underline"
          >
            {partner.name}
          </Link>{" "}
          / {group.name}
        </h1>

        <div className="h-6" />
        <form
          onSubmit={handleSubmit(saveUpdate)}
          noValidate
          className="space-y-8 mb-4 max-w-md"
        >
          <section>
            <FormGroup name="name" label={t("groupName")} id="name">
              <Input
                register={register}
                id="name"
                maxLength={120}
                placeholder={t("groupName")}
                helpText="E.g. Amazon Workers United"
                defaultValue={group.name || null}
                onChange={() => setIsDirty(true)}
              />
            </FormGroup>
          </section>
          <section>
            <FormGroup
              name="leadOrganizerId"
              label={t("leadOrganizer")}
              id="leadOrganizerId"
            >
              <Select
                options={organizers.objects.map((o) => ({
                  value: o.user.id,
                  label: o.user.name,
                  selected: o.userId === group.leadOrganizerId,
                }))}
                register={register}
                id="leadOrganizerId"
                placeholder={t("chooseALeadOrganizer")}
                defaultValue={group.leadOrganizerId}
                onChange={() => setIsDirty(true)}
                helpText={t("theWelcomeMessagePostedInGeneralW") as string}
              />
            </FormGroup>
          </section>
          <section>
            <div className="t-mini plus -mb-2">{t("groupLogo")}</div>
            <AddPhoto
              setGroupAttachmentId={({ attachmentId }) =>
                updateGroupDetails({ attachmentId, groupId })
              }
              defaultUrl={group.logo?.url}
              onUpload={(err, url) => {
                setIsDirty(true);
                refetch();
                toaster.addToast({
                  intent: Intent.SUCCESS,
                  children: t("successfullyUpdatedGroupLogo"),
                });
                if (err) {
                  toaster.addToast({
                    intent: Intent.FAILURE,
                    children: t("somethingWentWrongUpdatingYourGroup"),
                  });
                }
              }}
              groupId={groupId}
            />
          </section>
          <section>
            <FormGroup
              name="emailSenderName"
              label={t("emailSenderName")}
              id="emailSenderName"
            >
              <Input
                register={register}
                id="emailSenderName"
                maxLength={120}
                placeholder={t("emailSenderName")}
                helpText={t("recipientsWillReceiveEmailsFromThis") as string}
                defaultValue={group.partner.name || null}
                onChange={() => setIsDirty(true)}
              />
            </FormGroup>
          </section>
          <section>
            <FormGroup
              name="welcomeMessage"
              label={t("welcomeMessage")}
              id="welcomeMessage"
            >
              <Textarea
                register={register}
                id="welcomeMessage"
                maxLength={600}
                placeholder={t("welcomeMessage")}
                helpText={
                  t("createACustomMessageToWelcomeGroup", {
                    partnerName: group.partner.name,
                  }) as string
                }
                defaultValue={group.welcomeMessage || null}
                onChange={() => setIsDirty(true)}
              />
            </FormGroup>
          </section>
          <section>
            <FormGroup
              name="inviteMessage"
              label={t("inviteMessage")}
              id="inviteMessage"
            >
              <Textarea
                register={register}
                id="inviteMessage"
                maxLength={600}
                placeholder={t("inviteMessage")}
                helpText={t("includeAPersonalizedNoteToRecipient") as string}
                defaultValue={group.inviteMessage || null}
                onChange={() => setIsDirty(true)}
              />
            </FormGroup>
          </section>
          <section>
            <FormGroup
              name="companyName"
              label={t("companyName")}
              id="companyName"
            >
              <Input
                register={register}
                id="companyName"
                placeholder={t("companyName")}
                helpText={t("whatIsTheNameOfTheCompanyYouAnd") as string}
                maxLength={120}
                defaultValue={group.company || null}
                onChange={() => setIsDirty(true)}
              />
            </FormGroup>
          </section>
          <section>
            <FormGroup name="size" label={t("companySize")} id="size">
              <Input
                type="number"
                register={register}
                id="size"
                maxLength={20}
                placeholder={t("companySize")}
                helpText={t("aboutHowManyPeopleWorkAtYourCompa") as string}
                defaultValue={group.size || undefined}
                onChange={() => setIsDirty(true)}
              />
            </FormGroup>
          </section>
          <CustomResources
            lng={preferredLanguage}
            deleteAttachment={async (attachmentId) =>
              removeCustomResource({ attachmentId, refetch, groupId })
            }
            resources={group.customResources}
            uploadFile={async (e, title) => {
              const attachment = await uploadAttachment(e);
              await createCustomResource({
                attachmentId: attachment.attachmentId,
                refetch,
                title,
                groupId,
              });
            }}
            level="group"
            loading={createCustomResourceLoading}
            logoUrl={group.logo?.url || group.partner.logoAttachment.url}
          />
          <section>
            <div className="t-mini plus pb-1">{t("currentOrganizers")}</div>
            <div className="grid grid-cols-2 gap-2">
              {group.organizers.map((o) => (
                <OrganizerPill
                  key={o.id}
                  organizer={o}
                  setOrganizersToRemove={setOrganizersToRemove}
                  organizersToRemove={organizersToRemove}
                />
              ))}
            </div>
            {!!organizersToRemove.length && (
              <div className="pt-4 flex justify-end">
                <Button
                  type="button"
                  buttonStyle="outline"
                  size={Sizes.SM}
                  onClick={() => removeOrganizersFlow(organizersToRemove)}
                >
                  {t("removeOrganizers")}
                </Button>
              </div>
            )}
          </section>
          <section>
            <div className="t-mini plus pb-1">
              {t("addOrganizersToGroupName", { groupName: group.name })}
            </div>
            <div>
              <MultiSelect
                value={organizersToAdd}
                loadOptions={loadOptions}
                defaultOptions={organizersNotInCurrentGroup?.map((o) => ({
                  label: o.user.name,
                  value: o.userId,
                }))}
                onChange={(newValue) => {
                  setIsDirty(true);
                  setOrganizersToAdd(newValue);
                }}
                isLoading={loadingOrganizers}
              />
            </div>
          </section>
          {!!group.onboardingTasks.length && (
            <section>
              <div className="t-mini plus pb-1">{t("onboardingTasks")}</div>
              <div className="grid grid-cols-2 gap-6">
                {group.onboardingTasks.map((ot) => (
                  <TaskCard
                    lng={preferredLanguage}
                    task={{ ...ot.task, note: ot.note }}
                    menuItems={
                      <MenuItem
                        onClick={async () => {
                          const confirm = await openConfirmationModal({
                            title: t("areYouSureYouWantToRemoveThisOnb"),
                            bodyText: t(
                              "ifYouChangeYourMindYouCanReAssi"
                            ) as string,
                            actionText: t("confirm"),
                          });
                          if (!confirm) {
                            return;
                          }
                          await removeScheduledTask(ot.id);
                          await refetch();
                        }}
                      >
                        {t("remove")}
                      </MenuItem>
                    }
                  />
                ))}
              </div>
              <div className="text-canvas-400 t-mini pt-2">
                <Trans i18nKey="toEditCurrentTasksOrCreateMoreSeeTheTasksPage">
                  To edit current tasks or create more, see the{" "}
                  <Link to="../tasks" className="underline">
                    Tasks
                  </Link>{" "}
                  page.
                </Trans>
              </div>
            </section>
          )}
          {partner.allowAnonymousChannel && (
            <section>
              <div className="flex justify-between">
                <div className="t-small">{t("allowAnonymousChannelGroup")}</div>
                <Switch
                  onChange={() => {
                    setAllowAnonymousChannel(!allowAnonymousChannel);
                    setIsDirty(true);
                  }}
                  checked={allowAnonymousChannel}
                  label="allowAnonymousChannel"
                  id="allowAnonymousChannel"
                />
              </div>
              <div className="t-mini text-canvas-400 pt-1">
                {t("additionalAnonymousChannelToggleDescription")}
              </div>
            </section>
          )}
          {partner.allowFromFrankChannel && (
            <section>
              <div className="flex justify-between">
                <div className="t-small">
                  {t("allowFromFrankChannelGroup", {
                    partnerName: partner.slug,
                  })}{" "}
                </div>
                <Switch
                  onChange={() => {
                    setAllowFromFrankChannel(!allowFromFrankChannel);
                    setIsDirty(true);
                  }}
                  checked={allowFromFrankChannel}
                  label="allowFromFrankChannel"
                  id="allowFromFrankChannel"
                />
              </div>
              <div className="t-mini text-canvas-400 pt-1">
                {t("additionalFromFrankChannelToggleDescription")}
              </div>
            </section>
          )}

          {deleteGroupError && (
            <InlineAlert
              title={t("somethingWentWrong")}
              intent={Intent.FAILURE}
            >
              {t("thereWasAProblemDeletingThisGroup")}
            </InlineAlert>
          )}
          {canDeleteGroup && (
            <section className="p-4 border border-frank-red-400 border-dashed rounded space-y-4">
              <div className="uppercase">{t("permanentActionsZone")}</div>
              <div className="t-small">{t("permanentActionsZoneWarning")}</div>
              <Button
                type="button"
                iconLeft="warning"
                onClick={deleteGroup}
                style={{ backgroundColor: "#A4463E" }}
              >
                {t("deleteGroup")}
              </Button>
            </section>
          )}
          <Button
            type="submit"
            buttonStyle="brand"
            loading={loading}
            disabled={!isDirty}
          >
            {t("save")}
          </Button>
        </form>
        <div className="h-8" />
      </div>
    </>
  );
};

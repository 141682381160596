/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import * as ReactRouter from "react-router-dom";
import Sidebar from "./Navigation";
import Loading from "./components/Loading";
import { AuthStates, useAuthState } from "./Auth/AuthState";
import { CurrentUserProvider } from "./Auth/useCurrentUserData";
import { UseOffline } from "./hooks/useOffline";

import { CurrentChatUserProvider } from "@get-frank-eng/chat-client";

import {
  FullCenter,
  Layout,
  LeftSideBar,
  RightSideBarOpenOnMobile,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { CurrentTourProvider } from "./Tour/CurrentTourProvider";
import { ModalProvider } from "./Modals";

export const RightSideRoute: React.FC<RouteProps> = ({
  children,
  ...routeProps
}) => {
  return (
    <ReactRouter.Route {...routeProps}>
      <RightSideBarOpenOnMobile />
      {children}
    </ReactRouter.Route>
  );
};

type RouteProps = {
  sidebar?: boolean;
  sidebarBorder?: boolean;
  auth?: boolean;
  onboarding?: boolean;
  children?: React.ReactNode;
} & React.ComponentProps<typeof ReactRouter.Route>;

const ExtendedRoute: React.FC<RouteProps> = ({
  sidebar,
  auth,
  onboarding = false,
  children,
  ...routeProps
}) => {
  const { authState, onboardingState } = useAuthState();
  const location = ReactRouter.useLocation();

  if (auth && authState === AuthStates.LOGGED_IN) {
    if (
      onboardingState ===
      FrankBackendTypes.OnboardingWorkflowState.CreatingNewGroup
    ) {
      return <ReactRouter.Redirect to={`/join/profile`} />;
    }
  }

  if (auth && authState === AuthStates.NEW) {
    return (
      <FullCenter className="h-screen">
        <Loading />
      </FullCenter>
    );
  }

  if (auth && authState === AuthStates.LOADING_EXISTING_SESSION) {
    return (
      <FullCenter className="h-screen">
        <Loading />
      </FullCenter>
    );
  }
  if (
    auth &&
    (authState === AuthStates.NOT_LOGGED_IN || authState === AuthStates.ERROR)
  ) {
    return (
      <ReactRouter.Redirect
        to={`/login?after=${location.pathname}${location.search}`}
      />
    );
  }
  const CurrentUserProviderOrFragment =
    auth && !onboarding ? CurrentUserProvider : React.Fragment;

  const CurrentChatUserProviderOrFragment =
    auth && !onboarding ? CurrentChatUserProvider : React.Fragment;

  const CurrentTourProviderOrFragment =
    auth && !onboarding ? CurrentTourProvider : React.Fragment;

  return (
    <ReactRouter.Route {...routeProps}>
      <CurrentUserProviderOrFragment>
        <CurrentChatUserProviderOrFragment>
          <ModalProvider>
            <UseOffline>
              <Layout>
                <CurrentTourProviderOrFragment>
                  {sidebar ? (
                    <LeftSideBar>
                      <Sidebar />
                    </LeftSideBar>
                  ) : null}
                  {children}
                </CurrentTourProviderOrFragment>
              </Layout>
            </UseOffline>
          </ModalProvider>
        </CurrentChatUserProviderOrFragment>
      </CurrentUserProviderOrFragment>
    </ReactRouter.Route>
  );
};

export const RouteWithModals: React.FC<RouteProps> = ({
  children,
  ...routeProps
}) => {
  return (
    <ReactRouter.Route {...routeProps}>
      <ModalProvider>{children}</ModalProvider>
    </ReactRouter.Route>
  );
};

export default ExtendedRoute;

import { useMutation, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const MUTATION = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      groupId
      error
    }
  }
`;

export default function useSignUp() {
  const [invokeSignUp, { loading, error }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "signUp">,
    FrankBackendTypes.MutationSignUpArgs
  >(MUTATION);
  const signUp = React.useCallback(
    async (email: string, partnerSlug: string, templateId?: string) => {
      return invokeSignUp({
        variables: { input: { email, templateId, partnerSlug } },
      });
    },
    [invokeSignUp]
  );

  return { signUp, loading, error };
}

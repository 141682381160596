import { useMutation, gql } from "@apollo/client";
import { Intent, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../../Modals";
import { useTranslation } from "react-i18next";

const createMutation = gql`
  mutation AddColumn($input: AddCoworkerColumnDTO!) {
    addColumn(input: $input) {
      id
      name
    }
  }
`;

const deleteMutation = gql`
  mutation DeleteColumn($columnId: ID!) {
    deleteColumn(columnId: $columnId) {
      nothing
    }
  }
`;

export default function useColumns() {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { openConfirmationModal } = useModals();
  const [
    invokeMutation,
    { loading: addLoading, error: addError, called: addCalled },
  ] = useMutation<
    Pick<FrankBackendTypes.Mutation, "addColumn">,
    FrankBackendTypes.MutationAddColumnArgs
  >(createMutation);

  const [
    invokeDeleteMutation,
    { loading: deleteLoading, error: deleteError, called: deleteCalled },
  ] = useMutation<
    Pick<FrankBackendTypes.Mutation, "deleteColumn">,
    FrankBackendTypes.MutationDeleteColumnArgs
  >(deleteMutation);

  const addColumn = React.useCallback(
    async (input: FrankBackendTypes.AddCoworkerColumnDto) => {
      try {
        const result = await invokeMutation({
          variables: { input },
        });
        return result;
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrongWithCreatingThis"),
        });
        throw e;
      }
    },
    [invokeMutation, toaster, t]
  );

  const deleteColumn = React.useCallback(
    async (columnId: string) => {
      const confirm = await openConfirmationModal({
        title: t("areYouSureYouWantToDeleteThisCol"),
        bodyText: t("thisCannotBeUndoneAndWillPermanent") as string,
        actionText: t("confirm"),
      });
      if (confirm) {
        await invokeDeleteMutation({
          variables: { columnId },
        });
        if (deleteError) {
          toaster.addToast({
            intent: Intent.FAILURE,
            children: t("somethingWentWrongWithCreatingThis"),
          });
          throw deleteError;
        }
      }
    },
    [deleteError, invokeDeleteMutation, openConfirmationModal, toaster, t]
  );

  return {
    deleteColumn,
    addColumn,
    addLoading,
    addError,
    addCalled,
    deleteLoading,
    deleteError,
    deleteCalled,
  };
}

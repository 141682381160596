import { gql } from "@apollo/client";
import client from "../../gqlClient";
import { Avatar, MentionSuggestion, Sizes } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import useAreUsersInChannel from "../../Topics/dataAccess/mutations/useAreUsersInChannel";
import find from "lodash/find";

const COWORKER_QUERY = gql`
  query CoworkerSearch(
    $pagination: PaginationArgumentDTO!
    $filter: CoworkerFilterDTO!
    $explicitGroupId: ID
  ) {
    coworkers(
      pagination: $pagination
      filter: $filter
      explicitGroupId: $explicitGroupId
    ) {
      hasNext
      total
      objects {
        name
        id
        user {
          id
          name
          profilePic {
            id
            url
          }
        }
      }
    }
  }
`;

export const searchCoworkersByName = async (
  query: string,
  groupId?: string
) => {
  const restrictions: FrankBackendTypes.CoworkerRestriction[] = [
    {
      column: FrankBackendTypes.CoworkerFilterableColummns.Status,
      combinator: FrankBackendTypes.Combinators.OneOf,
      in: ["member", "organizer"],
    },
  ];

  if (query?.length) {
    restrictions.push({
      column: FrankBackendTypes.CoworkerFilterableColummns.Name,
      combinator: FrankBackendTypes.Combinators.Contains,
      stringValue: query,
    });
  }

  const { data, errors } = await client.query<
    Pick<FrankBackendTypes.Query, "coworkers">,
    FrankBackendTypes.QueryCoworkersArgs
  >({
    query: COWORKER_QUERY,
    variables: {
      pagination: { page: 0, perPage: 15 },
      filter: {
        restrictions,
      },
      explicitGroupId: groupId,
    },
  });
  if (errors?.length) {
    throw errors[0];
  }
  return data.coworkers?.objects;
};

export const searchCoworkers = (
  query: string,
  callback: (suggestions: MentionSuggestion[]) => void
) => {
  if (!query) {
    return;
  }
  searchCoworkersByName(query).then((coworkers) => {
    callback(
      coworkers.map((coworker) => ({
        id: coworker.id,
        display: coworker.name,
        // this is really gross, but for reasons of Friday and this library being tricky, it is what it is
        preview: (
          <Avatar size={Sizes.XS} src={coworker.user?.profilePic?.url} />
        ),
      }))
    );
  });
};

export const useSearchPrivateCoworkers = (channelId) => {
  const { areUsersInChannel } = useAreUsersInChannel();

  return (
    query: string,
    callback: (suggestions: MentionSuggestion[]) => void
  ) => {
    let coworkers;
    searchCoworkersByName(query).then((result) => {
      coworkers = result;
      areUsersInChannel({
        userIds: coworkers.map((c) => c.id),
        channelId,
      }).then((channelMemberIds) => {
        callback(
          channelMemberIds
            .map((id) => find(coworkers, (c) => c.id === id))
            .map((coworker) => ({
              id: coworker.id,
              display: coworker.name,
              preview: (
                <Avatar size={Sizes.XS} src={coworker.user?.profilePic?.url} />
              ),
            }))
        );
      });
    });
  };
};

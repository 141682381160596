/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames";
import find from "lodash/find";
import invert from "lodash/invert";
import * as React from "react";
import { HeaderGroup } from "react-table";
import {
  Icon,
  Menu,
  MenuItem,
  MenuTrigger,
  Nudge,
  Sizes,
  Tooltip,
} from "@get-frank-eng/design-system";
import { MaterialIcons } from "@get-frank-eng/design-system/dist/components/Icon";
import useColumns from "../dataAccess/mutations/useColumns";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

const map = {
  status: FrankBackendTypes.CoworkerOrderColumns.Status,
  employmentType: FrankBackendTypes.CoworkerOrderColumns.EmploymentType,
  yourTrust: FrankBackendTypes.CoworkerOrderColumns.TrustJudgementCount,
  name: FrankBackendTypes.CoworkerOrderColumns.Name,
};

const invertMap = invert(map);

function getIconForHeader({
  isThisCol,
  order,
}: {
  isThisCol: boolean;
  order: string;
}): MaterialIcons {
  let icon = "arrow_drop_up";
  if (isThisCol && order === "DESC") {
    icon = "arrow_drop_down";
  } else if (isThisCol && order === "ASC") {
    icon = "arrow_drop_up";
  }
  return icon as MaterialIcons;
}

function getNextOrder(order?: string) {
  if (!order) {
    return "DESC";
  }
  if (order === "DESC") {
    return "ASC";
  }
  if (order === "ASC") {
    return null;
  }
  return null;
}

const makeNewOrder = ({ sortOrder, columnName, setSortOrder }) => {
  const nextOrder = getNextOrder(sortOrder.order);
  if (!nextOrder) {
    return setSortOrder({ order: null, column: null });
  }
  const columnKey = map[columnName];
  if (!columnKey) {
    return setSortOrder({ order: null, column: null });
  }
  setSortOrder({ order: nextOrder, column: columnKey });
};

const ColumnHeader = ({
  column,
  customColumns,
  refetch,
  sortOrder,
  setSortOrder,
  view = "workers",
}: {
  column: HeaderGroup<FrankBackendTypes.Coworker>;
  customColumns: FrankBackendTypes.CoworkerCustomColumn[];
  refetch: () => void;
  sortOrder: {
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  };
  setSortOrder: (order: {
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  }) => void;
  view?: "workers" | "members" | "management";
}) => {
  const { t } = useTranslation();
  const { deleteColumn } = useColumns();
  // @ts-ignore - not sure why but typescript doesn't believe that we have any of these extra fun props
  const cellProps = column.getHeaderProps(column.getSortByToggleProps());

  if (cellProps.style) {
    cellProps.style.display = "flex";
  }

  const columnName = column.id;
  const col = invertMap[sortOrder.column];
  const isThisCol = col && col === columnName;

  // will bring this back if we bring back sorting in the backend
  // const icon = getIconForHeader({ isThisCol, order: sortOrder.order });

  // @ts-ignore
  const icon = column.isSorted
    ? //@ts-ignore
      column.isSortedDesc
      ? "arrow_drop_down"
      : "arrow_drop_up"
    : "unfold_more";

  const label = (
    <div
      className={`flex flex-row ${columnName === "selection" ? "ml-2" : ""} ${
        column["info"] ? "space-x-1" : ""
      } items-center`}
    >
      <span>{column.render("Header")}</span>
      {column["info"] && (
        <Tooltip
          placement="bottom"
          nudgeY={10}
          trigger="hover"
          tooltip={column["info"]}
        >
          <Nudge y={2}>
            <Icon size={Sizes.SM} icon="info" classNames="text-canvas-400" />
          </Nudge>
        </Tooltip>
      )}
    </div>
  );

  const deleteColumnMenu = (
    <Menu>
      <MenuItem
        onClick={async () => {
          await deleteColumn(column.id);
          await refetch();
        }}
      >
        {t("deleteColumn")}
      </MenuItem>
    </Menu>
  );

  const toOrder = map[columnName];

  if (find(customColumns, { id: column.id })) {
    return (
      <MenuTrigger
        placement="bottom-end"
        trigger="right-click"
        menu={deleteColumnMenu}
      >
        <div
          className="header-cell t-mini plus relative hoverable"
          style={cellProps.style}
        >
          <div
            id={`${columnName}-column-${view}`}
            {...cellProps}
            className="justify-between"
          >
            {label}
            <span
              className={classNames([
                { "text-canvas-300": !isThisCol, "text-white": isThisCol },
              ])}
            >
              <Nudge y={2}>
                <Icon size={Sizes.SM} icon={icon} />
              </Nudge>
            </span>
          </div>
          <div
            //@ts-ignore
            {...column.getResizerProps()}
            className="w-2 h-full absolute right-0"
          />
        </div>
      </MenuTrigger>
    );
  }

  if (!toOrder) {
    return (
      <div className="header-cell t-mini plus relative" style={cellProps.style}>
        <div
          id={`${columnName}-column-${view}`}
          {...cellProps}
          className="justify-between"
        >
          {label}
          {/** @ts-ignore */}
          {cellProps.onClick && (
            <span
              className={classNames([
                { "text-canvas-300": !isThisCol, "text-white": isThisCol },
              ])}
            >
              <Nudge y={2}>
                <Icon size={Sizes.SM} icon={icon} />
              </Nudge>
            </span>
          )}
        </div>
        <div
          //@ts-ignore
          {...column.getResizerProps()}
          className="w-2 h-full absolute right-0"
        />
      </div>
    );
  }
  return (
    <div
      className="header-cell hoverable t-mini plus relative"
      style={cellProps.style}
    >
      <button
        id={`${columnName}-column-${view}`}
        //  commenting this out in favor of front end sorting for now - keeping this around in case we decide to go back to backend sorting
        // onClick={() => makeNewOrder({ sortOrder, columnName, setSortOrder })}
        {...cellProps}
        className="justify-between"
      >
        {label}
        <div
          className={classNames([
            { "text-canvas-300": !isThisCol, "text-white": isThisCol },
          ])}
        >
          <Nudge y={2}>
            <Icon size={Sizes.SM} icon={icon} />
          </Nudge>
        </div>
      </button>
      <div
        //@ts-ignore
        {...column.getResizerProps()}
        className="w-2 h-full absolute right-0"
      />
    </div>
  );
};

export default ColumnHeader;

import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation UpdateTaskAssignment($input: UpdateTaskAssignmentInput!) {
    updateTaskAssignment(input: $input) {
      id
    }
  }
`;

export default function useUpdateTaskAssignment() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "updateTaskAssignment">,
    FrankBackendTypes.MutationUpdateTaskAssignmentArgs
  >(mutation);

  const updateTaskAssignment = React.useCallback(
    async (input: FrankBackendTypes.UpdateTaskAssignmentInput) => {
      const { data } = await invokeMutation({
        variables: { input },
      });
      return data?.updateTaskAssignment;
    },
    [invokeMutation]
  );

  return {
    updateTaskAssignment,
    loading,
    error,
    called,
  };
}

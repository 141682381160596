import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query Partners($pagination: PaginationArgumentDTO!) {
    partners(pagination: $pagination) {
      hasNext
      objects {
        id
        name
        slug
        stats {
          groupCount
          organizerCount
        }
      }
    }
  }
`;

export default function usePartners() {
  const perPage = 25;

  const { loading, error, data, refetch, fetchMore } = useQuery<
    Pick<FrankBackendTypes.Query, "partners">,
    FrankBackendTypes.QueryPartnersArgs
  >(QUERY, {
    variables: {
      pagination: {
        page: 0,
        perPage,
      },
    },
  });

  const nextPage = React.useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          page: Math.round(data?.partners.objects.length / perPage),
          perPage,
        },
      },
    });
  }, [fetchMore, data]);

  return {
    partners: data?.partners,
    loading: loading,
    error: error,
    refetch,
    nextPage,
  };
}

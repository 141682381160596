import { DataField } from "frank-types/dist/frank-backend-types";
import React from "react";
import { DataFieldResponseDataInput } from "./types/common";
import { DataFieldConfigTable } from "./types/data-field-config";
import { DataFieldResponseTableData } from "./types/data-field-response-data";
import EventEmitter from "./util";
import DataFieldTable from "./DataFieldTable";
import useCustomFormConfigContext from "./hooks/useCustomFormConfigContext";

const DataFieldTableInput = ({
  field,
  config,
  onUpdate,
  data,
  disabled,
}: {
  field: DataField;
  config: DataFieldConfigTable;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  data?: DataFieldResponseTableData;
  disabled: boolean;
}) => {
  const onUpdateRowSetId = React.useCallback(
    (rowSetId: string | null) => {
      if (rowSetId === null) {
        onUpdate({ dataFieldId: field.id, data: null });
      } else {
        onUpdate({ dataFieldId: field.id, data: { rowSetId } });
      }
    },
    [field, onUpdate]
  );
  const rowSetId = React.useMemo(() => data?.rowSetId ?? null, [data]);
  return (
    <DataFieldTable
      dataFieldTableId={config.dataFieldTableId}
      rowSetId={rowSetId}
      onUpdateRowSetId={onUpdateRowSetId}
      disabled={disabled}
    />
  );
};

export default DataFieldTableInput;

import {
  Button,
  FormGroup,
  Input,
  Intent,
  Select,
  useToaster,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import AddPhoto from "../../Onboarding/components/AddPhoto";
import useUpdateProfile from "../dataAccess/mutations/useUpdateProfile";
import { useTranslation } from "react-i18next";
import { useNavigationState } from "../../Navigation/hooks/useNavigationState";

type FormParams = {
  name: string;
  language?: string;
};

export const ProfileSettings = () => {
  const { refetch } = useNavigationState();
  const { myProfile, refetchCurrentUserData } = useCurrentUserData();
  const toaster = useToaster();
  const { updateProfile } = useUpdateProfile();
  const { register, handleSubmit } = useForm<FormParams>();
  const [isDirty, setIsDirty] = React.useState(false);

  const { t } = useTranslation();

  const saveUpdate = async (formData: FormParams) => {
    try {
      await updateProfile({ name: formData.name, language: formData.language });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("successfullyUpdatedProfile"),
      });
      await refetchCurrentUserData();
      await refetch();
      setIsDirty(false);
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrongUpdatingProfile"),
      });
    }
  };

  return (
    <div className="max-w-md">
      <form
        onSubmit={handleSubmit(saveUpdate)}
        noValidate
        className="space-y-8"
      >
        <section>
          <div className="t-small plus">{t("profilePhoto")}</div>
          <AddPhoto
            defaultUrl={myProfile.profilePic?.url || null}
            onUpload={(err, url) => {
              toaster.addToast({
                intent: Intent.SUCCESS,
                children: t("successfullyUpdatedProfilePicture"),
              });
              setIsDirty(true);
              if (err) {
                toaster.addToast({
                  intent: Intent.FAILURE,
                  children: t("somethingWentWrongUpdatingYourProfi"),
                });
              }
            }}
          />
        </section>
        <FormGroup name="name" label={t("fullName")} id="name">
          <Input
            register={register}
            id="name"
            maxLength={120}
            placeholder={t("enterYourFullName")}
            helpText={t("whatNameDoYourCoworkersKnowYouBy") as string}
            defaultValue={myProfile.name || null}
            onChange={() => setIsDirty(true)}
          />
        </FormGroup>
        <FormGroup name="language" label={t("language")} id="name">
          <Select
            onChange={() => setIsDirty(true)}
            register={register}
            placeholder={!myProfile.language && t("chooseLanguage")}
            options={[
              {
                value: "en",
                label: "English (US)",
                selected: myProfile.language === "en",
              },
              {
                value: "es",
                label: "Español (Latinoamérica)",
                selected: myProfile.language === "es",
              },
            ]}
          />
        </FormGroup>
        <Button type="submit" buttonStyle="brand" disabled={!isDirty}>
          {t("save")}
        </Button>
      </form>
    </div>
  );
};

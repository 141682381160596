import { AnimatePresence } from "framer-motion";
import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import { Link } from "react-router-dom";
import FullScreenLoading from "../components/FullScreenLoading";
import {
  LeftSideBar,
  ScreenSizes,
  useResponsive,
} from "@get-frank-eng/design-system";
import CampaignBuilderSidebar from "./CampaignBuilderSidebar";
import useCampaignBuilderData from "./dataAccess/queries/useCampaignBuilderData";
import Attachments from "./pages/Attachments";
// import Category from "./pages/Category";
import Organizers from "./pages/Organizers";
import Demand from "./pages/Demand";
import Publish from "./pages/Publish";
import Settings from "./pages/Settings";
import Title from "./pages/Title";
import MobileBottom from "./components/MobileBottom";
import IntercomShouldOffsetOnMobile from "../components/IntercomShouldOffsetOnMobile";
import Recipients from "./pages/Recipients";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";

const CampaignBuilder = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { screenSize } = useResponsive();
  const { url } = useRouteMatch();
  const {
    campaign,
    refetchCampaign,
    myProfile,
    loadingCampaign,
    calledCampaign,
  } = useCampaignBuilderData({ campaignId });

  const isLoading = !calledCampaign && loadingCampaign;

  const { t } = useTranslation();

  const history = useHistory();
  React.useEffect(
    () => {
      const unlisten = history.listen(() => {
        refetchCampaign();
      });
      return () => unlisten();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history] // this hook *intentionally* omits refetch campaign
  );

  if (
    campaign &&
    campaign?.relationshipToMe !==
      FrankBackendTypes.CampaignUserRelationship.Organizer
  ) {
    if (
      campaign?.workflowState === FrankBackendTypes.CampaignWorkflowState.Draft
    ) {
      return <Redirect to="/campaigns" />;
    }
    return <Redirect to={`/campaigns/${campaignId}`} />;
  }

  return (
    <>
      <AnimatePresence>
        {isLoading && <FullScreenLoading text={t("loadingPetitionBuilder")} />}
      </AnimatePresence>
      {!isLoading && campaign && (
        <>
          <LeftSideBar>
            <div className="p-6 border-r flex-grow flex flex-col">
              <CampaignBuilderSidebar campaign={campaign} />
            </div>
          </LeftSideBar>
          {screenSize <= ScreenSizes.SM && (
            <Link className="t-mini plus mt-8 block" to="/campaigns">
              {t("saveAndExit")}
            </Link>
          )}
          <Switch>
            {/* <Route path={`${url}/category`}>
              <Category campaign={campaign} refetch={refetchCampaign} />
            </Route> */}
            <Route path={`${url}/title`}>
              <Title campaign={campaign} refetch={refetchCampaign} />
            </Route>
            <Route path={`${url}/organizers`}>
              <Organizers campaign={campaign} />
            </Route>
            <Route path={`${url}/demand`}>
              <Demand
                campaign={campaign}
                myProfile={myProfile}
                refetch={refetchCampaign}
              />
            </Route>
            <Route path={`${url}/recipients`}>
              <Recipients campaign={campaign} refetch={refetchCampaign} />
            </Route>
            <Route path={`${url}/attachments`}>
              <Attachments campaign={campaign} refetch={refetchCampaign} />
            </Route>
            <Route path={`${url}/Settings`}>
              <Settings campaign={campaign} />
            </Route>
            <Route path={`${url}/publish`}>
              <Publish campaign={campaign} />
            </Route>
            <Redirect to={`${url}/title`} />
          </Switch>
          {screenSize <= ScreenSizes.SM && (
            <>
              <IntercomShouldOffsetOnMobile />
              <MobileBottom
                campaign={campaign}
                workflowState={campaign.workflowState}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CampaignBuilder;

import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query AuthorizationCardSignatures(
    $pagination: PaginationArgumentDTO!
    $groupId: ID!
  ) {
    authorizationCard(groupId: $groupId) {
      id
      signatures(pagination: $pagination) {
        hasNext
        total
        objects {
          id
          firstName
          lastName
          createdAt
          employer
          email
          phone
          authCardFormResponseJSON
          authorizationLanguage
          confirmedByUser
        }
      }
    }
  }
`;

export default function useAuthorizationCardSignatures({
  groupId,
  perPage,
  page,
}) {
  const { loading, error, data, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "authorizationCard">,
    FrankBackendTypes.QueryAuthorizationCardArgs & {
      pagination: { page: number; perPage: number };
    }
  >(QUERY, {
    variables: {
      pagination: {
        page,
        perPage,
      },
      groupId,
    },
  });

  return {
    signatures: data?.authorizationCard
      ? data.authorizationCard.signatures
      : { objects: [], hasNext: false, total: 0 },
    loading: loading,
    error: error,
    refetchSignatures: refetch,
  };
}

import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const LOAD_CAMPAIGN_SIGNATURES = gql`
  query CampaignUpdates($campaignId: ID!, $pagination: PaginationArgumentDTO!) {
    campaign(campaignId: $campaignId) {
      id
      signatures(pagination: $pagination) {
        hasNext
        total
        objects {
          id
          statement
          timeAgo
          createdAt
          user {
            id
            name
            profilePic {
              id
              url
            }
          }
        }
      }
    }
  }
`;

const perPage = 10;

export default function useCampaignSignatures(campaignId: string) {
  const { data, loading, error, fetchMore, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "campaign">,
    FrankBackendTypes.QueryCampaignArgs &
      FrankBackendTypes.CampaignSignaturesArgs
  >(LOAD_CAMPAIGN_SIGNATURES, {
    variables: { campaignId, pagination: { page: 0, perPage } },
  });

  const loadMore = React.useCallback(() => {
    fetchMore({
      variables: {
        campaignId,
        pagination: {
          perPage,
          page: Math.round(data?.campaign.signatures.objects.length / perPage),
        },
      },
    });
  }, [fetchMore, campaignId, data]);

  return {
    loading,
    error,
    loadMore,
    signatures: data?.campaign.signatures,
    refetch,
  };
}

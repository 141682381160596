import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query WorkerOneOnboarding($onboardingId: ID!) {
    workerOneOnboarding(onboardingId: $onboardingId) {
      email
      groupId
      hasSetPassword
      onboardingStartedAt
      onboardingFormJSON
    }
  }
`;

export default function useWorkerOneOnboarding({
  onboardingId,
}: {
  onboardingId: string;
}) {
  const { data, error, loading } = useQuery<
    Pick<FrankBackendTypes.Query, "workerOneOnboarding">,
    FrankBackendTypes.QueryWorkerOneOnboardingArgs
  >(QUERY, { variables: { onboardingId } });

  return {
    loading,
    error,
    data: data?.workerOneOnboarding,
  };
}

import * as React from "react";
import Trustworthiness from "../vouchingSections/Trustworthiness";
import Eligibility from "../vouchingSections/Eligibility";
import Authenticity from "../vouchingSections/Authenticity";
import {
  useToaster,
  Button,
  Intent,
  Sizes,
  PendingMemberCard,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useModals } from "../../Modals";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { useHistory } from "react-router";
import useQuery from "../../hooks/useQuery";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";

export default function ExpandablePendingMemberCard({
  pendingMember,
  loadingCoworkerTrustVote,
  setOpenMemberId,
  isExpanded,
  coworkerTrustVote,
  removeCoworkerTrustVote,
  refetch,
  canExpand,
  to,
}: {
  pendingMember: FrankBackendTypes.Coworker;
  loadingCoworkerTrustVote: boolean;
  setOpenMemberId: (id: FrankBackendTypes.Coworker["id"]) => void;
  isExpanded: boolean;
  coworkerTrustVote: (config: {
    coworkerId: FrankBackendTypes.Coworker["id"];
    trust: number;
    rejectionReason?: FrankBackendTypes.CoworkerRejectionReason;
    rejectionDetail?: string;
  }) => Promise<void>;
  removeCoworkerTrustVote: (config: {
    coworkerId: FrankBackendTypes.Coworker["id"];
  }) => Promise<void>;
  refetch?: () => void;
  canExpand: boolean;
  to?: string;
}) {
  const { t } = useTranslation();
  const toaster = useToaster();
  const upvote = React.useCallback(async () => {
    await coworkerTrustVote({ coworkerId: pendingMember.id, trust: 1 });
    toaster.addToast({
      intent: Intent.SUCCESS,
      children: t("youVotedToApprovePendingMemberName", {
        pendingMemberName: pendingMember.name,
      }),
      action: {
        label: t("undo"),
        onClick: () => {
          removeCoworkerTrustVote({ coworkerId: pendingMember.id });
        },
      },
    });
    await refetch();
  }, [
    coworkerTrustVote,
    pendingMember,
    toaster,
    removeCoworkerTrustVote,
    refetch,
    t,
  ]);

  const downvote = React.useCallback(
    async ({
      reason,
      rejectionDetail,
    }: {
      reason?: FrankBackendTypes.CoworkerRejectionReason;
      rejectionDetail?: string;
    }) => {
      await coworkerTrustVote({
        coworkerId: pendingMember.id,
        trust: -1,
        rejectionReason: reason,
        rejectionDetail,
      });
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("youVotedNotToApprovePendingMemberName", {
          pendingMemberName: pendingMember.name,
        }),
      });
      await refetch();
    },
    [coworkerTrustVote, pendingMember, toaster, refetch, t]
  );

  const qs = useQuery();
  const history = useHistory();

  const { setModal } = useModals();

  const { currentGroup, preferredLanguage } = useCurrentUserData();

  const onClick = () => {
    if (!canExpand) {
      return;
    }
    if (isExpanded) {
      setOpenMemberId(null);
      if (qs.get("id")) {
        history.push("/coworkers");
      }
    } else {
      setOpenMemberId(pendingMember.id);
    }
  };

  return (
    <div className="py-2 w-full">
      <PendingMemberCard
        lng={preferredLanguage}
        onClick={onClick}
        pendingMember={pendingMember}
        dataCy={`pending-member-${pendingMember.id}`}
        isExpanded={isExpanded}
      >
        <Collapse isOpened={isExpanded}>
          {isExpanded && (
            <div className="m-4 overflow-auto">
              <Eligibility pendingMember={pendingMember} />

              <Authenticity pendingMember={pendingMember} />

              <Trustworthiness pendingMember={pendingMember} />
            </div>
          )}
        </Collapse>

        {pendingMember.canVoteOnTrust && (
          <div className="m-4 overflow-auto">
            <div className="flex flex-row items-center">
              <Button
                dataCy={`pending-member-${pendingMember.id}-vote-to-deny`}
                type="button"
                size={Sizes.SM}
                onClick={() => {
                  setModal({
                    type: "denyPendingMemberModal",
                    props: {
                      downvote,
                      pendingMember,
                      company: currentGroup.name,
                    },
                    afterClose: () => setOpenMemberId(null),
                  });
                }}
                className="mr-2"
                loading={loadingCoworkerTrustVote}
                buttonStyle="secondary"
              >
                {t("voteToDeny")}
              </Button>
              <Button
                dataCy={`pending-member-${pendingMember.id}-vote-to-admit`}
                type="button"
                size={Sizes.SM}
                loading={loadingCoworkerTrustVote}
                onClick={() => {
                  setModal({
                    type: "reviewPendingMemberModal",
                    props: {
                      pendingMember,
                      groupName: currentGroup.name,
                      upvote,
                    },
                    afterClose: () => setOpenMemberId(null),
                  });
                }}
                buttonStyle="brand"
              >
                {t("voteToAdmit")}
              </Button>
            </div>
          </div>
        )}
      </PendingMemberCard>
    </div>
  );
}

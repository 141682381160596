import { FormGroup, Input, Select } from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DateIntervals } from "../utils/Date";
import SecurityFeatureRow from "./components/SecurityFeatureRow";

export const LinkSecurityOptions = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-2 mt-6 block">
        <div className="mt-4">
          <SecurityFeatureRow
            id="limit-seats"
            title={t("limitNumberOfUses")}
            contentToShowWhenChecked={() => (
              <div className="w-64">
                <Select name="maxSeats" defaultValue="10" register={register}>
                  <option value="1">1</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </Select>
              </div>
            )}
          >
            {t("byDefaultInviteLinksCanOnlyBeUsedOnce")}
          </SecurityFeatureRow>

          <SecurityFeatureRow
            id="require-passphrase"
            title="Require Passcode"
            contentToShowWhenChecked={() => (
              <div className="flex flex-row w-64">
                <FormGroup
                  label={t("passPhrase")}
                  name="passphrase"
                  id="passphrase"
                >
                  <Input
                    errorText={
                      errors.passphrase && (t("requiredField") as string)
                    }
                    register={register}
                    registerArgs={{ required: true }}
                    autoFocus
                  />
                </FormGroup>
              </div>
            )}
          >
            {t("addASecretPasscodeToThisInviteLin")}
          </SecurityFeatureRow>

          <SecurityFeatureRow
            id="expiration"
            title={t("setExpiration")}
            contentToShowWhenChecked={() => (
              <div className="w-64">
                <Select
                  name="expiration"
                  defaultValue={DateIntervals.ONE_HOUR}
                  register={register}
                >
                  <option value={DateIntervals.ONE_HOUR}>
                    {t("hourWithCount", { count: 1 })}
                  </option>
                  <option value={DateIntervals.ONE_DAY}>
                    {t("hourWithCount", { count: 24 })}
                  </option>
                  <option value={DateIntervals.THREE_DAYS}>
                    {t("dayWithCount", { count: 3 })}
                  </option>
                  <option value={DateIntervals.ONE_WEEK}>
                    {t("weekWithCount", { count: 1 })}
                  </option>
                </Select>
              </div>
            )}
          >
            {t("setThisInviteLinkToExpireAfterAS")}
          </SecurityFeatureRow>
        </div>
      </div>
    </>
  );
};

import { Button, Sizes, useScreenSize } from "@get-frank-eng/design-system";
import * as React from "react";
import Joyride, {
  BeaconRenderProps,
  TooltipRenderProps,
  Step,
} from "react-joyride";
import useCompleteTour from "./dataAccess/useCompleteTour";
import { useCurrentTourContext } from "./CurrentTourProvider";
import { useTranslation } from "react-i18next";

export interface ExtendedStep extends Step {
  nudgeBeaconX?: number;
  nudgeBeaconY?: number;
  customOnClick?: () => void;
}

const Beacon = React.forwardRef<HTMLSpanElement, BeaconRenderProps>(
  (props, ref) => {
    const { currentStep } = React.useContext(tourContext);

    return (
      <span
        {...props}
        className="flex h-3 w-3"
        ref={ref}
        style={{
          transform: `translate(${currentStep.nudgeBeaconX || 0}px, ${
            currentStep.nudgeBeaconY || 0
          }px)`,
        }}
      >
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand-300 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-brand-300"></span>
      </span>
    );
  }
);

const Tooltip = React.forwardRef<HTMLDivElement, TooltipRenderProps>(
  (props, ref) => {
    const { t } = useTranslation();
    const { currentTourSteps } = useCurrentTourContext();
    const { completeTour } = useCompleteTour();
    return (
      <div
        className="rounded-xl bg-canvas-600 shadow-xxl border p-4 space-y-1 max-w-xs"
        {...props.tooltipProps}
      >
        <h2 className="t-small plus">{props.step.title}</h2>
        <p className="t-mini text-canvas-300">{props.step.content}</p>
        <div
          className={`flex flex-row pt-2 ${
            props.isLastStep ? "justify-end" : "justify-between"
          }`}
        >
          {!props.isLastStep && (
            <Button
              size={Sizes.XS}
              buttonStyle="outline"
              {...props.skipProps}
              onClick={(e) => {
                completeTour(currentTourSteps.type);
                props.skipProps.onClick(e);
              }}
            >
             {t("skipTour")}
            </Button>
          )}
          {props.continuous && (
            <Button buttonStyle="brand" size={Sizes.XS} {...props.primaryProps}>
              {props.isLastStep ? t("done") : t("next")}
            </Button>
          )}
        </div>
      </div>
    );
  }
);
type TourContext = {
  currentStep?: ExtendedStep;
};
const tourContext = React.createContext<TourContext>({});

const useScrollTourStepIntoView = (currentStep) => {
  const screenSize = useScreenSize();

  React.useEffect(() => {
    const currentEl = document.querySelector(
      (currentStep.target as unknown) as string
    );
    if (!currentEl) {
      return;
    }
    const position = currentEl.getBoundingClientRect();
    if (position.right > screenSize) {
      currentEl.scrollIntoView();
    }
  });
};

const Tour = ({
  steps,
  getHelpers,
  run = true,
  onTourFinish,
}: {
  steps: ExtendedStep[];
  run?: boolean;
  getHelpers?: React.ComponentProps<typeof Joyride>["getHelpers"];
  onTourFinish: () => void;
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  useScrollTourStepIntoView(steps[currentIndex]);

  return (
    <tourContext.Provider value={{ currentStep: steps[currentIndex] }}>
      <Joyride
        steps={steps}
        styles={{
          options: {
            overlayColor: "rgba(0, 0, 0, 0.4)",
          },
          overlay: {},
          spotlight: {
            border: "3px solid var(--canvas-100)",
          },
        }}
        floaterProps={{
          hideArrow: true,
        }}
        getHelpers={getHelpers}
        tooltipComponent={Tooltip}
        continuous
        disableScrollParentFix
        scrollOffset={150}
        beaconComponent={Beacon}
        callback={(event) => {
          setCurrentIndex(event.index);
          if (event.type === "tour:end") {
            onTourFinish();
          }
        }}
        run={run}
        spotlightClicks
      />
    </tourContext.Provider>
  );
};

export default Tour;

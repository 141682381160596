import uniqBy from "lodash/uniqBy";
import keyBy from "lodash/keyBy";
import * as React from "react";
import { v4 as uuid } from "uuid";
import useDataTableChanger from "./useDataTableChanger";
import { FrankBackendTypes } from "frank-types";

function useCoworkerErrorsState() {
  const [coworkerErrors, setCoworkerErrors] = React.useState<
    FrankBackendTypes.CoworkerError[]
  >([]);

  const setCoworkerErrorsUniq = React.useCallback(
    (errs: FrankBackendTypes.CoworkerError[]) => {
      setCoworkerErrors(
        uniqBy<FrankBackendTypes.CoworkerError>(
          errs,
          (err) => `${err.coworkerId}-${err.type}`
        )
      );
    },
    [setCoworkerErrors]
  );

  const addError = React.useCallback(
    (error: FrankBackendTypes.CoworkerError) => {
      setCoworkerErrorsUniq([...coworkerErrors, error]);
    },
    [setCoworkerErrorsUniq, coworkerErrors]
  );
  const clearError = React.useCallback(
    (change: FrankBackendTypes.CoworkerChangeDto) => {
      setCoworkerErrorsUniq(
        coworkerErrors.filter(
          (err) =>
            err.coworkerId !== change.id &&
            !Object.keys(change).includes(err.column)
        )
      );
    },
    [setCoworkerErrorsUniq, coworkerErrors]
  );

  return { addError, clearError, coworkerErrors };
}

export default function useRecentlyAdded({
  coworkers = [],
  allowAdd,
  defaultForNewRows = {},
  refetchFromBackend,
  addEmptyRows = false,
}: {
  coworkers: FrankBackendTypes.Coworker[];
  allowAdd: boolean;
  defaultForNewRows?: Omit<FrankBackendTypes.CoworkerChangeDto, "id">;
  refetchFromBackend: (
    args?: FrankBackendTypes.QueryCoworkersArgs
  ) => Promise<any>;
  addEmptyRows?: boolean;
}) {
  const { coworkerErrors, clearError, addError } = useCoworkerErrorsState();

  const makeNewInsertRow = React.useCallback(() => {
    return {
      id: uuid(),
      noteCount: 0,
      editable: true,
      // if we want don't people to be able to add as many rows as they want, then add this --- it's kind of glitchy too
      // isEmpty: true,
      ...defaultForNewRows,
    } as FrankBackendTypes.Coworker;
  }, [defaultForNewRows]);

  const { change, changeError, changeLoading } = useDataTableChanger({
    view: "table",
  });

  const [recentlyAdded, setRecentlyAdded] = React.useState<
    FrankBackendTypes.Coworker[]
  >([]);

  React.useEffect(() => {
    if (!addEmptyRows) {
      return setRecentlyAdded([]);
    }

    const totalCurrentCoworkers = coworkers.length;

    if (totalCurrentCoworkers > 5) {
      return setRecentlyAdded([makeNewInsertRow()]);
    }

    return setRecentlyAdded(
      new Array(5 - totalCurrentCoworkers)
        .fill(null)
        .map(() => makeNewInsertRow())
    );
    // adding makeNewInsertRow here causes infinite rendering
  }, [addEmptyRows, setRecentlyAdded, coworkers.length]);

  const applyChangeToCoworker = React.useCallback(
    (ch: FrankBackendTypes.CoworkerChangeDto | FrankBackendTypes.Coworker) => {
      const recentIds = recentlyAdded.map((r) => r.id);

      if (!recentIds.includes(ch.id)) {
        return;
      }

      const withChange = recentlyAdded.map((priorRecentlyAdded) => {
        if (priorRecentlyAdded.id !== ch.id) {
          return priorRecentlyAdded;
        }
        return { ...priorRecentlyAdded, ...ch };
      });

      setRecentlyAdded(withChange);
    },
    [recentlyAdded, setRecentlyAdded]
  );

  const computedCoworkers = React.useMemo(() => {
    return uniqBy(
      [
        ...coworkers,
        ...recentlyAdded.map((r) => {
          // I don't know if I need this gobbledegook now that we're adding rows onto the end
          if (coworkers.map((c) => c.id).includes(r.id)) {
            const coworker = coworkers.find((c) => c.id === r.id);
            return { ...coworker, ...r };
          }
          return r;
        }),
      ],
      "id"
    );
  }, [recentlyAdded, coworkers]);

  const computedCoworkersById = React.useMemo(() => {
    return keyBy(computedCoworkers, "id");
  }, [computedCoworkers]);

  const refetch = React.useCallback(async () => {
    await refetchFromBackend();
    setRecentlyAdded([]);
  }, [setRecentlyAdded, refetchFromBackend]);

  const changeAndSetRecentlyAdded = React.useCallback(
    async (ch: FrankBackendTypes.CoworkerChangeDto) => {
      const existing = computedCoworkersById[ch.id];
      // this is to solve a bug where for the first row, the default employment type is not saved. Result: you fill out a name and the employment type disappears
      const augmentedChange = {
        employmentType: existing?.employmentType,
        ...ch,
      };
      applyChangeToCoworker(augmentedChange);
      try {
        const { isNew, coworker, error } = await change(augmentedChange);
        if (error) {
          addError(error);
          refetch();
        } else {
          clearError(augmentedChange);
        }
        applyChangeToCoworker(coworker);
      } catch (e) {
        console.error("error updating coworker", e);
        applyChangeToCoworker(existing);
      }
    },
    [
      change,
      applyChangeToCoworker,
      computedCoworkersById,
      addError,
      clearError,
      refetch,
    ]
  );

  const scrollToCoworker = React.useCallback((id) => {
    const emptyNameInput = document.querySelector<HTMLInputElement>(
      `#c${id} input[type='text']`
    );
    emptyNameInput.focus();
    emptyNameInput.select();
    emptyNameInput.scrollIntoView();
    return;
  }, []);

  const addCoworker = React.useCallback(
    async function addCoworker() {
      if (!allowAdd) {
        return;
      }
      const newRow = makeNewInsertRow();
      setRecentlyAdded([...recentlyAdded, newRow]);

      setTimeout(() => {
        scrollToCoworker(newRow.id);
      }, 50);
    },
    [allowAdd, recentlyAdded, makeNewInsertRow, scrollToCoworker]
  );

  return {
    computedCoworkers,
    changeAndSetRecentlyAdded,
    changeLoading,
    changeError,
    addCoworker,
    coworkerErrors,
    refetch,
  };
}

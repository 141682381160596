import * as React from "react";
import * as spinners from "react-spinners";
import Loading from "../components/Loading";
import RedirectOutsideOfRouter from "../components/RedirectOutsideOfRouter";
import { useModals } from "../Modals";
import useResourcesData from "../Resources/dataAccess/useResourcesData";
import useSendConfirmationEmail from "./dataAccess/mutations/useSendConfirmationEmail";
import useWaitingRoomQuery from "./dataAccess/queries/useWaitingRoomQuery";
import {
  Icon,
  Sizes,
  Card,
  ResourceCard,
  Delay,
  OutlinePillBadge,
  ScreenSizes,
  useResponsive,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import useCertify from "./dataAccess/mutations/useCertify";
import { useTranslation } from "react-i18next";

const WaitingRoomSection: React.FunctionComponent<{
  title: string;
  complete: boolean;
  locked: boolean;
  openWhenComplete?: boolean;
  showPending: boolean;
  description: string;
  successDescription: React.ReactNode;
}> = ({
  title,
  complete,
  locked,
  children,
  openWhenComplete = false,
  showPending,
  description,
  successDescription,
}) => {
  const { t } = useTranslation();
  const isOpen = (!complete && !locked) || (openWhenComplete && complete);

  const status = (isPending, isComplete) => {
    if (isPending) {
      return (
        <OutlinePillBadge
          borderColor="frank-gold-300"
          textColor="frank-gold-300"
          size={Sizes.MD}
        >
          {t("pending")}
        </OutlinePillBadge>
      );
    }
    if (!isComplete) {
      return (
        <OutlinePillBadge
          borderColor="frank-gold-300"
          textColor="frank-gold-300"
          size={Sizes.MD}
        >
          {t("unverified")}
        </OutlinePillBadge>
      );
    }
    return (
      <OutlinePillBadge
        borderColor="frank-green-300"
        textColor="frank-green-300"
        size={Sizes.MD}
      >
        {t("verified")}
      </OutlinePillBadge>
    );
  };

  return (
    <div className="w-full">
      <Card className="hoverable">
        <div className="border-b p-4 sm:p-6 ">
          <div className="flex flex-row items-center mb-1">
            <div className="t-large plus mr-2">{title}</div>
            {status(showPending, complete)}
          </div>
          <div className="t-regular text-canvas-400 text-left">
            {complete ? successDescription : description}
          </div>
        </div>
        {isOpen && children}
      </Card>
    </div>
  );
};

const TaskRow = ({
  title,
  description,
  complete,
  id,
  onClick,
  buttonId,
  showRight = false,
  successDescription,
}: {
  title: string;
  description: string;
  complete: boolean;
  id: string;
  onClick?: () => void;
  buttonId?: string;
  showRight?: boolean;
  successDescription?: string;
}) => {
  const taskRowClasses =
    "p-4 sm:p-6 flex flex-row justify-between items-center border-b last:border-b-0 w-full group input-hover";

  const taskRowGuts = (
    <>
      <div className="flex-grow text-left">
        <div className="t-small plus" id={id} data-cy={id}>
          {title}
        </div>
        <div className="t-small text-canvas-400">
          {complete && successDescription ? successDescription : description}
        </div>
      </div>
      <div>
        {!complete && showRight && (
          <Icon
            size={Sizes.XL}
            icon="chevron_right"
            classNames="text-canvas-400 group-hover:text-canvas-400"
          />
        )}
        {complete && (
          <Icon
            size={Sizes.XL}
            icon="check"
            classNames="text-frank-green-300 rounded-full"
          />
        )}
      </div>
    </>
  );

  if (onClick && !complete) {
    return (
      <button
        data-cy={buttonId}
        id={buttonId}
        type="button"
        onClick={onClick}
        className={taskRowClasses}
      >
        {taskRowGuts}
      </button>
    );
  }
  return <div className={taskRowClasses}>{taskRowGuts}</div>;
};

export default function WaitingRoom() {
  const { t } = useTranslation();
  const { data: resources } = useResourcesData("onboarding");

  const { screenSize } = useResponsive();

  const isMobile = screenSize <= ScreenSizes.SM;

  const afterRedirect = isMobile
    ? process.env.REACT_APP_FRONTEND_URL + "/home"
    : process.env.REACT_APP_FRONTEND_URL + "/from-welcomebot";

  const {
    data: onboardingData,
    refetch: onboardingRefetch,
    loading,
  } = useWaitingRoomQuery();

  const { sendConfirmationEmail } = useSendConfirmationEmail();

  const issueList = React.useMemo(
    () => new Set(onboardingData?.issues.issueList),
    [onboardingData]
  );
  const { setModal } = useModals();

  const { setCertifications } = useCertify({
    onboardingId: onboardingData?.id,
  });

  const certify = React.useCallback(
    async (certification: FrankBackendTypes.OnboardingCertification) => {
      await setCertifications([
        FrankBackendTypes.OnboardingCertification.NonMgmt,
        FrankBackendTypes.OnboardingCertification.CurrentEmployee,
      ]);
      await onboardingRefetch();
    },
    [setCertifications, onboardingRefetch]
  );

  if (loading) {
    return <Loading />;
  }

  const issues = onboardingData?.issues;

  // i18n CONCATENATION

  const secondPartOfInvitedDescription = onboardingData?.inviter
    ? t("sentByInviterName", { inviterName: onboardingData.inviter.name })
    : "";

  const canShowResources =
    onboardingData?.workflowState !==
      FrankBackendTypes.OnboardingWorkflowState.Finished &&
    onboardingData?.issues.eligibilityIssuesVisibleToOnboarder.length === 0 &&
    onboardingData?.issues.authenticityIssuesVisibleToOnboarder.length === 0;
  const { group } = onboardingData;

  const needsVouch =
    issueList.has(
      FrankBackendTypes.OnboardingIssueType.NotEnoughCoworkerTrust
    ) || issueList.has(FrankBackendTypes.OnboardingIssueType.WorkerIneligible);

  const trustworthinessLocked =
    issues.eligibilityIssuesVisibleToOnboarder.length > 0 ||
    issues.authenticityIssuesVisibleToOnboarder.length > 0;

  // i18n CONCATENATION
  return (
    <div className="mb-16 grid grid-cols-1 gap-4">
      <WaitingRoomSection
        showPending={false}
        title={t("eligibility")}
        description={t("frankIsOnlyForNonManagementWorkers")}
        successDescription={
          <div className="space-y-1">
            <div>{t("thankYouForCertifyingYourWorkerStatus")}</div>
            <div className="t-small">
              {t("reviewTheCommunityAgreementAgain")}
              <a
                href="https://app.getfrank.com/legal/toc"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                {t("herePeriod")}
              </a>
            </div>
          </div>
        }
        locked={false}
        complete={
          onboardingData?.issues.eligibilityIssuesVisibleToOnboarder.length ===
          0
        }
      >
        <TaskRow
          buttonId="open-onboarding-certifications-modal"
          complete={
            !issueList.has(
              FrankBackendTypes.OnboardingIssueType.NeedsMgmtCertification
            ) &&
            !issueList.has(
              FrankBackendTypes.OnboardingIssueType
                .NeedsCurrentEmployeeCertification
            )
          }
          title={t("certifyYoureAWorkerAtYourCompany")}
          description={t("managersAreNotEligibleToUseFrank")}
          id="onboarding-certifications"
          showRight
          onClick={() =>
            setModal({
              type: "certificationModal",
              props: {
                onSubmit: certify,
              },
            })
          }
        />
      </WaitingRoomSection>
      <WaitingRoomSection
        description={t("allWorkersAreRequiredToConfirmThey")}
        successDescription={t("thankYouForConfirmingYourIdentity")}
        showPending={false}
        title={t("authenticity")}
        locked={issues.eligibilityIssuesVisibleToOnboarder.length > 0}
        complete={issues.authenticityIssuesVisibleToOnboarder.length === 0}
      >
        <TaskRow
          complete
          id="receive-invitation"
          title={t("receiveAnInvitationFromAVerifiedMe")}
          description={t("invitedViaInviteTypeSecondPart", {
            inviteType: onboardingData.inviteType,
            secondPart: secondPartOfInvitedDescription,
          })}
        />
        <TaskRow
          complete={
            !issueList.has(
              FrankBackendTypes.OnboardingIssueType.EmailOwnershipUnproven
            )
          }
          title={t("confirmYourEmailAddress")}
          id="verify-your-email-address"
          description={t("confirmEmailAddressEmailAddress", {
            emailAddress: onboardingData.validationEmail,
          })}
          successDescription={t("emailAddressConfirmed", {
            emailAddress: onboardingData.validationEmail,
          })}
          onClick={() =>
            setModal({
              type: "verifyEmailModal",
              props: {
                email: onboardingData.validationEmail,
                sendConfirmationEmail,
              },
            })
          }
          showRight
        />
        {onboardingData.requiresPassphrase && (
          <TaskRow
            complete={
              !issueList.has(
                FrankBackendTypes.OnboardingIssueType.PassphraseNeeded
              ) &&
              !issueList.has(
                FrankBackendTypes.OnboardingIssueType.PassphraseMismatch
              )
            }
            title={t("enterAPasscode")}
            id="enter-passcode"
            description={t("enterThePasscodeSharedWithYouByYo")}
            showRight
            buttonId="enter-passcode-button"
            onClick={() =>
              setModal({
                type: "enterPassphraseModal",
                props: {
                  afterSubmit: onboardingRefetch,
                  onboardingId: onboardingData.id,
                },
              })
            }
          />
        )}
      </WaitingRoomSection>
      <WaitingRoomSection
        description={t("newWorkersMustHaveSufficientSupport")}
        successDescription={t("yourCoworkersHaveVouchedForYou")}
        title={t("trustworthiness")}
        openWhenComplete
        showPending={!trustworthinessLocked && needsVouch}
        locked={
          issues.eligibilityIssuesVisibleToOnboarder.length > 0 ||
          issues.authenticityIssuesVisibleToOnboarder.length > 0
        }
        complete={!needsVouch}
      />
      {onboardingData?.workflowState ===
        FrankBackendTypes.OnboardingWorkflowState.Finished && (
        <div className="flex flex-col items-center">
          <div>
            <spinners.BeatLoader color="white" size={8} />
          </div>
          <div className="mt-2 t-small">
            {t("verificationCompleteRedirectingYouT")}
          </div>
          <Delay delay={1200}>
            <RedirectOutsideOfRouter to={afterRedirect} />
          </Delay>
        </div>
      )}
      {canShowResources && resources && (
        <div className="text-left">
          <div className="t-small pb-8 px-3 text-center">
            {t("whileYouWaitForApprovalCheckOutA")}
          </div>
          <div className="grid grid-cols-1 gap-3">
            {resources.map((resource) => (
              <ResourceCard
                topic="Labor"
                resourceLabel={resource.label}
                title={resource.title}
                url={resource.url}
                key={resource.url}
                imageUrl={resource.imageUrl}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

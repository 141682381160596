import * as React from "react";
import { FrankBackendTypes } from "frank-types";
import {
  ActionCard,
  Sizes,
  Tooltip,
  TopBar,
  Button,
  IconButton,
  IconLinkButton,
} from "@get-frank-eng/design-system";
import { useModals } from "../../Modals";
import useVoteOnAction from "../dataAccess/mutations/useVoteOnAction";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";

export default function Actions({
  campaignId,
  actions,
  relationshipToMe,
  refetchCampaign,
}: {
  campaignId: FrankBackendTypes.Campaign["id"];
  actions: FrankBackendTypes.CustomActionDto[];
  relationshipToMe: FrankBackendTypes.CampaignUserRelationship;
  refetchCampaign: () => any;
}) {
  const { t } = useTranslation();

  const { upvote, downvote } = useVoteOnAction({
    campaignId,
    afterVote: refetchCampaign,
  });
  const { setModal } = useModals();
  const { preferredLanguage } = useCurrentUserData();

  return (
    <div>
      <TopBar
        title={t("actions")}
        left={
          <IconLinkButton
            to={`../${campaignId}`}
            icon="arrow_back"
            size={Sizes.XL}
            buttonStyle="minimal"
          />
        }
        right={
          <>
            <div className="flex flex-row items-center">
              <Tooltip
                placement="left"
                trigger="hover"
                delayShow={100}
                nudgeX={-10}
                tooltip={
                  <div className="t-micro p-2 bg-canvas-700 rounded-lg">
                    {t("help")}
                  </div>
                }
                styleCard={false}
              >
                <IconButton
                  icon="help_outline"
                  size={Sizes.LG}
                  className="text-white"
                  buttonStyle="minimal"
                  onClick={() => {
                    setModal({
                      type: "actionFAQModal",
                      props: {},
                    });
                  }}
                />
              </Tooltip>
              <IconButton
                icon="add"
                size={Sizes.LG}
                className="text-white"
                buttonStyle="minimal"
                onClick={() => {
                  setModal({
                    type: "createCustomActionModal",
                    props: {
                      campaignId,
                      refetchCampaign,
                    },
                  });
                }}
              />
            </div>
          </>
        }
      />
      <section className="space-y-4 p-6">
        {actions.map((action) => (
          <ActionCard
            action={action}
            key={action.id}
            onUpvote={upvote}
            onDownvote={downvote}
            lng={preferredLanguage}
          />
        ))}
        <Button
          buttonStyle="outline"
          size={Sizes.SM}
          onClick={() =>
            setModal({
              type: "createCustomActionModal",
              props: { campaignId, refetchCampaign },
            })
          }
        >
          {t("createCustomAction")}
        </Button>
      </section>
    </div>
  );
}

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionHeader = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const constants_1 = require("../constants");
const Circle_1 = require("./Circle");
const Icon_1 = require("./Icon");
const Nudge_1 = require("./Nudge");
const Tooltip_1 = require("./Tooltip");
const SectionHeader = ({ children, rightContent, badge, count, onClick, tooltip, className, to }) => {
    const classes = `w-full truncate text-left py-2 justify-between group flex flex-row items-center rounded-lg ${className}`;
    const guts = (React.createElement(React.Fragment, null,
        " ",
        React.createElement("div", { className: "flex flex-row space-x-2 items-center" },
            React.createElement("div", { className: "t-large plus truncate" }, children),
            tooltip && (React.createElement(Nudge_1.Nudge, { className: "text-canvas-400 t-small", y: 2 },
                React.createElement(Tooltip_1.Tooltip, { placement: "bottom", trigger: "hover", tooltip: tooltip })))),
        rightContent ? (rightContent) : (React.createElement("div", { className: "flex flex-row items-center space-x-2 transition-colors text-canvas-400 group-hover:text-white" },
            badge && (React.createElement("div", { className: "px-2" },
                React.createElement(Circle_1.Circle, null))),
            count && React.createElement("span", { className: "t-large " }, count),
            React.createElement(Icon_1.Icon, { icon: "chevron_right", size: constants_1.Sizes.XL })))));
    return (React.createElement("div", { className: "border-b pb-3" }, !to ? (React.createElement("button", { onClick: onClick, disabled: !onClick, type: "button", className: classes }, guts)) : (React.createElement(react_router_dom_1.Link, { to: to, onClick: onClick, className: classes }, guts))));
};
exports.SectionHeader = SectionHeader;

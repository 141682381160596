import {
  Button,
  Checkbox,
  FormGroup,
  InlineAlert,
  Input,
  Intent,
  LinkButton,
  ScreenSizes,
  Sizes,
  useScreenSize,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useAuthState } from "../../Auth/AuthState";
import { useModals } from "../../Modals";
import AddPhoto from "../../Onboarding/components/AddPhoto";
import {
  StepContainer,
  StepDescription,
  StepLabel,
  StepTitle,
} from "../components";
import { CreateNewGroupLayout } from "../CreateNewGroupLayout";
import useConfigureUser from "../dataAccess/mutations/useConfigureUser";
import useMyPartner from "../dataAccess/queries/useMyPartner";
import { useNewGroupLayout } from "../state";
import { useTranslation } from "react-i18next";

type FormData = {
  name: string;
};

export default function Profile() {
  const { t } = useTranslation();
  const [profilePictureUrl, setProfilePictureUrl] = React.useState("");
  const { setOnboardingState } = useAuthState();
  const isMobile = useScreenSize() <= ScreenSizes.SM;
  const { configureUser, error } = useConfigureUser();
  const { dispatch, state } = useNewGroupLayout();
  const { register, errors, handleSubmit, watch, setValue } = useForm<FormData>(
    {
      defaultValues: {
        name: state.user.name,
      },
    }
  );
  const { name } = watch(["name"]);
  const { partner } = useMyPartner();

  const { setModal, stackSize } = useModals();

  const certify = React.useCallback(() => {
    dispatch({ type: "set-user", nonMgmt: true });
  }, [dispatch, setValue]);

  useEffect(() => {
    setModal({
      type: "certificationModal",
      props: {
        onSubmit: certify,
      },
    });
  }, [setModal]);

  const { push, go } = useHistory();

  const canContinue = state.user.nonMgmt && !!name;
  const onSubmit = React.useCallback(
    async (fd: FormData) => {
      dispatch({
        type: "set-user",
        name: fd.name,
        profilePictureThumbnailUrl: profilePictureUrl,
      });
      const onboardingState = await configureUser({
        name: fd.name,
        certifications: [
          FrankBackendTypes.OnboardingCertification.NonMgmt,
          FrankBackendTypes.OnboardingCertification.CurrentEmployee,
        ],
      });
      setOnboardingState({ onboardingState });

      if (partner.onboardingFormJSON) {
        push("/join/partner-questions");
      } else {
        push(isMobile ? "/home" : "/from-welcomebot");
      }
    },
    [
      configureUser,
      push,
      setOnboardingState,
      profilePictureUrl,
      partner,
      dispatch,
      isMobile,
    ]
  );

  return (
    <CreateNewGroupLayout>
      <StepContainer>
        <StepLabel>
          {t("newGroupStepCurrentStepOfTo", {
            currentStep: 2,
            totalSteps: state.steps,
          })}
        </StepLabel>
        <StepTitle>{t("createYourProfile")}</StepTitle>
        <StepDescription>
          {t("thisInformationWillBeVisibleToVeri")}
        </StepDescription>
        {!!error && (
          <div className="py-4">
            <InlineAlert
              intent={Intent.FAILURE}
              title={t("errorUpdatingProfile")}
              actions={
                <div className="flex flex-row space-x-2">
                  <Button
                    size={Sizes.SM}
                    buttonStyle="outline"
                    onClick={() => go(0)}
                  >
                    {t("refresh")}
                  </Button>
                  <LinkButton
                    to="../contact"
                    size={Sizes.SM}
                    buttonStyle="outline"
                  >
                    {t("help")}
                  </LinkButton>
                </div>
              }
            />
          </div>
        )}
        <div className="h-8" />
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="space-y-8"
        >
          <FormGroup name="name" label={t("yourFullName")} id="name">
            <Input
              dataCy="name"
              register={register}
              registerArgs={{
                maxLength: {
                  value: 120,
                  message: t("overCharacterLimit"),
                },
                required: true,
              }}
              maxLength={120}
              helpText={t("addTheNameYouUseAtWork")}
              defaultValue={state.user.name || null}
              errorText={errors.name?.message}
            />
          </FormGroup>
          <div>
            <div className="t-small plus">{t("profilePhoto")}</div>

            <AddPhoto
              defaultUrl={state.user.profilePictureThumbnailUrl || null}
              onUpload={(err, url) => {
                setProfilePictureUrl(url);
                dispatch({ type: "set-user", profilePictureThumbnailUrl: url });
                if (err) {
                  return;
                }
              }}
            />
          </div>
          {!state.user.nonMgmt && stackSize === 0 && (
            <section>
              <p className="t-small plus mb-2">{t("noManagersAllowed")}</p>
              <p className="t-small text-canvas-400">
                {t("noManagersAllowedManagementAtAnyL")}
                <a
                  href="https://app.getfrank.com/legal/toc"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  {t("termsOfUse")}
                </a>{" "}
                {t("inDetailToFullyUnderstandThePotent")}
              </p>
              <div className="pt-4">
                <label
                  className="flex flex-row items-center"
                  htmlFor="certifications-non-mgmt"
                >
                  <Checkbox
                    className="mr-3"
                    dataCy="certifications-non-mgmt"
                    id="certifications-non-mgmt"
                    name="nonMgmtCertification"
                    onChange={(e) => {
                      dispatch({
                        type: "set-user",
                        nonMgmt: !state.user.nonMgmt,
                      });
                    }}
                    defaultChecked={state.user.currentEmployee}
                    value={FrankBackendTypes.OnboardingCertification.NonMgmt}
                  />
                  <div className="t-small">
                    {t("iAmNotAManagerAtMyCompany")}
                  </div>
                </label>
              </div>
            </section>
          )}

          <div className="flex flex-row space-x-3 pb-12">
            <Button
              dataCy="continue-to-app"
              type="submit"
              buttonStyle="brand"
              iconRight="arrow_forward"
              disabled={!canContinue}
            >
              {t("continue")}
            </Button>
          </div>
        </form>
      </StepContainer>
    </CreateNewGroupLayout>
  );
}

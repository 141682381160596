interface ListenerMap {
  [key: number]: () => Promise<unknown>;
}

export default class EventEmitter {
  private listenerMap: ListenerMap = {};

  public subscribe = (listener: () => Promise<unknown>): number => {
    const key = Math.round(Math.random() * 10000);
    this.listenerMap[key] = listener;
    return key;
  };

  public resubscribe = (
    key: number,
    listener: () => Promise<unknown>
  ): void => {
    this.listenerMap[key] = listener;
  };

  public unsubscribe = (key: number): void => {
    delete this.listenerMap[key];
  };

  public unsubscribeAll = (): void => {
    this.listenerMap = {};
  };

  public trigger = async (): Promise<boolean> => {
    const success = await Object.values(this.listenerMap).reduce(
      async (prevSuccess, listener) => {
        if (prevSuccess) {
          return await listener();
        }
        return false;
      },
      true
    );
    return success;
  };
}

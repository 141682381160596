import { Button, LinkButton } from "@get-frank-eng/design-system";
import * as React from "react";
import { useHistory } from "react-router";
import { useLocationHistory } from "../hooks/useLocationHistory";
import { useTranslation } from "react-i18next";

function BackButton({ goBack }: { goBack: () => any }) {
  return (
    <Button buttonStyle="minimal" onClick={goBack} iconLeft="arrow_back">
      <span className="ml-1 t-small plus">Back</span>
    </Button>
  );
}

function Close({ goBack }: { goBack: () => any }) {
  return <Button onClick={goBack} buttonStyle="minimal" iconLeft="close" />;
}

export default function Back({
  backStyle = "back-button",
}: {
  backStyle?: "back-button" | "chevron" | "close";
}) {
  const { t } = useTranslation();
  const { lastPage } = useLocationHistory();

  const { goBack } = useHistory();

  if (!lastPage) {
    return (
      <LinkButton buttonStyle="minimal" iconLeft="arrow_back" to="/home">
        {t("home")}
      </LinkButton>
    );
  }
  switch (backStyle) {
    case "back-button":
      return <BackButton goBack={goBack} />;
    case "close":
      return <Close goBack={goBack} />;
    default:
      return null;
  }
}

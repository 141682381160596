import { useMutation, useQuery, gql } from "@apollo/client";
import * as React from "react";
import { useForm } from "react-hook-form";
import Loading from "../../components/Loading";
import { useModals } from "../../Modals";
import { generateFutureDate } from "../../utils/Date";
import BulkInviteAdvancedOptions from "./BulkInviteAdvancedOptions";
import {
  Button,
  FormGroup,
  Input,
  Textarea,
  Intent,
  useToaster,
  ModalBody,
  InlineAlert,
  ModalFooter,
  ModalHeader,
  doesEmailHaveFreeDomain,
} from "@get-frank-eng/design-system";
import { AbilityAction, FrankBackendTypes } from "frank-types";
import { useBulkInviteByEmail } from "../dataAccess/queries/mutations/useBulkInviteByEmail";
import { useConfirmEmailModal } from "../../SSO/ConfirmEmailModal";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { useTranslation } from "react-i18next";
import { onPasteEmails } from "../../utils/onPasteEmails";

const INVITE_BY_COWORKER = gql`
  mutation BulkInviteByCoworker($input: BulkInviteByCoworkerDTO!) {
    bulkInviteByCoworker(input: $input) {
      nothing
    }
  }
`;

const GROUP_QUERY = gql`
  query Groups($groupId: ID!) {
    myProfile {
      id
      name
      profilePic {
        url
        id
      }
    }
    group(groupId: $groupId) {
      id
      name
      company
      partner {
        id
        name
      }
    }
  }
`;

const EmailInvite = ({
  onSubmit,
  emails,
  coworkerIds,
  warnings,
  view = "modal",
  refetchStats,
}: {
  onSubmit?: (coworkerIds?: FrankBackendTypes.Coworker["id"][]) => any;
  emails?: string[];
  coworkerIds?: string[];
  warnings?: any;
  view?: "default" | "modal";
  refetchStats?: () => any;
}) => {
  const { t } = useTranslation();
  const { setModal } = useModals();
  const [skipVouch, setSkipVouch] = React.useState(false);

  const { currentGroup, ability } = useCurrentUserData();
  const canUpdateVouchSettings = ability?.can(
    AbilityAction.Update,
    "VouchSettings"
  );

  const openAddCoworkerDataModal = React.useCallback(
    (followUpCoworkerIds) =>
      setModal({
        type: "coworkersModal",
        props: {
          message: <>{t("yourInvitesHaveBeenSentNowToMak")}</>,
          coworkerIds: followUpCoworkerIds,
        },
      }),
    [setModal, t]
  );

  const { loading: groupLoading, data: groupData } = useQuery<{
    group: FrankBackendTypes.Group;
    myProfile: FrankBackendTypes.MyProfile;
  }>(GROUP_QUERY, { variables: { groupId: currentGroup.id } });

  const toaster = useToaster();

  const {
    bulkInviteByEmail,
    loadingInviteByEmail,
    saveErrorByEmail,
  } = useBulkInviteByEmail();

  const [
    invokeInviteByCoworker,
    { loading: loadingInviteByCoworker, error: saveErrorByCoworker },
  ] = useMutation<
    Pick<FrankBackendTypes.Mutation, "bulkInviteByCoworker">,
    FrankBackendTypes.MutationBulkInviteByCoworkerArgs
  >(INVITE_BY_COWORKER);

  const openConfirmEmailModal = useConfirmEmailModal();

  const saveError = saveErrorByCoworker || saveErrorByEmail;

  const loading = loadingInviteByCoworker || loadingInviteByEmail;
  const [inputtedEmails, setInputtedEmails] = React.useState<{
    emails?: string;
  }>({
    emails: "",
  });

  const { register, handleSubmit, errors, getValues, reset, watch } = useForm<{
    emails: string;
    message: string;
  }>();

  const submit = React.useCallback(
    async (data) => {
      if (coworkerIds) {
        await invokeInviteByCoworker({
          variables: {
            input: {
              coworkerIds,
              message: data.message,
              passphrase: data.bulkRequirePassphrase,
              expiration: data.bulkExpiration
                ? generateFutureDate(new Date(), +data.bulkExpiration)
                : undefined,
              netTrustNeeded: skipVouch ? 1 : 2,
            },
          },
        });

        if (onSubmit) {
          await onSubmit();
        }
      } else {
        const emails = data.emails
          .split(",")
          .map((email: string) => email.trim());
        const suspiciousEmails = emails.filter(
          (email) => !doesEmailHaveFreeDomain(email)
        );
        if (suspiciousEmails.length) {
          const confirm = await openConfirmEmailModal({ invite: true });
          if (!confirm) {
            return;
          }
        }
        const returnedCoworkerIds = await bulkInviteByEmail({
          emails,
          message: data.message,
          passphrase: data.bulkRequirePassphrase,
          expiration: data.bulkExpiration,
          netTrustNeeded: skipVouch ? 1 : 2,
        });
        if (onSubmit) {
          await onSubmit(returnedCoworkerIds.bulkInviteByEmail);
        }
        await openAddCoworkerDataModal(returnedCoworkerIds.bulkInviteByEmail);
        if (refetchStats) {
          await refetchStats();
        }
      }

      const numberOfEmails = inputtedEmails.emails?.split(", ").length;
      reset();

      toaster.addToast({
        intent: Intent.SUCCESS,
        children:
          numberOfEmails > 1
            ? t("countEmailInvitationsSent", { count: numberOfEmails })
            : t("inviteSent"),
      });
    },
    [
      invokeInviteByCoworker,
      toaster,
      onSubmit,
      coworkerIds,
      reset,
      openAddCoworkerDataModal,
      refetchStats,
      inputtedEmails,
      bulkInviteByEmail,
      openConfirmEmailModal,
      t,
      skipVouch,
    ]
  );

  const openModal = React.useCallback(() => {
    const { message } = getValues();

    setModal({
      type: "previewEmailModal",
      props: {
        groupName: groupData?.group.name,
        inviterName: groupData.myProfile.name,
        inviterProfilePic:
          groupData.myProfile.profilePic?.url ||
          `${process.env.REACT_APP_FRONTEND_URL}/avatar.png`,
        personalMessage: message,
        partnerName: groupData?.group.partner.name,
        partnerLogoUrl: currentGroup.partner.logoAttachment.url,
      },
    });
  }, [setModal, groupData, getValues, currentGroup]);

  const disableEmailsField = emails && emails.length > 0;
  const defaultEmails = emails ? emails.join(", ") : "";

  if (groupLoading) {
    return (
      <div className="h-72">
        <Loading />
      </div>
    );
  }

  const footer = (
    <div className="flex flex-row">
      <Button buttonStyle="minimal" onClick={openModal} type="button">
        {t("previewEmail")}
      </Button>
      <Button
        className="ml-2"
        buttonStyle="brand"
        form="email-invite-form"
        type="submit"
        loading={loading}
        disabled={!coworkerIds && !inputtedEmails.emails?.length}
      >
        {t("sendInvite")}
      </Button>
    </div>
  );

  const guts = (
    <>
      <div>
        {warnings}
        {saveError && (
          <InlineAlert title={t("serverError")} intent={Intent.FAILURE}>
            {t("thereWasAnErrorInvitingThisUser")}
          </InlineAlert>
        )}
      </div>
      <FormGroup
        label={t("emailAddressesCommaSeparated")}
        name="emails"
        id="emails"
      >
        <Input
          errorText={
            errors.emails && (
              <>
                {errors.emails.type === "required" &&
                  t("emailIsRequiredAndMustBeValid")}
                {errors.emails.type === "pattern" &&
                  t("oneOrMoreOfTheEmailAddressesYouE")}
              </>
            )
          }
          onChange={() => {
            setInputtedEmails(watch(["emails"]));
          }}
          register={register}
          onPaste={onPasteEmails}
          type="email"
          disabled={disableEmailsField}
          defaultValue={defaultEmails}
          registerArgs={{
            required: true,
            pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/gi,
          }}
        />
      </FormGroup>
      <FormGroup name="message" id="message" label={t("personalNote")}>
        <Textarea
          maxLength={1600}
          errorText={
            errors.message && (
              <>
                {errors.message.type === "required" && t("messageIsRequired")}
                {errors.message.type === "maxLength" && t("messageIsTooLong")}
              </>
            )
          }
          defaultValue={currentGroup.inviteMessage}
          register={register}
          registerArgs={{ required: true, maxLength: 1600 }}
        />
      </FormGroup>
      <div className="mb-4">
        <BulkInviteAdvancedOptions
          register={register}
          errors={errors}
          canUpdateVouchSettings={canUpdateVouchSettings}
          setValue={(v) => setSkipVouch(v)}
        />
      </div>
    </>
  );

  if (view === "modal") {
    return (
      <>
        <ModalHeader title={t("inviteViaEmail")} />
        <ModalBody>
          <form
            id="email-invite-form"
            onSubmit={handleSubmit(submit)}
            noValidate
          >
            {guts}
            <ModalFooter border additionalClasses={["pt-3"]}>
              {footer}
            </ModalFooter>
          </form>
        </ModalBody>
      </>
    );
  }

  return (
    <>
      <form id="email-invite-form" onSubmit={handleSubmit(submit)} noValidate>
        {guts}
        <div className="my-3 py-4">{footer}</div>
      </form>
    </>
  );
};

export default EmailInvite;

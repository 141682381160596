import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import ManageCollaborators from "../../Collaborators/ManageCollaborators";
import BackAndContinue from "../components/BackAndContinue";
import ContentContainer from "../components/ContentContainer";

import { useTranslation } from "react-i18next";

const Organizers = ({ campaign }: { campaign: FrankBackendTypes.Campaign }) => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <h2 className="t-title-4 plus">{t("inviteOrganizers")}</h2>
      <p className="t-regular text-canvas-400 mb-12">
        {t("addOrganizersToHelpYouEditYourPet")}
      </p>

      <ManageCollaborators campaignId={campaign.id} />
      <BackAndContinue workflowState={campaign.workflowState} />
    </ContentContainer>
  );
};

export default Organizers;

/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import Loading from "../../components/Loading";
import { useModals } from "../../Modals";
import useRecentlyAdded from "../dataAccess/useRecentlyAdded";
import useTableDataFetcher from "../dataAccess/useTableDataFetcher";
import Table from "../table";
import tablePresentations from "../table/tablePresentations";
import useIsScrolled from "../hooks/useIsScrolled";
import { useTranslation } from "react-i18next";

export default function CoworkersModal({
  coworkerIds,
  message,
}: {
  coworkerIds: FrankBackendTypes.Coworker["id"][];
  message: string | React.ReactChild;
}) {
  const { t } = useTranslation();
  const { closeModal } = useModals();
  const ref = React.useRef<HTMLDivElement>();
  const { isScrolledX } = useIsScrolled<HTMLDivElement>(ref);
  const presentation = tablePresentations.modal;

  const [sortOrder, setSortOrder] = React.useState<{
    column: FrankBackendTypes.CoworkerOrderColumns;
    order: FrankBackendTypes.Order;
  }>({ column: null, order: null });

  const augmentedPresentations = React.useMemo(() => {
    return {
      ...presentation,
      filter: {
        restrictions: [
          ...presentation.filter.restrictions,
          {
            column: FrankBackendTypes.CoworkerFilterableColummns.Id,
            combinator: FrankBackendTypes.Combinators.OneOf,
            in: coworkerIds,
          },
        ],
      } as FrankBackendTypes.CoworkerFilterDto,
    };
  }, [presentation, coworkerIds]);

  const {
    data,
    loading,
    fetchNextPage,
    refetch: refetchFromBackend,
  } = useTableDataFetcher({
    filter: augmentedPresentations.filter,
    order: sortOrder.column
      ? {
          column: sortOrder.column,
          order: sortOrder.order,
        }
      : undefined,
  });

  const {
    changeAndSetRecentlyAdded,
    changeLoading,
    computedCoworkers,
  } = useRecentlyAdded({
    refetchFromBackend,
    coworkers: data?.coworkers.objects,
    allowAdd: presentation.allowAdd,
  });

  return (
    <>
      <ModalHeader title={t("invitesSentNowAddMoreInfo")} />
      <ModalBody pad={false}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <p className="p-4 border-b">{message}</p>
            <div className="overflow-x-auto">
              <Table
                noHeaderOffset
                changeAndSetRecentlyAdded={changeAndSetRecentlyAdded}
                changeLoading={changeLoading}
                computedCoworkers={computedCoworkers}
                key={JSON.stringify(sortOrder)}
                data={data?.coworkers}
                coworkerErrors={[]}
                allowSelect={false}
                columns={augmentedPresentations.columns}
                allowAdd={augmentedPresentations.allowAdd}
                loading={loading}
                fetchNextPage={fetchNextPage}
                onSelectCoworker={() => {}}
                isScrolledX={isScrolledX}
                customColumns={data?.customColumns}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            </div>
          </>
        )}
        <ModalFooter additionalClasses={["p-3"]}>
          <>
            <div />
            <Button type="button" onClick={closeModal}>
              {t("done")}
            </Button>
          </>
        </ModalFooter>
      </ModalBody>
    </>
  );
}

import * as React from "react";
import SecurityFeatureRow from "../components/SecurityFeatureRow";
import {
  FormGroup,
  Intent,
  Select,
  useToaster,
} from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";

export default ({ maxSeats, update, token, loading, idModifier = "" }) => {
  const { t } = useTranslation();

  const toaster = useToaster();

  const updateSeats = async (seats) => {
    try {
      await update({ maxSeats: +seats }, token);
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("updateSuccessful"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
    }
  };

  return (
    <SecurityFeatureRow
      id={`limit-seats${idModifier}`}
      defaultChecked={!!maxSeats}
      title={t("limitNumberOfUses")}
      onToggle={(isChecked, dirty) => {
        if (!isChecked && dirty) {
          updateSeats(null);
          return;
        }
        if (!isChecked && maxSeats) {
          updateSeats(null);
        }
      }}
      contentToShowWhenChecked={(setDirty) => (
        <div className="w-64">
          <FormGroup label="" name="maxSeats" id="maxSeats">
            <Select
              autoFocus
              name="expiration"
              disabled={loading}
              onChange={(e: React.SyntheticEvent<HTMLSelectElement>) => {
                setDirty(true);
                updateSeats(e.currentTarget.value);
              }}
              defaultValue={maxSeats || null}
            >
              <option value={null}>{t("selectAValue")}</option>
              <option value="1">1</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Select>
          </FormGroup>
        </div>
      )}
    >
      {t("byDefaultInviteLinksCanOnlyBeUsedOnce")}
    </SecurityFeatureRow>
  );
};

import * as React from "react";
import axios from "axios";
import { useModals } from "../../Modals";
import { IconButton, Sizes } from "@get-frank-eng/design-system";
import { useTranslation } from "react-i18next";

interface TemplateArgs {
  inviterName: string;
  groupName: string;
  personalMessage: string;
  inviterProfilePic: string;
  partnerName: string;
  partnerLogoUrl: string;
}

const PreviewEmailModal = ({ ...templateArgs }: TemplateArgs) => {
  const { t } = useTranslation();
  const { modalBack } = useModals();
  const [html, setHtml] = React.useState<string>(null);
  const fetchHtml = React.useCallback(async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/email-preview/inviteEmail`,
      {
        params: templateArgs,
      }
    );
    setHtml(data);
  }, [templateArgs]);

  React.useEffect(() => {
    fetchHtml();
  }, [fetchHtml]);

  return (
    <>
      <div
        style={{ height: "85vh" }}
        className="relative w-full md:w-88 bg-canvas-800 shadow-lg z-50 border-4 border-canvas-700"
      >
        <div className="absolute right-0 top-0 transform -translate-x-4 translate-y-4">
          <IconButton
            size={Sizes.LG}
            onClick={modalBack}
            icon="close"
            tabIndex={0}
          />
        </div>
        <iframe
          title={t("invitePreview")}
          className="h-full w-full"
          srcDoc={html}
        />
      </div>
    </>
  );
};

export default PreviewEmailModal;

import { DataFieldLink, DataFieldResponseDataInput } from "./types/common";
import { FormGroup } from "@get-frank-eng/design-system/dist/components/forms/FormGroup";
import { FrankBackendTypes } from "frank-types";
import { TFunction, useTranslation } from "react-i18next";
import DataFieldResponseInput from "./DataFieldResponseInput";
import EventEmitter from "./util";

function slugifyField(formField: DataFieldLink): string {
  const slugLabel = formField.dataField.label.replace(" ", "-");
  return `${formField.order}-${slugLabel}`;
}

function getTextForError(
  error: FrankBackendTypes.CustomFormFieldErrors,
  t: TFunction<"translations", undefined>
): string {
  switch (error) {
    case FrankBackendTypes.CustomFormFieldErrors.Required:
      return t("fieldRequired");
    default:
      throw new Error("Error not supported");
  }
}

export default function CustomFormFieldResponseInput({
  field,
  onUpdate,
  response,
  disabled,
  error,
  index,
}: {
  field: DataFieldLink;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  response?: DataFieldResponseDataInput;
  disabled: boolean;
  error?: FrankBackendTypes.CustomFormFieldError;
  index: number;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="mb-6">
      <FormGroup
        name={slugifyField(field)}
        label={`${index + 1}. ${field.dataField.label}${
          field.required ? " *" : ""
        }`}
        id={slugifyField(field)}
        hasError={!!error}
      >
        <>
          <DataFieldResponseInput
            field={field.dataField}
            onUpdate={onUpdate}
            response={response}
            disabled={disabled}
          />
          {field.dataField.tooltip && (
            <span className="block px-1 text-canvas-300 whitespace-pre-line t-mini pt-1">
              {field.dataField.tooltip}
            </span>
          )}
          {error && (
            <div className="text-frank-red-400 t-mini pt-1">
              {getTextForError(error.error, t)}
            </div>
          )}
        </>
      </FormGroup>
    </div>
  );
}

import { Card, Sizes, Icon } from "@get-frank-eng/design-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useModals } from "../Modals";
import { QRInviteModal } from "./QRInviteModal";

const DefaultInvite = () => {
  const { t } = useTranslation();
  const { setModal } = useModals();
  const openEmailInviteModal = React.useCallback(() => {
    setModal({
      type: "emailInviteModal",
      props: {
        view: "modal",
      },
    });
  }, [setModal]);

  const openLinkInviteModal = React.useCallback(() => {
    setModal({
      type: "linkInviteModal",
      props: {},
    });
  }, [setModal]);

  const openQRCodeInviteModal = React.useCallback(() => {
    setModal({
      type: "genericModal",
      props: {
        body: <QRInviteModal />,
        title: t("qrCodeInvite"),
      },
    });
  }, [setModal, t]);
  return (
    <div className="p-6">
      <div className="t-regular text-canvas-400 pb-4">
        {t("everyWorkerInvitedToTheGroupMustAlso")}
      </div>
      <div className="space-y-3">
        <Card pad size={Sizes.SM} onClick={openEmailInviteModal}>
          <div className="flex flex-row justify-start items-center">
            <Icon icon="mail" size={Sizes.XL} />
            <div className="ml-3">
              <div className="t-small text-white">{t("inviteViaEmail")}</div>
              <div className="t-small text-canvas-400">
                {t("sendAnEmailToYourCoworker")}
              </div>
            </div>
          </div>
        </Card>
        <Card pad size={Sizes.SM} onClick={openLinkInviteModal}>
          <div className="flex flex-row justify-start items-center">
            <Icon icon="link" size={Sizes.XL} />
            <div className="ml-3">
              <div className="t-small text-white">
                {t("inviteViaPrivateLink")}
              </div>
              <div className="t-small text-canvas-400">
                {t("generateAUniqueLinkForCoworkersToAccess")}
              </div>
            </div>
          </div>
        </Card>
        <Card pad size={Sizes.SM} onClick={openQRCodeInviteModal}>
          <div className="flex flex-row justify-start items-center">
            <Icon icon="crop_free" size={Sizes.XL} />
            <div className="ml-3">
              <div className="t-small text-white">{t("inviteViaQRCode")}</div>
              <div className="t-small text-canvas-400">
                {t("generateAQRCode")}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default DefaultInvite;

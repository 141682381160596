import {
  Button,
  FormGroup,
  Input,
  Intent,
  Sizes,
  useToaster,
  ModalBody,
  ModalHeader,
} from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function VerifyEmailModal({
  email,
  sendConfirmationEmail,
}: {
  email: string;
  sendConfirmationEmail: () => void;
}) {
  const { t } = useTranslation();
  const toaster = useToaster();
  const onResendClick = React.useCallback(async () => {
    try {
      await sendConfirmationEmail();
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("emailConfirmationResent"),
      });
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("thereWasAnErrorResendingYourConfir"),
      });
    }
  }, [toaster, sendConfirmationEmail, t]);

  return (
    <>
      <ModalHeader title={t("emailVerification")} />
      <ModalBody>
        <p className="mb-3">{t("weveSentAConfirmationEmailToTheF")}</p>

        <FormGroup label={t("personalEmailAddress")} id="email" name="email">
          <Input type="email" disabled value={email} />
        </FormGroup>

        <p className="my-3">{t("onceYouReceiveTheEmailClickOnThe")}</p>

        <p className="my-3">{t("didntReceiveTheEmail")}</p>

        <Button
          buttonStyle="outline"
          size={Sizes.SM}
          type="button"
          onClick={onResendClick}
        >
          {t("sendConfirmationEmailAgain")}
        </Button>
      </ModalBody>
    </>
  );
}

import {
  Button,
  Checkbox,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Sizes,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { useModals } from "../../Modals";
import { useTranslation } from "react-i18next";

export default function CertificationsModal({
  onSubmit,
}: {
  onSubmit: (certification: FrankBackendTypes.OnboardingCertification) => any;
}) {
  const { t } = useTranslation();
  const [
    nonMgmtCertification,
    setNonMgmtCertification,
  ] = React.useState<FrankBackendTypes.OnboardingCertification>(null);

  const [loading, setLoading] = React.useState<boolean>(false);

  const { closeModal } = useModals();

  const submit = React.useCallback(
    async (e) => {
      try {
        setLoading(true);
        e.preventDefault();
        await onSubmit(nonMgmtCertification);
        setLoading(false);
        closeModal();
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [closeModal, onSubmit, nonMgmtCertification, setLoading]
  );

  // i18n CONCATENATION

  return (
    <>
      <ModalHeader
        title={t("noManagersAllowed")}
        specialty
        icon={
          <Icon icon="warning" classNames="text-brand-400" size={Sizes.XL} />
        }
      />
      <ModalBody>
        <form id="certifications-form" onSubmit={submit} noValidate>
          <p className="t-small mb-3">
            {t("noManagersAllowedManagementAtAnyL")}
            <a
              href="https://app.getfrank.com/legal/toc"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              {t("communityAgreement")}
            </a>{" "}
            {t("inDetailToFullyUnderstandThePotent")}
          </p>
          <div className="t-small">{t("youWillNotBeAbleToChangeYourAnsw")}</div>
          <div className="py-6">
            <label
              className="flex flex-row items-center"
              htmlFor="certifications-non-mgmt"
            >
              <Checkbox
                className="mr-3"
                dataCy="certifications-non-mgmt"
                id="certifications-non-mgmt"
                name="certifications"
                checked={
                  nonMgmtCertification ===
                  FrankBackendTypes.OnboardingCertification.NonMgmt
                }
                onChange={() =>
                  setNonMgmtCertification(
                    !nonMgmtCertification
                      ? FrankBackendTypes.OnboardingCertification.NonMgmt
                      : null
                  )
                }
                value={FrankBackendTypes.OnboardingCertification.NonMgmt}
              />
              <div className="t-small plus">
                {t("iAmNotAManagerAtMyCompany")}
              </div>
            </label>
          </div>

          <ModalFooter>
            <div className="flex flex-row w-full justify-end">
              <Button
                onClick={closeModal}
                buttonStyle="minimal"
                className="mr-2"
              >
                {t("cancel")}
              </Button>
              <Button
                form="certifications-form"
                buttonStyle="brand"
                dataCy="submit-certifications-form"
                type="submit"
                loading={loading}
                disabled={
                  nonMgmtCertification !==
                  FrankBackendTypes.OnboardingCertification.NonMgmt
                }
                onClick={submit}
              >
                {t("verify")}
              </Button>
            </div>
          </ModalFooter>
        </form>
      </ModalBody>
    </>
  );
}

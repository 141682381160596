import * as React from "react";
import { DataField } from "frank-types/dist/frank-backend-types";
import { DataFieldResponseDataInput } from "./types/common";
import { DataFieldConfigDefault } from "./types/data-field-config";
import { DataFieldResponseStringData } from "./types/data-field-response-data";
import { Input } from "@get-frank-eng/design-system/dist/components/forms/Input";

export default function DataFieldTextInput({
  field,
  onUpdate,
  data,
  disabled,
}: {
  field: DataField;
  config: DataFieldConfigDefault;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  data?: DataFieldResponseStringData;
  disabled: boolean;
}): JSX.Element {
  const updateValue = React.useCallback(
    (val) => {
      let data = undefined;
      // Unset the value if none is provided
      if (val) {
        data = { value: val };
      }
      onUpdate({ dataFieldId: field.id, data });
    },
    [onUpdate]
  );
  return (
    <Input
      type="text"
      onChange={(evt) => updateValue(evt.currentTarget.value)}
      defaultValue={data?.value ?? ""}
      disabled={disabled}
    />
  );
}

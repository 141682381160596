"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Password = void 0;
const React = __importStar(require("react"));
const constants_1 = require("../../constants");
const useComposeEventHandler_1 = require("../../hooks/useComposeEventHandler");
const useMaxLength_1 = require("../../hooks/useMaxLength");
const Icon_1 = require("../Icon");
const FormGroup_1 = require("./FormGroup");
const FormHelpText_1 = require("./FormHelpText");
const Input_1 = require("./Input");
const Password = (_a) => {
    var { helpTextRef, charCountRef, helpText, maxLength, autoComplete = "off", register, registerArgs, onChange, onFocus, id, name, errorText, disabled, size = constants_1.Sizes.MD } = _a, inputProps = __rest(_a, ["helpTextRef", "charCountRef", "helpText", "maxLength", "autoComplete", "register", "registerArgs", "onChange", "onFocus", "id", "name", "errorText", "disabled", "size"]);
    const [type, setType] = React.useState("password");
    const toggleType = React.useCallback(() => {
        setType(type === "text" ? "password" : "text");
    }, [type, setType]);
    const { onChangeForCounter, onFocusForCounter } = (0, useMaxLength_1.useMaxLength)({
        maxLength
    });
    const composedOnChange = (0, useComposeEventHandler_1.useComposeEventHandler)([
        onChange,
        onChangeForCounter
    ]);
    const composedOnFocus = (0, useComposeEventHandler_1.useComposeEventHandler)([onFocus, onFocusForCounter]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `relative ${!disabled && "hoverable"}`, style: { overflow: "visible" } },
            React.createElement("input", Object.assign({ "data-cy": "password", disabled: disabled, type: type, onChange: composedOnChange, onFocus: composedOnFocus, ref: register && register(registerArgs), autoComplete: autoComplete, id: id, name: name, className: (0, Input_1.inputClasses)({
                    hasError: !!errorText,
                    disabled: !!disabled,
                    // @ts-ignore
                    size
                }) }, inputProps)),
            React.createElement("button", { type: "button", onClick: toggleType, className: "absolute inset-y-0 right-0 px-2 m-2 flex items-center cursor-pointer focus:outline-none rounded-sm" },
                React.createElement(Icon_1.Icon, { icon: type === "password" ? "visibility" : "visibility_off", size: constants_1.Sizes.MD }))),
        helpTextRef && (React.createElement(FormGroup_1.HelpTextPortal, { elemRef: helpTextRef },
            helpText && React.createElement(FormHelpText_1.HelpText, null, helpText),
            errorText && React.createElement(FormHelpText_1.ErrorText, null, errorText)))));
};
exports.Password = Password;

import { Intent, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { isEmpty, omit } from "lodash";
import * as React from "react";
import Loading from "../../components/Loading";
import {
  useEmailForOnboardingQuery,
  useGroupAuthCardAndPartnerQuery,
  useSignAuthCardExternalMutation,
} from "../../generated-hooks";
import { useModals } from "../../Modals";
import sentry from "../../sentryClient";
import { findLanguage } from "../../utils/findLanguage";
import { AuthorizationCardComponent } from "../../Partner/components/AuthorizationCardComponent";
import { ActionSwitch } from "../components/ActionSwitch";
import { useTranslation } from "react-i18next";
import useQuery from "../../hooks/useQuery";

export const SignAuthCard = () => {
  const qs = useQuery();
  const groupId = qs.get("groupId");
  const onboardingId = qs.get("onboardingId");

  const [email, setEmail] = React.useState<string>();
  const [submitted, setSubmitted] = React.useState<boolean>(false);

  const { data, loading } = useGroupAuthCardAndPartnerQuery({
    variables: { groupId },
  });
  const { data: emailData } = useEmailForOnboardingQuery({
    variables: { onboardingId },
  });

  const [
    invokeSignAuthCardExternal,
    { loading: signAuthCardLoading, error: signAuthCardError },
  ] = useSignAuthCardExternalMutation();
  const { setModal } = useModals();
  const toaster = useToaster();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (emailData?.emailForOnboarding) {
      setEmail(emailData.emailForOnboarding);
    }
  }, [emailData]);

  const setCreateAccountModal = React.useCallback(
    ({
      submittedEmail,
      token,
      name,
      onboardingId,
      isEmailInvite,
      hasAccount,
      onboardingWorkflowState,
      partnerName,
    }: {
      submittedEmail: string;
      token: string;
      name: string;
      onboardingId: string;
      isEmailInvite: boolean;
      hasAccount: boolean;
      onboardingWorkflowState: FrankBackendTypes.OnboardingWorkflowState;
      partnerName: string;
    }) => {
      setModal({
        type: "genericModal",
        props: {
          title: isEmailInvite
            ? t("thankYou")
            : t("checkEmailToVerifySignature"),
          body: (
            <div className="space-y-4">
              <div className="text-canvas-400 t-small">
                {t("thankYouForSigningYourAuthCard", { partnerName })}
              </div>
              {!isEmailInvite ? (
                <div className="text-canvas-400 t-small">
                  {t("thereIsOneMoreStepToSubmit", { submittedEmail })}
                </div>
              ) : (
                <div className="text-canvas-400 t-small">
                  {t("youWillReceiveAReceipt", { submittedEmail })}
                </div>
              )}
              <ActionSwitch
                email={submittedEmail}
                token={token}
                name={name}
                onboardingId={onboardingId}
                hasAccount={hasAccount}
                onboardingWorkflowState={onboardingWorkflowState}
                partnerName={data?.group.partner.name}
              />
            </div>
          ),
        },
      });
    },
    [setModal, data, t]
  );

  const onSubmit = React.useCallback(
    async (formData) => {
      const authorizationCardFormValues = omit(formData, [
        "firstName",
        "lastName",
        "phone",
        "employer",
        "email",
        "signature",
      ]);

      try {
        const { data: signAuthCardData } = await invokeSignAuthCardExternal({
          variables: {
            input: {
              onboardingId,
              firstName: formData.firstName,
              lastName: formData.lastName,
              phone: formData.phone,
              employer: formData.employer,
              email: formData.email,
              signature: formData.signature,
              groupId,
              authorizationCardFormResponses: isEmpty(
                authorizationCardFormValues
              )
                ? undefined
                : JSON.stringify(authorizationCardFormValues),
            },
          },
        });
        setSubmitted(true);
        setCreateAccountModal({
          submittedEmail: formData.email,
          token: signAuthCardData.signAuthorizationCardExternal.token,
          name: `${formData.firstName} ${formData.lastName}`,
          onboardingId: signAuthCardData.signAuthorizationCardExternal.id,
          hasAccount: signAuthCardData.signAuthorizationCardExternal.onboardingCache.find(
            (v) => v.key === "createAccount.submitted"
          )?.boolValue,
          isEmailInvite: !!onboardingId,
          onboardingWorkflowState:
            signAuthCardData.signAuthorizationCardExternal.workflowState,
          partnerName: data?.group.partner.name,
        });
      } catch (e) {
        toaster.addToast({
          children: t("somethingWentWrong"),
          intent: Intent.FAILURE,
        });
        sentry.captureException(signAuthCardError);
      }
    },
    [
      groupId,
      invokeSignAuthCardExternal,
      setCreateAccountModal,
      signAuthCardError,
      toaster,
      data,
      t,
      onboardingId,
    ]
  );

  if (loading || !data) {
    return <Loading />;
  }

  if (!data.group.authorizationCard) {
    return <div>This group does not have an authorization card</div>;
  }

  return (
    <div className="flex flex-row items-center justify-center h-screen overflow-auto">
      <div className="h-screen">
        <div className="max-w-lg px-4 py-24">
          {submitted ? (
            <div>{t("confirmSubmitAuthCard")}</div>
          ) : (
            <AuthorizationCardComponent
              onSubmit={onSubmit}
              authorizationLanguage={data.group.authorizationCard?.language}
              employer={data.group.company}
              additionalFields={data.group.authorizationCard.additionalFields}
              preferredLanguage={findLanguage(navigator.language)}
              loading={signAuthCardLoading}
              showCancel={false}
              partnerLogoUrl={data.group.partner.logoAttachment.url}
              partnerName={data.group.partner.name}
              email={email}
            />
          )}
        </div>
      </div>
    </div>
  );
};

import { gql, useQuery } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";

const QUERY = gql`
  query GroupReports($partnerId: String!, $pagination: PaginationArgumentDTO!) {
    groupReports(pagination: $pagination, partnerId: $partnerId) {
      hasNext
      objects {
        id
        groupName
        company
        partnerId
        lead
        invited
        onboarding
        inGroupReview
        rejected
        member
      }
    }
  }
`;

export default function useGroupReport({ partnerId }: { partnerId: string }) {
  const { data, loading, error, refetch } = useQuery<
    Pick<FrankBackendTypes.Query, "groupReports">,
    FrankBackendTypes.QueryGroupReportsArgs
  >(QUERY, {
    variables: {
      partnerId,
      pagination: { page: 0, perPage: 100 },
    },
  });

  return {
    loading,
    refetch,
    error,
    groups: data?.groupReports,
  };
}

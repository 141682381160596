import {
  ActivityFeed,
  Button,
  coworkerStatusLabelMap,
  EmptyState,
  FormGroup,
  IconButton,
  InlineAlert,
  Intent,
  Select,
  SimpleSectionHeader,
  useToaster,
} from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { capitalize } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import Loading from "../../components/Loading";
import Pager, { PaginationStrategy } from "../../components/Pager";
import {
  useAssessCoworkerMutation,
  useAssessmentOptionsQuery,
} from "../../generated-hooks";
import { useModals } from "../../Modals";
import { dateFormatter } from "../../utils/Date";
import useAssessmentActivity from "../dataAccess/hooks/queries/useAssessmentActivity";
import useCoworkerAssessments from "../dataAccess/hooks/queries/useCoworkersAssessments";
import useGroup from "../dataAccess/hooks/queries/useGroup";
import { useRequestDataExport } from "../utilityHooks/useRequestDataExport";
import { useTranslation } from "react-i18next";

const Assessments = () => {
  const { t } = useTranslation();
  const { myProfile, currentGroup, preferredLanguage } = useCurrentUserData();
  const [groupId, setGroupId] = React.useState(currentGroup.id);
  const toaster = useToaster();
  const { setModal } = useModals();

  const { group, loading: groupLoading } = useGroup({ groupId });
  const {
    assessmentActivity,
    fetchNextPageAssessmentActivity,
    loading: assessmentActivityLoading,
    refetch: assessmentActivityRefetch,
  } = useAssessmentActivity({ groupId });

  const {
    coworkers,
    loading,
    error,
    refetch,
    fetchNextPage,
  } = useCoworkerAssessments({
    groupId,
  });

  const {
    data,
    loading: optionsLoading,
    error: optionsError,
  } = useAssessmentOptionsQuery();

  const [
    invokeAssessCoworker,
    {
      error: assessCoworkerError,
      loading: assessCoworkerLoading,
      data: assessCoworkerData,
    },
  ] = useAssessCoworkerMutation();

  const requestDataExport = useRequestDataExport();

  const assessmentOptions = React.useMemo(() => {
    if (!data?.assessmentOptions) {
      return [];
    }
    const options = [...data.assessmentOptions];
    return options.sort((a, b) => {
      if (a.value < b.value) {
        return -1;
      }
      if (b.value < a.value) {
        return 1;
      }
      return 0;
    });
  }, [data]);

  const assessCoworker = async (e, coworker) => {
    const value = e.currentTarget.value;

    if (data?.assessmentOptions.map((o) => o.id).includes(value)) {
      try {
        await invokeAssessCoworker({
          variables: {
            input: {
              coworkerId: coworker.id,
              assessmentOptionId: value,
              groupId,
            },
          },
        });
        toaster.addToast({
          intent: Intent.SUCCESS,
          children: t("success"),
        });
        await refetch();
        await assessmentActivityRefetch();
      } catch (e) {
        toaster.addToast({
          intent: Intent.FAILURE,
          children: t("somethingWentWrong"),
        });
      }
    }
  };

  if (loading || groupLoading || optionsLoading || assessmentActivityLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <InlineAlert title={t("errorLoading")} intent={Intent.FAILURE}>
        {t("somethingWentWrongWhileLoadingPlea")}
      </InlineAlert>
    );
  }

  return (
    <div className="pb-48 sm:pb-0 max-w-6xl mx-auto w-full space-y-4">
      <section className="flex flex-row space-x-8 justify-between">
        <FormGroup name="groupId" label={t("chooseGroup")} id="groupId">
          <Select
            options={myProfile.groups.map((g) => ({
              value: g.id,
              label: g.name,
              selected: g.id === groupId,
            }))}
            id="groupId"
            placeholder={t("selectAGroup")}
            defaultValue={group.id}
            onChange={(e) => setGroupId(e.currentTarget.value)}
            helpText={t("selectAGroupToManageItsAssessments") as string}
          />
        </FormGroup>
      </section>
      <div className="pb-48">
        <section className="pt-8 space-y-4">
          {!!coworkers.objects.length ? (
            <div>
              <div className="pb-4 w-full flex justify-end">
                <Button iconLeft="import_export" onClick={requestDataExport}>
                  {t("requestExport")}
                </Button>
              </div>
              <div className="overflow-x-auto rounded border">
                <table className="w-full">
                  <thead>
                    <tr className="border-b-2">
                      <th className="text-left p-2">{t("name")}</th>
                      <th className="text-left p-2">{t("email")}</th>
                      <th className="text-left p-2">{t("groupStatus")}</th>
                      <th className="text-left p-2 flex space-x-2">
                        <div>{t("assessment")}</div>
                        <div>
                          <IconButton
                            title={t("whatIsThis")}
                            icon="help"
                            buttonStyle="minimal"
                            onClick={() => {
                              setModal({
                                type: "genericModal",
                                props: {
                                  body: (
                                    <div>
                                      <div className="grid grid-cols-6 space-2 t-small plus pb-3">
                                        <div>{t("score")}</div>
                                        <div className="col-span-2">
                                          {t("label")}
                                        </div>
                                        <div className="col-span-3">
                                          {t("description")}
                                        </div>
                                      </div>
                                      {assessmentOptions.map((o) => (
                                        <div className="grid grid-cols-6 space-2 border-b py-1 t-small last:border-b-0">
                                          <div className="pl-px flex items-center">
                                            {o.value}
                                          </div>
                                          <div className="col-span-2 flex items-center">
                                            {capitalize(o.label)}
                                          </div>
                                          <div className="col-span-3 flex items-center">
                                            {capitalize(o.description)}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ),
                                },
                              });
                            }}
                          />
                        </div>
                      </th>
                      <th className="text-left p-2">{t("dateAssessed")}</th>
                      <th className="text-left p-2">{t("assessedBy")}</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-canvas-500">
                    {coworkers.objects?.map((c) => {
                      return (
                        <tr key={c.id} className="divide-x divide-canvas-500">
                          <td className="p-2">
                            {c.user ? (
                              <Link
                                className="underline"
                                to={`/users/${c.user.id}`}
                              >
                                {c.name}
                              </Link>
                            ) : (
                              c.name
                            )}
                          </td>
                          <td className="p-2">{c.email}</td>
                          <td className="p-2">
                            {
                              coworkerStatusLabelMap(preferredLanguage)[
                                c.status
                              ]
                            }
                          </td>
                          <td className="p-2">
                            <Select
                              // this is to keep the onChange from running onBlur
                              onBlur={() => {}}
                              onChange={async (e) => assessCoworker(e, c)}
                              options={[
                                { value: null, label: t("select") },
                                ...assessmentOptions.map((o) => ({
                                  value: o.id,
                                  label: `${capitalize(o.label)} (${o.value})`,
                                  selected:
                                    o.id === c.assessment?.assessmentOption.id,
                                })),
                              ]}
                            />
                          </td>
                          <td className="p-2">
                            {c.assessment
                              ? dateFormatter(preferredLanguage).format(
                                  new Date(c.assessment?.createdAt)
                                )
                              : null}
                          </td>
                          <td className="p-2">
                            {c.assessment ? c.assessment.ratedBy.name : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {coworkers.hasNext && (
                <div className="pt-8">
                  <Button onClick={() => fetchNextPage()}>
                    {t("showMore")}
                  </Button>
                </div>
              )}
              {!!assessmentActivity.objects.length && (
                <div className="pt-24">
                  <Pager<FrankBackendTypes.Activity>
                    header={<SimpleSectionHeader title={t("history")} />}
                    pagination={assessmentActivity}
                    onShowMore={fetchNextPageAssessmentActivity}
                    paginationStrategy={PaginationStrategy.SHOW_MORE}
                    showAllText={t("showMore")}
                  >
                    <ActivityFeed
                      activities={assessmentActivity.objects}
                      lng={preferredLanguage}
                    />
                  </Pager>
                </div>
              )}
            </div>
          ) : (
            <EmptyState title={t("noWorkersYet")}>
              <div>
                {t("groupNameDoesNotHaveWorkersYet", { groupName: group.name })}
              </div>
            </EmptyState>
          )}
        </section>
      </div>
    </div>
  );
};

export default Assessments;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monthLongDayYearNumeric = exports.doesEmailHaveFreeDomain = exports.ConditionalWrapper = void 0;
const freeEmailDomains_1 = require("./assets/freeEmailDomains");
const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);
exports.ConditionalWrapper = ConditionalWrapper;
// from https://knowledge.hubspot.com/forms/what-domains-are-blocked-when-using-the-forms-email-domains-to-block-feature
const doesEmailHaveFreeDomain = (email) => {
    const [first, domain] = email.split("@");
    if (freeEmailDomains_1.freeEmailDomains.includes(domain)) {
        return true;
    }
    return false;
};
exports.doesEmailHaveFreeDomain = doesEmailHaveFreeDomain;
const monthLongDayYearNumeric = (lng) => new Intl.DateTimeFormat(lng, {
    month: "long",
    year: "numeric",
    day: "numeric"
});
exports.monthLongDayYearNumeric = monthLongDayYearNumeric;

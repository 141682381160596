import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { campaignsFragment } from "../../../CampaignList/dataAccess/queries/useCampaignList";

const profileActivityFragment = gql`
  fragment ProfileActivityFragment on PaginatedActivity {
    hasNext
    total
    objects {
      time
      id
      quoteText
      htmlDescription
      link
      verb
      timeAgo
      count
      campaign {
        id
        title
      }
      objectUser {
        id
        shortName
      }
      user {
        id
        isYou
        shortName
      }
    }
  }
`;

const signedCampaignsFragment = gql`
  fragment SignedCampaignsFragment on PaginatedCampaign {
    hasNext
    objects {
      ...CampaignsFragment
    }
  }
  ${campaignsFragment}
`;

const LOAD_PROFILE = gql`
  query Profile(
    $userId: ID!
    $activityPagination: PaginationArgumentDTO!
    $signedCampaignPagination: PaginationArgumentDTO!
  ) {
    profile(userId: $userId) {
      createdAt
      id
      name
      emailVerificationDate
      isYou
      shortName
      invite {
        id
        createdAt
        inviterId
        inviter {
          name
        }
      }
      membership {
        id
        workplaceIssues
        title
      }
      profilePic {
        id
        url
      }
      organizedCampaigns {
        ...CampaignsFragment
      }
      signedCampaigns(pagination: $signedCampaignPagination) {
        ...SignedCampaignsFragment
      }
      activity(pagination: $activityPagination) {
        ...ProfileActivityFragment
      }
    }
  }
  ${profileActivityFragment}
  ${campaignsFragment}
  ${signedCampaignsFragment}
`;

const useProfileDataQuery = ({ userId, activityPerPage, campaignPerPage }) => {
  const { loading, error, data, fetchMore, refetch } = useQuery<
    {
      profile: FrankBackendTypes.User;
    },
    FrankBackendTypes.QueryProfileArgs & {
      activityPagination: FrankBackendTypes.PaginationArgumentDto;
      signedCampaignPagination: FrankBackendTypes.PaginationArgumentDto;
    }
  >(LOAD_PROFILE, {
    variables: {
      userId,
      activityPagination: {
        page: 0,
        perPage: activityPerPage,
      },
      signedCampaignPagination: {
        page: 0,
        perPage: campaignPerPage,
      },
    },
  });

  const loadNextPageActivity = React.useCallback(() => {
    fetchMore({
      variables: {
        userId,
        activityPagination: {
          perPage: activityPerPage,
          page: Math.ceil(
            data?.profile.activity.objects.length / activityPerPage
          ),
        },
      },
    });
  }, [fetchMore, userId, data, activityPerPage]);

  const loadNextSignedCampaignsPage = React.useCallback(() => {
    fetchMore({
      variables: {
        userId,
        signedCampaignPagination: {
          perPage: campaignPerPage,
          page: Math.round(
            data?.profile.signedCampaigns.objects.length / campaignPerPage
          ),
        },
      },
    });
  }, [fetchMore, userId, campaignPerPage, data]);

  return {
    loading,
    error,
    data,
    refetch,
    fetchMore,
    loadNextPageActivity,
    loadNextSignedCampaignsPage,
  };
};

export default useProfileDataQuery;

import { Icon, Sizes } from "@get-frank-eng/design-system";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const AttachmentCard = ({ attachment }) => (
  <a href={attachment.url} target="_blank" rel="noreferrer">
    <div className="t-small rounded plus text-canvas-300 flex items-center justify-between border p-5">
      <span>{attachment.filename}</span>
      <div className="ml-3">
        <Icon size={Sizes.LG} icon="attach_file" />
      </div>
    </div>
  </a>
);

const CampaignAttachmentEditList = ({
  campaignId,
  attachments,
  onUpload = () => {},
  editable = true,
}: {
  campaignId: string;
  attachments: Array<any>;
  onUpload?: () => any;
  editable?: boolean;
}) => {
  const { t } = useTranslation();

  const uploadCampaignAttachmentCallback = React.useCallback(async () => {
    const { default: uploadCampaignAttachment } = await import(
      "../../Campaigns/UploadCampaignAttachment"
    );
    uploadCampaignAttachment(campaignId, (err, url) => {
      onUpload();
    });
  }, [campaignId, onUpload]);
  return (
    <>
      <div className="grid grid-cols-3 gap-2">
        {attachments.map((attachment) => (
          <AttachmentCard key={attachment.id} attachment={attachment} />
        ))}
      </div>
      {editable && (
        <button
          type="button"
          className="block my-4 cursor-pointer t-regular plus text-canvas-400"
          onClick={uploadCampaignAttachmentCallback}
        >
          {t("addAttachment")}
        </button>
      )}
    </>
  );
};

export default CampaignAttachmentEditList;

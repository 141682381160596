import { Tooltip } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { Link } from "react-router-dom";
import PendingMemberSection from "../components/PendingMemberSection";
import TaskRow from "../components/TaskRow";
import { useTranslation } from "react-i18next";

function Description({
  pendingMember,
}: {
  pendingMember: FrankBackendTypes.Coworker;
}) {
  const { t } = useTranslation();
  if (pendingMember.trustJudgementCount === 0) {
    return (
      <>
        {t("pendingMemberNameHasntBeenMarked", {
          pendingMemberName: pendingMember.name,
        })}
      </>
    );
  }
  return (
    <>
      {pendingMember.totalTrustUpvotes > 0 && (
        <Tooltip
          hideArrow={false}
          trigger="hover"
          tooltip={
            <div>
              {pendingMember.trustedBy.map((user) => (
                <div>
                  <Link
                    key={user.id}
                    className="underline"
                    to={`/users/${user.id}`}
                  >
                    {user.shortName}
                  </Link>
                </div>
              ))}
            </div>
          }
        >
          <span className="pr-1">
            {t(
              pendingMember.totalTrustUpvotes === 1
                ? "coworkerWithCount_one"
                : "coworkerWithCount_other",
              {
                count: pendingMember.totalTrustUpvotes,
              }
            )}
            {t("votedToAdmitPendingMemberName", {
              pendingMemberName: pendingMember.name,
            })}
          </span>
        </Tooltip>
      )}
      {pendingMember.totalTrustDownvotes > 0 && (
        <Tooltip
          hideArrow={false}
          trigger="hover"
          tooltip={
            <div>
              {pendingMember.distrustedBy.map((user) => (
                <div>
                  <Link
                    key={user.id}
                    className="underline"
                    to={`/users/${user.id}`}
                  >
                    {user.shortName}
                  </Link>
                </div>
              ))}
            </div>
          }
        >
          <span data-cy="coworkers-voted-to-deny">
            {t(
              pendingMember.totalTrustDownvotes === 1
                ? "coworkerWithCount_one"
                : "coworkerWithCount_other",
              {
                count: pendingMember.totalTrustDownvotes,
              }
            )}
            {t("votedToDenyPendingMemberName", {
              pendingMemberName: pendingMember.name,
            })}
          </span>
        </Tooltip>
      )}
    </>
  );
}

export default function Trustworthiness({
  pendingMember,
}: {
  pendingMember: FrankBackendTypes.Coworker;
}) {
  const { t } = useTranslation();
  return (
    <PendingMemberSection
      tooltip={JSON.stringify(
        pendingMember.onboardingIssues.trustworthinessIssues.map((i) => i.type),
        null,
        2
      )}
      title={t("trustworthiness")}
      complete={
        pendingMember.onboardingIssues.trustworthinessIssues.length === 0
      }
    >
      <TaskRow
        dataCy="trustworthiness"
        complete={
          pendingMember.onboardingIssues.trustworthinessIssues.length === 0
        }
        title={
          pendingMember.netTrust < 2
            ? t("mustBeTrustedByAtLeast2VerifiedM")
            : t("trustedByAtLeast2VerifiedMembers")
        }
        description={<Description pendingMember={pendingMember} />}
      />
    </PendingMemberSection>
  );
}

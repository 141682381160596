import { Intent, SelectOption, useToaster } from "@get-frank-eng/design-system";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import Loading from "../../components/Loading";
import useMyPartner from "../../SSO/dataAccess/queries/useMyPartner";
import useSignAuthorizationCard from "../dataAccess/hooks/mutations/useSignAuthorizationCard";
import { AuthorizationCardComponent } from "./AuthorizationCardComponent";

type FormParams = {
  firstName: string;
  lastName: string;
  email: string;
  employer: string;
  phone: string;
  signature: string;
};

const AuthorizationCardForm = ({
  authorizationLanguage,
  isPreview = false,
  signature,
  closeModal,
  employer,
  previewAdditionalFields,
  onSubmit,
}: {
  authorizationLanguage: string;
  isPreview?: boolean;
  signature?: FrankBackendTypes.AuthorizationCardSignature;
  closeModal: () => void;
  employer?: string;
  previewAdditionalFields?: SelectOption[];
  onSubmit?: () => void;
}) => {
  const { t } = useTranslation();
  const {
    currentGroup,
    refetchCurrentUserData,
    myProfile,
    preferredLanguage,
  } = useCurrentUserData();
  const { partner, loading } = useMyPartner();
  const {
    signAuthorizationCard,
    loading: loadingAuthorizationCardSignature,
  } = useSignAuthorizationCard();

  const toaster = useToaster();

  const submit = async (fd: FormParams) => {
    try {
      await signAuthorizationCard(fd);
      if (onSubmit) {
        onSubmit();
      }
      toaster.addToast({
        intent: Intent.SUCCESS,
        children: t("success"),
      });
      await refetchCurrentUserData();
      closeModal();
    } catch (e) {
      toaster.addToast({
        intent: Intent.FAILURE,
        children: t("somethingWentWrong"),
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="space-y-4">
      <AuthorizationCardComponent
        partnerName={partner.name}
        partnerLogoUrl={partner.logoAttachment.url}
        onSubmit={submit}
        isPreview={isPreview}
        authorizationLanguage={authorizationLanguage}
        signature={signature}
        closeModal={closeModal}
        employer={employer}
        previewAdditionalFields={previewAdditionalFields}
        myProfile={myProfile}
        additionalFields={currentGroup.authorizationCard?.additionalFields}
        preferredLanguage={preferredLanguage}
        loading={loadingAuthorizationCardSignature}
        refetch={refetchCurrentUserData}
      />
    </div>
  );
};

export default AuthorizationCardForm;

import React from "react";
import { useContext } from "react";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import EventEmitter from "../util";

interface CustomFormConfig {
  groupId?: string;
  saveEmitter: EventEmitter;
}

const customFormContext = React.createContext<CustomFormConfig | null>({
  saveEmitter: new EventEmitter(),
});

export const CustomFormConfigProvider = ({
  groupId,
  saveEmitterRef,
  children,
}: {
  groupId?: string;
  saveEmitterRef: React.RefObject<EventEmitter>;
  children: React.ReactNode[];
}) => {
  const { currentGroup } = useCurrentUserData();
  return (
    <customFormContext.Provider
      value={{
        groupId: groupId ?? currentGroup?.id,
        saveEmitter: saveEmitterRef.current,
      }}
    >
      {children}
    </customFormContext.Provider>
  );
};

export default function useCustomFormConfigContext() {
  const saveListener = React.useRef<number | null>(null);
  const [saver, setSaver] = React.useState<{
    save: () => Promise<boolean>;
  } | null>(null);
  const updateSaver = React.useCallback(
    (save: () => Promise<boolean>) => {
      setSaver({ save });
    },
    [setSaver]
  );
  const context = useContext(customFormContext);
  // Listen to the save emitter
  React.useEffect(() => {
    if (saver === null) {
      return;
    }
    if (saveListener.current === null) {
      saveListener.current = context.saveEmitter.subscribe(saver.save);
    } else {
      context.saveEmitter.resubscribe(saveListener.current, saver.save);
    }
    return () => {
      context.saveEmitter.unsubscribe(saveListener.current);
    };
  }, [saver, context.saveEmitter]);
  return {
    ...context,
    updateSaver,
  };
}

import * as React from "react";
import ExpandablePendingMemberCard from "../components/ExpandablePendingMemberCard";
import usePendingMembersListData from "../dataAccess/queries/usePendingMembersListData";
import useCoworkerTrust from "../../Coworkers/dataAccess/mutations/useCoworkerTrust";
import useQuery from "../../hooks/useQuery";
import {
  Button,
  Delay,
  InlineAlert,
  PulseMessage,
  Sizes,
} from "@get-frank-eng/design-system";
import useRecentlyReviewedMembersListData from "../dataAccess/queries/useRecentlyReviewedMemberData";
import Pager, { PaginationStrategy } from "../../components/Pager";
import { FrankBackendTypes } from "frank-types";
import { useTranslation } from "react-i18next";
import sentryClient from "../../sentryClient";
import { useHistory } from "react-router";

export default function PendingMemberList({
  slim,
  onRefetch,
}: {
  slim?: boolean;
  onRefetch?: () => void;
}) {
  const { t } = useTranslation();
  const {
    pendingMemberData,
    loadingPendingMembers,
    errorPendingMembers,
    fetchMorePendingMembers,
    refetchPendingMembers,
  } = usePendingMembersListData({ perPage: 50 });

  const {
    recentlyReviewedData,
    recentlyReviewedLoading,
    recentlyReviewedError,
    fetchMoreRecentlyReviewedMembers,
    refetchRecentlyReviewed,
  } = useRecentlyReviewedMembersListData({ perPage: 50 });

  const qs = useQuery();

  const [openMemberId, setOpenMemberId] = React.useState<
    FrankBackendTypes.Coworker["id"]
  >(null);

  const history = useHistory();

  React.useEffect(() => {
    const pendingMemberId = qs.get("id");

    if (openMemberId && openMemberId !== pendingMemberId) {
      return;
    }
    if (pendingMemberId) {
      setOpenMemberId(pendingMemberId);
    }
  }, [qs, setOpenMemberId, openMemberId]);

  const {
    coworkerTrustVote,
    loadingCoworkerTrustVote,
    removeCoworkerTrustVote,
  } = useCoworkerTrust();

  const refetchAll = async () => {
    await Promise.all([refetchPendingMembers(), refetchRecentlyReviewed()]);
    if (onRefetch) {
      onRefetch();
    }
  };

  if (errorPendingMembers || recentlyReviewedError) {
    if (errorPendingMembers) {
      sentryClient.captureException(errorPendingMembers);
    }
    if (recentlyReviewedError) {
      sentryClient.captureException(recentlyReviewedError);
    }

    if (
      !pendingMemberData?.objects.length &&
      !recentlyReviewedData?.objects.length
    ) {
      return (
        <div className="space-y-3">
          {!slim && (
            <div className="t-large plus">{t("membershipRequests")}</div>
          )}
          <InlineAlert
            title={t("serverError")}
            actions={
              <Button
                iconLeft="refresh"
                onClick={() => history.go(0)}
                buttonStyle="minimal"
                size={Sizes.XL}
              >
                {t("refreshPage")}
              </Button>
            }
          />
        </div>
      );
    }
  }

  if (loadingPendingMembers || recentlyReviewedLoading) {
    return (
      <div className="space-y-3">
        {!slim && <div className="t-large plus">{t("membershipRequests")}</div>}
        <Delay delay={500}>
          <div className="space-y-6">
            <PulseMessage />
            <PulseMessage />
            <PulseMessage />
            <PulseMessage />
          </div>
        </Delay>
      </div>
    );
  }

  return (
    <div className="flex-grow">
      <div className="pb-12 border-b sm:border-0">
        {!slim && <div className="t-large plus">{t("membershipRequests")}</div>}
        {pendingMemberData?.objects.length ? (
          <div className="grid grid-cols-1 gap-2 place-items-start">
            <Pager<FrankBackendTypes.Coworker>
              paginationStrategy={PaginationStrategy.SHOW_MORE}
              onShowMore={fetchMorePendingMembers}
              pagination={pendingMemberData}
              showAllText="Show more"
            >
              {pendingMemberData.objects.map((pendingMember) => (
                <ExpandablePendingMemberCard
                  refetch={refetchAll}
                  isExpanded={
                    openMemberId === pendingMember.id ||
                    openMemberId === pendingMember.user?.id
                  }
                  setOpenMemberId={setOpenMemberId}
                  coworkerTrustVote={coworkerTrustVote}
                  removeCoworkerTrustVote={removeCoworkerTrustVote}
                  loadingCoworkerTrustVote={loadingCoworkerTrustVote}
                  key={pendingMember.id}
                  pendingMember={pendingMember}
                  canExpand
                />
              ))}
            </Pager>
          </div>
        ) : (
          <p className="t-regular text-canvas-400">
            {t("thereAreNoPendingMembershipRequests")}
          </p>
        )}
      </div>
      {!slim && (
        <div className="py-12 sm:pb-24 sm:pt-0 border-b sm:border-0">
          <div className="t-large plus">{t("recentlyReviewed")}</div>
          {recentlyReviewedData?.objects.length ? (
            <div className="grid grid-cols-1 gap-2 place-items-start">
              <Pager<FrankBackendTypes.Coworker>
                paginationStrategy={PaginationStrategy.SHOW_MORE}
                onShowMore={fetchMoreRecentlyReviewedMembers}
                pagination={recentlyReviewedData}
                showAllText="Show more"
              >
                {recentlyReviewedData.objects.map((recentlyReviewedMember) => {
                  const isMember =
                    recentlyReviewedMember.status ===
                    FrankBackendTypes.CoworkerStatus.Member;
                  return (
                    <ExpandablePendingMemberCard
                      isExpanded={
                        openMemberId === recentlyReviewedMember.id ||
                        openMemberId === recentlyReviewedMember.user?.id
                      }
                      setOpenMemberId={setOpenMemberId}
                      coworkerTrustVote={coworkerTrustVote}
                      removeCoworkerTrustVote={removeCoworkerTrustVote}
                      loadingCoworkerTrustVote={loadingCoworkerTrustVote}
                      key={recentlyReviewedMember.id}
                      pendingMember={recentlyReviewedMember}
                      refetch={refetchAll}
                      canExpand={!isMember}
                      to={
                        isMember
                          ? `/users/${recentlyReviewedMember.user.id}`
                          : null
                      }
                    />
                  );
                })}
              </Pager>
            </div>
          ) : (
            <p className="t-regular text-canvas-400">
              {t("youHaventReviewedAnyWorkersInTheLastWeek")}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

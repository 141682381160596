"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePortal = void 0;
const React = __importStar(require("react"));
function usePortal({ parentRef, classNames }) {
    const rootElemRef = React.useRef(document.createElement("div"));
    if (classNames) {
        rootElemRef.current.classList.add(classNames);
    }
    React.useEffect(function setupElement() {
        // Look for existing target dom element to append to
        const parentElem = parentRef.current;
        if (parentElem) {
            // Add the detached element to the parent
            if (parentElem.firstChild) {
                parentElem.replaceChild(rootElemRef.current, parentElem.firstChild);
            }
            else {
                parentElem.appendChild(rootElemRef.current);
            }
        }
        // This function is run on unmount
        return function removeElement() {
            if (parentElem) {
                parentElem.replaceChild(document.createElement("div"), rootElemRef.current);
            }
        };
    }, [parentRef]);
    return rootElemRef.current;
}
exports.usePortal = usePortal;

import * as React from "react";
import {
  Sizes,
  InlineAlert,
  Intent,
  EmptyState,
  FormGroup,
  Select,
  Button,
  Icon,
  useToaster,
} from "@get-frank-eng/design-system";
import Loading from "../../components/Loading";
import { useCurrentUserData } from "../../Auth/useCurrentUserData";
import { useModals } from "../../Modals";
import useAuthorizationCard from "../dataAccess/hooks/queries/useAuthorizationCard";
import AuthorizationCardForm from "../components/AuthorizationCardForm";
import { dateFormatter } from "../../utils/Date";
import useGroup from "../dataAccess/hooks/queries/useGroup";
import useAuthorizationCardUnsignedMembers from "../dataAccess/hooks/queries/useAuthorizationCardUnsignedMembers";
import useAuthorizationCardSignatures from "../dataAccess/hooks/queries/useAuthorizationCardSignatures";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";
import ViewSignedCard from "./ViewSignedCard";
import { printElementsToPages } from "../../utils/printPdf";
import AuthCardSendMethodsModal from "../modals/AuthCardSendMethodsModal";
import { FrankBackendTypes } from "frank-types";
import { useResendConfirmAuthCardMutation } from "../../generated-hooks";

const makeCsv = ({ data, exclude }: { data: any[]; exclude?: string[] }) => {
  const dataType = "data:text/csv;charset=utf-8,";
  const first = data[0];
  const columns = Object.keys(first).filter((k) => !exclude.includes(k));
  return data.reduce((acc, curr) => {
    const thisRow = columns.map((c) => curr[c]).join(",");
    return [acc, thisRow].join("\n");
  }, dataType + columns.join(","));
};

const download = (data, fileName) => {
  var encodedUri = encodeURI(data);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click();
};

const AuthorizationCards = () => {
  const { t } = useTranslation();
  const {
    myProfile,
    currentGroup,
    partner,
    preferredLanguage,
  } = useCurrentUserData();
  const toaster = useToaster();
  const [exportAuthCardsAsPDF, setExportAuthCardsAsPDF] = React.useState(false);
  const [groupId, setGroupId] = React.useState(currentGroup.id);
  const {
    authorizationCard,
    error,
    loading,
    refetch,
    nextPageSignatures,
    nextPageMembers,
  } = useAuthorizationCard(groupId);
  const [
    invokeResendConfirmAuthCardMutation,
  ] = useResendConfirmAuthCardMutation();

  const { group, loading: groupLoading } = useGroup({ groupId });

  // yeah this is pretty dumb, but this is what I got for trying to make the pagination work and stuff, with export
  const {
    unsignedMembers,
    refetchUnsignedMembers,
  } = useAuthorizationCardUnsignedMembers({
    groupId,
    page: 0,
    perPage: 25,
  });

  const { signatures, refetchSignatures } = useAuthorizationCardSignatures({
    groupId,
    page: 0,
    perPage: 100,
  });

  const additionalFields = React.useMemo(() => {
    if (!authorizationCard) {
      return [];
    }

    if (authorizationCard.additionalFields) {
      const additionalFieldsParsed = JSON.parse(
        authorizationCard.additionalFields
      );
      return Object.keys(additionalFieldsParsed["properties"]);
    }
    return [];
  }, [authorizationCard]);

  const { setModal, closeModal } = useModals();

  if (loading || groupLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <InlineAlert title={t("errorLoading")} intent={Intent.FAILURE}>
        {t("somethingWentWrongWhileLoadingPlea")}
      </InlineAlert>
    );
  }

  return (
    <div className="pb-48 sm:pb-0 max-w-6xl mx-auto w-full space-y-4">
      <section className="flex flex-row space-x-8 items-center">
        <FormGroup name="groupId" label={t("chooseGroup")} id="groupId">
          <Select
            options={myProfile.groups.map((g) => ({
              value: g.id,
              label: g.name,
              selected: g.id === groupId,
            }))}
            id="groupId"
            placeholder={t("selectAGroup")}
            defaultValue={group.id}
            onChange={(e) => setGroupId(e.currentTarget.value)}
            helpText={t("selectAGroupToManageItsCardCollec") as string}
          />
        </FormGroup>
      </section>
      {authorizationCard ? (
        <div className="pb-48">
          <section className="border rounded p-4 max-w-sm">
            <h3 className="t-regular plus pb-2">
              {t("authorizationCardStats")}
            </h3>
            <p className="t-small">
              {t("datePublished")}
              {dateFormatter(preferredLanguage).format(
                new Date(authorizationCard.createdAt)
              )}
            </p>
            <p className="t-small">
              {t("totalSignatures")}
              {authorizationCard.signatures.total}
            </p>
            <div className="flex space-x-2">
              <Button
                size={Sizes.SM}
                buttonStyle="outline"
                className="mt-2"
                onClick={() =>
                  setModal({
                    type: "genericModal",
                    props: {
                      body: (
                        <AuthorizationCardForm
                          employer={group.company}
                          isPreview
                          authorizationLanguage={authorizationCard.language}
                          closeModal={closeModal}
                        />
                      ),
                      title: t("previewAuthorizationCard"),
                    },
                  })
                }
              >
                {t("viewCard")}
              </Button>
              <Button
                size={Sizes.SM}
                buttonStyle="outline"
                className="mt-2"
                onClick={() =>
                  setModal({
                    type: "genericModal",
                    props: {
                      body: (
                        <AuthCardSendMethodsModal
                          groupId={groupId}
                          partnerName={partner.name}
                        />
                      ),
                      title: t("sendAuthorizationCard"),
                    },
                  })
                }
              >
                {t("sendCard")}
              </Button>
            </div>
          </section>
          <section className="pt-8 space-y-4">
            <div className="flex flex-row justify-between items-center">
              <div className="t-large plus">{t("signatures")}</div>
              {!!authorizationCard.signatures.objects.length && (
                <div className="space-x-2">
                  <Button
                    loading={loading}
                    size={Sizes.SM}
                    className="outline"
                    onClick={async () => {
                      setExportAuthCardsAsPDF(true);
                      if (signatures.objects.length < signatures.total) {
                        // am I sure that this works??
                        await refetchSignatures({
                          pagination: {
                            perPage: signatures.total,
                            page: 0,
                          },
                        });
                      }

                      setTimeout(async () => {
                        // need to wait here for the elements to be fully on the dom or else the pdf gets corrupted
                        await printElementsToPages({
                          className: ".authorization-card",
                          width: 132,
                          filename: `${group.name}_authorization_cards.pdf`,
                        });
                      }, 1000);
                    }}
                  >
                    {/** need to i18n this before committing .... */}
                    Download all (PDF)
                  </Button>
                  <Button
                    loading={loading}
                    size={Sizes.SM}
                    className="outline"
                    onClick={async () => {
                      if (signatures.objects.length < signatures.total) {
                        await refetchSignatures({
                          pagination: {
                            perPage: signatures.total,
                            page: 0,
                          },
                        });
                      }
                      download(
                        makeCsv({
                          data: signatures.objects.map((s) => {
                            if (!s.authCardFormResponseJSON) {
                              return s;
                            }
                            return {
                              ...s,
                              ...JSON.parse(s.authCardFormResponseJSON),
                            };
                          }),
                          exclude: [
                            "__typename",
                            "id",
                            "authCardFormResponseJSON",
                          ],
                        }),
                        "authorization-card-signatures"
                      );
                    }}
                  >
                    {t("downloadCSV")}
                  </Button>
                </div>
              )}
            </div>
            {authorizationCard.signatures.objects.length ? (
              <div className="mt-6 space-y-2 mb-16 border rounded overflow-x-auto">
                <table className="text-sm w-full">
                  <thead>
                    <tr className="border-b divide-x divide-canvas-500">
                      <th className="text-left p-2">{t("viewCard")}</th>
                      <th className="text-left p-2">{t("firstName")}</th>
                      <th className="text-left p-2">{t("lastName")}</th>
                      <th className="text-left p-2">{t("email")}</th>
                      <th className="text-left p-2">{t("employer")}</th>
                      <th className="text-left p-2">{t("phone")}</th>
                      <th className="text-left p-2">{t("dateSigned")}</th>
                      <th className="text-left p-2">Date verified</th>
                      {additionalFields.map((f) => (
                        <th className="text-left p-2" key={f}>
                          {startCase(f)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-canvas-500">
                    {authorizationCard.signatures.objects?.map(
                      (s: FrankBackendTypes.AuthorizationCardSignature) => {
                        const additionalFieldResponses = s.authCardFormResponseJSON
                          ? JSON.parse(s.authCardFormResponseJSON)
                          : {};

                        const keys = Object.keys(additionalFieldResponses);

                        return (
                          <tr key={s.id} className="divide-x divide-canvas-500">
                            <td className="p-2">
                              <Button
                                buttonStyle="outline"
                                size={Sizes.XS}
                                onClick={() =>
                                  setModal({
                                    type: "genericModal",
                                    props: {
                                      title: `${s.firstName} ${s.lastName}'s Authorization Card`,
                                      body: (
                                        <ViewSignedCard
                                          customFormId={
                                            partner.authorizationCardFormId
                                          }
                                          signatureId={s.id}
                                        />
                                      ),
                                    },
                                  })
                                }
                              >
                                <div className="flex flex-row items-center space-x-2">
                                  <Icon
                                    icon="open_in_browser"
                                    size={Sizes.MD}
                                  />
                                </div>
                              </Button>
                            </td>
                            <td className="p-2">{s.firstName}</td>
                            <td className="p-2">{s.lastName}</td>
                            <td className="p-2">{s.email}</td>
                            <td className="p-2">{s.employer}</td>
                            <td className="p-2">{s.phone}</td>
                            <td className="p-2">
                              {dateFormatter(preferredLanguage).format(
                                new Date(s.createdAt)
                              )}
                            </td>
                            <td className="p-2">
                              {s.confirmedByUser ? (
                                dateFormatter(preferredLanguage).format(
                                  new Date(s.confirmedByUser)
                                )
                              ) : (
                                <div className="flex flex-col items-center justify-center space-y-1">
                                  <div className="t-mini plus text-frank-red-400">
                                    {t("notVerified")}
                                  </div>
                                  <div>
                                    <Button
                                      size={Sizes.SM}
                                      type="button"
                                      buttonStyle="outline"
                                      onClick={async () => {
                                        try {
                                          await invokeResendConfirmAuthCardMutation(
                                            {
                                              variables: {
                                                coworkerId: s.coworkerId,
                                              },
                                            }
                                          );
                                          toaster.addToast({
                                            children: t("success"),
                                            intent: Intent.SUCCESS,
                                          });
                                        } catch (e) {
                                          toaster.addToast({
                                            children: t("somethingWentWrong"),
                                            intent: Intent.FAILURE,
                                          });
                                        }
                                      }}
                                    >
                                      {t("remind")}
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </td>
                            {keys.map((k) => (
                              <td className="p-2" key={k}>
                                {additionalFieldResponses[k]}
                              </td>
                            ))}
                            {/** even if there are no responses, there should be cells if the column exists */}
                            {keys.length < additionalFields.length &&
                              !!additionalFields.length &&
                              new Array(additionalFields.length - keys.length)
                                .fill(3)
                                .map((v, i) => <td key={i} />)}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyState title={t("noSignaturesYet")} />
            )}
            {authorizationCard.signatures.hasNext && (
              <div className="pt-8">
                <Button onClick={nextPageSignatures}>{t("showMore")}</Button>
              </div>
            )}
          </section>
          <section className="pt-8 space-y-4">
            <div className="flex flex-row justify-between items-center">
              <div className="t-large plus">{t("notSigned")}</div>
              {!!authorizationCard.unsignedMembers.objects.length && (
                <Button
                  loading={loading}
                  size={Sizes.SM}
                  className="outline"
                  onClick={async () => {
                    if (
                      unsignedMembers.objects.length < unsignedMembers.total
                    ) {
                      await refetchUnsignedMembers({
                        unsignedMembersPagination: {
                          perPage: unsignedMembers.total,
                          page: 0,
                        },
                      });
                    }
                    download(
                      makeCsv({
                        data: unsignedMembers.objects,
                        exclude: ["__typename", "id"],
                      }),
                      "not-signed"
                    );
                  }}
                >
                  {t("downloadCSV")}
                </Button>
              )}
            </div>
            {!!authorizationCard.unsignedMembers.objects.length ? (
              <div className="overflow-x-auto rounded border">
                <table className="w-full">
                  <thead>
                    <tr className="border-b-2">
                      <th className="text-left p-2">{t("name")}</th>
                      <th className="text-left p-2">{t("email")}</th>
                      <th className="text-left p-2">{t("dateJoined")}</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-canvas-500">
                    {authorizationCard.unsignedMembers.objects?.map((c) => {
                      return (
                        <tr key={c.id}>
                          <td className="p-2">{c.name}</td>
                          <td className="p-2">{c.email}</td>
                          <td className="p-2">
                            {dateFormatter(preferredLanguage).format(
                              new Date(c.joinedAt)
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyState title={t("allVerifiedMembersHaveSignedTheAut")} />
            )}
            {authorizationCard.unsignedMembers.hasNext && (
              <div className="pt-8">
                <Button onClick={nextPageMembers}>{t("showMore")}</Button>
              </div>
            )}
          </section>
        </div>
      ) : (
        <EmptyState
          title={t("noCurrentCardCollectionCampaign")}
          onClick={() =>
            setModal({
              type: "createAuthCardModal",
              props: { groupId },
              afterClose: refetch,
            })
          }
          buttonLabel={t("create")}
        >
          {t("clickTheButtonBelowToStartACardC")}
        </EmptyState>
      )}
      {exportAuthCardsAsPDF && (
        <div style={{ position: "absolute", left: "-1000px", top: "-1000px" }}>
          {signatures.objects.map((s) => (
            <ViewSignedCard
              key={s.id}
              customFormId={partner.authorizationCardFormId}
              signatureId={s.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AuthorizationCards;

import * as React from "react";
import { DataField } from "frank-types/dist/frank-backend-types";
import { DataFieldResponseDataInput } from "./types/common";
import { DataFieldConfigCurrency } from "./types/data-field-config";
import { DataFieldResponseCurrencyData } from "./types/data-field-response-data";
import { NumericFormat } from "react-number-format";

const currencyRegex = /(\d+\.?\d*)/;

export default function DataFieldCurrencyInput({
  field,
  config,
  onUpdate,
  data,
  disabled,
}: {
  field: DataField;
  config: DataFieldConfigCurrency;
  onUpdate: (value: DataFieldResponseDataInput) => void;
  data?: DataFieldResponseCurrencyData;
  disabled: boolean;
}): JSX.Element {
  const [rawValue, setRawValue] = React.useState(data?.value ?? "");
  React.useEffect(() => {
    setRawValue(data?.value);
  }, [data]);
  const updateValue = React.useCallback(
    (enteredValue: string | undefined) => {
      setRawValue(enteredValue);
      onUpdate({
        dataFieldId: field.id,
        data: enteredValue ? { value: enteredValue } : undefined,
      });
    },
    [setRawValue, field, onUpdate]
  );

  return (
    <NumericFormat
      type="text"
      value={rawValue}
      valueIsNumericString
      onValueChange={(values) => updateValue(values.value)}
      disabled={disabled}
      thousandSeparator=","
      prefix="$"
      className={`t-small relative inset-0 placeholder-canvas-400 bg-transparent block w-full border rounded px-3 overflow-hidden disabled:text-canvas-400 disabled:bg-canvas-700 ${
        disabled ? "border-canvas-500" : ""
      }`}
    />
  );
}

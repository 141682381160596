import { gql, useQuery } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";

const QUERY = gql`
  query AssessmentActivity($pagination: PaginationArgumentDTO!, $groupId: ID!) {
    group(groupId: $groupId) {
      id
      assessmentActivity(pagination: $pagination) {
        objects {
          id
          time
          htmlDescription
          verb
          timeAgo
          user {
            id
            shortName
            isYou
          }
        }
        total
        hasNext
      }
    }
  }
`;

export default function useAssessmentActivity({
  groupId,
}: {
  groupId: string;
}) {
  const perPage = 5;
  const { data, loading, error, refetch, fetchMore } = useQuery<
    Pick<FrankBackendTypes.Query, "group">
  >(QUERY, {
    fetchPolicy: "network-only",
    variables: {
      pagination: {
        perPage,
        page: 0,
      },
      groupId,
    },
  });

  const fetchNextPage = React.useCallback(() => {
    fetchMore({
      variables: {
        pagination: {
          perPage,
          page: Math.round(
            data.group.assessmentActivity.objects.length / perPage
          ),
        },
        groupId,
      },
    });
  }, [fetchMore, data, perPage, groupId]);

  return {
    loading,
    error,
    refetch,
    assessmentActivity: data?.group.assessmentActivity,
    fetchNextPageAssessmentActivity: fetchNextPage,
  };
}

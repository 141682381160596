function howManyAgo(value: number, word: string): string {
  const valueFloor = Math.floor(value);
  if (valueFloor !== 1) {
    return `${valueFloor} ${word}s ago`;
  }
  return `1 ${word} ago`;
}

export function convertJsonDateToString(jsonDate: string): string {
  const dateObj = new Date(jsonDate);
  const now = new Date();
  const difference = (now.getTime() - dateObj.getTime()) / 1000;
  let output = "";
  if (difference < 60) {
    // Less than a minute has passed:
    output = "just now";
  } else if (difference < 3600) {
    // Less than an hour has passed:
    output = howManyAgo(difference / 60, "minute");
  } else if (difference < 86400) {
    // Less than a day has passed:
    output = howManyAgo(difference / 3600, "hour");
  } else if (difference < 604800) {
    // Less than a week has passed:
    output = howManyAgo(difference / 86400, "day");
  } else {
    output = dateObj.toLocaleDateString();
  }
  return output;
}

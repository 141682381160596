import { Button, IconButton, Sizes } from "@get-frank-eng/design-system";
import {
  DataField,
  DataFieldError,
  SubmitDataFieldResponseError,
} from "frank-types/dist/frank-backend-types";
import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import DataFieldResponseInput from "./DataFieldResponseInput";
import { DataFieldResponseDataInput } from "./types/common";
import EventEmitter from "./util";

export interface RowData {
  id?: string;
  key: string;
  cells: CellData[];
}

export interface CellData {
  field: DataField;
  response?: DataFieldResponseDataInput;
}

export type OnUpdateCell = (
  rowNumber: number,
  cellNumber: number,
  response: DataFieldResponseDataInput
) => void;

function getTextForError(
  error: SubmitDataFieldResponseError,
  t: TFunction<"translations", undefined>
): string {
  switch (error) {
    case SubmitDataFieldResponseError.Required:
      return t("fieldRequired");
    default:
      throw new Error("Error: unknown error");
  }
}

const DataFieldTableRow = ({
  disabled,
  rowIndex,
  tableRow,
  updateRow,
  deleteRow,
  rowErrors,
}: {
  disabled: boolean;
  rowIndex: number;
  deleteRow: (rowIndex: number) => void;
  tableRow: RowData;
  updateRow: (rowNumber: number, newRow: RowData) => void;
  rowErrors: DataFieldError[];
}) => {
  const { t } = useTranslation();
  const onUpdateCell = React.useCallback(
    (cellNumber: number, response: DataFieldResponseDataInput) => {
      const newCell = { ...tableRow.cells[cellNumber], response };
      const newRow = [...tableRow.cells];
      newRow[cellNumber] = newCell;
      updateRow(rowIndex, {
        ...tableRow,
        cells: newRow,
      });
    },
    [rowIndex, tableRow, updateRow]
  );
  const fieldErrorMap: { [fieldId: string]: DataFieldError } = React.useMemo(
    () =>
      rowErrors.reduce((prevMap, fieldError) => {
        prevMap[fieldError.dataFieldId] = fieldError;
        return prevMap;
      }, {}),
    [rowErrors]
  );
  return (
    <tr className="border-b bg-canvas-700 border-canvas-500">
      {tableRow.cells.map((cell, cellIndex) => (
        <td className="px-6 py-2" key={cellIndex}>
          <DataFieldResponseInput
            field={cell.field}
            onUpdate={(response) => onUpdateCell(cellIndex, response)}
            response={cell.response}
            disabled={disabled}
          />
          {fieldErrorMap[cell.field.id] && (
            <div className="text-frank-red-400 t-mini pt-1">
              {getTextForError(fieldErrorMap[cell.field.id].error, t)}
            </div>
          )}
        </td>
      ))}
      {!disabled && (
        <td className="px-2">
          <IconButton
            icon="delete"
            buttonStyle="minimal"
            size={Sizes.LG}
            onClick={() => deleteRow(rowIndex)}
          />
        </td>
      )}
    </tr>
  );
};

export default DataFieldTableRow;

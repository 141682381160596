import { FrankBackendTypes } from "frank-types";
import i18n from "../i18n/config";

export const welcomeTourSteps = ({ isMobile, fromFrankName }) =>
  [
    {
      target: `#${FrankBackendTypes.NavigationSubjectTypes.Group}`,
      title: i18n.t("buildYourGroup"),
      content: i18n.t("useTheCoworkersPageToOrganizeYour"),
      disableBeacon: true,
    },
    {
      target: `.${FrankBackendTypes.NavigationSubjectTypes.PublicTopic}`,
      title: i18n.t("talkToYourCoworkers"),
      content: i18n.t("topicsHelpYouStartConversationsAbou"),
      disableBeacon: true,
    },
    {
      target: `#${FrankBackendTypes.NavigationSubjectTypes.CreateCampaign}`,
      title: i18n.t("beHeardByManagement"),
      content: i18n.t("whenYoureReadyYouCanWriteAPetit"),
      disableBeacon: true,
    },
    {
      target: `#${FrankBackendTypes.NavigationSubjectTypes.Invite}`,
      title: i18n.t("inviteCoworkers"),
      content: i18n.t("inviteOneOrTwoOfYourMostTrustedC"),
      disableBeacon: true,
    },
    isMobile
      ? {
          target: `#${FrankBackendTypes.NavigationSubjectTypes.FromFrank}`,
          title: i18n.t("getStarted"),
          content: i18n.t("nowThatYouKnowWhereEverythingIsC", {
            fromFrankName,
          }),
          disableBeacon: true,
        }
      : null,
  ].filter((step) => !!step);

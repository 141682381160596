import { FrankBackendTypes } from "frank-types";
import columnDefs from "./Columns";

/**
 *
 *
 * presentationConfiguration
 * configuration
 * viewConfigurations
 * presentations
 * tablePresentations
 * tableConfigurations
 * tableViewConfigurations
 */

export interface TablePresentation {
  columns: any[];
  actions: string[];
  allowAdd: boolean;
  filter: FrankBackendTypes.CoworkerFilterDto;
}

const tablePresentations: { [key: string]: TablePresentation } = {
  modal: {
    allowAdd: false,
    filter: {
      restrictions: [],
    },
    actions: [],
    columns: [columnDefs.name, columnDefs.email, columnDefs.employmentType],
  },
  inviteCoworkerToCollaborate: {
    filter: {
      restrictions: [
        {
          column: FrankBackendTypes.CoworkerFilterableColummns.Status,
          combinator: FrankBackendTypes.Combinators.OneOf,
          in: ["lead", "onboarding", "invited", "member"],
        },
      ],
    },
    actions: ["inviteCoworkerToCollaborate"],
    allowAdd: true,
    columns: [
      columnDefs.name,
      columnDefs.status,
      columnDefs.email,
      columnDefs.employmentType,
      columnDefs.notes,
      columnDefs.joinedAt,
    ],
  },
  workers: {
    filter: {
      restrictions: [
        {
          column: FrankBackendTypes.CoworkerFilterableColummns.Status,
          combinator: FrankBackendTypes.Combinators.OneOf,
          in: ["lead", "onboarding", "invited", "in-group-review", "rejected"],
        },
        {
          column: FrankBackendTypes.CoworkerFilterableColummns.Status,
          combinator: FrankBackendTypes.Combinators.NotOneOf,
          in: ["archived", "flagged", "admin", "deleted"],
        },
        {
          column: FrankBackendTypes.CoworkerFilterableColummns.EmploymentType,
          combinator: FrankBackendTypes.Combinators.NotEquals,
          stringValue: "management",
        },
      ],
    },
    actions: ["invite", "archive"],
    allowAdd: true,
    columns: [
      columnDefs.name,
      columnDefs.employmentType,
      columnDefs.email,
      columnDefs.trust,
      columnDefs.notes,
    ],
  },
  management: {
    filter: {
      restrictions: [
        {
          column: FrankBackendTypes.CoworkerFilterableColummns.EmploymentType,
          combinator: FrankBackendTypes.Combinators.OneOf,
          in: ["management"],
        },
        {
          column: FrankBackendTypes.CoworkerFilterableColummns.Status,
          combinator: FrankBackendTypes.Combinators.NotOneOf,
          in: ["archived", "admin"],
        },
      ],
    },
    actions: ["archive"],
    allowAdd: true,
    columns: [
      columnDefs.name,
      columnDefs.employmentType,
      columnDefs.email,
      columnDefs.notes,
    ],
  },
  members: {
    filter: {
      restrictions: [
        {
          column: FrankBackendTypes.CoworkerFilterableColummns.Status,
          combinator: FrankBackendTypes.Combinators.OneOf,
          in: ["member", "organizer"],
        },
      ],
    },
    actions: ["flag"],
    allowAdd: false,
    columns: [
      columnDefs.name,
      columnDefs.employmentType,
      columnDefs.joinedAt,
      columnDefs.email,
    ],
  },
  archived: {
    filter: {
      restrictions: [
        {
          column: FrankBackendTypes.CoworkerFilterableColummns.Status,
          combinator: FrankBackendTypes.Combinators.OneOf,
          in: ["archived"],
        },
      ],
    },
    actions: ["unarchive"],
    allowAdd: false,
    columns: [
      columnDefs.status,
      columnDefs.name,
      columnDefs.email,
      columnDefs.employmentType,
      columnDefs.notes,
    ],
  },
};

export default tablePresentations;

import { useQuery, gql } from "@apollo/client";
import { pendingMemberDetailFragment } from "../../PendingMembers/dataAccess/queries/usePendingMembersListData";
import { resourceFragment } from "../../Resources/dataAccess/useResourcesData";
import { homeFeedFragment } from "./useHomeFeedData";
import { campaignsFragment } from "../../CampaignList/dataAccess/queries/useCampaignList";
import { defaultPendingMemberOrder } from "../../PendingMembers/dataAccess/orders";
import { pendingMemberCoworkersRestriction } from "../../PendingMembers/dataAccess/filters";
import { FrankBackendTypes } from "frank-types";
import { taskAssignmentFragment } from "../../Tasks/dataAccess/taskAssignmentFragment";
import attachmentFragment from "../../Attachment/dataAccess/attachmentFragment";

const QUERY = gql`
  query HomePage(
    $feedPagination: PaginationArgumentDTO!
    $coworkerPagination: PaginationArgumentDTO!
    $coworkerFilter: CoworkerFilterDTO!
    $coworkerOrder: CoworkerOrderArgumentDTO
    $secondCoworkerOrder: CoworkerOrderArgumentDTO
    $campaignWorkflowState: CampaignWorkflowState
    $assignmentPagination: PaginationArgumentDTO!
    $assignmentCoworkerId: ID
    $assignmentWorkflowState: AssignmentWorkflowState
  ) {
    myProfile {
      id
      homeFeed(pagination: $feedPagination) {
        ...HomeFeedFragment
      }
      currentGroup {
        id
        customResources {
          id
          title
          attachment {
            ...AttachmentFragment
          }
        }
        partner {
          id
          customResources {
            id
            title
            attachment {
              ...AttachmentFragment
            }
          }
        }
      }
    }
    coworkers(
      pagination: $coworkerPagination
      filter: $coworkerFilter
      order: $coworkerOrder
      secondOrder: $secondCoworkerOrder
    ) {
      ...PendingMemberDetailFragment
    }
    resources(tag: "home") {
      ...ResourceFragment
    }
    campaigns(workflowState: $campaignWorkflowState) {
      ...CampaignsFragment
    }
    taskAssignments(
      workflowState: $assignmentWorkflowState
      pagination: $assignmentPagination
      coworkerId: $assignmentCoworkerId
    ) {
      hasNext
      total
      objects {
        ...TaskAssignmentFragment
      }
    }
  }
  ${homeFeedFragment}
  ${pendingMemberDetailFragment}
  ${resourceFragment}
  ${campaignsFragment}
  ${taskAssignmentFragment}
  ${attachmentFragment}
`;

const PERPAGE_ACTIVITY = 3;
const PERPAGEPENDINGMEMBER = 4;
const PERPAGE_ASSIGNMENT = 4;

export default function useHomePageData({ coworkerId }) {
  const { loading, data, refetch, error } = useQuery<
    {
      myProfile: FrankBackendTypes.MyProfile;
      coworkers: FrankBackendTypes.PaginatedCoworker;
      resources: FrankBackendTypes.Resource[];
      campaigns: FrankBackendTypes.Campaign[];
      taskAssignments: FrankBackendTypes.PaginatedTaskAssignment;
    },
    {
      feedPagination: FrankBackendTypes.PaginationArgumentDto;
      coworkerPagination: FrankBackendTypes.PaginationArgumentDto;
      coworkerFilter: FrankBackendTypes.CoworkerFilterDto;
      coworkerOrder: FrankBackendTypes.CoworkerOrderArgumentDto;
      secondCoworkerOrder: FrankBackendTypes.CoworkerOrderArgumentDto;
      campaignWorkflowState: FrankBackendTypes.CampaignWorkflowState;
      assignmentPagination: FrankBackendTypes.PaginationArgumentDto;
      assignmentCoworkerId: string;
      assignmentWorkflowState: FrankBackendTypes.AssignmentWorkflowState;
    }
  >(QUERY, {
    fetchPolicy: "network-only",
    variables: {
      feedPagination: { page: 0, perPage: PERPAGE_ACTIVITY },
      coworkerPagination: { page: 0, perPage: PERPAGEPENDINGMEMBER },
      coworkerOrder: defaultPendingMemberOrder.order,
      secondCoworkerOrder: defaultPendingMemberOrder.secondOrder,
      coworkerFilter: pendingMemberCoworkersRestriction,
      campaignWorkflowState: FrankBackendTypes.CampaignWorkflowState.Published,
      assignmentWorkflowState:
        FrankBackendTypes.AssignmentWorkflowState.Incomplete,
      assignmentPagination: { page: 0, perPage: PERPAGE_ASSIGNMENT },
      assignmentCoworkerId: coworkerId,
    },
    pollInterval: 300000,
  });

  return {
    data,
    loading,
    error,
    refetch,
  };
}

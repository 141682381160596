import React from "react";
import { AuthCardAction } from "frank-types/dist/frank-backend-types";
import { useCurrentUserData } from "../../../Auth/useCurrentUserData";
import { useModals } from "../../../Modals";
import AuthorizationCardForm from "../../../Partner/components/AuthorizationCardForm";
import ActionListItem from "./ActionListItem";
import { useTranslation } from "react-i18next";

const AuthCardActionItem = ({
  authCard,
  onUpdate,
}: {
  authCard: AuthCardAction;
  onUpdate: () => Promise<void>;
}) => {
  const { setModal } = useModals();
  const { currentGroup } = useCurrentUserData();
  const { t } = useTranslation();
  const defaultAction = () =>
    setModal({
      type: "genericModal",
      props: {
        body: (
          <AuthorizationCardForm
            authorizationLanguage={authCard.language}
            onSubmit={onUpdate}
            closeModal={() => null}
            employer={currentGroup.company}
          />
        ),
        title: t("signYourAuthorizationCard"),
      },
    });
  const actions = [
    {
      label: t("sign"),
      action: defaultAction,
    },
  ];
  return (
    <ActionListItem
      title={t("signYourAuthorizationCard")}
      subheading={`${t("dateAssigned")}: ${authCard.createdAt}`}
      actions={actions}
      defaultAction={defaultAction}
    />
  );
};

export default AuthCardActionItem;

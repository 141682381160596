import {
  Accordion,
  ModalBody,
  ModalHeader,
} from "@get-frank-eng/design-system";
import * as React from "react";

import { useTranslation } from "react-i18next";

// must alter Modals.tsx if you need to add props e.g. <Modal {...modal.props} />

const ActionFAQModal = () => {
  const { t } = useTranslation();

  return (
    <>
      <ModalHeader title="Help" />
      <ModalBody>
        <>
          <h2 className="t-large plus">{t("collectiveAction")}</h2>
          <p className="t-regular text-canvas-400">
            {t("collectiveActionInTheWorkplaceHappe")}
          </p>
          <h2 className="t-large plus pt-6 pb-3">{t("faQs")}</h2>
          <Accordion title={t("whatAreActions")}>
            <p className="mb-3 text-canvas-400">
              {t("whenYourCampaignIsDeliveredToManag")}
            </p>
            <p className="text-canvas-400">
              {t("theActionsListedHereAreAFewCommon")}
            </p>
          </Accordion>
          <Accordion title={t("whatDoesUpvotingMean")}>
            <p className="mb-3 text-canvas-400">
              {t("upvotingAnActionMeansYouAreWilling")}
            </p>

            <p className="text-canvas-400">
              {t("downvotingMeansYouAreNotWillingTo")}
            </p>
          </Accordion>
          <Accordion title={t("doIHaveToParticipateInActions")}>
            <div className="text-canvas-400">
              {t("upvotingOrDownvotingActionsDoesNot")}
            </div>
          </Accordion>

          <Accordion title={t("whatAreTheRisksOfTakingAction")}>
            <div className="text-canvas-400">
              {t("theseActionsHaveVaryingDegreesOfRi")}
            </div>
          </Accordion>

          <Accordion title={t("areThereOtherActionsWeCanTake")}>
            <div className="text-canvas-400">
              {t("yesAsAGroupOfWorkersYouAreEntir")}
            </div>
          </Accordion>
        </>
      </ModalBody>
    </>
  );
};

export default ActionFAQModal;

import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import Tour, { ExtendedStep } from ".";
import { useModals } from "../Modals";
import useCompleteTour from "./dataAccess/useCompleteTour";
import { useNavigationState } from "../Navigation/hooks/useNavigationState";
import { coworkersTourSteps } from "./CoworkersTour";
import { welcomeTourSteps } from "./WelcomeTour";
import { observe } from "selector-observer";
import { ScreenSizes, useScreenSize } from "@get-frank-eng/design-system";
import { useCurrentUserData } from "../Auth/useCurrentUserData";

interface TourSteps {
  modalArgs?: any;
  steps: ExtendedStep[];
  type: FrankBackendTypes.TourType;
}

type CurrentTourContext = {
  currentTourSteps?: TourSteps;
  setRetakingTour: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentTour: React.Dispatch<
    React.SetStateAction<FrankBackendTypes.TourType>
  >;
  currentTour?: FrankBackendTypes.TourType;
};
const currentTourContext = React.createContext<CurrentTourContext>({
  setRetakingTour: () => {},
  setCurrentTour: () => {},
});

function useIsNodePresent(selector) {
  const [present, setPresent] = React.useState(false);

  React.useEffect(() => {
    setPresent(false);
    const observer = observe(selector, {
      add(el) {
        setPresent(true);
      },
      remove(el) {
        setPresent(false);
      },
    });
    return () => observer.abort();
  }, [selector]);
  return present;
}

export const CurrentTourProvider = ({ children }) => {
  const { refetch } = useNavigationState();
  const [modalClosed, setModalClosed] = React.useState(false);
  const { completeTour } = useCompleteTour();
  const { setModal } = useModals();
  const isMobile = useScreenSize() < ScreenSizes.MD;
  const { myProfile, currentGroup, partner } = useCurrentUserData();
  const [retakingTour, setRetakingTour] = React.useState(false);
  const [
    currentTour,
    setCurrentTour,
  ] = React.useState<FrankBackendTypes.TourType>(null);

  const finishTour = React.useCallback(
    async (tour) => {
      await completeTour(tour);
      await refetch();
      setCurrentTour(null);
    },
    [completeTour, refetch]
  );

  const tours: { [index: string]: TourSteps } = React.useMemo(
    () => ({
      [FrankBackendTypes.TourType.Welcome]: {
        modalArgs: !retakingTour && {
          type: "welcomeModal",
          afterClose: () => setModalClosed(true),
          props: {
            callback: () => setModalClosed(true),
            workerType: myProfile.workerType,
            groupName: currentGroup.name,
            groupLogoUrl: currentGroup.logo?.url,
            partnerLogoUrl: partner.logoAttachment.url,
            currentGroup: currentGroup,
            partner: partner,
            setCurrentTour,
          },
        },
        steps: welcomeTourSteps({
          isMobile,
          fromFrankName: `#from-${partner.slug}`,
        }),
        type: FrankBackendTypes.TourType.Welcome,
      },
      [FrankBackendTypes.TourType.Coworkers]: {
        steps: coworkersTourSteps({
          isMobile,
          fromFrankName: `#from-${partner.slug}`,
        }),
        type: FrankBackendTypes.TourType.Coworkers,
      },
    }),
    [
      setModalClosed,
      isMobile,
      myProfile.workerType,
      currentGroup,
      partner,
      retakingTour,
    ]
  );

  const currentTourSteps = React.useMemo(() => {
    return tours[currentTour];
  }, [currentTour, tours]);

  const isPresent = useIsNodePresent(currentTourSteps?.steps[0].target);

  React.useEffect(() => {
    if (!currentTourSteps) {
      return;
    }
    if (currentTourSteps.modalArgs && !modalClosed) {
      setModal(currentTourSteps.modalArgs);
    }
  }, [setModal, currentTourSteps, modalClosed]);

  const modalClosedOrNotPresent = currentTourSteps?.modalArgs
    ? modalClosed
    : true;

  const shouldShowTour =
    modalClosedOrNotPresent && currentTourSteps && isPresent;

  return (
    <currentTourContext.Provider
      value={{ currentTourSteps, setRetakingTour, setCurrentTour, currentTour }}
    >
      {children}
      {shouldShowTour && (
        <Tour
          key={currentTourSteps.type}
          steps={currentTourSteps.steps}
          onTourFinish={() => finishTour(currentTourSteps.type)}
          run
        />
      )}
    </currentTourContext.Provider>
  );
};

export const useCurrentTourContext = () => {
  const {
    currentTourSteps,
    setRetakingTour,
    setCurrentTour,
    currentTour,
  } = React.useContext(currentTourContext);
  return {
    currentTourSteps,
    setRetakingTour,
    setCurrentTour,
    currentTour,
  };
};

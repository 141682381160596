import { useMutation, gql } from "@apollo/client";
import * as React from "react";
import { FrankBackendTypes } from "frank-types";

const mutation = gql`
  mutation AddToChannel($input: AddToChannelInput!) {
    addToChannel(input: $input) {
      nothing
    }
  }
`;

export default function useAddMember() {
  const [invokeMutation, { loading, error, called }] = useMutation<
    Pick<FrankBackendTypes.Mutation, "addToChannel">,
    FrankBackendTypes.MutationAddToChannelArgs
  >(mutation);

  const addToChannel = React.useCallback(
    async (input: FrankBackendTypes.AddToChannelInput) => {
      const { data } = await invokeMutation({
        variables: { input },
      });
      return data?.addToChannel;
    },
    [invokeMutation]
  );

  return {
    addToChannel,
    loading,
    error,
    called,
  };
}

import { useQuery, gql } from "@apollo/client";
import { FrankBackendTypes } from "frank-types";
import * as React from "react";
import { taskAssignmentFragment } from "../taskAssignmentFragment";

const QUERY = gql`
  query TaskAssignments(
    $groupId: ID
    $coworkerId: ID
    $pagination: PaginationArgumentDTO!
  ) {
    taskAssignments(
      pagination: $pagination
      groupId: $groupId
      coworkerId: $coworkerId
    ) {
      hasNext
      objects {
        ...TaskAssignmentFragment
      }
    }
  }
  ${taskAssignmentFragment}
`;

export default function useTaskAssignments({
  groupId,
  coworkerId,
  workflowState,
}: {
  groupId?: string;
  coworkerId?: string;
  workflowState?: FrankBackendTypes.AssignmentWorkflowState;
}) {
  const perPage = 25;

  const { loading, error, data, refetch, fetchMore } = useQuery<
    Pick<FrankBackendTypes.Query, "taskAssignments">,
    FrankBackendTypes.QueryTaskAssignmentsArgs
  >(QUERY, {
    variables: {
      pagination: {
        page: 0,
        perPage,
      },
      groupId,
      coworkerId,
      workflowState,
    },
  });

  const nextPage = React.useCallback(() => {
    return fetchMore({
      variables: {
        pagination: {
          page: Math.round(data?.taskAssignments.objects.length / perPage),
          perPage,
        },
      },
    });
  }, [fetchMore, data]);

  return {
    taskAssignments: data?.taskAssignments,
    loading: loading,
    error: error,
    refetch,
    nextPage,
  };
}
